/*
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-12-15 16:34:24
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2023-12-18 11:09:15
 * @FilePath: /lcwpcvue/src/api/compe/subAccountApi.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'
/* 获取子账号列表 */
export function getMySubAccountList(params) {
  return request({
    url: 'CompanyAccount/accountList',
    method: 'get',
    params: params,
  })
}
/* 通过账号查询子账号信息 */
export function getSubInfByUserName(params) {
  return request({
    url: 'CompanyAccount/searchAccount',
    method: 'get',
    params: params,
  })
}
/* 绑定子账号 */

export function bindSubAccount(params) {
  return request({
    url: 'CompanyAccount/bindAccount',
    method: 'post',
    data: params,
  })
}

/* 查看子账号信息 */
export function getSubAccountInfById(params) {
  return request({
    url: 'CompanyAccount/accountInfo',
    method: 'get',
    params: params,
  })
}


/* 修改子账号信息 */

export function editSubAcccountInf(params) {
  return request({
    url: 'CompanyAccount/accountInfoEdit',
    method: 'post',
    data: params,
  })
}

/* 获取子账号和主账号的配额数量 */
export function getSubAccountComboNumber(params) {
  return request({
    url: 'CompanyAccount/getAccountConfig',
    method: 'get',
    params: params,
  })
}

/* 给子账号分配套餐 */
export function comboNuberToSub(params) {
  return request({
    url: 'CompanyAccount/setAccountAllocation',
    method: 'post',
    data: params,
  })
}

/* 解除子账号的绑定 */
export function delSubAccount(params) {
  return request({
    url: 'CompanyAccount/unbindAccountV2',
    method: 'post',
    data: params,
  })
}

/* 删除，在状态为3的时候，非解除操作 */
export function delAccount(id) {
  return request({
    url: 'v2/CompanyAccount/delAccount',
    method: 'post',
    data: {
      id:id
    },
  })
}

/* 是否有待确认的申请绑定 */
export function isHaveConfirmed() {
  return request({
    url: 'v2/CompanyAccount/onfirmlist',
    method: 'get',
  })
}

/*是否接受邀请 2同意 3拒绝 */
export function accountHandle(id,status) {
  return request({
    url: 'CompanyAccount/childAccountHandle',
    method: 'post',
    data:{
      id:id,
      status:status,
      uid:''
    }
  })
}
/*主账号取消绑定，已知晓按钮 */
export function toCancelConfirm(id) {
  return request({
    url: 'v2/CompanyAccount/cancelConfirm',
    method: 'post',
    data:{
      id:id
    }
  })
}