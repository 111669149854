<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-05-24 17:28:28
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-06-07 18:31:55
 * @FilePath: /lcwpcvue/src/components/individualUser/jobYx.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="jobyx">
    <div class="title-view">
      <div class="title">求职意向</div>
      <div class="t2">根据求职意向为你推荐专属职位～</div>
    </div>

    <el-form
      label-position="top"
      :model="ruleForm"
      :rules="formRules"
      status-icon
      ref="ruleForm"
      class="jobyx-demo-ruleForm"
    >
      <div class="choseview intention-sl">
        <el-form-item label="" prop="job_classid">
          <div class="tt">求职意向</div>
          <div class="chose-input" style="padding: 0 0;width: 688px;border: none" @click="isyxzw = true">
            <el-input
              v-model="jobClassName"
              placeholder="请输入内容"
              :clearable="false"
            ></el-input>
            <!-- <span class="tt1" v-show="jobClassName == ''">请选择期望职位</span>
            <span class="tt2" v-show="jobClassName != ''">{{
              jobClassName
            }}</span> -->

            <!-- <i class="el-icon-arrow-right"></i> -->
          </div>
        </el-form-item>
      </div>
      <div class="choseview">
        <el-form-item label="" prop="city_classid">
          <div class="tt">工作城市</div>
          <div class="chose-input" @click="isCityShow = true">
            <span class="tt1" v-show="jobCityName == ''">请选择期望城市</span>
            <span class="tt2" v-show="jobCityName != ''">{{
              jobCityName
            }}</span>
            <i class="el-icon-arrow-right"></i>
          </div>
        </el-form-item>
      </div>
      <div class="choseview" prop="hy">
        <el-form-item label="" prop="hy">
          <div class="tt">期望行业</div>
          <div class="chose-input" @click="isJobHyShow = true">
            <span class="tt1" v-show="hyName == ''">请选择期望行业</span>
            <span class="tt2" v-show="hyName != ''">{{ hyName }}</span>
            <i class="el-icon-arrow-right"></i>
          </div>
        </el-form-item>
      </div>

      <div class="choseview">
        <div class="tt">期望薪资</div>
        <el-form-item class="xz-view" label="" prop="minsalary">
          <el-select
            @change="minChange"
            v-model="ruleForm.minsalary"
            placeholder="请选择最低工资"
            :disabled="checkDis"
          >
            <el-option
              v-for="item in minOptions"
              :key="item.id"
              :label="item.key"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-select
            @change="maxChange"
            v-model="ruleForm.maxsalary"
            placeholder="请选择最高工资"
            :disabled="checkDis"
          >
            <el-option
              v-for="item in maxOptions"
              :key="item.id"
              :label="item.key"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <!--          <el-checkbox @change="checkBoxChange"></el-checkbox>-->
          <el-checkbox v-model="isPrice"></el-checkbox>
          <span class="mytext">面议</span>
        </el-form-item>
      </div>

      <div class="choseviewlong">
        <el-form-item label="" prop="jobstatus">
          <div class="tt">求职状态</div>
          <el-select v-model="ruleForm.jobstatus" placeholder="请选择求职状态">
            <el-option
              v-for="item in qzStatus"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </div>

      <div class="choseview">
        <div class="tt">工作性质</div>
        <el-form-item class="xz-view" label="">
          <div class="item-view">
            <span
              class="it"
              :class="ruleForm.type == 0 ? 'isslit' : ''"
              @click="changeJtype(0)"
              >不限</span
            >
            <span
              class="it"
              :class="ruleForm.type == 1 ? 'isslit' : ''"
              @click="changeJtype(1)"
              >全职</span
            >
            <span
              class="it"
              :class="ruleForm.type == 2 ? 'isslit' : ''"
              @click="changeJtype(2)"
              >兼职</span
            >
          </div>
        </el-form-item>
      </div>

      <el-form-item class="subm">
        <div class="subm-bot-btn">
          <el-button type="info" plain @click="banckFunction">上一步</el-button>
          <el-button type="primary" @click="submitForm('ruleForm')">{{
            submitBtnText
          }}</el-button>
        </div>
      </el-form-item>
    </el-form>
    <chosecity
      :dialogVisible2="isCityShow"
      @closeDilog2="closeDilog2"
      @saveJobAddr="saveJobAddr"
      :is-show-all="true"
    ></chosecity>
    <chosejobhy
      :dialogVisible3="isJobHyShow"
      @closeDilog3="closeDilog3"
      @changeJobHy="changeJobHy"
    ></chosejobhy>
    <choseyxzw
      :isOneJob="false"
      :dialogVisible1="isyxzw"
      @closeDilog1="closeDilog1"
      @saveJobArr="saveJobArr"
    ></choseyxzw>
  </div>
</template>

<script>
import ChoseCity from "@/components/choseCity.vue";
import ChoseJobHy from "../choseHy.vue";
import ChoseYxZw from "../choseZw.vue";
import { getUserJobStatus } from "@/api/commen/register";
import { updateQzYxInf } from "@/api/indiviuser/userInf";
export default {
  name: "jobyx",
  components: {
    chosecity: ChoseCity,
    chosejobhy: ChoseJobHy,
    choseyxzw: ChoseYxZw,
  },
  data() {
    return {
      submitBtnText: "下一步",
      isyxzw: false,
      isCityShow: false,
      isJobHyShow: false,
      //     job_classid: this.job_classid, // 期望职位id
      //  city_classid: this.city_classid, // 工作城市id
      //  hy: this.hyid, // 行业id
      //  maxsalary: this.maxsalary, // 最高薪资
      //  minsalary: this.minsalary, // 最低薪资
      //  jobstatus: this.jobstatus, // 求职状态
      //  report: this.report, // 随时到岗  周内到岗 月内到岗
      //  type: this.type // 工作性质
      ruleForm: {
        job_classid: "",
        city_classid: "",
        hy: "",
        maxsalary: "",
        minsalary: "",
        jobstatus: "",
        report: "1051",
        type: 0,
      },
      formRules: {
        job_classid: [
          { required: true, message: "请选择求职意向", trigger: "blur" },
        ],
        city_classid: [
          { required: true, message: "请选择工作城市", trigger: "blur" },
        ],
        hy: [{ required: true, message: "前选择期望行业", trigger: "blur" }],
        maxsalary: [{ required: true, message: "请选择薪资", trigger: "blur" }],
        minsalary: [{ required: true, message: "请选择薪资", trigger: "blur" }],
        jobstatus: [
          { required: true, message: "请选择求职状态", trigger: "blur" },
        ],
      },
      minOptions: [],
      maxOptions: [],
      jobClassName: "",
      qzStatus: [],
      jobCityName: "",
      hyName: "",
      checkDis: false,
      isFisterLogin: false,
    };
  },
  computed: {
    isPrice: {
      get() {
        if (this.ruleForm.maxsalary === 0 && this.ruleForm.minsalary === 0) {
          return true;
        }
        return false;
      },
      set(value) {
        console.log("isPrice:", value);
        if (value) {
          this.checkDis = true;
          this.ruleForm.maxsalary = 0;
          this.ruleForm.minsalary = 0;
        } else {
          this.ruleForm.maxsalary = 8000;
          this.ruleForm.minsalary = 5000;
          this.minChange(this.ruleForm.minsalary);
          this.checkDis = false;
        }
      },
    },
  },
  created() {
    this.minOptions = [];
    this.maxOptions = [];
    this.getUserJobStatus();
    for (let i = 1; i < 30; i++) {
      var a = {
        id: i * 1000,
        key: i + "k",
      };
      this.minOptions.push(a);
      if (i < 5) {
        var b = {
          id: (i + 1) * 1000,
          key: i + 1 + "k",
        };
        this.maxOptions.push(b);
      }
    }
    this.isFisterLogin = this.$store.state.user.isfisterlogin;
    if (this.$store.state.user.isfisterlogin) {
      this.submitBtnText = "下一步";
    } else {
      this.submitBtnText = "保存";
    }

    console.log("09090", JSON.parse(localStorage.regJobYx));

    if (localStorage.regJobYx) {
      this.ruleForm = JSON.parse(localStorage.regJobYx);
      this.jobClassName = localStorage.regJobYxjobClassName;
      this.jobCityName = localStorage.regJobYxjobCityName;
      this.hyName = localStorage.regJobYxhyName;
      if (this.ruleForm.minsalary !== 0) {
        this.minChange(this.ruleForm.minsalary);
      }
    }
  },
  methods: {
    banckFunction() {
      this.$router.go(-1);
    },
    submitForm(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (this.ruleForm.city_classid == "") {
            this.$message({
              message: "工作城市不能为空",
              type: "warning",
            });
            return false;
          }
          updateQzYxInf(this.ruleForm)
            .then((result) => {
              console.log("保存求职意向", result);
              this.$message({
                message: "提交成功！",
                type: "success",
              });
              this.$emit("saveJobYx");
              localStorage.setItem("regJobYx", JSON.stringify(this.ruleForm));
              localStorage.setItem("regJobYxjobClassName", this.jobClassName);
              localStorage.setItem("regJobYxjobCityName", this.jobCityName);
              localStorage.setItem("regJobYxhyName", this.hyName);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },

    closeDilog1() {
      this.isyxzw = false;
    },
    closeDilog2() {
      this.isCityShow = false;
    },
    closeDilog3() {
      this.isJobHyShow = false;
    },

    saveJobArr(arr) {
      console.log(arr);
      this.isyxzw = false;
      var a = [],
        b = [];
      arr.forEach((element) => {
        a.push(element.name);
        b.push(element.id);
      });
      this.jobClassName = String(a);
      this.ruleForm.job_classid = String(b);
    },
    saveJobAddr(pr, ct, ar) {
      console.log("=========", ar);
      this.isCityShow = false;
      var c = [],
        d = [];
      ar.forEach((element) => {
        c.push(element.name);
        d.push(element.id);
      });
      this.jobCityName = String(c);
      this.ruleForm.city_classid = String(d);
    },
    changeJobHy(e) {
      console.log(e);
      this.isJobHyShow = false;
      this.ruleForm.hy = e.id;
      this.hyName = e.name;
    },
    minChange(e) {
      console.log("最小", e);
      this.maxOptions = [];
      var va = e / 1000 + 1;
      for (let i = va; i < 40; i++) {
        console.log(va);
        var b = {
          id: (i + 1) * 1000,
          key: i + 1 + "k",
        };
        this.maxOptions.push(b);
      }
    },

    maxChange(e) {
      console.log("最大", e);
    },
    //获取求职状态
    getUserJobStatus() {
      getUserJobStatus()
        .then((result) => {
          console.log("求职状态", result.data);
          this.qzStatus = result.data["job_status_list"];
          // console.log(this.qzStatus);
        })
        .catch(() => {});
    },

    checkBoxChange(e) {
      if (e) {
        this.checkDis = true;
        this.ruleForm.maxsalary = 0;
        this.ruleForm.minsalary = 0;
      } else {
        this.checkDis = false;
      }
    },
    changeJtype(e) {
      this.ruleForm.type = e;
    },
  },
};
</script>

<style>
.jobyx {
  width: 988px;
  background-color: #ffffff;
  padding: 54px 71px;
  border-radius: 24px;
}
.title-view .title {
  font-size: 32px;

  font-weight: 600;
  color: #222222;
}

.title-view .t2 {
  font-size: 20px;

  font-weight: 500;
  color: #b1b1b1;
  margin-top: 12px;
  margin-bottom: 50px;
}
.chose-input {
  width: 830px;
  height: 40px;
  border: 1px solid #e4e7ed;
  border-radius: 4px;
  padding: 0 15px;
  color: #b1b1b1;
  position: relative;
  display: flex;
  align-items: center;
}

.chose-input .tt1 {
  font-size: 14px;

  font-weight: 400;
}

.chose-input .tt2 {
  color: #222;
}

.chose-input i {
  position: absolute;
  right: 15px;
}

.choseview {
  position: relative;
}

.choseview .tt,
.choseviewlong .tt {
  font-size: 14px;
  color: #606266;
}

.xz-view {
  margin-top: 12px;
}

/* .el-cascader {
  width: 343px;
} */

.jobyx .el-cascader .el-input .el-input__inner {
  text-align: center;
}
.choseview .el-select {
  width: 343px;
  margin-right: 57px;
}
.el-cascader-menu {
  width: 343px;
}

.xz-view .mytext {
  margin-left: 5px;

  font-weight: 400;
  color: #222222;
}

.choseviewlong .el-select {
  width: 860px;
}

/* .choseviewlong .el-cascader .el-input .el-input__inner {
  text-align: left;
}

.choseviewlong .el-cascader-menu {
  width: 860px;
} */
.choseview .item-view .it {
  display: inline-block;
  width: 260px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #e4e7ed;
  text-align: center;
  font-size: 14px;

  font-weight: 400;
  color: #b1b1b1;
  margin-right: 35px;
}
.choseview .item-view .it:last-child {
  margin-right: 0;
}

.choseview .item-view .isslit {
  background-color: #fff5f0;
  color: #fe6002;
  border: 1px solid #fe6002;
}
.jobyx .subm {
  text-align: right;
  margin-top: 40px;
}
.jobyx .subm .el-button {
  width: 369px;
  height: 40px;
  font-size: 14px;
}
.jobyx .subm .subm-bot-btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>