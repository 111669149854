<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-05-11 14:34:54
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-05-28 14:12:55
 * @FilePath: /lcwpcvue/src/components/pageHeader.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="min-head-view">
    <div class="left">
      <img class="logo" src="@/assets/logo.jpg" @click="toHome" />
      <div class="fgx"></div>
      <div class="typetext">{{ userTypeName }}</div>
    </div>
    <div class="right">
      <div class="kfphone">
        <img src="@/assets/head_phone.png" />
        <span class="phone">客服电话：021-60409917</span>
      </div>
      <div class="chang-btn" @click="changeLoginType">
        <img src="@/assets/head_chagimg.png" />
        <span>{{ userTypeNameCh }}用户登录</span>
        <!-- <span v-show="userType == 2">{{ userTypeName }}用户登录</span> -->
      </div>
    </div>
  </div>
</template>

<script>
// import { mapState } from "vuex";

// import ChangeUsertype from "@/components/choseUserType.vue";
// import store from "@/store";
// import { mapState } from "vuex";
// import getters from "../store/getters";

export default {
  name: "pageHeader",
  props: {
    // userTypeName: {
    //   type: String,
    //   default: "招聘端",
    // },
    submitBtnText: {
      type: String,
      default: "登录/注册",
    },
    loginType: {
      type: Number,
      default: 1,
    },
  },

  components: {
    // ChangeUsertype,
  },
  data() {
    return {
      usertype: "",
      userTypeName: "招聘端",
      userTypeNameCh: "求职端",
    };
  },
  computed: {
    // ...mapState(["usertype"]),
  },

  created() {
    this.$store.watch(
      (state) => {
        if (state.user.usertype == 1) {
          this.userTypeNameCh = "招聘";
          this.userTypeName = "求职端";
        } else {
          this.userTypeNameCh = "求职";
          this.userTypeName = "招聘端";
        }
      },
      () => {}
    );
  },
  methods: {
    changeLoginType() {
      this.$emit("changeUserType");
    },
    toHome() {
      this.$router.push("/home");
    },
  },
};
</script>

<style>
.min-head-view {
  background-color: #fff;
  /* width: 100%; */
  width: 1920px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px 0;
  z-index: 9999;
}
.left {
  display: flex;
  align-items: center;
  /* margin-left: 11.82rem; */
}

.left .logo {
  width: 200px;
  height: auto;
}
.left .fgx {
  width: 0rem;
  height: 30px;
  border: 1px solid #222222;
  margin-left: 32px;
}

.left .typetext {
  /* width: 200px; */
  font-size: 20px;
  
  font-weight: 500;
  color: #222222;
  margin-left: 14px;
  white-space: nowrap;
  overflow: hidden;
}
.right {
  margin-left: 675px;
  display: flex;
  align-items: center;
}

.right .kfphone {
  display: flex;
  align-items: center;
  font-size: 16px;
  
  font-weight: 400;
  color: #666666;
}

.right .kfphone img {
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.right .chang-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 166px;
  height: 40px;
  border-radius: 10px;
  border: 2px solid #fe6002;
  font-size: 16px;
  
  font-weight: 500;
  color: #fe6002;
  margin-left: 100px;
  white-space: nowrap;
  overflow: hidden;
}

.right .chang-btn img {
  width: 18px;
  height: 18px;
  margin-right: 14px;
}
</style>

