/*
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-07-28 17:39:23
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-03-01 17:19:30
 * @FilePath: /lcwpcvue/src/api/commen/findJobList.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'
//获取职位列表
export function getJobList(params) {
  return request({
    url: 'index/indexV2',
    method: 'post',
    data: params
  })
}

//获取职位列表
export function getPcJobList(params) {
  return request({
    url: 'v2/index/indexV2',
    method: 'post',
    data: params
  })
}