<template>
  <div class="h5-com-detail-page">
    <div class="h5-back-view" @click="backFun">
      <i class="el-icon-arrow-left"></i>
      <span>{{ datas.name }}</span>
    </div>
    <div style="margin-top: 50px"></div>
    <div class="header containers">
      <div class="hname">
        <div class="hline1">
          <div class="hcomname">{{ datas.name }}</div>
          <!-- <image v-if="datas.yyzz_status==1" :class="comnameLeth>15?'h2':'h1'" :src="datas.yyzz_status_icon"
						mode="heightFix"> -->
          <div :class="comnameLeth > 15 ? 'h2' : 'h1'">
            <img
              v-if="datas.yyzz_status == 1"
              :src="
                process.env.VUE_APP_IMG +
                '/data/upload/reward/20230209/16830424511.png'
              "
            />
            <span v-if="datas.yyzz_status == 1">已认证</span>
          </div>
        </div>
        <div class="hline2">
          <span v-if="datas.hy_name">{{ datas.hy_name }}</span>
          <span v-if="datas.company_num">{{ datas.company_num }}</span>
          <span v-if="datas.sdate">{{ datas.sdate }}</span>
          <span v-if="datas.area">{{ datas.area }}m²</span>
        </div>
      </div>
      <img class="logo" :src="datas.logo" mode="aspectFill" />
    </div>

    <!-- <div class="containers time">
			<div v-if="datas.work_time" style="display: flex;align-items: center; margin-top: 44px;">
				<u-icon name="clock" size="32" color="222222"></u-icon>
				<text style="margin-left: 8px;">{{ datas.work_time }}</text>
			</div>
			<div v-if="datas.rest_day != 0" style="display: flex;align-items: center;margin-top: 44px;">
				<u-icon style="margin-left: 36px;" name="calendar" size="32" color="222222"></u-icon>
				<text style="margin-left: 8px;" v-if="datas.rest_day == 2">双休</text>
				<text style="margin-left: 8px;" v-if="datas.rest_day == 1">单休</text>
				<text style="margin-left: 8px;" v-if="datas.rest_day == 3">排班轮休</text>
			</div>
		</div> -->

    <div class="welfare">
      <div
        class="wcontent"
        v-for="(item, index) in datas.welfare"
        :key="index"
        v-show="item"
      >
        {{ item }}
      </div>
    </div>

    <div class="containers" v-if="datas.content">
      <div class="alltitle mt-32">公司介绍</div>
      <div class="description">
        <span style="white-space: pre-wrap" v-if="more">{{ more }}...</span>
        <span style="color: #ff8136" @click="showdescription" v-if="more"
          >查看全部</span
        >
        <span style="white-space: pre-wrap" v-if="!more">{{
          datas.content
        }}</span>
      </div>
    </div>

    <div class="containers">
      <div class="alltitle mt-30" v-if="datas.address">公司地址</div>
      <div class="address">
        <div class="ads">{{ datas.address }}</div>
        <div class="navigete" @click="toAmapDh">
          <img
            src="https://mp-168c5a18-1927-4a65-8b2c-95087d865588.cdn.bspapp.com/cloudstorage/h2.png"
          />
          去导航
        </div>
      </div>
    </div>

    <div class="containers" v-if="datas.company_pic.length > 0">
      <div class="alltitle mt-30">公司环境</div>
      <div class="chgtype">
        <div
          class="nochg"
          :class="type == 1 ? 'choose' : 'nochoose'"
          @click="checktype(1)"
          v-if="videolist.length > 0"
        >
          视频.{{ videolist.length }}
        </div>
        <div
          class="nochg"
          :class="type == 2 ? 'choose' : 'nochoose'"
          @click="checktype(2)"
          v-if="imglist.length > 0"
        >
          照片.{{ imglist.length }}
        </div>
      </div>
      <div class="environment">
        <div v-if="type == 1">
          <div v-for="(item, index) in videolist" :key="index"></div>
        </div>

        <div v-show="type == 2" v-for="(item, index) in imglist" :key="index">
          <el-image
            style="width: 100px; height: 100px"
            :src="item.picurl"
            :preview-src-list="prImglist"
          >
          </el-image>
        </div>
      </div>
    </div>

    <div class="forjob containers">
      <div class="ftitle">公司招聘职位</div>
      <div class="flist" v-for="(item, index) in company_jobs" :key="index">
        <div class="fline1">
          <span>{{ item.name }}</span>
          <!-- <img
            style="width: 72px; height: 36px; margin-left: 16px"
            v-if="item.part && item.part != '' && item.part != 0"
            src="/static/images/index_list_jianzhi.png"
            mode=""
          /> -->
          <div class="flineview">
            <span
              >{{ item.minsalary / 1000 }}-{{ item.maxsalary / 1000 }}k</span
            >
            <!-- <salary-range :maxsalary="item.maxsalary" :minsalary="item.minsalary"></salary-range> -->
            <!-- <haspart-salary-range
              :salary="item.salary"
              :maxsalary="item.maxsalary"
              :minsalary="item.minsalary"
              :part="item.part"  
            ></haspart-salary-range> -->
          </div>
        </div>
        <div
          class="fline2"
          v-if="
            item.exp_name != '不限' ||
            item.exp_name ||
            item.province ||
            item.city
          "
        >
          <div class="ftag">
            <span>{{ item.exp_name }}</span>
            <span>{{ item.edu_name }}</span>
          </div>
          <div class="fposi">
            <span v-if="item.province">{{ item.province }}</span>
            <span v-if="item.city">-{{ item.city }}</span>
            <span v-if="item.three_city">-{{ item.three_city }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- #ifdef MP-WEIXIN -->
    <div class="tip" v-if="usertype == 1">
      <div class="ipos mt-6">
        <div class="tips">
          <!-- <image src="/pagesA/static/img/safe.png" mode="widthFix"></image> -->
          <span>猎宠网安全提示：</span>
        </div>
        <div class="tcontent">
          求职中如遇到招聘方存在虚假信息，收取费用，扣押证件，或其他违法情形，请立即
          <span @click="toMiniProFun">举报</span>
          !
        </div>
      </div>
    </div>
    <!-- #endif -->
    <openmini style="position: fixed; left: -20px; bottom: 0"></openmini>
    <div style="margin-top: 128px"></div>
  </div>
</template>

<script>
import { getCompanyDetailInfo } from "@/api/compe/companyInf.js";
import openmini from "@/components/h5Components/h5TominPro.vue";
import {cresteHttpUrl} from "@/api/h5/h5Redirect";
export default {
  components: {
    openmini,
  },
  data() {
    return {
      backtext: "",
      uid: "38570",
      datas: {
        company_pic: [],
      },
      more: "",
      type: 1,
      videolist: [],
      imglist: [],
      company_jobs: [{}, {}, {}, {}, {}],
      is_chat: "0",
      is_collection: "0",
      is_shield: "0",
      usertype: 1,
      modalLh: false,
      isLahei: 0,
      modalContenLh: "",
      // modeltitlePb:'屏蔽公司',
      modalPb: false,
      modalContenPb:
        "屏蔽该公司后，猎宠网将不再向您推荐该公司的相关职位，也不再主动将您推送给对方。",
      comnameLeth: 0,
      prImglist: [],
    };
  },
  created() {
    this.uid = this.$route.query.id;
    this.getCompanyDetailInfo();
  },
  methods: {
    backFun() {
      this.$router.go(-1);
    },
    // tojobinfo(id, parttime) {
    //   if (!!parttime && parttime != 0) {
    //     uni.navigateTo({
    //       url: `/pages/index/jobinfo?id=${id}&parttype=1`,
    //     });
    //   } else {
    //     uni.navigateTo({
    //       url: `/pages/index/jobinfo?id=${id}`,
    //     });
    //   }
    // },
    //获取公司信息
    getCompanyDetailInfo() {
      getCompanyDetailInfo({
        company_uid: this.uid,
      })
        .then((result) => {
          console.log("获取公司信息", result);
          this.datas = result.data.company;
          document.title = result.data.company.name;
          // this.companyInf = result.data.company;
          if (this.datas.content.length > 100) {
            this.more = this.datas.content.slice(0, 100);
          }
          this.company_jobs = result.data.company_jobs;
          this.datas.company_pic.forEach((item) => {
            if (item.type === "video") {
              this.videolist.push(item);
            }
            if (item.type === "img") {
              this.imglist.push(item);
            }
          });
          if (this.videolist.length === 0) {
            this.type = 2;
          }
          let imgs = result.data.company.company_pic;
          imgs.forEach((im) => {
            if (im.type === "img") {
              this.prImglist.push(im.picurl);
            } else {
              this.video = im.picurl;
              this.videoCover = im.coverurl;
            }
          });
        })
        .catch(() => {});
    },
    // getcompanyList() {
    //   api.post({
    //     url: "company/companyInfo",
    //     data: {
    //       company_uid: this.uid,
    //       // company_uid: '2690655'
    //     },
    //     success: (data) => {
    //       uni.hideLoading();
    //       console.log("获取公司详情", this.uid, data);
    //       this.is_collection = data.data.is_collection;
    //       this.is_chat = data.data.is_chat;
    //       this.is_shield = data.data.is_shield;
    //       this.datas = data.data.company;
    //       this.company_jobs = data.data.company_jobs;
    //       this.comnameLeth = data.data.company.name.split("").length;
    //       this.datas.company_pic.forEach((item) => {
    //         if (item.type === "video") {
    //           this.videolist.push(item);
    //         }
    //         if (item.type === "img") {
    //           this.imglist.push(item);
    //         }
    //       });
    //       if (this.videolist.length === 0) {
    //         this.type = 2;
    //       }
    //       if (this.datas.content.length > 100) {
    //         this.more = this.datas.content.slice(0, 100);
    //       }
    //     },
    //   });
    // },
    showdescription() {
      this.more = "";
    },

    toAmapDh() {
      const destination = `${this.datas.x},${this.datas.y}`; // 目的地坐标, 例如北京水立方
      window.location.href = `https://uri.amap.com/marker?position=${destination}&name=${this.datas.address}&keywords=高德地图官网&dev=0&id=BGVJIFRK&coordinate=gaode&callnative=0`;
    },

    // // 打开地图
    // openmap() {

    // },
    toMiniProFun() {
      cresteHttpUrl().then(e=>{
        window.open(e.data.url);
      });
    },

    checktype(num) {
      this.type = num;
    },
  },
};
</script>

<style scoped>
.h5-com-detail-page {
  width: 100%;
  height: 100%;
  overflow: scroll;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 16px;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  transition: width 0.3s ease;
  &::-webkit-scrollbar {
    display: none;
  }
}

.navbtn {
  display: flex;
  align-items: center;
}

.navbtn img {
  width: 22px;
  height: 22px;
  margin-right: 11px;
}

.header {
  margin-top: 17px;
  height: 116px;
  display: flex;
  position: relative;
}

.logo {
  width: 58px;
  height: 58px;
  border-radius: 2px;
  position: absolute;
  right: 0;
}

.hname {
  line-height: 25px;
  line-height: 25px;
}

.hline1 {
  margin-top: 3px;
  position: relative;
  width: 272px;
  /* display: flex;
		align-items: center;
		flex-wrap: nowrap; */
}

.hcomname {
  width: 272px;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 25px;

  font-weight: 900;
  color: #222222;
  /* line-height: 64px; */
}

.h1 {
  height: 16px;
  margin-top: 16px;
  display: flex;
  align-items: center;
}

.h2 {
  position: absolute;
  height: 16px;
  top: 32px;
  right: 0;
  display: flex;
  align-items: center;
}

.h1 img,
.h2 img {
  height: 18px;
  width: 18px;
  margin-right: 2px;
}

.h1 span,
.h2 span {
  font-size: 16px;

  font-weight: 400;
  color: #ff8136;
}

.h_rz {
  padding-left: 4px;
  font-size: 14px;

  font-weight: 400;
  color: #ff8136;
}

.hline2 {
  margin-top: 10px;
  height: 10px;
  line-height: 7px;
}

.hline2 span {
  margin-top: 10px;
  display: inline-block;
  padding: 0 8px;
  font-size: 13px;

  font-weight: 400;
  color: #757575;
  border-right: 1px solid #979797;
}

.hline2 text:nth-child(1) {
  padding-left: 0;
}

.hline2 text:last-child {
  border-right: none;
}

.time {
  margin-top: 22px;
  display: flex;
  align-items: center;
  font-size: 15px;

  font-weight: 400;
  color: #222222;
}

.welfare {
  margin-top: 37px;
  /* margin-left: 16px; */
  width: 359px;
}

.wcontent {
  display: inline-block;
  height: 24px;
  line-height: 24px;
  padding: 0 8px;
  margin-right: 10px;
  font-size: 14px;

  font-weight: 400;
  background: #fff3ec;
  color: #ff8136;
  border-radius: 2px;
  margin-bottom: 10px;
}

.alltitle {
  font-size: 20px;

  font-weight: 900;
  color: #222222;
}

.mt-32 {
  margin-top: 32px;
}

.description {
  display: block;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /* -webkit-line-clamp: 3; */
  overflow: hidden;
  margin-top: 8px;
  font-size: 15px;

  font-weight: 400;
  color: #222222;
  line-height: 28px;
}

.mt-30 {
  margin-top: 30px;
}

.address {
  height: 24px;
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ads {
  width: 331px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;

  font-weight: 400;
  color: #757575;
}

.navigete {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 29px;
  line-height: 29px;
  background: #fffbf9;
  border-radius: 29px;
  border: 1px solid #fe6002;
  font-size: 14px;

  font-weight: 400;
  color: #fe6002;
}

.navigete img {
  width: 16px;
  height: 17px;
}

.chgtype {
  display: flex;
}

.nochg {
  width: 72px;
  height: 30px;
  margin-right: 10px;
  margin-top: 8px;
  line-height: 30px;
  text-align: center;
  border-radius: 14px;
  font-size: 14px;

  font-weight: 400;
  color: #ffffff;
}

.nochoose {
  background: #c5c9cd;
}

.chgtype .choose {
  background: #ff8136;
}

.environment {
  max-width: 450px;
  margin-top: 16px;
  overflow: scroll;
  white-space: nowrap;
  height: 139px;
  margin-right: -16px;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  transition: width 0.3s ease;
  &::-webkit-scrollbar {
    display: none;
  }
}

.environment div {
  display: inline-block;
  margin-right: 10px;
  border-radius: 8px;
}

.environment div img {
  border-radius: 8px;
}

/* .environment div video {
  height: 348px;
  width: 620px;
} */

.forjob {
  margin-top: 17px;
  padding-bottom: 5px;
}
.forjob .flineview span {
  color: #ff8136;
}
.ftitle {
  margin-bottom: 9px;
  font-size: 20px;

  font-weight: 700;
  color: #222222;
}

.flist {
  /* margin: 30px 0px 32px; */
  border-bottom: 1px solid #e7e8eb;
}

.fline1 {
  height: 52px;
  line-height: 52px;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}

.fline1 > span:nth-child(1) {
  /* width: 540px; */
  font-size: 18px;

  font-weight: 700;
  color: #222222;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fline1 .flineview {
  position: absolute;
  right: 15px;
}

.fline2 {
  /* height: 100px; */
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  transition: width 0.3s ease;
  &::-webkit-scrollbar {
    display: none;
  }
}

.ftag {
  max-width: 285px;
  overflow: scroll;
  white-space: nowrap;
  line-height: 24px;
  font-size: 14px;

  font-weight: 400;
  color: #757575;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  transition: width 0.3s ease;
  &::-webkit-scrollbar {
    display: none;
  }
}

.ftag span {
  display: inline-block;
  background: #f5f6fa;
  border-radius: 2px;
  padding: 0 6px;
  margin-right: 16px;
}

.fposi {
  max-width: 140px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;

  font-weight: 400;
  color: #939597;
}

/* #ifdef MP-WEIXIN */
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 32px;
  height: 140px;
  display: flex;
  justify-content: space-around;
  background-color: #ffffff;
  padding-top: 16px;
  z-index: 999;
}

.footer .sollec {
  width: 50px;
  margin-top: 10px;
  margin-left: 15px;
}

.footer .sollec .text {
  font-size: 24px;
  color: #222222;
}

.footer .sollec .txtsl {
  color: #fe6002;
}

.footer .sollec image {
  width: 44px;
  height: 44px;
}

.footer .f-btn {
  flex: 1;
  height: 96px;
  line-height: 96px;
  text-align: center;
  border-radius: 4px;
  font-size: 16px;

  font-weight: 500;
}

.footer .fot-btn1 {
  background: #222222;
  color: #ffffff;
  margin-right: 20px;
}

.footer .fot-btn2 {
  background: #fe6002;
  color: #ffffff;
}

/* .footer view {
		flex: 1;
		height: 96px;
		line-height: 96px;
		text-align: center;
		border-radius: 4px;
		font-size: 16px;
		
		font-weight: 500;
	}
	
	.footer view:nth-child(1) {
		
	}
	
	.footer view:nth-child(2) {
		background: #fe6002;
		color: #ffffff;
	} */
/* #endif */

.tip {
  border-top: 6px solid #f5f6fa;
  padding-left: 16px;
}

.tips {
  margin-top: 20px;
  display: flex;
  align-items: center;
  z-index: 9999;
}

.tips img {
  width: 15px;
  height: 15px;
}

.tips span {
  margin-left: 4px;
  font-size: 13px;

  font-weight: 500;
  color: #222222;
}

.tcontent {
  margin-top: 6px;
  padding-bottom: 20px;
  line-height: 18px;
  font-size: 12px;

  font-weight: 400;
  color: #757575;
}

.tcontent span {
  color: #3291e1;
  text-decoration: underline;
}
</style>