<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2024-01-03 17:02:50
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-06-07 17:57:26
 * @FilePath: /lcwpcvue/src/views/publishTransffer.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="publish-transffer-view">
    <!--    <div class="top-zk-div"></div>-->
    <!--    <div class="breadcrumb">-->
    <!--      <el-breadcrumb separator-class="el-icon-arrow-right">-->
    <!--        <el-breadcrumb-item :to="{ path: '/mytransfer' }">个人中心</el-breadcrumb-item>-->
    <!--        <el-breadcrumb-item>转接店</el-breadcrumb-item>-->
    <!--        <el-breadcrumb-item>发布转店</el-breadcrumb-item>-->
    <!--      </el-breadcrumb>-->
    <!--    </div>-->
    <div class="add-transffer-view">
      <div class="top-titel-text-view">
        <span>发布转店</span>
      </div>
      <div class="transffer-edt-inf-view">
        <div class="release-ms-title">
          <div class="left-xian"></div>
          <div class="title">基本信息</div>
        </div>
        <el-form label-position="left" ref="" size="medium">
          <div class="edi-lin-item">
            <el-form-item label="">
              <div class="items">
                <label class="title-store"><span>店铺类型</span></label>
                <el-select
                  placeholder="请选择店铺类型"
                  v-model="publishShopObj.typeName"
                  @change="choseShopType"
                  ref="type_name_ref"
                >
                  <el-option
                    v-for="item in shopTypeClass"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </div>
            </el-form-item>
            <el-form-item label="">
              <div class="items" @click="openAddress()">
                <label class="title-store"><span>门店地址</span></label>
                <el-input
                  ref="address_ref"
                  readonly
                  placeholder="请选择店门店地址"
                  clearable
                  suffix-icon="el-icon-arrow-right"
                  v-model="publishShopObj.address"
                >
                </el-input>
                <div class="zd-ingp-view"></div>
              </div>
            </el-form-item>
          </div>

          <div class="edi-lin-item">
            <el-form-item label="">
              <div class="items">
                <label class="title-store"><span>联系人</span></label>
                <el-input
                  v-model="publishShopObj.link_man"
                  placeholder="请填写联系人"
                  clearable
                  ref="link_man_ref"
                >
                </el-input>
              </div>
            </el-form-item>
            <el-form-item label="">
              <div class="items">
                <label class="title-store"><span>联系电话</span></label>
                <el-input
                  v-model="publishShopObj.link_tel"
                  placeholder="请填写联系电话"
                  clearable
                  @change="checkData('phone')"
                  ref="link_tel_ref"
                >
                </el-input>
              </div>
            </el-form-item>
          </div>
          <el-divider></el-divider>

          <div class="release-ms-title">
            <div class="left-xian"></div>
            <div class="title">详细信息</div>
          </div>

          <div class="edi-lin-item">
            <el-form-item label="">
              <div class="items">
                <label class="title-store"><span>转店标题</span></label>
                <el-input
                  v-model="publishShopObj.title"
                  placeholder="请填写标题"
                  clearable
                  ref="title_ref"
                >
                </el-input>
              </div>
            </el-form-item>
            <el-form-item label="">
              <div class="items">
                <label class="title-store"><span>店铺面积</span></label>
                <el-input
                  v-model="publishShopObj.area"
                  placeholder="请填写面积"
                  clearable
                  @change="checkData('area')"
                  ref="area_ref"
                >
                  <template slot="append">㎡</template>
                </el-input>
              </div>
            </el-form-item>
          </div>

          <div class="edi-lin-item">
            <el-form-item label="">
              <div class="items">
                <label class="title-store"><span>转让费</span></label>
                <el-input
                  v-model="publishShopObj.transfer_fee"
                  placeholder="请填写转让费"
                  clearable
                  @change="checkData('transfer_fee')"
                  ref="transfer_fee_ref"
                  maxlength="4"
                >
                  <template slot="append">万元</template>
                </el-input>
              </div>
            </el-form-item>
            <el-form-item label="">
              <div class="items" ref="rent_type_ref">
                <label class="title-store"><span>店铺租金</span></label>
                <el-input
                  v-model="publishShopObj.rent"
                  placeholder="请填写租金"
                  clearable
                  @change="checkData('rent')"
                  ref="rent_ref"
                >
                  <template slot="append">元/月</template>
                </el-input>
              </div>
            </el-form-item>
          </div>
          <el-checkbox>对外显示面议，不展示具体金额</el-checkbox>
          <div class="choseview">
            <el-form-item prop="">
              <label class="title-store"><span>租金类型</span></label>
              <div class="choseview-input-view">
                <span
                  class="ch-item"
                  @click="choseRentType('1')"
                  :class="publishShopObj.rent_type == '1' ? 'sl-sex' : ''"
                  >押三付一</span
                >
                <span
                  class="ch-item"
                  @click="choseRentType('2')"
                  :class="publishShopObj.rent_type == '2' ? 'sl-sex' : ''"
                  >半年付</span
                >
                <span
                  class="ch-item"
                  @click="choseRentType('3')"
                  :class="publishShopObj.rent_type == '3' ? 'sl-sex' : ''"
                  >一年付</span
                >
                <span class="ch-item"
                  ><el-input
                    v-model="publishShopObj.rent_type_body"
                    @focus="rentTypeFoc"
                    placeholder="填写自定义"
                  ></el-input
                ></span>
              </div>
            </el-form-item>
          </div>

          <el-divider></el-divider>

          <div class="edi-lin-item">
            <el-form-item label="">
              <div class="items">
                <label class="title-store"><span>每月流水</span></label>
                <el-input
                  v-model="publishShopObj.month_tally"
                  placeholder="请填写（选填）"
                  clearable
                >
                </el-input>
              </div>
            </el-form-item>
            <el-form-item label="">
              <div class="items">
                <label class="title-store"><span>店铺卡金</span></label>
                <el-input
                  v-model="publishShopObj.card_tally"
                  placeholder="请填写（选填）"
                  clearable
                ></el-input>
              </div>
            </el-form-item>
          </div>
          <div class="edi-lin-item">
            <el-form-item label="">
              <div class="items">
                <label class="title-store"><span>会员数量</span></label>
                <el-input
                  v-model="publishShopObj.mem_num"
                  placeholder="请填写（选填）"
                  clearable
                >
                </el-input>
              </div>
            </el-form-item>
            <!-- <el-form-item label="">
              <div class="items">
                <div class="title"><span>店铺卡金</span></div>
                <el-input placeholder="请填写（选填）" clearable></el-input>
              </div>
            </el-form-item> -->
          </div>
          <el-divider></el-divider>
          <div class="second-title-view" v-show="isLogin">
            <span class="ttt1">配套设施</span>
            <span class="ttt2">（请选择店铺配套设施）</span>
          </div>

          <div class="sl-ptss-view" v-show="isLogin">
            <label v-for="(item, index) in shopFacilityArr" :key="item.id">
              <div
                class="pt-item"
                @click="choseFacility(item)"
                :class="item.checked ? 'pt-item-sl' : ''"
              >
                <img v-if="!item.checked" :src="item.ic_n" alt="" />
                <img v-if="item.checked" :src="item.ic" alt="" />

                <div class="pt-text">{{ item.name }}</div>
              </div>
              <div class="items-fg-kong" v-show="(index + 1) % 10 != 0"></div>
            </label>
          </div>
          <div class="third-title-view" v-show="isLogin">
            <span class="ttt1">其他设施</span>
            <span class="ttt2">（最多可自定义10个标签）</span>
          </div>
          <div class="add-other-pt-btn" v-show="isLogin">
            <label v-for="item in otherFacilityArr" :key="item.id">
              <span class="ot-pt-view"
                >{{ item.name
                }}<i
                  @click="delOtherFacility(item.id)"
                  style="margin-left: 10px"
                  class="el-icon-close"
                ></i
              ></span>
            </label>
            <el-button
              @click="addOtherFaci"
              type="primary"
              icon="el-icon-plus"
              plain
              >添加设备名称
            </el-button>
          </div>
          <el-divider v-if="isLogin"></el-divider>
          <div class="second-title-view">
            <span class="ttt1">详细描述</span>
          </div>

          <div class="edi-one-item">
            <el-form-item label="">
              <div class="items">
                <div class="textarea-view">
                  <el-input
                    type="textarea"
                    :rows="8"
                    :maxlength="500"
                    placeholder="请输入店铺详细描述"
                    v-model="publishShopObj.shop_desc"
                  ></el-input>
                  <div class="textarea-bot-view">
                    <span class="tt1"
                      ><label>建议填写字数在20个字以上</label></span
                    >
                    <span class="tt2">0/500</span>
                  </div>
                </div>
              </div>
            </el-form-item>
          </div>

          <div class="second-title-view">
            <span class="ttt1">店铺照片</span>
            <span class="ttt2">（发布上传视频，能够获得更多曝光）</span>
          </div>

          <!--          上传视频-->
          <div class="store-erp-view">
            <label v-if="publishShopObj.video">
              <div class="com-work-img">
                <img
                  @click.stop="openVideo('v', '')"
                  src="../../src/assets/transfer_spplay.png"
                  class="video-cover-img"
                  style="width: 50px; height: auto"
                />
                <img :src="videoCover" />
                <img
                  @click="delWorkImg('video')"
                  class="colse-img"
                  src="@/assets/com_index_del_img.png"
                />
              </div>
            </label>
            <label v-for="(item, index) in imgFileListTmp" :key="index">
              <div class="com-work-img">
                <img
                  :src="item.url"
                  alt=""
                  @click.stop="openVideo('i', item.url)"
                  style="cursor: pointer"
                />
                <img
                  @click="delWorkImg(index)"
                  class="colse-img"
                  src="@/assets/com_index_del_img.png"
                />
              </div>
            </label>
            <el-upload
              :show-file-list="false"
              class="el-upload-view"
              :action="uploadUrl"
              list-type="picture-card"
              :on-remove="handleRemove"
              :on-success="uploadSuccess"
              :before-upload="beforeUploadEve"
              style="margin-top: 20px"
              :on-progress="handleUploadProgress"
              :multiple="true"
              :limit="9"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </div>

          <div class="second-title-view" v-show="isLogin">
            <span class="ttt1">更多信息</span>
            <span class="ttt3">（最多可添加10个标签）</span>
          </div>

          <div class="other-inf-view" v-show="isLogin">
            <label v-for="item in tagArr" :key="item.id">
              <div
                class="other-it"
                :class="item.ischeck ? 'other-it-sl' : ''"
                @click="choseMoreTag(item)"
              >
                {{ item.name }}
              </div>
            </label>
            <label v-for="item in userTagArr" :key="item.id">
              <div
                class="other-it"
                :class="item.ischeck ? 'other-it-sl' : ''"
                @click="choseUserMoreTag(item)"
              >
                {{ item.name }}
              </div>
            </label>
          </div>
          <div class="add-other-bq-btn" v-show="isLogin">
            <el-button
              style="margin-top: 10px"
              @click="addMoreTag"
              type="primary"
              icon="el-icon-plus"
              plain
              >添加标签
            </el-button>
          </div>
          <div :class="'bot-submit-btn'">
            <el-button
              type="primary"
              @click="submitTransffer"
              :style="
                isCanSubbmit ? '' : 'background-color: #d2d2d2;border: none'
              "
              >立即发布
            </el-button>
          </div>
        </el-form>
      </div>
    </div>
    <div class="video-open">
      <!--      :show-close="false"-->
      <el-dialog :visible.sync="isOpenVideo" :before-close="closeVideo">
        <div style="height: 650px; display: flex; justify-content: center">
          <video
            v-show="openVideoOrImg === 'v'"
            controls
            style="max-height: 650px"
            ref="videoPlayer"
          >
            <source :src="publishShopObj.video" type="video/mp4" />
            您的浏览器不支持 video 标签。
          </video>
          <img
            :src="currentImg"
            v-show="openVideoOrImg === 'i'"
            style="height: 650px; width: auto"
          />
        </div>
      </el-dialog>
    </div>
    <el-dialog
      title="购买VIP"
      :visible.sync="payTransfferDialogShow"
      @close="closeBuyVipDig"
      class="transffer-pay-dialog-css"
    >
      <div class="vip-type-view">
        <img class="vip-type-bg" src="@/assets/vip_pay_bg_img.png" alt="" />
        <div class="vip-type-text-view">
          <img class="lczx-img" src="@/assets/vip_pay_zx_img.png" alt="" />
          <div class="left-text-view">
            <div class="name-tt1">转店VIP套餐</div>
            <div class="time">使用时间：转掉为止</div>
          </div>
          <div class="right-text-view">
            <label>￥</label>{{ payInf.order_price }}
          </div>
        </div>
      </div>
      <div class="pay-type-view">
        <div class="paytype-title">支付方式</div>
        <div class="paytype">
          <div
            class="paytype-item"
            @click="changePayType('wxpay')"
            :class="payType == 'wxpay' ? 'item-sl' : ''"
          >
            <img src="@/assets/vip_paytype_wx_img.png" alt="" />
            <span>微信支付</span>
          </div>
          <div
            class="paytype-item"
            @click="changePayType('alipay')"
            :class="payType == 'alipay' ? 'item-sl' : ''"
          >
            <img src="@/assets/vip_paytype_zfb_img.png" alt="" />
            <span>支付宝支付</span>
          </div>
        </div>
      </div>
      <div class="wx-pay-img-view" v-show="payType == 'wxpay'">
        <div class="wxpay-tt1">请使用<label>微信</label>扫描二维码完成支付</div>
        <div class="wxpay-tt2">
          如无法支付或二维码已过期请点击<label>刷新</label>
        </div>
        <img class="wxpay-ewm-img" :src="payInf.pay_img" alt="" />
        <div class="wxpay-tt2">
          购买付费即表示同意<label @click="selectRegisterAgreement('register')"
            >《转店推广服务协议》</label
          >
        </div>
      </div>
      <div v-show="payType == 'alipay'" slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toAliPayPage">去支付</el-button>
      </div>
    </el-dialog>
    <affirm-dialog
      :isopen-affirm="isOpenAffirm"
      :affirm-dialog-close="affirmClose"
      :affirmDialogOk="affirmDialogOk"
      affirmTitle="提示"
      :affirmMsge="affirmMsge"
    >
      <template v-slot:icon><i></i></template>
    </affirm-dialog>
    <choseaddressinmap
      :addAddrDialogShow="choseAddrDialogShow"
      @saveAddress="saveAddress"
      @closeMapPop="claseDia"
      :addressByXY="publishShopObj"
    ></choseaddressinmap>

    <comDialog
      :isopen="isOpenAgreement"
      dialogTetle="协议内容"
      @dialogFrameEvent="agreementEvent"
      myWidth="1280px"
    >
      <div v-html="agreementContent" class="vipServe-content"></div>
    </comDialog>
  </div>
</template>

<script>
import {
  checkHaveTransfer,
  getEdtiTransferDetail,
  getShopTypeClass,
  getShopFacility,
  saveOtherFacility,
  delOtherFacility,
  getShopBq,
  addMoreTags,
  addTransfer,
  editTransfer,
} from "@/api/commen/transferShop.js";
import { getUserProfile } from "@/api/commen/login";
import { getAddressId } from "@/api/commen/addressManageApi.js";
import { gcj02towgs84 } from "@/utils/WSCoordinate.js";
import choseaddressinmap from "@/components/comUser/addAddressDialog.vue";
import { submitOrder } from "@/api/commen/payApi.js";
import AffirmDialog from "@/components/affirmDialog.vue";
import { Loading } from "element-ui";
import comDialog from "@/components/comDialog.vue";
export default {
  components: {
    AffirmDialog,
    choseaddressinmap,
    comDialog,
  },
  data() {
    return {
      isLogin: false,
      loadingInstance: "",
      imgFileList: [],
      imgFileListTmp: [],
      workErpEditShow: false,
      uploadUrl: process.env.VUE_APP_UPLOUD + "/api/upload/uploadCos",
      shopTypeClass: [],
      publishShopObj: {
        id: "",
        type_id: "",
        lat: "",
        lng: "",
        address: "",
        province: "",
        city: "",
        district: "",
        link_man: "",
        link_tel: "",
        typeName: "",
        title: "",
        pics: "",
        video: "",
        area: "",
        rent_type: "",
        transfer_fee: "",
        rent: "",
        peitao: "",
        more_info: "", //
        shop_desc: "",
        salary_type: "",
        card_tally: "",
        month_tally: "",
        mem_num: "",
        rent_type_body: "",
      },
      videoCover: "",
      choseAddrDialogShow: false,
      choseAddrDialogShow2: false,
      payTransfferDialogShow: false,
      shopFacilityArr: [],
      facilityChose: [],
      otherFacilityArr: [],
      shopImgList: [],
      tagArr: [],
      userTagArr: [],
      moreTagChose: [],
      need_pay: 0,
      payType: "wxpay",
      payInf: {},
      isCanSubbmit: true,
      isOpenAffirm: false,
      affirmMsge: "",
      isOpenVideo: false,
      openVideoOrImg: "i",
      currentImg: "",
      isOpenAgreement: false,
      agreementContent: "",
    };
  },
  mounted() {
    let id = this.$route.query.id;
    if (id) {
      console.log("have id");
      this.publishShopObj.id = id;
      this.getEdtiTransferDetail();
    } else {
      console.log("no id");
      this.checkHaveTransfer();
    }
    this.getShopTypeClass();
    this.recoverData();
  },
  created() {
    this.isLogin = localStorage.token ? true : false;
  },
  methods: {
    closeVideo() {
      if (this.openVideoOrImg === "v") {
        const video = this.$refs.videoPlayer;
        if (video) {
          video.pause();
          video.currentTime = 0; // 可选，将视频 currentTime 重置为 0
        }
      }
      this.isOpenVideo = false;
    },
    openVideo(type, url) {
      this.openVideoOrImg = type;
      this.currentImg = url;
      this.isOpenVideo = true;
    },
    delWorkImg(idx) {
      if (idx === "video") {
        this.publishShopObj.video = "";
      } else {
        this.imgFileListTmp.splice(idx, 1);
        console.log(this.imgFileListTmp);
      }
    },
    recoverData() {
      if (this.publishShopObj.id === "") {
        let cacheCreateData = JSON.parse(
          localStorage.getItem("cacheCreateShopData")
        );
        console.log("还原数据:", cacheCreateData);
        if (cacheCreateData) {
          this.publishShopObj = cacheCreateData;
        }
      }
    },
    affirmDialogOk() {
      localStorage.setItem(
        "cacheCreateShopData",
        JSON.stringify(this.publishShopObj)
      );
      this.$router.push("/login");
    },
    affirmClose() {
      this.isOpenAffirm = false;
    },
    closeBuyVipDig() {
      this.$router.go(-1);
    },
    openAddress() {
      console.log("打开弹窗.....");
      this.choseAddrDialogShow = true;
    },
    claseDia() {
      console.log("关闭弹窗.....");
      this.choseAddrDialogShow = false;
    },
    /* 检查有没有转店 */
    checkHaveTransfer() {
      if (localStorage.getItem("token")) {
        checkHaveTransfer()
          .then((result) => {
            console.log("检查有没转店", result);
            if (result.data.transfer_id) {
              this.publishShopObj.id = result.data.transfer_id;
              this.getEdtiTransferDetail();
            } else {
              this.getShopFacility();
              this.getShopBq();
            }
          })
          .catch(() => {});
      }
    },

    /* 获取已有的转店信息 */
    getEdtiTransferDetail() {
      getEdtiTransferDetail({ id: this.publishShopObj.id })
        .then((result) => {
          console.log("获取转店信息", result);
          let imgs = result.data.info.pics;
          if (imgs && imgs.length > 0) {
            for (let i in imgs) {
              let obj = { name: i + ".jpg", url: imgs[i] };
              this.imgFileListTmp.push(obj);
            }
          }
          this.publishShopObj.type_id = result.data.info.type_id;
          this.publishShopObj.lat = result.data.info.lat;
          this.publishShopObj.lng = result.data.info.lng;
          this.publishShopObj.address = result.data.info.address;
          this.publishShopObj.province = result.data.info.province;
          this.publishShopObj.city = result.data.info.city;
          this.publishShopObj.district = result.data.info.district;
          this.publishShopObj.link_man = result.data.info.link_man;
          this.publishShopObj.link_tel = result.data.info.link_tel;
          this.publishShopObj.typeName = result.data.info.type_name;
          this.publishShopObj.title = result.data.info.title;
          // this.publishShopObj.pics= result.data..info
          this.publishShopObj.video = result.data.info.video;
          this.videoCover = result.data.info.video_cover;
          this.publishShopObj.area = result.data.info.area;
          this.publishShopObj.rent_type = result.data.info.rent_type;
          this.publishShopObj.transfer_fee = result.data.info.transfer_fee;
          this.publishShopObj.rent = result.data.info.rent;
          this.publishShopObj.peitao = result.data.info.peitao;
          this.publishShopObj.more_info = result.data.info.more_info;
          this.publishShopObj.shop_desc = result.data.info.shop_desc;
          this.publishShopObj.salary_type = result.data.info.salary_type;
          this.publishShopObj.card_tally = result.data.info.card_tally;
          this.publishShopObj.month_tally = result.data.info.month_tally;
          this.publishShopObj.mem_num = result.data.info.mem_num;
          this.publishShopObj.rent_type_body = result.data.info.rent_type_body;
          this.facilityChose = result.data.info.peitao_arr;
          this.need_pay = result.data.info.pay_status;
          // this.moreTagChose =
          this.getShopFacility();
          this.getShopBq();
        })
        .catch(() => {});
    },
    checkData(type) {
      switch (type) {
        case "phone": {
          let regExp = /^1[3-9]\d{9}$/;
          if (!regExp.test(this.publishShopObj.link_tel)) {
            this.$refs.link_tel_ref.select();
            this.$message.error("请正确输入手机号");
          }
          break;
        }
        case "area": {
          if (isNaN(this.publishShopObj.area)) {
            this.$refs.area_ref.select();
            this.$message.error("请正确输入面积");
          }
          break;
        }
        case "transfer_fee": {
          if (isNaN(this.publishShopObj.transfer_fee)) {
            this.$refs.transfer_fee_ref.select();
            this.$message.error("请正确输入转让费");
          }
          break;
        }
        case "rent": {
          if (isNaN(this.publishShopObj.rent)) {
            this.$refs.rent_ref.select();
            this.$message.error("请正确输入租金");
          }
          break;
        }
      }
    },
    /* 添加转店 */
    submitTransffer() {
      localStorage.removeItem("cacheCreateShopData");
      if (!this.isCanSubbmit) return false;
      if (this.publishShopObj.type_id == "") {
        this.$message.error("请选择店铺类型");
        this.$refs.type_name_ref.focus();
        return false;
      }
      if (this.publishShopObj.address == "") {
        this.$message.error("请选择店铺地址");
        this.$refs.address_ref.focus();
        return false;
      }
      if (this.publishShopObj.link_man == "") {
        this.$message.error("请填写联系人");
        this.$refs.link_man_ref.focus();
        return false;
      }
      if (this.publishShopObj.link_tel == "") {
        this.$message.error("请填写联系电话");
        this.$refs.link_tel_ref.focus();
        return false;
      } else {
        let regExp = /^1[3-9]\d{9}$/;
        let isPhone = regExp.test(this.publishShopObj.link_tel);
        if (!isPhone) {
          this.$message.error("联系电话格式异常");
          this.$refs.link_tel_ref.focus();
          return false;
        }
      }
      if (this.publishShopObj.title == "") {
        this.$message.error("请填写转店标题");
        this.$refs.title_ref.focus();
        return false;
      }
      if (this.publishShopObj.area == "" || isNaN(this.publishShopObj.area)) {
        this.$message.error("请正确填写店铺面积");
        this.$refs.area_ref.focus();
        return false;
      }
      if (
        this.publishShopObj.transfer_fee == "" ||
        isNaN(this.publishShopObj.transfer_fee)
      ) {
        this.$message.error("请正确填写转让费");
        this.$refs.transfer_fee_ref.focus();
        return false;
      }
      if (this.publishShopObj.rent == "" || isNaN(this.publishShopObj.rent)) {
        this.$message.error("请正确填写店铺租金");
        this.$refs.rent_ref.focus();
        return false;
      }
      if (
        !(
          this.publishShopObj.rent_type != "" ||
          this.publishShopObj.rent_type_body != ""
        )
      ) {
        this.$message.error("请选择租金类型");
        this.$refs.rent_type_ref.scrollIntoView();
        return false;
      }
      var ptids = [],
        moreids = [];
      if (this.facilityChose.length > 0) {
        this.facilityChose.forEach((element) => {
          ptids.push(element.id);
        });
        this.publishShopObj.peitao = String(ptids);
      }
      if (this.moreTagChose.length > 0) {
        this.moreTagChose.forEach((element) => {
          moreids.push(element.name);
        });
        this.publishShopObj.more_info = String(moreids).replace(/,/g, "|@|");
      }
      if (this.imgFileListTmp.length > 0) {
        let imgsList = "";
        for (let i in this.imgFileListTmp) {
          if (this.imgFileListTmp[i].isImg === 2) continue;
          imgsList += this.imgFileListTmp[i].url + ",";
        }
        imgsList = imgsList.slice(0, -1);
        this.publishShopObj.pics = imgsList;
      }
      if (this.publishShopObj.id) {
        editTransfer(this.publishShopObj)
          .then((result) => {
            console.log("编辑转店", result);
            this.shopId = result.data.id;
            console.log("zhuang tai :", this.need_pay);
            if (result.code == 200) {
              if (this.need_pay == 0 || this.need_pay == undefined) {
                this.postTransferOrder(result.data.id);
              } else {
                console.log("修改成功");
                this.$router.push("/userhomepage");
              }
            }
          })
          .catch(() => {});
      } else {
        addTransfer(this.publishShopObj)
          .then((result) => {
            console.log("添加转店", result);
            this.shopId = result.data.id;
            if (result.code == 200) {
              console.log("添加成功", result.data.id);
              this.postTransferOrder(result.data.id);
            }
          })
          .catch(() => {});
      }
    },

    /* 转店订单 */
    postTransferOrder(id) {
      if (this.$store.state.user.userinf.ad_login === "1") {
        this.$router.go(-1);
        return false;
      }
      submitOrder({
        pay_type: this.payType,
        type: 27,
        storeId: id,
        rating_id: "0",
      })
        .then((result) => {
          console.log("支付", result);
          this.payInf = result.data;
          this.payTransfferDialogShow = true;
        })
        .catch(() => {});
    },

    changePayType(type) {
      this.payType = type;
      submitOrder({
        pay_type: this.payType,
        type: 27,
        storeId: this.shopId,
        rating_id: "0",
      })
        .then((result) => {
          console.log("提交订单", result);
          if (type == "wxpay") {
            this.payInf = result.data;
          } else {
            this.payInf = result.data;
          }
        })
        .catch(() => {});
    },

    /* 获取店铺类型 */
    getShopTypeClass() {
      getShopTypeClass()
        .then((result) => {
          console.log("获取店铺类型", result);
          this.shopTypeClass = result.data.list;
        })
        .catch(() => {});
    },
    saveAddress(e) {
      console.log("选择地址", e);
      this.publishShopObj.lat = e.y;
      this.publishShopObj.lng = e.x;
      this.publishShopObj.address = e.fulladdr + e.detailedAddress;
      this.choseAddrDialogShow = false;
      this.getAddressId(e);
    },
    //解析省市区id
    getAddressId(e) {
      var latlng = gcj02towgs84(e.x, e.y);
      getAddressId({ lat: latlng[1], lng: latlng[0] })
        .then((result) => {
          console.log("获取省市id", result);
          this.publishShopObj.province = result.data.province_id;
          this.publishShopObj.city = result.data.city_id;
        })
        .catch(() => {});
    },
    /* 店铺配套设施 */
    getShopFacility() {
      getShopFacility()
        .then((result) => {
          console.log("获取配套设施", result);
          result.data.system.forEach((element) => {
            element.ic = element.icon.replace(
              "./",
              `${process.env.VUE_APP_IMG}/`
            );
            element.ic_n = element.icon_no.replace(
              "./",
              `${process.env.VUE_APP_IMG}/`
            );
            element.checked = false;
            if (this.facilityChose.length > 0) {
              this.facilityChose.forEach((i) => {
                // console.log(it.id, i);
                if (element.id == i.id) {
                  element.checked = true;
                }
              });
            }
          });
          result.data.user.forEach((element) => {
            this.facilityChose.push(element);
          });

          this.otherFacilityArr = result.data.user;
          this.shopFacilityArr = result.data.system;
        })
        .catch(() => {});
    },
    choseFacility(item) {
      this.shopFacilityArr.forEach((element) => {
        if (element.id == item.id) {
          if (element.checked) {
            element.checked = false;
            this.facilityChose.forEach((ii, index) => {
              if (ii.id == element.id) {
                this.facilityChose.splice(index, 1);
              }
            });
          } else {
            element.checked = true;
            this.facilityChose.push(element);
          }
        }
      });
    },
    addOtherFaci() {
      let token = localStorage.getItem("token");
      if (!token) {
        this.affirmMsge = "登录状态下才可以添加设备标签,去登录？";
        this.isOpenAffirm = true;
        return;
      }
      this.$prompt("", "添加设备标签", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          saveOtherFacility({ name: value })
            .then((result) => {
              if (result.code == 200) {
                this.$message({
                  message: "添加成功！",
                  type: "success",
                });
              }
              this.getShopFacility();
            })
            .catch(() => {});
        })
        .catch(() => {});
    },
    /* 删除其他配套 */
    delOtherFacility(id) {
      delOtherFacility({ id: id })
        .then((result) => {
          console.log("删除其他", result);
          if (result.code == 200) {
            this.$message({
              message: "删除成功！",
              type: "success",
            });
            this.getShopFacility();
          }
        })
        .catch(() => {});
    },

    /* 获取更多信息标签 */
    getShopBq() {
      getShopBq()
        .then((result) => {
          console.log("获取更多标签", result);
          result.data.system.forEach((element) => {
            element.ischeck = false;
            if (this.publishShopObj.more_info.length > 0) {
              this.publishShopObj.more_info.forEach((i) => {
                if (element.name == i) {
                  element.ischeck = true;
                  this.moreTagChose.push(element);
                }
              });
            }
            // if (this.moreTagChose.length > 0) {
            //   this.moreTagChose.forEach((i) => {
            //     // console.log(it.id, i);
            //     if (element.id == i.id) {
            //       element.ischeck = true;
            //     }
            //   });
            // }
          });
          result.data.user.forEach((element) => {
            element.ischeck = true;
            this.moreTagChose.push(element);
          });
          this.tagArr = result.data.system;
          this.userTagArr = result.data.user;
        })
        .catch(() => {});
    },

    choseMoreTag(item) {
      this.tagArr.forEach((element) => {
        if (element.id == item.id) {
          if (element.ischeck) {
            element.ischeck = false;
            this.moreTagChose.forEach((ii, index) => {
              if (ii.id == element.id) {
                this.moreTagChose.splice(index, 1);
              }
            });
          } else {
            element.ischeck = true;
            this.moreTagChose.push(element);
          }
        }
      });
    },

    choseUserMoreTag(item) {
      this.userTagArr.forEach((element) => {
        if (element.id == item.id) {
          if (element.ischeck) {
            element.ischeck = false;
            this.moreTagChose.forEach((ii, index) => {
              if (ii.id == element.id) {
                this.moreTagChose.splice(index, 1);
              }
            });
          } else {
            element.ischeck = true;
            this.moreTagChose.push(element);
          }
        }
      });
    },
    /* 添加更多标签 */
    addMoreTag() {
      let token = localStorage.getItem("token");
      if (!token) {
        this.affirmMsge = "登录状态下才可以添加标签,去登录？";
        this.isOpenAffirm = true;
        return;
      }
      this.$prompt("", "添加更多信息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          addMoreTags({ name: value })
            .then((result) => {
              console.log("添加更多信息", result);
              if (result.code == 200) {
                this.$message({
                  message: "添加成功！",
                  type: "success",
                });
              }
              this.getShopBq();
            })
            .catch(() => {});
        })
        .catch(() => {});
    },

    choseShopType(e) {
      console.log(e);
      this.publishShopObj.type_id = e;
    },
    choseRentType(type) {
      this.publishShopObj.rent_type = type;
    },
    rentTypeFoc() {
      this.publishShopObj.rent_type = "";
    },
    // 上传前的狗子
    beforeUploadEve(file) {
      this.loadingInstance = Loading.service({ background: "rgba(0,0,0,0.1)" });
      console.log("beforeUploadEve:", file);
      let fileType = file.type;
      let typeArr = ["image/jpeg", "image/png", "video/mp4"];
      if (!typeArr.includes(fileType)) {
        this.$message.error("媒体类型异常");
        return false;
      }
      let astrictNum = 8;
      let isMp4 = file.name.endsWith(".mp4");
      if (isMp4) {
        astrictNum = 50;
      }
      let isLt2M = file.size / 1024 / 1024 > astrictNum;
      if (isLt2M) {
        this.$message.error("媒体超过限制大小8M");
        // return false;
      }
      if (this.imgFileListTmp.length >= 9) {
        console.log("超出限制....");
        this.$message.error("最多可上传9张图片");
        return false;
      }
      if (this.publishShopObj.video && fileType === "video/mp4") {
        this.$message.error("最多可上传一个视频");
        return false;
      }
      this.isCanSubbmit = false;
      return true;
    },
    //上传图片成功回调
    uploadSuccess(e, file) {
      this.$nextTick(() => {
        // 以服务的方式调用的 Loading 需要异步关闭
        this.loadingInstance.close();
      });
      console.log("成功回调", e);
      console.log("成功回调", file);
      if (e.code === 0){
        return this.$message.error(e.msg)
      }
      let url = e.data.httpCosUrl;
      let isImg = 1;
      let fileName = Date.now() + ".jpg";
      if (url.endsWith(".mp4")) {
        isImg = 2;
        this.videoCover = e.data.httpsCover;
        this.publishShopObj.video = e.data.cosUrl;
      } else {
        let obj = { name: fileName, url: url, isImg: isImg, uid: file.uid };
        this.imgFileListTmp.push(obj);
      }
      this.isCanSubbmit = true;
    },
    //删除图片
    handleRemove(e) {
      this.$nextTick(() => {
        this.loadingInstance.close();
      });
      console.log("del:", e);
      this.imgFileListTmp = this.imgFileListTmp.filter((item) => {
        if (item.uid !== e.uid) {
          return item;
        } else {
          console.log("匹配到要删除的数据：", item);
          if (item.isImg === 2) {
            this.publishShopObj.video = "";
          }
        }
      });
      console.log(this.imgFileListTmp);
    },
    toAliPayPage() {
      window.open(this.payInf.url, "_blank");
    },

    handleUploadProgress(event, file, fileList) {
      console.log("a", event);
      console.log("b", file);
      console.log("c", fileList);
      // 计算上传进度
      const progress = Math.ceil((event.loaded / event.total) * 100);
      // 将进度赋给进度条的 percentage 属性
      file.percentage = progress;
      // 如果不使用 file.percentage 属性，而是使用 fileList 的方式更新进度条，请参考下面的代码
      // const index = fileList.findIndex(item => item.uid === file.uid);
      // fileList[index].percentage = progress;
      // 强制更新 DOM
      this.$forceUpdate();
    },

    selectRegisterAgreement(from) {
      if (from === "register") {
        getUserProfile("/profile").then((res) => {
          this.agreementContent = res.data;
        });
      } else if (from === "privacy") {
        getUserProfile("/privacy").then((res) => {
          this.agreementContent = res.data;
        });
      } else {
        return false;
      }
      this.isOpenAgreement = true;
    },
    agreementEvent() {
      this.isOpenAgreement = false;
    },
  },
};
</script>

<style>
.publish-transffer-view .el-dialog__wrapper .el-dialog {
  border-radius: 20px;
}

.publish-transffer-view {
  /* padding: 0 345px; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.add-transffer-view {
}

.add-transffer-view .top-titel-text-view {
  width: 1231px;
  height: 93px;
  background-color: #fff;
  margin-top: 40px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.add-transffer-view .top-titel-text-view span {
  display: inline-block;
  font-size: 32px;

  font-weight: 600;
  color: #222222;
  line-height: 38px;
  margin-left: 20px;
  margin-top: 24px;
}

.transffer-edt-inf-view {
  width: 1195px;
  background-color: #f5f6fa;
  padding: 40px 18px;
}

.transffer-edt-inf-view .release-ms-title {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.transffer-edt-inf-view .release-ms-title .left-xian {
  width: 4px;
  height: 20px;
  background-color: #fe6002;
  border-radius: 3px;
}

.transffer-edt-inf-view .release-ms-title .title {
  font-size: 22px;

  font-weight: 600;
  color: #222222;
  margin-left: 10px;
}

.transffer-edt-inf-view .edi-lin-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.transffer-edt-inf-view .edi-lin-item .items {
  width: 574px;
  /* margin-right: 78px; */
  position: relative;
}

.el-autocomplete {
  width: 100%;
}

/*.transffer-edt-inf-view .edi-lin-item .items*/
.title-store {
  font-size: 16px;

  font-weight: 400;
  color: #757575;
  line-height: 30px;
  /*margin-top: 24px;*/
}

.transffer-edt-inf-view .edi-lin-item .sl-clik-view {
  display: flex;
}

.transffer-edt-inf-view .edi-lin-item .sl-clik-view .cl-change-item {
  width: 574px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #e7e8eb;
  font-size: 16px;

  font-weight: 400;
  color: #757575;
}

.transffer-edt-inf-view .edi-lin-item .items .el-input.el-input--suffix {
  width: 574px;
  /* height: 46px; */
}

.transffer-edt-inf-view .edi-one-item .items .zd-ingp-view {
  width: 100%;
  height: 46px;
  /* border: 1px solid #000; */
  position: absolute;
  top: 30px;
}

.transffer-edt-inf-view .edi-lin-item .items .zd-ingp-view {
  width: 100%;
  height: 46px;
  /* border: 1px solid #000; */
  position: absolute;
  top: 30px;
}

.transffer-edt-inf-view .edi-lin-item .el-input-group__append {
  background-color: #fff;
  font-size: 18px;

  font-weight: 400;
  color: #191919;
  line-height: 24px;
}

.transffer-edt-inf-view .edi-lin-item .items .sl-group-view {
  display: flex;
  align-items: center;
}

.textarea-view {
  margin-top: 16px;
}

.transffer-edt-inf-view .edi-one-item .textarea-view .textarea-bot-view {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.transffer-edt-inf-view .edi-one-item .textarea-view .textarea-bot-view .tt1 {
  font-size: 16px;

  font-weight: 400;
  color: #b1b1b1;
}

.transffer-edt-inf-view
  .edi-one-item
  .textarea-view
  .textarea-bot-view
  .tt1
  label {
  color: #f34b37;
}

.transffer-edt-inf-view .edi-one-item .textarea-view .textarea-bot-view .tt2 {
  font-size: 16px;

  font-weight: 400;
  color: #757575;
  line-height: 19px;
}

/* .transffer-edt-inf-view .edi-one-item .textarea-view .el-input__inner {
  border: none;
} */
.transffer-edt-inf-view .edi-one-item .textarea-view .el-textarea__inner {
  border: 0;
  resize: none;
}

.transffer-edt-inf-view .release-ms-title {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.transffer-edt-inf-view .release-ms-title .left-xian {
  width: 4px;
  height: 20px;
  background-color: #fe6002;
  border-radius: 3px;
}

.transffer-edt-inf-view .release-ms-title .title {
  font-size: 22px;

  font-weight: 600;
  color: #222222;
  margin-left: 10px;
}

.transffer-edt-inf-view .el-checkbox {
  margin-top: 5px;
}

.transffer-edt-inf-view .el-checkbox__inner {
  border-radius: 50%;
}

.transffer-edt-inf-view .choseview {
  position: relative;
}

.transffer-edt-inf-view .choseview .tt {
  font-size: 14px;
  color: #606266;
}

.transffer-edt-inf-view .choseview .choseview-input-view {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.transffer-edt-inf-view .choseview .choseview-input-view .ch-item {
  display: block;
  width: 249px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #e4e7ed;
  text-align: center;
  background-color: #fff;
  color: #303030;
  line-height: 40px;
}

/* .release-job-view .choseview .choseview-input-view .ch-item:first-child {
  margin-right: 58px;
} */

.transffer-edt-inf-view .choseview .choseview-input-view .sl-sex {
  background-color: #fff5f0;
  color: #fe6002;
  border: 1px solid #fe6002;
}

.transffer-edt-inf-view .choseview .el-input__inner {
  display: block;
  width: 249px;
  height: 40px;
}

.second-title-view {
  font-size: 20px;

  font-weight: 600;
  color: #191919;
  line-height: 28px;
  margin-top: 40px;
}

.second-title-view .ttt1 {
  font-size: 22px;

  font-weight: 600;
  color: #222222;
  margin-left: 10px;
}

.second-title-view .ttt2 {
  font-size: 16px;

  font-weight: 400;
  color: #757575;
  line-height: 19px;
}

.second-title-view .ttt3 {
  font-size: 16px;

  font-weight: 400;
  color: #f34b37;
  line-height: 19px;
}

.third-title-view {
  font-size: 16px;

  font-weight: 600;
  color: #191919;
  line-height: 19px;
  margin-top: 10px;
}

.third-title-view .ttt2 {
  font-size: 16px;

  font-weight: 400;
  color: #f34b37;
  line-height: 19px;
}

.sl-ptss-view {
  margin-top: 16px;
  width: 100%;
}

.sl-ptss-view .pt-item {
  display: inline-flex;
  width: 80px;
  height: 70px;
  background-color: #fff;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin-bottom: 16px;
  cursor: pointer;
}

.sl-ptss-view .pt-item img {
  width: 40px;
  height: 40px;
}

.sl-ptss-view .pt-item .pt-text {
  font-size: 14px;

  font-weight: 400;
  color: #303030;
  line-height: 16px;
}

.sl-ptss-view .pt-item-sl {
  background-color: #f6dfd5;
  border: 1px solid #fe6002;
}

.sl-ptss-view .pt-item-sl .pt-text {
  color: #fe6002;
}

.sl-ptss-view .items-fg-kong {
  display: inline-block;
  width: 40px;
}

.add-other-pt-btn {
  margin-top: 16px;
}

.add-other-pt-btn .ot-pt-view {
  padding: 6px 8px;
  background-color: #ff8136;
  color: #fffdfc;
  font-size: 18px;

  font-weight: 400;
  color: #fffdfc;
  line-height: 24px;
  border-radius: 4px;
  margin-right: 16px;
}

.other-inf-view {
  margin-top: 2px;
}

.other-inf-view .other-it {
  display: inline-block;
  background-color: #fff;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 16px;

  font-weight: 400;
  color: #303030;
  margin-top: 22px;
  margin-right: 22px;
  cursor: pointer;
}

.other-inf-view .other-it-sl {
  background-color: #f6dfd5;
  border: 1px solid #fe6002;
  color: #fe6002;
}

.bot-submit-btn {
  width: 100%;
  text-align: center;
  margin-top: 80px;
}

.bot-submit-btn .el-button--primary {
  width: 400px;
  height: 52px;
  font-size: 18px;
}

.transffer-pay-dialog-css .el-dialog {
  width: 524px;
}

.stro-img-up .el-progress--circle {
  width: 30px;
  height: 30px;
}

.stro-img-up .el-upload-list__item-actions {
  width: 30px;
  height: 30px;
}
/*上传图片Class*/
.store-erp-view {
  margin-top: 30px;
}
.store-erp-view .com-work-img {
  display: inline;
  position: relative;
}
.store-erp-view .com-work-img img {
  width: 140px;
  height: 140px;
  margin-right: 20px;
  margin-bottom: 20px;
}
.store-erp-view .com-work-img .colse-img {
  width: 28px;
  height: 28px;
  position: absolute;
  top: -66px;
  right: 0;
  cursor: pointer;
}
.store-erp-view .el-upload-view {
  display: inline;
}
.store-erp-view .el-upload-view {
  display: inline;
}
.publish-transffer-view .video-cover-img {
  position: absolute;
  top: -28px;
  left: 40px;
  cursor: pointer;
}
.publish-transffer-view .video-open .el-dialog {
  width: 100px;
  background-color: transparent;
}
.publish-transffer-view .video-open .el-dialog .el-dialog__header {
  display: none;
}

.publish-transffer-view .vipServe-content {
  border-top: 2px solid rgba(230, 230, 230, 0.5);
  padding: 50px 50px 80px;
}
</style>