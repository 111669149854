<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2024-02-20 16:31:14
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-02-20 17:18:07
 * @FilePath: /lcwpcvue/src/views/registRelJob.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="com-regist">
    <pageheader class="head-view"></pageheader>
    <registreleasejob class="topview"></registreleasejob>
  </div>
</template>

<script>
import pageheader from "@/components/pageHeader.vue";
import registreleasejob from "@/components/comUser/registReleaseJob.vue";
export default {
  components: {
    registreleasejob,
    pageheader,
  },
};
</script>

<style>
</style>