<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2024-02-26 17:54:26
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-03-20 11:09:35
 * @FilePath: /lcwpcvue/src/views/userRegistBasInf.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="com-regist">
    <userbasInf @saveBaseInf="saveBaseInf" class="wxsc-view"></userbasInf>
  </div>
</template>

<script>
import userbasInf from "@/components/individualUser/userBasInf.vue";
export default {
  components: {
    userbasInf,
  },
  methods: {
    saveBaseInf() {
      this.$router.push("/addjobyx");
      this.$store.dispatch("user/setInfo").then(() => {
        // location.reload();
      });
    },
  },
};
</script>

<style>
</style>