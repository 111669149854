<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-07-12 16:19:48
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-06-12 10:57:23
 * @FilePath: /lcwpcvue/src/components/headNavMen.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="head-div">
    <div class="hed-nav">
      <div style="display: flex;justify-content: flex-start">
        <img class="logo-img hand-shape" src="@/assets/logob.png" alt="" @click="toHomePage" />
        <div class="navmen">
          <el-menu
              :default-active="privateIdx"
              class="el-menu-demo"
              mode="horizontal"
              background-color="#212329"
              text-color="#fff"
              active-text-color="#FE6002"
              @select="handleSelect"
              router
          >
            <el-menu-item style="border-bottom: none" index="/home"
            ><span class="navtext">首页</span></el-menu-item
            >

            <el-menu-item
                v-show="isLogin && usertype == 1"
                style="border-bottom: none"
                index="/findjob"
            ><span class="navtext">找工作</span></el-menu-item
            >
            <el-menu-item
                v-show="isLogin && usertype == 2"
                style="border-bottom: none"
                index="/findtalentslist"
            ><span class="navtext">招人才</span></el-menu-item
            >
            <el-menu-item
                v-show="!isLogin"
                style="border-bottom: none"
                index="/findjob"
            ><span class="navtext">找工作</span></el-menu-item
            >
            <el-menu-item
                v-show="!isLogin"
                style="border-bottom: none"
                index="/findtalentslist"
            ><span class="navtext">招人才</span></el-menu-item
            >
            <el-menu-item
                v-show="isLogin && usertype == 1"
                style="border-bottom: none"
                index="/parttimejoblist"
            ><span class="navtext">兼职</span></el-menu-item
            >
            <el-menu-item
                v-show="!isLogin"
                style="border-bottom: none"
                index="/parttimejoblist"
            ><span class="navtext">兼职</span></el-menu-item
            >
            <el-menu-item style="border-bottom: none" index="/transfershoplist"
            ><span class="navtext">转店</span></el-menu-item
            >
            <el-menu-item style="border-bottom: none" index="/takeshoplist"
            ><span class="navtext">接店</span></el-menu-item
            >
            <el-menu-item style="border-bottom: none" index="/informationlist"
            ><span class="navtext">资讯</span></el-menu-item
            >
          </el-menu>
        </div>
      </div>
      <div style="display: flex;justify-content: flex-end">
        <label v-show="!isLogin">
          <div class="chutype">
            <span class="hand-shape" @click="chageUserType('2')">发布职位</span>
            <el-divider direction="vertical"></el-divider>
            <span class="hand-shape" @click="chageUserType('1')">寻找工作</span>
          </div>
        </label>
        <label v-show="isLogin">
          <div class="chutype">
            <span class="hand-shape" v-show="usertype == 2" @click="toReleaseJob">发布职位</span>
            <span class="hand-shape" v-show="usertype == 1" @click="toLookJobs">寻找工作</span>
          </div>
        </label>

        <div class="msg-ld-view" @click="toChatPage">
          <el-badge
              :hidden="msgNumber == 0"
              :value="msgNumber"
              :max="99"
              class="msg-badge"
          >
            <img class="ld-img" src="../assets/nav_msg_img.png" alt="" />
          </el-badge>
        </div>

        <div class="logbtn" v-show="!isLogin">
          <input type="button" value="登录/注册" @click="toLogin" />
        </div>
        <el-dropdown @command="handleCommand" v-show="usertype == 1">
          <div class="user-head-img" v-show="isLogin">
            <span class="user-name">{{ userInf.userName }}</span>
            <img :src="userInf.userAvatar" alt="" class="user-avatar-img" />
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="/userhomepage">个人中心</el-dropdown-item>
            <el-dropdown-item command="/useredtresume">我的简历</el-dropdown-item>
            <el-dropdown-item command="/jobInformation">我的求职</el-dropdown-item>
            <el-dropdown-item command="/setpage">账户安全</el-dropdown-item>
            <el-dropdown-item command="/privacy">隐私设置</el-dropdown-item>
            <el-dropdown-item command="login">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown @command="handleCommand" v-show="usertype == 2">
          <div class="user-head-img" v-show="isLogin">
            <span class="user-name">{{ userInf.userName }}</span>
            <img :src="userInf.userAvatar" alt="" class="user-avatar-img" />
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="userhomepage">
              <div class="dropdown-fist-item-view">
                <img
                    class="dropdown-user-avatar"
                    :src="userInf.userAvatar"
                    alt=""
                />
                <div class="text-view">
                  <div class="tt1">
                    公司主页
                    <i class="el-icon-arrow-right"></i>
                  </div>
                  <div class="tt2">{{ userInf.userName }}</div>
                </div>
              </div></el-dropdown-item
            >
            <el-dropdown-item command="/setpage">
              <div class="normal-item">
                <img src="../assets/company_set_img.webp" alt="" />
                <span>账号设置</span>
              </div>
            </el-dropdown-item>
            <el-dropdown-item command="/myequitypage">
              <div class="normal-item">
                <img src="../assets/company_wdqy_img.webp" alt="" />
                <span>我的权益</span>
              </div>
            </el-dropdown-item>
            <el-dropdown-item command="/addressmanage">
              <div class="normal-item">
                <img src="../assets/company_dzgl_img.webp" alt="" />
                <span>地址管理</span>
              </div>
            </el-dropdown-item>
            <el-dropdown-item command="/mycouponpage">
              <div class="normal-item">
                <img src="../assets/company_yhq_img.webp" alt="" />
                <span>优惠券</span>
              </div>
            </el-dropdown-item>
            <el-dropdown-item command="login">
              <div class="normal-item">
                <img src="../assets/company_logout_img.webp" alt="" />
                <span>退出登录</span>
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
export default {
  name: "navmen",
  props: {
    activeIndex: {
      type: String,
      default: "/home",
    },
  },
  data() {
    return {
      usertype: 0,
      isLogin: false,
      userInf: {
        userName: "",
        userAvatar: "",
      },
      msgNumber: 0,
      choseUserTypeShow: false,
      mouse1: false,
      mouse2: false,
      privateIdx: this.activeIndex,
    };
  },
  created() {
    this.isLogin = localStorage.token ? true : false;
    console.log('看看缓存的用户类型是几',localStorage.usertype);
    this.usertype = localStorage.usertype;
    this.msgNumber = localStorage.wdchatnum;
    if (this.isLogin) {
      store.dispatch("user/setInfo").then(() => {
        console.log("aaaaa", store.getters.name);
        // location.reload();
        this.userInf.userName = store.getters.name;
        this.userInf.userAvatar = store.getters.avatar;
      });
    }
    this.$store.dispatch('user/setToken',localStorage.token);
    this.$store.watch((state) => {
      // console.log("路由变化", state.user.headnavmenindex);
      this.userInf.userName = state.user.name;
      this.userInf.userAvatar = state.user.avatar;
      this.msgNumber = state.user.wdmsgnumber;
      this.privateIdx = state.user.headnavmenindex;
      
      // console.log(this.msgNumber, state.user.wdmsgnumber);
    });
  },
  watch: {
    // $route(to) {
    //   console.log(to);
    //   // this.privateIdx = to.path;
    // },
  },
  methods: {
    handleSelect(e) {
      // console.log(e);
      this.privateIdx = e;
      // this.$router.push(e);
    },
    chageUserType(e) {
      localStorage.removeItem("token");
      this.$store.dispatch("user/setUserType", e);
      this.$cookies.set("usertype", e);
      localStorage.setItem("usertype", e);
      this.$router.push("/login");
    },

    toComEdtPage() {
      this.$router.push("/companyinfdetail");
    },
    toReleaseJob() {
      this.$router.push("/releasejob");
    },
    toLookJobs() {
      this.$router.push("/findjob");
    },

    handleCommand(e) {
      // console.log(e);
      if (e == "login") {
        store.dispatch("user/resetToken").then(() => {
          this.$router.push("/home");
          location.reload();
        });
      } else {
        this.$router.push(e);
      }
    },
    toLogin() {
      // this.$router.push("/login");
      // this.choseUserTypeShow = true;
      this.$emit("showChoseType");
    },
    choseUserTypeClose() {
      this.choseUserTypeShow = false;
    },
    toHomePage() {
      this.$router.push("/home");
    },
    toChatPage() {
      this.$router.push("/chatpage");
    },

    setUserType(type) {
      // this.$store.dispatch("setIsAutnenticated", !this.isEmpty(decoded));
      // this.$store.dispatch("setUser", decoded)
      // console.log(store.dispatch());
      this.$store.dispatch("user/setUserType", type);
      this.$cookies.set("usertype", type);
      localStorage.setItem("usertype", type);
      // console.log("----", this.$store.state);
      // this.$store.state.user;
      this.changeUserTypeDialogVisible = false;
      this.$router.push("/login");
    },

    mousov1() {
      this.mouse1 = true;
    },
    mousov2() {
      this.mouse2 = true;
    },
    mousle1() {
      this.mouse1 = false;
    },
    mousle2() {
      this.mouse2 = false;
    },
  },
};
</script>

<style>
a {
  display: inline-block;
}
.head-div{
  width: 100%;
  height: 70px;
  background-color: #212329;
  position: fixed;
  top: 0;
  z-index: 999;
}
.hed-nav {
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  overflow: hidden;
  padding: 5px 0;
  width: 1475px;
  margin: 0 auto;
}

.logo-img {
  width: auto;
  height: 54px;
  margin-top: 2px;
  margin-right: 10px;
}

.web-name {
  color: #ffffff;
  line-height: 28px;
  margin-left: -80px;
  margin-right: 60px;
  cursor: pointer;
}

.web-name .ttt1 {
  
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
}
.web-name .ttt2 {
  
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
}
.navmen {
}
ul.el-menu-demo {
  display: flex;
  white-space: nowrap;
  overflow: hidden;
}
ul.el-menu-demo.el-menu--horizontal.el-menu {
  border-bottom: none;
  font-size: 20px;
  
  font-weight: 600;
}

.navtext {
  border-bottom: none;
  font-size: 20px;
  
  font-weight: 600;
  line-height: 28px;
}

.chutype {
  font-size: 20px;
  
  font-weight: 600;
  color: #ffffff;
  line-height: 60px;
  /*margin-left: 325px;*/
}

.chutypelogin {
  /*margin-left: 250px;*/
}

.logbtn {
  margin-left: 60px;
  margin-top: 12px;
}
.logbtn input {
  width: 120px;
  height: 36px;
  background-color: #212329;
  border: 2px solid #fe6002;
  border-radius: 8px;
  font-size: 20px;
  
  font-weight: 600;
  color: #ffffff;
  line-height: 30px;
}

.msg-ld-view {
  margin-left: 60px;
  margin-top: 15px;
}

.msg-ld-view .ld-img {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.user-head-img {
  display: flex;
  align-items: center;
  margin-left: 61px;
  margin-top: 10px;
  cursor: pointer;
}

.user-head-img .user-avatar-img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-left: 16px;
}
.user-head-img .user-name {
  font-size: 18px;
  
  font-weight: 500;
  color: #fe6002;
  line-height: 21px;
}
.dropdown-fist-item-view {
  display: flex;
  align-items: center;
  background-color: #fff9f0;
  border-radius: 8px;
  padding: 12px 16px;
  position: relative;
}
.dropdown-fist-item-view .dropdown-user-avatar {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  border: 1px solid#E2C07C;
}

.dropdown-fist-item-view .text-view {
  margin-left: 10px;
}

.dropdown-fist-item-view .text-view .tt1 {
  font-size: 14px;
  
  font-weight: 400;
  color: #7a5536;
  line-height: 16px;
  width: 300px;
  text-align: right;
}
.dropdown-fist-item-view .text-view .tt2 {
  font-size: 16px;
  
  font-weight: 600;
  color: #7a5536;
  line-height: 19px;
  margin-top: 10px;
}
.normal-item {
  display: flex;
  align-items: center;
  padding: 18px 16px;
}
.normal-item img {
  width: 24px;
  height: 24px;
}
.normal-item span {
  font-size: 18px;
  
  font-weight: 400;
  color: #666666;
  line-height: 24px;
  margin-left: 10px;
}

.dial {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 0;
}
.type-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  width: 320px;
  height: 375px;
  border: 1px solid#FF8136;
  box-shadow: 0px 8px 16px -1px rgba(175, 180, 197, 0.16);
  border-radius: 8px;
}

.type-item img {
  width: 264px;
  height: 235px;
}

.type-item .bot-btn {
  width: 135px;
  height: 45px;
  font-size: 20px;
  
  font-weight: 500;
  color: #ffffff;
  border: none;
  margin-top: 51px;
}
.type-item .bt1 {
  background: linear-gradient(132deg, #ffbe4d 0%, #ff8942 100%);
  border-radius: 4px;
}

.type-item .bt2 {
  background: linear-gradient(133deg, #7cc3ff 0%, #5da5ff 100%);
  border-radius: 4px;
}

.heover {
  background-color: #fff5f0;
}
.head-div .hand-shape{
  cursor: pointer;
}
</style>