<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2024-03-19 11:55:30
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-03-19 16:37:13
 * @FilePath: /lcwpcvue/src/views/jobReport.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="user-home-page">
    <div class="top-zk-div"></div>
    <userinfleftmen
      :activeIndex="'/jobreport'"
      class="left-menu"
    ></userinfleftmen>
    <jobreportcompon class="userinf-index"></jobreportcompon>
  </div>
</template> 

<script>
import userinfleftmen from "@/components/userInfPageLeftMenu.vue";
import jobreportcompon from "@/components/individualUser/jobReportCompon.vue";

export default {
  components: {
    userinfleftmen,
    jobreportcompon,
  },
};
</script>

<style>
</style>