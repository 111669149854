<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2024-01-02 15:48:57
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-01-02 18:03:41
 * @FilePath: /lcwpcvue/src/views/wxScGdPage.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div></div>
</template>

<script>
import { Loading } from "element-ui";
import { userLoginByWxEwm } from "@/api/commen/login";
export default {
  data() {
    return {
      loading: null,
      code: "",
      usertype: 1,
    };
  },
  created() {
    console.log(this.$cookies.get("usertype"));
    this.code = this.$route.query.code;

    this.loading = Loading.service({
      text: "登录中...",
      background: "rgba(0,0,0,0.7)",
      lock: true,
    });
    this.userLoginByWxEwm();
  },
  methods: {
    userLoginByWxEwm() {
      userLoginByWxEwm({
        code: this.code,
        usertype: this.$cookies.get("usertype"),
      })
        .then((result) => {
          console.log("二维码登录", result);
          if (result.code == 200) {
            // uni.setStorageSync("token", logininf.member.token);
            // uni.setStorageSync("uid", logininf.member.uid);
            this.$store.dispatch("user/setToken", result.data.token);
            this.$store.dispatch("user/setUserUid", result.data.member.uid);
            this.$cookies.set("token", result.data.token, "1d");
            localStorage.setItem("token", result.data.token);
            // this.$store.dispatch("user/setInfo", val.data);
            // this.$store.dispatch("user/setUserUid", val.data.member.uid);
            // this.$store.dispatch("user/setUserUid", val.data.member.uid);
          }

          if (this.$cookies.get("usertype") == 1) {
            console.log(result.data.login_check);
            if (result.data.login_check == "") {
              // 个人信息
              this.showType = 2;
            } else if (result.data.login_check.is_resume == "0") {
              this.showType = 2;
            } else if (result.data.login_check.is_expect == "0") {
              this.showType = 3;
            } else {
              // this.$refs;
              console.log("tiaotiaojfladjflidjfidaj");
              this.$nextTick(() => {
                this.$router.push("/home");
              });
            }
          } else if (this.$cookies.get("usertype") == 2) {
            // console.log("ppp");
            if (result.data.login_check.length == 0) {
              // 公司信息
              console.log("走的是这里111");
            } else if (result.data.login_check.is_company == "0") {
              // uni.navigateTo({
              // 	url: `/pagesA/user2/info/info?firstlogin=1`
              // 	// url: '/pagesA/comAddJob/comAddJob?firstlogin=1'
              // })
            } else if (result.data.login_check.com_cert == "0") {
              // 营业执照
              // console.log('走的是这里222');
              // uni.navigateTo({
              // 	// url: `/pagesA/user2/info/info?firstlogin=1`
              // 	url: '/pagesA/comAddJob/comAddJob?firstlogin=1'
              // })
            } else {
              // 登录成功
              this.$router.push("/index");
            }
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style>
</style>