<template>
  <div class="null-page">
    <div>
      <img v-if="type == 1" src="@/assets/coupon_none_list.png" alt="">
      <img v-else src="@/assets/transfer_is_null.png" alt="">
      <div class="null-page-title">{{title}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "nullPage",
  props:['type','title'],
  data(){
    return{

    }
  },
  created() {

  }
}

</script>

<style scoped>
  .null-page img{
    width: 208px;
    height: auto;
  }
  .null-page{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .null-page .null-page-title{
    font-weight: 400;
    font-size: 18px;
    color: #939597;
    line-height: 24px;
    text-align: center;
    margin-top: 12px;
  }
</style>