<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2024-02-19 14:41:11
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-05-16 16:22:00
 * @FilePath: /lcwpcvue/src/components/comUser/businessAuthDialog.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="business-auth-dialog">
    <registhead></registhead>
    <steppro :stepnub="2"></steppro>
    <div class="auth-tips-view">
      <i class="el-icon-warning"></i>
      <span
        >请确保提交认证资料的真实，账号仅限本人使用，否则你可能将承担相应的违法违规的后果</span
      >
    </div>

    <div v-show="isShowYyzz === true" class="business-auth-inf">
      <div class="ipn-title">公司全称</div>
      <div class="input-view">
        <el-autocomplete
          v-model="comName"
          :fetch-suggestions="remoteMethod"
          placeholder="请输入内容"
          @select="comNameHandleSelect"
          @input="clearInput()"
          :trigger-on-focus="false"
        >
<!--          <span slot="suffix" class="right-text">
            修改
            <i class="el-icon-arrow-right"></i>
          </span>-->
        </el-autocomplete>
      </div>
      <div class="up-img-view">
        <el-upload
          class="avatar-uploader"
          :action="updateUrl"
          :show-file-list="false"
          :on-success="yyzzAvatarSuccess"
        >
          <img
            v-if="!cerObj.cert"
            class="up-img"
            src="@/assets/auth_up_zz_img.png"
            alt=""
          />
          <img v-if="cerObj.cert" class="up-img" :src="cerObj.cert" alt="" />
        </el-upload>
      </div>
      <div class="zysx-text">
        <div class="tt1">注意事项</div>
        <div class="tt2">1、请确认营业执照与公司<span>全称一致</span></div>
        <div class="tt2">2、请上传<span>完整清晰</span>的营业执照照片</div>
      </div>

      <div class="bot-btn-view">
        <el-button @click="goBackPage">上一步</el-button>
        <el-button type="primary" @click="submitCer">下一步</el-button>
      </div>
    </div>
    <div v-show="isShowZlxkz === true" class="business-auth-inf">
      <div class="up-img-view">
        <el-upload
          class="avatar-uploader"
          :action="updateUrl"
          :show-file-list="false"
          :on-success="zlxkAvatarSuccess"
        >
          <img
            v-if="!zlxkz"
            class="up-img"
            src="@/assets/auth_com_zlxk.png"
            alt=""
          />
          <img v-else class="up-img" :src="zlxkz" alt="" />
        </el-upload>
      </div>
      <div class="zyst-tgbtn-view">
        <div class="zysx-text">
          <div class="tt1">注意事项</div>
          <div class="tt2">1、请确认诊疗许可证与公司<span>全称一致</span></div>
          <div class="tt2">2、请上传<span>完整清晰</span>的诊疗许可证图片</div>
        </div>
        <div class="tg-btn">
          <el-button type="text" @click="toReleaseJob">跳过>></el-button>
        </div>
      </div>

      <div class="bot-btn-view">
        <el-button @click="goBackPage">上一步</el-button>
        <el-button type="primary" @click="submitZlxkz">下一步</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import registhead from "@/components/comUser/registHead.vue";
import steppro from "@/components/comUser/stepCom.vue";
import {
  getCompanyInf,
  getCertStatus,
  updateCertImg,
  updateZlxkImg,
} from "@/api/compe/companyInf.js";
import requestByNotLoading from "@/utils/requestByNotLoading";

export default {
  components: {
    registhead,
    steppro,
  },
  data() {
    return {
      isShowYyzz: true,
      isShowZlxkz: false,
      updateUrl: process.env.VUE_APP_UPLOUD + "/api/upload/uploadCos",
      comName: "",
      company: {},
      hyClass: 0,
      zlxkz: "",
      cerObj: {
        cert: "",
        obj: "{}",
      },
      compList: [],
    };
  },
  created() {
    this.getCompanyInf();
    this.getCertStatus();
    let imgCache = sessionStorage.getItem("businessAuthImg");
    if (imgCache) {
      let imgArr = JSON.parse(imgCache);
      this.zlxkz = imgArr.zlxhz;
      this.cerObj.cert = imgArr.yyzz;
    }
  },
  methods: {
    clearInput() {
      this.cerObj.obj = {};
    },
    remoteMethod(e, cb) {
      console.log("remoteMethod:", e);
      cb([]);
      if (e !== "" && e.length > 1) {
        requestByNotLoading({
          url: "company/comParamsByQcc",
          method: "post",
          data: { kw: e },
        }).then((res) => {
          if (res.data.Result.length > 0) {
            res.data.Result.forEach((element) => {
              element.value = element.Name;
            });
            this.compList = res.data.Result;
            cb(this.compList);
          }
        });
      }
    },
    comNameHandleSelect(item) {
      console.log("comNameHandleSelect:", item);
      this.cerObj.name = item.name = item.Name;
      delete item.value;
      delete item.Name;
      this.cerObj.obj = item;
    },
    //获取公司信息
    getCompanyInf() {
      getCompanyInf()
        .then((result) => {
          console.log("公司信息", result);
          this.comName = result.data.info.name;
          this.hyClass = result.data.info.hy;
          this.company = result.data;
          if (this.company.info.business_obj) {
            this.cerObj.obj = this.company.info.business_obj;
            this.cerObj.obj.KeyNo = this.cerObj.obj.key_no;
            this.cerObj.obj.OperName = this.cerObj.obj.oper_name;
            this.cerObj.obj.CreditCode = this.cerObj.obj.credit_code;
            this.cerObj.obj.StartDate = this.cerObj.obj.start_date;
            this.cerObj.obj.Address = this.cerObj.obj.address;
            this.cerObj.obj.No = this.cerObj.obj.no;
            this.cerObj.obj.Status = this.cerObj.obj.status;
            delete this.cerObj.obj.key_no;
            delete this.cerObj.obj.oper_name;
            delete this.cerObj.obj.credit_code;
            delete this.cerObj.obj.start_date;
            delete this.cerObj.obj.id;
            delete this.cerObj.obj.address;
            delete this.cerObj.obj.no;
            delete this.cerObj.obj.status;
          }
        })
        .catch(() => {});
    },
    //获取公司执照审核信息
    getCertStatus() {
      getCertStatus()
        .then((result) => {
          console.log("审核信息", result);
        })
        .catch(() => {});
    },
    zlxkAvatarSuccess(e) {
      if (e.code === 0){
        return this.$message.error(e.msg)
      }
      this.zlxkz = e.data.httpCosUrl;
    },
    yyzzAvatarSuccess(e) {
      if (e.code === 0){
        return this.$message.error(e.msg)
      }
      this.cerObj.cert = e.data.httpCosUrl;
    },
    //提交诊疗许可
    submitZlxkz() {
      console.log("ti jiao z l x k");
      if (this.zlxkz === "") return this.$message.error("请上传诊疗许可证");
      updateZlxkImg({ pic: this.zlxkz })
        .then((res) => {
          this.$message.success(res.msg);
          this.$router.push("/registreljob");
        })
        .catch(() => {});
    },
    //提交
    submitCer() {
      if (this.cerObj.cert === "") return this.$message.error("请上传营业执照");
      this.cerObj.name = this.comName;
      this.cerObj.from = "register";
      if (this.cerObj.obj) {
        this.cerObj.obj = JSON.stringify(this.cerObj.obj);
      }
      let imgPath = {
        zlxhz: this.zlxkz,
        yyzz: this.cerObj.cert,
      };
      sessionStorage.setItem("businessAuthImg", JSON.stringify(imgPath));
      updateCertImg(this.cerObj)
        .then((result) => {
          console.log("提交营业执照", result);
          if (result.code === 200) {
            this.$message.success(result.msg);
            console.log("hyClass:", this.hyClass);
            if (["1001", "1014"].includes(this.hyClass)) {
              this.isShowYyzz = false;
              this.isShowZlxkz = true;
            } else {
              this.$router.push("/registreljob");
            }
          }
        })
        .catch(() => {});
    },

    toReleaseJob() {
      this.$router.push("/registreljob");
    },
    goBackPage() {
      if (this.isShowZlxkz === true) {
        this.isShowYyzz = true;
        this.isShowZlxkz = false;
      } else {
        this.$router.back();
      }
    },
  },
};
</script>

<style>
.business-auth-dialog {
  background-color: #fff;
  width: 975px;
  padding: 25px 23px;
  border-radius: 20px;
}

.auth-tips-view {
  width: 860px;
  height: 39px;
  background-color: #fff5f0;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  margin-left: 49px;
  margin-top: 51px;
  color: #f34b37;
}

.auth-tips-view span {
  color: #fe6002;
  font-size: 14px;
  
  font-weight: 400;
  color: #fe6002;
  line-height: 16px;
  margin-left: 2px;
}

.business-auth-inf {
  margin: 0 49px;
  margin-top: 49px;
}

.business-auth-inf .ipn-title {
  font-size: 22px;
  
  font-weight: 400;
  color: #191919;
  line-height: 28px;
}

.business-auth-inf .input-view {
  margin-top: 12px;
}

.business-auth-inf .input-view .el-input__inner {
  height: 60px;
  font-size: 20px;
  
  font-weight: 600;
  color: #191919;
  line-height: 28px;
}

.business-auth-inf .input-view .right-text {
  font-size: 20px;
  
  font-weight: 400;
  color: #b1b1b1;
  line-height: 28px;
}

.business-auth-inf .up-img-view {
  margin-top: 48px;
  text-align: center;
}

.business-auth-inf .up-img-view .avatar-uploader {
  height: 224px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.business-auth-inf .up-img-view img {
  width: 336px;
  height: 224px;
}

.business-auth-inf .zyst-tgbtn-view {
  display: flex;
  align-items: end;
  justify-content: space-between;
}

.business-auth-inf .zysx-text {
  margin-top: 48px;
}

.business-auth-inf .zysx-text .tt1 {
  font-size: 18px;
  
  font-weight: 600;
  color: #191919;
  line-height: 28px;
}

.business-auth-inf .zysx-text .tt2 {
  font-size: 16px;
  
  font-weight: 400;
  color: #757575;
  line-height: 26px;
}

.business-auth-inf .zysx-text .tt2 span {
  color: #f34b37;
}
.business-auth-inf .zyst-tgbtn-view .tg-btn .el-button--text {
  font-size: 18px;
}
.business-auth-inf .bot-btn-view {
  text-align: center;
  margin-top: 50px;
}

.business-auth-inf .bot-btn-view .el-button {
  width: 368px;
  height: 50px;
  font-size: 18px;
  border: none;
  border-radius: 8px;
  margin-left: 50px;
}

.business-auth-inf .bot-btn-view .el-button--default {
  background-color: #f5f6fa;
}
</style>