<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2024-02-26 18:15:41
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-02-26 18:21:06
 * @FilePath: /lcwpcvue/src/views/jobExperience.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="com-regist">
    <jobexper class="wxsc-view" @saveJobExp="saveJobExp"></jobexper>
  </div>
</template>

<script>
import jobexper from "@/components/individualUser/jobExper.vue";

export default {
  components: {
    jobexper,
  },
  methods: {
    saveJobExp() {
      this.$router.push("/home");
    },
  },
};
</script>

<style>
</style>