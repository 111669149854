/*
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-08-30 14:41:06
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-04-30 11:56:08
 * @FilePath: /lcwpcvue/src/api/indiviuser/jobInfoPage.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'

//获取工作详情
export function getJobDetails(params) {
  return request({
    url: 'job/jobInfo',
    method: 'post',
    data: params,
  })
}

//获取职位详情的推荐职位
export function getRecommendJobList(params) {
  return request({
    url: 'Comjob/recommendjob',
    method: 'get',
    params: params,
  })
}


//根据公司id查找该公司招聘的职位
export function getJobListByComId(params) {
  return request({
    url: 'company/companyInfo',
    method: 'post',
    data: params,
  })
}

/**
* 预览职位
* @url Business/detail
* @param {} params 
* @return 
*/

export function getJobInfByYl() {
  return request({
    url: 'Business/detail',
    method: 'get',
  })
}

/**
* 判断能否直聊
* @url resume/NeedScore
* @param {} params 
* @return 
*/
export function checkUserIsCanChat() {
  return request({
    url: 'resume/NeedScore',
    method: 'post',
  })
}

/**
* 屏蔽公司
* @url Shield/subShield
* @param {} params 
* @return 
*/
export function shieldingCompany(params) {
  return request({
    url: 'Shield/subShield',
    method: 'post',
    data: params
  })
}
/**
* 收藏公司
* @url Collection/doCollection
* @param {} params 
* @return 
*/
export function collectionCompany(params) {
  return request({
    url: 'Collection/doCollection',
    method: 'post',
    data: params
  })
}

/**
 * 投递简历
 * @param jobid 职位ID
 * @param comid 企业ID
 * @param isShowRec 是否显示平台推荐 0没管理员 1显示推荐 2是管理员但不推荐
 */
export function deliverResume(jobid, comid,isShowRec) {
  return request({
    url: 'Interview/sendexpect',
    method: 'post',
    data: {
      jobid: jobid,
      comid: comid,
      is_ad: isShowRec,
    }
  })
}

/**
 * 收藏兼职
 * @url Collection/doPartCollection
 * @param {job_id} params 
 * @return
 */
export function collectionPartJob(params) {
  return request({
    url: 'Collection/doPartCollection',
    method: 'post',
    data: params
  })
}