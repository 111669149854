<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2024-05-07 11:07:44
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-05-28 16:00:23
 * @FilePath: /lcwpcvue/src/components/individualUser/companyListItem.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="coms-item">
    <div v-for="item in companylist" :key="item.id">
      <div class="companys-page" @click="toCompanyDetail(item.to_uid)">
        <div>
          <span class="coms-create-time" v-show="wherefrom == '2'"
            >{{ item.startDate }} {{ item.startTime }}-{{ item.endTime }}</span
          >
          <div class="companys-top-inf">
            <img class="coms-logo-img" :src="item.logo" alt="" />
            <div class="coms-name-add">
              <div class="coms-name-view">
                <span>{{ item.name }}</span>
              </div>
              <div class="coms-addr-view">
                <span v-show="wherefrom">{{ item.tab }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="coms-bot-sol-view">
          <span class="coms-time" v-show="[1, 5].includes(wherefrom)">{{
            item.time
          }}</span>
          <button
            v-show="wherefrom == 2 && item.typeName !== '未知'"
            @click.stop.capture="disposeInterview(item)"
            class="coms-interview-butt"
            :class="
              ['1', '3'].includes(item.type)
                ? 'coms-interview-butt-stay'
                : 'coms-interview-butt-over'
            "
          >
            {{ item.typeName }}
          </button>
          <div
              @click.stop="deleteLook(item.id)"
              class="zcz-zdy-btn" v-show="wherefrom === 5 && $store.getters.userinf.ad_login === '1'">
            <i class="el-icon-delete"></i>
            <span>删除</span>
          </div>
        </div>
      </div>
    </div>
    <ComDialog
      :isopen="isOpenDialog"
      dialogTetle="邀请面试"
      @close="comDialogClose"
      @dialogFrameEvent="dialogFrameEvent"
      myWidth="400px"
    >
      <div class="coms-dislog">
        <div>
          <!--          头像-->
          <div class="coms-dia-one">
            <div style="width: 80px; height: 80px">
              <img
                :src="interviewInfo.logo"
                style="width: 70px; height: 70px; margin-top: 5px"
              />
            </div>
            <div>
              <p class="one-companyname">{{ interviewInfo.company_name }}</p>
              <span class="one-com-status">{{
                interviewInfo.is_browse_name
              }}</span>
            </div>
          </div>
          <!--          中间部分-->
          <div class="coms-dia-content">
            <el-button @click.stop.capture="interviewlookJob"
              >查看职位</el-button
            >
            <el-button @click.stop.capture="closeMapPop">位置</el-button>
            <el-button @click.stop.capture="interviewChat">聊天</el-button>
            <el-tooltip
              class="item"
              effect="dark"
              :content="interviewInfo.moblie"
              placement="top-start"
            >
              <el-button>打电话</el-button>
            </el-tooltip>
          </div>
          <!--          底部-->
          <div class="coms-dia-foot">
            <p>
              <span class="coms-interview-jobname"
                >职位：{{ interviewInfo.jobname }}</span
              >
              &nbsp;&nbsp;
            </p>
            <p>
              <span
                class="coms-interview-salary"
                v-show="interviewInfo.job_minsalary != 0"
                >薪资：{{ interviewInfo.job_minsalary }}-{{
                  interviewInfo.job_maxsalaryold
                }}</span
              >
              <span
                class="coms-interview-salary"
                v-show="interviewInfo.job_minsalary == 0"
                >薪资：(面议)</span
              >
            </p>
            <p class="coms-interview-jobname">
              时间：{{ interviewInfo.intertime }}
            </p>
            <p class="coms-interview-jobname">
              地点：{{ interviewInfo.address }}
            </p>
          </div>
          <!--          按钮-->
          <div style="height: 40px" v-show="interviewInfo.is_browse === '1'">
            <el-button
              style="float: right"
              type="primary"
              @click.stop="interviewButt('accept')"
              >接受面试</el-button
            >
            <el-button
              style="float: right; margin-right: 15px"
              @click.stop="interviewButt('refuse')"
              >拒绝面试</el-button
            >
          </div>
          <p
            v-show="interviewInfo.is_browse === '3'"
            style="text-align: end; margin-bottom: 10px"
          >
            您可以在{{ interviewInfo.cancel_time }}前
            <span
              style="color: #fe6002; cursor: pointer"
              @click="cancelInterview"
              >取消面试</span
            >
          </p>
        </div>
      </div>
    </ComDialog>
    <addaddressdialog
      :addAddrDialogShow="isOpenMap"
      :addressByXY="interviewInfo"
      @closeMapPop="closeMapPop"
    >
    </addaddressdialog>
  </div>
</template>

<script>
import ComDialog from "@/components/comDialog.vue";
import { interviewInfoResu } from "@/api/commen/userCenter";
import { parseTime } from "@/utils/datePas";
import { acceptInterview, cancelInterview } from "@/api/commen/resume";
import { chatLog } from "@/api/commen/chatApi";
import Addaddressdialog from "@/components/mapShowDialog.vue";
import {delResuManagement} from "@/api/compe/aboutResumeMa";

export default {
  components: { Addaddressdialog, ComDialog },
  props: {
    companylist: {
      type: Array,
    },
    wherefrom: {
      type: Number,
    },
  },
  data() {
    return {
      isOpenDialog: false,
      isOpenMap: false,
      interviewInfo: {},
    };
  },
  methods: {
    deleteLook(id){
      this.$confirm('确定要删除吗？','警告',{
        confirmButtonText:'确认',
        cancelButtonText:"取消",
        type:"error"
      }).then(()=>{
        delResuManagement(id,'company_seelog').then(e=>{
          this.$message.success(e.msg);
          this.$emit('updateList',this.wherefrom);
        });
      }).catch(()=>{});
    },
    dialogFrameEvent(param) {
      console.log(param);
      this.isOpenDialog = false;
    },
    toCompanyDetail(uid) {
      let newRouter = this.$router.resolve({
        path: `/companyinf?id=${uid}`,
      });
      window.open(newRouter.href, "_blank");
    },
    disposeInterview(item) {
      // 1-待处理,3-同意，4-拒绝,5-完成面试,6.取消面试, 7-面试中,2-没有面试
      interviewInfoResu(item.inter_id).then((e) => {
        this.interviewInfo = e.data.info;
        let statusArr = [
          "面试待接受",
          "没有面试",
          "面议已接受",
          "面试已拒绝",
          "面试已完成",
          "面试已取消",
          "面试中",
        ];
        let timeArr = this.interviewInfo.intertime.split("-");
        let day = parseTime(timeArr[0], "{m}月{d}日");
        let startTime = parseTime(timeArr[0], "{h}:{i}");
        let endTime = parseTime(timeArr[1], "{h}:{i}");
        this.interviewInfo.intertime = day + "  " + startTime + "-" + endTime;
        this.interviewInfo.is_browse_name =
          statusArr[this.interviewInfo.is_browse - 1];
        this.interviewInfo.cancel_time = parseTime(
          timeArr[0] - 21600,
          "{m}月{d}日{h}:{i}"
        );
        this.interviewInfo.lat = parseFloat(this.interviewInfo.y);
        this.interviewInfo.lng = parseFloat(this.interviewInfo.x);
        this.isOpenDialog = true;
        console.log(this.interviewInfo);
      });
    },
    cancelInterview() {
      cancelInterview(this.interviewInfo.id).then((e) => {
        this.isOpenDialog = false;
        this.$emit("refresh", "2");
        this.$message.success(e.msg);
      });
    },
    interviewButt(isOk) {
      acceptInterview(this.interviewInfo.id, isOk).then((e) => {
        let isButtOk = isOk === "accept";
        let chilArr = {
          job_id: "",
          interview_id: this.interviewInfo.id,
          agree_interview: isButtOk,
          usertype: 1,
        };
        let chatlog = {
          type: 5,
          content: isButtOk ? "[面试接受]" : "[面试拒绝]",
          to_uid: this.interviewInfo.fid,
          chat_type: "normal",
          agree: isButtOk ? 1 : 2,
          job_id: "",
          new_content: JSON.stringify(chilArr),
        };
        chatLog(chatlog).then((e) => {
          console.log(e);
        });
        this.$message.success(e.msg);
        this.isOpenDialog = false;
        this.$emit("refresh", "2");
      });
    },
    interviewlookJob() {
      let newRouter = this.$router.resolve({
        path: `jobinfopage?id=${this.interviewInfo.jobid}`,
      });
      window.open(newRouter.href, "_blank");
    },
    interviewChat() {
      let newRouter = this.$router.resolve({
        path: `chatpage?chatid=${this.interviewInfo.fid}`,
      });
      window.open(newRouter.href, "_blank");
    },
    closeMapPop(type) {
      if (!type) {
        this.isOpenMap = false;
      } else {
        console.log("将传递map的参数：", this.interviewInfo);
        this.isOpenMap = true;
      }
    },
    comDialogClose() {},
  },
};
</script>

<style scoped>
.coms-item .companys-page {
  background-color: #fff;
  border-radius: 8px;
  margin-top: 20px;
  padding: 30px 24px;
  display: flex;
  justify-content: space-between;
  
  cursor: pointer;
}
.companys-page .coms-interview-butt-stay {
  background-color: #fe6002;
}
.companys-page .coms-interview-butt-over {
  background-color: #d9d9d9;
}
.coms-item .companys-page:hover {
  background-color: #fff5f0;
}
.coms-item .companys-page .coms-item-wf div {
  font-weight: 400;
  font-size: 12px;
  color: #757575;
  line-height: 28px;
  text-align: center;
  margin: 3px 5px 0 0;
  padding: 0 15px;
  background: #f5f6fa;
  border-radius: 6px;
}
.coms-item .companys-page .companys-top-inf {
  display: flex;
  align-items: center;
}
.coms-item .companys-page .coms-create-time {
  font-weight: 600;
  font-size: 19px;
  color: #191919;
  line-height: 28px;
  text-align: left;
}

.coms-item .companys-page .coms-interview-butt {
  border: none;
  border-radius: 15px;
  width: 76px;
  height: 30px;
  font-size: 14px;
  color: white;
  cursor: pointer;
}
.coms-item .companys-page .companys-top-inf .coms-logo-img {
  width: 70px;
  height: 70px;
}

.coms-item .companys-page .companys-top-inf .coms-name-add {
  margin-left: 16px;
}

.coms-item .companys-page .coms-name-view {
  font-weight: 600;
  font-size: 20px;
  color: #191919;
  line-height: 28px;
}

.coms-item .companys-page .companys-top-inf .coms-name-add .coms-addr-view {
  font-weight: 400;
  font-size: 14px;
  color: #757575;
  line-height: 16px;
  margin-top: 16px;
}

.coms-item .companys-page .coms-time {
  font-weight: 400;
  font-size: 16px;
  color: #666666;
  text-align: left;
}
.coms-dislog {
  padding: 20px;
}
.coms-dia-one {
  display: flex;
}
.coms-dia-one img {
  width: 100px;
  height: auto;
}
.one-companyname {
  font-weight: 600;
  font-size: 20px;
  color: #191919;
  line-height: 60px;
}
.one-com-status {
  font-size: 16px;
  font-weight: 600;
  color: #fe6002;
}
.coms-dia-content {
  display: flex;
  margin: 20px 0;
}
.coms-interview-jobname {
  font-size: 18px;
  color: #191919;
  line-height: 28px;
}
.coms-interview-salary {
  font-size: 18px;
  color: #191919;
  line-height: 28px;
}
.coms-dia-foot {
  border-top: 2px solid #ececec;
  margin-bottom: 30px;
}
.coms-dia-foot p {
  margin-top: 20px;
}
.coms-item .zcz-zdy-btn:hover {
  border: 1px solid #fe6002;
  background-color: #fff5f0;
  color: #fe6002;
}
</style>