<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2024-03-22 13:09:26
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-06-05 16:32:10
 * @FilePath: /lcwpcvue/src/components/comUser/comWaitingInterview.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <div style="height: 30px"></div>
    <div class="common-title-view">
      <span class="left-xian-view"></span>
      <span class="text">面试日程</span>
    </div>

    <div class="coupon-navbar-view">
      <div class="nav-item" @click="changeNavId(1)">
        <div class="nav-text" :class="navbarId == '1' ? 'nav-sltext' : ''">
          待面试
        </div>
        <div v-show="navbarId == '1'" class="bot-xian"></div>
      </div>
      <div class="nav-item" @click="changeNavId(2)">
        <div class="nav-text" :class="navbarId == '2' ? 'nav-sltext' : ''">
          已结束
        </div>
        <div v-show="navbarId == '2'" class="bot-xian"></div>
      </div>
    </div>
    <div class="waiting-list-view">
      <comwaitinglistitem :listArr="waritingList"></comwaitinglistitem>
      <div v-show="waritingList.length == 0" class="nocoupon-list-view">
        <img class="none-list-img" src="@/assets/transfer_is_null.png" alt="" />
        <div class="none-text">暂无还没有相关的信息</div>
      </div>
    </div>

    <el-pagination
      v-show="waritingList.length != 0"
      @current-change="pagesChage"
      background
      layout="prev, pager, next"
      :page-count="allPage"
      :current-page.sync="currentpage"
      class="pag-ina"
    >
    </el-pagination>
  </div>
</template>

<script>
import comwaitinglistitem from "@/components/comUser/comWaitingListItem.vue";
import { getInterviewList } from "@/api/commen/aboutUserIndexApi.js";
import { parseTime } from "@/utils";
export default {
  components: {
    comwaitinglistitem,
  },
  data() {
    return {
      navbarId: "1",
      waritingList: [],
      pageIndex: 1,
      allPage: 0,
      currentpage: 0,
    };
  },
  created() {
    this.getInterviewList();
  },
  methods: {
    changeNavId(id) {
      this.navbarId = id;
      this.getInterviewList();
      this.pageIndex = 1;
    },
    getInterviewList() {
      getInterviewList({
        page: this.pageIndex,
        category: this.navbarId,
      })
        .then((result) => {
          console.log("获取待面试intertime", result);
          if (result.data.list) {
            this.allPage = parseInt(result.data.allpage);
            this.waritingList = result.data.list;
            this.waritingList.forEach((e) => {
              let se = e.intertime.split("-");
              e.startDate = parseTime(se[0], "{m}月{d}日");
              e.startTime = parseTime(se[0], "{h}:{i}");
              e.endTime = parseTime(se[1], "{h}:{i}");
            });
          }
        })
        .catch(() => {});
    },
    pagesChage(e) {
      this.pageIndex = e;
      window.scrollTo(10, 10);
      this.getInterviewList();
    },
  },
};
</script>

<style>
</style>