<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-06-01 16:14:09
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-06-03 10:32:25
 * @FilePath: /lcwpcvue/src/components/individualUser/eduExperience.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="eduexp">
    <div class="title-view">
      <div class="title">教育经历</div>
      <div class="t2">请填写国家承认学历的教育经历，非培训经历～</div>
    </div>

    <el-form
      :model="ruleForm"
      ref="ruleForm"
      :rules="formRules"
      status-icon
      class="demo-ruleForm"
    >
      <div class="choseview chsl-input">
        <el-form-item label="" id="ch1" prop="">
          <div class="tt">学历</div>
          <el-select v-model="ruleForm.education" placeholder="请选择学历">
            <el-option
              v-for="item in eduList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </div>

      <div class="choseview chsl-input">
        <el-form-item label="" prop="name">
          <div class="tt">学校名称</div>
          <el-autocomplete
            v-model="ruleForm.name"
            :fetch-suggestions="remoteMethod"
            placeholder="请输入内容"
            :trigger-on-focus="false"
            @select="schoolNameHandleSelect"
          ></el-autocomplete>
        </el-form-item>
      </div>

      <div class="choseview chsl-input" v-if="ruleForm.education != '80'">
        <el-form-item label="" prop="specialty">
          <div class="tt">专业</div>
          <el-input
            placeholder="请填写学习专业"
            v-model="ruleForm.specialty"
          ></el-input>
        </el-form-item>
      </div>
      <div class="choseview time-sle">
        <el-form-item label="" class="time-sle-for" prop="sdate">
          <div class="tt">时间</div>
          <el-date-picker
            v-model="ruleForm.sdate"
            type="month"
            placeholder="入学时间"
            format="yyyy-MM"
            value-format="timestamp"
            :picker-options="setMonthDisabled"
          >
          </el-date-picker>
          &nbsp; — &nbsp;
          <el-date-picker
            v-model="ruleForm.edate"
            type="month"
            placeholder="毕业时间"
            format="yyyy-MM"
            value-format="timestamp"
            :picker-options="setMonthDisabled"
          >
          </el-date-picker>
          <!--          <el-date-picker
            v-model="date"
            type="monthrange"
            range-separator="至"
            start-placeholder="入学时间"
            end-placeholder="毕业时间"
            value-format="timestamp"
            format="yyyy-MM"
            prefix-icon="none"
            @change="choseEduTime"
          >
          </el-date-picker>-->
        </el-form-item>
      </div>
      <div class="choseview time-sle">
        <el-form-item label="" class="time-sle-for">
          <div class="tt">
            在校经历（选填）<span>可简单叙述，在个人简历中详细修改</span>
          </div>
          <el-input
            type="textarea"
            placeholder="可填写在校期间学习到的主要技能及获得的荣誉"
            maxlength="300"
            show-word-limit
            v-model="ruleForm.content"
            :rows="6"
          ></el-input>
        </el-form-item>
      </div>

      <div class="add-eduexp">
        <span class="tt1">继续添加教育经验</span>
        <div class="rg-view" @click="addNewEduExp">
          <i class="el-icon-plus"></i>
          <el-button type="text">添加</el-button>
          <!-- <span>添加</span> -->
        </div>
      </div>

      <el-form-item class="subm">
        <el-button type="info" plain @click="banckFunction">上一步</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >下一步</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  searSchoolName,
  addResumeEdu,
  ediResumeEdu,
  getXlClass,
} from "@/api/commen/register";
export default {
  data() {
    return {
      eduList: [],
      ruleForm: {
        name: "",
        education: "",
        specialty: "",
        sdate: "",
        edate: "",
        content: "",
      },
      formRules: {
        name: [{ required: true, message: "请填写学校名", trigger: "blur" }],
        // education: [{ required: true, message: "请选择学历", trigger: "blur" }],
        specialty: [{ required: true, message: "请填写专业", trigger: "blur" }],
        sdate: [{ required: true, message: "请选择时间", trigger: "blur" }],
        edate: [{ required: true, message: "请选择时间", trigger: "blur" }],
      },
      date: "",
      schoolList: [],
      isFisterLogin: false,
      setMonthDisabled: {
        // 返回禁用时间
        disabledDate(time) {
          // 获取当前的月份信息
          const date = new Date(); // 获取当前的时间基本信息，值是这样的： Tue Jul 20 2021 14:59:43 GMT+0800 (中国标准时间)
          const year = date.getFullYear(); // 获取当前年份，值是这样的： 2021
          let month = date.getMonth() + 1; // 获取当前月份，值是这样的： 6   getMonth()方法返回值是0-11，也就是1月份到12月份，所以要加上1，才是当前月份
          if (month >= 1 && month <= 9) {
            // 如果是1月到9月就要在前面补上一个0   比如：02、07    月份这样表示
            month = "0" + month;
          }
          const nowDate = year.toString() + month.toString(); // 转换成字符串拼接，最终得到年和月，比如此时的时间是2021年7月20号，所以nowDate的值是：202107

          // 获取时间选择器的月份信息
          const timeyear = time.getFullYear(); // 获取时间选择器的年份（有很多）
          let timemonth = time.getMonth() + 1; // 与上面同理
          if (timemonth >= 1 && timemonth <= 9) {
            timemonth = "0" + timemonth;
          }
          const elTimeData = timeyear.toString() + timemonth.toString();

          // 返回，时间选择器的日期月份大于当前日期的月份，又因为disabledDate函数是控制月份禁用不可选
          // 所以，最终就是：时间选择器的月份大于当前的月份，就都禁用掉，所以就实现了最终效果：
          // 小于等于当前月份都不可选
          return elTimeData > nowDate; // 这里虽然是字符串，但是弱类型语言js会做一个转换，是可以比较大小的如： '202107' > '202008'
        },
      },
    };
  },
  created() {
    this.isFisterLogin = this.$store.state.user.isfisterlogin;
    if (this.$store.state.user.isfisterlogin) {
      this.submitBtnText = "下一步";
    } else {
      this.submitBtnText = "保存";
    }
    if (localStorage.regEduExp) {
      this.ruleForm = JSON.parse(localStorage.regEduExp);
    }
    this.getXlClass();
  },

  methods: {
    //获取学历列表
    getXlClass() {
      getXlClass({
        name: "user_edu",
      })
        .then((result) => {
          console.log("获取学历", result);
          this.eduList = result.data.list.user_edu;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    submitForm(form) {
      if (this.ruleForm.education == "") {
        this.$message.warning("请选择学历");
        return false;
      }
      this.$refs[form].validate((valid) => {
        if (valid) {
          localStorage.setItem("regEduExp", JSON.stringify(this.ruleForm));
          this.ruleForm.sdate = this.ruleForm.sdate / 1000;
          this.ruleForm.edate = this.ruleForm.edate / 1000;
          if (this.ruleForm.sdate >= this.ruleForm.edate) {
            this.$message.warning("开始时间不能晚于结束时间");
            this.ruleForm.sdate = "";
            this.ruleForm.edate = "";
            return false;
          }
          if (this.isFisterLogin) {
            addResumeEdu(this.ruleForm)
              .then((result) => {
                console.log("添加", result);
                this.$emit("saveEduExp");
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            ediResumeEdu(this.ruleForm)
              .then((result) => {
                console.log("编辑", result);
              })
              .catch((err) => {
                console.log(err);
              });
          }
        }
      });
    },
    remoteMethod(e, cb) {
      this.schoolList = [];
      if (e != "") {
        searSchoolName({ kw: e })
          .then((result) => {
            console.log("搜索学校", result);
            if (result.data.length > 0) {
              result.data.forEach((element) => {
                var i = { value: "" };
                i.value = element;
                this.schoolList.push(i);
              });
              cb(this.schoolList);
            } else {
              cb([]);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        cb([]);
      }
    },
    choseSchool(e) {
      console.log(e);
    },

    choseEduTime(res) {
      console.log(this.date);
      this.ruleForm.sdate = res[0];
      this.ruleForm.edate = res[1];
    },
    addNewEduExp() {
      if (this.ruleForm.education == "") {
        this.$message.warning("请选择学历");
        return false;
      }
      this.ruleForm.sdate = this.ruleForm.sdate / 1000;
      this.ruleForm.edate = this.ruleForm.edate / 1000;
      if (this.ruleForm.sdate >= this.ruleForm.edate) {
        this.$message.warning("开始时间不能晚于结束时间");
        this.ruleForm.sdate = "";
        this.ruleForm.edate = "";
        return false;
      }
      addResumeEdu(this.ruleForm)
        .then((result) => {
          console.log("添加", result);
          this.$message({
            message: "保存成功！",
            type: "success",
          });
          this.ruleForm = {
            name: "",
            education: "",
            specialty: "",
            sdate: "",
            edate: "",
            content: "",
          };
        })
        .catch((err) => {
          console.log(err);
        });
    },
    schoolNameHandleSelect(e) {
      console.log(e);
    },
    banckFunction() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.eduexp {
  width: 861px;
  background-color: #ffffff;
  border-radius: 20px;
  padding: 54px 71px;
}
.title-view .title {
  font-size: 30px;

  font-weight: 600;
  color: #222222;
}

.title-view .t2 {
  font-size: 20px;

  font-weight: 500;
  color: #b1b1b1;
  margin-top: 12px;
  margin-bottom: 50px;
}

.choseview {
  position: relative;
}

.choseview .tt,
.choseviewlong .tt {
  font-size: 14px;
  color: #606266;
}

.choseview .tt span,
.choseviewlong .tt span {
  font-size: 12px;
  color: #b1b1b1;
}

.chsl-input.el-cascader .el-input .el-input__inner {
  /* text-align: center; */
}

.chsl-input .el-select {
  width: 860px;
  margin-right: 57px;
}

.el-date-editor.el-range-editor.el-input__inner.el-date-editor--monthrange {
  width: 860px;
}

.el-picker-panel.el-date-range-picker.el-popper {
  width: 860px;
}
.el-picker-panel__body-wrapper {
  width: 860px;
}
/* .chsl-input .el-cascader-menu {
} */

.eduexp .subm {
  width: 860px;
  text-align: right;
  margin-top: 40px;
}
.eduexp .subm .el-button {
  width: 369px;
  height: 40px;
  font-size: 14px;
}

.add-eduexp {
  width: 861px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add-eduexp .tt1 {
  font-size: 14px;

  font-weight: 400;
  color: #191919;
  line-height: 28px;
}
.add-eduexp .rg-view {
  font-size: 13px;
  font-weight: 600;
  color: #fe6002;
}
.add-eduexp .rg-view .el-button {
  width: auto;
  color: #fe6002;
  font-size: 13px;
}
</style>