<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2024-03-22 14:09:21
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-03-22 18:39:39
 * @FilePath: /lcwpcvue/src/components/comUser/comWaitingListItem.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <label v-for="item in listArr" :key="item.inter_id">
      <div class="waiting-inrerview-page" @click="openResume(item.to_uid)">
        <div class="waiting-inrerview-top-view">
          <div class="inf-top-left-view">
            <div class="avatar-img-view">
              <img class="avata-img" :src="item.resu_logo" alt="" />
              <img
                v-show="item.sex == '男'"
                class="sex-img"
                src="@/assets/gender_nan.png"
                alt=""
              />
              <img
                v-show="item.sex == '女'"
                class="sex-img"
                src="@/assets/gender_nv.png"
                alt=""
              />
            </div>
            <div class="name-tag-age-view">
              <div class="name-tag-view">
                <span class="name">{{ item.resu_name }}</span>
              </div>
              <div class="age-deu-exp"></div>
            </div>
          </div>
          <div class="inf-top-right-view">
            <div class="qw-jb-ct-item">
              <img
                class="qw-img"
                src="@/assets/talentsdetail_qzzt_img.png"
                alt=""
              />
              <span class="tt1">面试职位：</span>
              <span class="tt2">{{ item.jobname }}</span>
            </div>
            <div class="qw-jb-ct-item">
              <img class="qw-img" src="@/assets/user_card_city.png" alt="" />
              <span class="tt1">期望薪资</span>
              <span class="tt2">
                <label v-show="item.minsalary != '0'">{{ item.minsalary }}-{{ item.maxsalary }}元/月</label>
                <label v-show="item.minsalary == '0'">面议</label>
              </span
              >
            </div>
          </div>
        </div>
        <div class="waiting-inrerview-bott-view">
          <div class="waiting-inrerview-bott-left-view">
            <div class="moday">{{item.startDate}} {{item.startTime}} - {{item.endTime}}</div>
          </div>
          <div class="waiting-inrerview-bott-right-view">
            <div class="status" v-show="item.type == 1">待处理</div>
            <div class="status" v-show="item.type == 3">待面试</div>
            <div class="status qx" v-show="item.type == 4">已拒绝</div>
            <div class="status qx" v-show="item.type == 5">已完成</div>
            <div class="status qx" v-show="item.type == 6">已取消</div>
<!--            <el-button type="primary" @click="toTanlentDetail(item)"
              >查看简历</el-button
            >
            <el-button type="primary">聊天</el-button>
            <el-button type="primary">拨打电话</el-button>-->
          </div>
        </div>
      </div>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    listArr: {
      type: Array,
    },
  },
  created() {
    console.log('listArr',this.listArr)
  },
  methods: {
    toTanlentDetail() {

    },
    openResume(id){
      let newRouter = this.$router.resolve({
        path:`talentsdetail?id=${id}`
      });
      window.open(newRouter.href,"_blank")
    },
  },
};
</script>

<style>
.waiting-inrerview-page {
  width: 1148px;
  background-color: #fff;
  padding: 24px 24px;
  margin-top: 16px;
  cursor: pointer;
}
.waiting-inrerview-page:hover{
  background-color: #fff5f0;
}
.waiting-inrerview-top-view {
  display: flex;
  align-items: center;
}
.waiting-inrerview-page .inf-top-left-view {
  display: flex;
  align-items: center;
  width: 600px;
}

.waiting-inrerview-page .inf-top-left-view .avatar-img-view {
  position: relative;
}

.waiting-inrerview-page .inf-top-left-view .avatar-img-view .avata-img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}
.waiting-inrerview-page .inf-top-left-view .avatar-img-view .sex-img {
  width: 22px;
  height: 22px;
  position: absolute;
  bottom: 0;
  right: 2px;
}

.waiting-inrerview-page .inf-top-left-view .name-tag-age-view {
  margin-left: 24px;
}

.waiting-inrerview-page .inf-top-left-view .name-tag-age-view .name-tag-view {
  display: flex;
  align-items: center;
}

.waiting-inrerview-page
  .inf-top-left-view
  .name-tag-age-view
  .name-tag-view
  .name {
  
  font-weight: 600;
  font-size: 22px;
  color: #191919;
  line-height: 28px;
}
.waiting-inrerview-page
  .inf-top-left-view
  .name-tag-age-view
  .name-tag-view
  .tag1,
.waiting-inrerview-page
  .inf-top-left-view
  .name-tag-age-view
  .name-tag-view
  .tag2 {
  width: 32px;
  height: 16px;
  margin-left: 12px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  
  font-weight: 400;
}
.waiting-inrerview-page
  .inf-top-left-view
  .name-tag-age-view
  .name-tag-view
  .tag1 {
  background-color: #ffd9c3;
  color: #f34b37;
}
.waiting-inrerview-page
  .inf-top-left-view
  .name-tag-age-view
  .name-tag-view
  .tag2 {
  background-color: #b3dfff;
  color: #0294fe;
}
.waiting-inrerview-page .inf-top-left-view .name-tag-age-view .age-deu-exp {
  
  font-weight: 400;
  font-size: 16px;
  color: #303030;
  line-height: 19px;
  margin-top: 16px;
}

.waiting-inrerview-page .inf-top-right-view {
  border-left: 1px solid#EAECF5;
  height: 65px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 30px;
}

.waiting-inrerview-page .inf-top-right-view .qw-jb-ct-item {
  display: flex;
  align-items: center;
}
.waiting-inrerview-page .inf-top-right-view .qw-jb-ct-item .qw-img {
  width: 20px;
  height: 20px;
  margin-right: 6px;
}
.waiting-inrerview-page .inf-top-right-view .qw-jb-ct-item .tt1 {
  
  font-weight: 400;
  font-size: 16px;
  color: #b1b1b1;
  line-height: 19px;
}

.waiting-inrerview-page .inf-top-right-view .qw-jb-ct-item .tt2 {
  
  font-weight: 400;
  font-size: 18px;
  color: #191919;
  line-height: 24px;
}
.waiting-inrerview-page .inf-top-right-view .qw-jb-ct-item .tt2 label {
  color: #f34b37;
}

.waiting-inrerview-page .waiting-inrerview-bott-view {
  display: flex;
  margin-top: 16px;
  border-top: 1px solid#EAECF5;
  padding-top: 16px;
  position: relative;
  padding-bottom: 16px;
  justify-content: space-between;
  align-items: flex-end;
}
.waiting-inrerview-page
  .waiting-inrerview-bott-view
  .waiting-inrerview-bott-left-view
  .moday {
  font-size: 20px;
  font-weight: 600;
}
.waiting-inrerview-page
  .waiting-inrerview-bott-view
  .status {
  display: inline-block;
  background-color: #fe6002;
  color: #fff;
  padding: 6px 10px;
  border-radius: 30px;
  margin-top: 10px;
  font-size: 16px;
}
.waiting-inrerview-page
  .waiting-inrerview-bott-view
  .waiting-inrerview-bott-left-view
  .qx {
  background-color: #cccccc;
}
</style>