/*
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-12-21 10:42:39
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-06-05 17:56:07
 * @FilePath: /lcwpcvue/src/api/commen/orderApi.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'
/**
* 求职者获取我的订单
* @url Rating/orderList
* @param {} params 
* @return 
*/

export function getQzOrderList(params) {
  return request({
    url: 'Resurating/orderlist',
    method: 'get',
    params: params
  })
}

/**
* 招聘者获取我的订单
* @url Rating/orderList
* @param {} params 
* @return 
*/
export function getZpOrderList(params) {
  return request({
    url: 'Rating/orderList',
    method: 'post',
    data: params
  })
}

/**
* 获取历史开票信息
* @url company/comInvData
* @param {} params 
* @return 
*/

export function getHistoryInvoiceInf() {
  return request({
    url: 'company/comInvData',
    method: 'post'
  })
}

/**
* 检索发票公司名称
* @url company/searchComData
* @param {kw//关键字} params 
* @return 
*/

export function getCompanyListInInvoice(param) {
  return request({
    url: 'company/searchComData',
    method: 'post',
    data: param
  })
}

/**
* 查询纳税人编号
* @url company/searchComCode
* @param {name//公司名} params 
* @return 
*/
export function getCompanyNoCode(param) {
  return request({
    url: 'company/searchComCode',
    method: 'post',
    data: param
  })
}

/**
* 提交发票申请&获取已提交发票信息
* @url company/invoice
* @param {} params 
* @return 
*/

export function submitInvoiceRep(params) {
  return request({
    url: 'company/invoice',
    method: 'post',
    data: params
  })
}

/**
* 求职端取消订单
* @url Resurating/orderdel
* @param {} params 
* @return 
*/

export function qzDelOrder(params) {
  return request({
    url: 'Resurating/orderdel',
    method: 'post',
    data: params
  })
}



/**
* 招聘端取消订单
* @url Rating/orderDel
* @param {} params 
* @return 
*/
export function zpDelOrder(params) {
  return request({
    url: 'Rating/orderDel',
    method: 'post',
    data: params
  })
}



