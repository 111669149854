<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2024-01-09 18:54:34
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-06-04 10:32:11
 * @FilePath: /lcwpcvue/src/views/takeShopDetail.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="take-detail">
    <div class="top-zk-div"></div>
    <div v-if="openshareQrcode" class="mask" @click="closeModal"></div>
    <div style="width: 100%; background-color: white; margin-bottom: 15px">
      <div class="top-name-uptime-look-share">
        <div class="left-view">
          <div class="title">{{ takeDetail.title }}</div>
          <div class="up-look-jb-view">
            <div class="items">
              <img src="../assets/jobinf_uptime_img.png" alt="" />
              <span>更新于 {{ takeDetail.update_time }}</span>
            </div>
            <div class="items">
              <img src="../assets/jobinf_look_img.png" alt="" />
              <span>浏览 {{ takeDetail.view }}次</span>
            </div>
            <!--          <div class="items">-->
            <!--            <img src="../assets/jobinf_jb_img.png" alt="" />-->
            <!--            <span>举报</span>-->
            <!--          </div>-->
          </div>
        </div>

        <el-popover
          placement="bottom"
          width="200"
          trigger="click"
          @show="openshareQrcodeFun"
        >
          <div slot="reference" class="share-view">
            <img src="../assets/jobinf_share_img.png" alt="" />
            <span>分享</span>
            <!-- <div
            id="qrcode-image-div"
            style="position: relative"
            v-if="openshareQrcode && qrcodeImg"
          >
            <div class="share-qrcode-triangle"></div>
            <div class="share-qrcode-image">
              <img :src="qrcodeImg" style="width: 100%; height: 100%" />
            </div>
          </div> -->
          </div>
          <div class="share-ewm-img-view">
            <img :src="qrcodeImg" alt="" />
          </div>
        </el-popover>
      </div>
    </div>
    <div class="take-detail-btm-view">
      <div class="take-detail-left-view">
        <div class="takeshop-comm-view">
          <div class="take-inf-view">
            <div class="inf-item">
              <span class="tt1">意向区域：</span>
              <span class="tt2">{{ takeDetail.all_city_name }}</span>
            </div>
            <div class="inf-item">
              <span class="tt1">意向类型：</span>
              <span class="tt2">{{ takeDetail.type_name }}</span>
            </div>
            <div class="inf-item">
              <span class="tt1">意向面积：</span>
              <span class="tt2">{{ takeDetail.area_key.key }}</span>
            </div>
            <div class="inf-item">
              <span class="tt1">意向转让费：</span>

              <span class="tt2">{{ takeDetail.fee_key.key }}</span>
            </div>
            <div class="inf-item">
              <span class="tt1">意向租金：</span>
              <span class="tt2">{{ takeDetail.rent_key.key }}</span>
            </div>
          </div>
          <div class="link-man-inf-view">
            <img class="link-avatar" :src="takeDetail.avatar" alt="" />
            <div class="link-name-add">
              <div class="name">
                {{ takeDetail.link_man }}
                <!--                <label>
                  <span class="zx-dian"></span>
                  <span class="zx-text">在线</span>
                </label>-->
              </div>
              <div class="addre-text">{{ takeDetail.city_name }}</div>
            </div>
            <div class="lin-btn-view">
              <el-button type="primary" @click="toCallHe">{{
                callButtContent
              }}</el-button>
            </div>
          </div>
        </div>
        <div class="takeshop-comm-view">
          <div class="yq-title">店铺要求</div>
          <!--          <div class="yq-text" v-html="takeDetail.desc"></div>-->
          <div class="yq-text">{{ takeDetail.desc }}</div>
          <div v-for="(item, idx) in takeDetail.show_pic" :key="idx">
            <img :src="item" style="width: 90%; height: auto" />
          </div>
        </div>
      </div>
      <div class="take-detail-right-view">
        <img class="sac-ewm-img" src="@/assets/tran_take_sc_ewm.png" alt="" />
      </div>
    </div>
    <affirm-dialog
      :isopenAffirm="affirm.isopenAffirm"
      :affirmDialogClose="affirmDialogClose"
      :affirmDialogOk="affirmDialogOk"
      :affirmTitle="affirm.affirmTitle"
      :affirmMsge="affirm.affirmMsge"
      :affirmFrom="affirm.affirmFrom"
    >
      <template v-slot:icon>
        <i></i>
      </template>
    </affirm-dialog>
  </div>
</template>

<script>
// getTakeShopDetail
import {
  getTakeShopDetail,
  getTakeShopLinkPhone,
} from "@/api/commen/takeShowList";
import { userPcApi } from "@/api/commen/transferShop";
import AffirmDialog from "@/components/affirmDialog.vue";
export default {
  components: { AffirmDialog },
  data() {
    return {
      id: "",
      takeDetail: {},
      qrcodeImg: "",
      openshareQrcode: false,
      callButtContent: "联系他",
      affirm: {
        affirmMsge: "",
        isopenAffirm: false,
        affirmTitle: "提示",
        affirmFrom: "",
      },
    };
  },
  created() {
    window.scrollTo(0, 0);
    this.id = this.$route.query.id;
    this.getTakeShopDetail();
    let token = localStorage.getItem("token");
    if (!token) {
      this.callButtContent = "联系他（请先登录）";
    }
  },
  methods: {
    closeModal() {
      this.openshareQrcode = false;
    },
    affirmDialogOk(from) {
      this.affirm.isopenAffirm = false;
      if (from === "toVip") {
        this.$router.push("/mytransfer");
      } else if (from === "toCreate") {
        this.$router.push("/publishtransffer");
      }
    },
    affirmDialogClose() {
      this.affirm.isopenAffirm = false;
    },
    // 分享按钮
    openshareQrcodeFun() {
      this.getXCXQrcodeImg();
      // this.openshareQrcode = true;
    },
    //获取小程序二维码
    getXCXQrcodeImg() {
      if (!this.qrcodeImg) {
        userPcApi({
          type: "createQrcode",
          id: this.id,
          exeType: "jiedian",
        }).then((res) => {
          console.log("createQrcode", res);
          if (res.code === 200) {
            this.qrcodeImg = res.data.img;
          }
        });
      }
    },
    getTakeShopDetail() {
      getTakeShopDetail({ id: this.id })
        .then((result) => {
          console.log("获接店详情", result);
          this.takeDetail = result.data.info;
        })
        .catch(() => {});
    },
    toCallHe() {
      let token = localStorage.getItem("token");
      if (!token) {
        this.$router.push("/login");
        return;
      }
      getTakeShopLinkPhone({ id: this.takeDetail.id })
        .then((result) => {
          console.log("获取联系电话", result);
          if (result.code == 200) {
            let status = result.data.status;
            if (status === "2") {
              this.affirm.affirmFrom = "toCreate";
              this.affirm.affirmMsge = result.data.err;
            } else if (status === "3") {
              this.affirm.affirmFrom = "toVip";
              this.affirm.affirmMsge = result.data.err;
            } else if (status === "1") {
              this.affirm.affirmFrom = "ok";
              this.affirm.affirmTitle = "联系电话";
              this.affirm.affirmMsge = `<span style="color: #191919;font-size: 16px">${result.data.link_tel}</span>`;
            }
            this.affirm.isopenAffirm = true;
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style>
.take-detail-btm-view {
  display: flex;
  width: 1480px;
  margin: 0 auto;
}

.take-detail-left-view {
}
.take-detail-left-view .takeshop-comm-view {
  width: 952px;
  padding: 36px 40px;
  background-color: #fff;
  border-radius: 16px 16px 16px 16px;
  margin-bottom: 30px;
}
.take-detail-left-view .take-inf-view .inf-item {
  margin-bottom: 30px;
}
.take-detail-left-view .take-inf-view .inf-item .tt1 {
  font-size: 20px;

  font-weight: 400;
  color: #757575;
  line-height: 28px;
}

.take-detail-left-view .take-inf-view .inf-item .tt2 {
  font-size: 20px;

  font-weight: 600;
  color: #191919;
  line-height: 28px;
}

.take-detail-left-view .link-man-inf-view {
  display: flex;
  align-items: center;
  position: relative;
  border-top: 4px solid #f5f6fa;
  padding-top: 30px;
}

.take-detail-left-view .link-man-inf-view .link-avatar {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}
.take-detail-left-view .link-man-inf-view .link-name-add {
  margin-left: 18px;
}

.take-detail-left-view .link-man-inf-view .link-name-add .name {
  font-size: 18px;

  font-weight: 400;
  color: #222222;
  line-height: 24px;
  display: flex;
  align-items: center;
}
.take-detail-left-view .link-man-inf-view .link-name-add .name label {
  display: inline-flex;
  align-items: center;
  font-size: 14px;

  font-weight: 400;
  color: #939597;
  line-height: 16px;
  margin-left: 12px;
}
.take-detail-left-view .link-man-inf-view .link-name-add .name .zx-dian {
  display: inline-block;
  width: 6px;
  height: 6px;
  background-color: #ff8136;
  border-radius: 50%;
  margin-right: 5px;
}

.take-detail-left-view .link-man-inf-view .link-name-add .addre-text {
  font-size: 16px;

  font-weight: 400;
  color: #191919;
  line-height: 19px;
  margin-top: 16px;
}

.take-detail-left-view .link-man-inf-view .lin-btn-view {
  position: absolute;
  right: 0;
}
.take-detail-left-view .link-man-inf-view .lin-btn-view .el-button--primary {
  width: 435px;
  height: 70px;
  font-size: 24px;

  font-weight: 600;
}
.take-detail-left-view .yq-title {
  font-size: 32px;

  font-weight: 600;
  color: #191919;
  line-height: 38px;
}
.take-detail-left-view .yq-text {
  white-space: pre-wrap;
  font-size: 18px;

  font-weight: 400;
  color: #191919;
  line-height: 24px;
  margin-top: 24px;
}
.take-detail-right-view {
  margin-left: 45px;
}
.take-detail-right-view .sac-ewm-img {
  width: 403px;
  height: 320px;
}
.share-qrcode-image {
  height: 240px;
  width: 240px;
  background-color: white;
  position: absolute;
  top: 25px;
  left: -140px;
  border: 1px solid #d9d9d9;
}
.share-qrcode-triangle {
  width: 20px;
  height: 20px;
  background-color: white;
  transform: rotate(45deg);
  position: absolute;
  left: -35px;
  top: 15px;
  border: 2px solid #939597;
}
#qrcode-image-div {
  z-index: 1;
}
.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>