<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2024-01-12 16:45:42
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-06-05 17:49:35
 * @FilePath: /lcwpcvue/src/components/comUser/comChatedUserItem.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="com-chatuser-item-page">
    <label v-for="item in listArr" :key="item.uid">
      <div
        class="resume-manage-item-view"
        :class="item.uid == isHover ? 'hover-css' : ''"
        @mouseover="hadlerMouseOver(item.uid)"
        @mouseout="hadlerMoseOut"
        @click="toItemDetail(item.uid)"
      >
        <div class="resume-manage-item-top-view">
          <div class="inf-top-left-view">
            <div class="avatar-img-view">
              <img class="avata-img" :src="item.resume_photo" alt="" />
              <img
                v-show="item.sex_name == '男'"
                class="sex-img"
                src="@/assets/gender_nan.png"
                alt=""
              />
              <img
                v-show="item.sex_name == '女'"
                class="sex-img"
                src="@/assets/gender_nv.png"
                alt=""
              />
            </div>
            <div class="name-tag-age-view">
              <!-- v-show="item.newtab[2] && item.newtab[2] != ''" -->
              <div class="name-tag-view">
                <span class="name">{{ item.name }}</span>
                <!-- <span v-show="item.top == 1" class="tag1">置顶</span>
                <span class="tag2">兼职</span> -->
              </div>
              <div class="age-deu-exp">
                <span>{{ item.age }}</span>
                <el-divider direction="vertical"></el-divider>
                <span>{{ item.edu_name }}</span>
                <el-divider direction="vertical"></el-divider>
                <span>{{ item.exp_name }}</span>
              </div>
            </div>
          </div>
          <div class="inf-top-right-view">
            <div class="qw-jb-ct-item">
              <img
                class="qw-img"
                src="@/assets/talentsdetail_qzzt_img.png"
                alt=""
              />
              <span class="tt1">期望职位：</span>
              <span
                class="tt2"
                v-for="(job, index) in item.job_class"
                :key="index"
                >{{ job }}
                <span
                  v-show="item.job_class && index != item.job_class.length - 1"
                  >、</span
                ></span
              >
            </div>
            <div class="qw-jb-ct-item">
              <img class="qw-img" src="@/assets/user_card_city.png" alt="" />
              <span class="tt1">期望城市：</span>
              <span
                class="tt2"
                v-for="(city, index) in item.city_class"
                :key="index"
                >{{ city }}
                <span
                  v-show="
                    item.city_class && index != item.city_class.length - 1
                  "
                  >/</span
                ></span
              >
            </div>
          </div>
        </div>
        <div class="resume-manage-item-bottom-view">
          <div class="resume-bz-time-view">
            <div class="resume-time-view" v-show="listType == 'chated'">
              {{ item.time }} 与你沟通过
            </div>
            <div class="resume-time-view" v-show="listType == 'collection'">
              {{ item.time }} 你收藏了TA
            </div>
            <div class="resume-time-view" v-show="listType == 'wlook'">
              {{ item.time }} 浏览了你
            </div>
            <div class="resume-time-view" v-show="listType == 'mlook'">
              {{ item.time }} 看了TA
            </div>

            <!-- <div class="resume-bz-view" v-show="item.note != ''">
              备注：{{ item.note }}
            </div> -->
          </div>
          <div class="right-btn-view" style="top: -1px" v-show="$store.getters.userinf.ad_login ==='1' && listType === 'wlook'">
            <el-button
                icon="el-icon-delete"
                @click.stop="deleteLook(item.id)"
            >删除</el-button
            >
          </div>
          <!-- <div class="right-btn-view">
            <el-button
              icon="el-icon-chat-dot-round"
              @click.stop="toChatPage(item.uid)"
              >沟通</el-button
            >
            <el-button
              v-show="item.is_unmatch != 1"
              icon="el-icon-chat-dot"
              @click.stop="doesnFit(item.uid)"
              >不合适</el-button
            >
            <el-button
              type="primary"
              v-show="item.is_unmatch == 1"
              icon="el-icon-chat-dot"
              @click.stop="doesnFit(item.uid)"
              >不合适</el-button
            >
            <el-button
              icon="el-icon-edit-outline"
              @click.stop="addResuBz(item.uid)"
              >备注</el-button
            >
          </div> -->
        </div>
      </div>
    </label>
  </div>
</template>

<script>
import {delResuManagement} from "@/api/compe/aboutResumeMa";

export default {
  props: {
    listArr: {
      type: Array,
    },
    listType: {
      type: String,
    },
  },
  data() {
    return {
      isHover: 0,
    };
  },
  methods: {
    deleteLook(id){
      this.$confirm('确定要删除吗？','警告',{
        confirmButtonText:'确认',
        cancelButtonText:"取消",
        type:"error"
      }).then(()=>{
        console.log('resume_seelog',':',id)
        delResuManagement(id,'resume_seelog').then(e=>{
          this.$message.success(e.msg);
          this.$emit('updateList');
        });
      }).catch(()=>{});
    },
    hadlerMouseOver(id) {
      // console.log("悬停");
      this.isHover = id;
    },
    hadlerMoseOut() {
      this.isHover = 0;
    },
    toItemDetail(id) {
      this.$router.push(`/talentsdetail?id=${id}`);
    },
  },
};
</script>

<style>
.resume-manage-item-view {
  width: 1148px;
  background-color: #fff;
  padding: 24px 24px;
  margin-top: 16px;
}

.com-chatuser-item-page .hover-css {
  border: 1px solid #fe6002;
  background-color: #fff5f0;
}
.resume-manage-item-top-view {
  display: flex;
  align-items: center;
}
.resume-manage-item-top-view .inf-top-left-view {
  display: flex;
  align-items: center;
  width: 600px;
}

.resume-manage-item-top-view .inf-top-left-view .avatar-img-view {
  position: relative;
}

.resume-manage-item-top-view .inf-top-left-view .avatar-img-view .avata-img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}
.resume-manage-item-top-view .inf-top-left-view .avatar-img-view .sex-img {
  width: 22px;
  height: 22px;
  position: absolute;
  bottom: 0;
  right: 2px;
}

.resume-manage-item-top-view .inf-top-left-view .name-tag-age-view {
  margin-left: 24px;
}

.resume-manage-item-top-view
  .inf-top-left-view
  .name-tag-age-view
  .name-tag-view {
  display: flex;
  align-items: center;
}

.resume-manage-item-top-view
  .inf-top-left-view
  .name-tag-age-view
  .name-tag-view
  .name {
  font-weight: 600;
  font-size: 22px;
  color: #191919;
  line-height: 28px;
}
.resume-manage-item-top-view
  .inf-top-left-view
  .name-tag-age-view
  .name-tag-view
  .tag1,
.resume-manage-item-top-view
  .inf-top-left-view
  .name-tag-age-view
  .name-tag-view
  .tag2 {
  width: 32px;
  height: 16px;
  margin-left: 12px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;

  font-weight: 400;
}
.resume-manage-item-top-view
  .inf-top-left-view
  .name-tag-age-view
  .name-tag-view
  .tag1 {
  background-color: #ffd9c3;
  color: #f34b37;
}
.resume-manage-item-top-view
  .inf-top-left-view
  .name-tag-age-view
  .name-tag-view
  .tag2 {
  background-color: #b3dfff;
  color: #0294fe;
}
.resume-manage-item-top-view
  .inf-top-left-view
  .name-tag-age-view
  .age-deu-exp {
  font-weight: 400;
  font-size: 16px;
  color: #303030;
  line-height: 19px;
  margin-top: 16px;
}

.resume-manage-item-top-view .inf-top-right-view {
  border-left: 1px solid#EAECF5;
  height: 65px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 30px;
}

.resume-manage-item-top-view .inf-top-right-view .qw-jb-ct-item {
  display: flex;
  align-items: center;
}
.resume-manage-item-top-view .inf-top-right-view .qw-jb-ct-item .qw-img {
  width: 20px;
  height: 20px;
  margin-right: 6px;
}
.resume-manage-item-top-view .inf-top-right-view .qw-jb-ct-item .tt1 {
  font-weight: 400;
  font-size: 16px;
  color: #b1b1b1;
  line-height: 19px;
}

.resume-manage-item-top-view .inf-top-right-view .qw-jb-ct-item .tt2 {
  font-weight: 400;
  font-size: 18px;
  color: #191919;
  line-height: 24px;
}
.resume-manage-item-bottom-view {
  display: flex;
  margin-top: 16px;
  border-top: 1px solid#EAECF5;
  padding-top: 16px;
  position: relative;
  padding-bottom: 16px;
}

.resume-manage-item-bottom-view .resume-bz-time-view {
  font-weight: 400;
  font-size: 16px;
  color: #b1b1b1;
  line-height: 19px;
  width: 800px;
}
.resume-manage-item-bottom-view .resume-bz-time-view .resume-bz-view {
  margin-top: 12px;
}

.resume-manage-item-bottom-view .right-btn-view {
  position: absolute;
  right: 0;
}
.resume-manage-item-bottom-view .right-btn-view .el-button {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
</style>