<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2024-02-26 11:01:17
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-02-26 11:03:29
 * @FilePath: /lcwpcvue/src/views/vForgetPass.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="com-regist">
    <forgetpass class="wxsc-view"></forgetpass>
  </div>
</template>

<script>
import forgetpass from "@/components/forgetPass.vue";

export default {
  components: {
    forgetpass,
  },
};
</script>

<style>
.wxsc-view {
  /* margin-top: 11.87rem; */
  margin-top: 171px;
}
</style>