<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2024-01-12 16:26:50
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-06-11 17:58:12
 * @FilePath: /lcwpcvue/src/components/comUser/resumeManage.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="resume-manage-page">
    <div style="height: 30px"></div>
    <div class="common-title-view" style="margin-bottom: 15px">
      <span class="left-xian-view"></span>
      <span class="text">简历管理</span>
    </div>

    <div class="coupon-navbar-view">
      <div class="nav-item" @click="changeNavBarId('received')">
        <div
          class="nav-text"
          :class="navbarId == 'received' ? 'nav-sltext' : ''"
        >
          收到的（{{ navNub.received }}）
        </div>
        <div v-show="navbarId == 'received'" class="bot-xian"></div>
      </div>
      <div class="nav-item" @click="changeNavBarId('down')">
        <div class="nav-text" :class="navbarId == 'down' ? 'nav-sltext' : ''">
          下载的（{{ navNub.down }}）
        </div>
        <div v-show="navbarId == 'down'" class="bot-xian"></div>
      </div>
      <div class="nav-item" @click="changeNavBarId('invitation')">
        <div
          class="nav-text"
          :class="navbarId == 'invitation' ? 'nav-sltext' : ''"
        >
          邀请的（{{ navNub.invitation }}）
        </div>
        <div v-show="navbarId == 'invitation'" class="bot-xian"></div>
      </div>
      <div class="nav-item" @click="changeNavBarId('report')">
        <div class="nav-text" :class="navbarId == 'report' ? 'nav-sltext' : ''">
          举报的（{{ navNub.report }}）
        </div>
        <div v-show="navbarId == 'report'" class="bot-xian"></div>
      </div>
    </div>

    <div class="resume-marage-flter-view" v-if="navbarId == 'received'">
      <div class="flter-job-name">
        <div class="flter-job">
          <span class="resflter-title">招聘职位</span>
          <el-select
            class="resflter-sele"
            v-model="userJobId"
            filterable
            clearable
            reserve-keyword
            automatic-dropdown
            placeholder="请选择"
            @visible-change="remoteMethod"
            @change="userJobIdChange"
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="flter-name">
          <div>
            <el-input
              class="flter-name-input"
              placeholder="请输入姓名查找"
              v-model="userName"
            >
              <el-button
                @click="toFlterByUsername"
                slot="append"
                icon="el-icon-search"
              ></el-button>
            </el-input>
          </div>
        </div>
      </div>

      <div class="flter-resume-status">
        <span class="resflter-title">简历状态：</span>
        <span
          @click="chageFlterRsumeStatus('')"
          class="resume-status-item"
          :class="resumeStatus == '' ? 'is-slect' : ''"
          >不限</span
        >
        <span
          class="resume-status-item"
          :class="resumeStatus == '1' ? 'is-slect' : ''"
          @click="chageFlterRsumeStatus('1')"
          >未查看</span
        >
        <span
          class="resume-status-item"
          :class="resumeStatus == '2' ? 'is-slect' : ''"
          @click="chageFlterRsumeStatus('2')"
          >已查看</span
        >
        <span
          class="resume-status-item"
          :class="resumeStatus == '3' ? 'is-slect' : ''"
          @click="chageFlterRsumeStatus('3')"
          >不合适</span
        >
      </div>
    </div>
    <div class="resume-marage-flter-view" v-if="navbarId == 'down'">
      <div class="flter-job-name">
     
        <div class="flter-name">
          <div>
            <el-input
              class="flter-name-input"
              placeholder="请输入姓名查找"
              v-model="userName"
            >
              <el-button
                @click="toFlterByUsername"
                slot="append"
                icon="el-icon-search"
              ></el-button>
            </el-input>
          </div>
        </div>
      </div>
    </div>
    <div class="resume-marage-flter-view" v-if="navbarId == 'report'">
      <div class="flter-job-name">
       
        <div class="flter-name">
          <div>
            <el-input
              class="flter-name-input"
              placeholder="请输入姓名查找"
              v-model="userName"
            >
              <el-button
                @click="toFlterByUsername"
                slot="append"
                icon="el-icon-search"
              ></el-button>
            </el-input>
          </div>
        </div>
      </div>
    </div>

    <div class="resume-item-view">
      <resumemanageitem
        :resumeArr="resumeArr"
        :tabId="navbarId"
        @addResuBz="addResuBz"
        @doesnFit="doesnFit"
        @deleteObj="deleteObj"
      ></resumemanageitem>

      <div v-show="resumeArr.length == 0" class="nocoupon-list-view">
        <img class="none-list-img" src="@/assets/transfer_is_null.png" alt="" />
        <div class="none-text">暂无还没有相关的信息</div>
      </div>

      <el-pagination
        v-show="resumeArr.length != 0"
        @current-change="pagesChage"
        background
        layout="prev, pager, next"
        :page-count="allPage"
        :current-page.sync="currentpage"
        class="pag-ina"
      >
      </el-pagination>
    </div>

    <el-dialog title="添加备注" :visible.sync="bzDialogVisibleShow" width="40%">
      <div class="bz-dialog-view">
        <div>
          <el-input
            type="textarea"
            placeholder="请详细描述你的问题"
            v-model="bzTextareaTxt"
            maxlength="200"
            show-word-limit
            rows="10"
          >
          </el-input>
        </div>
        <div class="bot-btn">
          <el-button @click="bzDialogVisibleShow = false">取消</el-button>
          <el-button type="primary" @click="addBzFun">保存</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import resumemanageitem from "@/components/comUser/resumeManageItem.vue";
import {
  getResumeManageNub,
  getResumeManageList,
  addResumeNote,
  doesnFit,
  getMyAllJobs, delResuManagement,
} from "@/api/compe/aboutResumeMa.js";
export default {
  components: {
    resumemanageitem,
  },
  data() {
    return {
      navbarId: "received",
      navNub: {},
      resumeArr: [],
      bzDialogVisibleShow: false,
      bzTextareaTxt: "",
      reUid: "",
      pageIndex: 1,
      allPage: 0,
      currentpage: 0,
      resumeStatus: "",
      userName: "",
      userJobId: "",
      options: [],
      // userJobIdName: '全部职位',
    };
  },
  created() {
    this.getResumeManageNub();
    this.getResumeManageList(this.navbarId, 1);
  },
  methods: {
    deleteObj(id,type){
      this.$confirm('确定要删除吗？','警告',{
        confirmButtonText:'确认',
        cancelButtonText:"取消",
        type:"error"
      }).then(()=>{
        delResuManagement(id,type).then(e=>{
          this.$message.success(e.msg);
          this.getResumeManageNub();
          this.getResumeManageList(this.navbarId, this.pageIndex);
        });
      }).catch(()=>{});
    },
    chageFlterRsumeStatus(e) {
      this.resumeStatus = e;
      this.userJobId = ''
      this.userName = ''
      this.pageIndex = 1;
      this.currentpage = 1;
      this.getResumeManageList(this.navbarId, 1);
    },

    userJobIdChange(e) {
      this.userJobId = e === 'all' ? '' : e;
      this.pageIndex = 1;
      this.currentpage = 1;
      this.getResumeManageList(this.navbarId, 1);
    },
    remoteMethod(e) {
      if (e) {
        getMyAllJobs()
          .then((result) => {
            console.log("获取职位》》》", result);
            this.options = result.data;
            if (this.options.length > 0){
              this.options.unshift({
                id:'all',
                name:'全部'
              });
            }
          })
          .catch(() => {});
      }
    },
    toFlterByUsername() {
      if (!this.userName) {
        return;
      }
      this.pageIndex = 1;
      this.currentpage = 1;
      this.getResumeManageList(this.navbarId);
    },
    getResumeManageNub() {
      getResumeManageNub()
        .then((result) => {
          console.log("获取数字信息", result);
          this.navNub = result.data;
        })
        .catch(() => {});
    },
    getResumeManageList(type) {
      getResumeManageList({
        column: type,
        page: this.pageIndex,
        status: this.resumeStatus,
        userJobId: this.userJobId,
        username: this.userName,
      })
        .then((result) => {
          console.log("获取简历管理", result);
          this.resumeArr = result.data.list;
          this.allPage = parseInt(result.data.allpage);

          if (result.data.list.length > 0 && this.navbarId == "received") {
            this.resumeArr.forEach((e) => {
              console.log(e.is_admin);
              if (e.is_admin === "1") {
                e.is_rec = "平台推荐";
              } else {
                e.is_rec = "";
              }
            });
          }
        })
        .catch(() => {});
    },
    pagesChage(e) {
      this.pageIndex = e;
      window.scrollTo(200, 200);
      this.getResumeManageList(this.navbarId, 1);
    },
    addResuBz(uid) {
      this.reUid = uid;
      this.bzDialogVisibleShow = true;
    },
    changeNavBarId(e) {
      this.navbarId = e;
      this.pageIndex = 1;
      this.currentpage = 1;
      this.getResumeManageList(e, 1);
    },
    addBzFun() {
      addResumeNote({
        uid: this.reUid,
        content: this.bzTextareaTxt,
      })
        .then((result) => {
          console.log("添加备注", result);
          if (result.code == 200) {
            this.$message({
              message: "添加成功！",
              type: "success",
            });
            this.getResumeManageList(this.navbarId, this.pageIndex);
            this.bzDialogVisibleShow = false;
          }
        })
        .catch(() => {});
    },
    /* 不合适 */
    doesnFit(uid) {
      doesnFit({ to_uid: uid })
        .then((result) => {
          console.log("并不合适", result);
          if (result.code == 200) {
            this.$message({
              message: "操作成功！",
              type: "success",
            });
            this.getResumeManageList(this.navbarId, this.pageIndex);
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style>
.resume-marage-flter-view {
  width: 1196px;
  padding: 30px 0;
  background-color: #fff;
  margin-top: 20px;
}
.resume-marage-flter-view .resflter-title {
  font-size: 20px;
  color: #333;
}
.resume-marage-flter-view .flter-job-name {
  display: flex;
  align-items: center;
  margin-left: 20px;
}
.resume-marage-flter-view .flter-job-name .flter-job .resflter-sele {
  margin-left: 10px;
  width: 300px;
}

.resume-marage-flter-view .flter-job-name .flter-name .flter-name-input {
  margin-left: 20px;
  width: 400px;
}

.resume-marage-flter-view .flter-resume-status {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-left: 20px;
}

.resume-marage-flter-view .flter-resume-status .resume-status-item {
  display: inline-block;
  color: #222;
  font-size: 21px;
  margin-right: 20px;
  margin-left: 10px;
  padding: 8px 13px;
  border-radius: 30px;
}

.resume-marage-flter-view .flter-resume-status .is-slect {
  color: #fff;
  background-color: #fe6002;
}

.resume-manage-page .el-dialog__wrapper .el-dialog {
  border-radius: 20px;
}
.coupon-navbar-view {
  /*margin-top: 17px;*/
}
.bz-dialog-view {
  border-top: 1px solid #eaecf5;
  padding: 20px 34px;
}
.bz-dialog-view .bot-btn {
  text-align: right;
}

.resume-manage-page .bz-dialog-view .bot-btn .el-button {
  width: 160px;
  height: 44px;
  font-size: 18px;
}

.resume-manage-page .bz-dialog-view .bot-btn .el-button--default {
  background-color: #eaecf5;
}

.resume-manage-page .bz-dialog-view .el-textarea__inner {
  font-size: 18px;
  resize: none;
}
.resume-manage-page .bz-dialog-view .el-input__count {
  font-size: 16px;
}
</style>