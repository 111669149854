<template>
  <div>
    <div style="clear:both"></div>
    <div v-for="im in myHelpList" :key="im.id"  class="my-feedback-list" @click="openDetails(im)">
      <div >
        <div class="my-feedback-div">
          <span v-if="im.reply" class="feedback-reply">[已回复]</span>
          <span v-html="im.content" class="my-feedback-content"></span>
        </div>
        <p class="my-feedback-time">{{im.createtime}}</p>
      </div>
      <div> <i class="el-icon-arrow-right"></i> </div>
    </div>
    <slot></slot>
  </div>
</template>
<script>
import {getFeedList} from "@/api/commen/helpAndFeebback";
import {parseTime} from "@/utils/index.js";
export default {
  name:'question',
  props:['getDetails'],
  components:{},
  data(){
    return{
      myHelpList: []
    }
  },
  methods:{
    getMyFeedbackList() {
      getFeedList(1).then(req => {
        this.myHelpList = req.data.FeedList;
        req.data.FeedList.forEach(function (item) {
          if (item.createtime){
            item.createtime = parseTime(item.createtime,"{y}-{m}-{d} {h}:{i}");
          }
          if (item.replytime){
            item.replytime = parseTime(item.replytime,"{y}-{m}-{d} {h}:{i}");
          }
        })
      });
    },
    openDetails(item){
      this.getDetails(item);
    }
  }
}
</script>
<style>
.my-feedback-list{
  height: 88px;
  border-bottom: 1px solid #e6e6e6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer
}
.my-feedback-div{
  width: 1000px;
  height: 28px;
  overflow: hidden;
}
.my-feedback-content{
  font-size: 18px;
  
  font-weight: 600;
  color: #191919;
  line-height: 28px;
  margin-bottom: 16px;
}
.my-feedback-time{
  font-size: 16px;
  
  font-weight: 400;
  color: #666666;
  line-height: 19px;
}
.feedback-reply{
  color: #fe6002;
  margin-right: 10px;
  font-size: 18px;
}
</style>