<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2024-03-06 15:28:47
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-05-10 15:34:06
 * @FilePath: /lcwpcvue/src/components/reportDialog.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="report-dialog-page">
    <el-dialog
      :title="dlTitle"
      :visible.sync="reportDialogShow"
      width="40%"
      @open="dialogOpne"
      @close="dialogClose"
    >
      <div class="report-view">
        <el-radio-group v-model="reportRadio">
          <label v-for="(item, index) in reportRadioArr" :key="index">
            <el-radio class="radio" size="medium" :label="item.id">{{
              item.name
            }}</el-radio>
          </label>
        </el-radio-group>
        <el-input
          class="report-textarea"
          type="textarea"
          placeholder="请输入内容"
          v-model="repDesp"
          maxlength="200"
          show-word-limit
          rows="7"
        >
        </el-input>
        <span slot="footer" class="dialog-footer">
          <el-button @click="reportDialogShow = false">取 消</el-button>
          <el-button type="primary" @click="postReport">确 定</el-button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getReportRadios, postReport } from "@/api/commen/aboutTalentsApi.js";
export default {
  name: "reportdialog",
  props: {
    rpUid: {
      type: String,
    },
    cuid: {
      type: String,
    },
    dlTitle: {
      type: String,
    },
    reportDialogShow: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      uid: "",
      usertype: 2,
      reportRadioArr: [],
      reportRadio: "",
      type: 0,
      repDesp: "",
    };
  },
  created() {
    this.usertype = localStorage.usertype;
    // this.uid = this.$store.getters.uid;
    if (this.usertype == 1) {
      this.type = 0;
    } else {
      this.type = 1;
    }
    this.getReportRadios();
  },
  methods: {
    dialogOpne() {
      console.log("9090909");
    },
    dialogClose() {
      this.$emit("closeReportDialog");
    },

    getReportRadios() {
      getReportRadios()
        .then((result) => {
          console.log("获取举报选项", result);
          this.reportRadioArr = result.data.list;
        })
        .catch(() => {});
    },
    postReport() {
      postReport({
        type: this.type,
        reason_class: this.reportRadio,
        eid: this.rpUid,
        c_uid: this.rpUid,
        r_reason: this.repDesp,
        r_pic: "",
      })
        .then((result) => {
          console.log("提交举报", result);
          this.reportDialogShow = false;

          if (result.code == 200) {
            this.$message({
              message: result.msg,
              type: "success",
              center: true,
            });
          }
        })
        .catch(() => {
          this.reportDialogShow = false;
        });
    },
  },
};
</script>

<style>
.report-dialog-page .el-dialog__wrapper .el-dialog {
  border-radius: 15px;
}

.report-dialog-page .report-view {
  padding: 30px 32px;
}

.report-dialog-page .report-view .el-radio__inner {
  width: 15px;
  height: 15px;
}
.report-dialog-page .report-view .radio {
  margin-right: 20px;
  margin-bottom: 10px;
}
.report-dialog-page .report-view .report-textarea {
  margin-top: 30px;
}

.report-dialog-page .report-view .el-textarea__inner {
  font-size: 18px;
  resize: none;
}
.report-dialog-page .report-view .el-input__count {
  font-size: 16px;
}
.report-dialog-page .report-view .dialog-footer {
  display: inline-block;
  margin-top: 24px;
  width: 100%;
  text-align: right;
}
</style>