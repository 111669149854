 
<template>
  <div class="cropper-heard-img-page">
    <el-dialog
      title="头像裁剪"
      :visible.sync="cropperDialogShow"
      append-to-body
      @close="closeCropper"
    >
      <div class="cropper-content">
        <div class="cropper" style="text-align: center">
          <vue-cropper
            ref="cropper"
            :img="cropperImg"
            :output-size="option.size"
            :output-type="option.outputType"
            :info="true"
            :full="option.full"
            :can-move="option.canMove"
            :can-move-box="option.canMoveBox"
            :fixed-box="option.fixedBox"
            :original="option.original"
            :auto-crop="option.autoCrop"
            :auto-crop-width="option.autoCropWidth"
            :auto-crop-height="option.autoCropHeight"
            :center-box="option.centerBox"
            :high="option.high"
            :info-true="option.infoTrue"
            :enlarge="option.enlarge"
            :fixed="option.fixed"
            :fixed-number="fixedNumber"
          />
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <!-- <el-button @click="closeCropper">取 消</el-button> -->
        <el-button type="primary" @click="finish" :loading="loading"
          >确认</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "cropper",
  props: {
    cropperDialogShow: {
      type: Boolean,
      default: false,
    },
    imgType: {
      type: String,

      default: "blob",
    },

    cropperImg: {
      type: String,

      default: "",
    },

    fixedNumber: {
      type: Array,

      default: () => [1, 1],
    },
  },
  data() {
    return {
      // 裁剪配置
      option: {
        img: "", // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 1, // 裁剪生成图片的质量
        outputType: "jpg", // 裁剪生成图片的格式
        canScale: true, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        // autoCropWidth: 300, // 默认生成截图框宽度
        // autoCropHeight: 300, // 默认生成截图框高度
        fixedBox: true, // 固定截图框大小不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [1, 1], // 截图框的宽高比例
        full: false, // 是否输出原图比例的截图
        canMoveBox: false, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: true, // 截图框是否被限制在图片里面
        infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      },
      // 防止重复提交
      loading: false,
      // 裁剪过后的图片
      imgUrl: "",
    };
  },

  methods: {
    // 确认裁剪
    finish() {
      this.$refs.cropper.getCropData((data) => {
        this.uploadFile = data;
        this.$emit("upload-img", data);
      });

      // this.$refs.cropper.getCropData((data) => {
      //   console.log(data);
      //   this.loading = true;
      //   // let formData = new FormData();
      //   // 以表单的形式将数据传给服务端
      //   // formData.append("imgBase64", data);
      //   // 此处上传的地址为模拟地址

      //   // 将base64字符串转换为二进制数据
      //   let binaryData = atob(data.split(",")[1]);
      //   // 创建ArrayBuffer
      //   let arrayBuffer = new ArrayBuffer(binaryData.length);
      //   let intArray = new Uint8Array(arrayBuffer);
      //   // 将二进制字符串转换为ArrayBuffer的字节
      //   for (let i = 0; i < binaryData.length; i++) {
      //     intArray[i] = binaryData.charCodeAt(i);
      //   }
      //   // 创建Blob对象
      //   let blob = new Blob([intArray], { type: "image/jpeg" });
      //   // 创建URL
      //   let imageUrl = URL.createObjectURL(blob);
      //   console.log(imageUrl);

      //   // this.$http.post("saveBase64", formData).then((result) => {
      //   //   if (result.code == 10000) {
      //   //     this.loading = false;
      //   //     this.dialogVisible = false;

      //   //     // 获取服务端返回的裁剪图片地址
      //   //     this.imgUrl = result.url;
      //   //   }
      //   // });
      // });
    },
    // 关闭裁剪
    closeCropper() {
      this.$emit("closeCropper");
      // this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
// 图片裁剪样式
.cropper-content {
  .cropper {
    width: auto;
    height: 300px;
  }
}
</style>

