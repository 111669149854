<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-07-18 17:36:37
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-06-03 10:41:29
 * @FilePath: /lcwpcvue/src/components/comUser/comUserCard.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="com-user-card">
    <label v-for="(item, index) in mqarr" :key="item.id">
      <div
        class="com-inf-view"
        :class="item.uid == comUid ? 'mouse-over' : ''"
        @click="toCompanyDetail(item.uid)"
        @mouseover="hadlerMouseOver(item.uid)"
        @mouseout="hadlerMoseOut"
      >
        <div class="con-head-img">
          <!-- <img :src="item.hot_pic" /> -->
          <el-image :src="item.hot_pic"></el-image>
        </div>
        <div class="com-name">{{ item.username }}</div>
        <!--        <div class="com-add-exp-edu">
          <span>邹平市</span>
          <el-divider direction="vertical"></el-divider>
          <span>经验不限</span>
          <el-divider direction="vertical"></el-divider>
          <span>学历不限</span>
        </div>-->
        <div class="mo-hover-hind-view" v-show="item.uid == comUid">
          <div class="items-view">
            <label v-for="jb in item.jobList" :key="jb.id">
              <div class="item" @click="toJobInfDetail(jb.id)">
                · {{ jb.name }}
              </div>
            </label>
          </div>
        </div>
      </div>
      <div v-show="(index + 1) % 4 != 0" class="ccard-fgx"></div>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    mqarr: {
      type: Array,
    },
  },
  data() {
    return {
      comUid: 0,
    };
  },
  methods: {
    toJobInfDetail(id) {
      this.$router.push(`/jobinfopage?id=${id}`);
    },
    toCompanyDetail(id) {
      this.$router.push(`/companyinf?id=${id}`);
    },
    hadlerMouseOver(id) {
      console.log("悬停");
      this.comUid = id;
    },
    hadlerMoseOut() {
      this.comUid = 0;
    },
  },
};
</script>

<style>
.com-user-card .com-inf-view {
  box-sizing: border-box;
  width: 340px;
  height: 208px;
  background: #ffffff;
  border-radius: 16px;
  text-align: center;
  display: inline-block;
  margin-top: 24px;
  position: relative;
  cursor: pointer;
}
.com-user-card .com-inf-view:hover {
  border: 1px solid #ff8136;
}
.mouse-over {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.com-inf-view .con-head-img img {
  width: 130px;
  height: 130px;
  border-radius: 8px;
  margin-top: 20px;
}
.com-inf-view .com-name {
  font-size: 20px;
  font-weight: 600;
  color: #191919;
  line-height: 28px;
  width: 80%;
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.com-inf-view .com-add-exp-edu {
  font-size: 14px;

  font-weight: 400;
  color: #757575;
  line-height: 16px;
  margin-top: 16px;
  padding-bottom: 24px;
}
.mo-hover-hind-view {
  box-sizing: border-box;
  width: 340px;
  text-align: left;
  position: absolute;
  left: -1px;
  background-color: #ffffff;
  border-right: 1px solid #ff8136;
  border-left: 1px solid #ff8136;
  border-bottom: 1px solid #ff8136;
  z-index: 99;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}
.mo-hover-hind-view .items-view {
  border-top: 2px solid #eaecf5;
  padding: 20px 0 24px 0;
  margin: 0 18px;
}
.mo-hover-hind-view .items-view .item:hover {
  background-color: rgba(255, 129, 54, 0.15);
}

.mo-hover-hind-view .item {
  font-size: 16px;

  font-weight: 400;
  color: #ff8136;
  line-height: 30px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>