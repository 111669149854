<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-05-18 16:03:59
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-03-20 10:33:25
 * @FilePath: /lcwpcvue/src/components/forgetPass.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->


<template>
  <div class="fg-view">
    <div class="title">找回密码</div>
    <div class="step-view">
      <el-steps :active="prog" align-center :space="1550">
        <el-step title="请填写账号">
          <i class="step01" slot="icon"></i>
        </el-step>
        <el-step title="重置密码">
          <i class="step02" slot="icon"></i>
        </el-step>
        <el-step title="修改成功">
          <i class="step03" slot="icon"></i>
        </el-step>
      </el-steps>
    </div>
    <div class="sendcode">
      <el-form
        :model="ruleForm"
        status-icon
        :rules="rules"
        ref="ruleForm"
        class="demo-ruleForm"
      >
        <label v-show="prog == 1">
          <el-form-item class="input-view" label="">
            <el-input
              placeholder="请输入手机号或邮箱"
              type="text"
              v-model="moblie"
              clearable
            >
            </el-input>
          </el-form-item>

          <el-form-item class="input-view code-view" label="" prop="checkCode">
            <el-input
              v-model="phoneCode"
              type="text"
              placeholder="请输入验证码"
              clearable
            >
            </el-input>

            <el-button v-show="!smsFlag" type="text" @click="sendPhoneCode"
              >发送验证码
            </el-button>
            <el-button v-show="smsFlag" type="text" @click="sendCode" disabled
              >{{ sendTime }}
            </el-button>
          </el-form-item>
        </label>
        <label v-show="prog == 2">
          <el-form-item class="input-view" label="" prop="pass">
            <el-input
              type="password"
              v-model="ruleForm.pass"
              autocomplete="off"
              placeholder="请输入密码"
            ></el-input>
          </el-form-item>
          <div class="passwqd">
            <span :class="[level.includes('low') ? 'passlow' : '']">危险</span>
            <span :class="[level.includes('middle') ? 'passmiddle' : '']"
              >一般</span
            >
            <span :class="[level.includes('high') ? 'passhigh' : '']"
              >安全</span
            >
          </div>
          <el-form-item class="input-view" label="" prop="checkPass">
            <el-input
              type="password"
              v-model="ruleForm.checkPass"
              autocomplete="off"
              placeholder="请再次输入密码"
            ></el-input>
          </el-form-item>
        </label>

        <label v-show="prog == 3">
          <div class="succ-img">
            <img src="../assets/login/forgetpasssucc.png" alt="" />
            <span>密码重置成功！</span>
          </div>
        </label>
        <el-form-item class="subm">
          <el-button type="primary" @click="submitForm(btnType)"
            >{{ submitBtnText }}
          </el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="botm-tetx">
      <div class="tt1">温馨提示：</div>
      <div class="tt2">
        如<label>手机号已丢失且未绑定邮箱</label>，请联系在线客服,或拨打全国统一服务热线：{{
          kefuPhone
        }}
      </div>
    </div>
  </div>
</template>


<script>
import { userPcApi } from "@/api/commen/setApi";
import { sendPhoneCode, updatdePassword } from "@/api/commen/login";
import { Message } from "element-ui";
import { rsaEncrypt } from "@/utils/Rsa/rsa";

export default {
  // name: "forgetPass",
  data() {
    // var validatePass = (rule, value, callback) => {
    //   if (value === "") {
    //     callback(new Error("请输入密码"));
    //   } else {
    //     if (this.ruleForm.checkPass !== "") {
    //       this.$refs.ruleForm.validateField("checkPass");
    //     }
    //     callback();
    //   }
    // };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      prog: 1,
      usertype: 0,
      moblie: "",
      smsFlag: false,
      sendTime: "重新获取",
      snsMsgWait: 60,
      phoneCode: "",
      btnType: "code",
      ruleForm: {
        pass: "",
        checkPass: "",
      },
      rules: {
        username: [
          { required: false, message: "手机号不能为空", trigger: "blur" },
        ],
        checkCode: [
          { required: true, message: "验证码不能为空", trigger: "blur" },
        ],

        moblie: [
          {
            required: true,
            message: "手机号不能为空",
            trigger: "blur",
          },
        ],
        pass: [{ validator: this.checkPassword, trigger: "change" }],
        checkPass: [{ validator: validatePass2, trigger: "blur" }],
        // code: [
        // { required: true, message: "验证码不能为空", trigger: "blur" },
        // ]

        // age: [{ validator: checkAge, trigger: "blur" }],
      },
      submitBtnText: "下一步",
      level: [],
      kefuPhone: "",
      ischooseEmail: 0,
    };
  },
  created() {
    this.usertype = localStorage.usertype;
    this.getKufuPhone();
  },
  methods: {
    getKufuPhone() {
      userPcApi({ type: "getKefuPhone" }).then((res) => {
        this.kefuPhone = res.data.phone;
      });
    },
    updatdePassword() {
      updatdePassword({
        moblie: this.moblie,
        usertype: this.usertype,
        password: rsaEncrypt(this.ruleForm.pass),
        code: this.phoneCode,
        ip: this.$store.state.user.userip,
        isEmail: this.ischooseEmail,
      })
        .then((result) => {
          console.log("更新密码", result);
          if (result.code == 200) {
            this.prog = 3;
            this.btnType = "succ";
          }
        })
        .catch(() => {});
    },
    isEmail(str) {
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return emailPattern.test(str);
    },
    sendPhoneCode() {
      if (this.moblie == "") {
        Message({
          message: "请输入手机号或邮箱",
          type: "error",
          duration: 3 * 1000,
          showClose: true,
          offset: 100,
        });
        return;
      }
      if (this.isEmail(this.moblie)) {
        this.ischooseEmail = 1;
        userPcApi({ type: "sendEmail", newEmail: this.moblie }).then((req) => {
          this.countdown();
          this.$message.success(req.msg);
        });
      } else {
        if (this.moblie.length != 11) {
          Message({
            message: "请输入正确的手机号",
            type: "error",
            duration: 3 * 1000,
            showClose: true,
            offset: 100,
          });
          return;
        }
        if (!this.validatePhoneNumber()) {
          Message({
            message: "请输入正确的手机号",
            type: "error",
            duration: 3 * 1000,
            showClose: true,
            offset: 100,
          });
          return;
        }
        this.countdown();
        sendPhoneCode({
          usertype: this.usertype,
          event: "forgetpass",
          moblie: this.moblie,
        })
          .then((result) => {
            console.log("发送验证码", result);
            if (result.code == 200) {
              Message({
                message: "发送成功",
                type: "success",
                duration: 3 * 1000,
                showClose: true,
                offset: 100,
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    validatePhoneNumber() {
      const reg = /^1[3456789]\d{9}$/;
      if (!reg.test(this.moblie)) {
        // 手机号码不符合规则
        return false;
      }
      // 手机号码符合规则
      return true;
    },

    countdown() {
      // 60秒后重新获取验证码
      this.smsFlag = true;
      this.sendTime = this.snsMsgWait + "s后重发";
      this.snsMsgWait = this.snsMsgWait - 1;

      var inter = setInterval(
        function () {
          this.smsFlag = true;
          this.bordercolor = "no";
          this.sendTime = this.snsMsgWait + "s后重发";
          this.snsMsgWait = this.snsMsgWait - 1;
          if (this.snsMsgWait < 0) {
            clearInterval(inter);
            this.bordercolor = "basics";
            this.sendColor = "#02c598";
            this.sendTime = "获取验证码";
            this.snsMsgWait = 60;
            this.smsFlag = false;
          }
        }.bind(this),
        1000
      );
    },

    submitForm(e) {
      if (e === "code") {
        if (this.phoneCode == "") {
          Message({
            message: "验证码不能为空",
            type: "error",
            duration: 3 * 1000,
            showClose: true,
            offset: 100,
          });
          return;
        }
        userPcApi({
          type: "checkCode",
          phone: this.moblie,
          event: this.ischooseEmail === 1 ? "email" : "forgetpass",
          code: this.phoneCode,
        }).then(() => {
          this.prog = 2;
          this.btnType = "pass";
        });
      } else if (e === "pass") {
        this.updatdePassword();
      } else if (e === "succ") {
        this.$router.push("/login");
      }
    },

    // 校验密码
    checkPassword(rule, value, callback) {
      this.level = [];
      // if (!value) {
      //   return callback("密码不能为空");
      // }
      // if (value.length < 8) {
      //   return callback("密码不少于8位");
      // }
      if (value.length > 16) {
        return callback("密码不大于16位");
      }
      // 校验是数字
      const regex1 = /^\d+$/;
      // 校验字母
      const regex2 = /^[A-Za-z]+$/;
      // 校验符号
      const regex3 =
        /^[`~!@#$%^&*()_\-+=<>?:"{}|,.\\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]+$/;
      if (regex1.test(value)) {
        this.level.push("low");
      } else if (regex2.test(value)) {
        this.level.push("low");
      } else if (regex3.test(value)) {
        this.level.push("low");
      } else if (/^[A-Za-z\d]+$/.test(value)) {
        this.level.push("low");
        this.level.push("middle");
      } else if (
        /^[`~!@#$%^&*()_\-+=<>?:"{}|,.\\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、\d]+$/.test(
          value
        )
      ) {
        this.level.push("low");
        this.level.push("middle");
      } else if (
        /^[`~!@#$%^&*()_\-+=<>?:"{}|,.\\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、A-Za-z]+$/.test(
          value
        )
      ) {
        this.level.push("low");
        this.level.push("middle");
      } else if (
        /^[`~!@#$%^&*()_\-+=<>?:"{}|,.\\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、A-Za-z\d]+$/.test(
          value
        )
      ) {
        this.level.push("low");
        this.level.push("middle");
        this.level.push("high");
      }
      return callback();
    },
  },
};
</script>

<style>
.fg-view {
  width: 1003px;
  height: 800px;
  background-color: #ffffff;
  border-radius: 20px;
}

.fg-view .title {
  width: 866px;
  margin-left: 68px;
  font-size: 30px;
  
  font-weight: 600;
  color: #222222;
  border-bottom: 1px solid #dddddd;
  padding: 30px 0;
}

.fg-view .step-view {
  /* width: 1003px; */
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.fg-view .step-view .el-steps {
  /* flex: 1; */
  width: 600px;
}

.el-step__head {
}

.step-view .is-process .el-step__icon,
.step-view .is-wait .el-step__icon {
  background: #dddddd;
}

/* is-wait */
.step-view .is-finish .el-step__icon {
  background: #ff8136;
}

.step-view .el-step__icon i {
  color: #ffffff;
}

.step-view .el-step__line {
  margin-top: 20px;
}

.step-view .el-step__title {
  font-size: 16px;
  
  font-weight: 500;
}

.step-view .el-step__main .is-process,
.step-view .el-step__main .is-wait {
  color: #939597;
}

.step-view .el-step__icon {
  width: 63px;
  height: 63px;
}

.step01,
.step02,
.step03 {
  width: 60px;
  height: 60px;
  background-size: 100% 100%;
}

.step01 {
  background-image: url("~@/assets/forgetpass_1.png");
}

.step02 {
  background-image: url("~@/assets/forgetpass_3.png");
}

.step03 {
  background-image: url("~@/assets/forgetpass_5.png");
}

.el-step.is-horizontal .el-step__line {
  top: 50%;
  /* left: 94px;
  right: 48px; */
}

.el-step__head.is-process {
  border-color: #dedede;
}

.el-step__head.is-finish {
  border-color: #ff8136;
}

.el-step__title.is-process {
  color: #dedede;
}

.el-step__title.is-finish {
  color: #ff8136;
}

.el-step__icon {
  width: 40px;
  height: 40px;
}

.is-finish .step02 {
  background-image: url("~@/assets/forgetpass_2.png");
}

.is-finish .step03 {
  background-image: url("~@/assets/forgetpass_4.png");
}

.sendcode {
  width: 678px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 163px;
}

.demo-ruleForm {
  width: 100%;
  /* margin-top: 0.37rem; */
}

.sendcode .code-view .code-x {
  /* width: 0.1rem; */
  height: 30px;
  background-color: #c1c1c1;
  position: absolute;
  /* left: 10.95rem; */
  right: 162px;
}

.fg-view .sendcode .code-view .el-button {
  /* width: 5.05rem; */
  height: 80px;
  color: #fe6002;
  position: absolute;
  right: 32px;
  font-size: 20px;
  
  font-weight: 400;
}

.fg-view .sendcode .codeclear {
  position: absolute;
  line-height: 60px;
  right: 30px;
}

.sendcode .code-view .codeclear {
  right: 180px;
}

.sendcode .el-form-item--feedback .el-input__validateIcon {
  display: none;
}

.sendcode .el-form-item {
  margin-bottom: 0;
}

.sendcode .input-view {
  margin-top: 45px;
  height: 60px;
}

.fg-view .subm {
  margin-top: 122px;
  width: 678px !important;
}

.fg-view .subm .el-button {
  width: 369px;
  height: 60px;
  font-size: 20px;
}

.botm-tetx {
  margin-top: 52px;
  font-size: 16px;
  
  font-weight: 400;
  color: #222222;
  margin-left: 163px;
}

.botm-tetx .tt2 {
  margin-top: 5px;
}

.botm-tetx .tt2 label {
  color: #f34b37;
}

.passwqd {
  margin-top: 20px;
}

.passwqd span {
  display: inline-block;
  width: 219px;
  height: 30px;
  background-color: #f5f6fa;
  font-size: 14px;
  
  font-weight: 400;
  color: #757575;
  line-height: 30px;
  margin-right: 10px;
}

.passwqd span:last-child {
  margin-right: 0;
}

.passwqd .passlow {
  background-color: #f34b37;
  color: #fff;
}

.passwqd .passmiddle {
  background-color: #3291e1;
  color: #fff;
}

.passwqd .passhigh {
  background-color: #59d773;
  color: #fff;
}

.succ-img {
  margin-top: 51px;
}

.succ-img img {
  width: 241px;
  height: 215px;
}

.succ-img span {
  display: block;
  font-size: 24px;
  
  font-weight: 600;
  color: #222222;
  margin-top: 9px;
}

.fg-view .el-input--suffix .el-input__inner {
  height: 80px;
  font-size: 20px;
}
</style>