import request from "@/utils/request";

// export function userPcApi(obj) {
//     return request({
//         url: 'UserPc/api',
//         method: 'post',
//         params:obj
//     })
// }
export function helpIndex(type){
    return request({
        url: 'Help/index',
        method: 'post',
        params:{
            'type': type
        }
    })
}
export function submitFeedback(obj){
    return request({
        url: 'Feedback/submitFeedback',
        method: 'post',
        data:obj
    })
}
export function getFeedList(page){
    return request({
        url: 'Feedback/getFeedList',
        method: 'get',
        params:{
            page:page
        }
    })
}
