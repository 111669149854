<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2024-03-04 10:29:52
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-03-07 17:29:43
 * @FilePath: /lcwpcvue/src/views/myEquityPage.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="user-home-page">
    <div class="top-zk-div"></div>
    <userinfleftmen
      :activeIndex="'/myequitypage'"
      class="left-menu"
    ></userinfleftmen>
    <myequitypage
      :on-update="updatePageIndexData"
      class="userinf-index"
    ></myequitypage>
  </div>
</template>

<script>
import userinfleftmen from "@/components/userInfPageLeftMenu.vue";

import myequitypage from "@/components/comUser/myEquityPage.vue";

export default {
  components: {
    userinfleftmen,
    myequitypage,
  },
};
</script>

<style>
</style>