import request from '@/utils/request'


/**
 * 处理待面试
 * @param id 待面试ID
 */
export function interviewInfoResu(id) {
    return request({
        url: 'Interview/interviewInfoResu',
        method: 'post',
        data:{
            inter_id:id
        }
    })
}