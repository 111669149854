
export function turnoverTime(timestamp){
    const now = new Date().getTime();
    timestamp = timestamp * 1000;
    const diff = now - timestamp;
    if (diff < 3600000) {
        let fen = Math.floor(diff / 60000);
        return (fen <= 0 ? 1 : fen) + '分钟内 更新';
    } else if (diff < 86400000) {
        let h = Math.floor(diff / 3600000);
        return (h <= 0 ? 1 : h) + '小时内 更新';
    } else if (diff < 604800000) {
        let tian = Math.floor(diff / 86400000);
        return (tian <= 0 ? 1 : tian) + '天前 更新';
    } else {
        const date = new Date(timestamp);
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        return year + '-' + month + '-' + day + " 更新";
    }
}