<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-06-19 14:41:42
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-03-20 09:28:01
 * @FilePath: /lcwpcvue/src/views/comregist.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="com-regist">
    <PageHeader class="head-view" :userTypeName="userTypeName"></PageHeader>
    <comreggisterview class="topview"></comreggisterview>
  </div>
</template>

<script>
import PageHeader from "@/components/pageHeader.vue";
import comreggisterview from "@/components/comUser/comBasInf.vue";
export default {
  components: {
    PageHeader,
    comreggisterview,
    // ForgetPass,
    // LoginView,
    // WxsvLogin,
  },
  data() {
    return {};
  },
};
</script>

<style>
.com-regist {
  width: 1920px;
  height: 100%;
  background: linear-gradient(316deg, #ff8840 0%, #fffbf6 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.topview {
  margin-top: 171px;
}
</style>