<template>
  <div class="deliver-job">
    <div v-for="(item,idx) in jobList" :key="idx"
         class="dj-item" :style="item.is_browse === '2' ? 'background-color: rgba(255, 255, 255,0.5);' : ''"
         @click="toDetailPage(item.id)">
<!--      右边-->
      <div class="dj-item-left">
        <div class="dj-item-left-title">
          <span>{{ item.name }}</span>
        </div>
        <div class="dj-item-centre">
          <span class="dj-item-salary" v-show="item.minsalary !== '0'">{{item.minsalary}}-{{item.maxsalary}} 元/月</span>
          <span class="dj-item-salary" v-show="item.minsalary === '0'">面议</span>
          <span class="dj-item-condition">{{item.city}}</span>
          <span v-show="item.exp_name">
            <el-divider direction="vertical"></el-divider>
            <span class="dj-item-condition">{{item.exp_name}}</span>
          </span>
          <span v-show="item.edu_name">
            <el-divider direction="vertical"></el-divider>
            <span class="dj-item-condition">{{item.edu_name}}</span>
          </span>
        </div>
        <div v-show="item.welfare.length > 0">
          <div class="dj-item-wf">
            <div v-for="(wf,idx) in item.welfare" :key="idx" >{{ wf }}</div>
          </div>
        </div>
      </div>
<!--      左边-->
      <div class="dj-item-right">
        <div class="dj-right-comname">
          <span >{{item.com_name}}</span>
          <img v-show="item.yyzz_status === '1'" src="@/assets/company_rz_img.png" class="dj-right-comname-rz">
        </div>
        <div class="dj-time-name">{{item.lastupdate_name}}</div>
        <div v-show="item.is_browse === '2'" class="dj-time-name"><span>已被查看</span></div>
<!--        <div style="margin: 10px 0 18px 0;text-align: end">
          <span class="dj-item-condition">{{item.hy}}</span>
          <span v-show="item.mun">
            <el-divider direction="vertical"></el-divider>
            <span class="dj-item-condition">{{item.mun}}</span>
          </span>
          <span v-show="item.sdate">
            <el-divider direction="vertical"></el-divider>
            <span class="dj-item-condition">{{item.sdate}}</span>
          </span>
          <span v-show="item.area">
            <el-divider direction="vertical"></el-divider>
            <span class="dj-item-condition">{{item.area}}m²</span>
          </span>
        </div>-->
<!--        <div class="dj-right-date">
          <span>{{item.lastupdate}}更新</span>
        </div>-->
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "deliverJob",
  props:['jobList'],
  data() {
    return {
    }
  },
  methods: {
    toDetailPage(id) {
      let newRouter = this.$router.resolve({
        path: `/jobinfopage?id=${id}`
      });
      window.open(newRouter.href, "_blank")
    },
  }
}

</script>

<style scoped>
.deliver-job .dj-item{
  
  width: 1148px;
  /*height: 124px;*/
  background-color: #ffffff;
  margin-bottom: 10px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  padding: 30px 24px;
  cursor: pointer;
}
.deliver-job .dj-item .dj-item-left-title{
  font-weight: 600;
  font-size: 22px;
  color: #191919;
  line-height: 28px;
  font-style: normal;
  text-transform: none;
}
.deliver-job .dj-item .dj-item-salary{
  font-weight: 600;
  font-size: 20px;
  color: #FE6002;
  margin-right: 15px;
}
.deliver-job .dj-item .dj-item-condition{
  font-weight: 400;
  font-size: 14px;
  color: #757575;
  line-height: 16px;
  text-align: left;
  font-style: normal;
  text-transform: none;

}
.deliver-job .dj-item .dj-item-wf{
  display: flex;
  flex-wrap: wrap;
  max-width: 820px;
}
.deliver-job .dj-item .dj-item-wf div{
  font-weight: 400;
  font-size: 14px;
  color: #757575;
  line-height: 28px;
  text-align: center;
  margin:0 10px 5px 0;
  padding: 0 15px;
  background: #F5F6FA;
  border-radius: 8px;
}
.deliver-job .dj-item .dj-item-centre{
  margin: 12px 0 16px 0;
}
.deliver-job .dj-right-comname{
    text-align: end;
    display: flex;
    align-items: center;
    justify-content: end;
}
.deliver-job .dj-right-comname .dj-right-comname-rz{
  width: auto;
  height: 19px;
  margin-left: 5px;
}
.deliver-job .dj-right-comname span{
  font-weight: 400;
  font-size: 18px;
  color: #191919;
  line-height: 24px;
}

.deliver-job .dj-item-right{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.deliver-job .dj-right-date{
  font-weight: 400;
  font-size: 14px;
  color: #757575;
  line-height: 16px;
  text-align: end;
}
.deliver-job .dj-item:hover{
  background-color: #fff5f0;
}
.dj-time-name{
  font-weight: 400;
  font-size: 14px;
  color: #757575;
  line-height: 16px;
  text-align: end;
  margin-top: 10px;
}
</style>