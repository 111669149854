<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2024-04-22 18:03:31
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-06-07 16:56:24
 * @FilePath: /lcwpcvue/src/views/partTimeJobDetail.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="parttime-job-inf-page">
    <div class="top-zk-div"></div>
    <div style="background-color: white; width: 100%">
      <div class="top-jobb-inf-view">
        <div class="left-title-gz-ll">
          <div class="title-tag-gz-view">
            <div class="job-title">{{ jobInfo.name }}</div>
            <div class="tags" v-show="jobInfo.urgent_time > 0">急聘</div>
          </div>
          <div class="add-exp-edu-view">
            <div class="job-gz" v-show="jobInfo.salary_type != 1">
              {{ jobInfo.salary }}
            </div>
            <div class="job-gz" v-show="jobInfo.salary_type == 1">面议</div>
            <!-- <el-divider direction="vertical"></el-divider>
            <div class="job-gz">{{jobInfo.}}</div> -->
          </div>
          <div class="lab-view">
            <label v-for="(item, index) in jobInfo.welfare" :key="index">
              <span class="lab-item">{{ item }}</span>
            </label>
          </div>
          <div class="up-look-jb-view">
            <div class="items">
              <img src="../assets/jobinf_uptime_img.png" alt="" />
              <span>更新于 {{ jobInfo.time }}</span>
            </div>
            <div class="items">
              <img src="../assets/jobinf_look_img.png" alt="" />
              <span>浏览 {{ jobInfo.hits }}次</span>
            </div>
            <!-- <div class="items">
              <img src="../assets/jobinf_jb_img.png" alt="" />
              <span>举报</span>
            </div> -->
          </div>
        </div>
        <div class="btn-share-view" v-show="!isYl">
          <div class="btn-view">
            <el-button
              v-show="isApply == 0"
              type="primary"
              class="btn2"
              @click="replacePartJob"
              >申请职位</el-button
            >
            <el-button v-show="isApply == 1" type="primary" class="btn2"
              >已申请</el-button
            >
          </div>
          <div class="shar-clo-view">
            <el-popover placement="bottom" width="200" trigger="click">
              <div class="share-ewm-img">
                <img :src="shareEwmImg" alt="" />
              </div>
              <div class="items" slot="reference" @click="getShareEwm">
                <img src="../assets/jobinf_share_img.png" alt="" />
                <span>分享</span>
              </div>
            </el-popover>
            <div class="items" @click="collectionPartJob">
              <!-- <img src="../assets/jobinf_clo_img.png" alt="" />
              <span>收藏</span> -->
              <span v-show="isCollect === '1'">
                <i class="el-icon-star-on" style="font-size: 14px"></i>
                <span style="vertical-align: top">已收藏</span>
              </span>
              <span v-show="isCollect === '0'">
                <i class="el-icon-star-off" style="font-size: 14px"></i>
                <span style="vertical-align: top">收藏</span>
              </span>
            </div>
          </div>
        </div>
        <div class="btn-share-view" v-show="isYl">
          <el-button type="primary" class="btn2" @click="toEdtJobInf"
            >编辑</el-button
          >
          <div class="shar-clo-view">
            <div class="items" @click="delJob">
              <img src="@/assets/user_resume_del.png" alt="" />
              <span>删除</span>
            </div>
            <div class="items" @click="closeJob">
              <img src="@/assets/yl_job_colse_job.png" alt="" />
              <span>关闭</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="min-view">
      <div class="">
        <div class="com-sty zw-detail-view">
          <div class="title-one">职位发布者</div>
          <div class="lin-inf-view">
            <img class="lin-user-head-img" :src="jobInfo.logo" alt="" />
            <div class="lin-inf-text-view">
              <div class="top">
                <span class="nikname">{{ jobInfo.linkman }}</span>
              </div>
              <div class="lin-ze-tel-view">
                <img src="@/assets/setPageIndex/phone_set.png" alt="" />
                <span v-show="!isReplace">{{ jobInfo.linktel_jm }}</span>
                <span v-show="isReplace">{{ jobInfo.linktel }}</span>
              </div>
            </div>
            <!-- <el-button class="right-lil-btn" type="primary" @click="toChatPage"
              >立即沟通</el-button
            > -->
            <div class="right-lil-btn" type="primary" @click="toChatPage">
              立即沟通
            </div>
          </div>
          <div class="link-tips-view">
            <span class="link-tips1">点击申请职位查看联系方式</span>
            <span class="link-tips2" @click="replacePartJob">申请职位</span>
            <span class="link-tips3">（联系时请说明是在猎宠网上看到的）</span>
          </div>
        </div>

        <div class="com-sty zw-detail-view">
          <div class="title-one">职位描述</div>
          <div class="work-titme-view">
            职位需求：
            <div style="margin-top: 10px" v-show="jobInfo.mun_name">
              <img src="@/assets/partjob_pop_num.png" alt="" />
              <span>招聘 {{ jobInfo.number }} 人</span>
            </div>
            <div v-show="jobInfo.work_shift_name">
              <img src="@/assets/partjob_sex_img.png" alt="" />
              <span>{{ jobInfo.work_shift_name }}</span>
            </div>
          </div>

          <div class="job-time-table">
            <div class="time-title">工作时间：</div>
            <div class="time_border">
              <div class="b_sw b_th">
                <span></span>
                <div class="text">周一</div>
                <div class="text">周二</div>
                <div class="text">周三</div>
                <div class="text">周四</div>
                <div class="text">周五</div>
                <div class="text">周六</div>
                <div class="text">周日</div>
              </div>
              <div class="b_sw">
                <span>上午</span>
                <div class="text" v-for="(item, index) in morning" :key="index">
                  <i
                    class="el-icon-check"
                    style="color: #fe6002"
                    v-if="item == 1"
                  ></i>
                </div>
              </div>
              <div class="b_sw">
                <span>下午</span>
                <div class="text" v-for="(item, index) in noon" :key="index">
                  <i
                    class="el-icon-check"
                    style="color: #fe6002"
                    v-if="item == 1"
                  ></i>
                </div>
              </div>
              <div class="b_sw">
                <span>晚上</span>
                <div
                  class="text"
                  v-for="(item, index) in afternoon"
                  :key="index"
                >
                  <i
                    class="el-icon-check"
                    style="color: #fe6002"
                    v-if="item == 1"
                  ></i>
                </div>
              </div>
            </div>
          </div>

          <div class="job-ms-view">
            <div class="ms-title">任职要求</div>
            <div class="ms-tetail">
              {{ jobInfo.content }}
            </div>
          </div>
        </div>

        <div class="com-sty com-addr">
          <div class="title-one">工作地点</div>
          <div class="addr-view">
            <img src="../assets/jobinf_addr_img.png" alt="" />
            <span>{{ jobInfo.address }}</span>
          </div>
          <div class="map">
            <zczmap
              v-if="jobInfo.x"
              :lat="jobInfo.y"
              :lng="jobInfo.x"
              idname="jobmap"
              :mapwidth="'780'"
              :mapheight="'335'"
            ></zczmap>
          </div>
        </div>
        <div class="com-sty min-ju-view">
          <div class="lc-ts-view">
            <img src="../assets/jobinf_minjb_img.png" alt="" />
            <span>猎宠网安全提示：</span>
          </div>
          <div class="jb-text">
            求职中如遇到招聘方存在虚假信息，收取费用，扣押证件，或其他违法情形，请立即<a
              @click="toReport"
              >举报！</a
            >
          </div>
        </div>
        <div class="com-sty job-card-list-view" v-show="recJobList.length > 0">
          <div class="title-one">推荐职位</div>
          <label v-for="(item, index) in recJobList" :key="item.id">
            <jobinfcardinjobinf
              class="inucad"
              :jobItem="item"
            ></jobinfcardinjobinf>
            <div v-show="(index + 1) % 3 != 0" class="card-fgx"></div>
          </label>
        </div>
      </div>
      <div class="">
        <companyinjobinf
          class="com-inf-car"
          :companyInf="jobInfo"
          :imgurls="jobShowImg"
        ></companyinjobinf>

        <div class="jobdetail-tjjob-view">
          <div class="tj-title-view">
            <span class="tj-view">该公司其TA职位</span>
          </div>
          <div class="tj-list">
            <label v-for="item in comIdJobList" :key="item.id">
              <div class="tjitem">
                <div class="tj-top-view">
                  <span class="tj-t1">{{ item.name }}</span>
                  <span class="spar-gz"
                    >￥{{ item.minsalary }}-{{ item.maxsalary }}</span
                  >
                </div>
                <div class="com-name">
                  {{ item.com_name }}
                </div>
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>
    <reportdialog
      :reportDialogShow="reportDialogShow"
      :dlTitle="'举报职位'"
      :rpUid="jobid"
      @closeReportDialog="closeReportDialog"
    ></reportdialog>
  </div>
</template>

<script>
import jobinfcardinjobinf from "@/components/comUser/jobInfCardInJobInf.vue";
import companyinjobinf from "@/components/individualUser/companyInJobInf.vue";
import {
  getJobDetails,
  getRecommendJobList,
  getJobListByComId,
  deliverResume,
  collectionPartJob,
  checkUserIsCanChat,
} from "@/api/indiviuser/jobInfoPage";
import { checkIsLahe } from "@/api/commen/aboutTalentsApi.js";
import { userPcApi } from "@/api/commen/transferShop";
import { colseMyFullJob, delMyFullJob } from "@/api/compe/jobsApi.js";
// companyInJobInf
import reportdialog from "@/components/reportDialog.vue";

export default {
  name: "jobinf",
  components: {
    jobinfcardinjobinf,
    companyinjobinf,
    reportdialog,
  },
  data() {
    return {
      jobid: "",
      jobInfo: {},
      jobLinkInf: {},
      comIdJobList: {},
      isYl: false,
      isPartJob: 1,
      reportDialogShow: false,
      recJobList: [],
      morning: [{}, {}, {}, {}, {}, {}, {}],
      noon: [{}, {}, {}, {}, {}, {}, {}],
      afternoon: [{}, {}, {}, {}, {}, {}, {}],
      isReplace: false,
      isApply: 0,
      isCollect: 0,
      shareEwmImg: "",
      jobShowImg: "",
    };
  },
  created() {
    console.log("aaa", this.$route);
    this.jobid = this.$route.query.id;
    if (this.$route.query.isyl) {
      this.isYl = true;
    }
  },
  mounted() {
    this.getRecommendJobList();
    this.getJobDetails();
    this.getRecJobList();
  },

  methods: {
    getRecJobList() {
      if (!localStorage.getItem("token")) return;
      getRecommendJobList({ id: this.jobid }).then((res) => {
        console.log("getRecommendJobList");
        console.log(res);
        if (res.data.length > 0) {
          this.recJobList = res.data;
          sessionStorage.setItem(
            "getRecommendJobList",
            JSON.stringify(res.data)
          );
        }
      });
    },
    //获取职位信息详情
    getJobDetails() {
      getJobDetails({ job_id: this.jobid, is_part: this.isPartJob })
        .then((result) => {
          console.log("职位信息", result);
          result.data.job_info.x = parseFloat(result.data.job_info.x);
          result.data.job_info.y = parseFloat(result.data.job_info.y);
          var jobinf = result.data.job_info;
          this.jobInfo = jobinf;
          this.isApply = result.data.is_apply;
          this.isCollect = result.data.is_coll;
          this.center = [jobinf.x, jobinf.y];
          this.jobLinkInf = result.data.recruit;
          var reg = /^(\d{3})\d{4}(\d{4})$/;
          // result.data.job_info.linktel
          result.data.job_info.linktel_jm =
            result.data.job_info.linktel.replace(reg, "$1****$2");
          let morning = result.data.job_info.worktime_arr.morning;
          let noon = result.data.job_info.worktime_arr.noon;
          let afternoon = result.data.job_info.worktime_arr.afternoon;
          morning.length = 7;
          noon.length = 7;
          afternoon.length = 7;
          morning.forEach((item) => {
            if (item == "周一") {
              morning[0] = 1;
            }
            if (item == "周二") {
              morning[1] = 1;
            }
            if (item == "周三") {
              morning[2] = 1;
            }
            if (item == "周四") {
              morning[3] = 1;
            }
            if (item == "周五") {
              morning[4] = 1;
            }
            if (item == "周六") {
              morning[5] = 1;
            }
            if (item == "周日") {
              morning[6] = 1;
            }
          });
          noon.forEach((item) => {
            if (item == "周一") {
              noon[0] = 1;
            }
            if (item == "周二") {
              noon[1] = 1;
            }
            if (item == "周三") {
              noon[2] = 1;
            }
            if (item == "周四") {
              noon[3] = 1;
            }
            if (item == "周五") {
              noon[4] = 1;
            }
            if (item == "周六") {
              noon[5] = 1;
            }
            if (item == "周日") {
              noon[6] = 1;
            }
          });
          afternoon.forEach((item) => {
            if (item == "周一") {
              afternoon[0] = 1;
            }
            if (item == "周二") {
              afternoon[1] = 1;
            }
            if (item == "周三") {
              afternoon[2] = 1;
            }
            if (item == "周四") {
              afternoon[3] = 1;
            }
            if (item == "周五") {
              afternoon[4] = 1;
            }
            if (item == "周六") {
              afternoon[5] = 1;
            }
            if (item == "周日") {
              afternoon[6] = 1;
            }
          });
          this.morning = morning;
          this.noon = noon;
          this.afternoon = afternoon;
          var imgs = [];
          result.data.job_show.forEach((element) => {
            if (element.type == "img") {
              imgs.push(element.picurl);
            }
          });
          this.jobShowImg = imgs;
          this.getJobListByComId();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    toChatPage() {
      if (!localStorage.getItem("token")) {
        this.$store.dispatch("user/setUserType", 1);
        this.$cookies.set("usertype", 1);
        localStorage.setItem("usertype", 1);
        this.$router.push("/login");
        return;
      }
      checkUserIsCanChat()
        .then((result) => {
          console.log("ooopppooo", result);
          if (result.data.score > result.data.myscore || result.code == 102) {
            // 102简历未通过审核
            this.$message({
              message: result.msg,
              type: "warning",
            });
            // uni.showToast({
            //   title: res.msg,
            //   icon: "none",
            // });
            setTimeout(() => {
              this.$router.push(`/useredtresume`);
            }, 1500);
          } else {
            checkIsLahe({ to_uid: this.jobInfo.uid })
              .then((result) => {
                console.log("是否拉黑", result);
                if (result.data.hmd == 1) {
                  this.$confirm(
                    "您已将对方拉黑，确认要给他发送消息吗？",
                    "提示",
                    {
                      confirmButtonText: "确定",
                      cancelButtonText: "取消",
                      type: "warning",
                    }
                  )
                    .then(() => {
                      this.$router.push(`/chatpage?chatid=${this.jobInfo.uid}`);
                    })
                    .catch(() => {});
                } else {
                  if (result.data.to_hmd == 1) {
                    this.$confirm(
                      "对方已将你拉黑，您不能发送消息给TA!",
                      "提示",
                      {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                      }
                    )
                      .then(() => {})
                      .catch(() => {});
                  } else {
                    this.$router.push(`/chatpage?chatid=${this.jobInfo.uid}`);
                  }
                }
              })
              .catch(() => {});
          }
        })
        .catch(() => {});
    },
    replacePartJob() {
      if (!localStorage.getItem("token")) {
        this.$store.dispatch("user/setUserType", 1);
        this.$cookies.set("usertype", 1);
        localStorage.setItem("usertype", 1);
        this.$router.push("/login");
        return;
      }
      // this.isReplace = true;

      deliverResume(this.jobid, this.jobInfo.uid, 0)
        .then((result) => {
          console.log("投递简历", result);
          if (result.code == 200) {
            this.$message({
              message: "申请成功",
              type: "success",
            });
            this.getJobDetails();
          }
        })
        .catch(() => {});
    },
    /* 预览职位 */
    // getJobInfByYl() {
    //   let data = localStorage.zwdetails;
    //   getJobInfByYl()
    //     .then((res) => {
    //       console.log("预览职位", res);
    //       let resData = res.data;
    //       if (this.parttimejob) {
    //         this.jobInfo.name = data.name;
    //         this.jobInfo.provinceid = data.provinceid;
    //         if (
    //           data.address.indexOf("北京") > -1 ||
    //           data.address.indexOf("天津") > -1 ||
    //           data.address.indexOf("上海") > -1 ||
    //           data.address.indexOf("重庆") > -1
    //         ) {
    //           this.jobInfo.province_name = data.address.split("市")[0] + "市";
    //           this.jobInfo.pcity_name =
    //             data.address.split("市")[1].split("区")[0] + "区";
    //         } else {
    //           this.jobInfo.province_name = data.address.split("省")[0] + "省";
    //           this.jobInfo.pcity_name =
    //             data.address.split("省")[1].split("市")[0] + "市";
    //         }
    //         this.addrLth = data.address.split("").length;
    //         this.jobInfo.cityid = data.cityid;
    //         this.jobInfo.three_cityid = data.three_cityid;
    //         this.jobInfo.three_city_name = "";
    //         this.jobInfo.description = data.content;
    //         if (data.content.length > 100) {
    //           this.more = data.content.slice(0, 100);
    //         }
    //         this.m.forEach((it, index) => {
    //           data.worktime.forEach((i) => {
    //             if (it.id == i) {
    //               this.morning[index] = 1;
    //             }
    //           });
    //         });
    //         this.n.forEach((it, index) => {
    //           data.worktime.forEach((i) => {
    //             if (it.id == i) {
    //               this.noon[index] = 1;
    //             }
    //           });
    //         });
    //         this.a.forEach((it, index) => {
    //           data.worktime.forEach((i) => {
    //             if (it.id == i) {
    //               this.afternoon[index] = 1;
    //             }
    //           });
    //         });
    //         this.jobInfo.x = parseFloat(data.x);
    //         this.jobInfo.y = parseFloat(data.y);
    //         this.datas.is_part = "1";
    //         this.jobInfo.salary = data.salary + data.sltname;
    //       } else {
    //         this.jobInfo.name = data.name;
    //         this.jobInfo.minsalary = data.minsalary;
    //         this.jobInfo.maxsalary = data.maxsalary;
    //         this.jobInfo.salary_type = data.release_type;
    //         this.jobInfo.description = data.description;
    //         this.jobInfo.edu = data.edu;
    //         this.jobInfo.edu_name = data.eduval;
    //         this.jobInfo.exp = data.exp;
    //         this.jobInfo.exp_name = data.expval;
    //         this.jobInfo.provinceid = data.provinceid;

    //         if (
    //           data.address.indexOf("北京") > -1 ||
    //           data.address.indexOf("天津") > -1 ||
    //           data.address.indexOf("上海") > -1 ||
    //           data.address.indexOf("重庆") > -1
    //         ) {
    //           this.jobInfo.province_name = data.address.split("市")[0] + "市";
    //           this.jobInfo.pcity_name =
    //             data.address.split("市")[1].split("区")[0] + "区";
    //         } else {
    //           this.jobInfo.province_name = data.address.split("省")[0] + "省";
    //           this.jobInfo.pcity_name =
    //             data.address.split("省")[1].split("市")[0] + "市";
    //         }
    //         this.jobInfo.cityid = data.cityid;
    //         this.jobInfo.three_cityid = data.three_cityid;
    //         this.jobInfo.three_city_name = "";
    //         this.jobInfo.address = data.address;
    //         this.jobInfo.x = parseFloat(data.x);
    //         this.jobInfo.y = parseFloat(data.y);
    //         if (data.description.length > 100) {
    //           this.more = data.description.slice(0, 100);
    //         }
    //       }
    //       this.jobInfo.status = "0";
    //       this.datas.job_show = resData.show.allshow;
    //       this.jobInfo.com_name = resData.info.name;
    //       this.jobInfo.mun = resData.info.mun;
    //       this.jobInfo.mun_name = resData.info.mun_name;
    //       this.jobInfo.welfare = resData.welfare.welfare_list;
    //       this.jobInfo.logo = resData.info.logo;
    //       this.jobInfo.hy = resData.info.hy;
    //       this.jobInfo.hy_name = resData.info.hy_name;
    //       var i = [];
    //       resData.show.allshow.forEach((it) => {
    //         i.push({
    //           picurl: it,
    //           type: "img",
    //         });
    //       });
    //       this.imglist = i;
    //       if (resData.show.video != "") {
    //         var v = [
    //           {
    //             picurl: resData.show.video,
    //             coverurl: resData.show.video_cover,
    //           },
    //         ];
    //         this.videolist = v;
    //       }

    //       if (this.videolist.length === 0) {
    //         this.type = 2;
    //       }
    //       console.log(this.imglist, this.videolist);
    //     })
    //     .catch(() => {});
    // },
    //获取推荐的职位
    getRecommendJobList() {
      if (!localStorage.getItem("token")) return;
      getRecommendJobList({ id: this.jobid })
        .then((result) => {
          console.log("获取推荐职位", result);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //获取公司发布的其他职位
    getJobListByComId() {
      getJobListByComId({ company_uid: this.jobInfo.uid })
        .then((result) => {
          console.log("获取公司其他职位", result);
          this.comIdJobList = result.data.company_jobs;
        })
        .catch((err) => {
          console.log("err", err);
        });
    },

    toEdtJobInf() {
      this.$router.push(
        `/releasejob?id=${this.jobid}&ispart=${this.isPartJob}`
      );
    },

    delJob() {
      this.$confirm("确定要删除该职位吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delMyFullJob({ id: this.jobid })
            .then((result) => {
              console.log("删除职位", result);
              if (result.code == 200) {
                this.$message({
                  message: result.msg,
                  type: "success",
                });
                this.$router.go(-1);
              }
            })
            .catch(() => {});
        })
        .catch(() => {});
    },
    closeJob() {
      this.$confirm("确定要关闭该职位吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          colseMyFullJob({ id: this.jobid })
            .then((result) => {
              console.log("关闭职位", result);
              if (result.code == 200) {
                this.$message({
                  message: result.msg,
                  type: "success",
                });
                this.$router.go(-1);
              }
            })
            .catch(() => {});
        })
        .catch(() => {});
    },
    toReport() {
      console.log("举报职位");
      this.reportDialogShow = true;
    },

    /* 获取分享二维码 */
    getShareEwm() {
      userPcApi({ type: "createQrcode", id: this.jobid, exeType: "job" })
        .then((result) => {
          if (result.code == 200) {
            this.shareEwmImg = result.data.img;
          }
        })
        .catch(() => {});
    },

    closeReportDialog() {
      this.reportDialogShow = false;
    },
    /* 兼职收藏 */

    collectionPartJob() {
      if (!localStorage.getItem("token")) {
        this.$store.dispatch("user/setUserType", 1);
        this.$cookies.set("usertype", 1);
        localStorage.setItem("usertype", 1);
        this.$router.push("/login");
        return;
      }
      collectionPartJob({
        job_id: this.jobid,
      })
        .then((result) => {
          console.log("兼职收藏", result);
          if (result.code == 200) {
            this.$message({
              message: result.msg,
              type: "success",
            });
            this.getJobDetails();
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style>
.parttime-job-inf-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.rightContainer {
  /* width: 100%; */
}

.min-view {
  display: flex;
}

.parttime-job-inf-page .com-sty {
  background-color: #fff;
  margin-top: 40px;
  width: 962px;
  /* height: 691px; */
  border-radius: 16px;
  /*margin-left: 220px;*/
  padding: 36px;
}

.parttime-job-inf-page .com-sty .title-one {
  font-size: 32px;

  font-weight: 600;
  color: #191919;
  line-height: 38px;
}
/* body {
  width: 100%;
} */
.top-jobb-inf-view {
  /*width: 100%;*/
  width: 1480px;
  margin: 0 auto;
  display: flex;
  padding: 40px 0;
  justify-content: space-between;
}

/* .top-jobb-inf-view .title-gz-ll-btn {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
} */
.top-jobb-inf-view .left-title-gz-ll {
  /*margin-left: 220px;*/
}
.top-jobb-inf-view .left-title-gz-ll .title-tag-gz-view {
  display: flex;
  align-items: center;
}
.top-jobb-inf-view .left-title-gz-ll .title-tag-gz-view .job-title {
  font-size: 36px;

  font-weight: 600;
  color: #191919;
  line-height: 42px;
}
.top-jobb-inf-view .left-title-gz-ll .title-tag-gz-view .tags {
  width: 35px;
  height: 19px;
  background-color: #ffd9c3;
  color: #f34b37;
  font-size: 12px;

  font-weight: 400;
  text-align: center;
  line-height: 19px;
  border-radius: 2px;
  margin-left: 12px;
}
.top-jobb-inf-view .left-title-gz-ll .title-tag-gz-view .job-gz {
  font-size: 36px;

  font-weight: 600;
  color: #fe6002;
  line-height: 42px;
  margin-left: 40px;
}

.top-jobb-inf-view .left-title-gz-ll .add-exp-edu-view {
  display: flex;
  align-items: center;
  margin-top: 24px;
}

.top-jobb-inf-view .left-title-gz-ll .add-exp-edu-view .job-gz {
  font-size: 36px;

  font-weight: 600;
  color: #fe6002;
  line-height: 42px;
}

.top-jobb-inf-view .left-title-gz-ll .add-exp-edu-view .items {
  display: flex;
  align-items: center;
  margin-right: 24px;
}
.top-jobb-inf-view .left-title-gz-ll .add-exp-edu-view .items img {
  width: 18px;
  height: 18px;
  margin-right: 6px;
}
.top-jobb-inf-view .left-title-gz-ll .add-exp-edu-view .items span {
  font-size: 18px;

  font-weight: 400;
  color: #757575;
  line-height: 24px;
}

.top-jobb-inf-view .left-title-gz-ll .lab-view {
  margin-top: 24px;
}

.top-jobb-inf-view .left-title-gz-ll .lab-view .lab-item {
  display: inline-block;
  background-color: #f5f6fa;
  padding: 6px 16px;
  font-size: 18px;

  font-weight: 400;
  color: #757575;
  line-height: 24px;
  margin-right: 14px;
  border-radius: 8px;
}
.top-jobb-inf-view .left-title-gz-ll .up-look-jb-view {
  display: flex;
  align-items: center;
  margin-top: 24px;
}
.top-jobb-inf-view .left-title-gz-ll .up-look-jb-view .items {
  display: flex;
  align-items: center;
  margin-right: 36px;
}
.top-jobb-inf-view .left-title-gz-ll .up-look-jb-view .items img {
  width: 16px;
  height: 16px;
  margin-right: 6px;
}
.top-jobb-inf-view .left-title-gz-ll .up-look-jb-view .items span {
  font-size: 14px;

  font-weight: 400;
  color: #b1b1b1;
  line-height: 16px;
}

.top-jobb-inf-view .btn-share-view {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  /*margin-right: 220px;*/
}
.top-jobb-inf-view .btn-share-view .btn-view {
  display: flex;
  align-items: center;
}
.top-jobb-inf-view .btn-share-view .btn1 {
  font-size: 24px;

  font-weight: 600;
  color: #fe6002;
  line-height: 28px;
  display: flex;
  width: 188px;
  height: 62px;
  justify-content: center;
  border: 1px solid #fe6002;
  border-radius: 4px;
}

.top-jobb-inf-view .btn-share-view .btn1 img {
  width: 32px;
  height: 32px;
}
.top-jobb-inf-view .btn-share-view .btn1 span {
}

.top-jobb-inf-view .btn-share-view .btn2 {
  width: 340px;
  height: 62px;
  font-size: 24px;

  font-weight: 500;
  color: #ffffff;
  line-height: 28px;
}

.top-jobb-inf-view .btn-share-view .shar-clo-view {
  display: flex;
  align-items: center;
  margin-top: 40px;
}
.top-jobb-inf-view .btn-share-view .shar-clo-view .items {
  display: flex;
  align-items: center;
  font-size: 16px;

  font-weight: 400;
  color: #fe6002;
  line-height: 19px;
  margin-left: 50px;
}

.top-jobb-inf-view .btn-share-view .shar-clo-view .items img {
  width: 14px;
  height: 14px;
  margin-right: 4px;
}

.zw-detail-view .work-titme-view {
  font-size: 22px;

  font-weight: 600;
  color: #191919;
  line-height: 28px;
  margin-top: 40px;
}
.zw-detail-view .work-titme-view span {
  font-size: 20px;

  font-weight: 600;
  color: #191919;
  line-height: 28px;
}
.zw-detail-view .work-titme-view div span {
  font-weight: 400;
  font-size: 15px;
  color: #666666;
  line-height: 12px;
  margin-left: 8px;
}
.zw-detail-view .work-titme-view div img {
  width: 22px;
  height: 22px;
}
.zw-detail-view .job-ms-view {
  margin-top: 30px;
}
.zw-detail-view .job-ms-view .ms-title {
  font-size: 22px;

  font-weight: 600;
  color: #191919;
  line-height: 28px;
}
.zw-detail-view .job-ms-view .ms-tetail {
  font-size: 18px;

  font-weight: 400;
  color: #191919;
  line-height: 30px;
  margin-top: 24px;
}

.parttime-job-inf-page .zw-detail-view .lin-inf-view {
  display: flex;
  align-items: center;
  padding-top: 40px;
  position: relative;
}
.zw-detail-view .lin-inf-view .lin-user-head-img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.zw-detail-view .lin-inf-view .lin-inf-text-view {
  margin-left: 18px;
}

.zw-detail-view .lin-inf-view .lin-inf-text-view .top {
  display: flex;
  align-items: center;
}

.parttime-job-inf-page
  .zw-detail-view
  .lin-inf-view
  .lin-inf-text-view
  .top
  .nikname {
  font-weight: 500;
  font-size: 16px;
  color: #222222;
  line-height: 22px;
}
.parttime-job-inf-page .zw-detail-view .lin-inf-text-view .lin-ze-tel-view img {
  width: 16px;
  height: 17px;
}
.parttime-job-inf-page .zw-detail-view .lin-inf-text-view .lin-ze-tel-view {
  font-weight: 400;
  font-size: 14px;
  color: #ff3f3f;
  line-height: 20px;
  display: flex;
  align-items: center;
  margin-top: 5px;
}

/* .zw-detail-view .lin-inf-view .right-lil-btn {
  width: 100px;
  height: 36px;
  font-size: 14px;
  
  font-weight: 600;
  color: #ffffff;
  position: absolute;
  right: 0;
} */
.zw-detail-view .lin-inf-view .right-lil-btn {
  width: 100px;
  height: 40px;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  position: absolute;
  right: 0;
  background-color: #fe6002;
  border-radius: 4px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}

.com-addr .addr-view {
  display: flex;
  align-items: center;
  font-size: 18px;

  font-weight: 400;
  color: #191919;
  line-height: 24px;
  margin-top: 24px;
}
.com-addr .addr-view img {
  width: 20px;
  height: 20px;
}
.com-addr .map {
  /* width: 986px;
  height: 213px; */
  margin-top: 24px;
}
.job-inf-page .com-addr .map .amap-box {
  width: 966px;
  height: 213px;
}

.min-ju-view .lc-ts-view {
  font-size: 20px;

  font-weight: 600;
  color: #191919;
  line-height: 28px;
  display: flex;
  align-items: center;
}
.min-ju-view .lc-ts-view img {
  width: 20px;
  height: 20px;
  margin-right: 6px;
}
.min-ju-view .jb-text {
  font-size: 18px;

  font-weight: 400;
  color: #fe6002;
  margin-top: 10px;
}
.min-ju-view .jb-text a {
  color: #0294fe;
}
.job-card-list-view .inucad {
  border: 1px solid#E5E5E5;
}
.job-card-list-view .card-fgx {
  display: inline-block;
  width: 20px;
}
.com-inf-car {
  margin-top: 40px;
  margin-left: 43px;
}

.parttime-job-inf-page .jobdetail-tjjob-view {
  margin-top: 40px;
  margin-left: 43px;
  background-color: #fff;
  border-radius: 8px;
  margin-top: 40px;
  padding: 25px 24px;
}

.parttime-job-inf-page .jobdetail-tjjob-view .tj-view {
  font-size: 22px;

  font-weight: 600;
  color: #191919;
  line-height: 28px;
}

.parttime-job-inf-page .jobdetail-tjjob-view .tj-list .tjitem {
  border-bottom: 1px solid #f5f6fa;
  padding: 20px 0;
}
.parttime-job-inf-page .jobdetail-tjjob-view .tj-list .tjitem .tj-top-view {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.parttime-job-inf-page
  .jobdetail-tjjob-view
  .tj-list
  .tjitem
  .tj-top-view
  .tj-t1 {
  font-size: 18px;

  font-weight: 600;
  color: #191919;
  line-height: 28px;
}

.parttime-job-inf-page
  .jobdetail-tjjob-view
  .tj-list
  .tjitem
  .tj-top-view
  .spar-gz {
  font-size: 16px;

  font-weight: 500;
  color: #fe6002;
  line-height: 19px;
}

.parttime-job-inf-page .jobdetail-tjjob-view .tj-list .tjitem .com-name {
  font-size: 12px;

  font-weight: 400;
  color: #757575;
  line-height: 14px;
  margin-top: 8px;
}
.link-tips-view {
  margin-top: 16px;

  font-weight: 400;
  font-size: 14px;
  color: #222222;
  line-height: 20px;
  display: flex;
  align-items: center;
}
.link-tips-view .link-tips2 {
  display: inline-block;
  width: 62px;
  height: 20px;
  background-color: #ff3f3f;
  border-radius: 2px;

  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  line-height: 17px;
  text-align: center;
  line-height: 20px;
  margin: 0 8px;
  cursor: pointer;
}
.link-tips-view .link-tips3 {
  font-weight: 400;
  font-size: 14px;
  color: #999999;
  line-height: 20px;
}

.job-time-table {
  padding-top: 16px;
}

.job-time-table .time-title {
  font-size: 22px;

  font-weight: 600;
  color: #191919;
  line-height: 28px;
  margin-bottom: 10px;
}

.job-time-table .tit {
  font-size: 17px;
  color: #333333;
  font-weight: bold;
}

.time_border {
  margin-top: 1px;
}

.time_border span,
.job-time-table .text {
  width: 12.5%;
  height: 30px;
  line-height: 25px;
  text-align: center;
  display: block;
  color: #000000;
  font-size: 13px;
}

.b_th {
  border-top: 1px solid #fe6002;
  background-color: #fe6002;
}

.b_sw {
  display: flex;
}

.b_sw span:last-child,
.b_sw .text:last-child {
  border-right: 1px solid #fe6002;
}

.b_sw span:first-child {
  border-right: none;
}

.b_sw span,
.b_sw .text {
  border: 1px solid #fe6002;
  border-right: none;
  border-top: none;
  box-sizing: initial;

  font-weight: 400;
  font-size: 12px;
  color: #737373;
}

.b_sw .text {
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tit {
  font-size: 36px;

  font-weight: 600;
  color: #222222;
  line-height: 50px;
}

.b_th .text {
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  line-height: 17px;
}
</style>