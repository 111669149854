/*
 * @Author: your name
 * @Date: 2021-05-24 11:26:33
 * @LastEditTime: 2024-06-04 16:17:06
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /YongHuaShengDa/vue-admin-template/src/utils/request.js
 */
import axios from 'axios'
import store from '@/store'
import { MessageBox, Message } from 'element-ui'
// import { getToken } from '@/utils/auth'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // baseURL: 'https://app.petzp.com/api/',
  // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 50000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    // console.log('过没过', config);
    config.headers['xx-device-type'] = 'web'
    if (store.getters.token) {
      config.headers['token'] = store.getters.token
    }
    return config
  },
  error => {
    // do something with request error
    console.log('----', error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {

    const res = response.data
    // console.log(response.data);

    // if the custom code is not 20000, it is judged as an error.

    if (res.code == 200) {
      return res
    } else if (res.code == 0) {
      Message({
        message: res.msg,
        type: 'error',
        duration: 5 * 1000
      })
      // this.$router.push({ name: "login" });
      // store.dispatch('user/resetToken').then(() => {
      //   location.reload()
      // })
      return Promise.reject(new Error(JSON.stringify(response.config.url) + res.msg))
    } else if (res.code == 14) {
      return res
    } else if (res.code == 100) {
      return res
    } else if (res.code == 101) {
      return res
    } else {
      Message({
        message: res.msg,
        type: 'error',
        duration: 5 * 1000
      })
      if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
        // to re-login
        MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
          confirmButtonText: 'Re-Login',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          store.dispatch('user/resetToken').then(() => {
            location.reload()
          })
        })
      }
      return Promise.reject(new Error(res.msg))

    }


    // if (res.code != 200) {
    //   Message({
    //     message: res.msg,
    //     type: 'error',
    //     duration: 5 * 1000
    //   })

    //   //50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
    //   if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
    //     // to re-login
    //     MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
    //       confirmButtonText: 'Re-Login',
    //       cancelButtonText: 'Cancel',
    //       type: 'warning'
    //     }).then(() => {
    //       store.dispatch('user/resetToken').then(() => {
    //         location.reload()
    //       })
    //     })
    //   }
    //   return Promise.reject(new Error(res.msg))
    // } else {
    //   return res
    // }
  },
  error => {
    console.log('000000', error) // for debug
    Message({
      message: error.msg,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
