<template>
  <div>
    <div style="clear:both"></div>
      <el-collapse accordion>
        <el-collapse-item :title="im.title" :name="im.id" v-for="im in helpList" :key="im.id">
          <p v-html="im.content"></p>
        </el-collapse-item>
      </el-collapse>
    <slot></slot>
  </div>
</template>
<script>
  import {helpIndex} from "@/api/commen/helpAndFeebback";

  export default {
    name:'question',
    data(){
      return{
        helpList: []
      }
    },
    mounted() {
      this.getIndexList();
    },
    methods:{
      getIndexList() {
        helpIndex(2).then(req => {
          this.helpList = req.data.list;
          console.log(req)
        });
      },
    }
  }
</script>
<style>


</style>