import request from '@/utils/request'


/**
 * 生成小程序的http连接
 */
export function cresteHttpUrl() {
    return request({
        url: 'v2/Business/getXcxHttpUrl',
        method: 'get'
    })
}