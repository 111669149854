<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2024-02-26 18:34:00
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-02-26 18:39:11
 * @FilePath: /lcwpcvue/src/views/wxLoginEwm.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="com-regist">
    <wxsclogin class="wxsc-view" @tophonelogin="toPhoneLogin"></wxsclogin>
  </div>
</template>

<script>
import wxsclogin from "@/components/wxscLogin.vue";
export default {
  components: {
    wxsclogin,
  },
  methods: {
    toPhoneLogin() {
      this.$router.push("/login");
    },
  },
};
</script>

<style>
</style>