<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-12-13 18:01:19
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-03-15 17:36:01
 * @FilePath: /lcwpcvue/src/components/comUser/addressManage.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="address-manage-view">
    <div class="manage-top-tile-view">
      <span>{{ shopName }}</span>
      <img class="bg-img" src="@/assets/address_manage_bg_img.png" alt="" />
    </div>
    <div class="address-title2-view">
      <div class="left-view">
        <div class="addr-xian"></div>
        <div class="title2">全部地址（{{ addressList.length }}）</div>
      </div>
      <el-button
        type="primary"
        round
        icon="el-icon-plus"
        @click="addAddrDialogOpen"
        >添加地址</el-button
      >
    </div>
    <div class="address-list-view">
      <label v-for="item in addressList" :key="item.id">
        <div class="item">
          <div class="left">
            <div class="tt1" style="display: flex;justify-content: space-between;align-items: center;width: 800px">
              <span>{{ item.address1 }}</span>
              <span v-show="item.is_com === '1'" style="color: #FE6002;font-size: 12px;font-weight: 400">(默认公司地址)</span>
            </div>
            <div class="tt2">{{ item.prov_name }}-{{ item.city_name }}</div>
          </div>
          <el-button plain @click="delAddress(item.id)">删除</el-button>
        </div>
      </label>
    </div>
    <addaddredialog
      :addAddrDialogShow="addAddrDialogShow"
      @saveAddress="saveAddress"
      @closeMapPop="closeMapPop"
    ></addaddredialog>

    <el-dialog title="关联的职位" :visible.sync="dialogTableVisible">
      <div class="jobs-ul-div">
        <div class="jobs-title">更改以下职位的关联地址，就可以删除地址了~</div>
        <div
          class="jobs-ul"
          v-for="item in jobList"
          :key="item.job_id"
          @click="toLookJob(item.job_id, item.is_part)"
        >
          <div class="jobs-ul-li-first">{{ item.name }}</div>
          <div>
            <span>{{ item.province_name }}{{ item.city_name }}</span
            ><span style="margin: 0 20px">|</span>
            <span>{{ item.exp_name }}</span
            ><span style="margin: 0 20px">|</span>
            <span>{{ item.edu_name }}</span
            ><span style="margin: 0 20px">|</span>
            <span>{{ item.minsalary }}-{{ item.maxsalary }}￥</span>
            <span class="jobs-ul-li-state">{{ item.state }}</span>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getAddressList,
  getAddressId,
  addAddress,
  delAddress,
  getJobsByAddrId,
} from "@/api/commen/addressManageApi.js";
import addaddredialog from "@/components/comUser/addAddressDialog.vue";
export default {
  name: "addressmanage",
  components: {
    addaddredialog,
  },
  data() {
    return {
      addressList: [],
      addAddrDialogShow: false,
      dialogTableVisible: false,
      jobList: [],
      shopName: "",
    };
  },
  created() {
    this.shopName = this.$store.state.user.userinf.name;
    this.getAddressList();
  },
  methods: {
    toLookJob(id, ispart) {
      this.$router.push(`/releasejob?id=${id}&&ispart=${ispart}`);
    },
    closeMapPop() {
      this.addAddrDialogShow = false;
    },
    getAddressList() {
      getAddressList()
        .then((result) => {
          console.log("获取地址列表", result);
          this.addressList = result.data;
        })
        .catch(() => {});
    },
    addAddrDialogOpen() {
      this.addAddrDialogShow = true;
    },
    saveAddress(e) {
      console.log("保存地址", e);
      getAddressId({ lat: e.y, lng: e.x })
        .then((result) => {
          console.log("获取省市id", result);
          addAddress({
            provinceid: result.data.province_id,
            cityid: result.data.city_id,
            three_cityid: result.data.three_city_id,
            address1: e.fulladdr, // 详细地址
            address2: e.detailedAddress, // 备用地址
            x: e.x,
            y: e.y,
          })
            .then((res) => {
              console.log("添加地址", res);
              this.addAddrDialogShow = false;
              if (res.code == 200) {
                this.getAddressList();
                this.$message({
                  message: "发布成功！",
                  type: "success",
                  center: true,
                });
              }
            })
            .catch(() => {});
        })
        .catch(() => {});
    },
    delAddress(id) {
      console.log(id);
      this.$confirm("确认删除该地址吗?", "提醒", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delAddress({ id })
            .then((result) => {
              console.log("删除", result);
              this.getAddressList();
              if (result.code == 200) {
                this.$message({
                  message: "删除成功！",
                  type: "success",
                });
              } else if (result.code == 101) {
                this.$confirm("该地址还有关联职位，请先修改职位地址", "提醒", {
                  confirmButtonText: "查看职位",
                  cancelButtonText: "取消",
                  type: "warning",
                })
                  .then(() => {
                    getJobsByAddrId(id).then((res) => {
                      if (res.code === 200) {
                        this.jobList = res.data.list.map((item) => {
                          if (item.state === "1") {
                            return { ...item, state: "已审核" };
                          } else if (item.state === "0") {
                            return { ...item, state: "未审核" };
                          } else if (item.state === "3") {
                            return { ...item, state: "未通过" };
                          } else {
                            return item;
                          }
                        });
                      }
                    });
                    this.dialogTableVisible = true;
                  })
                  .catch(() => {});
              }
            })
            .catch(() => {});
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消删除",
          // });
        });
    },
  },
};
</script>

<style>
.address-manage-view {
  background-color: #ffffff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.manage-top-tile-view {
  width: 1196px;
  height: 129px;
}
.manage-top-tile-view .bg-img {
  width: 1196px;
  height: 129px;
}
.manage-top-tile-view span {
  width: 1196px;
  height: 129px;
  font-size: 32px;
  
  font-weight: 600;
  color: #ffffff;
  line-height: 38px;
  text-shadow: 0px 2px 1px rgba(173, 50, 18, 0.17);
  position: absolute;
  display: flex;
  align-items: center;
  margin-left: 25px;
}
.address-title2-view {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 24px;
}
.address-title2-view .left-view {
  display: flex;
  align-items: center;
  /* margin-left: 24px; */
}
.address-title2-view .left-view .addr-xian {
  width: 4px;
  height: 20px;
  background-color: #fe6002;
}
.address-title2-view .left-view .title2 {
  font-size: 24px;
  
  font-weight: 600;
  color: #222222;
  margin-left: 10px;
}
.address-title2-view .el-button.el-button--primary.is-round {
  font-size: 16px;
  
  font-weight: 600;
  color: #ffffff;
}
.address-list-view .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 24px;
  padding: 30px 0;
  border-bottom: 1px solid #e7e8eb;
}
.address-list-view .item .left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.address-list-view .item .left .tt1 {
  font-size: 22px;
  
  font-weight: 600;
  color: #191919;
}
.address-list-view .item .left .tt2 {
  font-size: 16px;
  
  font-weight: 400;
  color: #939597;
  margin-top: 16px;
}
.jobs-ul-div {
  margin-top: 10px;
  padding: 0 20px 20px;
  border-top: 1px solid #d9d9d9;
}
.jobs-ul {
  border-bottom: 1px solid #d9d9d9;
  line-height: 30px;
  
  font-size: 15px;
  color: #999999;
  cursor: pointer;
  padding: 10px 10px;
}
.jobs-ul:hover {
  background-color: rgba(254, 96, 2, 0.1);
}
.jobs-ul-li-first {
  font-size: 20px;
  font-weight: 600;
  color: #191919;
  margin-top: 20px;
}
.jobs-ul-li-state {
  margin-left: 80px;
}
.jobs-title {
  margin-top: 5px;
}
</style>