<template>
  <div class="u-skeleton" v-if="jobsList.length > 0">
    <div
      class="listqy list"
      v-for="(item, i) in jobsList"
      data-type="1"
      @click="todetails"
      :key="i"
    >
      <div class="">
        <div class="timesss">
          <img class="logo" :src="item.resume_photo" />
          <div class="sex-view" :class="item.sex_name == '男' ? 'men' : ''">
            <img
              v-if="item.sex_name == '男'"
              src="@/assets/h5img/list_nan.png"
            />
            <img
              v-if="item.sex_name == '女'"
              src="@/assets/h5img/list_nv.png"
            />
          </div>
        </div>
        <div class="left_qy">
          <div class="bt">
            <div class="toptit">
              <!-- <text class="ells" :class="item.status == 2 ? 'namebm' : ''">{{ item.name }}</text> -->
              <span class="ells">{{ item.name }}</span>
              <div class="tablist" v-if="item.newtab">
                <div class="zx-view" v-if="item.newtab[1] != ''">
                  <div class="chengdian"></div>
                  <span class="zx-text">在线</span>
                </div>
                <img
                  class="pttj-img"
                  v-if="item.is_admin == 1"
                  src="@/assets/h5img/jlgl_pttj.png"
                  mode=""
                />
                <span v-if="item.top == 1" class="tag-view">置顶</span>
                <span class="tag-view isjz" v-if="item.newtab[2] != ''"
                  >兼职</span
                >
              </div>
            </div>
            <!-- <div class="timesbm" v-if="item.status == 2">
							<div>简历已保密</div>
						</div> -->
            <!-- 	<div class="times" v-if="item.height_status!=2">
							<salary-range :maxsalary="item.maxsalary" :minsalary="item.minsalary"></salary-range>
						</div> -->
            <div class="advanced-plp" v-if="item.height_status == 2">
              高级简历
            </div>
          </div>
          <div class="address">
            <div class="detail">
              <span v-if="item.sex_name">{{ item.sex_name }}</span>
              <span class="hasline" v-if="item.age">{{ item.age }}</span>
              <span class="hasline" v-if="item.edu_name != ''">{{
                item.edu_name
              }}</span>
              <span class="hasline" v-if="item.exp_name != ''">{{
                item.exp_name
              }}</span>
              <!-- <text class="hasline"
								v-if="item.minsalary!=0">{{ item.minsalary/1000 }}-{{item.maxsalary/1000}}K</text> -->
              <span
                class="hasline"
                v-if="item.maxsalary == 0 && item.minsalary == 0"
                >薪资面议</span
              >
              <span
                class="hasline"
                v-if="item.maxsalary == 0 && item.minsalary != 0"
                >{{ item.minsalary / 1000 }}K以上</span
              >
              <span
                class="hasline"
                v-if="item.maxsalary != 0 && item.minsalary == 0"
                >{{ item.maxsalary / 1000 }}K以下</span
              >
              <span
                class="hasline"
                v-if="item.maxsalary != 0 && item.minsalary != 0"
                >{{ item.minsalary / 1000 }}-{{ item.maxsalary / 1000 }}K</span
              >
            </div>
            <!-- <div class="cityclass">
							<text v-for="(itemin, index2) in item.city_class" :key="index2">
								{{ itemin }}
								<text v-if="item.city_class && index2 != item.city_class.length - 1">/</text>
							</text>
						</div> -->
          </div>
        </div>
      </div>
      <div class="min-inf-view">
        <div class="zhiwei">
          期望职位：
          <div v-for="(welfare, index3) in item.job_class" :key="index3">
            {{ welfare }}
            <label v-if="item.job_class && index3 != item.job_class.length - 1"
              >、</label
            >
          </div>
        </div>
        <div class="addree">
          期望地区：
          <div v-for="(itemin, index2) in item.city_class" :key="index2">
            {{ itemin }}
            <label
              v-if="item.city_class && index2 != item.city_class.length - 1"
              >/</label
            >
          </div>
        </div>
      </div>

      <div
        class="hastag"
        v-if="
          (item.tags && item.tags.length != 0) ||
          (item.newtab && item.newtab[0] != '')
        "
      >
        <div class="tags">
          <span
            style="color: #fe6002; background-color: #fff5f0"
            class="u-skeleton-fillet-yzp"
            v-if="item.newtab[0] != ''"
            >{{ item.newtab[0] }}</span
          >
          <label v-for="(welfare, index3) in item.tags" :key="index3">
            <span
              v-if="welfare == '过年可值班'"
              style="color: #fe6002; background-color: #fff5f0"
              class="u-skeleton-fillet-yzp"
              >{{ welfare }}</span
            >
            <span v-if="welfare != '过年可值班'" class="u-skeleton-fillet">{{
              welfare
            }}</span>
          </label>
        </div>
      </div>

      <!-- <div
        v-if="
          adverts.adverts_img != '' &&
          adverts.adverts_img &&
          (i == 4 || jobsList.length == 4)
        "
        @click.stop="advert"
      >
        <div class="advert">
          <img
            class="u-skeleton-rect"
            mode="widthFix"
            :src="adverts.adverts_img"
          />
        </div>
      </div> -->

      <!-- 骨架屏 -->
      <!-- <u-skeleton bg-color="rgb(250, 250, 250)" :loading="dataloading" :animation="true"
			el-color="#e5e5e5" :border-radius="10"></u-skeleton> -->
    </div>
    <div style="height: 200px"></div>
  </div>
</template>

<script>
// import uSkeleton from "../../uview-ui/components/u-skeleton/u-skeleton.vue"
import {cresteHttpUrl} from "@/api/h5/h5Redirect";

export default {
  // components:{
  // 	uSkeleton
  // },
  name: "user1card",
  props: {
    jobsList: {
      type: Array,
      default: () => [],
    },
    hasother: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      dataloading: false,
    };
  },
  methods: {
    todetails() {
      cresteHttpUrl().then(e=>{
        window.open(e.data.url);
      });
    },
    // }
  },
};
</script>

<style scoped>
/* page {
		
	} */

.list {
  width: 456px;
  background-color: #ffffff;
  border-radius: 8px;
  margin-bottom: 20px;
}

.hastag {
  height: 26px;
  overflow: hidden;
  margin-top: 15px;
  /* margin-bottom: 24px; */
}

.tags {
  display: flex;
  flex-wrap: wrap;
  min-height: 38px;
  
  font-weight: 400;
}

.listqy .tags {
  min-height: 43px;
}

.list .tags span,
.listqy .tags span {
  height: 26px !important;
  line-height: 25px !important;
  margin-right: 8px;
  margin-bottom: 8px;
  padding: 0 10px;
  font-size: 12px;
  color: #757575;
  background: #f5f6fa;
  border-radius: 2px;
}

.list .tags span {
  height: 22px;
  line-height: 28px;
}

.listqy .tags span {
  height: 32px;
  line-height: 32px;
}

.tags .u-skeleton-fillet-yzp {
  color: #fe6002;
  background-color: #fff5f0;
}

.company {
  margin-bottom: 14px;
  height: 24px;
  line-height: 24px;
  display: flex;
  justify-content: space-between;
}

.con-info {
  display: flex;
}

.com_name {
  padding-left: 8px;
  width: 220px;
  
  font-weight: 400;
  color: #222222;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.u-skeletonu-skeleton .logo {
  width: 48px;
  height: 48px;
}

.con-position {
  width: 100px;
  text-align: right;
  font-size: 12px;
  color: #939597;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* 招聘方代码 */

.listqy {
  /* height: 550px; */
  padding: 7px 10px 16px 10px;
  margin-bottom: 11px;
  /* border-bottom: 12px solid #f5f6fa; */
  position: relative;
}

.listqy:first-child {
  margin-top: 15px;
}

.listqy > div:first-child {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  /* margin-bottom: 28px; */
}

.listqy .timesss {
  width: 44px;
  position: relative;
}

.listqy .logo {
  margin-top: 5px;
  width: 44px;
  height: 44px;
  border-radius: 50%;
}

.listqy .timesss .sex-view {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  border: 2px solid #fff;
  background-color: #f95698;
  border-radius: 50%;
  position: absolute;
  bottom: 5px;
  right: -3px;
  z-index: 9;
}

.listqy .timesss .men {
  background-color: #4ab6f2;
}

.listqy .timesss .sex-view img {
  width: 13px;
  height: 13px;
}

.listqy .left_qy {
  width: 280px;
  font-size: 16px;
  
  font-weight: 500;
  color: #222222;
  margin-left: 20px;
}

.left_qy .bt {
  margin-top: -1px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* position: relative; */
}

.left_qy .bt .times {
  font-size: 16px;
  font-weight: bold;
  color: #fe6002;
}

.advanced-plp {
  width: 53px;
  height: 19px;
  background: linear-gradient(296deg, #f08721 0%, #fcc95a 100%);
  border-bottom-left-radius: 8px;
  border-top-right-radius: 8px;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 10px;
  
  font-weight: 500;
  color: #ffffff;
  line-height: 19px;
  text-align: center;
}

.left_qy .bt .timesbm {
  font-size: 16px;
  font-weight: bold;
  color: #b1b1b1;
}

.left_qy .bt .toptit {
  display: flex;
  align-items: center;
}

.ells {
  /* height: 54px; */
  /* line-height: 54px; */
  font-weight: 700;
}

.left_qy .bt .toptit .tablist {
  display: flex;
}

.left_qy .bt .toptit .zx-view {
  display: flex;
  align-items: center;
  margin-left: 8px;
}

.left_qy .bt .toptit .zx-view .chengdian {
  width: 6px;
  height: 6px;
  background-color: #ff8136;
  border-radius: 50%;
}

.left_qy .bt .toptit .zx-view span {
  font-size: 10px;
  
  font-weight: 400;
  color: #666666;
  margin-left: 3px;
}

.left_qy .bt .toptit .tablist .tag-view {
  width: 23px;
  height: 12px;
  background: #ffdfd0;
  border-radius: 2px;
  font-size: 8px;
  
  font-weight: 400;
  color: #f34b37;
  line-height: 12px;
  text-align: center;
  margin-left: 8px;
}

.left_qy .bt .toptit .tablist .isjz {
  background-color: #d0e6f8;
  color: #3291e1;
}

/* .left_qy .bt .toptit view text {
		color: #fe6002;
		border: 2px solid #fe6002;
		font-size: 24px;
		margin-left: 16px;
		padding: 0 8px;
		height: 34px;
		display: flex;
		align-items: center;
	}
 */
.left_qy .bt .toptit .namebm {
  color: #b1b1b1;
}

.left_qy .bt .toptit .pttj-img {
  width: 48px;
  height: 16px;
  margin-left: 7px;
}

.listqy .min-inf-view {
  font-size: 12px;
  
  font-weight: 400;
  color: #222222;
  margin-top: 11px;
}

.listqy .min-inf-view .addree {
  margin-top: 9px;
  display: flex;
  align-items: center;
}

.listqy .min-inf-view .zhiwei {
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
}

.address {
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  
  font-weight: 400;
  color: #757575;
  position: relative;
}

.detail:first-child text {
  margin-right: 7px;
}

.hasline {
  position: relative;
  padding-left: 7px;
}

.hasline::after {
  position: absolute;
  content: " ";
  width: 0;
  height: 9px;
  border-right: 1px solid #979797;
  top: 5px;
  left: 0;
}

.cityclass {
  white-space: nowrap;
}

.advert {
  margin-top: 10px;
}

.advert image {
  width: 100%;
  height: 100px;
  border-radius: 5px;
}
</style>