<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-07-20 16:52:28
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-06-07 17:46:50
 * @FilePath: /lcwpcvue/src/components/comUser/jobCardInFindJob.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <div
      class="job-card"
      v-for="item in jobList"
      :key="item.id"
      @click="toJobDetail(item.id)"
    >
      <div class="jobcard-left-view" style="width: 100%">
        <div class="title-tag-chatimg">
          <div
            class="comname-sta comname-sta-sl"
            @click.stop.capture="openCompany(item.uid)"
          >
            <div class="com-name">{{ item.com_name }}</div>
            <div class="state" v-show="item.yyzz_status === '1'">
              <img src="@/assets/img/h1.png" alt="" />
              <span>已认证</span>
            </div>
          </div>

          <div class="comadd-exp">
            <div style="margin-bottom: 15px">
              <span>{{ item.hy_name }}</span>
              <span v-show="item.com_mun">
                <el-divider direction="vertical"></el-divider>
                <span>{{ item.com_mun }}</span>
              </span>
              <span v-show="item.com_sdate">
                <el-divider direction="vertical"></el-divider>
                <span >{{ item.com_sdate.slice(0,4)}}年成立</span>
            </span>
              <span v-show="item.com_area">
                <el-divider direction="vertical"></el-divider>
                <span >{{ item.com_area }}m²</span>
            </span>
            </div>
            <div class="up-tiem-sl" style="text-align: right">{{ item.jobOnline }}</div>
          </div>

          <span class="job-title">{{ item.name }}</span>
          <span
            v-show="item.urgent && item.urgent != '' && item.urgent != 0"
            class="tag-sl"
            >急聘</span
          >
          <span
            v-show="item.xsdate && item.xsdate != '' && item.xsdate != 0"
            class="tag-sl"
            >置顶</span
          >
          <span
            v-show="item.rec && item.rec != '' && item.rec != 0"
            class="tag-sl tjtag"
            >推荐</span
          >
          <el-popover
            placement="bottom-start"
            width="300"
            trigger="hover"
            :offset:="500"
          >
            <div
              slot="reference"
              class="job-card-chating-div"
              @click.stop="toChatPage(item.uid)"
            >
              <img
                class="chat-img-sl"
                src="../../assets/chat-logo.png"
                alt=""
              />
              <span class="job-card-chating">立即沟通</span>
            </div>
            <div
              class="popover-link-inf-view"
              @click.stop="toChatPage(item.uid)"
            >
              <img class="user-logo" :src="item.logo" alt="" />
              <div class="link-inf-left-view">
                <div class="top-inf">
                  <span class="link-name">{{ item.recruit.name }}</span>
                  <span class="dian"></span>
                  <span class="onlin">{{ item.recruit.online }}</span>
                </div>
                <div class="bot-inf">
                  {{ item.recruit.job }}·{{ item.com_name }}
                </div>
              </div>
            </div>
          </el-popover>
        </div>
        <div style="position: relative">
          <div class="pr-add-exp">
            <span v-show="item.minsalary != '0'" class="pr-view">{{ item.minsalary }}-{{ item.maxsalary }}元/月</span>
            <span v-show="item.minsalary == '0'" class="pr-view">面议</span>
            <div class="add-exp">
              <span>{{ item.city }}</span>
              <el-divider direction="vertical"></el-divider>
              <span>{{ item.exp !== '不限' ? item.exp : '经验不限'}}</span>
              <el-divider direction="vertical"></el-divider>
              <span>{{ item.edu !== '不限' ? item.edu : '学历不限'}}</span>
            </div>
          </div>
        </div>
        <div class="other-tag" style="min-height: 13px">
          <div v-for="i in item.welfare" :key="i">
            <div class="other-tag-content">{{ i }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { checkUserIsCanChat } from "@/api/indiviuser/jobInfoPage";
import { checkIsLahe } from "@/api/commen/aboutTalentsApi.js";
export default {
  props: {
    jobList: {
      type: Array,
    },
  },
  data() {
    return {
      isLogin: false,
    };
  },
  created() {
    this.isLogin = localStorage.token ? true : false;
  },
  methods: {
    openCompany(uid) {
      let newRouter = this.$router.resolve({
        path: `companyinf?id=${uid}`,
      });
      window.open(newRouter.href, "_blank");
    },
    toJobDetail(id) {
      localStorage.setItem("recommendJobId", id);
      let newRouter = this.$router.resolve({
        path: `/jobinfopage?id=${id}`,
      });
      window.open(newRouter.href, "_blank");
    },
    toChatPage(uid) {
      if (!this.isLogin) {
        this.$store.dispatch("user/setUserType", 1);
        this.$cookies.set("usertype", 1);
        localStorage.setItem("usertype", 1);
        this.$router.push(`/login`);
        return false;
      }

      checkUserIsCanChat()
        .then((result) => {
          console.log("ooopppooo", result);
          if (result.data.score > result.data.myscore || result.code == 102) {
            // 102简历未通过审核
            this.$message({
              message: result.msg,
              type: "warning",
            });
            // uni.showToast({
            //   title: res.msg,
            //   icon: "none",
            // });
            setTimeout(() => {
              this.$router.push(`/useredtresume`);
            }, 1500);
          } else {
            checkIsLahe({ to_uid: uid })
              .then((result) => {
                console.log("是否拉黑", result);
                if (result.data.hmd == 1) {
                  this.$confirm(
                    "您已将对方拉黑，确认要给他发送消息吗？",
                    "提示",
                    {
                      confirmButtonText: "确定",
                      cancelButtonText: "取消",
                      type: "warning",
                    }
                  )
                    .then(() => {
                      this.$router.push(`/chatpage?chatid=${uid}`);
                    })
                    .catch(() => {});
                } else {
                  if (result.data.to_hmd == 1) {
                    this.$confirm(
                      "对方已将你拉黑，您不能发送消息给TA!",
                      "提示",
                      {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                      }
                    )
                      .then(() => {})
                      .catch(() => {});
                  } else {
                    this.$router.push(`/chatpage?chatid=${uid}`);
                  }
                }
              })
              .catch(() => {});
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style>
.job-card {
  width: 986px;
  background-color: #fff;
  display: flex;
  align-items: center;
  border-radius: 16px;
  margin-top: 20px;
  padding: 30px 24px;
  justify-content: space-between;
  cursor: pointer;
}
.job-card .jobcard-left-view {

}
.job-card .jobcard-left-view .title-tag-chatimg {
  display: flex;
  align-items: center;
  position: relative;
}
.job-card .jobcard-left-view .title-tag-chatimg .job-title {
  font-size: 22px;

  font-weight: 600;
  color: #191919;
  line-height: 28px;
}

.job-card .jobcard-left-view .title-tag-chatimg .tag-sl {
  display: inline-block;
  background: #f34b37;
  border-radius: 0px 4px 0px 4px;
  font-size: 11px;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  margin-left: 20px;
  padding: 2px 5px;
}

.job-card .jobcard-left-view .title-tag-chatimg .tjtag {
  background: #fe6002;
}

.job-card .jobcard-left-view .title-tag-chatimg .chat-img-sl {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}
.job-card .job-card-chating-div {
  height: 25px;
  padding: 0 2px;
  background-color: #fff5f0;
  margin-left: 40px;
  border-radius: 5px;
  border: 1px solid #ffc5a2;
}
.job-card .job-card-chating {
  font-weight: 400;
  font-size: 13px;
  color: #fe6002;
  line-height: 14px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
.popover-link-inf-view {
  display: flex;
  align-items: center;
  padding: 12px 7px;
}

.popover-link-inf-view .user-logo {
  width: 75px;
  height: 75px;
  border-radius: 50%;
}

.popover-link-inf-view .link-inf-left-view {
  margin-left: 20px;
}

.popover-link-inf-view .link-inf-left-view .top-inf {
  display: flex;
  align-items: center;
}

.popover-link-inf-view .link-inf-left-view .top-inf .link-name {
  font-weight: 500;
  font-size: 22px;
  color: #191919;
  line-height: 26px;
}

.popover-link-inf-view .link-inf-left-view .top-inf .dian {
  width: 6px;
  height: 6px;
  background-color: #fe6002;
  border-radius: 50%;
  margin-left: 10px;
}
.popover-link-inf-view .link-inf-left-view .top-inf .onlin {
  font-weight: 400;
  font-size: 12px;
  color: #757575;
  line-height: 14px;
  margin-left: 2px;
}
.popover-link-inf-view .link-inf-left-view .bot-inf {
  margin-top: 20px;

  font-weight: 400;
  font-size: 16px;
  color: #191919;
  line-height: 19px;
}

.job-card .jobcard-left-view .pr-add-exp {
  display: flex;
  align-items: center;
  margin-top: 12px;
}
.job-card .jobcard-left-view .pr-add-exp .pr-view {
  font-size: 20px;

  font-weight: 600;
  color: #fe6002;
}

.job-card .jobcard-left-view .pr-add-exp .add-exp {
  font-size: 14px;

  font-weight: 400;
  color: #757575;
  margin-left: 30px;
}

.job-card .jobcard-left-view .other-tag {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 20px;
  max-width: 850px;
}
.job-card .jobcard-left-view .other-tag .other-tag-content {
  background: #f5f6fa;
  font-size: 14px;

  font-weight: 400;
  color: #757575;
  padding: 4px 8px;
  margin-right: 16px;
  margin-bottom: 6px;
}

.job-card .comname-sta {
  display: flex;
}

.job-card .comname-sta-sl {
  position: absolute;
  right: 0;
}
.job-card .comname-sta-sl .com-name:hover {
  color: #fe6002 !important;
}
.job-card .comname-sta .com-name {
  font-size: 18px;

  font-weight: 400;
  color: #191919;
  line-height: 24px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.job-card .comname-sta .state {
  display: flex;
  align-items: center;
  font-size: 10px;

  font-weight: 400;
  color: #fe6002;
  line-height: 14px;
  margin-left: 12px;
  width: 60px;
  white-space: nowrap;
}
.job-card .comname-sta .state img {
  width: 12px;
  height: 12px;
  margin-right: 2px;
}

.job-card .comadd-exp {
  font-size: 14px;
  font-weight: 400;
  color: #757575;
  line-height: 16px;
  position: absolute;
  right: 0;
  top: 35px;
}
.job-card:hover {
  background-color: #fff5f0;
  border: #fe6002 1px solid;
}
</style>