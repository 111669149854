/*
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-05-26 14:56:00
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-06-07 10:37:43
 * @FilePath: /lcwpcvue/src/api/commen/login.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'

/**
* 已登录，获取状态
* @url member/usertypeChangeStatus
* @param  params 
* @return 
*/
export function getUserSta() {
  return request({
    url: 'member/usertypeChangeStatus',
    method: 'get',
  })
}


//发送验证码
export function sendPhoneCode(params) {
  return request({
    url: 'applets/send_msg',
    method: 'post',
    data: params
  })
}


//手机验证码登录
export function userLoginByCode(params) {
  return request({
    url: 'member/accountV2',
    method: 'post',
    data: params
  })
}

//获取求职者基本信息
export function getIndUserInf() {
  // console.log('89898', params);
  return request({
    url: 'member/indexV2',
    method: 'post'
  })
}


//获取招聘者基本信息

export function getComUserInf() {
  // console.log('89898', params);
  return request({
    url: 'Business/indexV2',
    method: 'post'
  })
}





//账号密码登录
/**
  username   //用户名
  password   //密码
  usertype   //用户类型
  login_ip   //登录ip
  appletsid: this.openid,
  unionid: this.unionid,
  push_clientid: uni.getStorageSync('registrationID'),
  push_platform: uni.getSystemInfoSync().platform,
  partner: uni.getStorageSync('ispartner')
 */
export function userLoginByPassw(params) {
  return request({
    url: 'applets/login',
    method: 'post',
    data: params
  })
}


//设置/重置密码
// moblie: ,
// usertype: ,
// password: ,
// code: ,
// ip: 
export function updatdePassword(params) {
  return request({
    url: 'member/resetPass',
    method: 'post',
    data: params
  })
}

/**
* 微信登录
* @url Member/ewmLogin
* @param {code,usertype} params 
* @return 
*/

export function userLoginByWxEwm(params) {
  return request({
    url: 'Member/ewmLogin',
    method: 'post',
    data: params
  })
}



/**
* 用户协议
* @url 
* @param  params 
* @return 
*/

export function getUserProfile(params) {
  return request({
    url: 'member/' + params,
    method: 'get',
  })
}

/**
* 更新企业头像
* @url Business/updatelogo
* @param  params 
* @return 
*/

export function updateLogo(params) {
  console.log('00000', params);
  return request({
    url: 'Business/updatelogo',
    method: 'post',
    data: params
  })
}

/**
* 上传base64格式头像
* @url v2/upload/upload64
* @param  params 
* @return 
*/
export function uploadBase64Header(params) {
  return request({
    url: 'v2/upload/upload64',
    method: 'post',
    data: params
  })
}







