/*
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-12-18 18:39:00
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2023-12-20 17:34:32
 * @FilePath: /lcwpcvue/src/api/compe/aboutVipApi.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'


/**
* 获取会员套餐
* @url Rating/ratinglistnew
* @param {} params 
* @return 
*/
export function getVipComboList(params) {
  return request({
    url: 'Rating/ratinglistnew',
    method: 'post',
    data: params,
  })
}

/**
* 所有支付
* @url Rating/submitRatingByPc
* @param {type//1会员支付 rating_id//会员套餐id,pay_type//支付方式} params 
* @return 
*/

export function submitVipOrder(params) {
  return request({
    url: 'Rating/submitRatingByPc',
    method: 'post',
    data: params,
  })
}

