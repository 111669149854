<template>
  <div>
    <div v-show="transferList.length > 0 || buyShopList.length > 0">
      <MyTransfer
        :transferList="transferList"
        @updateTransferList="getMyTransferList"
      ></MyTransfer>
      <MyBuyShop
        :transferList="buyShopList"
        @updateBuyShowList="getMyTransferList"
      ></MyBuyShop>
    </div>
    <div style="height: 30px"></div>

    <div v-show="transferList.length <= 0 && buyShopList.length <= 0">
      <div style="width: 100%">
        <div class="common-title-view" style="margin-bottom: 16px">
          <span class="left-xian-view"></span>
          <span class="text">转接店</span>
        </div>
      </div>
      <el-card class="transferList-null">
        <div class="transferList-null-div">
          <img
            src="../../assets/transfer_is_null.png"
            class="transferList-null-img"
          />
          <p class="transferList-null-title">暂无还没有相关的信息</p>
          <div>
            <button class="transferList-null-butt" @click="toAddTransfer">
              发布转店
            </button>
            <button class="transferList-null-butt" @click="toAddTake">
              发布接店
            </button>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
import MyTransfer from "@/components/ transferShop/myTransfer.vue";
import MyBuyShop from "@/components/ transferShop/myBuyShop.vue";
import { myShopList } from "@/api/commen/transferShop";
import { parseTime } from "@/utils";
export default {
  components: { MyTransfer, MyBuyShop },
  data() {
    return {
      transferList: [],
      buyShopList: [],
    };
  },
  mounted() {
    this.getMyTransferList();
  },
  beforeDestroy() {
    this.$off("updateBuyShowList");
    this.$off("updateTransferList");
  },
  methods: {
    toAddTransfer() {
      this.$router.push("/publishtransffer");
    },
    toAddTake() {
      this.$router.push("/publishtake");
    },

    getMyTransferList() {
      this.buyShopList = [];
      this.transferList = [];
      myShopList().then((req) => {
        if (req.code === 200) {
          let list = req.data.list;
          list.forEach((item) => {
            item.time = parseTime(item.time, "{y}-{m}-{d} {h}:{i}");
            if (item.type === "1") {
              // pay_status 支付状态：1：未支付 2：支付成功 3：支付失败  s_status   1：待转让  2：转让成功
              let stateArr = [];
              stateArr[1] = "待支付";
              stateArr[2] = "已支付";
              stateArr[3] = "支付失败";
              item.state = stateArr[item.pay_status];
              this.transferList.push(item);
            } else {
              // 'r_status'  0：待审核  1：已审核  2：未成功 'sj_status' 1：上架 0 xiajia 's_status'  0： 待接  1：接成功
              let stateName = "";
              let stateClass = "";
              if (item.r_status === "0") {
                stateName = "待审核";
                stateClass = "transfer-item-authing";
              } else if (item.s_status === "1") {
                stateName = "接店成功";
                stateClass = "transfer-item-jd-gray";
              } else if (item.sj_status === "0") {
                stateName = "已下架";
                stateClass = "transfer-item-jd-gray";
              } else if (item.r_status === "2") {
                stateName = "未通过";
                stateClass = "transfer-item-authing";
              } else {
                stateName = "接店中";
                stateClass = "transfer-item-jd-underway";
              }
              item.stateName = stateName;
              item.stateClass = stateClass;
              this.buyShopList.push(item);
            }
          });
        }
      });
    },
  },
};
</script>
<style scoped>
.transferList-null {
  width: 1196px;
  height: 912px;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
}
.transferList-null-div {
  width: 308px;
  height: 329px;
  margin: 200px auto 0 auto;
}
.transferList-null-img {
  display: block;
  width: 260px;
  height: 222.86px;
  margin: 0 auto;
}
.transferList-null-title {
  height: 24px;
  width: 100%;
  font-size: 18px;
  
  font-weight: 400;
  color: #939597;
  line-height: 24px;
  text-align: center;
  margin: 12px 0 18px 0;
}
.transferList-null-butt {
  width: 308px;
  height: 43px;
  background-color: #fe6002;
  border: none;
  color: white;
  
  border-radius: 5px;
  font-size: 18px;
  letter-spacing: 1px;
  cursor: pointer;
}
.transferList-null-butt:last-child {
  margin-top: 15px;
}
>>> .box-card {
  margin-left: 0;
}
>>> .transfer-add {
  height: 30px;
  width: 86px;
  position: absolute;
  top: -45px;
  right: 10px;
  cursor: pointer;
}
</style>