<template>
  <div></div>
</template>

<script>
import request from "@/utils/request";

export default {
  name: "transfer",
  data(){
    return{
      ps:{}
    }
  },
  created() {
    let params = this.$route.query;
    console.log('jinlaile::::',params)
    this.ps = params;
    let keys = Object.keys(params);
    if (!keys.includes('path')){
      this.$router.push('/home');
    }
    switch (params.path) {
      // 职位
      case 'job':{
        if (params.c === 'comapply'){
          this.$router.push(`jobinfopage?id=${params.id}`);
        }else {
          this.$router.push('/findjob');
          this.notFound();
        }
        break;
      }
      //  公司
      case 'company':{
        if (params.c === 'show'){
          this.$router.push(`companyinf?id=${params.id}`);
        }else {
          this.$router.push('/companylist');
          this.notFound();
        }
        break;
      }
      // 简历
      case 'resume':{
        if (params.c === 'show'){
          this.$router.push(`talentsdetail?id=${params.id}`);
        }else {
          this.$router.push('/findtalentslist');
          this.notFound();
        }
        break;
      }
      // 资讯
      case 'article':{
        if (params.c === 'show'){
          this.$router.push(`informationdetail?id=${params.id}`);
        }else {
          this.notFound();
        }
        break;
      }
      default:{
        this.notFound();
        this.$router.push('/home');
      }
    }
  },
  methods:{
    notFound(){
      request({
        url: 'v2/Business/notFound',
        method: 'post',
        data:this.ps
      });
    }
  }
}
</script>

<style scoped>

</style>