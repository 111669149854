/*
 * @Author: your name
 * @Date: 2021-05-24 11:26:33
 * @LastEditTime: 2024-06-03 14:46:01
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// import router from './router'
module.exports = {

  title: '猎宠网（petzp.com）',

  /**
   * @type {boolean} true | false
   * @description Whether fix the header
   */
  fixedHeader: true,

  /**
   * @type {boolean} true | false
   * @description Whether show the logo in sidebar
   */
  sidebarLogo: true
}
