<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2024-01-12 16:45:42
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-06-12 15:00:44
 * @FilePath: /lcwpcvue/src/components/comUser/resumeManageItem.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="resume-manage-page">
    <label v-for="item in resumeArr" :key="item.id">
      <div
        class="resume-manage-item-view"
        @click.stop="toTalentsDetail(item.uid)"
      >
        <div class="resume-manage-item-top-view">
          <div class="inf-top-left-view">
            <div class="avatar-img-view">
              <img class="avata-img" :src="item.resume_photo" alt="" />
              <img
                v-show="item.sex == '男'"
                class="sex-img"
                src="@/assets/gender_nan.png"
                alt=""
              />
              <img
                v-show="item.sex == '女'"
                class="sex-img"
                src="@/assets/gender_nv.png"
                alt=""
              />
            </div>
            <div class="name-tag-age-view">
              <!-- v-show="item.newtab[2] && item.newtab[2] != ''" -->
              <div class="name-tag-view">
                <span class="name">{{ item.name }}</span>
                <span class="name-tag-age-view-rec" v-show="item.is_rec">{{
                  item.is_rec
                }}</span>
                <!-- <span v-show="item.top == 1" class="tag1">置顶</span>
                <span class="tag2">兼职</span> -->
              </div>
              <div class="age-deu-exp">
                <span>{{ item.age }}</span>
                <el-divider direction="vertical"></el-divider>
                <span>{{ item.edu_name }}</span>
                <el-divider direction="vertical"></el-divider>
                <span>{{ item.exp_name }}</span>
              </div>
            </div>
          </div>
          <div class="inf-top-right-view">
            <div class="qw-jb-ct-item">
              <img
                class="qw-img"
                src="@/assets/talentsdetail_qzzt_img.png"
                alt=""
              />
              <span class="tt1">期望职位：</span>
              <span
                class="tt2"
                v-for="(job, index) in item.job_class_name"
                :key="index"
                >{{ job }}
                <span
                  v-show="
                    item.job_class_name &&
                    index != item.job_class_name.length - 1
                  "
                  >、</span
                ></span
              >
            </div>
            <div class="qw-jb-ct-item">
              <img class="qw-img" src="@/assets/user_card_city.png" alt="" />
              <span class="tt1">期望城市：</span>
              <span
                class="tt2"
                v-for="(city, index) in item.city_class_name"
                :key="index"
                >{{ city }}
                <span
                  v-show="
                    item.city_class_name &&
                    index != item.city_class_name.length - 1
                  "
                  >/</span
                ></span
              >
            </div>
          </div>
        </div>
        <div class="resume-manage-item-bottom-view">
          <div class="resume-bz-time-view">
            <div class="resume-time-view">
              {{ item.time }}
              {{
                tabId == "received"
                  ? "投递"
                  : tabId == "down"
                  ? "下载"
                  : tabId == "invitation"
                  ? "邀请"
                  : "举报"
              }}<label v-if="tabId == 'received'">
                <span style="color: #fe6002"> {{ item.job_name }}</span> 职位
                <span v-if="item.is_browse == 1" style="color: #fe6002"
                  >（未查看）</span
                >
                <span v-if="item.is_browse == 2">（已查看）</span>
              </label>
            </div>
            <div class="resume-bz-view" v-show="item.note != ''">
              备注：{{ item.note }}
            </div>
          </div>
          <div class="right-btn-view">
            <div class="zcz-zdy-btn" @click.stop="toChatPage(item.uid)">
              <i class="el-icon-chat-dot-round"></i>
              <span>沟通</span>
            </div>
            <div
              class="zcz-zdy-btn"
              v-show="item.is_unmatch !== '1'"
              @click.stop="doesnFit(item.uid)"
            >
              <i class="el-icon-chat-dot"></i>
              <span>不合适</span>
            </div>
            <div
              class="zcz-zdy-btn zcz-primary"
              v-show="item.is_unmatch === '1'"
              @click.stop="doesnFit(item.uid)"
            >
              <i class="el-icon-chat-dot"></i>
              <span>不合适</span>
            </div>
            <div class="zcz-zdy-btn" @click.stop="addResuBz(item.uid)">
              <i class="el-icon-edit-outline"></i>
              <span>备注</span>
            </div>
            <div class="zcz-zdy-btn" v-if="$store.getters.userinf.ad_login === '1'" @click.stop="deleteObj(item.id)">
              <i class="el-icon-delete"></i>
              <span>删除</span>
            </div>
          </div>
        </div>
      </div>
    </label>
  </div>
</template>

<script>
import { checkIsCanChat, checkIsChatBlack } from "@/api/commen/chatApi.js";
export default {
  props: {
    resumeArr: {
      type: Array,
    },
    tabId: {
      type: String,
    },
  },
  data() {
    return {
      gtBntHover: false,
      bhsBntHover: false,
      bzBntHover: false,
      isAdmin: false
    };
  },
  methods: {
    deleteObj(id){
      this.$emit('deleteObj',id,this.tabId);
    },
    toTalentsDetail(uid) {
      this.$router.push(`/talentsdetail?id=${uid}`);
    },
    addResuBz(uid) {
      this.$emit("addResuBz", uid);
    },
    toChatPage(uid) {
      checkIsCanChat({
        to_uid: uid,
      })
        .then((result) => {
          console.log("判断直聊", result);
          if (result.code == 200) {
            if (result.data.is_vip != 0 && result.data.is_can_chat == 0) {
              this.$confirm(
                "您的直聊权限已用完,赶紧购买直聊卡联系心仪的人吧!",
                "提示",
                {
                  confirmButtonText: "立即购买",
                  cancelButtonText: "取消",
                }
              )
                .then(() => {
                  this.$router.push(`/myequitypage`);
                })
                .catch(() => {});
            } else if (
              result.data.is_vip == 0 &&
              result.data.is_can_chat != 0
            ) {
              this.$confirm(
                "升级VIP，可以第一时间联系心仪的人哦～",
                "请升级VIP会员",
                {
                  confirmButtonText: "立即升级",
                  cancelButtonText: "取消",
                }
              )
                .then(() => {
                  this.$router.push(`/vippaypage`);
                })
                .catch(() => {});
            } else {
              checkIsChatBlack({
                to_uid: uid,
              })
                .then((res) => {
                  console.log("是否拉黑", res);
                  if (res.data.hmd == 1) {
                    this.$alert(
                      "您已将对方拉黑，确认要给他发送消息吗？",
                      "提示",
                      {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                      }
                    )
                      .then(() => {
                        this.$message({
                          type: "success",
                          message: "删除成功!",
                        });
                      })
                      .catch(() => {
                        this.$message({
                          type: "info",
                          message: "已取消删除",
                        });
                      });
                  } else {
                    if (res.data.to_hmd == 1) {
                      this.$alert(
                        "对方已将你拉黑，您不能发送消息给TA!",
                        "提示",
                        {
                          confirmButtonText: "确定",
                        }
                      )
                        .then(() => {
                          this.$message({
                            type: "success",
                            message: "删除成功!",
                          });
                        })
                        .catch(() => {});
                    } else {
                      this.$router.push(`/chatpage?chatid=${uid}`);
                    }
                  }
                })
                .catch(() => {});
            }
          }
        })
        .catch(() => {});
    },

    doesnFit(uid) {
      console.log("不合适：", uid);
      this.$emit("doesnFit", uid);
    },
  },
};
</script>

<style>
.resume-manage-item-view {
  width: 1148px;
  background-color: #fff;
  padding: 24px 24px;
  margin-top: 16px;
  border: 1px solid #fff;
  cursor: pointer;
}
.resume-manage-page .right-btn-view .zcz-zdy-btn:hover {
  border: 1px solid #fe6002;
  background-color: #fff5f0;
  color: #fe6002;
}
.resume-manage-page .resume-manage-item-view:hover {
  border: 1px solid #fe6002;
  background-color: #fff5f0;
}
.resume-manage-item-top-view {
  display: flex;
  align-items: center;
}

.resume-manage-item-top-view .inf-top-left-view {
  display: flex;
  align-items: center;
  width: 600px;
}

.resume-manage-item-top-view .inf-top-left-view .avatar-img-view {
  position: relative;
}

.resume-manage-item-top-view .inf-top-left-view .avatar-img-view .avata-img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}
.resume-manage-item-top-view .inf-top-left-view .avatar-img-view .sex-img {
  width: 22px;
  height: 22px;
  position: absolute;
  bottom: 0;
  right: 2px;
}

.resume-manage-item-top-view .inf-top-left-view .name-tag-age-view {
  margin-left: 24px;
}

.resume-manage-item-top-view
  .inf-top-left-view
  .name-tag-age-view
  .name-tag-view {
  display: flex;
  align-items: center;
}

.resume-manage-item-top-view
  .inf-top-left-view
  .name-tag-age-view
  .name-tag-view
  .name {
  font-weight: 600;
  font-size: 22px;
  color: #191919;
  line-height: 28px;
}
.resume-manage-item-top-view
  .inf-top-left-view
  .name-tag-age-view
  .name-tag-view
  .tag1,
.resume-manage-item-top-view
  .inf-top-left-view
  .name-tag-age-view
  .name-tag-view
  .tag2 {
  width: 32px;
  height: 16px;
  margin-left: 12px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;

  font-weight: 400;
}
.resume-manage-item-top-view
  .inf-top-left-view
  .name-tag-age-view
  .name-tag-view
  .tag1 {
  background-color: #ffd9c3;
  color: #f34b37;
}
.resume-manage-item-top-view
  .inf-top-left-view
  .name-tag-age-view
  .name-tag-view
  .tag2 {
  background-color: #b3dfff;
  color: #0294fe;
}
.resume-manage-item-top-view
  .inf-top-left-view
  .name-tag-age-view
  .age-deu-exp {
  font-weight: 400;
  font-size: 16px;
  color: #303030;
  line-height: 19px;
  margin-top: 16px;
}

.resume-manage-item-top-view .inf-top-right-view {
  border-left: 1px solid#EAECF5;
  height: 65px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 30px;
}

.resume-manage-item-top-view .inf-top-right-view .qw-jb-ct-item {
  display: flex;
  align-items: center;
}
.resume-manage-item-top-view .inf-top-right-view .qw-jb-ct-item .qw-img {
  width: 20px;
  height: 20px;
  margin-right: 6px;
}
.resume-manage-item-top-view .inf-top-right-view .qw-jb-ct-item .tt1 {
  font-weight: 400;
  font-size: 16px;
  color: #b1b1b1;
  line-height: 19px;
}

.resume-manage-item-top-view .inf-top-right-view .qw-jb-ct-item .tt2 {
  font-weight: 400;
  font-size: 18px;
  color: #191919;
  line-height: 24px;
}
.resume-manage-item-bottom-view {
  display: flex;
  margin-top: 16px;
  border-top: 1px solid#EAECF5;
  padding-top: 16px;
  position: relative;
  padding-bottom: 16px;
}

.resume-manage-item-bottom-view .resume-bz-time-view {
  font-weight: 400;
  font-size: 16px;
  color: #b1b1b1;
  line-height: 19px;
  width: 800px;
}
.resume-manage-item-bottom-view .resume-bz-time-view .resume-bz-view {
  margin-top: 12px;
}

.resume-manage-item-bottom-view .right-btn-view {
  position: absolute;
  right: 0;
  margin-top: 16px;
  cursor: pointer;
}
.resume-manage-item-bottom-view .right-btn-view .el-button {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

.zcz-zdy-btn {
  display: inline;
  padding: 8px 16px;
  border: 1px solid #e7e8eb;

  font-weight: 400;
  font-size: 14px;
  color: #757575;
  line-height: 16px;
  border-radius: 4px;
  margin-left: 24px;
}
.zcz-primary {
  background-color: #fe6002;
  color: #fff;
  border: none;
}
.mrbtn-hover {
  border: 1px solid#fe6002;
  color: #fe6002;
  background-color: #fff5f0;
}
.resume-manage-page .name-tag-age-view-rec {
  color: #fe6002;
  font-size: 14px;
  border: 1px solid #fe6002;
  padding: 4px 7px;
  border-radius: 5px;
  margin-left: 15px;
  background-color: #fff5f0;
}
</style>