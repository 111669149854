/*
 * @Author: your name
 * @Date: 2021-05-24 11:26:33
 * @LastEditTime: 2024-08-14 16:57:32
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /YongHuaShengDa/vue-admin-template/src/utils/request.js
 */
import axios from 'axios'
import {
  Loading,
  MessageBox, Message
} from 'element-ui'
import store from '@/store'
import router from '../router'
// import { getToken } from '@/utils/auth'

var loading;

function showLoading() {
  loading = Loading.service({
    text: "拼命加载中...",
    background: "rgba(0,0,0,0.5)"
  });
}

function hindLoading() {
  loading.close();
}
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // baseURL: 'https://app.petzp.com/api/',
  // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 50000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    // console.log('过没过', config);
    showLoading();
    config.headers['xx-device-type'] = 'web'
    config.headers['Content-Type'] = 'application/json'

    if (localStorage.token) {
      if (
        store.getters.token != localStorage.token &&
        router.path !== "/login"
      ) {
        console.log('不一样了');
        // _this
        //   .$confirm(
        //     "当前页面下有其他账号在不同设备、浏览器或选项卡发生了登录操作。请刷新以同步至最新登录账号，或切换其他账号登录。",
        //     "登录账号发生变更",
        //     {
        //       confirmButtonText: "刷新页面",
        //       cancelButtonText: "登录其他账号",
        //       type: "",
        //       showClose: false,
        //     }
        //   )
        //   .then(() => {
        //     location.reload();
        //   })
        //   .catch(() => {
        //     // 退出登录，并 跳转到登录页
        //     _this.$store
        //       .dispatch("user/resetToken")
        //       .then(() => {
        //         _this.$router.push("/login");
        //       })
        //       .catch(() => {
        //         _this.$router.push("/login");
        //       });
        //     // .then(() => {
        //     //   location.href = process.env.VUE_APP_CONTEXT_PATH + "index";
        //     // })
        //     // .catch(() => {
        //     //   _this.$router.push("/login");
        //     // });
        //   });
      }



      config.headers['token'] = localStorage.token
    }
    return config
  },
  error => {
    // do something with request error
    console.log('----', error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {

    hindLoading();
    const res = response.data
    // console.log(response.data);

    // if the custom code is not 20000, it is judged as an error.

    if (res.code == 200) {
      return res
    } else if (res.code == 0) {
      if (res.msg == '缺少token') {
        // router.push(`/home`);
      } else if (res.msg == '查询失败:公司不存在') {
        Message({
          message: res.msg,
          type: 'error',
          // duration: 3 * 1000,
          duration: 2 * 1000,

        })
        setTimeout(() => {
          router.push('/');
        }, 1000)
      } else if (res.msg == '点数不足') {
        return res
      } else {
        Message({
          message: res.msg,
          type: 'error',
          // duration: 3 * 1000,
          duration: 2 * 1000,

        })
      }
      // this.$router.push({ name: "login" });
      // store.dispatch('user/resetToken').then(() => {
      //   location.reload()
      // })
      return Promise.reject(new Error(JSON.stringify(response.config.url) + res.msg))
    } else if (res.code == 14 || res.code == 15) {
      return res
    } else if (res.code == 100) {
      return res
    } else if (res.code == 101) {
      return res
    } else if (res.code == 102) {
      return res
    } else if (res.code == 201 || res.code == 202 || res.code == 203 || res.code == 204) {
      return res
    } else if (res.code == 401) {
      Message({
        message: res.msg,
        // message: '--------------------------',
        type: 'error',
        duration: 2 * 1000
      });
      store.dispatch("user/resetToken").then(() => {
        // location.reload();
        router.push('/')
      });
    } else {
      Message({
        message: res.msg,
        type: 'error',
        duration: 2 * 1000
      })
      if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
        // to re-login
        MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
          confirmButtonText: 'Re-Login',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          store.dispatch('user/resetToken').then(() => {
            location.reload()
          })
        })
      }
      return Promise.reject(new Error(res.msg))

    }


    // if (res.code != 200) {
    //   Message({
    //     message: res.msg,
    //     type: 'error',
    //     duration: 5 * 1000
    //   })

    //   //50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
    //   if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
    //     // to re-login
    //     MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
    //       confirmButtonText: 'Re-Login',
    //       cancelButtonText: 'Cancel',
    //       type: 'warning'
    //     }).then(() => {
    //       store.dispatch('user/resetToken').then(() => {
    //         location.reload()
    //       })
    //     })
    //   }
    //   return Promise.reject(new Error(res.msg))
    // } else {
    //   return res
    // }
  },
  error => {
    // hindLoading();
    console.log('000000', error) // for debug
    Message({
      message: error.msg,
      type: 'error',
      duration: 2 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
