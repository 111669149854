<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-07-19 14:22:25
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-06-11 16:39:20
 * @FilePath: /lcwpcvue/src/views/takeShopList.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="take-shop-list-page">
    <div class="find-job-sear-view">
      <div class="top-zk-div"></div>

      <searview
        :pagescrollhe="pagescrollhe"
        searchpage="takeshop"
        @toSearch="searchFun"
        ref="searview-ref"
      >
      </searview>
      <filterateview
        searchpage="take"
        @toFliterList="fliterTalentList"
        @clearFiltrateFun="clearFiltrateFun"
      ></filterateview>
    </div>

    <div class="min-transfer-list-tj-view">
      <div class="transfer-navbar-view">
        <div
          v-for="item in menuList"
          :key="item.id"
          @click="searchTakeList(item.id)"
          class="navbar-item"
        >
          <template v-if="meunIdx === item.id">
            <span class="navbar-item-sl">{{ item.name }}</span>
            <div class="btm-xian"></div>
          </template>
          <template v-else>
            <span>{{ item.name }}</span>
          </template>
        </div>
      </div>

      <div class="tranlist-right-view">
        <div class="list-left-view">
          <TakeShowListItem ref="takeShowListItem-ref"></TakeShowListItem>
        </div>

        <div class="right-view-jiedian">
          <img
            @click="toAddTake"
            class="fb-btn"
            src="../assets/tran_take_shop.png"
            alt=""
          />
          <img class="yhjl-view" src="../assets/findjob_yh_jl.png" alt="" />
          <img
            class="sac-ewm-img"
            src="../assets/tran_take_sc_ewm.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import searview from "@/components/homeSearComp.vue";
import filterateview from "@/components/filtrateComp.vue";
import TakeShowListItem from "@/components/takeShow/takeShowListItem.vue";
import { shopType } from "@/api/commen/takeShowList";
export default {
  components: {
    TakeShowListItem,
    searview,
    filterateview,
  },
  data() {
    return {
      pagescrollhe: 0,
      menuList: [],
      meunIdx: 0,
      transferList: [],
    };
  },
  mounted() {
    this.getMenuList();
    window.addEventListener("scroll", this.homePageScroll);
  },
  methods: {
    //监听页面滚动
    homePageScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      this.pagescrollhe = scrollTop;
    },
    createTake() {
      this.$router.push("/publishtake");
    },
    //条件框清空条件按钮
    clearFiltrateFun() {
      this.$refs["takeShowListItem-ref"].clearCondition();
      this.$refs["searview-ref"].searchKey = "";
    },
    searchTakeList(id) {
      this.meunIdx = id;
      this.$refs["takeShowListItem-ref"].searchParams.type = id;
      this.$refs["takeShowListItem-ref"].searchParams.page = 1;
      this.$refs["takeShowListItem-ref"].currentpage = 1;
      this.$refs["takeShowListItem-ref"].getTakeList();
    },
    getMenuList() {
      shopType().then((req) => {
        this.menuList = req.data.list;
        this.menuList.unshift({ id: 0, name: "所有店铺" });
      });
    },
    searchFun(wd) {
      this.$refs["takeShowListItem-ref"].searchParams.wd = wd;
      this.$refs["takeShowListItem-ref"].getTakeList();
    },
    toAddTake() {
      this.$router.push("/publishtake");
    },
    fliterTalentList(type, proid) {
      if (type === "pro") {
        if (proid.proId === "all") {
          this.$refs["takeShowListItem-ref"].searchParams.province = "";
          this.$refs["takeShowListItem-ref"].searchParams.city = "";
        } else {
          this.$refs["takeShowListItem-ref"].searchParams.province =
            proid.proId;
          this.$refs["takeShowListItem-ref"].searchParams.city =
            proid.cityId === "all" ? "" : proid.cityId;
        }
      } else if (type === "area") {
        this.$refs["takeShowListItem-ref"].searchParams.area = proid.query;
      } else if (type === "rent") {
        this.$refs["takeShowListItem-ref"].searchParams.rent = proid.query;
      }
      this.$refs["takeShowListItem-ref"].getTakeList();
    },
  },
};
</script>

<style scoped>
.take-shop-list-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.min-transfer-list-tj-view {
  /* margin: 0 220px; */
}

.transfer-navbar-view {
  width: 100%;
  display: flex;
  background-color: #fff;
  margin-top: 41px;
  font-size: 22px;

  font-weight: 400;
  color: #757575;
  padding: 15px 0;
  border-radius: 8px;
  border-bottom: 2px solid#EAECF5;
}
.transfer-navbar-view .navbar-item {
  margin: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  cursor: pointer;
  min-width: auto;
}

.transfer-navbar-view .navbar-item-sl {
  font-size: 24px;

  font-weight: 600;
  color: #fe6002;
}
.transfer-navbar-view .btm-xian {
  width: 40px;
  height: 3px;
  background-color: #fe6002;
  position: absolute;
  bottom: -15px;
}

.tranlist-right-view {
  display: flex;
}
.tranlist-right-view .right-view-jiedian {
  width: 403px;
  margin-left: 40px;
}

.tranlist-right-view .right-view-jiedian .fb-btn {
  width: 403px;
  height: 177px;
  margin-top: 24px;
  cursor: pointer;
}

.tranlist-right-view .right-view-jiedian .yhjl-view {
  width: 403px;
  height: 200px;
  margin-top: 40px;
}

.tranlist-right-view .right-view-jiedian .sac-ewm-img {
  width: 403px;
  height: 320px;
  margin-top: 40px;
}
</style>