<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2024-03-22 11:56:36
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-03-22 13:17:52
 * @FilePath: /lcwpcvue/src/views/waitingInterview.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="user-home-page">
    <div class="top-zk-div"></div>
    <userinfleftmen class="left-menu"></userinfleftmen>
    <comwaitinginterview class="userinf-index"></comwaitinginterview>
  </div>
</template>

<script>
import userinfleftmen from "@/components/userInfPageLeftMenu.vue";
import comwaitinginterview from "@/components/comUser/comWaitingInterview.vue";
export default {
  components: {
    userinfleftmen,
    comwaitinginterview,
  },
};
</script>

<style>
</style>