<template>
  <div class="qz-card-page">
    <!-- 求职者信息  :data-url="'/pagesA/user/resume/detail?uid=' + datainfo.zuid" -->
    <div class="box containers" v-if="usertype == 2" @click="toTalentsDetail">
      <div class="qzinfo">
        <img :src="datainfo.chatavatar" />
        <div class="qzdetail">
          <div class="name">
            <div>
              <span>{{ datainfo.name }}</span>
            </div>
            <salary-range
              class="salary"
              :maxsalary="datainfo.maxsalary"
              :minsalary="datainfo.minsalary"
            >
            </salary-range>
          </div>
          <div class="detailmsg">
            <div>
              <span>{{ datainfo.yearsold }}岁</span>
              <span v-if="datainfo.eduname">/{{ datainfo.eduname }}</span>
              <span v-if="datainfo.exp_name">/{{ datainfo.exp_name }}</span>
            </div>
            <div v-if="datainfo.city_name">
              <span v-for="(item, index) in datainfo.city_name" :key="index">
                <span v-if="index != 0">/</span>
                {{ item }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="hastag"
        v-if="datainfo.job_class && datainfo.job_class.length != 0"
      >
        <div class="tags">
          <span
            class="u-skeleton-fillet"
            v-for="(welfare, index3) in datainfo.job_class"
            :key="index3"
            >{{ welfare }}</span
          >
        </div>
      </div>
    </div>
    <!-- 企业信息  :data-url="'/pages/index/cominfo?id=' + datainfo.uid" -->
    <div
      class="box com_box containers"
      v-if="usertype == 1"
      @click="toCompanyDetail"
    >
      <div class="gs_name">
        <img class="logo" :src="datainfo.logo" />
        <div class="text">
          <span class="name">{{ datainfo.name }}</span>
          <div style="margin-top: 5px">
            <span>{{ datainfo.province }} </span>
            <span space="ensp" v-if="datainfo.province && datainfo.job">
              &nbsp; | &nbsp;
            </span>
            <span> {{ datainfo.com_hy }}</span>
          </div>
        </div>
      </div>
      <div class="hotget" v-if="datainfo.num == 0">没有在热招的职位</div>
      <div class="hotget" v-if="datainfo.num > 0">
        <img src="@/assets/hot.png" />正在热招
        <span class="yellow">{{ datainfo.num }}个</span>
        {{ datainfo.job }} 相关职业
      </div>
      <div class="times">
        {{ time }}由{{ fistuid != datainfo.uid ? "你" : "TA" }}发起的沟通
      </div>
    </div>
  </div>
</template>

<script>
import salaryRange from "@/components/salary-range.vue";
export default {
  components: {
    salaryRange,
  },
  props: {
    datainfo: {
      type: Object,
      default: () => {},
    },
    usertype: {
      type: Number,
      default: () => 1,
    },
    time: {
      type: String,
      default: () => "",
    },
    fistuid: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      // errorimg: api.errorimg,
    };
  },
  created() {},
  methods: {
    toTalentsDetail() {
      this.$router.push(`/talentsdetail?id=${this.datainfo.zuid}`);

      // uni.navigateTo({
      //   url: url,
      // });
    },
    toCompanyDetail() {
      this.$router.push(`/companyinf?id=${this.datainfo.uid}`);
    },
  },
};
</script>

<style>
.box {
  width: 481px;
  margin-top: 32px;
  padding: 32px;
  background: linear-gradient(to bottom, #fff5f0, #fff);
  box-shadow: 0px 10px 20px -4px rgba(175, 180, 197, 0.16);
  border-radius: 8px;
  border: 1px solid #ff8136;
}

.qzinfo {
  display: flex;
}

.qzdetail {
  flex: 1;
  margin-left: 12px;
}

.qzdetail .name,
.detailmsg {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* line-height: 44px; */
  /* height: 44px; */
}

.qzdetail .name {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
}

.detailmsg {
  font-size: 14px;

  font-weight: 400;
  color: #757575;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 16px;
  margin-top: 4px;
}

.salary {
  color: #fe6002;
  font-size: 16px;
}

.qzinfo img {
  width: 52px;
  height: 52px;
  border-radius: 50%;
}

.labels {
  padding: 23px 0;
}

.labels label {
  height: 22px;
  line-height: 22px;
  padding: 0 20px;
  border-left: 2px solid #c8c8c8;
  color: #666666;
  font-size: 22px;
}

.labels label:nth-child(1) {
  border: 0;
  padding-left: 0;
}

.infos text {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 15px;
  background: #f4f5f7;
  color: #999999;
  font-size: 22px;
  margin-right: 12px;
  margin-top: 10px;
  border-radius: 4px;
}

.tips {
  margin-top: 35px;
  padding-top: 30px;
  border-top: 1px solid #e5e5e5;
  color: #999999;
  font-size: 24px;
  line-height: 24px;
}

.com_box .tit {
  margin-bottom: 10px;
  font-weight: 600;
}

.comname {
  font-size: 26px;
  color: #333333;
  line-height: 50px;
}

.gs_name {
  display: flex;
  align-items: center;
  color: #888888;

  font-weight: 400;
  font-size: 14px;
}

.gs_name .logo {
  width: 52px;
  height: 52px;
  border-radius: 8px;
  display: block;
  margin-right: 20px;
}

.gs_name .text {
  width: 331px;
}

.gs_name .name {
  font-weight: 600;
  font-size: 20px;
  color: #222222;
  line-height: 28px;
}

.yellow {
  color: #fe6002;
}

.hotget {
  display: flex;
  align-items: center;
  margin-top: 13px;
  color: #888888;
  font-size: 12px;
  font-weight: 400;
}

.hotget img {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.times {
  margin-top: 15px;
  height: 32px;
  line-height: 48px;
  border-top: 1px solid #e7e8eb;
  font-size: 12px;

  font-weight: 400;
  color: #b1b1b1;
}

.qz-card-page .hastag {
  /* height: 52px; */
  overflow: hidden;
  margin-top: 20px;
  margin-left: -30px;
  /* position: absolute; */
  /* margin-bottom: 24px; */
}

.qz-card-page .tags {
  display: flex;
  flex-wrap: wrap;
  /* min-height: 76px; */

  font-weight: 400;
  margin-left: 16px;
}

.qz-card-page .tags span {
  margin-right: 16px;
  margin-bottom: 16px;
  padding: 0 8px;
  font-size: 12px;
  color: #757575;
  background: #f5f6fa;
  border-radius: 4px;
  height: 25px;
  line-height: 25px;
}
</style>