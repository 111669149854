<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2024-05-22 09:31:47
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-05-29 15:56:19
 * @FilePath: /lcwpcvue/src/components/h5Components/uTabbar.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="h5-taber-page">
    <el-menu
      :default-active="privateIdx"
      class="h5-el-taber"
      mode="horizontal"
      background-color="#fff"
      text-color="#222"
      active-text-color="#FE6002"
      @select="handleSelect"
    >
      <el-menu-item style="border-bottom: none" index="/h5homepage">
        <div class="h5-taber-it">
          <img
            v-if="privateIdx != '/h5homepage'"
            src="@/assets/h5img/h5_home_img.png"
            alt=""
          />
          <img
            v-if="privateIdx == '/h5homepage'"
            src="@/assets/h5img/h5_home_img_sl.png"
            alt=""
          />
          <span class="h5-navtext">首页</span>
        </div>
      </el-menu-item>

      <el-menu-item style="border-bottom: none" index="/h5findjobpage">
        <div class="h5-taber-it">
          <img
            v-if="privateIdx != '/h5findjobpage'"
            src="@/assets/h5img/home1.png"
            alt=""
          />
          <img
            v-if="privateIdx == '/h5findjobpage'"
            src="@/assets/h5img/home1-fill.png"
            alt=""
          />
          <span class="h5-navtext">找工作</span>
        </div>
      </el-menu-item>
      <el-menu-item style="border-bottom: none" index="5">
        <div class="h5-taber-it">
          <img
            v-if="privateIdx != '/h5findtalentlist'"
            src="@/assets/h5img/home.png"
            alt=""
          />
          <img
            v-if="privateIdx == '/h5findtalentlist'"
            src="@/assets/h5img/home-fill.png"
            alt=""
          />
          <span class="h5-navtext">招人才</span>
        </div>
      </el-menu-item>

      <el-menu-item style="border-bottom: none" index="/h5takeandtranstrolist">
        <div class="h5-taber-it">
          <img
            v-if="privateIdx != '/h5takeandtranstrolist'"
            src="@/assets/h5img/zjd.png"
            alt=""
          />
          <img
            v-if="privateIdx == '/h5takeandtranstrolist'"
            src="@/assets/h5img/zjd-fill.png"
            alt=""
          />
          <span class="h5-navtext">转接店</span>
        </div></el-menu-item
      >

      <el-menu-item style="border-bottom: none" index="4">
        <div class="h5-taber-it">
          <img src="@/assets/h5img/home1.png" alt="" />
          <span class="h5-navtext">我的</span>
        </div></el-menu-item
      >
    </el-menu>
  </div>
</template>

<script>
import {cresteHttpUrl} from "@/api/h5/h5Redirect";

export default {
  activeIndex: {
    type: String,
    default: "/h5homepage",
  },
  data() {
    return {
      privateIdx: this.activeIndex,
    };
  },
  created() {
    this.$store.watch((state) => {
      this.privateIdx = state.user.headnavmenindex;
    });
  },
  methods: {
    handleSelect(key) {
      this.privateIdx = key;
      if (key == "4") {
        cresteHttpUrl().then(e=>{
          window.open(e.data.url);
        });
      } else if (key == "5") {
        cresteHttpUrl().then(e=>{
          window.open(e.data.url);
        });
      } else {
        this.$router.push(key);
      }
    },
  },
};
</script>

<style>
.h5-taber-page {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
}
.h5-el-taber {
  display: flex;
  justify-content: space-between;
}
.h5-taber-it {
  width: 55px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.h5-taber-it img {
  width: 30px;
  height: 30px;
}
.h5-taber-it span {
  line-height: 20px;
}
</style>