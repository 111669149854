<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-10-20 11:48:38
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-07-03 15:20:38
 * @FilePath: /lcwpcvue/src/components/edtResume.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="user-center-page">
    <div class="edmesume-left-view">
      <div class="mesume-userbasinf-view" v-show="!basInfEdtShow">
        <img
          :src="userResemDetail.member.resume_photo"
          alt=""
          class="user-avatar-img"
        />
        <div class="min-userinf-text">
          <div class="name-sex-rz-view">
            <span class="name">{{ userResemDetail.member.names }}</span>
            <img
              :src="
                userResemDetail.member.sex === '1'
                  ? require('../assets/gender_nan.png')
                  : require('../assets/gender_nv.png')
              "
              alt=""
              class="sex-img"
            />
            <div v-show="getUSerAuthStatus == 1">
              <img class="rzst-img" src="../assets/findjob_yrzimg.png" alt="" />
              <span class="rest-text">已认证</span>
            </div>
            <div
              v-show="getUSerAuthStatus == 0"
              @click="toSetPage()"
              style="cursor: pointer"
            >
              <img class="rzst-img" src="../assets/findjob_wrzimg.png" alt="" />
              <span class="rest-text" style="color: #999999">未认证</span>
            </div>
          </div>
          <div class="age-xl-ggjy-add-xj-view">
            <span v-show="userResemDetail.member.year_old"
              >{{ userResemDetail.member.year_old }}岁</span
            >
            <el-divider
              v-if="userResemDetail.member.year_old"
              direction="vertical"
            ></el-divider>
            <span>{{ userResemDetail.member.edu_name }}</span>
            <el-divider direction="vertical"></el-divider>
            <span>{{ userResemDetail.member.exp_name }}</span>
            <span v-show="userBaseInf.domicile">
              <el-divider direction="vertical"></el-divider>
              <span>{{ userBaseInf.domicile }}人</span>
            </span>
            <span v-show="userBaseInf.living">
              <el-divider direction="vertical"></el-divider>
              <span>现居{{ userBaseInf.living }}</span>
            </span>
          </div>
          <div class="phone-email-view">
            <div class="item">
              <img src="../assets/resume/resume-tel.png" alt="" />
              <span>{{ userBaseInf.telphone }}</span>
            </div>
            <div class="item" v-show="userBaseInf.email">
              <img src="../assets/resume/email-resume.png" alt="" />
              <span>{{ userBaseInf.email }}</span>
            </div>
          </div>
        </div>

        <div class="ed-bbtn-view" @click="openEdieBasInf()">
          <img src="../assets/userinf_edi_img.png" alt="" />
          <span class="ed-text">编辑</span>
        </div>
      </div>

      <div class="edi-baseinf-view" v-show="basInfEdtShow">
        <div class="topo-title-view">基础信息</div>
        <div class="edit-view">
          <el-form label-position="left" ref="baseObj" :model="baseObj">
            <div class="userava-up-view">
              <!-- <el-upload
                class="avatar-uploader"
                :action="updateUrl"
                :show-file-list="false"
              >
                <div style="width: 300px; height: 60px"></div>
              </el-upload>
              <img :src="baseObj.resume_photo" class="avatar" /> -->
              <div style="width: 80px; height: 60px"></div>
              <zczheardimg
                class="avatar-uploader"
                :lookImgPath="baseObj.resume_photo"
                @success-img-path="successimgpath"
              />
              <div class="avatr-up-text">
                <div class="tt1">上传头像</div>
                <div class="tt2">上传真实头像，更能得到招聘者的好感</div>
              </div>
            </div>

            <div class="qzzt-edi-sl-view">
              <div
                class="edi-lin-item"
                style="position: relative; margin-bottom: 15px"
              >
                <el-form-item label="">
                  <div class="items">
                    <div class="title"><span>姓名</span></div>
                    <el-input
                      v-model="baseObj.name"
                      :disabled="getAuthStatus > 0"
                    ></el-input>
                  </div>
                </el-form-item>
                <el-form-item label="" class="checkbox-input-view">
                  <el-checkbox v-model="baseObj.show_name"></el-checkbox>
                  <span>显示先生/女士</span>
                </el-form-item>
                <el-form-item label="">
                  <div class="items">
                    <div class="title">性别</div>
                    <div class="sl-clik-view">
                      <span
                        class="cl-change-item"
                        :class="baseObj.sex == 1 ? 'sl-cl-change-item' : ''"
                        @click="chosesex(1)"
                        >男</span
                      >
                      <span
                        class="cl-change-item"
                        :class="baseObj.sex == 2 ? 'sl-cl-change-item' : ''"
                        @click="chosesex(2)"
                        >女</span
                      >
                    </div>
                  </div>
                </el-form-item>
              </div>

              <div class="edi-lin-item">
                <el-form-item label="">
                  <div class="items">
                    <div class="title">出生日期</div>
                    <el-date-picker
                      :disabled="getAuthStatus > 0"
                      v-model="baseObj.birthday"
                      type="month"
                      placeholder="选择月"
                       :picker-options="brSetMonthDisabled"
                    >
                    </el-date-picker>
                  </div>
                </el-form-item>
                <el-form-item label="">
                  <div class="items">
                    <div class="title">求职身份</div>
                    <div class="sl-clik-view">
                      <span
                        class="cl-change-item"
                        :class="
                          baseObj.job_type == 1 ? 'sl-cl-change-item' : ''
                        "
                        @click="jobTypeChose(1)"
                        >我是职场人</span
                      >
                      <span
                        class="cl-change-item"
                        :class="
                          baseObj.job_type == 2 ? 'sl-cl-change-item' : ''
                        "
                        @click="jobTypeChose(2)"
                        >我是学生</span
                      >
                    </div>
                  </div>
                </el-form-item>
              </div>

              <div class="edi-lin-item">
                <el-form-item label="">
                  <div class="items">
                    <div class="title"><span>最高学历</span></div>
                    <el-select v-model="baseObj.edu" placeholder="请选择学历">
                      <el-option
                        v-for="item in xlChoseArr"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      ></el-option>

                      <!-- <el-option label="区域二" value="beijing"></el-option> -->
                    </el-select>
                  </div>
                </el-form-item>
                <el-form-item label="" v-show="baseObj.job_type == 1">
                  <div class="items" @click.stop="createDefault('firstyear')">
                    <div class="title">进入宠物行业时间</div>
                    <el-date-picker
                      v-model="baseObj.firstyear"
                      type="month"
                      value-format="timestamp"
                      placeholder="选择月"
                      :picker-options="gzExSetMonthDisabled"
                    >
                    </el-date-picker>
                  </div>
                </el-form-item>
              </div>

              <div class="edi-lin-item">
                <el-form-item label="">
                  <div class="items">
                    <div class="title">居住地</div>
                    <el-input
                      placeholder="请输入内容"
                      v-model="baseObj.living"
                      clearable
                      suffix-icon="el-icon-arrow-right"
                    >
                    </el-input>
                    <div class="zd-ingp-view" @click="choseCityOne(1)"></div>
                  </div>
                </el-form-item>
                <el-form-item label="">
                  <div class="items">
                    <div class="title">户籍地</div>
                    <el-input
                      :disabled="getAuthStatus > 0"
                      placeholder="请输入内容"
                      v-model="baseObj.domicile"
                      clearable
                      suffix-icon="el-icon-arrow-right"
                    >
                    </el-input>
                    <div
                      class="zd-ingp-view"
                      v-show="getAuthStatus === '0'"
                      @click="choseCityOne(2)"
                    ></div>
                  </div>
                </el-form-item>
              </div>

              <div class="edi-lin-item">
                <el-form-item label="">
                  <div class="items">
                    <div class="title">手机号</div>
                    <el-input
                      placeholder="请输入内容"
                      v-model="baseObj.telphone"
                      clearable
                    >
                      <template slot="append">修改</template>
                    </el-input>
                    <div class="zd-ingp-view"></div>
                  </div>
                </el-form-item>
                <el-form-item label="">
                  <div class="items">
                    <div class="title">微信号</div>
                    <el-input
                      placeholder="请输入内容"
                      v-model="baseObj.wxnum"
                      clearable
                    >
                    </el-input>
                  </div>
                </el-form-item>
              </div>
              <div class="edi-lin-item">
                <el-form-item label="">
                  <div class="items">
                    <div class="title">邮箱</div>
                    <el-input
                      placeholder="请填写邮箱（选填）"
                      v-model="baseObj.email"
                      clearable
                    >
                    </el-input>
                  </div>
                </el-form-item>
              </div>
            </div>
            <span
              style="font-size: 12px; line-height: 30px; color: #919191"
              v-show="getAuthStatus > 0"
            >
              * 实名认证后不允许修改'姓名，性别，出生日期，户籍地'</span
            >
            <el-form-item class="yx-el-for-it" style="text-align: right">
              <el-button
                @click="updateUserBaseInf"
                class="btn btn-save"
                type="primary"
                >保存</el-button
              >
              <el-button class="btn btn-center" @click="basInfEdtShow = false"
                >取消</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>

      <div class="other-resume-inf-view" v-show="!qzztInfEdtShow">
        <div class="com-title-view">
          <span class="title-text">求职状态</span>
          <span class="isbt">必填</span>
          <div class="right-bj-view" @click="openQzztInfEdt">
            <img src="../assets/userinf_edi_img.png" alt="" />
            <span>编辑</span>
          </div>
        </div>

        <div class="zazt-view">
          <img
            class="resume-img1"
            src="../assets/resume/job-resume.png"
            alt=""
          />
          <span class="resume-text1"
            >{{ userResemDetail.expect.jobstatus_name }} -
            {{ userResemDetail.expect.report_name }}</span
          >
        </div>

        <div class="com-title-view" style="margin-top: 20px">
          <span class="title-text">求职意向</span>
          <span class="isbt">必填</span>
          <!-- <div class="right-bj-view">
            <img src="../assets/jobinf_jb_img.png" alt="" />
            <span>编辑简历</span>
          </div> -->
        </div>
        <div class="zazt-view">
          <img
            class="resume-img1"
            src="../assets/resume/expect-resume.png"
            alt=""
          />
          <span class="resume-text1">
            <label
              v-for="(item, index) in userResemDetail.expect.job_classid_name"
              :key="item.id"
              >{{ item.name
              }}{{
                index == userResemDetail.expect.job_classid_name.length - 1
                  ? ""
                  : "/"
              }}</label
            ></span
          >
        </div>
        <div class="qzyx-text-view">
          <div class="yx-txt-item">
            <div>
              <span class="xz-tt1">薪资要求：</span>
              <span
                class="xz-tt2"
                v-show="userResemDetail.expect.minsalary !== '0'"
              >
                {{ userResemDetail.expect.minsalary / 1000 }}-
                {{ userResemDetail.expect.maxsalary / 1000 }}
                K</span
              >
              <span
                class="xz-tt2"
                v-show="userResemDetail.expect.minsalary === '0'"
              >
                面议
              </span>
            </div>
            <div>
              <span class="xz-tt1">工作性质：</span>
              <span class="xz-tt2">{{ userResemDetail.expect.type_name }}</span>
            </div>
          </div>
          <div class="yx-txt-item" style="margin-left: 200px">
            <div>
              <span class="xz-tt1">期望城市：</span>
              <span class="xz-tt2">
                <label
                  v-for="(item, index) in userResemDetail.expect.city_name"
                  :key="item.id"
                  >{{ item.name
                  }}{{
                    index == userResemDetail.expect.city_name.length - 1
                      ? ""
                      : "/"
                  }}</label
                ></span
              >
            </div>
            <div>
              <span class="xz-tt1">期望行业：</span>
              <span class="xz-tt2">{{ userResemDetail.expect.hy_name }}</span>
            </div>
          </div>
        </div>
      </div>

      <div
        class="other-resume-inf-view edi-other-resume-inf-view"
        v-show="qzztInfEdtShow"
      >
        <el-form label-position="left">
          <div class="com-title-view">
            <span class="left-xian"></span>
            <span class="title-text">求职状态</span>
          </div>
          <div class="qzzt-edi-sl-view">
            <div class="edi-lin-item">
              <el-form-item label="">
                <div class="items">
                  <div class="title">求职状态</div>
                  <!--                  @visible-change="getUserQzStatus"-->
                  <el-select
                    placeholder="求职状态"
                    v-model="jbYxObj.jobstatus"
                    @change="qzZtChange"
                  >
                    <el-option
                      v-for="item in qzStatusArr.job_status_list"
                      :label="item.name"
                      :value="item.id"
                      :key="item.id"
                    ></el-option>
                  </el-select>
                </div>
              </el-form-item>
              <el-form-item label="">
                <div class="items">
                  <div class="title">到岗时间</div>
                  <!--                  @visible-change="getUserQzStatus"-->
                  <el-select
                    placeholder="到岗时间"
                    v-model="jbYxObj.report"
                    @change="dgTimeChange"
                  >
                    <el-option
                      v-for="item in qzStatusArr.user_report_list"
                      :label="item.name"
                      :value="item.id"
                      :key="item.id"
                    ></el-option>
                  </el-select>
                </div>
              </el-form-item>
            </div>
          </div>
          <div
            style="
              height: 1px;
              width: 100%;
              background-color: #e7e8eb;
              margin: 32px 0;
            "
          ></div>
          <div class="com-title-view">
            <span class="left-xian"></span>
            <span class="title-text">求职意向</span>
          </div>
          <div class="qzzt-edi-sl-view">
            <div class="edi-lin-item">
              <el-form-item label="">
                <div class="items" @click="isyxzw = true">
                  <div class="title">期望职位</div>
                  <el-input
                    placeholder="请输入内容"
                    v-model="jobExpInf.jobclassname"
                    clearable
                    suffix-icon="el-icon-arrow-right"
                  >
                  </el-input>
                  <div class="zd-ingp-view"></div>
                </div>
              </el-form-item>
              <el-form-item label="">
                <div class="items">
                  <div class="title">期望行业</div>
                  <el-input
                    placeholder="请输入内容"
                    v-model="jobExpInf.hy_name"
                    clearable
                    suffix-icon="el-icon-arrow-right"
                  >
                  </el-input>
                  <div class="zd-ingp-view" @click="isJobHyShow = true"></div>
                </div>
              </el-form-item>
            </div>
            <div class="edi-lin-item">
              <el-form-item label="">
<!--                <div class="items" @click="isCityShow = true">-->
                <div class="items" @click.stop="toOpenChooseCity">
                  <div class="title">期望城市</div>
                  <el-input
                    placeholder="请输入内容"
                    v-model="jobExpInf.cityname"
                    clearable
                    suffix-icon="el-icon-arrow-right"
                  >
                  </el-input>
                  <div class="zd-ingp-view"></div>
                </div>
              </el-form-item>
              <el-form-item label="">
                <div class="items">
                  <div class="title">期望薪资</div>
                  <div class="sl-group-view">
                    <!--                    <el-select placeholder="最低" v-model="jobExpInf.minsalary">
                      <el-option label="区域一" value="shanghai"></el-option>
                      <el-option label="区域二" value="beijing"></el-option>
                    </el-select>
                    <span class="he-xian">-</span>
                    <el-select placeholder="最高" v-model="jobExpInf.maxsalary">
                      <el-option label="区域一" value="shanghai"></el-option>
                      <el-option label="区域二" value="beijing"></el-option>
                    </el-select>-->

                    <el-select
                      @change="minChange"
                      v-model="jbYxObj.minsalary"
                      placeholder="请选择最低工资"
                      :disabled="checkDis"
                    >
                      <el-option
                        v-for="item in minOptions"
                        :key="item.id"
                        :label="item.key"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                    <el-select
                      @change="maxChange"
                      v-model="jbYxObj.maxsalary"
                      placeholder="请选择最高工资"
                      :disabled="checkDis"
                    >
                      <el-option
                        v-for="item in maxOptions"
                        :key="item.id"
                        :label="item.key"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>

                    <el-checkbox
                      style="
                        margin-left: 23px;

                        font-weight: 400;
                        color: #191919;
                        line-height: 19px;
                      "
                      v-model="isPrice"
                      >面议</el-checkbox
                    >
                  </div>
                </div>
              </el-form-item>
            </div>

            <div class="edi-lin-item">
              <el-form-item label="">
                <div class="items">
                  <div class="title">工作性质</div>
                  <el-select
                    placeholder="工作性质"
                    v-model="jobExpInf.type_name"
                    @change="jobTypeChange"
                  >
                    <el-option label="不限" value="0"></el-option>
                    <el-option label="全职" value="1"></el-option>
                    <el-option label="兼职" value="2"></el-option>
                  </el-select>
                </div>
              </el-form-item>
            </div>
          </div>

          <el-form-item class="yx-el-for-it" style="text-align: right">
            <el-button
              class="btn btn-save"
              type="primary"
              @click="updateQzYxInf"
              >保存</el-button
            >
            <el-button @click="qzztInfEdtShow = false" class="btn btn-center"
              >取消</el-button
            >
          </el-form-item>
        </el-form>
      </div>

      <div class="other-resume-inf-view" v-show="!ysEdtShow">
        <div class="com-title-view">
          <span class="title-text">个人优势</span>
          <div class="right-bj-view" @click="userUserYsShow">
            <img src="../assets/userinf_edi_img.png" alt="" />
          </div>
        </div>
        <div
          class="nonetext-view"
          v-show="userResemDetail.member.description == ''"
        >
          你还未填写个人优势，快去填写哦~
        </div>
        <div
          v-show="userResemDetail.member.description != ''"
          class="havedesc-view"
        >{{ userResemDetail.member.description }}</div>
        <div class="skills-tags">
          <label
            v-for="(item, index) in userResemDetail.resume_skill_tag"
            :key="index"
          >
            <span class="tag">{{ item }}</span>
          </label>
        </div>
      </div>

      <div class="edi-other-nowr-view" v-show="ysEdtShow">
        <div class="topo-title-view">
          <div class="com-title-view">
            <span class="title-text">个人优势</span>
            <div class="right-bj-view">
              <img src="../assets/userinf_edi_img.png" alt="" />
            </div>
          </div>
        </div>
        <div class="edit-view">
          <el-form label-position="left">
            <div class="tt2">我会的技能（{{ userSelcTag.length }}/10）</div>
            <div class="sl-bq-btn">
              <label v-for="(item, index) in userSelcTag" :key="index">
                <div class="sel-jn-tag">
                  <span>{{ item }}</span
                  ><i
                    class="el-icon-close"
                    @click="delResumeOthere('delTag', index)"
                  ></i>
                </div>
              </label>
              <div class="addbq-bt" @click="addUserJnTag">+ 添加</div>
            </div>

            <div class="tt3">热门标签：</div>
            <div class="hot-bq-view">
              <label v-for="(item, index) in hotJnUserTag" :key="index">
                <div class="hotbq-item" @click="choseHotTag(item, index)">
                  {{ item }}
                </div>
              </label>
            </div>
            <div class="tt2">优势描述</div>
            <div class="texterea-view">
              <el-form-item>
                <el-input
                  ref="description_ref"
                  type="textarea"
                  placeholder="请输入内容"
                  rows="5"
                  maxlength="500"
                  v-model="description"
                >
                </el-input>
                <div class="tips-text-view">
                  <span class="tp-text">建议填写字数在20个字以上</span>
                  <span class="text-num">{{ description.length }}/500</span>
                </div>
              </el-form-item>
            </div>

            <el-form-item class="yx-el-for-it" style="text-align: right">
              <el-button
                @click="updateOther({ description: description })"
                class="btn btn-save"
                type="primary"
                >保存</el-button
              >
              <el-button @click="ysEdtShow = false" class="btn btn-center"
                >取消</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>

      <div class="other-resume-inf-view" v-show="!expEdtShow">
        <div class="com-title-view">
          <span class="title-text">工作经历</span>
          <div class="right-bj-view" @click="openUpdate('works')">
            <img src="../assets/userinf_add_img.png" alt="" />
          </div>
        </div>
        <div v-show="userResemDetail.works.length == 0" class="nonetext-view">
          你还未添加工作经历，快去填写哦~
        </div>

        <div
          v-show="userResemDetail.works.length > 0"
          v-for="(item, index) in userResemDetail.works"
          :key="item.id"
          @click="toEdtJobExp(item)"
          :class="item.is_over ? 'userhome-hover' : ''"
          @mouseover="hadlerMouseOver('works', index)"
          @mouseout="hadlerMoseOut('works', index)"
        >
          <div class="jltitle-title-view">
            <span class="title-text">{{ item.name }}</span>
            <span class="gztime-text"
              >{{ item.stime }} -
              {{ item.etime === "0" ? "至今" : item.etime }}</span
            >
            <div class="right-edit-view" v-show="item.is_over">
              <div class="ed-it">
                <!-- <img src="../assets/user_resume_edit.png" alt="" />
                <span>编辑</span> -->
                <el-button type="text" icon="el-icon-edit-outline"
                  >编辑</el-button
                >
              </div>
              <div class="ed-it">
                <el-button
                  @click.stop="delResumeOthere('work', item.id)"
                  type="text"
                  icon="el-icon-delete"
                  >删除</el-button
                >
                <!-- <img src="../assets/user_resume_del.png" alt="" />
                <span>删除</span> -->
              </div>
            </div>
          </div>
          <div class="jl-title2">{{ item.title }}</div>
          <div class="jl-desp-text" v-show="item.content">
            工作描述：{{ item.content }}
          </div>
        </div>
      </div>

      <div class="edi-other-nowr-view" v-show="expEdtShow">
        <div class="topo-title-view">
          <div class="com-title-view">
            <span class="title-text">工作经历</span>
            <div class="right-bj-view">
              <img src="../assets/userinf_add_img.png" alt="" />
            </div>
          </div>
        </div>
        <div class="edit-view">
          <el-form label-position="left">
            <div class="edi-one-item">
              <el-form-item label="">
                <div class="items">
                  <div class="title">公司名称</div>
                  <!-- <el-input
                    placeholder="填写公司名称"
                    v-model="input"
                    clearable
                  >
                  </el-input> -->
                  <el-autocomplete
                    ref="comName_ref"
                    v-model="jobExperienceObj.name"
                    :fetch-suggestions="expComQuerySearchAsync"
                    placeholder="填写公司名称"
                    @select="experComhandleSelect"
                    :trigger-on-focus="false"
                  ></el-autocomplete>
                </div>
              </el-form-item>
            </div>

            <div class="edi-lin-item">
              <el-form-item label="">
                <div class="items">
                  <div class="title">职位名称</div>
                  <el-input placeholder="必填" v-model="jobExperienceObj.title">
                  </el-input>
                </div>
              </el-form-item>
              <el-form-item label="">
                <div class="items">
                  <div class="title">工作时间</div>
                  <div class="sl-group-view">
                    <el-date-picker
                      v-model="jobExperienceObj.sdate"
                      type="month"
                      placeholder="选择月"
                      value-format="timestamp"
                      :picker-options="gzExSetMonthDisabled"
                    >
                    </el-date-picker>
                    <span class="he-xian">-</span>
                    <el-date-picker
                      v-show="jobExperienceObj.edate !== 0"
                      v-model="jobExperienceObj.edate"
                      type="month"
                      placeholder="选择月"
                      value-format="timestamp"
                      :picker-options="gzExSetMonthDisabled"
                    >
                    </el-date-picker>
                    <el-checkbox
                      style="
                        margin-left: 23px;

                        font-weight: 400;
                        color: #191919;
                        line-height: 19px;
                      "
                      v-model="isSoFar"
                      >至今</el-checkbox
                    >
                  </div>
                </div>
              </el-form-item>
            </div>

            <div class="tt2">工作描述（选填）</div>
            <div class="texterea-view">
              <el-form-item>
                <el-input
                  type="textarea"
                  placeholder="描述这段工作期间你的主要工作内容，如：
1、职责范围
2、工作任务
3、工作产出"
                  rows="6"
                  maxlength="500"
                  v-model="jobExperienceObj.content"
                >
                </el-input>
                <div class="tips-text-view">
                  <span class="tp-text">建议填写字数在20个字以上</span>
                  <span class="text-num">0/500</span>
                </div>
              </el-form-item>
            </div>
            <el-form-item class="yx-el-for-it" style="text-align: right">
              <el-button
                @click="jobExperienceFuc"
                class="btn btn-save"
                type="primary"
                >保存</el-button
              >
              <el-button @click="expEdtShow = false" class="btn btn-center"
                >取消</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>

      <div class="other-resume-inf-view" v-show="!eduEdtShow">
        <div class="com-title-view">
          <span class="title-text">教育经历</span>
          <div class="right-bj-view" @click="openUpdate('edu')">
            <img src="../assets/userinf_add_img.png" alt="" />
          </div>
        </div>
        <div v-show="userResemDetail.edus.length == 0" class="nonetext-view">
          你还未填写个人优势，快去填写哦~
        </div>

        <div
          v-show="userResemDetail.edus.length > 0"
          v-for="(item, index) in userResemDetail.edus"
          :key="item.id"
          @click="toEditEducation(item)"
          :class="item.is_over ? 'userhome-hover' : ''"
          @mouseover="hadlerMouseOver('edus', index)"
          @mouseout="hadlerMoseOut('edus', index)"
        >
          <div class="jltitle-title-view">
            <span class="title-text">{{ item.name }}</span>
            <span class="gztime-text">{{ item.stime }} - {{ item.etime }}</span>
            <div class="right-edit-view" v-show="item.is_over">
              <div class="ed-it">
                <!-- <img src="../assets/user_resume_edit.png" alt="" />
                <span>编辑</span> -->
                <el-button type="text" icon="el-icon-edit-outline"
                  >编辑</el-button
                >
              </div>
              <div class="ed-it">
                <el-button
                  @click.stop="delResumeOthere('edu', item.id)"
                  type="text"
                  icon="el-icon-delete"
                  >删除</el-button
                >
                <!-- <img src="../assets/user_resume_del.png" alt="" />
                <span>删除</span> -->
              </div>
            </div>
          </div>
          <div class="jl-title3">
            {{ item.education_name }}
            <span v-show="item.education !== '80'">| {{ item.specialty }}</span>
          </div>
          <div class="jl-desp-text" v-show="item.content">在校经历：{{ item.content }}</div>
        </div>
      </div>
      <div class="edi-other-nowr-view" v-show="eduEdtShow">
        <div class="topo-title-view">
          <div class="com-title-view">
            <span class="title-text">教育经历</span>
            <div class="right-bj-view">
              <img src="../assets/userinf_add_img.png" alt="" />
            </div>
          </div>
        </div>
        <div class="edit-view">
          <el-form label-position="left">
            <div class="edi-one-item">
              <el-form-item label="">
                <div class="items">
                  <div class="title">学校名称</div>
                  <!-- <el-input placeholder="填写学校名称" clearable> </el-input> -->
                  <el-autocomplete
                    ref="eduComhandle_ref"
                    v-model="educationObj.name"
                    :fetch-suggestions="eduScQuerySearchAsync"
                    placeholder="填写学校名称"
                    @select="eduComhandleSelect"
                    :trigger-on-focus="false"
                  ></el-autocomplete>
                </div>
              </el-form-item>
            </div>

            <div class="edi-lin-item">
              <el-form-item label="">
                <div class="items">
                  <div class="title">学历</div>
                  <el-select
                    v-model="educationObj.education"
                    placeholder="请选择"
                    @change="eduXlChose"
                  >
                    <label v-for="item in xlChoseArr" :key="item.id">
                      <el-option
                        :label="item.name"
                        :value="item.id"
                      ></el-option>
                    </label>
                  </el-select>
                </div>
              </el-form-item>
              <el-form-item label="" v-show="educationObj.education !== '80'">
                <div class="items">
                  <div class="title">专业</div>
                  <el-input
                    v-model="educationObj.specialty"
                    placeholder="必填"
                    clearable
                  >
                  </el-input>
                </div>
              </el-form-item>
            </div>
            <div class="edi-lin-item">
              <el-form-item label="">
                <div class="items">
                  <div class="title">教育时间</div>
                  <div class="sl-group-view">
                    <el-date-picker
                      v-model="educationObj.sdate"
                      type="month"
                      placeholder="选择月"
                      value-format="timestamp"
                      :picker-options="edExSetMonthDisabled"
                    >
                    </el-date-picker>
                    <span class="he-xian">-</span>
                    <el-date-picker
                      v-model="educationObj.edate"
                      type="month"
                      placeholder="选择月"
                      value-format="timestamp"
                      :picker-options="edExSetMonthDisabled"
                    >
                    </el-date-picker>
                  </div>
                </div>
              </el-form-item>
            </div>

            <div class="tt2">在校经历（选填）</div>
            <div class="texterea-view">
              <el-form-item>
                <el-input
                  type="textarea"
                  placeholder="描述这段学习期间你的主要在校经历"
                  rows="6"
                  maxlength="500"
                  v-model="educationObj.content"
                >
                </el-input>
                <div class="tips-text-view">
                  <span class="tp-text">建议填写字数在20个字以上</span>
                  <span class="text-num"
                    >{{ educationObj.content.length }}/500</span
                  >
                </div>
              </el-form-item>
            </div>

            <el-form-item class="yx-el-for-it" style="text-align: right">
              <el-button
                @click="saveEducationFuc"
                class="btn btn-save"
                type="primary"
                >保存</el-button
              >
              <el-button @click="eduEdtShow = false" class="btn btn-center"
                >取消</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>

      <div class="other-resume-inf-view" v-show="!pxEdtShow">
        <div class="com-title-view">
          <span class="title-text">培训经历</span>
          <div class="right-bj-view" @click="openUpdate('pxEdt')">
            <img src="../assets/userinf_add_img.png" alt="" />
          </div>
        </div>
        <div
          v-show="userResemDetail.trainings.length == 0"
          class="nonetext-view"
        >
          你还未填写个培训经历，快去填写哦~
        </div>
        <div
          v-show="userResemDetail.trainings.length > 0"
          v-for="(item, index) in userResemDetail.trainings"
          :key="item.id"
          :class="item.is_over ? 'userhome-hover' : ''"
          @mouseover="hadlerMouseOver('trainings', index)"
          @mouseout="hadlerMoseOut('trainings', index)"
          @click="toEditPx(item)"
        >
          <div class="jltitle-title-view">
            <span class="title-text">{{ item.name }}</span>
            <span class="gztime-text">{{ item.stime }} - {{ item.etime }}</span>
            <div class="right-edit-view" v-show="item.is_over">
              <div class="ed-it">
                <!-- <img src="../assets/user_resume_edit.png" alt="" />
                <span>编辑</span> -->
                <el-button type="text" icon="el-icon-edit-outline"
                  >编辑</el-button
                >
              </div>
              <div class="ed-it">
                <el-button
                  @click.stop="delResumeOthere('training', item.id)"
                  type="text"
                  icon="el-icon-delete"
                  >删除</el-button
                >
                <!-- <img src="../assets/user_resume_del.png" alt="" />
                <span>删除</span> -->
              </div>
            </div>
          </div>
          <!-- <div class="jl-title3">
              {{ item.education_name }} | {{ item.specialty }}
            </div> -->
          <div class="jl-desp-text">培训经历：{{ item.content }}</div>
        </div>
      </div>
      <div class="edi-other-nowr-view" v-show="pxEdtShow">
        <div class="topo-title-view">
          <div class="com-title-view">
            <span class="title-text">培训经历</span>
            <div class="right-bj-view">
              <img src="../assets/userinf_add_img.png" alt="" />
            </div>
          </div>
        </div>
        <div class="edit-view">
          <el-form label-position="left">
            <div class="edi-one-item">
              <el-form-item label="">
                <div class="items">
                  <div class="title">培训机构</div>
                  <el-input
                    ref="train_ref"
                    v-model="pxObj.name"
                    placeholder="填写培训机构"
                    clearable
                  >
                  </el-input>
                </div>
              </el-form-item>
            </div>

            <div class="edi-lin-item">
              <el-form-item label="">
                <div class="items">
                  <div class="title">培训时间时间</div>
                  <div class="sl-group-view">
                    <el-date-picker
                      type="month"
                      placeholder="选择月"
                      value-format="timestamp"
                      v-model="pxObj.sdate"
                      :picker-options="pxExSetMonthDisabled"
                    >
                    </el-date-picker>
                    <span class="he-xian">-</span>
                    <el-date-picker
                      type="month"
                      placeholder="选择月"
                      value-format="timestamp"
                      v-model="pxObj.edate"
                      :picker-options="pxExSetMonthDisabled"
                    >
                    </el-date-picker>
                  </div>
                </div>
              </el-form-item>
            </div>

            <div class="tt2">内容描述（选填）</div>
            <div class="texterea-view">
              <el-form-item>
                <el-input
                  type="textarea"
                  placeholder="描述一下你培训的专业及课程内容"
                  rows="5"
                  maxlength="500"
                  v-model="pxObj.content"
                >
                </el-input>
                <div class="tips-text-view">
                  <span class="tp-text">建议填写字数在20个字以上</span>
                  <span class="text-num">{{ pxObj.content.length }}/500</span>
                </div>
              </el-form-item>
            </div>

            <el-form-item class="yx-el-for-it" style="text-align: right">
              <el-button @click="savePxFuc" class="btn btn-save" type="primary"
                >保存</el-button
              >
              <el-button @click="hidEditView" class="btn btn-center"
                >取消</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="other-resume-inf-view" v-show="!jnzsEdtShow">
        <div class="com-title-view">
          <span class="title-text">技能证书</span>
          <div class="right-bj-view" @click="openUpdate('jnzs')">
            <img src="../assets/userinf_add_img.png" alt="" />
          </div>
        </div>
        <div v-show="userResemDetail.skills.length == 0" class="nonetext-view">
          你还未上传技能证书，快去上传哦~
        </div>
        <div
          class="jnzs-items-view"
          v-for="(item, index) in userResemDetail.skills"
          :key="item.id"
          :class="item.is_over ? 'userhome-hover' : ''"
          @mouseover="hadlerMouseOver('skills', index)"
          @mouseout="hadlerMoseOut('skills', index)"
        >
          <div class="jltitle-title-view" @click="toEditJnzs(item)">
            <span class="title-text">{{ item.name }}</span>
            <span class="gztime-text">{{ item.longtime }}</span>
            <div class="right-edit-view" v-show="item.is_over">
              <div class="ed-it">
                <!-- <img src="../assets/user_resume_edit.png" alt="" />
                <span>编辑</span> -->
                <el-button type="text" icon="el-icon-edit-outline"
                  >编辑</el-button
                >
              </div>
              <div class="ed-it">
                <el-button
                  @click.stop="delResumeOthere('skill', item.id)"
                  type="text"
                  icon="el-icon-delete"
                  >删除</el-button
                >
                <!-- <img src="../assets/user_resume_del.png" alt="" />
                <span>删除</span> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="edi-other-nowr-view" v-show="jnzsEdtShow">
        <div class="topo-title-view">
          <div class="com-title-view">
            <span class="title-text">技能证书</span>
            <div class="right-bj-view">
              <img src="../assets/userinf_add_img.png" alt="" />
            </div>
          </div>
        </div>
        <div class="edit-view">
          <el-form label-position="left">
            <div class="edi-one-item">
              <el-form-item label="">
                <div class="items">
                  <div class="title">证书名称</div>
                  <el-input
                    ref="ediotherr_ef"
                    v-model="jnzsObj.name"
                    placeholder="请填写证书名称"
                    clearable
                  >
                  </el-input>
                </div>
              </el-form-item>
            </div>

            <div class="edi-lin-item">
              <el-form-item label="">
                <div class="items">
                  <div class="title">获得时间</div>
                  <el-date-picker
                    v-model="jnzsObj.get_time"
                    type="month"
                    placeholder="选择月"
                    value-format="timestamp"
                    :picker-options="gzExSetMonthDisabled"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
            </div>

            <el-form-item class="yx-el-for-it" style="text-align: right">
              <el-button
                @click="saveJnzsFuc"
                class="btn btn-save"
                type="primary"
                >保存</el-button
              >
              <el-button @click="jnzsEdtShow = false" class="btn btn-center"
                >取消</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="other-resume-inf-view" v-show="!isShowUplocdImg">
        <div class="com-title-view">
          <span class="title-text">我的作品</span>
          <div class="right-bj-view" @click="isShowUplocdImg = true">
            <img src="../assets/userinf_add_img.png" alt="" />
          </div>
        </div>
        <div class="nonetext-view" v-show="shows.length <= 0 && !video">
          你还未上传作品，快去上传哦~
        </div>
        <div class="work-erp-view" v-show="shows.length > 0 || video">
          <div
            v-show="video.picurl"
            @click="isOpenVideo = true"
            class="el-image"
            style="
              width: 100px;
              height: 100px;
              border: #e6e6e6 solid 1px;
              margin: 0 20px 20px 0;
              cursor: pointer;
            "
          >
            <img
              src="@/assets/transfer_spplay.png"
              style="
                width: 40px;
                height: 40px;
                position: absolute;
                left: 30px;
                top: 30px;
              "
            />
            <img :src="video.cover" style="width: 100%; height: 100%" />
          </div>
          <label v-for="(item, index) in shows" :key="index">
            <el-image
              style="
                border: #e6e6e6 1px solid;
                width: 100px;
                height: 100px;
                margin: 0 20px 20px 0;
              "
              :src="item"
              :preview-src-list="shows"
            >
            </el-image>
          </label>
        </div>
      </div>
      <div
        class="other-resume-inf-view"
        v-show="isShowUplocdImg"
        ref="uploadIcon_ref"
      >
        <div class="com-title-view">
          <span class="title-text">我的作品</span>
          <div class="right-bj-view">
            <img src="../assets/userinf_add_img.png" alt="" />
          </div>
        </div>
        <div class="work-erp-view">
          <div
            v-show="video.picurl"
            @click="isOpenVideo = true"
            class="el-image"
            style="
              width: 100px;
              height: 100px;
              border: #e6e6e6 solid 1px;
              margin: 0 20px 20px 0;
              cursor: pointer;
            "
          >
            <img
              src="@/assets/transfer_spplay.png"
              style="
                width: 40px;
                height: 40px;
                position: absolute;
                left: 30px;
                top: 30px;
              "
            />
            <img :src="video.cover" style="width: 100%; height: 100%" />
            <img
              @click="delWorksImg('video')"
              class="colse-img"
              src="@/assets/com_index_del_img.png"
            />
          </div>
          <label
            v-for="(item, index) in shows"
            :key="index"
            style="position: relative"
          >
            <el-image
              style="
                border: #e6e6e6 1px solid;
                width: 100px;
                height: 100px;
                margin: 0 20px 20px 0;
              "
              :src="item"
              :preview-src-list="shows"
            >
            </el-image>
            <img
              @click="delWorksImg(index)"
              class="colse-img"
              src="@/assets/com_index_del_img.png"
            />
          </label>
          <el-upload
            :show-file-list="false"
            class="el-upload-view"
            :action="updateUrl"
            multiple
            :auto-upload="true"
            list-type="picture-card"
            :on-success="uploadSuccess"
            :on-remove="handleRemove"
            style="margin-top: 10px"
            :before-upload="beforeUploadEve"
            :limit="9"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </div>
        <div class="yx-el-for-it" style="text-align: right">
          <el-button @click="saveImage()" class="btn btn-save" type="primary"
            >保存</el-button
          >
          <el-button @click="closeUpdateImg" class="btn btn-center"
            >取消</el-button
          >
        </div>
      </div>
    </div>
    <div class="edmesume-right-view">
      <div class="right-top-btn-view">
        <el-button class="btn" type="primary" plain @click="previewResume()"
          >预览简历</el-button
        >
        <el-button
          class="btn"
          type="primary"
          icon="el-icon-refresh"
          @click="refreshResu"
          >刷新简历</el-button
        >
      </div>
      <div class="jlwzd-view">
        <!--        <img class="wzd-bg-img" src="../assets/userinf_bg2.png" alt="" />-->
        <div class="wzd-text-view">
          <div class="top-pro-prc-view">
            <img
              class="left-img"
              src="../assets/userinf_jldws_img.png"
              alt=""
            />
            <div class="pro-left-view">
              <span class="text1">简历完整度</span>
              <el-progress
                class="wzd-el-progress"
                :text-inside="true"
                :stroke-width="8"
                :percentage="userResumScore.score.bili"
                :show-text="false"
              ></el-progress>
            </div>
            <div class="right-prce">{{ userResumScore.score.bili }}<label>%</label></div>
          </div>
          <div
            v-show="getCompleteData.length > 0"
            style="
              background-color: #fff;
              margin-top: 21px;
              padding: 30px 0;
              height: 340px;
              overflow: auto;
            "
          >
            <div class="jldwasx-title-view">
              <div class="title-view">
                <span>简历完善项</span>
                <span class="banum">{{ getCompleteData.length }}</span>
              </div>
              <div class="ws-desp">完善后可增加入职竞争力</div>
            </div>
            <div class="dws-item-view">
              <div
                class="item"
                v-for="(item, idx) in getCompleteData"
                :key="idx"
              >
                <div class="tp-view">
                  <span class="dian"></span>
                  <span class="ws-text">{{ item }}</span>
                </div>
                <div class="bt-view">
                  <div class="left-text">未填写</div>
                  <div class="right-view">
                    <span
                      class="ri-text"
                      style="cursor: pointer"
                      @click="perfectParams(item)"
                    >
                      去补充
                    </span>
                    <i class="el-icon-arrow-right"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="user-cent-qzbb-view">
        <div class="qzbb-title-view">
          <span>求职必备</span>
        </div>
        <div class="qzbb-item-view">
          <div class="item">
            <img src="../assets/userinf_jlyh.png" alt="" />
            <div>简历优化</div>
          </div>
          <div class="item">
            <img src="../assets/userinf_jlzd.png" alt="" />
            <div>简历置顶</div>
          </div>
          <div class="item">
            <img src="../assets/userinf_jldt.png" alt="" />
            <div>简历代投</div>
          </div>
        </div>
      </div>
      <div class="user-cent-qzbb-view">
        <div class="qzbb-title-view">
          <span>简历隐私设置</span>
          <router-link to="/privacy"
            ><span class="qzbb-title-right-text">设置</span></router-link
          >
        </div>
        <div class="jlys-item-view">
          <div class="item">
            <span class="dian">·</span>
            <span class="jxys-text1">简历状态</span>
            <span class="right-text2">{{ resumeStatus.isShowResume }}</span>
          </div>
        </div>
        <div class="jlys-item-view">
          <div class="item">
            <span class="dian">·</span>
            <span class="jxys-text1">屏蔽公司</span>
            <span class="right-text2"
              >已屏蔽{{ resumeStatus.shieldLengh }}个</span
            >
          </div>
        </div>
      </div>
    </div>

    <choseonecity
      :dialogVisible2="isCityOneShow"
      @closeDilog2="closeDilog2"
      @saveJobAddr="saveJobAddrOne"
    ></choseonecity>
    <chosezw
      :dialogVisible1="isyxzw"
      @closeDilog1="closeDilog1"
      @saveJobArr="saveYxJobArr"
      :slJobArrF.sync="slJobArrF"
    ></chosezw>
    <chosehy
      :dialogVisible3="isJobHyShow"
      @closeDilog3="closeDilog3"
      @changeJobHy="changeJobHy"
      :hyIdF="hyIdF"
    ></chosehy>
    <chosecity
        v-if="isCityShow"
      :dialogVisible2="isCityShow"
      @closeDilog2="closeDilog4"
      @saveJobAddr="saveJobAddr"
      :is-show-all="true"
      :sladdreArrF="sladdreArrF"
    ></chosecity>
    <affirm-dialog
      :isopen-affirm="affir.isOpenAffirm"
      :affirm-dialog-close="affirmClose"
      :affirmDialogOk="delResumeOthereConfirm"
      affirmTitle="提示"
      :affirmMsge="affir.affirmMsge"
      :affirmFrom="affir.affirmFrom"
      :data-id="affir.params"
    >
    </affirm-dialog>
    <div class="video-open">
      <el-dialog
        :visible.sync="isOpenVideo"
        :show-close="false"
        :before-close="closeVideo"
      >
        <div style="height: 650px; display: flex; justify-content: center">
          <video
            controls
            style="max-height: 650px; min-width: 400px"
            ref="videoPlayer"
          >
            <source :src="video.picurl" type="video/mp4" />
            您的浏览器不支持 video 标签。
          </video>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  getResumScoreInf,
  getResumInf,
  getUserBaseInf,
  getUserClass,
  getUserQzStatus,
  getUserExpectInf,
  getUserJnHotTag,
  addJnTag,
  delJnTag,
  updateQzStatus,
  updateQzYxInf,
  updateUserBaseInf,
  addJobExperience,
  editJobExperience,
  expSearchCompany,
  eduScnameSearch,
  addUserEdu,
  editUserEdu,
  addPxExp,
  editPxExp,
  addJnzs,
  editJnzs,
  delResumeOthere,
  refreshResumeV2,
  editShow, echoCity,
} from "@/api/indiviuser/userInf.js";
import choseonecity from "@/components/choseCityOne.vue";
import chosecity from "@/components/choseCity.vue";
import chosezw from "@/components/choseZw.vue";
import chosehy from "@/components/choseHy.vue";
import { formatnyr1 } from "@/utils/index.js";
import { Loading } from "element-ui";
import store from "@/store";
import { getshieldList, myResumeStatus } from "@/api/commen/setApi";
import AffirmDialog from "@/components/affirmDialog.vue";
export default {
  name: "edtresume",
  components: {
    choseonecity,
    chosecity,
    chosezw,
    chosehy,
    AffirmDialog,
  },
  data() {
    return {
      updateUrl: process.env.VUE_APP_UPLOUD + "/api/upload/uploadCos",
      basInfEdtShow: false,
      qzztInfEdtShow: false,
      ysEdtShow: false,
      expEdtShow: false,
      eduEdtShow: false,
      pxEdtShow: false,
      jnzsEdtShow: false,
      isShowUplocdImg: false,
      userResumScore: {},
      userResemDetail: {},
      userBaseInf: {},
      xlChoseArr: {},
      shows: [],
      video: {
        picurl: "",
        cover: "",
      },
      isOpenVideo: false,
      baseObj: {
        show_name: 0,
        resume_photo: "",
        name: "",
        sex: "",
        birthday: "",
        job_type: "", //1 职场人  2 学生
        edu_name: "", //学历名
        edu: "", //学历id
        firstyear: "", //进入宠物行业时间
        living: "", //居住地
        domicile: "", //户籍地
        telphone: "", //手机号
        wxnum: "", //微信号
        email: "", //邮箱
      },
      isCityShow: false,
      isCityOneShow: false,
      isyxzw: false,
      isJobHyShow: false,
      cityType: 1,
      qzStatusArr: "",
      jobExpInf: "",
      isMouseOverObj: {
        jnzsOver: false,
      },
      // qzStatus: "",
      // dgTime: "",
      jbYxObj: {
        // job_classid_name: "",
        job_classid: "",
        city_classid: "",
        hy: "",
        jobstatus: "", // 求职状态
        report: "", // 随时到岗  周内到岗 月内到岗
        minsalary: 0,
        maxsalary: 0,
        type: "",
      },
      description: "",
      hotJnUserTag: [],
      userSelcTag: [],
      jobExperienceObj: {
        name: "",
        title: "",
        sdate: "",
        edate: "",
        content: "",
        is_hide: 0,
        eid: 0,
        com_id: "",
        work_id: 0,
      },
      educationObj: {
        id: 0,
        name: "",
        education: "",
        specialty: "",
        sdate: "",
        edate: "",
        content: "",
      },
      pxObj: {
        id: 0,
        name: "",
        sdate: "",
        edate: "",
        content: "",
      },
      jnzsObj: {
        id: 0,
        name: "",
        longtime: "",
        get_time: "",
        pic: "",
      },
      loadingInstance: "",
      resumeStatus: {
        isShowResume: "",
        shieldLengh: 0,
      },
      affir: {
        isOpenAffirm: false,
        affirmMsge: "",
        affirmFrom: "",
        params: "",
      },
      checkDis: false,
      minOptions: [],
      maxOptions: [],
      // setMonthDisabled: {
      //   // 返回禁用时间
      //   disabledDate(time) {
      //     // 获取当前的月份信息
      //     const date = new Date(); // 获取当前的时间基本信息，值是这样的： Tue Jul 20 2021 14:59:43 GMT+0800 (中国标准时间)
      //     const year = date.getFullYear(); // 获取当前年份，值是这样的： 2021
      //     let month = date.getMonth() + 1; // 获取当前月份，值是这样的： 6   getMonth()方法返回值是0-11，也就是1月份到12月份，所以要加上1，才是当前月份
      //     if (month >= 1 && month <= 9) {
      //       // 如果是1月到9月就要在前面补上一个0   比如：02、07    月份这样表示
      //       month = "0" + month;
      //     }
      //     const nowDate = year.toString() + month.toString(); // 转换成字符串拼接，最终得到年和月，比如此时的时间是2021年7月20号，所以nowDate的值是：202107

      //     // 获取时间选择器的月份信息
      //     const timeyear = time.getFullYear(); // 获取时间选择器的年份（有很多）
      //     let timemonth = time.getMonth() + 1; // 与上面同理
      //     if (timemonth >= 1 && timemonth <= 9) {
      //       timemonth = "0" + timemonth;
      //     }
      //     const elTimeData = timeyear.toString() + timemonth.toString();

      //     // 返回，时间选择器的日期月份大于当前日期的月份，又因为disabledDate函数是控制月份禁用不可选
      //     // 所以，最终就是：时间选择器的月份大于当前的月份，就都禁用掉，所以就实现了最终效果：
      //     // 小于等于当前月份都不可选
      //     console.log("时间限制", elTimeData, nowDate);
      //     return elTimeData > nowDate; // 这里虽然是字符串，但是弱类型语言js会做一个转换，是可以比较大小的如： '202107' > '202008'
      //   },
      // },
      brSetMonthDisabled: {
        disabledDate: (time) => {
          let timeStamp = Date.now() - 31536000000 * 65;
          return (
            time.getTime() < timeStamp || time.getTime() > Date.now() - 8.64e7
          );
        },
      },
      gzExSetMonthDisabled: {
        disabledDate: (time) => {
          let timeStamp = Date.now() - 31536000000 * 40;
          return (
            time.getTime() < timeStamp || time.getTime() > Date.now() - 8.64e7
          );
        },
      },
      edExSetMonthDisabled: {
        disabledDate: (time) => {
          let timeStamp = Date.now() - 31536000000 * 40;
          return (
            time.getTime() < timeStamp ||
            time.getTime() > Date.now() + 31536000000 * 5
          );
        },
      },
      pxExSetMonthDisabled: {
        disabledDate: (time) => {
          let timeStamp = Date.now() - 31536000000 * 40;
          return (
            time.getTime() < timeStamp ||
            time.getTime() > Date.now() - 8.64e7
          );
        },
      },
      reportId: "",
      slJobArrF: [],
      sladdreArrF: {},
      hyIdF:''
    };
  },
  // watch: {
  //   // 开始时间
  //   "jobExperienceObj.sdate"(selectTime) {
  //     const date = new Date(
  //       new Date(selectTime).setHours(0, 0, 0, 0)
  //     ).getTime();
  //     const today = new Date(new Date().setHours(0, 0, 0, 0)).getTime();
  //     if (date <= today) {
  //       // 当选择的日期就是当天的时候，这个时候就要限制时间应当大于此时此刻的时分秒
  //       this.pickerOptions.selectableRange =
  //         new Date().getHours() +
  //         ":" +
  //         (new Date().getMinutes() + 1) +
  //         ":00" +
  //         "- 23:59:59";
  //     } else {
  //       // 当选择的日期大于当天的时候，这时需要把时分秒的限制放开，否则不能选择
  //       this.pickerOptions.selectableRange = "00:00:00 - 23:59:59";
  //     }
  //   },
  //   // 结束时间
  //   "jobExperienceObj.edate"(selectTime) {
  //     const date = new Date(
  //       new Date(selectTime).setHours(0, 0, 0, 0)
  //     ).getTime();
  //     const today = new Date(new Date().setHours(0, 0, 0, 0)).getTime();
  //     if (date <= today) {
  //       // 当选择的日期就是当天的时候，这个时候就要限制时间应当大于此时此刻的时分秒
  //       this.pickerOptions.selectableRange =
  //         new Date().getHours() +
  //         ":" +
  //         (new Date().getMinutes() + 1) +
  //         ":00" +
  //         "- 23:59:59";
  //     } else {
  //       // 当选择的日期大于当天的时候，这时需要把时分秒的限制放开，否则不能选择
  //       this.pickerOptions.selectableRange = "00:00:00 - 23:59:59";
  //     }
  //   },
  // },

  computed: {
    isSoFar: {
      get() {
        console.log(this.jobExperienceObj.edate);
        if (this.jobExperienceObj.edate === 0) {
          return true;
        }
        return false;
      },
      set(value) {
        if (value) {
          this.jobExperienceObj.edate = 0;
        } else {
          this.jobExperienceObj.edate = new Date().getTime();
        }
      },
    },
    getAuthStatus() {
      return this.$store.state.user.userinf.authCount;
    },
    getUSerAuthStatus() {
      return store.getters.userinf.authCount;
    },
    getCompleteData() {
      let completeList = [];
      if (this.userResemDetail.member.description === "") {
        completeList.push("个人优势");
      }
      if (this.userResemDetail.works.length === 0) {
        completeList.push("工作经历");
      }
      if (this.userResemDetail.edus.length === 0) {
        completeList.push("教育经历");
      }
      if (this.userResemDetail.trainings.length === 0) {
        completeList.push("培训经历");
      }
      if (this.userResemDetail.skills.length === 0) {
        completeList.push("技能证书");
      }
      if (this.shows.length <= 0) {
        completeList.push("我的作品");
      }
      return completeList;
    },
    isPrice: {
      get() {
        if (this.jbYxObj.maxsalary == 0 && this.jbYxObj.minsalary == 0) {
          return true;
        }
        return false;
      },
      set(value) {
        console.log("isPrice:", value);
        if (value) {
          this.checkDis = true;
          this.jbYxObj.maxsalary = 0;
          this.jbYxObj.minsalary = 0;
        } else {
          this.jbYxObj.maxsalary = 3000;
          this.jbYxObj.minsalary = 1000;
          this.minChange(this.jbYxObj.minsalary);
          this.checkDis = false;
        }
      },
    },
  },
  created() {
    console.log("getAuthStatus:", this.getAuthStatus);
    this.getResumScoreInf();
    this.getResumInf();
    this.getUserBaseInf();
    this.getUserExpectInf();
    this.getMyResumeStatus();
    this.selectShieldList();
    this.minOptions = [];
    this.maxOptions = [];
    for (let i = 1; i <= 100; i++) {
      var a = {
        id: i * 1000,
        key: i + "k",
      };
      this.minOptions.push(a);
      if (i < 101) {
        var b = {
          id: (i + 1) * 1000,
          key: i + 1 + "k",
        };
        this.maxOptions.push(b);
      }
    }
  },
  methods: {
    createDefault(from){
      if (from === 'firstyear'){
        if (this.baseObj.firstyear == 0){
          this.baseObj.firstyear = new Date().getTime();
          console.log(this.baseObj.firstyear);
        }
      }
    },
    toOpenChooseCity(){
      echoCity().then(e=>{
        console.log(e)
        this.sladdreArrF = e.data;
        this.isCityShow = true;
      }).catch(()=>{});
    },
    openQzztInfEdt() {
      this.getUserQzStatus(true);
      this.qzztInfEdtShow = true;
    },
    successimgpath(e) {
      this.baseObj.resume_photo = e.data.httpCosUrl;
    },
    refreshResu() {
      refreshResumeV2().then(() => {
        this.$message.success("刷新成功");
      });
    },
    closeVideo() {
      const video = this.$refs.videoPlayer;
      if (video) {
        video.pause();
        video.currentTime = 0; // 可选，将视频 currentTime 重置为 0
      }
      this.isOpenVideo = false;
    },
    toSetPage() {
      this.$router.push("/setpage");
    },
    openUpdate(from) {
      switch (from) {
        case "edu": {
          this.getUserClass(true);
          this.educationObj.id = 0;
          this.educationObj.name = "";
          this.educationObj.education = "";
          this.educationObj.specialty = "";
          this.educationObj.sdate = "";
          this.educationObj.edate = "";
          this.educationObj.content = "";
          this.eduEdtShow = true;
          break;
        }
        case "works": {
          this.jobExperienceObj.name = "";
          this.jobExperienceObj.title = "";
          this.jobExperienceObj.sdate = "";
          this.jobExperienceObj.edate = "";
          this.jobExperienceObj.content = "";
          this.jobExperienceObj.is_hide = 0;
          this.jobExperienceObj.eid = 0;
          this.jobExperienceObj.com_id = "";
          this.jobExperienceObj.work_id = 0;
          this.expEdtShow = true;
          break;
        }
        case "pxEdt": {
          this.pxObj.id = 0;
          this.pxObj.name = "";
          this.pxObj.sdate = "";
          this.pxObj.edate = "";
          this.pxObj.content = "";
          this.pxEdtShow = true;
          break;
        }
        case "jnzs": {
          this.jnzsObj.id = 0;
          this.jnzsObj.name = "";
          this.jnzsObj.longtime = "";
          this.jnzsObj.get_time = "";
          this.jnzsObj.pic = "";
          this.jnzsEdtShow = true;
          break;
        }
      }
    },
    minChange(e) {
      console.log("最小", e);
      this.maxOptions = [];
      var va = e / 1000 + 1;
      let size = 0;
      for (let i = va; i < 101; i++) {
        console.log(va);
        var b = {
          id: (i + 1) * 1000,
          key: i + 1 + "k",
        };
        this.maxOptions.push(b);
        if (size === 6) break;
        size++;
      }
    },
    maxChange(e) {
      console.log("最大", e);
    },
    selectShieldList() {
      getshieldList().then((res) => {
        this.resumeStatus.shieldLengh = res.data.list.length;
      });
    },
    getMyResumeStatus() {
      myResumeStatus().then((res) => {
        this.resumeStatus.isShowResume =
          res.data.status === "0" ? "隐藏" : "公开";
      });
    },
    saveImage() {
      let picStr = "";
      if (this.shows.length > 0) {
        picStr = this.shows.join(",") + ",";
      }
      if (this.video.picurl) {
        picStr += this.video.picurl;
      }
      editShow(picStr).then(() => {});
      this.isShowUplocdImg = false;
    },
    //删除图片
    handleRemove() {
      this.$nextTick(() => {
        this.loadingInstance.close();
      });
    },
    closeUpdateImg() {
      this.getResumInf();
      this.isShowUplocdImg = false;
    },
    //上传图片成功回调
    uploadSuccess(e) {
      console.log('我的简历')
      this.$nextTick(() => {
        this.loadingInstance.close();
      });
      if (e.code === 0){
        return this.$message.error(e.msg)
      }
      let url = e.data.httpCosUrl;
      if (url.endsWith(".mp4")) {
        this.video.picurl = url;
        this.video.cover = e.data.httpsCover;
      } else {
        this.shows.push(url);
      }
    },
    // 上传前的狗子
    beforeUploadEve(file) {
      this.loadingInstance = Loading.service({ background: "rgba(0,0,0,0.1)" });
      console.log("beforeUploadEve:", file);
      let fileType = file.type;
      let typeArr = ["image/jpeg", "image/png", "video/mp4"];
      if (!typeArr.includes(fileType)) {
        this.$message.error("媒体类型异常");
        return false;
      }
      let astrictNum = 5;
      let isMp4 = file.name.endsWith(".mp4");
      if (isMp4) {
        astrictNum = 50;
      }
      let isLt2M = file.size / 1024 / 1024 > astrictNum;
      if (isLt2M) {
        this.$message.error("媒体超过限制大小5M");
        return false;
      }
      if (this.shows.length >= 9) {
        console.log("超出限制....");
        this.$message.error("最多可上传9张图片");
        return false;
      }
      if (this.video.picurl && fileType === "video/mp4") {
        this.$message.error("最多可上传一个视频");
        return false;
      }
      return true;
    },
    delWorksImg(from) {
      if (from === "video") {
        this.video.cover = "";
        this.video.picurl = "";
      } else {
        this.shows.splice(from, 1);
      }
    },
    perfectParams(from) {
      let toRefName = "";
      switch (from) {
        case "个人优势": {
          this.userUserYsShow();
          toRefName = "description_ref";
          break;
        }
        case "工作经历": {
          this.expEdtShow = true;
          toRefName = "comName_ref";
          break;
        }
        case "教育经历": {
          this.eduEdtShow = true;
          toRefName = "eduComhandle_ref";
          break;
        }
        case "培训经历": {
          this.pxEdtShow = true;
          toRefName = "train_ref";
          break;
        }
        case "技能证书": {
          this.jnzsEdtShow = true;
          toRefName = "ediotherr_ef";
          break;
        }
        case "我的作品": {
          this.isShowUplocdImg = true;
          this.$nextTick(() => {
            this.$refs["uploadIcon_ref"].scrollIntoView();
          });
          return;
        }
        default:
          return;
      }
      this.$nextTick(() => {
        this.$refs[toRefName].focus();
      });
    },
    previewResume() {
      this.$router.push(
        `/talentsdetail?id=${this.userResemDetail.expect.uid}&isyl=1`
      );
    },
    hadlerMouseOver(itemname, index) {
      this.userResemDetail[itemname][index].is_over = true;
      // itemname =
      // this.isMouseOverObj[itemname] = true;
      // console.log(this.isMouseOverObj);
    },
    hadlerMoseOut(itemname, index) {
      this.userResemDetail[itemname][index].is_over = false;

      // this.isMouseOverObj[itemname] = false;
    },
    //获取简历待完善项
    getResumScoreInf() {
      getResumScoreInf()
        .then((result) => {
          console.log("简历待完善", result);
          let bili = result.data.score.bili.split("%")[0];
          if (isNaN(bili)) {
            result.data.score.bili = 0;
          } else {
            result.data.score.bili = Number(bili);
          }
          this.userResumScore = result.data;
        })
        .catch(() => {});
    },
    //获取简历详情
    getResumInf() {
      getResumInf()
        .then((result) => {
          console.log("获取简历详情", result);
          result.data.works.forEach((currentItem) => {
            currentItem.is_over = false;
            currentItem.stime = formatnyr1(currentItem.sdate);
            if (currentItem.edate !== "0") {
              currentItem.etime = formatnyr1(currentItem.edate);
            } else {
              currentItem.etime = "0";
            }
          });
          result.data.edus.forEach((currentItem) => {
            currentItem.is_over = false;
            currentItem.stime = formatnyr1(currentItem.sdate);
            currentItem.etime = formatnyr1(currentItem.edate);
          });
          result.data.trainings.forEach((currentItem) => {
            currentItem.is_over = false;
            currentItem.stime = formatnyr1(currentItem.sdate);
            currentItem.etime = formatnyr1(currentItem.edate);
          });
          result.data.skills.forEach((element) => {
            element.is_over = false;
          });
          this.description = result.data.member.description;
          this.shows = [];
          this.video.picurl = "";
          this.video.cover = "";
          result.data.shows.forEach((im) => {
            if (im.coverurl === "") {
              this.shows.push(im.picurl);
            } else {
              this.video.picurl = im.picurl;
              this.video.cover = im.coverurl;
            }
          });
          // for (let i = 0; i < this.shows.length; i++) {
          //   if (this.shows[i].picurl.endsWith('.mp4')) {
          //     this.video = this.shows[i];
          //     this.shows.splice(i, 1);
          //     i--;
          //   }else {
          //     this.shows[i].imgType = 1;
          //   }
          // }
          this.userResemDetail = result.data;
        })
        .catch(() => {});
    },
    //获取基本信息
    getUserBaseInf() {
      getUserBaseInf()
        .then((result) => {
          console.log("基本信息", result);
          this.userBaseInf = result.data;
          let firstyear = result.data.firstyear * 1000;
          // let birthday = result.data.birthday;
          // if (firstyear != "" && firstyear != 0) {
          //   const createDate = new Date();
          //   createDate.setTime(firstyear * 1000);
          //   firstyear = createDate.format("yyyy-MM");
          // } else {
          //   firstyear = this.date;
          // }
          // if (birthday != "") {
          //   birthday = birthday.substring(0, 7);
          // } else {
          //   birthday = "请选择";
          // }
          // if (result.data.idcard_status == 1) {
          //   this.isSm = true;
          // } else {
          //   this.isSm = false;
          // }
          this.baseObj.resume_photo = result.data.resume_photo;
          this.baseObj.name = result.data.name;
          this.baseObj.sex = result.data.sex;
          this.baseObj.birthday = result.data.birthday;
          this.baseObj.job_type = result.data.job_type;
          this.baseObj.edu_name = result.data.edu_name;
          this.baseObj.edu = result.data.edu;
          this.baseObj.living = result.data.living;
          this.baseObj.domicile = result.data.domicile;
          this.baseObj.telphone = result.data.telphone;
          this.baseObj.wxnum = result.data.wxnum;
          this.baseObj.email = result.data.email;
          this.baseObj.firstyear = firstyear;
          this.baseObj.show_name = result.data.show_name == "1" ? true : false;
        })
        .catch(() => {});
    },
    //获取求职意向信息
    getUserExpectInf() {
      getUserExpectInf()
        .then((result) => {
          console.log("获取求职意向", result);
          var jobclassname = [],
            jobclassnamestr = "",
            cityname = [],
            citynamestr = "";

          result.data.job_classid_name.forEach((element) => {
            jobclassname.push(element.name);
            var it = { name: element.name };
            this.slJobArrF.push(it);
            // element.jobclassname =
          });

          result.data.city_name.forEach((element) => {
            cityname.push(element.name);
          });

          jobclassnamestr = String(jobclassname).replace(/,/g, "/");
          result.data.jobclassname = jobclassnamestr;
          citynamestr = String(cityname).replace(/,/g, "/");
          var jbcaid = result.data.job_classid.split(",");
          this.slJobArrF.forEach((element, index) => {
            element.id = jbcaid[index];
          });

          console.log("意向名字", this.slJobArrF);
          result.data.cityname = citynamestr;
          this.jbYxObj.job_classid = result.data.job_classid;
          this.jbYxObj.city_classid = result.data.city_classid;
          this.jbYxObj.jobstatus = result.data.jobstatus;
          this.jbYxObj.report = result.data.report;
          this.reportId = result.data.report;
          this.jbYxObj.minsalary = result.data.minsalary;
          this.jbYxObj.maxsalary = result.data.maxsalary;
          this.jbYxObj.hy = result.data.hy;
          this.jbYxObj.type = result.data.type;
          this.jobExpInf = result.data;
          this.hyIdF = result.data.hy;
          if (this.jbYxObj.minsalary != 0) {
            this.minChange(this.jbYxObj.minsalary);
          }
        })
        .catch(() => {});
    },
    openEdieBasInf() {
      this.getUserClass(true);
      this.basInfEdtShow = true;
    },
    //获取学历列表
    getUserClass(e) {
      console.log("getUserClass:", e);
      if (e) {
        getUserClass({ name: "user_edu" })
          .then((result) => {
            console.log("学历列表", result);
            this.xlChoseArr = result.data.list.user_edu;
          })
          .catch(() => {});
      }
    },
    //获取求职状态
    getUserQzStatus(e) {
      if (e) {
        getUserQzStatus()
          .then((result) => {
            console.log("获取求职状态", result);
            this.qzStatusArr = result.data;
          })
          .catch(() => {});
      }
    },
    chosesex(e) {
      if (this.getAuthStatus > 0) {
        return this.$message.warning("已认证不能修改性别");
      }
      this.baseObj.sex = e;
    },
    // xlChose(e) {
    //   console.log(e);
    //   this.baseObj.edu = e;
    // },
    eduXlChose(e) {
      this.educationObj.education = e;
      if (e === "80") {
        this.educationObj.specialty = "";
      }
    },
    jobTypeChose(e) {
/*      if (e === 1){
        if (this.)
      }*/
      this.baseObj.job_type = e;
    },

    closeDilog2() {
      this.isCityOneShow = false;
    },
    closeDilog1() {
      this.isyxzw = false;
    },
    closeDilog3() {
      this.isJobHyShow = false;
    },
    closeDilog4() {
      this.isCityShow = false;
    },

    choseCityOne(e) {
      if (e === 2 && this.getAuthStatus > 0) {
        return;
      }
      this.cityType = e;
      this.isCityOneShow = true;
    },
    jobTypeChange(e) {
      // console.log("ppp", e);
      this.jbYxObj.type = e;
    },

    saveJobAddrOne(e) {
      // console.log(e);
      if (this.cityType == 1) {
        this.baseObj.living = e.name;
      } else {
        this.baseObj.domicile = e.name;
      }
      this.isCityOneShow = false;
    },
    saveJobAddr(pr, ct, ar) {
      console.log(pr, ct, ar);
      var citys = [],
        citysname = [];
      ar.forEach((element) => {
        citys.push(element.id);
        citysname.push(element.name);
      });
      this.jobExpInf.cityname = String(citysname).replace(/,/g, "/");
      this.jbYxObj.city_classid = String(citys);
      this.isCityShow = false;
    },
    saveYxJobArr(e) {
      console.log(e);
      let ids = e.map((e) => e.id);
      let names = e.map((e) => e.name);
      this.jobExpInf.jobclassname = names.join("/");
      this.jbYxObj.job_classid = String(ids);
      this.isyxzw = false;
    },
    changeJobHy(e) {
      this.jbYxObj.hy = e.id;
      this.jobExpInf.hy_name = e.name;
      this.hyIdF = e.id;
      this.isJobHyShow = false;
    },

    //改变求职状态qzZtChange

    qzZtChange(e) {
      this.this.jbYxObj.report = this.reportId;
      updateQzStatus({
        current_job_status: e,
        current_report: this.jbYxObj.report,
      })
        .then((result) => {
          console.log("更新求职状态", result);
        })
        .catch(() => {});
    },
    dgTimeChange(e) {
      console.log(e);
      /*      updateQzStatus({
        current_job_status: this.jbYxObj.jobstatus,
        current_report: e,
      })
        .then((result) => {
          console.log("更新求职状态", result);
        })
        .catch(() => {});*/
    },

    userUserYsShow() {
      this.ysEdtShow = true;
      getUserJnHotTag()
        .then((result) => {
          console.log("热门个人优势", result);
          this.hotJnUserTag = result.data.skill_tag;
          this.userSelcTag = result.data.resume_skill_tag;
        })
        .catch(() => {});
    },

    //添加技能标签弹窗
    addUserJnTag() {
      if (this.userSelcTag.length >= 10) {
        this.$message({
          message: "最多只能添加10个技能标签哦",
          type: "warning",
        });
      } else {
        this.$prompt("", "添加技能标签", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          inputErrorMessage: "邮箱格式不正确",
        })
          .then(({ value }) => {
            console.log(value);
            addJnTag({ name: value })
              .then((result) => {
                console.log("添加热门标签", result);
                this.userUserYsShow();
              })
              .catch(() => {});
            // this.$message({
            //   type: "success",
            //   message: "你的邮箱是: " + value,
            // });
          })
          .catch(() => {});
      }
    },
    //点击热门添加
    choseHotTag(name, index) {
      if (this.userSelcTag.length >= 10) {
        this.$message({
          message: "最多只能添加10个技能标签哦",
          type: "warning",
        });
      } else {
        let ski = this.userSelcTag.filter((item) => item === name);
        console.log(index, this.hotJnUserTag);
        delete this.hotJnUserTag[index];
        // this.hotJnUserTag.splice(index, 1);
        if (ski.length === 0) {
          addJnTag({ name: name })
            .then((result) => {
              console.log("添加热门标签", result);
              this.userUserYsShow();
              // this.userUserYsShow();
            })
            .catch(() => {});
        } else {
          this.$message({
            type: "warning",
            message: "已添加",
          });
        }
      }
    },
    //删除已选的标签
    delJnTagConfirm(index) {
      delJnTag({ id: index })
        .then((result) => {
          console.log("删除已选标签", result);
          if (result.code == 200) {
            this.$message({
              message: "删除成功！",
              type: "success",
            });
            this.affir.isOpenAffirm = false;
            this.userUserYsShow();
          }
        })
        .catch(() => {});
    },

    //检索公司名称
    expComQuerySearchAsync(queryString, cb) {
      console.log(queryString, cb);
      if (queryString) {
        expSearchCompany({ com_name: queryString, page: 1 })
          .then((result) => {
            console.log("获取公司名称", result);
            result.data.list.forEach((element) => {
              element.value = element.name;
            });
            cb(result.data.list);
          })
          .catch(() => {});
      }
    },
    //检索学校名称
    eduScQuerySearchAsync(queryString, cb) {
      cb([]);
      if (queryString) {
        eduScnameSearch({ kw: queryString })
          .then((result) => {
            console.log("获取学校名称", result);
            var list = [];
            result.data.forEach((element) => {
              list.push({ value: element });
            });
            if (list && list.length > 0) {
              cb(list);
            }
          })
          .catch(() => {});
      }
    },

    toEdtJobExp(item) {
      this.expEdtShow = true;
      console.log(item);
      this.jobExperienceObj.name = item.name;
      this.jobExperienceObj.com_id = item.com_id;
      this.jobExperienceObj.title = item.title;
      this.jobExperienceObj.sdate = item.sdate * 1000;
      this.jobExperienceObj.content = item.content;
      this.jobExperienceObj.eid = item.eid;
      this.jobExperienceObj.work_id = item.id;
      this.jobExperienceObj.edate = item.edate * 1000;
    },
    toEditEducation(item) {
      this.getUserClass(true);
      console.log(item);
      this.eduEdtShow = true;
      this.educationObj.id = item.id;
      this.educationObj.name = item.name;
      this.educationObj.education = item.education;
      this.educationObj.specialty = item.specialty;
      this.educationObj.sdate = item.sdate * 1000;
      this.educationObj.edate = item.edate * 1000;
      this.educationObj.content = item.content;
    },
    toEditPx(item) {
      console.log(item);
      this.pxEdtShow = true;
      this.pxObj.id = item.id;
      this.pxObj.name = item.name;
      this.pxObj.sdate = item.sdate * 1000;
      this.pxObj.edate = item.edate * 1000;
      this.pxObj.content = item.content;
    },
    toEditJnzs(item) {
      this.jnzsEdtShow = true;
      this.jnzsObj.id = item.id;
      this.jnzsObj.name = item.name;
      this.jnzsObj.longtime = item.longtime;
      this.jnzsObj.get_time = item.get_time * 1000;
      this.jnzsObj.pic = item.pic;
    },

    experComhandleSelect(e) {
      console.log(e);
      this.jobExperienceObj.com_id = e.uid;
    },
    eduComhandleSelect() {},
    hidEditView() {
      this.pxEdtShow = false;
      this.pxObj = {
        id: 0,
        name: "",
        sdate: "",
        edate: "",
        content: "",
      };
    },

    updateUserBaseInf() {
      var prop = "edu_name";
      delete this.baseObj[prop];
      this.baseObj.split;
      console.log(this.baseObj);
      if (this.baseObj.job_type == 2){
        this.baseObj.firstyear = 0;
      }else {
        this.baseObj.firstyear = this.baseObj.firstyear / 1000;
      }
      if (this.baseObj.show_name) {
        this.baseObj.show_name = 1;
      } else {
        this.baseObj.show_name = 0;
      }
      let tmpObj = Object.assign({}, this.baseObj);
      if (this.getAuthStatus > 0) {
        delete tmpObj.name;
        delete tmpObj.birthday;
        delete tmpObj.sex;
        delete tmpObj.domicile;
      }
      updateUserBaseInf(tmpObj)
        .then((result) => {
          console.log("更新信息", result);
          if (result.code === 200) {
            this.$message({
              message: "修改成功！",
              type: "success",
              center: true,
            });
            this.getResumInf();
            this.basInfEdtShow = false;
          }
        })
        .catch(() => {});
      this.baseObj.firstyear = this.baseObj.firstyear * 1000;
    },

    updateOther(obj) {
      console.log("updateOther: ", obj);
      updateUserBaseInf(obj)
        .then((result) => {
          console.log("更新信息", result);
          if (result.code == 200) {
            this.$message({
              message: "修改成功！",
              type: "success",
              center: true,
            });
            this.getResumInf();
            this.ysEdtShow = false;
          }
        })
        .catch(() => {});
    },
    //更新求职状态
    updateQzYxInf() {
      updateQzYxInf(this.jbYxObj)
        .then((result) => {
          console.log("更新求职状态", result);
          if (result.code == 200) {
            this.$message({
              message: "修改成功！",
              type: "success",
              center: true,
            });
            this.getResumInf();
            this.qzztInfEdtShow = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //添加/编辑工作经验
    jobExperienceFuc() {
      let jobObj = this.jobExperienceObj;
      let msg = "";
      if (!jobObj.name) {
        msg = "请填写公司名称";
      } else if (!jobObj.title) {
        msg = "请填写职位";
      } else if (!jobObj.sdate) {
        msg = "请填写工作时间";
      } else if (
        this.jobExperienceObj.edate !== 0 &&
        this.jobExperienceObj.sdate >= this.jobExperienceObj.edate
      ) {
        msg = "开始时间不能晚于结束时间";
      }
      if (msg !== "") {
        return this.$message.warning(msg);
      }
      if (String(this.jobExperienceObj.sdate).length == 13) {
        this.jobExperienceObj.sdate = this.jobExperienceObj.sdate / 1000;
      }
      if (String(this.jobExperienceObj.edate).length == 13) {
        this.jobExperienceObj.edate = this.jobExperienceObj.edate / 1000;
      }
      if (this.jobExperienceObj.work_id == 0) {
        addJobExperience(this.jobExperienceObj)
          .then((result) => {
            console.log("添加工作经验", result);
            this.$message({
              message: "添加成功！",
              type: "success",
              center: true,
            });
            this.getResumInf();
            this.expEdtShow = false;
          })
          .catch(() => {});
      } else {
        console.log(
          "工作经验",
          String(this.jobExperienceObj.sdate).length,
          this.jobExperienceObj
        );
        editJobExperience(this.jobExperienceObj)
          .then(() => {
            this.$message({
              message: "修改成功！",
              type: "success",
              center: true,
            });
            this.getResumInf();
            this.expEdtShow = false;
          })
          .catch(() => {});
      }
    },
    /**
     * 新增/编辑教育经历
     */
    saveEducationFuc() {
      //处理时间格式
      if (String(this.educationObj.sdate).length == 13) {
        this.educationObj.sdate = this.educationObj.sdate / 1000;
      }
      if (String(this.educationObj.edate).length == 13) {
        this.educationObj.edate = this.educationObj.edate / 1000;
      }
      let eduObj = this.educationObj;
      let msg = "";
      if (!eduObj.name) {
        msg = "请填写学校名称";
      } else if (!eduObj.education) {
        msg = "请填写学历";
      } else if (eduObj.education !== "80" && !eduObj.specialty) {
        msg = "请填专业";
      } else if (!eduObj.sdate || !eduObj.edate) {
        msg = "请填教育时间";
      } else if (eduObj.sdate >= eduObj.edate) {
        msg = "开始时间不能晚于结束时间";
      }
      if (msg !== "") {
        return this.$message.warning(msg);
      }

      if (this.educationObj.id == 0) {
        addUserEdu(this.educationObj)
          .then((result) => {
            console.log("添加教育经历", result);
            this.$message({
              message: "添加成功！",
              type: "success",
              center: true,
            });
            this.getResumInf();
            this.eduEdtShow = false;
          })
          .catch(() => {});
      } else {
        editUserEdu(this.educationObj)
          .then((result) => {
            console.log("编辑教育经历", result);
            this.$message({
              message: "修改成功！",
              type: "success",
              center: true,
            });
            this.getResumInf();
            this.eduEdtShow = false;
          })
          .catch(() => {});
        //
      }
    },
    /**
     * 添加/编辑培训经历
     */
    savePxFuc() {
      if (this.pxObj.sdate >= this.pxObj.edate) {
        this.$message.warning("开始时间不能晚于结束时间");
        return false;
      }
      if (String(this.pxObj.sdate).length == 13) {
        this.pxObj.sdate = this.pxObj.sdate / 1000;
      }
      if (String(this.pxObj.edate).length == 13) {
        this.pxObj.edate = this.pxObj.edate / 1000;
      }
      if (this.pxObj.id == 0) {
        addPxExp(this.pxObj)
          .then((result) => {
            console.log("培训经历", result);
            this.$message({
              message: "添加成功！",
              type: "success",
              center: true,
            });
            this.getResumInf();
            this.pxEdtShow = false;
          })
          .catch(() => {});
      } else {
        editPxExp(this.pxObj)
          .then((result) => {
            console.log("培训经历", result);
            this.$message({
              message: "添加成功！",
              type: "success",
              center: true,
            });
            this.getResumInf();
            this.pxEdtShow = false;
          })
          .catch(() => {});
      }
    },
    /**
     *添加/编辑技能证书
     */
    saveJnzsFuc() {
      this.jnzsObj.longtime = new Date(this.jnzsObj.get_time).getFullYear();
      if (String(this.jnzsObj.get_time).length == 13) {
        this.jnzsObj.get_time = this.jnzsObj.get_time / 1000;
      }
      if (this.jnzsObj.id == 0) {
        addJnzs(this.jnzsObj)
          .then((result) => {
            console.log("添加技能证书", this.jnzsObj, result);
            this.$message({
              message: "添加成功！",
              type: "success",
              center: true,
            });
            this.getResumInf();
            this.jnzsEdtShow = false;
          })
          .catch(() => {});
      } else {
        editJnzs(this.jnzsObj)
          .then((result) => {
            console.log("编辑", result);
            this.$message({
              message: "修改成功！",
              type: "success",
              center: true,
            });
            this.getResumInf();
            this.jnzsEdtShow = false;
          })
          .catch(() => {});
      }
    },
    delResumeOthere(type, id) {
      this.affir.affirmFrom = type;
      this.affir.params = id;
      this.affir.isOpenAffirm = true;
      let typeArr = {
        skill: "技能证书",
        training: "培训经历",
        edu: "教育经历",
        work: "工作经历",
        delTag: "标签",
      };
      this.affir.affirmMsge =
        '<div style="line-height: 30px">删除此项' +
        typeArr[type] +
        "将无法恢复，是否删除？</div>";
    },
    /**
     * 删除工作经历/教育经历/培训经历/技能证书  //标签
     */
    delResumeOthereConfirm(type, id) {
      console.log("delResumeOthereConfirm:", type);
      console.log(id);
      if (type === "delTag") {
        this.delJnTagConfirm(id);
      } else {
        delResumeOthere({
          type: type,
          id: id,
        }).then((result) => {
          console.log("删除", result);
          this.$message({
            message: "删除成功！",
            type: "success",
            center: true,
          });
          this.affir.isOpenAffirm = false;
          this.getResumInf();
        });
      }
    },
    affirmClose() {
      this.affir.isOpenAffirm = false;
    },
  },
};
</script>

<style>
.user-center-page {
  display: flex;
}
.mesume-userbasinf-view {
  display: flex;
  align-items: center;
  background-color: #fff;
  width: 760px;
  border-radius: 8px;
  padding: 30px 24px;
  position: relative;
}

.mesume-userbasinf-view .user-avatar-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.mesume-userbasinf-view .min-userinf-text {
  margin-left: 24px;
  width: 580px;
}
.mesume-userbasinf-view .min-userinf-text .name-sex-rz-view {
  display: flex;
  align-items: center;
}
.mesume-userbasinf-view .min-userinf-text .name-sex-rz-view .name {
  font-size: 24px;

  font-weight: 600;
  color: #222222;
  line-height: 28px;
}

.mesume-userbasinf-view .min-userinf-text .name-sex-rz-view .sex-img {
  width: 22px;
  height: 22px;
  position: absolute;
  left: 100px;
  top: 100px;
}
.mesume-userbasinf-view .min-userinf-text .name-sex-rz-view .rzst-img {
  width: 22px;
  height: 22px;
  margin-left: 18px;
}

.mesume-userbasinf-view .min-userinf-text .name-sex-rz-view .rest-text {
  font-size: 14px;

  font-weight: 400;
  color: #fe6002;
  line-height: 16px;
  margin-left: 4px;
}

.mesume-userbasinf-view .min-userinf-text .age-xl-ggjy-add-xj-view {
  font-size: 14px;
  font-weight: 400;
  color: #757575;
  line-height: 16px;
  margin-top: 12px;
}

.mesume-userbasinf-view .min-userinf-text .phone-email-view {
  display: flex;
  align-items: center;
  font-size: 14px;

  font-weight: 400;
  color: #757575;
  line-height: 16px;
  margin-top: 12px;
}

.mesume-userbasinf-view .min-userinf-text .phone-email-view .item {
  display: flex;
  align-items: center;
  margin-right: 16px;
}
.mesume-userbasinf-view .min-userinf-text .phone-email-view .item img {
  width: 16px;
  height: 16px;
  margin-right: 2px;
}
.mesume-userbasinf-view .ed-bbtn-view {
  display: flex;
  align-items: center;
  position: absolute;
  right: 24px;
  top: 30px;
}
.mesume-userbasinf-view .ed-bbtn-view img {
  width: 22px;
  height: 22px;
}

.mesume-userbasinf-view .ed-bbtn-view .ed-text {
  font-size: 14px;

  font-weight: 400;
  color: #fe6002;
  line-height: 16px;
  margin-left: 2px;
}
.other-resume-inf-view {
  background-color: #fff;
  width: 760px;
  border-radius: 8px;
  padding: 30px 24px;
  position: relative;
  margin-top: 16px;
}
.userhome-hover {
  background-color: #fffaf7;
  position: relative;
  padding: 0 12px;
}

.edi-other-resume-inf-view {
  background-color: #f5f6fa;
}

.other-resume-inf-view .com-title-view {
  display: flex;
  align-items: center;
  position: relative;
}

.other-resume-inf-view .com-title-view .left-xian {
  width: 4px;
  height: 20px;
  background-color: #fe6002;
  margin-right: 16px;
}

.other-resume-inf-view .com-title-view .title-text {
  font-size: 22px;

  font-weight: 600;
  color: #191919;
  line-height: 28px;
}

.other-resume-inf-view .com-title-view .isbt {
  padding: 3px 5px;
  background: #f34b37;
  border-radius: 2px 2px 2px 2px;
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  margin-left: 8px;
}
.other-resume-inf-view .com-title-view .right-bj-view {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.other-resume-inf-view .com-title-view .right-bj-view img {
  width: 22px;
  height: 22px;
  margin-right: 2px;
}

.other-resume-inf-view .com-title-view .right-bj-view span {
  font-size: 14px;

  font-weight: 400;
  color: #fe6002;
  line-height: 16px;
}
.zazt-view {
  display: flex;
  align-items: center;
  margin-top: 16px;
}

.zazt-view .resume-img1 {
  width: 20px;
  height: 20px;
}

.zazt-view .resume-text1 {
  font-size: 18px;

  font-weight: 400;
  color: #191919;
  line-height: 24px;
  margin-left: 5px;
}
.qzyx-text-view {
  display: flex;
  align-items: center;
  margin-left: 28px;
  margin-top: 7px;
}
.qzyx-text-view .yx-txt-item .xz-tt1 {
  font-size: 16px;

  font-weight: 400;
  color: #757575;
}
.qzyx-text-view .yx-txt-item div {
  margin-top: 8px;
}
.qzyx-text-view .yx-txt-item .xz-tt2 {
  font-size: 16px;

  font-weight: 400;
  color: #191919;
}

.nonetext-view {
  font-size: 16px;

  font-weight: 400;
  color: #939597;
  line-height: 19px;
  margin-top: 16px;
}

.havedesc-view {
  white-space: pre-wrap;
  font-size: 18px;

  font-weight: 400;
  color: #191919;
  line-height: 24px;
  margin-top: 16px;
}

.skills-tags {
  margin-top: 16px;
}

.skills-tags .tag {
  display: inline-block;
  background: #fff5f0;
  border-radius: 4px;
  padding: 6px 14px;
  font-size: 16px;

  font-weight: 400;
  color: #fe6002;
  line-height: 19px;
  margin-right: 12px;
  margin-bottom: 10px;
}
.jltitle-title-view {
  display: flex;
  align-items: center;
  margin-top: 32px;
  /* padding: 12px; */
  position: relative;
}

.jltitle-title-view .title-text {
  font-size: 20px;

  font-weight: 600;
  color: #191919;
  line-height: 28px;
}
.jltitle-title-view .gztime-text {
  font-size: 16px;

  font-weight: 600;
  color: #757575;
  line-height: 19px;
  margin-left: 32px;
}
.jltitle-title-view .right-edit-view {
  display: flex;
  font-size: 14px;

  font-weight: 400;
  color: #fe6002;
  line-height: 16px;
  position: absolute;
  right: 0;
}
.jltitle-title-view .right-edit-view .ed-it {
  display: flex;
  align-items: center;
  margin-left: 16px;
}

.jltitle-title-view .right-edit-view .ed-it img {
  width: 16px;
  height: 16px;
  margin-right: 2px;
}

.jl-title2 {
  font-size: 18px;

  font-weight: 600;
  color: #757575;
  line-height: 28px;
  margin-top: 12px;
}

.jl-title3 {
  font-size: 16px;

  font-weight: 600;
  color: #3b3b3b;
  line-height: 19px;
  margin-top: 12px;
}

.jl-desp-text {
  white-space: pre-wrap;
  font-size: 16px;

  font-weight: 400;
  color: #757575;
  line-height: 24px;
  margin-top: 12px;
}
.edmesume-right-view {
  margin-left: 24px;
}

.edmesume-right-view .right-top-btn-view .btn {
  width: 170px;
  height: 44px;
  font-size: 18px;

  font-weight: 600;
}
.edmesume-right-view .jlwzd-view {
  margin-top: 24px;
  position: relative;
  background-image: url("../assets/userinf_bg2.png");
  background-size: 359px 543px;
}
/*.edmesume-right-view .jlwzd-view .wzd-bg-img {
  width: 359px;
  height: 543px;
}*/
/*.edmesume-right-view .jlwzd-view .wzd-text-view {
  width: 359px;
  height: 543px;
  position: absolute;
  top: 0;
}*/
.edmesume-right-view .jlwzd-view .wzd-text-view .top-pro-prc-view {
  display: flex;
  align-items: flex-end;
  margin-top: 30px;
  margin-left: 17px;
  padding-top: 28px;
}
.edmesume-right-view .jlwzd-view .wzd-text-view .top-pro-prc-view .left-img {
  width: 80px;
  height: 80px;
}
.edmesume-right-view
  .jlwzd-view
  .wzd-text-view
  .top-pro-prc-view
  .pro-left-view {
  margin-left: 10px;
}
.edmesume-right-view
  .jlwzd-view
  .wzd-text-view
  .top-pro-prc-view
  .pro-left-view
  .text1 {
  font-size: 18px;

  font-weight: 600;
  color: #191919;
  line-height: 28px;
}

.edmesume-right-view
  .jlwzd-view
  .wzd-text-view
  .top-pro-prc-view
  .pro-left-view
  .wzd-el-progress {
  width: 133px;
  margin-top: 11px;
}

.edmesume-right-view .jlwzd-view .wzd-text-view .top-pro-prc-view .right-prce {
  font-size: 48px;

  font-weight: 600;
  color: #fe6002;
  /* line-height: 56px; */
  margin-left: 10px;
}
.edmesume-right-view
  .jlwzd-view
  .wzd-text-view
  .top-pro-prc-view
  .right-prce
  label {
  font-size: 25px;
}
.edmesume-right-view .jlwzd-view .jldwasx-title-view {
  margin-left: 24px;
}
.edmesume-right-view .jlwzd-view .jldwasx-title-view .title-view {
  display: flex;
  align-items: center;
  font-size: 20px;

  font-weight: 600;
  color: #222222;
  line-height: 28px;
}

.edmesume-right-view .jlwzd-view .jldwasx-title-view .title-view .banum {
  background-color: #ff3f3f;
  border-radius: 20px;
  padding: 1px 5px;
  font-size: 16px;

  font-weight: 600;
  color: #ffffff;
  line-height: 19px;
  margin-left: 8px;
}

.edmesume-right-view .jlwzd-view .jldwasx-title-view .ws-desp {
  font-size: 14px;

  font-weight: 400;
  color: #939597;
  line-height: 16px;
  margin-top: 8px;
}
.edmesume-right-view .jlwzd-view .dws-item-view {
  margin-top: 8px;
}
.edmesume-right-view .jlwzd-view .dws-item-view .item {
  margin-left: 24px;
  margin-top: 32px;
}
.edmesume-right-view .jlwzd-view .dws-item-view .item .tp-view {
}
.edmesume-right-view .jlwzd-view .dws-item-view .item .tp-view .dian {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: #fe6002;
  border-radius: 50%;
}
.edmesume-right-view .jlwzd-view .dws-item-view .item .tp-view .ws-text {
  font-size: 18px;

  font-weight: 600;
  color: #222222;
  line-height: 28px;
  margin-left: 8px;
}

.edmesume-right-view .jlwzd-view .dws-item-view .item .bt-view {
  display: flex;
  align-items: center;
  margin-top: 3px;
  position: relative;
}

.edmesume-right-view .jlwzd-view .dws-item-view .item .bt-view .left-text {
  font-size: 12px;

  font-weight: 400;
  color: #939597;
  line-height: 14px;
  margin-left: 16px;
}
.edmesume-right-view .jlwzd-view .dws-item-view .item .bt-view .right-view {
  position: absolute;
  right: 24px;
  font-size: 12px;

  font-weight: 400;
  color: #fe6002;
  line-height: 14px;
}

.edmesume-right-view .user-cent-qzbb-view {
  width: 311px;
  background-color: #ffffff;
  margin-top: 24px;
  border-radius: 8px;
  padding: 15px 24px;
}

.edmesume-right-view .user-cent-qzbb-view .qzbb-title-view {
  display: flex;
  align-items: center;
  font-size: 20px;

  font-weight: 600;
  color: #222222;
  line-height: 28px;
  position: relative;
}

.edmesume-right-view
  .user-cent-qzbb-view
  .qzbb-title-view
  .qzbb-title-right-text {
  font-size: 14px;

  font-weight: 600;
  color: #fe6002;
  line-height: 16px;
  position: absolute;
  right: 0;
  cursor: pointer;
}

.edmesume-right-view .user-cent-qzbb-view .qzbb-item-view {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
}
.edmesume-right-view .user-cent-qzbb-view .qzbb-item-view .item {
  text-align: center;
}
.edmesume-right-view .user-cent-qzbb-view .qzbb-item-view .item img {
  width: 50px;
  height: 50px;
}
.edmesume-right-view .user-cent-qzbb-view .qzbb-item-view .item div {
  font-size: 16px;

  font-weight: 400;
  color: #757575;
  line-height: 19px;
}
.edmesume-right-view .user-cent-qzbb-view .jlys-item-view {
  margin-top: 8px;
}
.edmesume-right-view .user-cent-qzbb-view .jlys-item-view .item {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 16px;
}
.edmesume-right-view .user-cent-qzbb-view .jlys-item-view .item .dian {
  font-size: 16px;

  font-weight: 600;
  color: #191919;
  line-height: 19px;
}
.edmesume-right-view .user-cent-qzbb-view .jlys-item-view .item .jxys-text1 {
  font-size: 16px;

  font-weight: 600;
  color: #191919;
  line-height: 19px;
}
.edmesume-right-view .user-cent-qzbb-view .jlys-item-view .item .right-text2 {
  position: absolute;
  right: 0;
  font-size: 12px;

  font-weight: 400;
  color: #b1b1b1;
  line-height: 14px;
}
.edi-baseinf-view {
  background-color: #f5f6fa;
  border-radius: 8px;
  width: 848px;
  border-radius: 8px;
  /* padding: 30px 24px; */
}

.edi-baseinf-view .topo-title-view {
  background-color: #fff;
  padding: 30px 24px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-size: 22px;

  font-weight: 600;
  color: #222222;
}

.edi-baseinf-view .edit-view {
  padding: 0 24px;
  padding-bottom: 30px;
}

.edi-baseinf-view .userava-up-view {
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.edi-baseinf-view .userava-up-view .avatar-uploader {
  width: 300px;
  height: 60px;
  position: absolute;
  z-index: 99;
}

.edi-baseinf-view .userava-up-view .avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.edi-baseinf-view .userava-up-view .avatr-up-text {
  margin-left: 24px;
}

.edi-baseinf-view .userava-up-view .avatr-up-text .tt1 {
  font-size: 20px;

  font-weight: 600;
  color: #fe6002;
  line-height: 28px;
}

.edi-baseinf-view .userava-up-view .avatr-up-text .tt2 {
  font-size: 14px;

  font-weight: 400;
  color: #757575;
  line-height: 16px;
  margin-top: 12px;
}

.qzzt-edi-sl-view {
  /* width: 50%; */
}
.edit-view .edi-lin-item,
.qzzt-edi-sl-view .edi-lin-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.edit-view .edi-lin-item .items,
.qzzt-edi-sl-view .edi-lin-item .items {
  width: 350px;
  margin-right: 78px;
  position: relative;
}
.el-autocomplete {
  width: 100%;
}
.edit-view .edi-lin-item .items .title,
.qzzt-edi-sl-view .edi-lin-item .items .title {
  font-size: 16px;

  font-weight: 400;
  color: #757575;
  line-height: 30px;
  margin-top: 24px;
}
.edit-view .edi-lin-item .sl-clik-view,
.qzzt-edi-sl-view .edi-lin-item .sl-clik-view {
  display: flex;
}
.edit-view .edi-lin-item .sl-clik-view .cl-change-item,
.qzzt-edi-sl-view .edi-lin-item .sl-clik-view .cl-change-item {
  width: 160px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #e7e8eb;
  font-size: 16px;

  font-weight: 400;
  color: #757575;
}
.edit-view .edi-lin-item .sl-clik-view .cl-change-item:first-child,
.qzzt-edi-sl-view .edi-lin-item .sl-clik-view .cl-change-item:first-child {
  margin-right: 30px;
}
.edit-view .edi-lin-item .sl-clik-view .sl-cl-change-item,
.qzzt-edi-sl-view .edi-lin-item .sl-clik-view .sl-cl-change-item {
  border: 1px solid #fe6002;
  background-color: #ffece1;
  color: #fe6002;
}

.qzzt-edi-sl-view .el-form-item {
  margin-bottom: 0;
}
.edit-view .edi-lin-item .items .el-input.el-input--suffix,
.qzzt-edi-sl-view .edi-lin-item .items .el-input.el-input--suffix {
  width: 350px;
  /* height: 46px; */
}
.edit-view .edi-lin-item .items .zd-ingp-view,
.qzzt-edi-sl-view .edi-lin-item .items .zd-ingp-view {
  width: 350px;
  height: 46px;
  /* border: 1px solid #000; */
  position: absolute;
  top: 30px;
}
.edit-view .edi-lin-item .items .sl-group-view,
.qzzt-edi-sl-view .edi-lin-item .items .sl-group-view {
  display: flex;
  align-items: center;
}
.edit-view .edi-lin-item .items .sl-group-view .el-input.el-input--suffix,
.qzzt-edi-sl-view
  .edi-lin-item
  .items
  .sl-group-view
  .el-input.el-input--suffix {
  width: 120px;
  /* height: 46px; */
}
.edit-view .edi-lin-item .items .sl-group-view .he-xian,
.qzzt-edi-sl-view .edi-lin-item .items .sl-group-view .he-xian {
  margin: 0 10px;
}

.yx-el-for-it {
  /* border: 1px solid #000; */
  margin-top: 60px;
}

.yx-el-for-it .btn {
  width: 170px;
  height: 44px;
  font-size: 18px;

  font-weight: 600;
}

.edi-other-nowr-view {
  background-color: #f5f6fa;
  border-radius: 8px;
  width: 808px;
  border-radius: 8px;
  margin-top: 16px;
}

.edi-other-nowr-view .topo-title-view {
  background-color: #fff;
  padding: 30px 24px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-size: 22px;

  font-weight: 600;
  color: #222222;
}
.edi-other-nowr-view .topo-title-view .com-title-view {
  display: flex;
  align-items: center;
  position: relative;
}

.edi-other-nowr-view .topo-title-view .com-title-view .right-bj-view {
  position: absolute;
  right: 0;
}
.edi-other-nowr-view .topo-title-view .com-title-view .right-bj-view img {
  width: 22px;
  height: 22px;
}

.edi-other-nowr-view .edit-view {
  padding: 0 24px;
  padding-bottom: 30px;
}

.edi-other-nowr-view .edit-view .tt2 {
  font-size: 20px;

  font-weight: 600;
  color: #191919;
  line-height: 28px;
  margin-top: 30px;
}
.edi-other-nowr-view .edit-view .sl-bq-btn {
  margin-top: 24px;
  /* display: flex;
  align-items: center; */
}

.edi-other-nowr-view .edit-view .sl-bq-btn .addbq-bt {
  width: 86px;
  height: 36px;
  border: 1px solid #fe6002;
  font-size: 18px;

  font-weight: 400;
  color: #fe6002;
  line-height: 36px;
  border-radius: 2px;
  text-align: center;
  display: inline-block;
  margin-bottom: 10px;
}
.edi-other-nowr-view .edit-view .sl-bq-btn .sel-jn-tag {
  display: flex;
  align-items: center;
  display: inline-block;
  font-size: 18px;

  font-weight: 400;
  color: #fffdfc;
  line-height: 24px;
  background-color: #ff8136;
  padding: 7px 8px;
  border-radius: 2px;
  margin-right: 16px;
  margin-bottom: 10px;
}
.edi-other-nowr-view .edit-view .sl-bq-btn .sel-jn-tag span {
  margin-right: 10px;
}

.edi-other-nowr-view .edit-view .tt3 {
  font-size: 18px;

  font-weight: 600;
  color: #191919;
  line-height: 28px;
  margin-top: 24px;
}
.edi-other-nowr-view .edit-view .hot-bq-view .hotbq-item {
  display: inline-block;
  background-color: #fff;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 16px;

  font-weight: 400;
  color: #757575;
  line-height: 19px;
  margin-top: 20px;
  margin-right: 20px;
}

.edi-other-nowr-view .edit-view .texterea-view {
  margin-top: 24px;
}
.edi-other-nowr-view .edit-view .texterea-view textarea {
  font-size: 18px;
}

.edi-other-nowr-view .edit-view .texterea-view .tips-text-view {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
}

.edi-other-nowr-view .edit-view .texterea-view .tips-text-view .tp-text {
  font-size: 16px;

  font-weight: 400;
  color: #ff6262;
  line-height: 19px;
}
.edi-other-nowr-view .edit-view .texterea-view .tips-text-view .text-num {
  font-size: 16px;

  font-weight: 400;
  color: #939597;
  line-height: 19px;
}
.user-center-page .work-erp-view .colse-img {
  cursor: pointer;
  width: 25px;
  height: 25px;
  position: absolute;
  left: 102px;
  z-index: 1;
}

.edi-lin-item .checkbox-input-view {
  position: absolute;
  top: 90px;
}
.edi-lin-item .checkbox-input-view .el-checkbox__inner {
  border-radius: 50%;
  width: 15px;
  height: 15px;
}
.edi-lin-item .checkbox-input-view span {
  margin-right: 5px;
  color: #757575;
  font-size: 13px;
}
.user-center-page .video-open .el-dialog {
  width: 100px;
  background-color: transparent;
}
.user-center-page .video-open .el-dialog .el-dialog__header {
  display: none;
}
</style>