<template>
  <div class="my-buy-shop">
    <div style="width: 100%">
      <div class="common-title-view">
        <span class="left-xian-view"></span>
        <span class="text">接店</span>
      </div>
    </div>
    <div style="position: relative">
      <img @click="newsBuyStore" src="../../assets/setPageIndex/transfer_add.png" class="transfer-add">
    </div>
    <el-card class="box-card">
      <div v-for="item in transferList" :key="item.id" class="transfer-item">
        <div class="transfer-item-row1 hide">
          <p class="transfer-item-title">{{ item.title }}</p>
          <p class="transfer-item-comm" :class="item.stateClass" style="float: right">{{ item.stateName }}</p>
        </div>
        <div class="transfer-item-row2 hide">
          <p class="transfer-item-time">更新时间：{{ item.time }}</p>
        </div>
        <div class="transfer-item-row3">
          <div class="transfer-item-del" @click="delBuyShow(item.id)">
            <i class="el-icon-delete"></i>
          </div>
          <div class="transfer-item-butt">
            <img v-show="item.s_status !== '1' && item.r_status === '1'"
                 src="../../assets/setPageIndex/transfer_jd_ok.png" @click="buyShowOk(item.id)"><!--接店成功-->
            <img v-show="item.s_status !== '1'" src="../../assets/setPageIndex/transfer_update.png" @click="toUpdateTakeShop(item.id)"><!--修改-->
            <img v-show="item.s_status === '0' && item.r_status === '1' && item.sj_status === '1'"
                 src="../../assets/setPageIndex/transfer_jd_soldout.png" @click="downOrUp(item.id,item.sj_status)"><!--下架-->
            <img v-show="item.s_status === '0' && item.r_status === '1' && item.sj_status === '0'"
                 src="../../assets/setPageIndex/transfer_jd_up.png" @click="downOrUp(item.id,item.sj_status)"><!--上架-->
          </div>

        </div>
      </div>
    </el-card>
    <AffirmDialog
        :isopenAffirm="isopen"
        :affirmDialogClose="affirmDialogClose"
        :affirmDialogOk="affirmDialogOk"
        :affirmTitle="affirmDialogArr.affirmTitle"
        :affirmMsge="affirmDialogArr.affirmMsge"
        :affirmFrom="affirmDialogArr.affirmFrom"
        :dataId="affirmDialogArr.dataId"
    >
    </AffirmDialog>
  </div>
</template>
<script>
import {delShop, seekQuickEdit} from "@/api/commen/transferShop";
import AffirmDialog from "@/components/affirmDialog.vue";

export default {
  components: {AffirmDialog},
  props: ["transferList"],
  data() {
    return {
      isopen: false,
      affirmDialogArr: {
        'dataId': '',
        'affirmTitle': '',
        'affirmMsge': '',
        'affirmFrom': '',
      }
    }
  },
  methods: {
    toUpdateTakeShop(id){
      this.$router.push('/publishtake?id='+id)
    },
    newsBuyStore(){
      this.$router.push('/publishtake')
    },
    //确认弹窗回调
    affirmDialogOk(fromType, dataId) {
      if (fromType === 'jd') {
        delShop(fromType, dataId).then(req => {
          if (req.code === 200) {
            this.$emit('updateBuyShowList');
            this.$message.success(req.msg);
          }
        });
      }else if (fromType === 'jd_ok'){
        seekQuickEdit(dataId, 'find_status', 1).then(() => {
          this.$message.success('确认接店成功')
          this.$emit('updateBuyShowList');
        })
      }
      this.isopen = false;
    },
    // 关闭弹窗
    affirmDialogClose() {
      this.isopen = false
    },
    // 上架或者下架
    downOrUp(id, state) {
      console.log(state)
      if (state === '1') {
        state = 0
      } else {
        state = 1
      }
      seekQuickEdit(id, 'status', state).then(req => {
        console.log(req)
        this.$message.success(req.msg)
        this.$emit('updateBuyShowList');
      })
    },
    //接店成功
    buyShowOk(id) {
      console.log(id)
      this.affirmDialogArr.dataId = id;
      this.affirmDialogArr.affirmTitle = '提醒';
      this.affirmDialogArr.affirmMsge = '一经确认接店成功就将不能再修改';
      this.affirmDialogArr.affirmFrom = 'jd_ok';
      this.isopen = true
    },
    // 删除
    delBuyShow(id) {
      this.affirmDialogArr.dataId = id;
      this.affirmDialogArr.affirmTitle = '提醒';
      this.affirmDialogArr.affirmMsge = '删除后将无法恢复，您确定要删除吗？';
      this.affirmDialogArr.affirmFrom = 'jd';
      this.isopen = true
    },
  }
}
</script>
<style scoped>

>>> .el-card__body {
  padding: 0;
}

.box-card {
  cursor: pointer;
  width: 1196px;
  margin-left: 24px;
  padding: 0;
}

.transfer-item {
  width: 1148px;
  height: 174px;
  padding: 34px 24px 0 24px;
  box-sizing: border-box;
  border-bottom: #d9d9d9 1px solid;
  margin: 0 auto;
}

.transfer-item-title {
  float: left;
  font-size: 22px;
  
  font-weight: 600;
  color: #191919;
  line-height: 28px;
}

.transfer-item-comm {
  font-size: 16px;
  
  font-weight: 600;
  line-height: 19px;
}

.transfer-item-authing {
  color: #F34B37;
}

.transfer-item-jd-gray {
  color: #CCCCCC;
}

.transfer-item-jd-underway {
  color: #FF8136;
}

.transfer-item-time {
  font-size: 16px;
  
  font-weight: 400;
  color: #939597;
  line-height: 19px;
}

.transfer-item-ok {
  float: right;
  height: 22px;
  font-size: 16px;
  
  font-weight: 600;
  color: #CCCCCC;
  line-height: 19px;
}

.transfer-item-del {
  float: left;
  width: 22px;
  color: #999999;
  cursor: pointer;
}

.transfer-item-butt {
  float: right;
}

.transfer-item-butt img {
  height: 32px;
  width: 72px;
  margin-left: 16px;
  cursor: pointer;
}

.transfer-item-row1 {
  height: 28px;
  margin-bottom: 16px;
}

.transfer-item-row2 {
  height: 22px;
  margin-bottom: 16px;
}

.transfer-item-row3 {
  line-height: 32px;
}

.transfer-item:last-child {
  border-bottom: 0;
}

.hide {
  overflow: hidden;
}
.common-title-view{
  margin-bottom: 16px;
}
.my-buy-shop .box-card:hover{
  background-color: #fff5f0;
  border: #fe6002 1px solid;
}
</style>