<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-05-10 09:09:58
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-06-06 15:42:54
 * @FilePath: /lcwpcvue/src/components/loginwWin.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="login-view">
    <div class="lefit-view">
      <div class="tt1">找工作 招人才</div>
      <div class="tt2">上猎宠网</div>
      <img class="erm" src="@/assets/dl_ewm.png" alt="" />
      <div class="ss-txt">扫一扫下载猎宠网APP</div>
    </div>
    <div class="right-view">
      <img class="cols-img" src="../assets/colse.png" alt="" />
      <div class="t3">{{ loginUserType }}</div>
      <div class="nvbar-view">
        <div class="nv1" @click="changLoginType(1)">
          <!-- <div >手机号登录/注册</div> -->
          <el-button
            size="mini"
            class="na-txt"
            :class="loginType == 1 ? 'nv2' : ''"
            type="text"
            >手机号登录/注册</el-button
          >
          <div class="bot-lin" v-show="loginType == 1"></div>
        </div>
        <div class="nv1" @click="changLoginType(2)">
          <!-- <div class="na-txt">账号登录</div> -->
          <el-button
            size="mini"
            class="na-txt"
            type="text"
            :class="loginType == 2 ? 'nv2' : ''"
            >账号登录</el-button
          >
          <div class="bot-lin" v-show="loginType == 2"></div>
        </div>
      </div>
      <el-form
        :model="ruleForm"
        status-icon
        :rules="rules"
        ref="ruleForm"
        class="demo-ruleForm"
      >
        <el-form-item
          v-if="loginType == 1"
          class="input-view"
          label=""
          prop="moblie"
        >
          <el-input
            placeholder="请输入手机号"
            type="text"
            v-model="ruleForm.moblie"
            clearable
          >
          </el-input>
        </el-form-item>
        <el-form-item
          v-if="loginType == 2"
          class="input-view"
          label=""
          prop="username"
        >
          <el-input
            placeholder="请输入账号"
            type="text"
            v-model="ruleForm.username"
            autocomplete="off"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="loginType == 1"
          class="input-view code-view"
          label=""
          prop=""
        >
          <!-- <el-input
            type="password"
            v-model="ruleForm.pass"
            autocomplete="off"
            style="border: none"
          ></el-input> -->
          <el-input
            v-model="ruleForm.code"
            type="text"
            placeholder="请输入验证码"
          >
            <i slot="suffix" class="el-icon-circle-close codeclear"></i>
          </el-input>
          <el-col class="code-x"></el-col>
          <el-button v-show="!smsFlag" type="text" @click="sendCode"
            >发送验证码</el-button
          >
          <el-button v-show="smsFlag" type="text" @click="sendCode" disabled>{{
            sendTime
          }}</el-button>
        </el-form-item>
        <el-form-item
          v-if="loginType == 2"
          class="input-view code-view"
          label=""
          prop="password"
        >
          <el-input
            type="password"
            v-model="passwordShow"
            autocomplete="off"
            placeholder="请输入密码"
            show-password
          ></el-input>
          <!-- <el-input type="text" placeholder="请输入验证码"></el-input>
          <el-col class="code-x"></el-col>
          <el-button type="text">发送验证码</el-button> -->
        </el-form-item>
        <el-form-item
          v-show="loginType == 2"
          class="remerme"
          label=""
          prop="pass"
        >
          <div class="cherem">
            <div class="chbox-view">
              <!-- <el-checkbox></el-checkbox>
              <span>记住我</span> -->
            </div>
            <el-button type="text" @click="frogetPassword"
              >忘记密码？</el-button
            >
          </div>
        </el-form-item>
        <el-form-item
          class="sub-btn"
          :style="loginType == 1 ? 'margin-top: 32px' : ''"
        >
          <el-button type="primary" @click="submitForm('ruleForm')">{{
            submitBtnText
          }}</el-button>
        </el-form-item>
      </el-form>

      <div class="wx-loginchan">
        <img class="wx-log" src="../assets/login_wximg.png" alt="" />
        <el-button type="text" @click="toWxLogin">微信登录/注册</el-button>
      </div>

      <div class="login-xy">
        <el-tooltip
          class="item"
          effect="dark"
          content="请勾选下方协议"
          placement="top-start"
          :manual="true"
          v-model="toolShow"
          :hide-after="800"
        >
          <el-checkbox v-model="allow" @change="checkboxChang"></el-checkbox>
        </el-tooltip>
        <div class="xy-view">
          同意
          <span @click="selectRegisterAgreement('register')"
            >《猎宠网注册协议》</span
          >和
          <span @click="selectRegisterAgreement('privacy')"
            >《猎宠网用户隐私协议》</span
          >
        </div>
      </div>
    </div>
    <comDialog
      :isopen="isOpenAgreement"
      dialogTetle="协议内容"
      @dialogFrameEvent="agreementEvent"
      myWidth="1280px"
    >
      <div v-html="agreementContent" class="vipServe-content"></div>
    </comDialog>
    <comDialog
        :isopen="isOpenRegisterAffirm"
        dialogTetle="手机号码已被绑定"
        @dialogFrameEvent="registerAffirm"
        myWidth="500px"
        left-but="换手机号登录"
        right-but="注册新账号"
        :is-show-butt= "true"
    >
      <div class="register-affirm-content">
        此手机号码已被<span style="color: #FE6002">{{registerAffirmData.alias}}</span>绑定，请核实输入信息的准确性，如无误您可以：<br>
        1、继续注册新账号<br>
        2、使用手机号<span style="color: #FE6002">{{registerAffirmData.moblie}}</span>获取验证码登录<span style="color: #FE6002">{{registerAffirmData.alias}}</span>账号
      </div>
    </comDialog>
  </div>
</template>
<script>
import {
  getUserProfile,
  sendPhoneCode,
  userLoginByCode,
  userLoginByPassw,
} from "@/api/commen/login";
import { Message } from "element-ui";
import comDialog from "@/components/comDialog.vue";
import { rsaEncrypt } from "@/utils/Rsa/rsa";
import {commonWay} from "@/api/commen/setApi";
export default {
  name: "loginWin",
  components: { comDialog },
  props: {
    pagesShowType: {
      type: Number,
      default: 1,
    },
    //   loginUserType: {
    //     type: String,
    //     default: "求职人员登录",
    //   },
    submitBtnText: {
      type: String,
      default: "登录/注册",
    },
    // loginType: {
    //   type: Number,
    //   default: 1,
    // },
  },
  data() {
    // var validatePass = (rule, value, callback) => {
    //   if (value === "") {
    //     callback(new Error("请输入验证码"));
    //   } else {
    //     if (this.ruleForm.checkPass !== "") {
    //       this.$refs.ruleForm.validateField("checkPass");
    //     }
    //     callback();
    //   }
    // };
    return {
      isOpenAgreement: false,
      isOpenRegisterAffirm: false,
      agreementContent: "",
      registerAffirmData:{
        verify:"",
        uid:"",
        alias:"",
        moblie:"",
        name:"",
      },
      ruleForm: {
        phone: "",
        pass: "",
        code: "",
        appletsid: "0",
        unionid: "",
      },
      rules: {
        username: [{ required: false, message: "手机号不能为空", trigger: "" }],
        moblie: [
          {
            required: true,
            message: "手机号不能为空",
            trigger: "",
          },
        ],
      },
      allow: true,
      smsFlag: false,
      sendTime: "重新获取",
      snsMsgWait: 60,
      toolShow: false,
      loginType: 1,
      passwordShow: "",
    };
  },
  created() {
    this.$store.watch(
      (state) => {
        if (state.user.usertype == 1) {
          this.loginUserType = "求职人员登录";
        } else {
          this.loginUserType = "招聘人员登录";
        }
        this.ruleForm = {
          moblie: "",
          code: "",
          usertype: localStorage.usertype,
          login_ip: this.$store.state.user.userip,
          appletsid: "0",
        };
        // this.rules = {
        //   moblie: [
        //     {
        //       required: true,
        //       message: "手机号不能为空",
        //       trigger: "",
        //     },
        //   ],
        //   code: [
        //     {
        //       required: true,
        //       message: "验证码",
        //       trigger: "",
        //     },
        //   ],
        // };
      },
      () => {}
    );
  },
  methods: {
    registerAffirm(show){
      if (show === 'close'){
        this.isOpenRegisterAffirm = false;
        this.ruleForm.code = '';
        this.ruleForm.moblie = '';
      }else {
        console.log(show)
        //验证码注册可以直接注册
        if (this.loginType == 1){
          commonWay(this.registerAffirmData.verify,this.registerAffirmData.uid).then(e=>{
            console.log(e)
            this.submitForm('ruleForm');
          });
        }else {
          this.$router.push('/registbyphone')
        }
      }
    },
    agreementEvent() {
      this.isOpenAgreement = false;
    },
    selectRegisterAgreement(from) {
      if (from === "register") {
        getUserProfile("/profile").then((res) => {
          this.agreementContent = res.data;
        });
      } else if (from === "privacy") {
        getUserProfile("/privacy").then((res) => {
          this.agreementContent = res.data;
        });
      } else {
        return false;
      }
      this.isOpenAgreement = true;
    },
    changLoginType(type) {
      this.loginType = type;
      if (type == 1) {
        this.ruleForm = {
          moblie: "",
          code: "",
          appletsid: "0",
          usertype: localStorage.usertype,
          login_ip: this.$store.state.user.userip,
        };

        // this.rules = {
        //   moblie: [
        //     {
        //       required: true,
        //       message: "手机号不能为空",
        //       trigger: "",
        //     },
        //   ],
        //   code: [
        //     {
        //       required: true,
        //       message: "验证码",
        //       trigger: "",
        //     },
        //   ],
        // };
      } else {
        this.ruleForm = {
          username: "",
          password: "",
          usertype: localStorage.usertype,
          login_ip: this.$store.state.user.userip,
          appletsid: "0",
          unionid: "0",
          // push_clientid: uni.getStorageSync("registrationID"),
          // push_platform: uni.getSystemInfoSync().platform,
          // partner: uni.getStorageSync("ispartner"),
        };

        // this.rules = {
        //   username: [
        //     { required: true, message: "账号不能为空", trigger: "blur" },
        //   ],
        //   password: [{ required: true, message: "请输入密码", trigger: "" }],
        // };
      }
    },
    validatePhoneNumber() {
      const reg = /^1[3456789]\d{9}$/;
      if (!reg.test(this.ruleForm.moblie)) {
        // 手机号码不符合规则
        return false;
      }
      // 手机号码符合规则
      return true;
    },
    sendCode() {
      if (!this.allow) {
        Message({
          message: "请阅读并勾选下方协议",
          type: "error",
          duration: 3 * 1000,
          showClose: true,
          offset: 100,
        });
        this.toolShow = true;
        return;
      }
      if (this.ruleForm.moblie == "") {
        Message({
          message: "请输入手机号",
          type: "error",
          duration: 3 * 1000,
          showClose: true,
          offset: 100,
        });
        return;
      }
      if (this.ruleForm.moblie.length != 11) {
        Message({
          message: "请输入正确的手机号",
          type: "error",
          duration: 3 * 1000,
          showClose: true,
          offset: 100,
        });
        return;
      }
      if (!this.validatePhoneNumber()) {
        Message({
          message: "请输入正确的手机号",
          type: "error",
          duration: 3 * 1000,
          showClose: true,
          offset: 100,
        });
        return;
      }
      this.countdown();
      // usertype: this.usertype,
      // 			event: 'login',
      // 			moblie: this.tel
      sendPhoneCode({
        usertype: this.ruleForm.usertype,
        event: "login",
        moblie: this.ruleForm.moblie,
      })
        .then((result) => {
          console.log("发送验证码", result);
          if (result.code == 200) {
            Message({
              message: "发送成功",
              type: "success",
              duration: 3 * 1000,
              showClose: true,
              offset: 100,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    checkboxChang() {
      this.toolShow = false;
    },

    submitForm(formName) {
      if (!this.allow) {
        this.toolShow = true;
        return;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // alert("submit!");
          console.log(this.ruleForm);
          if (this.loginType == 1) {
            // console.log(1);
            userLoginByCode(this.ruleForm)
              .then((result) => {
                console.log("验证码登录", result);
                if (result.code === 15){
                  console.log(88)
                  this.isOpenRegisterAffirm = true;
                  this.registerAffirmData.uid = result.data.uid;
                  this.registerAffirmData.verify = result.data.verify;
                  this.registerAffirmData.alias = result.data.alias;
                  this.registerAffirmData.moblie = result.data.moblie;
                  this.registerAffirmData.name = result.data.name;
                }else {
                  this.$emit("changeShowType", result);
                }

              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            // this.ruleForm.password = rsaEncrypt(this.ruleForm.password);
            this.ruleForm.password = rsaEncrypt(this.passwordShow);

            userLoginByPassw(this.ruleForm)
              .then((result) => {
                console.log("账号密码登录", result);
                if (result.code === 15){
                  this.isOpenRegisterAffirm = true;
                  this.registerAffirmData.uid = result.data.uid;
                  this.registerAffirmData.verify = result.data.verify;
                  this.registerAffirmData.alias = result.data.alias;
                  this.registerAffirmData.moblie = result.data.moblie;
                  this.registerAffirmData.name = result.data.name;
                }else {
                  this.$emit("changeShowType", result);
                }
              })
              .catch((err) => {
                console.log("cuocuocuco", err);
              });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    toWxLogin() {
      // this.$emit("towxlogin");
      this.$router.push("/wxsclogin");
    },
    frogetPassword() {
      this.$router.push("/forgetpass");
    },

    countdown() {
      // 60秒后重新获取验证码
      this.smsFlag = true;
      this.sendTime = this.snsMsgWait + "s后重发";
      this.snsMsgWait = this.snsMsgWait - 1;

      var inter = setInterval(
        function () {
          this.smsFlag = true;
          this.bordercolor = "no";
          this.sendTime = this.snsMsgWait + "s后重发";
          this.snsMsgWait = this.snsMsgWait - 1;
          if (this.snsMsgWait < 0) {
            clearInterval(inter);
            this.bordercolor = "basics";
            this.sendColor = "#02c598";
            this.sendTime = "获取验证码";
            this.snsMsgWait = 60;
            this.smsFlag = false;
          }
        }.bind(this),
        1000
      );
    },
  },
};
</script>

<style scoped>
.login-view {
  width: 1000px;
  height: 630px;
  background: #ffffff;
  border-radius: 20px;
  display: flex;
  align-items: center;
  padding: 38px 0;
}

.lefit-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 449px;
  height: 540px;
  margin: 83px 0;
  border-right: 2px solid rgba(245, 96, 2, 0.1);
}
.lefit-view .tt1 {
  font-size: 40px;

  font-weight: 400;
  color: #fe6002;
  line-height: 47px;
}
.lefit-view .tt2 {
  font-size: 65px;

  font-weight: bold;
  color: #fe6002;
  line-height: 76px;
  letter-spacing: 2px;
}
.lefit-view .erm {
  width: 160px;
  height: 160px;
  margin-top: 80px;
}

.lefit-view .ss-txt {
  font-size: 22px;

  font-weight: 400;
  color: #222222;
  line-height: 26px;
  margin-top: 50px;
}

.login-view .right-view {
  width: 548px;
  height: 100%;
  /* text-align: center; */
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.login-view .right-view .cols-img {
  width: 18px;
  height: 18px;
  /* float: right; */
  position: absolute;
  /* top: 1.32rem; */
  right: 40px;
}
.login-view .right-view .t3 {
  font-size: 30px;

  font-weight: 600;
  color: #222222;
  line-height: 35px;
  letter-spacing: 2px;
  margin-top: 66px;
}

.login-view .right-view .nvbar-view {
  display: flex;
  align-items: center;
  width: 75%;
  justify-content: space-around;
  font-size: 18px;

  font-weight: 400;
  color: #757575;
  margin-top: 40px;
  /* margin-left: 1rem; */
}
.login-view .right-view .nvbar-view .nv1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-view .right-view .nvbar-view .bot-lin {
  width: 77px;
  height: 3px;
  background-color: #fe6002;
  margin-top: 8px;
}

.login-view .right-view .nvbar-view .el-button--text {
  color: #757575;
  height: 28px;
  font-size: 18px;

  font-weight: 400;
}

.login-view .right-view .nvbar-view .nv2 {
  font-size: 22px;

  font-weight: 600;
  color: #222222;
}

.demo-ruleForm {
  /* width: 16.73rem;*/
  margin-top: 34px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
::v-deep .demo-ruleForm .el-input__inner {
  height: 50px;
  width: 368px;
  /* margin-top: 1.36rem; */
  line-height: 50px;
}

.demo-ruleForm .el-button {
  width: 368px;
  height: 50px;
  font-size: 18px;

  font-weight: 500;
  color: #ffffff;
}

.sub-btn .el-button {
  margin-top: 30px;
}

.demo-ruleForm .code-view {
  display: flex;
  position: relative;
  align-items: center;
  /* height: 50px; */
}

.el-input__suffix {
  display: flex;
  align-items: center;
  height: 50px;
}

.demo-ruleForm .code-view .code-x {
  width: 1px;
  height: 15px;
  background-color: #c1c1c1;
  position: absolute;
  /* left: 10.95rem; */
  right: 111px;
  top: 18px;
}
.demo-ruleForm .code-view .el-button {
  width: 80px;
  color: #fe6002;
  position: absolute;
  /* left: 5.95rem; */
  right: 15px;
  font-size: 16px;

  font-weight: 400;
}

.demo-ruleForm .code-view .codeclear {
  margin-right: 129px;
}
.demo-ruleForm .el-form-item--feedback .el-input__validateIcon {
  display: none;
}

/* .demo-ruleForm .el-input__validateIcon {
  display: none;
} */

/* .demo-ruleForm .code-view .el-input__validateIcon {
  display: none;
} */

.demo-ruleForm .el-form-item {
  margin-bottom: 0;
}

.demo-ruleForm .input-view {
  margin-top: 26px;
}

.demo-ruleForm .remerme {
  width: 368px;
  /* width: 80px; */
}

.demo-ruleForm .remerme .cherem {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.demo-ruleForm .remerme .chbox-view {
  /* width: 12px; */
  display: inline;
}

.demo-ruleForm .remerme .chbox-view label {
  margin-left: 4px;
  font-size: 14px;

  font-weight: 400;
  color: #cccccc;
}

.demo-ruleForm .remerme .el-button {
  color: #fe6002;
  /* text-align: right; */
  width: 74px;
  display: inline;
  font-size: 14px;
}
/* el-button--primary */
.wx-loginchan {
  display: flex;
  align-items: center;
  margin-top: 35px;
}
.wx-loginchan .wx-log {
  width: 25px;
  height: 25px;
  margin-right: 12px;
}

.wx-loginchan .el-button {
  font-size: 12px;

  font-weight: 500;
  color: #b1b1b1;
}

.login-xy {
  display: flex;
  align-items: center;
  font-size: 12px;

  font-weight: 400;
  color: #939597;
  margin-top: 30px;
}
.login-xy .el-checkbox__input {
  margin-right: 8px;
}

.login-xy .xy-view span {
  color: #3291e1;
  cursor: pointer;
}
.login-view .vipServe-content {
  border-top: 2px solid rgba(230, 230, 230, 0.5);
  padding: 50px 50px 80px;
}
.login-view .register-affirm-content{
  font-size: 18px;
  padding: 20px 20px 0 20px;
  line-height: 28px;

}
</style>
