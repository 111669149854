/*
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-12-20 16:54:42
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2023-12-20 17:13:43
 * @FilePath: /lcwpcvue/src/api/compe/myComboApi.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'
/**
* 获取我的会员信息
* @url Business/myvip
* @param {} params 
* @return 
*/
export function getMyVipCombo() {
  return request({
    url: 'Business/myvip',
    method: 'get'
  })
}

/**
* 获取增值服务
* @url CompanyStatis/servicenew
* @param {} params 
* @return 
*/
export function getServiceList() {
  return request({
    url: 'CompanyStatis/servicenew',
    method: 'get'
  })
}

/**
 * 查询是否有子账号权限用于是否显示菜单中的‘子账号是否显示’
 * @returns {AxiosPromise}
 */
export function isHaveChildAuth() {
  return request({
    url: 'v2/CompanyAccount/isHaveChild',
    method: 'get'
  })
}