<template>
  <div class="cardindex2-page" @click="todetails(datainfo.id, datainfo.part)">
    <div class="fline1">
      <span>{{ datainfo.name }}</span>
      <div class="flineview"></div>
    </div>
    <div class="fline2">
      <div class="ftag">
        <span v-if="datainfo.exp_name">{{ datainfo.exp_name }}</span>
        <span v-if="datainfo.edu_name">{{ datainfo.edu_name }}</span>
      </div>
    </div>
    <div class="cop">
      <div class="copc">
        <img :src="datainfo.logo" />
        <span style="color: #222222; margin-left: 16px">{{
          datainfo.com_name
        }}</span>
      </div>
      <div class="fposi">
        <span v-if="datainfo.province">{{ datainfo.province }}</span>
        <span v-if="datainfo.city">-{{ datainfo.city }}</span>
        <span v-if="datainfo.three_city">-{{ datainfo.three_city }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "cardindex2",
  props: {
    datainfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    todetails(id, parttime) {
      // parttime 判断有则为兼职
      if (!!parttime && parttime != 0) {
        // uni.navigateTo({
        // 	url: `/pages/index/jobinfo?id=${id}&parttype=1`
        // })
        this.$router.push("/jobmanage");
        this.$router.push(`/parttimejobdetail?id=${id}`);
      } else {
        this.$router.push(`/jobinfopage?id=${id}`);
        // uni.navigateTo({
        // 	url: `/pages/index/jobinfo?id=${id}`
        // })
      }
      // let url = e.currentTarget.dataset.url;
      // uni.navigateTo({
      // 	url: url,
      // })
    },
  },
};
</script>

<style>
.cardindex2-page {
  width: 481px;
  margin-top: 32px;
  padding: 32px;
  background: linear-gradient(to bottom, #fff5f0, #fff);
  box-shadow: 0px 10px 20px -4px rgba(175, 180, 197, 0.16);
  border-radius: 8px;
  border: 1px solid #ff8136;
}
.fline1 {
  height: 52px;
  line-height: 52px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fline1 span:nth-child(1) {
  font-size: 20px;
  
  font-weight: 600;
  color: #222222;
}

.fline1 .flineview {
  font-size: 32px;
  font-weight: bold;
  color: #fe6002;
}

.fline2 {
  /* height: 100px; */
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ftag {
  white-space: nowrap;
  height: 38px;
  line-height: 38px;
  font-size: 18px;
  
  font-weight: 400;
  color: #757575;
}

.ftag span {
  display: inline-block;
  background: #f5f6fa;
  border-radius: 4px;
  padding: 0 12px;
  margin-right: 32px;
}

.cop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 28px;
  line-height: 48px;
  margin: 30px 0;
  font-size: 18px;
  
  font-weight: 400;
}

.cop img {
  width: 28px;
  height: 28px;
  border: 1px solid #e7e8eb;
  border-radius: 4px;
}

.copc {
  display: flex;
  align-items: center;
}

.fposi {
  max-width: 280px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 18px;
  
  font-weight: 400;
  color: #939597;
}
</style>
