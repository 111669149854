<template>
  <div class="h5-job-detail-page">
    <div class="h5-back-view" @click="backFun">
      <i class="el-icon-arrow-left"></i>
      <span>招聘：{{ jobInfo.name }}</span>
    </div>
    <div style="margin-top: 50px"></div>
    <!-- <swiper easing-function='linear' :current-item-id='jobid' class="page-swper" :indicator-dots="false"
			:style="'min-height:'+windowHeigt+'px;'" @change="swperchange">
			<block v-for="(items,ids) in jobids" :key='ids'>
				<swiper-item class="swp-item" :item-id='items'> -->
    <div class="htitle containers">
      <div class="htitlename">{{ jobInfo.name }}</div>
      <div class="flineview">
        <span v-show="jobInfo.minsalary != 0 && jobInfo.maxsalary != 0"
          >{{ jobInfo.minsalary / 1000 }}-{{ jobInfo.maxsalary / 1000 }}K</span
        >
        <span v-show="jobInfo.minsalary == 0 && jobInfo.maxsalary == 0"
          >面议</span
        >
      </div>
    </div>

    <!-- 	<div class="time containers" v-if="addrLth < 10">
			<u-icon name="map" size="30" color="#222"></u-icon>
			<span
				style="margin-left: 8px; max-width: 400px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">{{ jobInfo.province_name }}</span>
			
			<u-icon style="margin-left: 36px;" name="clock" size="30" color="#222" v-if="jobInfo.exp_name">
			</u-icon>
			<span style="margin-left: 8px;">{{ jobInfo.exp_name }}</span>
			<image style="width: 32px;margin-left: 36px;" src="/pagesA/static/img/edu.png" mode="widthFix"
				v-if="jobInfo.edu_name"></image>
			<span style="margin-left: 8px;">{{ jobInfo.edu_name }}</span>
		</div> -->
    <div class="time2 containers">
      <div>
        <!-- <u-icon name="map" size="30" color="#222"></u-icon> -->
        <i class="el-icon-location-outline"></i>
        <span
          style="
            margin-left: 8px;
            max-width: 660px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          "
          >{{ jobInfo.province_name }}
        </span>
      </div>
      <div style="display: flex; align-items: center">
        <i class="el-icon-time"></i>
        <span class="etxt">{{ jobInfo.exp_name }}</span>
        <!-- <image
          style="width: 32px; margin-left: 36px"
          src="/static/images/edu.png"
          mode="widthFix"
          v-if="jobInfo.edu_name"
        ></image> -->
        <i style="margin-left: 36px" class="el-icon-collection"></i>
        <span class="etxt">{{ jobInfo.edu_name }}</span>
        <span class="up-time">{{ jobInfo.time }} 更新</span>
      </div>
    </div>

    <div class="hr-inf" @click="toCompayDetail">
      <img class="head-img" :src="jobInfo.logo" />
      <div class="text-view">
        <div class="top-txt-view">
          <div class="ninkname">{{ linkInf.name }}</div>
          <div class="on-lin" v-if="linkInf.online != ''">
            <div class="dian"></div>
            <span>{{ linkInf.online }}</span>
          </div>
        </div>
        <div class="job-com">
          {{ linkInf.job }} <label v-if="linkInf.job != ''">·</label
          >{{ jobInfo.com_name }}
        </div>
      </div>
      <i class="el-icon-arrow-right right-img"></i>
    </div>

    <div class="jianzhi_time" v-if="parttimejob">
      <div class="tit">兼职时间</div>
      <div class="time_border">
        <div class="b_sw b_th">
          <span></span>
          <div class="text">周一</div>
          <div class="text">周二</div>
          <div class="text">周三</div>
          <div class="text">周四</div>
          <div class="text">周五</div>
          <div class="text">周六</div>
          <div class="text">周日</div>
        </div>
        <div class="b_sw">
          <span>上午</span>
          <div class="text" v-for="(item, index) in morning" :key="index">
            <!-- <u-icon
              name="checkbox-mark"
              size="32"
              color="#FE6002"
              v-if="item == 1"
            ></u-icon> -->
          </div>
        </div>
        <div class="b_sw">
          <span>下午</span>
          <div class="text" v-for="(item, index) in noon" :key="index">
            <!-- <u-icon
              name="checkbox-mark"
              size="32"
              color="#FE6002"
              v-if="item == 1"
            ></u-icon> -->
          </div>
        </div>
        <div class="b_sw">
          <span>晚上</span>
          <div class="text" v-for="(item, index) in afternoon" :key="index">
            <!-- <u-icon
              name="checkbox-mark"
              size="32"
              color="#FE6002"
              v-if="item == 1"
            ></u-icon> -->
          </div>
        </div>
      </div>
    </div>

    <div class="containers">
      <div class="alltitle mt-32" v-if="jobInfo.description">职位描述</div>
      <!-- <div class="welfare">
				<div class="wcontent" v-for="(item, index) in jobInfo.welfare" :key="index" v-if="item != ''">
					{{ item }}
				</div>
			</div> -->
      <div class="time">
        <div
          v-if="jobInfo.work_time != ''"
          style="
            display: flex;
            align-items: center;
            margin-top: 4px;
            margin-right: 36px;
          "
        >
          <i class="el-icon-time"></i>
          <span style="margin-left: 8px">{{ jobInfo.work_time }}</span>
        </div>
        <div
          v-if="jobInfo.rest_day != ''"
          style="display: flex; align-items: center; margin-top: 4px"
        >
          <i class="el-icon-date"></i>
          <!-- <u-icon name="calendar" size="32" color="222222"></u-icon> -->
          <span>{{ jobInfo.work_shift_name }}</span>
          <span>·</span>
          <span>{{ jobInfo.rest_day_name }}</span>
          <span>·</span>
          <span>{{ jobInfo.overtime_state_name }}</span>
        </div>
      </div>

      <div class="description" v-if="jobInfo.description">
        <span v-if="more" style="white-space: pre-wrap">{{ more }}...</span>
        <span style="color: #ff8136" v-if="more" @click="showdescription"
          >查看全部</span
        >
        <span style="white-space: pre-wrap" v-if="!more">{{
          jobInfo.description
        }}</span>
      </div>
    </div>
    <div class="containers" v-if="welfareLength > 0">
      <div class="alltitle mt-32" v-if="jobInfo.description">职位福利</div>
      <div class="welfare">
        <!-- <div
          class="wcontent"
          v-for="(item, index) in jobInfo.welfare"
          :key="index"
          v-if="item != ''"
        >
          {{ item }}
        </div> -->
      </div>
    </div>

    <!-- <div class="containers" v-if="datas.job_show.length > 0">
      <div class="alltitle mt-30">公司环境</div>
      <div class="chgtype">
        <div
          class="nochg"
          :class="type == 1 ? 'choose' : 'nochoose'"
          v-if="videolist.length > 0"
        >
          视频.{{ videolist.length }}
        </div>
        <div
          class="nochg"
          :class="type == 2 ? 'choose' : 'nochoose'"
          v-if="imglist.length > 0"
        >
          照片.{{ imglist.length }}
        </div>
      </div>
      <div class="environment">
        <div v-if="type == 1">
          <div v-for="(item, index) in videolist" :key="index">
            <video :="item.picurl" controls></video>
          </div>
        </div>
        <div v-show="type == 2" v-for="(item, index) in imglist" :key="index">
          <el-image
            style="width: 100px; height: 100px"
            :src="item.picurl"
            :preview-src-list="prImglist"
          >
          </el-image>
        </div>
      </div>
    </div> -->

    <div class="containers">
      <div class="alltitle bortop">公司信息</div>
      <div class="header" @click="toCompayDetail">
        <img class="logo" :src="jobInfo.logo" mode="aspectFill" />
        <div class="hname">
          <div class="hline1">
            <span class="hcomname">{{ jobInfo.com_name }}</span>
          </div>
          <div class="hline2">
            <span>{{ jobInfo.hy_name }}</span>
            <span>{{ jobInfo.mun_name }}</span>
            <span v-if="jobInfo.sdate">{{ jobInfo.sdate }}</span>
            <span v-if="jobInfo.area">{{ jobInfo.area }}㎡</span>
          </div>
        </div>
        <!-- <div style="width: 30px; display: flex; align-items: center">
          <i style="display: inline-block" class="el-icon-arrow-right"></i>
        </div> -->
      </div>
      <div
        class="mapcontainer"
        v-if="jobInfo.y != 0 && jobInfo.y != '' && jobInfo.y"
      >
        <div class="mapmask">
          <zczmap
            :lat="jobInfo.y"
            :lng="jobInfo.x"
            :idname="'mapjobd'"
            :mapwidth="'335'"
            :mapheight="'200'"
          ></zczmap>
        </div>
      </div>
    </div>

    <div class="tip">
      <div class="containers">
        <div class="tips">
          <!-- <image src="/pagesA/static/img/safe.png" mode="widthFix"></image> -->
          <span>猎宠网安全提示：</span>
        </div>
        <div class="tcontent">
          求职中如遇到招聘方存在虚假信息，收取费用，扣押证件，或其他违法情形，请立即
          <span @click="toMiniProFun">举报</span>
          !
        </div>
      </div>
    </div>

    <div class="forjob" v-if="usertype == 1 && tjList.length > 0">
      <div class="ftitle containers view-flex">
        推荐职位
        <div class="right-view">
          <span style="font-weight: 400">查看更多</span>
          <!-- <u-icon name="arrow-right" size="24" color="#757575"></u-icon> -->
        </div>
      </div>
      <div class="cardd">
        <!-- <user-card2 :jobsList="tjList"> </user-card2> -->
      </div>
    </div>
    <div style="padding-top: 80px"></div>
    <openmini style="position: fixed; left: -20px; bottom: 0"></openmini>
  </div>
</template>

<script>
// const api = require("../../utils/api.js");
// import Map from '../../utils/openMap.js';
// import Map from "../../js_sdk/ms-openMap/openMap.js";
// import qzxmap from "../../components/qzxmap/qzxmap.vue";
import {
  getJobDetails,
  // getRecommendJobList,
  // getJobListByComId,
  // collectionCompany,
  // deliverResume,
  // checkUserIsCanChat,
} from "@/api/indiviuser/jobInfoPage";
import openmini from "@/components/h5Components/h5TominPro.vue";
import {cresteHttpUrl} from "@/api/h5/h5Redirect";

export default {
  components: {
    // qzxmap,
    openmini,
  },
  data() {
    return {
      token: "",
      data: {}, // 预览信息
      backtext: "", // 顶部返回文字展示
      maskState: 0, //分享面板显示状态
      jobid: "156004", // 当前职位id
      isPartJob: 0,
      datas: {
        job_show: [],
        is_apply: 0,
      },
      jobInfo: {
        description: "",
        name: "",
        x: 0,
        y: 0,
      },
      videolist: [],
      imglist: [],
      prImglist: [],

      more: "",
      type: 1, // 1 视频 2 图片 切换的参数
      tjList: [], // 推荐列表
      mark: [],
      // collection: false,
      // is_coll: 0, // 收藏
      // reportShow: false,
      morning: [{}, {}, {}, {}, {}, {}, {}],
      noon: [{}, {}, {}, {}, {}, {}, {}],
      afternoon: [{}, {}, {}, {}, {}, {}, {}],
      m: [
        {
          id: "0101",
          checked: false,
        },
        {
          id: "0201",
          checked: false,
        },
        {
          id: "0301",
          checked: false,
        },
        {
          id: "0401",
          checked: false,
        },
        {
          id: "0501",
          checked: false,
        },
        {
          id: "0601",
          checked: false,
        },
        {
          id: "0701",
          checked: false,
        },
      ],
      n: [
        {
          id: "0102",
          checked: false,
        },
        {
          id: "0202",
          checked: false,
        },
        {
          id: "0302",
          checked: false,
        },
        {
          id: "0402",
          checked: false,
        },
        {
          id: "0502",
          checked: false,
        },
        {
          id: "0602",
          checked: false,
        },
        {
          id: "0702",
          checked: false,
        },
      ],
      a: [
        {
          id: "0103",
          checked: false,
        },
        {
          id: "0203",
          checked: false,
        },
        {
          id: "0303",
          checked: false,
        },
        {
          id: "0403",
          checked: false,
        },
        {
          id: "0503",
          checked: false,
        },
        {
          id: "0603",
          checked: false,
        },
        {
          id: "0703",
          checked: false,
        },
      ],
      parttimejob: false,
      yuLanStatus: 0, // 0职位详情 2招聘中 3待审核 4已下架 5发布职位 预览
      modalShow: false,
      modalShow2: false,
      modalShow3: false,
      modalConten: "",
      flag: true,
      status_name: "", // 已下架重新上架, 如已下架 则直接上架, 未审核要审核 参数
      state: 0,
      usertype: 1,
      modalLh: false,
      isLahei: 0,
      modalContenLh: "",
      addrLth: 0,
      windowHeigt: "",
      jobids: [],
      jobreportshow: true,
      linkInf: "",
      welfareLength: 0,
    };
  },
  created() {
    this.jobid = this.$route.query.id;
    this.getJobDetails();
  },
  methods: {
    backFun() {
      this.$router.go(-1);
    },
    // swperchange(e) {
    //   console.log(e);
    //   this.jobid = e.detail.currentItemId;
    //   this.getjobInfo();
    // },

    //获取职位信息详情
    getJobDetails() {
      getJobDetails({ job_id: this.jobid, is_part: this.isPartJob })
        .then((result) => {
          console.log("职位信息", result);
          document.title = result.data.job_info.name;
          this.datas = result.data;
          result.data.job_info.x = parseFloat(result.data.job_info.x);
          result.data.job_info.y = parseFloat(result.data.job_info.y);
          var jobinf = result.data.job_info;
          this.jobInfo = jobinf;

          // this.center = [jobinf.x, jobinf.y];
          this.linkInf = result.data.recruit;
          if (this.jobInfo.description.length > 100) {
            this.more = this.jobInfo.description.slice(0, 100);
          }
          // this.isColl = result.data.is_coll;
          // this.isApply = result.data.is_apply;
          // // this.getJobListByComId();
          //     videolist: [],
          // imglist: [],
          // result.data.job_show.forEach((item) => {
          //   if (item.type == "video") {
          //     this.videolist.push(item);
          //   }
          //   if (item.type == "img") {
          //     this.imglist.push(item);
          //     this.prImglist.push(item.picurl);
          //   }
          // });
          // if (this.videolist.length === 0) {
          //   this.type = 2;
          // }
          // var imgs = [];
          // result.data.job_show.forEach((element) => {
          //   if (element.type == "img") {
          //     imgs.push(element.picurl);
          //   }
          // });
          // this.jobShowImg = imgs;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 工作信息
    getjobInfo() {
      let qure = {
        job_id: this.jobid,
      };
      if (this.parttimejob) {
        qure.is_part = 1;
      }
      // api.post({
      //   url: "job/jobInfo",
      //   data: qure,
      //   success: (ress) => {
      //     console.log("工作信息", ress.data.job_info.uid, ress);
      //     this.datas = ress.data;
      //     this.linkInf = ress.data.recruit;
      //     this.datas.job_show.forEach((item) => {
      //       if (item.type === "video") {
      //         this.videolist.push(item);
      //       }
      //       if (item.type === "img") {
      //         this.imglist.push(item);
      //       }
      //     });
      //     if (this.videolist.length === 0) {
      //       this.type = 2;
      //     }
      //     let jobInfo = ress.data.job_info;
      //     jobInfo.x = parseFloat(jobInfo.x);
      //     jobInfo.y = parseFloat(jobInfo.y);
      //     jobInfo.province_name =
      //       ress.data.job_info.province_name + ress.data.job_info.pcity_name;
      //     this.addrLth = ress.data.job_info.address.split("").length;
      //     // console.log(this.addrLth);
      //     this.jobInfo = jobInfo;
      //     this.welfareLength = ress.data.job_info.welfare.length;
      //     if (this.jobInfo.description.length > 100) {
      //       this.more = this.jobInfo.description.slice(0, 100);
      //     }
      //     if (this.parttimejob) {
      //       let morning = ress.data.job_info.worktime_arr.morning;
      //       let noon = ress.data.job_info.worktime_arr.noon;
      //       let afternoon = ress.data.job_info.worktime_arr.afternoon;
      //       morning.length = 7;
      //       noon.length = 7;
      //       afternoon.length = 7;
      //       morning.forEach((item) => {
      //         if (item == "周一") {
      //           morning[0] = 1;
      //         }
      //         if (item == "周二") {
      //           morning[1] = 1;
      //         }
      //         if (item == "周三") {
      //           morning[2] = 1;
      //         }
      //         if (item == "周四") {
      //           morning[3] = 1;
      //         }
      //         if (item == "周五") {
      //           morning[4] = 1;
      //         }
      //         if (item == "周六") {
      //           morning[5] = 1;
      //         }
      //         if (item == "周日") {
      //           morning[6] = 1;
      //         }
      //       });
      //       noon.forEach((item) => {
      //         if (item == "周一") {
      //           noon[0] = 1;
      //         }
      //         if (item == "周二") {
      //           noon[1] = 1;
      //         }
      //         if (item == "周三") {
      //           noon[2] = 1;
      //         }
      //         if (item == "周四") {
      //           noon[3] = 1;
      //         }
      //         if (item == "周五") {
      //           noon[4] = 1;
      //         }
      //         if (item == "周六") {
      //           noon[5] = 1;
      //         }
      //         if (item == "周日") {
      //           noon[6] = 1;
      //         }
      //       });
      //       afternoon.forEach((item) => {
      //         if (item == "周一") {
      //           afternoon[0] = 1;
      //         }
      //         if (item == "周二") {
      //           afternoon[1] = 1;
      //         }
      //         if (item == "周三") {
      //           afternoon[2] = 1;
      //         }
      //         if (item == "周四") {
      //           afternoon[3] = 1;
      //         }
      //         if (item == "周五") {
      //           afternoon[4] = 1;
      //         }
      //         if (item == "周六") {
      //           afternoon[5] = 1;
      //         }
      //         if (item == "周日") {
      //           afternoon[6] = 1;
      //         }
      //       });
      //       this.morning = morning;
      //       this.noon = noon;
      //       this.afternoon = afternoon;
      //     }
      //     console.log("jianzhhi", this.morning, this.noon, this.afternoon);
      //   },
      // });
    },

    // 推荐列表
    getTJlist() {
      // api.get({
      //   url: "Comjob/recommendjob",
      //   data: {
      //     id: this.jobid,
      //   },
      //   success: (res) => {
      //     let tjList = res.data;
      //     if (tjList.length > 0) {
      //       tjList.map((item) => {
      //         item.exp = item.exp_name;
      //         item.edu = item.edu_name;
      //       });
      //       var ls = tjList,
      //         li = [];
      //       ls.forEach((it, id) => {
      //         if (id <= 2) {
      //           li.push(it);
      //         }
      //       });
      //       tjList = li;
      //     }
      //     this.tjList = tjList;
      //   },
      // });
    },

    // 展示文本详情
    showdescription() {
      console.log("点击了");
      this.more = "";
    },
    // 1 展示视频. 2展示照片
    checktype(num) {
      this.type = num;
    },
    // previewImg(e) {
    //   let imglist = this.imglist.map((item) => {
    //     return item.picurl;
    //   });

    // },

    toCompayDetail() {
      this.$router.push(`/h5cominfo?id=${this.jobInfo.uid}`);
    },
    toMiniProFun() {
      cresteHttpUrl().then(e=>{
        window.open(e.data.url);
      });
    },

    // todetails(e) {
    //   if (this.yuLanStatus != 0) {
    //     return false;
    //   }
    //   let has = judgetoken.hastoken();
    //   if (has) {
    //     api.post({
    //       url: "resume/NeedScore",
    //       success: (res) => {
    //         if (res.data.score > res.data.myscore || res.code == 102) {
    //           // 102简历未通过审核
    //           uni.showToast({
    //             title: res.msg,
    //             icon: "none",
    //           });
    //           setTimeout(() => {
    //             uni.navigateTo({
    //               url: "/pagesA/user/resume/resume",
    //             });
    //           }, 1500);
    //         } else {
    //           //判断拉黑
    //           api.post({
    //             url: "/Chat/checkSetUp",
    //             data: {
    //               to_uid: this.jobInfo.uid,
    //             },
    //             success: (res) => {
    //               console.log("获取设置信息", this.jobInfo.uid, res);
    //               if (res.data.hmd == 1) {
    //                 this.modalContenLh =
    //                   "您已将对方拉黑，确认要给他发送消息吗？";
    //                 this.modalLh = true;
    //                 this.isLahei = 1;
    //               } else {
    //                 if (res.data.to_hmd == 1) {
    //                   this.modalContenLh =
    //                     "对方已将你拉黑，您不能发送消息给TA!";
    //                   this.modalLh = true;
    //                   this.isLahei = 2;
    //                 } else {
    //                   let url = e.currentTarget.dataset.url;
    //                   uni.navigateTo({
    //                     // url: `/pagesA/chat/chat?uid=${this.uid}`
    //                     url: url,
    //                   });
    //                 }
    //               }
    //             },
    //           });

    //           // 分数够直接直聊
    //           // uni.navigateTo({
    //           // 	url: url
    //           // });
    //         }
    //       },
    //     });
    //   }
    // },

    // modelconfirmLh() {
    //   switch (this.isLahei) {
    //     case 1:
    //       api.post({
    //         url: "Chat/DoSetUpV2",
    //         data: {
    //           to_uid: this.jobInfo.uid,
    //           type: 2,
    //         },
    //         success: (res) => {
    //           uni.navigateTo({
    //             url: `/pagesA/chat/chat?uid=${this.jobInfo.uid}`,
    //           });
    //         },
    //       });
    //       break;
    //     case 2:
    //       break;
    //     default:
    //       break;
    //   }
    // },

    getqwe() {
      return true;
    },

    close() {},
    open() {},
  },
};
</script>

<style scoped>
.h5-job-detail-page {
  /* width: 100%; */
  height: 100%;
  overflow: scroll;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 16px;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  transition: width 0.3s ease;
  &::-webkit-scrollbar {
    display: none;
  }
  /* align-items: center; */
}
.h5-job-detail-page .flineview {
  font-weight: 900;
  color: #fe6002;
}

.page-swper {
  width: 100%;
}

.page-swper .swp-item {
  width: 100%;
}

.jianzhi_time {
  padding-top: 32px;
  margin: 0 31px;
}

.jianzhi_time .tit {
  font-size: 17px;
  color: #333333;
  font-weight: bold;
}

.htitle {
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  font-size: 25px;
  font-weight: 600;
}

.htitlename {
  color: #222222;
  font-weight: 900;
}

.time_border {
  margin-top: 10px;
}

.time_border span,
.jianzhi_time .text {
  width: 12.5%;
  height: 25px;
  line-height: 25px;
  text-align: center;
  display: block;
  color: #000000;
  font-size: 13px;
}

.b_th {
  border-top: 1px solid #fe6002;
  background-color: #fe6002;
}

.b_sw {
  display: flex;
}

.b_sw > span:last-child,
.b_sw .text:last-child {
  border-right: 1px solid #fe6002;
}

.b_sw > span span:first-child {
  border-right: none;
}

.b_sw > span,
.b_sw .text {
  border: 1px solid #fe6002;
  border-right: none;
  border-top: none;
  box-sizing: initial;
  font-size: 6px;

  font-weight: 400;
  color: #737373;
}

.b_sw .text {
  font-size: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tit {
  font-size: 18px;

  font-weight: 600;
  color: #222222;
  line-height: 25px;
}

.b_th .text {
  font-size: 12px;
  color: #ffffff !important;
}

.header {
  width: 100%;
  margin-top: 17px;
  height: 60px;
  display: flex;
}

.logo {
  width: 60px;
  height: 60px;
  border-radius: 4px;
}

.hname {
  flex: 1;
  margin-left: 12px;
  line-height: 25px;
  line-height: 25px;
  max-width: 255px;
}

.hline1 {
  margin-top: 5px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.hcomname {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 17px;

  font-weight: 700;
  color: #222222;
}

.h1 {
  padding-left: 8px;
  width: 12px;
  height: 13px;
}

.h_rz {
  padding-left: 4px;
  font-size: 15px;

  font-weight: 400;
  color: #ff8136;
}

.hline2 {
  margin-top: 8px;
  display: flex;
  height: 10px;
  line-height: 10px;
}

.hline2 span {
  padding: 0 8px;
  font-size: 14px;

  font-weight: 400;
  color: #757575;
  border-right: 1px solid #979797;
  white-space: nowrap;
}

.hline2 span:nth-child(1) {
  padding-left: 0;
}

.hline2 span:last-child {
  border-right: none;
}

.time {
  margin-top: 10px;
  display: flex;
  align-items: center;
  font-size: 13px;

  font-weight: 400;
  color: #666666;
  line-height: 28px;
}

.time2 {
  margin-top: 10px;
  font-size: 16px;

  font-weight: 400;
  color: #666666;
  line-height: 28px;
}

.time2 div {
  display: flex;
  align-items: center;
}

.time2 .etxt {
  margin-left: 4px;
  font-size: 13px;

  font-weight: 400;
  color: #666666;
}

.time2 .up-time {
  position: absolute;
  right: 16px;
  font-size: 15px;

  font-weight: 400;
  color: #c0c0c0;
}

.hr-inf {
  margin: 20px 16px;
  display: flex;
  align-items: center;
  position: relative;
  border-top: 1px solid#F3F3F3;
  border-bottom: 1px solid#F3F3F3;
  padding: 20px 0;
}

.hr-inf .head-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.hr-inf .text-view {
  margin-left: 20px;
}

.hr-inf .text-view .top-txt-view {
  display: flex;
  align-items: center;
}

.hr-inf .text-view .top-txt-view .ninkname {
  font-size: 18px;

  font-weight: 500;
  color: #222222;
}

.hr-inf .text-view .top-txt-view .on-lin {
  display: flex;
  align-items: center;
  margin-left: 8px;
  font-size: 13px;

  font-weight: 400;
  color: #666666;
}

.hr-inf .text-view .top-txt-view .on-lin .dian {
  width: 8px;
  height: 8px;
  background-color: #ff8136;
  border-radius: 50%;
  margin-right: 4px;
}

.hr-inf .text-view .job-com {
  font-size: 16px;

  font-weight: 400;
  color: #757575;
  margin-top: 5px;
}

.hr-inf .right-img {
  width: 13px;
  height: 13px;
  position: absolute;
  right: 15px;
}

.welfare {
  margin-top: 16px;
  margin-bottom: 10px;
}

.wcontent {
  display: inline-block;
  height: 32px;
  line-height: 32px;
  padding: 0 7px;
  margin-right: 10px;
  font-size: 12px;

  font-weight: 400;
  background: #fff3ec;
  color: #ff8136;
  border-radius: 2px;
  margin-bottom: 5px;
}

.alltitle {
  font-size: 20px;

  font-weight: 900;
  color: #222222;
  display: flex;
  align-items: center;
}

.alltitle span {
  font-size: 16px;
  color: #c0c0c0;
  position: absolute;
  right: 16px;
  font-weight: 400;
}

.mt-32 {
  margin-top: 32px;
}

.description {
  margin-top: 8px;
  padding-bottom: 24px;
  display: block;
  font-size: 16px;

  font-weight: 400;
  color: #222222;
  border-bottom: 1px solid #f3f3f3;
  line-height: 27px;
}

.mt-30 {
  margin-top: 30px;
}

.address {
  height: 29px;
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ads {
  width: 251px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;

  font-weight: 400;
  color: #757575;
}

.navigete {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 24px;
  line-height: 12px;
  background: #fffbf9;
  border-radius: 12px;
  border: 1px solid #fe6002;
  font-size: 12px;

  font-weight: 400;
  color: #fe6002;
}

.navigete img {
  width: 32px;
  height: 34px;
}

.chgtype {
  display: flex;
}

.nochg {
  width: 52px;
  height: 24px;
  margin-right: 10px;
  margin-top: 8px;
  line-height: 24px;
  text-align: center;
  border-radius: 13px;
  font-size: 12px;

  font-weight: 400;
  color: #ffffff;
}

.nochoose {
  background: #c5c9cd;
}

.chgtype .choose {
  background: #ff8136;
}

.environment {
  margin-top: 16px;
  overflow: scroll;
  white-space: nowrap;
  height: 198px;
  margin-right: -32px;
}

.environment div {
  display: inline-block;
  height: 147px;
  margin-right: 1%;
  border-radius: 4px;
}

.environment div img {
  height: 147px;
  border-radius: 8px;
}

/* .environment view video {
		height: 348px;
		width: 620px;
	} */

.forjob {
  margin-top: 68px;
}

.ftitle {
  margin-bottom: 18px;
  font-size: 36px;

  font-weight: 700;
  color: #222222;
}

.view-flex {
  /* 	display: flex;
	flex-direction: column;
	justify-content: center; */
  position: relative;
}

.view-flex .right-view {
  display: inline;
  position: absolute;
  right: 0;
  font-size: 24px;
  color: #757575;
  line-height: 60px;
}

.flist {
  /* margin: 30px 0px 32px; */
  border-bottom: 1px solid #e7e8eb;
}
/* 
.mapcontainer {
  position: relative;
  margin: 32px -32px 0 -32px;
  border-bottom: 12px solid #f5f6fa;
}*/

.mapmask {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

/* .map {
  width: 686px;
  height: 516px;
  padding: 0 32px;
  margin-bottom: 48px;
} */

.cardd {
  background-color: #f5f6fa;
  padding-top: 20px;
}

.bortop {
  border-top: 1px solid #e7e8eb;
  padding-top: 42px;
}

.tip {
  border-bottom: 6px solid #f5f6fa;
}

.tips {
  margin-top: 20px;
  display: flex;
  align-items: center;
  z-index: 9999;
}

.tips img {
  width: 15px;
  height: 15px;
}

.tips span {
  margin-left: 4px;
  font-size: 15px;

  font-weight: 500;
  color: #222222;
}

.tcontent {
  margin-top: 6px;
  padding-bottom: 20px;
  line-height: 20px;
  font-size: 14px;

  font-weight: 400;
  color: #757575;
}

.tcontent span {
  color: #3291e1;
  text-decoration: underline;
}

.qzxlist {
  height: 90px;
  line-height: 90px;
  color: #222222;
  text-align: center;
  font-size: 28px;
  border-bottom: 2px solid #cfcfcf;
}

/* #ifdef APP */
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 32px;
  height: 140px;
  display: flex;
  justify-content: space-around;
  background-color: #ffffff;
  padding-top: 16px;
  z-index: 999;
}

.footer view {
  flex: 1;
  height: 96px;
  line-height: 96px;
  text-align: center;
  border-radius: 4px;
  font-size: 16px;

  font-weight: 500;
}

.footer view:nth-child(1) {
  background: #222222;
  color: #ffffff;
  margin-right: 20px;
}

.footer view:nth-child(2) {
  background: #fe6002;
  color: #ffffff;
}

/* #endif */
/* #ifdef MP-WEIXIN */
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 32px;
  height: 140px;
  display: flex;
  justify-content: space-around;
  background-color: #ffffff;
  padding-top: 16px;
  z-index: 999;
}

.footer .sollec {
  width: 100px;
  margin-top: 10px;
  margin-left: 15px;
}

.footer .sollec .text {
  font-size: 24px;
  color: #222222;
}

.footer .sollec .txtsl {
  color: #fe6002;
}

.footer .sollec image {
  width: 44px;
  height: 44px;
}

.footer .f-btn {
  flex: 1;
  height: 96px;
  line-height: 96px;
  text-align: center;
  border-radius: 4px;
  font-size: 16px;

  font-weight: 500;
}

.footer .fot-btn1 {
  background: #222222;
  color: #ffffff;
  margin-right: 20px;
}

.footer .fot-btn2 {
  background: #fe6002;
  color: #ffffff;
}

/* .footer view {
		flex: 1;
		height: 96px;
		line-height: 96px;
		text-align: center;
		border-radius: 4px;
		font-size: 16px;
		
		font-weight: 500;
	}

	.footer view:nth-child(1) {
		
	}

	.footer view:nth-child(2) {
		background: #fe6002;
		color: #ffffff;
	} */
/* #endif */

/* #ifdef MP-TOUTIAO */
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 32px;
  height: 140px;
  display: flex;
  justify-content: space-around;
  background-color: #ffffff;
  padding-top: 16px;
  z-index: 999;
}

.footer .sollec {
  width: 100px;
  margin-top: 10px;
  margin-left: 15px;
}

.footer .sollec .text {
  font-size: 24px;
  color: #222222;
}

.footer .sollec .txtsl {
  color: #fe6002;
}

.footer .sollec image {
  width: 44px;
  height: 44px;
}

.footer .f-btn {
  flex: 1;
  height: 96px;
  line-height: 96px;
  text-align: center;
  border-radius: 4px;
  font-size: 16px;

  font-weight: 500;
}

.footer .fot-btn1 {
  background: #222222;
  color: #ffffff;
  margin-right: 20px;
}

.footer .fot-btn2 {
  background: #fe6002;
  color: #ffffff;
}

/* .footer view {
		flex: 1;
		height: 96px;
		line-height: 96px;
		text-align: center;
		border-radius: 4px;
		font-size: 16px;
		
		font-weight: 500;
	}
	
	.footer view:nth-child(1) {
		
	}
	
	.footer view:nth-child(2) {
		background: #fe6002;
		color: #ffffff;
	} */
/* #endif */

.yl-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 140px;
  display: flex;
  justify-content: space-around;
  background-color: #ffffff;
  padding-top: 32px;
  z-index: 999;
}

.yl-footer-1 view,
.yl-footer view {
  height: 80px;
  line-height: 96px;
  text-align: center;
  border-radius: 4px;
  font-size: 16px;

  font-weight: 500;
  border: 4px;
  line-height: 80px;
}

.yl-footer-1 view:nth-child(1),
.yl-footer view:nth-child(1) {
  width: 148px;
  color: #222;
  border: 1px solid#757575;
}

.yl-footer view:nth-child(2) {
  width: 148px;
  color: #222;
  border: 1px solid#757575;
}

.yl-footer view:nth-child(3) {
  width: 350px;
  color: #ffffff;
  background-color: #fe6002;
  border: 1px solid#fe6002;
}

.yl-footer-1 {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 140px;
  display: flex;
  justify-content: space-around;
  background-color: #ffffff;
  padding-top: 32px;
  z-index: 999;
}

.yl-footer-1 view:nth-child(2) {
  width: 190px;
  color: #222;
  border: 1px solid#757575;
}

.yl-footer-1 view:nth-child(3) {
  width: 308px;
  color: #ffffff;
  background-color: #fe6002;
  border: 1px solid#fe6002;
}

/* 分享面板 */
.mask {
  display: flex;
  align-items: flex-end;
  position: fixed;
  left: 0;
  top: var(--window-top);
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0);
  z-index: 9995;
  transition: 0.3s;
}

.mask .mask-content {
  width: 100%;
  min-height: 30vh;
  max-height: 70vh;
  background: #ffffff;
  transform: translateY(100%);
  transition: 0.3s;
  overflow-y: scroll;
}

.mask.none {
  display: none;
}

.mask.show {
  background: rgba(0, 0, 0, 0.4);
}

.mask.show .mask-content {
  transform: translateY(0);
  border-radius: 8px 8px 0 0;
}

.share-header {
  height: 110px;
  font-size: 28px;
  color: #222222;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
}

.share-header:before,
.share-header:after {
  content: "";
  width: 240px;
  height: 0;
  border-top: 1px solid #ffffff;
  transform: scaleY(0.5);
  margin-right: 30px;
}

.share-header:after {
  margin-left: 30px;
  margin-right: 0;
}

.share-list {
  margin: 12px 110px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.share-item {
  margin: 0;
  padding: 0;
  border: 1px solid transparent;
  outline: none;
  background: transparent;
  border: none;
  border: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 180px;
}

.share-item image {
  width: 82px;
  height: 80px;
}

.share-item text {
  font-size: 24px;
  color: #757575;
}

.share-item::after,
.share-btn::after {
  border: none;
}

.share-cancle {
  border-top: 16px solid #f5f6fa;
  height: 90px;
  line-height: 90px;
  text-align: center;
  font-size: 28px;

  font-weight: 400;
  color: #222222;
}

.share-btn {
  margin: 0;
  padding: 0;
  border: 1px solid transparent;
  outline: none;
  background: transparent;
  border: none;
  border: 0;
  font-size: 30px;
}
</style>