<template>
  <div>
    <div v-if="isopenAffirm" class="affirm-modal">
      <div class="affirm-modal-content">
        <div class="affirm-modal-header">
          <slot name="icon">
            <i class="el-icon-warning" style="color: red"></i>
          </slot>
          <span class="affirm-modal-title">{{affirmTitle}}</span>
        </div>
        <div class="affirm-modal-msg">
          <p v-html="affirmMsge"></p>
          <slot name="content"></slot>
        </div>
        <div class="affirm-modal-butts">
          <div class="affirm-modal-butt-off affirm-modal-butt-comm" @click="handleClose">取消</div>
          <div class="affirm-modal-butt-ok affirm-modal-butt-comm" @click="dialogOk">确认</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "affirmDialog",
  /*
  *     <affirm-dialog
        :isopen-affirm="isOpenAffirm"
        :affirm-dialog-close="affirmClose"
        :affirmDialogOk="affirmDialogOk"
        affirmTitle="提示"
        :affirmMsge="affirmMsge"
    >
    <template v-slot:icon><i></i></template>*/
  /*图标用slot显示*/
  /*affirmDialogClose关闭弹窗回调；affirmDialogOk确认回调*/
  /*isopenAffirm 弹窗开关；affirmTitle头顶标题；affirmMsge内容；affirmFrom自定义内容用于区分确认的是哪一个；dataId传递的数据*/
  props: ["isopenAffirm","affirmDialogClose","affirmDialogOk","affirmTitle","affirmMsge","affirmFrom","dataId"],
  data() {
    return {

    };
  },
  methods: {
    handleClose(){
      this.affirmDialogClose()
    },
    dialogOk(){
      this.affirmDialogOk(this.affirmFrom,this.dataId)
    }
  }
};
</script>

<style scoped>
.affirm-modal-butts{
  display: flex;
  border-top: #d9d9d9 1px solid;
}
.affirm-modal-butt-comm{
  width: 50%;
  font-size: 20px;
  
  font-weight: 400;
  line-height: 55px;
  text-align: center;
  cursor: pointer;
}
.affirm-modal-butt-off{
  color: #191919;
  border-right: 1px solid #d9d9d9;
}
.affirm-modal-butt-ok{
  color: #FE6002;
}
.affirm-modal-msg{
  height: 80px;
  font-size: 18px;
  
  font-weight: 400;
  color: #333333;
  line-height: 25px;
  align-content: center;
  margin: 16px 20px 0;
}
.affirm-modal-header{
  margin: 25px 0 0 20px;
  font-size: 27px;
  height: 32px;
  line-height: 32px;
}
.affirm-modal-title{
  font-size: 24px;
  
  font-weight: 600;
  color: #333333;
  margin-left: 8px;
}
.affirm-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index:999;
}
.affirm-modal-content {
  width: 391px;
  height: 209px;
  background: #FFFFFF;
  border-radius: 8px 8px 8px 8px;
}
</style>