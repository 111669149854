/*
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-08-01 18:32:29
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-01-09 17:34:28
 * @FilePath: /lcwpcvue/src/api/commen/filtrateComp.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'
//获取筛选的各种职位
export function getJobClassList() {
  return request({
    url: 'job/classlist',
    method: 'post'
    // params: params
  })
}

/**
* 获取省列表
* @url Cityclass/provinceList
* @param {} params 
* @return 
*/
export function getProvinceList() {
  return request({
    url: 'Cityclass/provinceList',
    method: 'get'

  })
}

/**
* 获取筛选期望薪资
* @url Userclass/getAssignUserClass?usertype=2
* @param {} params 
* @return 
*/
export function getSalaryList() {
  return request({
    url: 'Userclass/getAssignUserClass?usertype=2',
    method: 'get'
  })
}

/**
* 获取转店面积筛选
* @url shop/searchFilter
* @param {} params 
* @return 
*/
export function getTransferAreaFil() {
  return request({
    url: 'shop/searchFilter',
    method: 'get'
  })
}

/**
* 获取转店类型筛选
* @url shop/type
* @param {} params 
* @return 
*/
export function getTransferTypeFil() {
  return request({
    url: 'shop/type',
    method: 'get'
  })
}