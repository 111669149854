<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2024-01-03 17:02:50
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-03-07 15:37:24
 * @FilePath: /lcwpcvue/src/views/publishTransffer.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="publish-transffer-view">
<!--    <div class="breadcrumb">-->
<!--      <el-breadcrumb separator-class="el-icon-arrow-right">-->
<!--        <el-breadcrumb-item :to="{ path: '/' }">个人中心</el-breadcrumb-item>-->
<!--        <el-breadcrumb-item>转接店</el-breadcrumb-item>-->
<!--        <el-breadcrumb-item>发布接店</el-breadcrumb-item>-->
<!--      </el-breadcrumb>-->
<!--    </div>-->
    <div class="add-transffer-view">
      <div class="top-titel-text-view">
        <span>发布接店</span>
      </div>
      <div class="transffer-edt-inf-view">
        <div class="release-ms-title">
          <div class="left-xian"></div>
          <div class="title">基本信息</div>
        </div>
        <el-form label-position="left" ref="" size="medium">
          <div class="edi-lin-item">
            <el-form-item label="">
              <div class="items">
                <label class="title-store"><span>联系人</span></label>
                <el-input
                  v-model="takeObj.link_man"
                  placeholder="请填写联系人"
                  clearable
                  ref="link_man_ref"
                ></el-input>
              </div>
            </el-form-item>
            <el-form-item label="">
              <div class="items">
                <label class="title-store"><span>联系电话</span></label>
                <el-input
                  v-model="takeObj.link_tel"
                  placeholder="请填写联系电话"
                  clearable
                  @change="checkData('phone')"
                  ref="link_tel_ref"
                >
                </el-input>
              </div>
            </el-form-item>
          </div>

          <div class="edi-lin-item">
            <el-form-item label="">
              <div class="items" @click="openAddress()">
                <label class="title-store"><span>期望地区</span></label>
                <el-input
                  readonly
                  placeholder="请选择期望地区"
                  clearable
                  suffix-icon="el-icon-arrow-right"
                  v-model="takeObj.city_name"
                  ref="address_ref"
                >
                </el-input>
                <div class="zd-ingp-view"></div>
              </div>
            </el-form-item>
          </div>
          <el-divider></el-divider>

          <div class="release-ms-title">
            <div class="left-xian"></div>
            <div class="title">详细信息</div>
          </div>
          <div class="edi-lin-item" ref="type_ref">
            <el-form-item label="">
              <div class="items">
                <label class="title-store"><span>接店标题</span></label>
                <el-input
                  v-model="takeObj.title"
                  placeholder="例如xx地区找xx面积宠物门店"
                  clearable
                  ref="title_ref"
                >
                </el-input>
              </div>
            </el-form-item>
          </div>
          <div class="choseview" >
            <el-form-item prop="">
              <label class="title-store"><span>店铺类型(可多选)</span></label>
              <div class="choseview-input-view">
                <div v-for="shoptype in shopTypeClass" :key="shoptype.id">
                  <span
                    class="ch-item-type"
                    @click="choseRentType(shoptype.id)"
                    :class="
                      takeObj.type_id.includes(shoptype.id) ? 'sl-sex' : ''
                    "
                    >{{ shoptype.name }}</span
                  >
                </div>
              </div>
            </el-form-item>
          </div>
          <el-divider></el-divider>
          <div class="edi-lin-item">
            <el-form-item label="">
              <div class="items">
                <label class="title-store"><span>店铺面积</span></label>
                <el-select
                  placeholder="请选择宠物店面积"
                  v-model="takeObj.area"
                  ref="area_ref"
                >
                  <el-option
                    v-for="item in jiedianParams.area"
                    :key="item.value"
                    :label="item.key"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
            </el-form-item>
            <el-form-item label="">
              <div class="items">
                <label class="title-store"><span>转让费</span></label>
                <el-select
                  placeholder="请选择转让费"
                  v-model="takeObj.transferFee"
                  ref="transferFee_ref"
                >
                  <el-option
                    v-for="item in jiedianParams.transferFee"
                    :key="item.value"
                    :label="item.key"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
            </el-form-item>
          </div>
          <div class="edi-lin-item">
            <el-form-item label="">
              <div class="items">
                <label class="title-store"><span>店铺租金</span></label>
                <el-select placeholder="请填写（选填）"
                           v-model="takeObj.rent"
                           ref="rent_ref">
                  <el-option
                    v-for="item in jiedianParams.rent"
                    :key="item.value"
                    :label="item.key"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
            </el-form-item>
          </div>
          <el-divider></el-divider>
          <div class="second-title-view">
            <span class="ttt1">店铺要求</span>
          </div>
          <div class="edi-one-item">
            <el-form-item label="">
              <div class="items">
                <div class="textarea-view">
                  <el-input
                    type="textarea"
                    :rows="8"
                    :maxlength="500"
                    placeholder="请输入店铺要求"
                    v-model="takeObj.desc"
                    ref="desc_ref"
                  ></el-input>
                  <div class="textarea-bot-view">
                    <span class="tt1"
                      ><label>建议填写字数在20个字以上</label></span
                    >
                    <span class="tt2">0/500</span>
                  </div>
                </div>
              </div>
            </el-form-item>
          </div>
          <div class="bot-submit-btn">
            <el-button type="primary" @click="submitTransffer"
              >立即发布
            </el-button>
          </div>
        </el-form>
      </div>
    </div>

    <ChoseCity
      :dialogVisible2="isCityShow"
      @closeDilog2="closeDilog2"
      @saveJobAddr="saveTakeAddr"
    ></ChoseCity>
  </div>
</template>

<script>
import {
  getShopTypeClass,
  getSearchFilter,
  createTakeShop,
  getTakeShopById,
} from "@/api/commen/transferShop.js";
import { getAddressId } from "@/api/commen/addressManageApi.js";
import { gcj02towgs84 } from "@/utils/WSCoordinate.js";

import ChoseCity from "@/components/choseCity.vue";

export default {
  components: {
    ChoseCity,
  },
  data() {
    return {
      isCityShow: false,
      takeObj: {
        id: 0,
        title: "",
        type_id: [],
        min_area: 0,
        max_area: 0,
        max_transfer_fee: 0,
        max_rent: 0,
        province: "",
        city: "",
        district: "",
        link_man: "",
        link_tel: "",
        desc: "",
        rent_type: 0,
        transfer_type: 0,
        city_name: "",
        area: "",
        rent: "",
        transferFee: "",
        full_city:''
      },
      shopTypeClass: [],
      jiedianParams: {},
    };
  },
  created() {
    this.getShopTypeClass();
    this.getJdParams();
  },
  mounted() {
    let id = this.$route.query.id;
    if (id) {
      this.selectTakeShopById(id);
    }
  },
  methods: {
    //查询接店信息
    selectTakeShopById(id) {
      console.log("查询回显数据");
      getTakeShopById(id).then((res) => {
        if (res.code === 200) {
          let data = res.data.info;
          console.log("selectTakeShopById: ",data)
          this.takeObj.id = id;
          this.takeObj.area = data.area_val;
          this.takeObj.title = data.title;
          this.takeObj.rent = data.rent_val;
          this.takeObj.transferFee = data.fee_val;
          this.takeObj.link_man = data.link_man;
          this.takeObj.link_tel = data.link_tel;
          this.takeObj.desc = data.desc;
          this.takeObj.city_name = data.all_city_name;
          this.takeObj.province = data.province;
          this.takeObj.city = data.city;
          this.takeObj.type_id = data.type_id.split(",");
        }
      });
    },
    getJdParams() {
      getSearchFilter({ type: "jd" }).then((req) => {
        if (req.code === 200) {
          this.jiedianParams = req.data;
        }
      });
    },
    openAddress() {
      this.isCityShow = true;
    },
    closeDilog2() {
      this.isCityShow = false;
    },
    saveTakeAddr(pr, ct, ar) {
      console.log('pr',pr)
      console.log('ct',ct)
      console.log('ar',ar)
      this.takeObj.province = String(pr);
      this.takeObj.city = String(ct);
      this.isCityShow = false;
      var citys = [],
          citysname = [];
      ar.forEach((element) => {
        citys.push(element.id);
        citysname.push(element.name);
      });
      this.takeObj.full_city = String(citys);
      this.takeObj.city_name = String(citysname);
    },
    checkData(type) {
      console.log("oooooooooooooo")
      switch (type) {
        case 'phone': {
          let regExp = /^1[3-9]\d{9}$/;
          if (!(regExp.test(this.takeObj.link_tel))) {
            this.$refs.link_tel_ref.value = '';
            this.$refs.link_tel_ref.select();
            this.$message.error('请正确输入手机号');
          }
          break;
        }
      }
    },
    /* 添加接店 */
    submitTransffer() {
      let data = this.takeObj;
      if (data.link_man == "") {
        this.$refs.link_man_ref.focus();
        return this.$message.error("请填写‘联系人’");
      }
      if (data.link_tel == "") {
        this.$refs.link_man_ref.focus();
        return this.$message.error("请填写‘联系电话’");
      }else {
        let regExp = /^1[3-9]\d{9}$/;
        if (!(regExp.test(data.link_tel))) {
          this.$refs.link_tel_ref.focus();
          return this.$message.error("请正确填写‘联系电话’");
        }
      }
      if (data.full_city == '') {
        this.$refs.address_ref.focus();
        return this.$message.error('请选择‘期望地区’');
      }
      if (data.title == "") {
        this.$refs.title_ref.focus();
        return this.$message.error("请填写‘接店标题’");
      }
      if (data.type_id.length == 0){
        this.$refs.type_ref.scrollIntoView();
        return this.$message.error("请选择‘店铺类型’");
      }
      if (data.area == "") {
        this.$refs.area_ref.focus();
        return this.$message.error("请选择‘面积’");
      }
      if (data.rent == "") {
        this.$refs.rent_ref.focus();
        return this.$message.error("请选择‘店铺租金’");
      }
      if (data.transferFee == "") {
        this.$refs.transferFee_ref.focus();
        return this.$message.error("请选择‘转让费’");
      }
      if (data.desc == "") {
        this.$refs.desc_ref.focus();
        return this.$message.error("请填写‘店铺要求’");
      }
      let typeIdArr = this.takeObj.type_id;
      if (typeIdArr.length > 0) {
        this.takeObj.type_id = String(typeIdArr);
      } else {
        this.takeObj.type_id = "";
      }
      createTakeShop(this.takeObj)
        .then((res) => {
          if (res.code === 200) {
            this.$message.success(res.msg);
            this.$router.push("/transfershoplist");
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    /* 获取店铺类型 */
    getShopTypeClass() {
      getShopTypeClass().then((result) => {
        console.log("获取店铺类型", result);
        this.shopTypeClass = result.data.list;
      });
    },
    saveAddress() {},
    //解析省市区id
    getAddressId(e) {
      var latlng = gcj02towgs84(e.x, e.y);
      getAddressId({ lat: latlng[1], lng: latlng[0] })
        .then((result) => {
          console.log("获取省市id", result);
          this.publishShopObj.province = result.data.province_id;
          this.publishShopObj.city = result.data.city_id;
        })
        .catch(() => {});
    },

    choseRentType(type) {
      console.log(type);
      if (this.takeObj.type_id.includes(type)) {
        let idx = this.takeObj.type_id.indexOf(type);
        if (idx !== -1) {
          this.takeObj.type_id.splice(idx, 1);
        }
      } else {
        this.takeObj.type_id.push(type);
      }
    },
  },
};
</script>

<style>
.publish-transffer-view {
  padding: 0 345px;
  margin-top: 80px;
}

.add-transffer-view {
}

.add-transffer-view .top-titel-text-view {
  width: 1231px;
  height: 93px;
  background-color: #fff;
  margin-top: 40px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.add-transffer-view .top-titel-text-view span {
  display: inline-block;
  font-size: 32px;
  
  font-weight: 600;
  color: #222222;
  line-height: 38px;
  margin-left: 20px;
  margin-top: 24px;
}

.transffer-edt-inf-view {
  background-color: #f5f6fa;
  padding: 40px 18px;
}

.transffer-edt-inf-view .release-ms-title {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.transffer-edt-inf-view .release-ms-title .left-xian {
  width: 4px;
  height: 20px;
  background-color: #fe6002;
  border-radius: 3px;
}

.transffer-edt-inf-view .release-ms-title .title {
  font-size: 22px;
  
  font-weight: 600;
  color: #222222;
  margin-left: 10px;
}

.transffer-edt-inf-view .edi-lin-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.transffer-edt-inf-view .edi-lin-item .items {
  width: 574px;
  /* margin-right: 78px; */
  position: relative;
}

.el-autocomplete {
  width: 100%;
}

/*.transffer-edt-inf-view .edi-lin-item .items*/
.title-store {
  font-size: 16px;
  
  font-weight: 400;
  color: #757575;
  line-height: 30px;
  /*margin-top: 24px;*/
}

.transffer-edt-inf-view .edi-one-item .items .zd-ingp-view {
  width: 100%;
  height: 46px;
  /* border: 1px solid #000; */
  position: absolute;
  top: 30px;
}

.transffer-edt-inf-view .edi-lin-item .items .zd-ingp-view {
  width: 100%;
  height: 46px;
  /* border: 1px solid #000; */
  position: absolute;
  top: 30px;
}

.textarea-view {
  margin-top: 16px;
}

.transffer-edt-inf-view .edi-one-item .textarea-view .textarea-bot-view {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.transffer-edt-inf-view .edi-one-item .textarea-view .textarea-bot-view .tt1 {
  font-size: 16px;
  
  font-weight: 400;
  color: #b1b1b1;
}

.transffer-edt-inf-view
  .edi-one-item
  .textarea-view
  .textarea-bot-view
  .tt1
  label {
  color: #f34b37;
}

.transffer-edt-inf-view .edi-one-item .textarea-view .textarea-bot-view .tt2 {
  font-size: 16px;
  
  font-weight: 400;
  color: #757575;
  line-height: 19px;
}

.transffer-edt-inf-view .release-ms-title {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.transffer-edt-inf-view .release-ms-title .left-xian {
  width: 4px;
  height: 20px;
  background-color: #fe6002;
  border-radius: 3px;
}

.transffer-edt-inf-view .release-ms-title .title {
  font-size: 22px;
  
  font-weight: 600;
  color: #222222;
  margin-left: 10px;
}

.transffer-edt-inf-view .choseview {
  position: relative;
}

.transffer-edt-inf-view .choseview .choseview-input-view {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.transffer-edt-inf-view .choseview .choseview-input-view .ch-item-type {
  display: block;
  padding: 0 20px;
  margin-right: 20px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #e4e7ed;
  text-align: center;
  background-color: #fff;
  color: #303030;
  line-height: 40px;
}

.second-title-view {
  font-size: 20px;
  
  font-weight: 600;
  color: #191919;
  line-height: 28px;
  margin-top: 40px;
}

.second-title-view .ttt1 {
  font-size: 22px;
  
  font-weight: 600;
  color: #222222;
  margin-left: 10px;
}

.sl-ptss-view .pt-item img {
  width: 40px;
  height: 40px;
}

.bot-submit-btn {
  width: 100%;
  text-align: center;
  margin-top: 80px;
}

.sl-sex {
  background-color: #fff5f0 !important;
  color: #fe6002 !important;
  border: 1px solid #fe6002 !important;
}
</style>