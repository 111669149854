<template>
  <div id="common-dialog">
      <el-dialog
          :title="dialogTetle"
          :visible="isopen"
          :width="dialogWidth"
          @close="handleClose"
      >
        <slot></slot>
        <div slot="footer" class="dialog-footer" :style="{textAlign:buttonAlign}" v-if="isShowButt === true">
          <el-button @click="handleClose">{{butLeft}}</el-button>
          <el-button type="primary" @click="dialogOk">{{butRight}}</el-button>
        </div>
      </el-dialog>
  </div>
</template>
<script>
/*
<ComDialog
    :isopen=""
    dialogTetle=""
    @dialogFrameEvent=""
    myWidth="500px"
>
</ComDialog>
*/
export default {
  name: "comDialog",
  props: ["isopen","dialogTetle","isShowButt","myWidth","butAlign","leftBut","rightBut"],
  created() {
    if (this.myWidth){
      this.dialogWidth = this.myWidth;
    }
    if (this.butAlign){
      this.buttonAlign = this.butAlign;
    }
    if (this.leftBut){
      this.butLeft = this.leftBut;
    }
    if (this.rightBut){
      this.butRight = this.rightBut;
    }
  },
  data() {
    return {
      dialogWidth:'680',
      buttonAlign:'right',
      butLeft: '取 消',
      butRight: '确 定',
    };
  },
  methods: {
    handleClose(){
      this.$emit('dialogFrameEvent','close')
    },
    dialogOk(){
      this.$emit('dialogFrameEvent','ok')
    }
  }
};
</script>

<style>
#common-dialog .el-dialog__wrapper .el-dialog {
  border-radius: 15px;
}
#common-dialog .el-dialog__wrapper .el-dialog .el-dialog__header{
  height: 40px;
  line-height: 40px;
}
#common-dialog .el-dialog__wrapper .el-dialog .el-dialog__headerbtn{
  top:27px;
  font-size: 24px;
}
#common-dialog .dialog-footer button.el-button.el-button--primary{
  width: 160px;
  height: 45px;
  line-height: 22px;
  font-size: 18px;
  
  border-radius: 8px 8px 8px 8px;
}
#common-dialog .dialog-footer button.el-button.el-button--default {
  width: 160px;
  height: 45px;
  line-height: 22px;
  font-size: 18px;
  
  border-radius: 8px 8px 8px 8px;
  background-color: #d9d9d9;
}
</style>