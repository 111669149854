<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-12-13 09:03:14
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-03-14 09:02:03
 * @FilePath: /lcwpcvue/src/components/setPage/setPageIndex.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <div style="height: 30px"></div>
    <div style="width: 100%">
      <div class="common-title-view">
        <span class="left-xian-view"></span>
        <span class="text">设置</span>
      </div>
    </div>
    <el-card class="box-card">
      <div class="list-item">
        <el-row>
          <el-col :span="24">
            <div class="item-col">
              <img
                class="item-row-img"
                src="../../assets/setPageIndex/account_set.png"
              />
              <div><span class="item-row-tetle">账户修改</span></div>
            </div>
            <span class="item-row-explain"
              >您有一次修改账户名机会（仅限一次）</span
            >
            <span
              v-if="accountSet === '0'"
              class="item-row-set item-row-toset"
              @click="openDialog('修改用户名')"
              >修改</span
            >
            <span v-else class="item-row-set item-row-unset">已修改</span>
          </el-col>
        </el-row>
      </div>
      <div class="list-item">
        <el-row>
          <el-col :span="24">
            <div class="item-col">
              <img
                class="item-row-img"
                src="../../assets/setPageIndex/psd_set.png"
              />
              <div><span class="item-row-tetle">账户密码</span></div>
            </div>
            <span class="item-row-explain">用于保护账号信息和登录安全</span>
            <span
              class="item-row-set item-row-toset"
              @click="openDialog('修改密码')"
              >修改</span
            >
          </el-col>
        </el-row>
      </div>
      <div class="list-item">
        <el-row>
          <el-col :span="24">
            <div class="item-col">
              <img
                class="item-row-img"
                src="../../assets/setPageIndex/email_set.png"
              />
              <div><span class="item-row-tetle">安全邮箱</span></div>
            </div>
            <span class="item-row-explain">安全邮箱将可用于接收面试邮件</span>
            <span
              class="item-row-set item-row-toset"
              @click="openDialog('修改邮箱')"
              >绑定</span
            >
          </el-col>
        </el-row>
      </div>
      <div class="list-item">
        <el-row>
          <el-col :span="24">
            <div class="item-col">
              <img
                class="item-row-img"
                src="../../assets/setPageIndex/phone_set.png"
              />
              <div><span class="item-row-tetle">安全手机</span></div>
            </div>
            <span class="item-row-explain"
              >安全手机将可用于登录账号和重置密码</span
            >
            <span
              class="item-row-set item-row-toset"
              @click="openDialog('修改手机号')"
              >修改</span
            >
          </el-col>
        </el-row>
      </div>
      <div class="list-item">
        <el-row>
          <el-col :span="24">
            <div class="item-col">
              <img
                class="item-row-img"
                src="../../assets/setPageIndex/wx_set.png"
              />
              <div><span class="item-row-tetle">绑定微信</span></div>
            </div>
            <span class="item-row-explain">绑定微信可使用微信扫一扫登录</span>
            <span
              v-if="isHaveWxBinding === '0' ||isHaveWxBinding=== ''"
              class="item-row-set item-row-toset"
              @click="openDialog('绑定微信')"
              >绑定</span
            >
            <span
              v-else
              @click="openDialog('绑定微信')"
              class="item-row-set item-row-toset"
              >重新绑定</span
            >
          </el-col>
        </el-row>
      </div>
      <!--      实名认证-->
      <div class="list-item" v-if="getUSerType === '1'">
        <el-row>
          <el-col :span="24">
            <div class="item-col">
              <img
                  class="item-row-img"
                  src="../../assets/setPageIndex/resume_auth.png"
              />
              <div><span class="item-row-tetle">实名认证</span></div>
            </div>
            <span class="item-row-explain">实名认证增加企业对你的信任度</span>
            <span
                v-if="getUSerAuthStatus == '0'"
                class="item-row-set item-row-toset"
                @click="openDialog('实名认证')"
            >认证</span
            >
            <span
                v-else
                class="item-row-set item-row-toset"
            >已认证</span
            >
          </el-col>
        </el-row>
      </div>
    </el-card>
    <wopener
      :isopen="updateUserName"
      :dialogTetle="dialogTetle"
      :dialogClose="dialogClose"
      :dialogDetermine="dialogDetermine"
    >
      <!--      修改用户名-->
      <div v-if="dialogTetle === '修改用户名'" class="set-username">
        <div class="set-username-top">
          <span class="set-username-top-sleft">新用户名</span
          ><span class="set-username-top-sright">建议使用手机号码或公司名</span>
          <input
            class="set-username-top-int font-color-glb"
            placeholder="请输入新用户名"
            v-model="username"
          />
        </div>
        <div class="set-username-bott">
          <span class="set-username-top-sleft">密码</span>
          <input
            class="set-username-bott-int font-color-glb"
            placeholder="请输入密码"
            type="password"
            v-model="password"
          />
        </div>
        <span class="psw-msg">请填写密码</span>
      </div>
      <!--      修改密码-->
      <div v-if="dialogTetle === '修改密码'" class="set-psd">
        <div class="set-username-top">
          <span class="set-username-top-sleft">原始密码</span>
          <input
            class="set-username-top-int font-color-glb"
            placeholder="请输入密码"
            type="password"
            v-model="updatePsd.oldPsd"
          />
        </div>
        <div class="set-username-top">
          <span class="set-username-top-sleft">新密码</span
          ><span class="set-username-top-sright"
            >6~16位，可包含数字、字母、下划线</span
          >
          <input
            class="set-username-bott-int font-color-glb"
            placeholder="请输入新密码"
            type="password"
            v-model="updatePsd.newPsd"
          />
        </div>
        <div class="set-username-bott">
          <span class="set-username-top-sleft">确认密码</span>
          <input
            class="set-username-bott-int font-color-glb"
            placeholder="请输入确认密码"
            type="password"
            v-model="updatePsd.affirmNewPsd"
          />
        </div>
      </div>
      <!--      修改邮箱-->
      <div v-if="dialogTetle === '修改邮箱'" class="set-email">
        <p class="e-nowEmail">当前邮箱号：{{ updateEmail.nowEmail }}</p>
        <div class="set-email-int">
          <input
            class="set-username-bott-int font-color-glb"
            type="text"
            v-model="updateEmail.newEmail"
            placeholder="请输入要绑定的邮箱"
          />
        </div>
        <CreateEmailCode
          eventTpye="sendEmail"
          ref="CreateEmailCode"
          :phoneOrEmail="updateEmail.newEmail"
        ></CreateEmailCode>
      </div>
      <!--      修改手机号-->
      <div v-if="dialogTetle === '修改手机号'" class="set-email">
        <p class="e-nowEmail">当前手机号：{{ updatePhone.nowPhone }}</p>
        <div class="set-email-int">
          <input
            class="set-username-bott-int font-color-glb"
            type="text"
            v-model="updatePhone.newPhone"
            placeholder="请输入新的手机号"
          />
        </div>
        <CreateEmailCode
          eventTpye="forgetpass"
          ref="CreatePhoneCode"
          :phoneOrEmail="updatePhone.newPhone"
          :is-check="true"
        ></CreateEmailCode>
      </div>
      <!--      绑定微信-->
      <div v-if="dialogTetle === '绑定微信'" class="set-email">
        <div class="wx-img">
          <div class="wx-img-qrcode">
            <img :src="ticketImg" style="width: 100%; height: 100%" />
          </div>
          <p class="wx-img-qrcode-msg-top wx-img-qrcode-msg-butt">
            请使用微信【扫一扫】
          </p>
          <p class="wx-img-qrcode-msg-butt">关注「猎宠网」公众号完成绑定</p>
        </div>
        <div style="text-align: center; margin-bottom: 8px">
          <img src="../../assets/setPageIndex/wx-msg.png" class="wx-img-data" />
        </div>
      </div>
      <!--      实名认证-->
      <div v-if="dialogTetle === '实名认证'" class="set-psd">
        <div class="set-username-top">
          <span class="set-username-top-sleft">姓名</span>
          <input
              class="set-username-top-int font-color-glb"
              placeholder="请输入姓名"
              v-model="auth.name"
          />
        </div>
        <div class="set-username-top">
          <span class="set-username-top-sleft">身份证号码</span>
          <input
              class="set-username-bott-int font-color-glb"
              placeholder="请输入身份证号码"
              v-model="auth.idcard"
          />
        </div>
        <div class="set-username-bott">
          <span class="set-username-top-sleft">手机号码</span>
          <input
              class="set-username-bott-int font-color-glb"
              placeholder="请输入手机号码"
              v-model="auth.phone"
          />
        </div>
        <div style="padding-top: 20px">
          <span class="set-username-top-sleft">验证码</span>
          <CreateEmailCode
              style="padding-top: 7px"
              eventTpye="check"
              ref="CreateCodeByAuth"
              :phoneOrEmail="auth.phone"
          ></CreateEmailCode>
        </div>
      </div>
    </wopener>
    <ExistsPhoneChoice
      :isOpenExiste="isOpenExiste"
      :existeArr="existePhoneArr"
      @buttCallback="existePhoneCallBack"
    >
    </ExistsPhoneChoice>
  </div>
</template>

<script>
import {appletsUpdateMoblie, userPcApi, commonWay, resumeAuth} from "@/api/commen/setApi";
import { rsaEncrypt } from "@/utils/Rsa/rsa";
import CreateEmailCode from "@/components/setPage/createCode.vue";
import wopener from "./wopener.vue";
import store from "@/store";
import requestByNotLoading from "@/utils/requestByNotLoading";
import ExistsPhoneChoice from "@/components/setPage/existsPhoneChoice.vue";

export default {
  name: "setPageIndex",
  components: {
    wopener,
    CreateEmailCode,
    ExistsPhoneChoice,
  },
  data() {
    return {
      updateUserName: false,
      isOpenExiste: false,
      accountSet: "0",
      isHaveWxBinding: "0",
      dialogTetle: "",
      password: "",
      username: "",
      updatePsd: {
        oldPsd: "",
        newPsd: "",
        affirmNewPsd: "",
      },
      updateEmail: {
        nowEmail: "",
        newEmail: "",
      },
      updatePhone: {
        nowPhone: "",
        newPhone: "",
      },
      phoneCode: "",
      ticketImg: "",
      interval: "",
      existePhoneArr: [],
      auth:{
        idcard: '',
        name: '',
        phone: '',
        code: '',
      }
    };
  },
  computed:{
    getUSerType(){
      return localStorage.getItem('usertype');
    },
    getUSerAuthStatus(){
      return store.getters.userinf.authCount;
    },
  },
  created() {
    this.getMemberParams();
  },
  async mounted() {
    window.addEventListener("onmessageWS", this.getSocketData);
  },
  beforeDestroy() {
    window.removeEventListener("onmessageWS", this.getSocketData, false);
  },
  methods: {
    /* 长连接事件监听微信绑定结果 */
    getSocketData(res) {
      var msgData = res.detail.data;
      if (msgData.sourceType === "wxbinding") {
        console.log("wxbinding........");
        if (msgData.code === 200) {
          this.$message.success(msgData.msg);
        } else {
          this.$message.error(msgData.msg);
          localStorage.removeItem("bdingGzhQrcode");
        }
        this.updateUserName = false;
      }
    },
    //电话重复时回调
    existePhoneCallBack(isChange) {
      if (isChange === true) {
        console.log("已确认");
        commonWay(this.existePhoneArr.verify, this.existePhoneArr.uid).then(
          () => {
            this.$message.success("已解除与旧账号的绑定");
            this.toUpdatePhone();
          }
        );
      } else {
        this.updatePhone.newPhone = "";
        this.$refs.CreatePhoneCode.code = "";
        console.log("取消更换手机");
      }
      this.isOpenExiste = false;
    },
    //查看用户是否有权限修改用户名
    getMemberParams() {
      userPcApi({ type: "getUserParams", field: "restname" }).then((req) => {
        console.log(req);
        if (req.code !== 200) {
          this.$message.error(req.msg);
        }
        this.accountSet = req.data.restname;
        this.isHaveWxBinding = req.data.isHaveWxBinding;
      });
    },
    // 点击弹窗确认按钮回调
    dialogDetermine(from) {
      console.log(from);
      switch (from) {
        case "修改用户名": {
          this.toUpdateUSername();
          break;
        }
        case "修改密码": {
          this.toUpdatePsd();
          break;
        }
        case "修改邮箱": {
          this.toUpdateEmail();
          break;
        }
        case "修改手机号": {
          this.toUpdatePhone();
          break;
        }
        case "实名认证": {
          this.toResumeAuth();
          break;
        }
      }
    },
    //实名认证
    toResumeAuth(){
      if (this.getUSerAuthStatus == 1) return this.$message.error("已提交认证，请刷新页面");
      if (this.auth.name === '') return this.$message.error("请填写姓名");
      if (this.auth.idcard === '') return this.$message.error("请填写身份证号码");
      if (this.auth.phone === "") return this.$message.error("请填写手机号");
      this.auth.code = this.$refs.CreateCodeByAuth.code;
      if (this.auth.code === "") return this.$message.error("验证码不能为空");
      resumeAuth(this.auth).then((res)=>{
        if (res.msg.message !== "认证成功"){
          this.$message.error('信息有误，请核实后重新提交！');
        }else {
          store.dispatch("user/setInfo");
          this.updateUserName = false;
          this.$message.success('已提交申请');
        }
      });
    },
    //修改手机号
    toUpdatePhone() {
      let code = this.$refs.CreatePhoneCode.code;
      if (code === "") return this.$message.error("验证码不能为空");
      if (this.updatePhone.newPhone === "")
        return this.$message.error("请填写手机号");
      // code   moblie  usertype
      // applets/update_moblie
      appletsUpdateMoblie(code, this.updatePhone.newPhone, 1).then((req) => {
        console.log(req);
        if (req.code === 200) {
          store.dispatch("user/setInfo");
          let info = store.getters.userinf;
          console.log(info);
          this.updateUserName = false;
          this.$message.success(req.msg);
        } else if (req.code === 15) {
          this.existePhoneArr = req.data;
          this.isOpenExiste = true;
        }
      });
    },
    //修改邮箱
    toUpdateEmail() {
      let code = this.$refs.CreateEmailCode.code;
      if (code === "") return this.$message.error("验证码不能为空");
      if (this.updateEmail.newEmail === "")
        return this.$message.error("请填写邮箱");
      userPcApi({
        type: "updateEmail",
        code: code,
        newEmail: this.updateEmail.newEmail,
      }).then((req) => {
        if (req.code === 200) {
          this.updateUserName = false;
          this.updateEmail.newEmail = "";
          this.$message.success(req.msg);
        }
      });
    },
    // 修改密码
    toUpdatePsd() {
      if (this.updatePsd.oldPsd === "")
        return this.$message.error("请填写原始密码");
      if (this.updatePsd.newPsd === "" || this.updatePsd.affirmNewPsd === "")
        return this.$message.error("请填写密码或者确认密码");
      if (this.updatePsd.newPsd.length < 6 || this.updatePsd.newPsd.length > 16)
        return this.$message.error("密码长度不在范围内！");
      if (this.updatePsd.newPsd !== this.updatePsd.affirmNewPsd) {
        this.updatePsd.affirmNewPsd = "";
        return this.$message.error("两次密码不一致,请重新输入！");
      }
      let oldPsd_r = rsaEncrypt(this.updatePsd.oldPsd);
      let newPsd_r = rsaEncrypt(this.updatePsd.newPsd);
      userPcApi({ type: "updatePsd", oldPsd: oldPsd_r, newPsd: newPsd_r })
        .then((req) => {
          console.log(req);
          if (req.code === 200) {
            this.updateUserName = false;
            this.updatePsd.oldPsd = "";
            this.updatePsd.newPsd = "";
            this.updatePsd.affirmNewPsd = "";
            this.$message.success(req.msg);
          } else {
            this.$message.error(req.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 修改用户名
    toUpdateUSername() {
      let psd = rsaEncrypt(this.password);
      console.log(psd);
      if (this.username === "") {
        this.$message.error("用户名不能为空");
        return false;
      }
      if (this.password === "") {
        this.$message.error("请填写密码");
        return false;
      }
      userPcApi({
        type: "updateUserName",
        username: this.username,
        psd: psd,
      }).then((req) => {
        console.log(req);
        this.updateUserName = false;
        this.accountSet = 1;
        this.$message.success("修改成功");
      });
    },
    // 打开弹窗
    openDialog(from) {
      if (from === "修改邮箱") {
        this.updateUserName = true;
        userPcApi({ type: "getUserParams", field: "getEmail" }).then((req) => {
          if (req.code !== 200) {
            this.$message.error(req.msg);
          }
          this.updateEmail.nowEmail = req.data.param;
        });
      } else if (from === "修改手机号") {
        this.updateUserName = true;
        this.updatePhone.nowPhone = store.getters.userinf.moblie;
      } else if (from === "绑定微信") {
        this.updateUserName = true;
        requestByNotLoading({
          url: "wx/serve",
          method: "post",
          data: {
            type: "getQrcodeByGzh",
            beFrom: "BD",
            userType: localStorage.usertype,
            imgKey: localStorage.getItem("gzhQrcodeByBD"+localStorage.usertype),
          },
        }).then((req) => {
          this.ticketImg = req.data.qrcodeTicket;
          localStorage.setItem("gzhQrcodeByBD"+localStorage.usertype, req.data.sceneStr);
        });
      } else {
        this.updateUserName = true;
      }
      this.dialogTetle = from;
    },
    // 关闭弹窗回调
    dialogClose() {
      clearInterval(this.interval);
      this.updateUserName = false;
    },
    //获取验证码
    getCallbackCode(code) {
      if (code === "") {
        this.$message.error("验证码不能为空");
        return;
      }
      this.phoneCode = code;
    },
  },
};
</script>

<style scoped>
.wx-img-qrcode-msg-butt {
  width: 270px;
  font-size: 16px;
  
  font-weight: 400;
  text-align: center;
  line-height: 22px;
  color: #757575;
}

.wx-img-qrcode-msg-top {
  margin-top: 16px;
}

.wx-img-qrcode {
  width: 200px;
  height: 200px;
  background-color: #5d6a93;
  margin: 0 auto;
  overflow: hidden;
}
.wx-img {
  width: 270px;
  height: 284px;
  margin: 0 auto;
}
.wx-img-data {
  width: 270px;
  height: 32px;
  margin: 0 auto;
}

.box-card {
  width: 1196px;
  padding: 0;
  margin-top: 16px;
  border-radius: 8px;
}

.list-item {
  border-bottom: #e6e6e6 1px solid;
  width: 1148px;
  height: 126px;
}
.list-item:last-of-type {
  border: none;
}

.item-col {
  height: 48px;
  margin-top: 30px;
  display: flex;
}

.item-row-img {
  margin-left: 24px;
  width: 30px;
  height: 30px;
}

.item-row-tetle {
  padding-left: 24px;
  font-size: 22px;
  
  font-weight: 600;
  color: #191919;
  line-height: 28px;
}

.item-row-set {
  float: right;
  position: relative;
  top: -48px;
  right: 36px;
}

.item-row-toset {
  font-size: 16px;
  
  font-weight: 600;
  color: #fe6002;
  line-height: 19px;
  cursor: pointer;
}
.item-row-unset {
  font-size: 16px;
  
  font-weight: 600;
  color: #b1b1b1;
  line-height: 19px;
}

.item-row-explain {
  font-size: 16px;
  
  font-weight: 400;
  color: #939597;
  line-height: 19px;
  margin-left: 72px;
}

.set-username,
.set-psd,
.set-email {
  width: 400px;
  margin: 0 auto;
  padding: 30px 0 32px 0;
}
.set-username-top {
  width: 100%;
  height: 80px;
  margin-bottom: 32px;
}

.set-email-int {
  margin-bottom: 32px;
}

.set-username-bott {
  width: 100%;
  height: 80px;
}
.set-username-top-int {
  height: 50px;
  width: 95%;
  border-radius: 2px 2px 2px 2px;
  border: 1px solid #e7e8eb;
  margin-top: 8px;
  padding-left: 16px;
}
.set-username-bott-int {
  height: 50px;
  width: 95%;
  border-radius: 2px 2px 2px 2px;
  border: 1px solid #e7e8eb;
  margin-top: 8px;
  padding-left: 16px;
}
.set-username-top-sleft {
  height: 22px;
  font-size: 16px;
  
  font-weight: 400;
  color: #191919;
  line-height: 19px;
  margin-right: 8px;
}
.set-username-top-sright {
  height: 22px;
  font-size: 16px;
  
  font-weight: 400;
  color: #b1b1b1;
  line-height: 19px;
}
.font-color-glb {
  font-size: 18px;
  
  font-weight: 400;
  color: #191919;
}
.psw-msg {
  height: 17px;
  font-size: 12px;
  
  font-weight: 400;
  color: #f34b37;
  line-height: 14px;
  display: none;
}
.e-nowEmail {
  ont-size: 16px;
  
  font-weight: 400;
  color: #757575;
  line-height: 19px;
  margin-bottom: 32px;
}

input::-webkit-input-placeholder {
  /*WebKit browsers*/
  font-size: 16px;
  
  font-weight: 400;
  color: #b1b1b1;
}

input::-moz-input-placeholder {
  /*Mozilla Firefox*/
  font-size: 16px;
  
  font-weight: 400;
  color: #b1b1b1;
}

input::-ms-input-placeholder {
  /*Internet Explorer*/
  font-size: 16px;
  
  font-weight: 400;
  color: #b1b1b1;
}
</style>