<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-07-19 14:21:15
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-05-14 15:11:51
 * @FilePath: /lcwpcvue/src/views/partTimeJobList.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="part-time-job-list-page">
    <div class="top-zk-div"></div>

    <div class="find-job-sear-view">
      <searview
          searchpage="partList"
          @toSearch="toSearch"
      ></searview>
      <filterateview
          searchpage="partList"
          :jobClass="jobListFilter"
          @toFliterList="fliterTalentList"
          @clearFiltrateFun="clearFiltrateFun"
          ref="filterateview_ref"
      ></filterateview>
    </div>
    <div class="min-jobList-login-tj-view">
      <div class="job-fil-navbar-view">
        <div
          class="navbar-item"
          :class="jobListFilter.billing === item.k ? 'navbar-item-sl' : ''"
          v-for="item in billingArr"
          :key="item.k"
          @click="menuEvent(item)"
        >
          <span> {{ item.v }} </span>
          <div v-show="item.k === jobListFilter.billing" class="btm-xian"></div>
        </div>
      </div>

      <div class="joblist-login-view">
        <div class="job-list-view">
          <partjobcard :jobList="jobList"></partjobcard>
          <el-pagination
              v-show="jobList.length > 0"
              @current-change="pagesChage"
              background
              layout="prev, pager, next"
              :page-count="allPage"
              :current-page.sync="currentpage"
              class="pag-ina"
          >
          </el-pagination>
          <div v-show="jobList.length <= 0" class="null-page-class">
            <NullPage
                title="暂无数据"
            ></NullPage>
          </div>
        </div>

        <div class="findjoblist-login-view">
          <loginview v-show="!isLogin" :pathname="'job'"></loginview>
          <img class="yhjl-view" src="../assets/findjob_yh_jl.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import searview from "../components/homeSearComp.vue";
import filterateview from "../components/filtrateComp.vue";
import partjobcard from "../components/comUser/partJobListCard.vue";
import loginview from "../components/loginWthiPage.vue";
import { getJobList } from "@/api/commen/findJobList";
import NullPage from "@/components/nullPage.vue";
import {turnoverTime} from "@/utils/timeUrils";

export default {
  components: {
    NullPage,
    searview,
    filterateview,
    partjobcard,
    loginview,
  },
  data() {
    return {
      isLogin: false,
      jobListFilter: {
        // 求职端 所有搜索的筛选项
        page: 1, // 页码
        column: "new", //  recommend推荐 new最新
        provinceid: "all", // 省
        cityid: "all", // 市
        three_cityid: "all", // 区
        is_qg: 0,
        jobclass_id: "all", // 顶部期望职位
        job_id: "all", // 职业, 例: 宠物医生
        search_key: "", // 搜索内容
        edu: "all", // 教育经历
        exp: "all", // 工作经历
        type: "42", // 职位类型 全职,兼职
        min_value: "all", // 期望薪资最小值
        max_value: "all", // 期望薪资最大值
        welfares: "all", //福利筛选
        billing: "all", //结算周期
      },
      jobList: [],
      billingArr: [
        { k: "all", v: "所有职位" },
        { k: "20", v: "日结" },
        { k: "21", v: "周结" },
        { k: "22", v: "月结" },
      ],
      allPage:0,
      currentpage:1
    };
  },
  created() {
    this.isLogin = localStorage.token ? true : false;
    this.getJobList();
  },
  methods: {
    pagesChage(e) {
      this.jobListFilter.page = e;
      this.getJobList();
      window.scrollTo(0, 0);
    },
    clearFiltrateFun() {
      this.jobListFilter = {
        page: 1, // 页码
        column: "new", //  recommend推荐 new最新
        provinceid: "all", // 省
        cityid: "all", // 市
        three_cityid: "all", // 区
        is_qg: 1,
        jobclass_id: "all", // 顶部期望职位
        job_id: "all", // 职业, 例: 宠物医生
        search_key: "", // 搜索内容
        edu: "all", // 教育经历
        exp: "all", // 工作经历
        type: "all", // 职位类型 全职,兼职
        min_value: "all", // 期望薪资最小值
        max_value: "all", // 期望薪资最大值
        welfares: "all", //福利筛选
        hy: "all",
        jobWeight: "all",
      };
      this.currentpage = 1;
      this.getJobList();
    },
    fliterTalentList(type, id) {
      this.jobListFilter.page = 1;
      this.currentpage = 1;
      if (type === "pro") {
        this.jobListFilter.provinceid = id.proId;
        this.jobListFilter.cityid = id.cityId;
        this.jobListFilter.three_cityid = id.threeId;
        if (this.jobListFilter.provinceid !== 'all' || this.jobListFilter.cityid !== 'all' || this.jobListFilter.three_cityid !== 'all'){
          this.jobListFilter.is_qg = 0;
        }else {
          this.jobListFilter.is_qg = 1;
        }
      }
      this.getJobList();
    },
    toSearch(kw) {
      if (!kw || kw === "") return;
      this.jobListFilter.search_key = kw;
      if (this.jobListFilter.provinceid !== 'all' || this.jobListFilter.cityid !== 'all' || this.jobListFilter.three_cityid !== 'all'){
        this.jobListFilter.is_qg = 0;
      }else {
        this.jobListFilter.is_qg = 1;
      }
      this.jobListFilter.page = 1;
      this.getJobList();
    },
    menuEvent(to) {
      this.jobListFilter.billing = to.k;
      this.getJobList();
    },
    getJobList() {
      getJobList(this.jobListFilter)
        .then((result) => {
          console.log("获取兼职位列表", result);
          this.jobList = result.data.jobs;
          this.jobList.forEach(im=>{
            im.jobOnline = turnoverTime(im.lastupdate);
          });
          this.allPage = parseInt(result.data.allpage);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style>
.part-time-job-list-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.part-time-job-list-page .null-page-class{
  height: 500px;
  background-color: white;
  margin-top: 25px;
  padding-bottom: 200px;
}
.joblist-login-view {
  display: flex;
}

.job-list-view {
  width: 1034px;
  text-align: center;
}
</style>