<template>
  <div>
    <div v-if="isopen" class="modal">
      <div class="modal-content">
        <div class="title-dev" slot="title">
          <p class="title-dev-sapn">{{ dialogTetle }}</p>
          <img
            class="title-dev-img"
            src="../../assets/setPageIndex/dialog_close.png"
            @click="handleClose"
          />
        </div>
        <slot></slot>
        <div></div>
        <div
          slot="footer"
          class="dialog-footer"
          style="padding: 0"
          v-show="showButt === true"
        >
          <el-button
            @click="handleClose"
            class="dialog-footer-all dialog-footer-cancel"
            >取消</el-button
          >
          <el-button
            @click="dialogOk"
            type="primary"
            class="dialog-footer-all dialog-footer-confirm"
            >确定</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "isopen",
    "dialogTetle",
    "dialogClose",
    "dialogDetermine",
    "showButt",
  ],
  data() {
    return {};
  },
  methods: {
    handleClose() {
      this.dialogClose();
    },
    dialogOk() {
      this.dialogDetermine(this.dialogTetle);
    },
  },
};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
.modal-content {
  width: 680px;
  background-color: white;
  border-radius: 16px;
}
.title-dev {
  height: 79px;
  border-bottom: 3px solid #eaecf5;
}
.title-dev-sapn {
  font-size: 24px;
  
  font-weight: 600;
  color: #191919;
  line-height: 81px;
  margin-left: 34px;
}
.title-dev-img {
  width: 18px;
  height: 18px;
  float: right;
  position: relative;
  right: 24px;
  top: -45px;
  cursor: pointer;
}
.dialog-footer-cancel {
  float: left;
  background: #eaecf5;
  color: #757575;
}
.dialog-footer-confirm {
  float: right;
  background: #fe6002;
  color: #ffffff;
}
.dialog-footer-all {
  font-size: 18px;
  
  font-weight: 600;
  line-height: 20px;
  width: 160px !important;
  height: 44px !important;
  border-radius: 8px 8px 8px 8px;
}
.dialog-footer {
  width: 368px;
  height: 84px;
  /*margin: 0 auto;*/
  float: right;
  margin-right: 34px;
}
</style>