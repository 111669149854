<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2024-05-16 14:15:27
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-05-22 09:09:11
 * @FilePath: /lcwpcvue/src/components/comUser/businessAuthInInfDialog.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="auth-info-dialog">
    <el-dialog
      title="企业认证"
      :visible.sync="authDialogShow"
      :show-close="true"
      width="800px"
      @open="dialogOpne"
      @close="dialogClose"
      :destroy-on-close="true"
    >
      <div style="padding: 0 0 40px 0" v-show="['1','3'].includes(certStatusArr.cert_status)">
        <div v-show="isShowYyzz === true" class="business-auth-inf">
          <div class="ipn-title">公司全称</div>
          <div class="input-view">
            <el-autocomplete
              v-model="comName"
              :fetch-suggestions="remoteMethod"
              placeholder="请输入内容"
              @select="comNameHandleSelect"
              @input="clearInput()"
              :trigger-on-focus="false"
            >
              <span slot="suffix" class="right-text">
                修改
                <i class="el-icon-arrow-right"></i>
              </span>
            </el-autocomplete>
          </div>
          <div class="up-img-view">
            <el-upload
              class="avatar-uploader"
              :action="updateUrl"
              :show-file-list="false"
              :on-success="yyzzAvatarSuccess"
            >
              <img
                v-if="!cerObj.cert"
                class="up-img"
                src="@/assets/auth_up_zz_img.png"
                alt=""
              />
              <img
                v-if="cerObj.cert"
                class="up-img"
                :src="cerObj.cert"
                alt=""
              />
            </el-upload>
          </div>
          <div class="zysx-text">
            <div class="tt1">注意事项</div>
            <div class="tt2">1、请确认营业执照与公司<span>全称一致</span></div>
            <div class="tt2">2、请上传<span>完整清晰</span>的营业执照照片</div>
          </div>

          <div class="bot-btn-view">
            <el-button type="primary" @click="submitCer">保存</el-button>
          </div>
        </div>
        <div v-show="isShowZlxkz === true" class="business-auth-inf">
          <div class="up-img-view">
            <el-upload
              class="avatar-uploader"
              :action="updateUrl"
              :show-file-list="false"
              :on-success="zlxkAvatarSuccess"
            >
              <img
                v-if="!zlxkz"
                class="up-img"
                src="@/assets/auth_com_zlxk.png"
                alt=""
              />
              <img v-else class="up-img" :src="zlxkz" alt="" />
            </el-upload>
          </div>
          <div class="zyst-tgbtn-view">
            <div class="zysx-text">
              <div class="tt1">注意事项</div>
              <div class="tt2">
                1、请确认诊疗许可证与公司<span>全称一致</span>
              </div>
              <div class="tt2">
                2、请上传<span>完整清晰</span>的诊疗许可证图片
              </div>
            </div>
            <div class="tg-btn">
              <el-button type="text" @click="authDialogShow = false"
                >跳过>></el-button
              >
            </div>
          </div>

          <div class="bot-btn-view">
            <el-button type="primary" @click="submitZlxkz">保存</el-button>
          </div>
        </div>
      </div>
      <div v-show="certStatusArr.cert_status !== '1' && certStatusArr.cert_status !== '3'" class="cert-status-false">
        <div v-show="certStatusArr.cert_status === '0'">
          <p style="font-size: 26px;font-weight: bold;color: #222222;margin-top: 50px">营业执照审核中</p>
          <p style="color: #222222;font-size: 16px;margin: 15px 0 40px 0">客服人员正在加紧审核中，请耐心等待...</p>
        </div>
        <div v-show="certStatusArr.cert_status === '2'">
          <p style="font-size: 26px;font-weight: bold;color: #222222;margin-top: 50px">营业执照审核未通过</p>
          <p style="color: red;font-size: 16px;margin: 15px 0 40px 0">{{certStatusArr.explain}}</p>
        </div>
        <el-button
            class="cert-butt"
            type="primary"
            @click="openUploud"
        >重 新 上 传</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getCompanyInf,
  getCertStatus,
  updateCertImg,
  updateZlxkImg,
} from "@/api/compe/companyInf.js";
import requestByNotLoading from "@/utils/requestByNotLoading";
export default {
  props: {
    authDialogShow: {
      type: Boolean,
    },
  },
  data() {
    return {
      isShowYyzz: true,
      isShowZlxkz: false,
      updateUrl: process.env.VUE_APP_UPLOUD + "/api/upload/uploadCos",
      comName: "",
      company: {},
      hyClass: 0,
      zlxkz: "",
      cerObj: {
        cert: "",
        obj: "{}",
      },
      certStatusArr:{
        com_yyzz_status:'',
        cert_status:'0',
        explain:'',
        img:'',
      },
      compList: [],
    };
  },
  created() {
    this.getCompanyInf();
    this.getCertStatus();
/*    let imgCache = sessionStorage.getItem("businessAuthImg");
    if (imgCache) {
      let imgArr = JSON.parse(imgCache);
      this.zlxkz = imgArr.zlxhz;
      this.cerObj.cert = imgArr.yyzz;
    }*/
  },
  methods: {
    dialogOpne() {},
    dialogClose() {
      console.log("close");
      this.$emit("closeAuthPop");
      // this.addAddrDialogShow = false;
    },

    clearInput() {
      this.cerObj.obj = {};
    },
    remoteMethod(e, cb) {
      if (e !== "" && e.length > 1) {
        requestByNotLoading({
          url: "company/comParamsByQcc",
          method: "post",
          data: { kw: e },
        }).then((res) => {
          if (res.data.Result.length > 0){
            res.data.Result.forEach((element) => {
              element.value = element.Name;
            });
            this.compList = res.data.Result;
            cb(this.compList);
          }
        });
      }else {
        cb([]);
      }
    },
    comNameHandleSelect(item) {
      console.log("comNameHandleSelect:", item);
      this.cerObj.name = item.name = item.Name;
      delete item.value;
      delete item.Name;
      this.cerObj.obj = item;
    },
    //获取公司信息
    getCompanyInf() {
      console.log(9999)
      getCompanyInf()
        .then((result) => {
          console.log("公司信息", result);
          this.comName = result.data.info.name;
          this.hyClass = result.data.info.hy;
          this.company = result.data;
          if (this.company.info.business_obj) {
            this.cerObj.obj = this.company.info.business_obj;
            this.cerObj.obj.KeyNo = this.cerObj.obj.key_no;
            this.cerObj.obj.OperName = this.cerObj.obj.oper_name;
            this.cerObj.obj.CreditCode = this.cerObj.obj.credit_code;
            this.cerObj.obj.StartDate = this.cerObj.obj.start_date;
            this.cerObj.obj.Address = this.cerObj.obj.address;
            this.cerObj.obj.No = this.cerObj.obj.no;
            this.cerObj.obj.Status = this.cerObj.obj.status;
            delete this.cerObj.obj.key_no;
            delete this.cerObj.obj.oper_name;
            delete this.cerObj.obj.credit_code;
            delete this.cerObj.obj.start_date;
            delete this.cerObj.obj.id;
            delete this.cerObj.obj.address;
            delete this.cerObj.obj.no;
            delete this.cerObj.obj.status;
          }
        })
        .catch(() => {});
    },
    //获取公司执照审核信息
    getCertStatus() {
      getCertStatus()
        .then((result) => {
          this.certStatusArr = result.data;
          console.log("审核信息", result);
        })
        .catch(() => {});
    },
    zlxkAvatarSuccess(e) {
      if (e.code === 0){
        return this.$message.error(e.msg)
      }
      this.zlxkz = e.data.httpCosUrl;
    },
    yyzzAvatarSuccess(e) {
      if (e.code === 0){
        return this.$message.error(e.msg)
      }
      this.cerObj.cert = e.data.httpCosUrl;
    },
    //提交诊疗许可
    submitZlxkz() {
      if (this.zlxkz === "") return this.$message.error("请上传诊疗许可证");
      updateZlxkImg({ pic: this.zlxkz })
        .then((res) => {
          this.$emit('closeAuthPop');
          this.$message.success(res.msg);
        })
        .catch(() => {});
    },
    //提交
    submitCer() {
      if (this.cerObj.cert === "") return this.$message.error("请上传营业执照");
      this.cerObj.name = this.comName;
      // this.cerObj.from = "register"; 只有注册的时候才传这个
      if (this.cerObj.obj) {
        this.cerObj.obj = JSON.stringify(this.cerObj.obj);
      }
      let imgPath = {
        zlxhz: this.zlxkz,
        yyzz: this.cerObj.cert,
      };
      sessionStorage.setItem("businessAuthImg", JSON.stringify(imgPath));
      updateCertImg(this.cerObj)
        .then((result) => {
          console.log("提交营业执照", result);
          if (result.code === 200) {
            this.$message.success(result.msg);
            console.log("hyClass:", this.hyClass);
            if (["1001", "1014"].includes(this.hyClass)) {
              this.isShowYyzz = false;
              this.isShowZlxkz = true;
            }else {
              this.$emit('closeAuthPop');
            }
          }
        })
        .catch(() => {});
    },
    goBackPage() {
      if (this.isShowZlxkz === true) {
        this.isShowYyzz = true;
        this.isShowZlxkz = false;
      } else {
        this.$router.back();
      }
    },
    openUploud(){
      this.certStatusArr.cert_status = '1';
    }
  },
};
</script>

<style>
.auth-info-dialog .cert-status-false{
  width: 600px;
  height: 300px;
  margin: 0 auto;
}
.auth-info-dialog .cert-status-false .el-button{
  font-size: 18px;
  font-weight: bold;
  width: 160px;
}
</style>