<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-09-27 14:00:07
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-01-10 10:14:19
 * @FilePath: /lcwpcvue/src/components/trnasferListItem.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="take-show-list-item">
    <div
      class="tranfer-list-item"
      v-for="item in takeShowList"
      :key="item.id"
      @click="toTransferDetail(item.id)"
    >
      <div class="left-div">
        <div class="item-title">{{ item.title }}</div>
        <div class="item-all_city_name item-type_name">
          <img
            src="../../assets/takeShowList/address.png"
            class="item-type-img"
          />
          {{ item.all_city_name }}
        </div>
        <div class="item-type_name">
          <img
            src="../../assets/takeShowList/show-type.png"
            class="item-type-img"
          />
          {{ item.type_name }}
        </div>
      </div>
      <div class="right-div">
        <div>
          <span>转让费：</span>
          <i>{{ item.fee_key.key }}</i>
        </div>
        <div>
          <span>租金：</span>
          <i>{{ item.rent_key.key }}</i>
        </div>
        <div>
          <span>面积：</span>
          <i>{{ item.area_key.key }}</i>
        </div>
      </div>
      <div class="chat-div" @click.stop="sendMsg(item.id)">
        <div class="avatar-div">
          <img :src="item.avatar" />
        </div>
        <p class="chating-p">聊一聊</p>
      </div>
    </div>
    <div v-show="takeShowList.length <= 0" class="tranfer-list-item-null">
      <div class="buy-null-img">
        <img src="@/assets/coupon_none_list.png">
        <div>暂无内容</div>
      </div>
    </div>
    <el-pagination
      @current-change="pagesChage"
      background
      layout="prev, pager, next"
      :page-count="allPage"
      :current-page.sync="currentpage"
      class="pag-ina"
    >
    </el-pagination>
    <affirm-dialog
        :isopenAffirm="affirm.isopenAffirm"
        :affirmDialogClose="affirmDialogClose"
        :affirmDialogOk="affirmDialogOk"
        :affirmTitle="affirm.affirmTitle"
        :affirmMsge="affirm.affirmMsge"
        :affirmFrom="affirm.affirmFrom"
    >
      <template v-slot:icon>
        <i></i>
      </template>
    </affirm-dialog>
  </div>
</template>

<script>
import {getTakeShopLinkPhone, seekSearchNew} from "@/api/commen/takeShowList";
import AffirmDialog from "@/components/affirmDialog.vue";
export default {
  components: {AffirmDialog},
  props: {},
  data() {
    return {
      affirm:{
        affirmMsge:'',
        isopenAffirm:false,
        affirmTitle:'提示',
        affirmFrom:'',
      },
      takeShowList: [],
      searchParams: {
        page: 1,
        size: 10,
        wd: "",
        province: "",
        city: "",
        district: "",
        area: "",
        rent: "",
        transferFee: "",
        type: "",
      },
      allPage: 0,
      currentpage: 1,
    };
  },
  mounted() {
    this.getTakeList();
  },
  methods: {
    clearCondition(){
      this.searchParams.page=1;
      this.searchParams.wd='';
      this.searchParams.province='';
      this.searchParams.city='';
      this.searchParams.district='';
      // this.searchParams.type='';
      this.getTakeList();
    },
    getTakeList() {
      seekSearchNew(this.searchParams).then((req) => {
        this.takeShowList = req.data.list.list;
        this.allPage = parseInt(req.data.list.allpage);
        if (this.takeShowList.length <= 0) {
          this.$message.error("没有数据");
        }
      });
    },
    toTransferDetail(id) {
      let newRouter = this.$router.resolve({
        path:`/takeshopdetail?id=${id}`
      });
      window.open(newRouter.href,"_blank")

    },
    affirmDialogOk(from){
      console.log("lai zi:",from)
      this.affirm.isopenAffirm = false;
      if (from === 'toVip'){
        this.$router.push('/mytransfer')
      }else if (from === 'toCreate'){
        this.$router.push('/publishtransffer')
      }
    },
    affirmDialogClose(){
      this.affirm.isopenAffirm = false;
    },
    sendMsg(id) {
      console.log(id)
      let token = localStorage.getItem('token');
      console.log('token:',token);
      if (!token) {
        this.$router.push('/login');
        return;
      }
      getTakeShopLinkPhone({ id: id })
          .then((result) => {
            if (result.code == 200) {
              let status = result.data.status;
              if (status === '2') {
                this.affirm.affirmFrom = 'toCreate';
                this.affirm.affirmMsge = result.data.err;
              }else if (status === '3'){
                this.affirm.affirmFrom = 'toVip';
                this.affirm.affirmMsge = result.data.err;
              }else if (status === '1'){
                this.affirm.affirmFrom = 'ok';
                this.affirm.affirmTitle = '联系电话'
                this.affirm.affirmMsge = `<span style="color: #191919;font-size: 16px">${result.data.link_tel}</span>`;
              }
              this.affirm.isopenAffirm = true;

              // if (result.data.status == 2) {
              //   this.$confirm(result.data.err, "提示", {
              //     confirmButtonText: "确定",
              //     cancelButtonText: "取消",
              //     type: "warning",
              //   })
              //       .then(() => {})
              //       .catch(() => {});
              // }
              // if (result.data.status == 3) {
              //   this.$confirm(result.data.err, "提示", {
              //     confirmButtonText: "确定",
              //     cancelButtonText: "取消",
              //     type: "warning",
              //   })
              //       .then(() => {})
              //       .catch(() => {});
              // }
              // if (result.data.status == 1) {
              //   this.$confirm(result.data.link_tel, "提示", {
              //     confirmButtonText: "确定",
              //     cancelButtonText: "取消",
              //     type: "warning",
              //   })
              //       .then(() => {})
              //       .catch(() => {});
              // }
            }
          })
          .catch(() => {});
    },
    pagesChage(e) {
      console.log("chage", e);
      this.searchParams.page = e;
      this.getTakeList();
      window.scrollTo(380,380)
    },
  },
};
</script>

<style scoped>
.take-show-list-item .chating-p {
  height: 22px;
  font-size: 16px;
  
  font-weight: 600;
  color: #ffffff;
  line-height: 19px;
  text-align: center;
}
.take-show-list-item .avatar-div img {
  width: 88px;
  height: 88px;
  border-radius: 50%;
  margin: 13px 26px;
}
.take-show-list-item .item-type-img {
  width: 16px;
  height: 16px;
  margin-right: 3px;
}
.take-show-list-item .item-type_name {
  height: 20px;
  font-size: 14px;
  
  font-weight: 400;
  color: #757575;
  line-height: 16px;
  display: flex;
  align-items: center;
}
.take-show-list-item .item-all_city_name {
  margin-bottom: 16px;
}
.take-show-list-item .item-title {
  height: 28px;
  font-size: 22px;
  
  font-weight: 600;
  color: #191919;
  line-height: 28px;
  margin-bottom: 16px;
}
.take-show-list-item .tranfer-list-item {
  width: 986px;
  background-color: #fff;
  display: flex;
  border-radius: 16px;
  margin-top: 20px;
  padding: 0px 24px;
  position: relative;
  border: #fff 1px solid;
  cursor: pointer;
}
.take-show-list-item .shop-top-img img {
  width: 200px;
  height: 150px;
  border-radius: 8px;
}
.take-show-list-item .left-div {
  margin: 24px 0;
}
.take-show-list-item .right-div {
  margin: 24px 0;
  position: absolute;
  right: 37px;
  width: 220px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.take-show-list-item .chat-div {
  position: absolute;
  right: 0px;
  width: 140px;
  height: 148px;
  background: #fe6002;
  border-radius: 0px 16px 16px 0px;
  float: right;
  display: none;
}
.take-show-list-item .right-div span {
  height: 20px;
  font-size: 14px;
  
  font-weight: 400;
  color: #757575;
  line-height: 16px;
}
.take-show-list-item .right-div i {
  height: 22px;
  font-size: 16px;
  
  font-weight: 600;
  color: #fe6002;
  line-height: 19px;
}
.take-show-list-item .tranfer-list-item:hover {
  background-color: #fff5f0;
  border: #fe6002 1px solid;
}
.take-show-list-item .tranfer-list-item:hover .chat-div {
  display: block;
}
.take-show-list-item .tranfer-list-item:hover .right-div {
  right: 150px;
}
.take-show-list-item .tranfer-list-item-null {
  margin-top: 24px;
  background-color: #fff;
  width: 1040px;
  height: 800px;
}
.take-show-list-item .pag-ina {
  margin-top: 30px;
  text-align: center;
}
.take-show-list-item .tranfer-list-item-null .buy-null-img {
  width: 360px;
  margin: 0 auto;
  padding-top: 220px;
}
.take-show-list-item .tranfer-list-item-null .buy-null-img img{
  width: 360px;
  height: auto;
}
.take-show-list-item .tranfer-list-item-null .buy-null-img div{
  font-size: 22px;
  font-weight: 400;
  color: #757575;
  text-align: center;
  margin-top: 20px;
}
</style>