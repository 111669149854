<template>
  <div>
    <div
      class="com-list"
      v-for="(item, index) in companyList"
      :key="index"
      @click="toComDetailPage(item.uid)"
    >
      <div class="c-container containers">
        <div class="imgcon">
          <!-- <img :src="item.logo" /> -->
          <el-image class="logo" :src="item.logo"></el-image>
        </div>
        <div class="cr">
          <div class="name">{{ item.name }}</div>
          <div class="address">
            <span>{{ item.province }}</span>
            <span v-if="item.city != ''">{{ item.city }}</span>
          </div>
        </div>
      </div>
      <div class="num containers" v-if="item.job.length == 0">
        没有在热招的职位
      </div>
      <div class="num containers" v-if="item.job.length > 0">
        <img src="@/assets/hot.png" mode="" />
        正在热招
        <span style="color: #fe6002">{{ item.job.length }}个</span>
        <span class="jbname" v-if="item.job.length > 0">{{
          item.job[0].name
        }}</span
        >等相关职位
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "company-list",
  props: {
    companyList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      // errorimg: api.errorimg,
    };
  },
  created() {
    console.log(this.errorimg);
  },
  methods: {
    todetails() {
      this.$router.push();
      // uni.navigateTo({
      //   url: url,
      // });
    },
    toComDetailPage(id) {
      this.$router.push(`/h5cominfo?id=${id}`);
    },
  },
};
</script>

<style>
.com-list {
  margin-top: 14px;
  /* 	border-bottom: 12px solid #f5f6fa; */
  background-color: #ffff;
  width: 456px;
  background-color: #ffffff;
  border-radius: 8px;
  margin-bottom: 10px;
}

.c-container {
  display: flex;
  flex-wrap: nowrap;
  padding-top: 18px;
}

.cr {
  margin-left: 8px;
}

.imgcon {
  margin-top: 2px;
  width: 68px;
  height: 68px;
  border: 1px solid #e7e8eb;
  border-radius: 2px;
  margin-left: 10px;
}

.c-container .logo {
  width: 68px;
  height: 68px;
}

.c-container .name {
  max-width: 300px;
  height: 23px;
  line-height: 23px;
  font-size: 20px;
  
  font-weight: 900;
  color: #222222;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.c-container .address {
  font-size: 15px;
  
  font-weight: 400;
  color: #757575;
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.c-container .address span {
  position: relative;
  padding: 0 10px;
}

.c-container .address span:nth-child(1) {
  padding-left: 0;
}

.c-container .address span:nth-child(n + 2)::after {
  position: absolute;
  content: "";
  top: 2px;
  left: 0;
  width: 0px;
  height: 10px;
  border-left: 1px solid #757575;
}

.com-list .num {
  height: 65px;
  display: flex;
  align-items: center;
  font-size: 16px;
  
  font-weight: 400;
  color: #757575;
  margin-left: 10px;
}

.com-list .num img {
  margin-right: 8px;
  width: 20px;
  height: 20px;
}

.com-list .num .jbname {
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>