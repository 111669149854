/*
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2024-05-22 09:08:17
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-06-20 18:20:02
 * @FilePath: /lcwpcvue/src/api/commen/resume.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from "@/utils/request";

// 我的投递
export function sendAnnex(page) {
    return request({
        url: 'member/sendAnnex',
        method: 'post',
        data:{
            page:page
        }
    })
}
// 沟通过
export function chatListV2(page,keyword) {
    return request({
        url: 'chat/chatListV2',
        method: 'post',
        data:{
            page: page,
            keyword: keyword
        }
    })
}
// 待面试 category:1未开始 2已结束
export function getInterviewList(page,category) {
    return request({
        url: 'Interview/getInterviewList',
        method: 'post',
        data:{
            page:page,
            category:category
        }
    })
}
// 浏览记录
export function userLookJob(page) {
    return request({
        url: 'member/userLookJob',
        method: 'post',
        data:{
            page:page
        }
    })
}
// 我的收藏 column ：job职位 company企业
export function memberCollectionList(page,column) {
    return request({
        url: 'Collection/memberCollectionList',
        method: 'post',
        data:{
            page:page,
            column:column
        }
    })
}
// 谁浏览了我
export function seeMeNew(page) {
    return request({
        url: 'Interaction/seeMeNew',
        method: 'get',
        params:{
            page:page
        }
    })
}
// 取消面试
export function cancelInterview(id) {
    return request({
        url: 'Interview/cancelInterview',
        method: 'post',
        data:{
            inter_id:id,
            cancel_reason:'我想取消面试'
        }
    })
}
// accept同意或拒refuse绝面试
export function acceptInterview(id,status) {
    return request({
        url: 'Interview/acceptInterview',
        method: 'post',
        data:{
            inter_id: id,
            status: status
        }
    })
}

// 个人中心数据
export function myCenterParams() {
    return request({
        url: 'v2/resume/getActivityMessage',
        method: 'get',
    })
}