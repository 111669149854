<template>
  <div class="h5-findjob-list-page">
    <div class="nologin-ser">
      <div class="no-search">
        <el-input
          v-model="talentListData.search_key"
          placeholder="请输入搜索内容"
          class="input-with-select"
        >
          <el-button
            @click="toSearch"
            slot="append"
            icon="el-icon-search"
            type="primary"
            >搜索</el-button
          >
        </el-input>
      </div>
    </div>
    <div class="h5-home-banner-view">
      <el-carousel height="100px">
        <el-carousel-item v-for="(item, index) in Banners" :key="index">
          <!-- <img class="" :src="item.banner_image" /> -->
          <el-image
            class="h5-home-banner-img"
            :src="item.banner_image"
            fit="fit"
          ></el-image>
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="condition">
      <div class="now-around">
        <span class="current" slot="reference" @click="isyxzw = true">{{
          jobname
        }}</span>
        <!-- <div>
            <div class="title">选择你的职位类型</div>
            <div class="province">
              <label v-for="(item, index) in List" :key="item.id">
                <div
                  :data-id="item.id"
                  :data-val="item.name"
                  :data-index="index"
                  @click="tosecond"
                  class="sf-view"
                  :class="checkindex == index ? 'current' : ''"
                >
                  <span
                    :data-id="item.id"
                    :data-val="item.name"
                    :data-index="index"
                    :class="checkindex == index ? 'current' : ''"
                    @click="tosecond"
                    >{{ item.name }}</span
                  >
                  <div v-if="!onejob && item.countnum > 0" class="num-jb">
                    {{ item.countnum }}
                  </div>
                </div>
              </label>
            </div>
            <div class="city" :class="onejob ? 'top-100' : 'top-80'">
              <span
                v-for="item in next_list"
                :key="item.id"
                :data-id="item.id"
                :data-keyid="item.keyid"
                :data-val="item.name"
                :class="twicecheck == item.id ? 'current' : ''"
                @click="choice"
              >
                {{ item.name }}
              </span>
            </div>

            <div class="province" :class="onejob ? 'top-100' : 'top-80'">
              <label v-for="(item, index) in List" :key="item.id">
                <span
                  :data-id="item.id"
                  :data-val="item.name"
                  :data-index="index"
                  :class="checkindex == index ? 'current' : ''"
                  @click="tosecond"
                  >{{ item.name }}</span
                >
              </label>
            </div>
            <div class="city" :class="onejob ? 'top-100' : 'top-80'">
              <span
                v-for="item in next_list"
                :key="item.id"
                :data-id="item.id"
                :data-val="item.name"
                :class="twicecheck == item.id ? 'current' : ''"
                @click="choice"
              >
                {{ item.name }}
              </span>
            </div>
            <div class="foots_btn" v-if="!onejob"
              ><button @click="confirm">确定</button></div
            >
          </div> -->
      </div>
      <div class="fr">
        <span @click="isCityOneShow = true">{{ cityval }}</span>
        <!-- <span>筛选</span> -->
      </div>
    </div>

    <!-- 求职端列表 -->

    <!-- <div>
      <ul v-infinite-scroll="load">
        <usercard :jobsList="jobs_List"></usercard>
      </ul>
    </div> -->
    <!-- 招聘方列表 -->
    <div v-infinite-scroll="load">
      <user1card :jobsList="jobs_List"></user1card>
    </div>
    <openmini></openmini>
    <utabbar activeIndex="/h5findtalentlist"></utabbar>

    <h5chosezw
      :dialogVisible1="isyxzw"
      @closeDilog1="closeDilog1"
      @saveJobArr="saveYxJobArr"
    ></h5chosezw>
    <h5chosecity
      :dialogVisible2="isCityOneShow"
      @closeDilog2="closeDilog2"
      @saveJobAddr="saveJobAddrOne"
    ></h5chosecity>
  </div>
</template>

<script>
import utabbar from "@/components/h5Components/uTabbar.vue";
import openmini from "@/components/h5Components/h5TominPro.vue";
import { getH5BannerList } from "@/api/commen/homeApi.js";
import { getTalentsList } from "@/api/commen/aboutTalentsApi";
import user1card from "@/components/h5Components/user1-card.vue";
import h5chosezw from "@/components/h5Components/h5ChoseZwtypeCom.vue";
import h5chosecity from "@/components/h5Components/h5ChoseCityOne.vue";
export default {
  components: {
    utabbar,
    user1card,
    h5chosezw,
    h5chosecity,
    openmini,
  },
  data() {
    return {
      Banners: [],
      current: 2,
      cityval: "区域",
      jobname: "职位类别",
      jobs_List: [],
      talentListData: {
        page: 1, // 页码
        provinceid: "all", // 省
        cityid: "all", // 市
        three_cityid: "all", // 区
        job_id: "all", // 职业, 例: 宠物医生
        search_key: "", // 搜索内容
        edu: "all", // 教育经历
        exp: "all", // 工作经历
        type: "all", // 职位类型
        min_value: "all", // 期望薪资最小值
        max_value: "all", // 期望薪资最大值
        job_type_id: "all",
        sex: "all",
        shows: "all",
      },
      allpage: 0,
      loading: false,
      isyxzw: false,
      isCityOneShow: false,
      // isInfiniteScrollDisabled: false,
    };
  },
  created() {
    if (this.$route.query.jobclassid) {
      this.talentListData.jobclass_id = this.$route.query.jobclassid;
      this.jobname = this.$route.query.name;
    }
    this.getHomeBannerList();
    this.getTalentList();
  },
  computed: {
    noMore() {
      return this.joblistData.page >= this.allpage;
    },
    isInfiniteScrollDisabled() {
      return this.loading;
    },
  },
  methods: {
    /* 获取banner图 */
    getHomeBannerList() {
      // if (this.usertype == 1) {
      //   type = "resume";
      // } else {
      //   type = "company";
      // }
      getH5BannerList({
        type: "company",
        position: "joblist",
        cid: "",
      })
        .then((result) => {
          console.log("获取banner", result);
          this.Banners = result.data.banners;
        })
        .catch(() => {});
    },
    getTalentList() {
      getTalentsList(this.talentListData)
        .then((result) => {
          console.log("人才", result);
          this.allpage = result.data.allpage;
          if (this.page == 1) {
            this.jobs_List = result.data.jobs;
          } else {
            this.jobs_List = this.jobs_List.concat(result.data.jobs);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    load() {
      console.log("0000");
      this.loading = true;
      if (this.talentListData.page < this.allpage) {
        this.talentListData.page = this.talentListData.page + 1;
        this.getTalentList();
        this.loading = false;
      }
    },
    toSearch() {
      this.jobs_List = [];
      this.getTalentList();
    },
    closeDilog1() {
      this.isyxzw = false;
    },
    closeDilog2() {
      this.isCityOneShow = false;
    },
    saveYxJobArr(e) {
      console.log("职位", e);
      this.talentListData.page = 1;
      this.talentListData.job_id = e.keyid;
      this.talentListData.jobclass_id = e.id;
      this.jobname = e.name;
      this.jobs_List = [];
      this.getTalentList();
      this.isyxzw = false;
    },
    saveJobAddrOne(e) {
      console.log("城市", e);
      this.talentListData.provinceid = e.keyid;
      this.talentListData.cityid = e.id;
      this.talentListData.page = 1;
      this.jobs_List = [];
      this.cityval = e.name;
      this.getTalentList();

      this.isCityOneShow = false;
    },
  },
};
</script>

<style>
</style>