<template>
  <div class="edtPrivacy-v">
    <div style="width: 100%; margin-top: 25px">
      <div class="common-title-view">
        <span class="left-xian-view"></span>
        <span class="text">隐私设置</span>
      </div>
    </div>

    <div class="hide-resu-name">
      <div class="hide-resu-name-content">
        <span class="hide-resu-name-title">隐藏我的简历</span>
        <el-switch
          v-model="isShowResume"
          active-color="#fe6002"
          inactive-color="#919191"
          class="hide-resu-name-switch"
          @change="openAffirmResumeStatus('showResume')"
        >
        </el-switch>
        <p class="hide-resu-name-desc">
          简历开启隐藏后，招聘单位将看不到你的简历信息
        </p>
      </div>
    </div>

    <div class="hide-resu-name">
      <div class="hide-resu-name-content">
        <span class="hide-resu-name-title">真实姓名保护</span>
        <el-switch
          v-model="isShowFullName"
          active-color="#fe6002"
          inactive-color="#919191"
          class="hide-resu-name-switch"
          @change="openAffirmResumeStatus('fullName')"
        >
        </el-switch>
        <p class="hide-resu-name-desc">
          开启后，对外显示名称将展示“x女士/先生”
        </p>
      </div>
    </div>

    <div class="hide-calltime">
      <div class="hide-resu-name-content">
        <div class="hide-calltime-content">
          <span class="hide-resu-name-title">建议联系时间</span>
          <p class="hide-resu-name-desc">你方便接听电话的时间展示给招聘者</p>
          <button
            v-for="item in callTime.user_linktime"
            :key="item.id"
            class="hide-calltime-commen"
            :class="
              item.id === callTime.status
                ? 'hide-calltime-choose-this'
                : 'hide-calltime-choose'
            "
            @click="setCallTime(item.id)"
          >
            <span
              v-if="item.id === callTime.status && callTime.status === '1152'"
            >
              {{ callTime.body }}
            </span>
            <span v-else>{{ item.name }}</span>
          </button>
        </div>
      </div>
    </div>

    <div class="hide-resu-name">
      <div class="hide-resu-name-content">
        <span class="hide-resu-name-title">屏蔽企业</span>
        <p class="hide-com-desc">
          屏蔽企业后，该企业将不会再各个入口看见你的简历
        </p>
        <div class="hide-com-add" @click="openAddShield">
          <i class="el-icon-plus hide-com-add-butt"></i>
          <span class="hide-com-add-butt">添加</span>
        </div>
        <div style="max-height: 400px; overflow: auto">
          <div v-for="im in shieldList" :key="im.id" class="hide-com-havehide">
            <div style="float: left">{{ im.com_name }}</div>
            <div style="float: right" @click="deleteShield(im.id)">
              <i
                class="el-icon-delete"
                style="color: #747474; cursor: pointer; margin-right: 10px"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <comDialog
      :isopen="isShowShieldChoose"
      dialogTetle="添加屏蔽企业"
      @dialogFrameEvent="shieldChooseEvent"
      myWidth="680px"
    >
      <div class="shield-choose">
        <el-input
          v-model="searchCompany"
          placeholder="搜索您要屏蔽的企业"
          clearable
          @input="shieldInputChange"
        >
        </el-input>
        <div
          v-show="searchCompany.length <= 1"
          style="
            font-size: 16px;
            color: #757575;
            line-height: 25px;
            margin-top: 20px;
          "
        >
          <p style="margin-bottom: 5px">快速屏蔽公司指南：</p>
          <p>1、搜索公司全称，如“上海猎宠网有限公司”</p>
          <p>2、搜索公司关键词，如“猎宠网”</p>
        </div>
        <div v-show="searchCompany.length > 1">
          <p v-show="searchCompanyList.length !== 0" class="shield-choose-hint">
            共搜索到以下相关企业
          </p>
          <p
            v-show="searchCompanyList.length === 0"
            class="shield-choose-hint"
            style="color: #fe6002"
          >
            未搜到相关企业，换个名称试一下...
          </p>
          <div
            v-for="(item, idx) in searchCompanyList"
            :key="idx"
            style="border-bottom: 1px solid rgba(230, 230, 230, 0.5)"
          >
            <span class="shield-choose-comname" v-html="item.htmlName"></span>
            <span
              v-show="item.have == '0'"
              class="shield-choose-butt"
              @click="addShield(item)"
              >屏蔽</span
            >
            <span
              v-show="item.have != '0'"
              class="shield-choose-butt"
              @click="deleteShield(item.have)"
              >解除屏蔽</span
            >
          </div>
        </div>
      </div>
    </comDialog>
    <AffirmDialog
      :isopen-affirm="affirm.isOpenResumeAffirm"
      :affirm-dialog-close="affirmResumeClose"
      :affirmDialogOk="affirmDialogResumeOk"
      affirmTitle="提醒"
      affirmMsge="简历开启隐藏，招聘单位将无法看到你的简历信息，是否要开启隐藏简历？"
    ></AffirmDialog>
    <AffirmDialog
      :isopen-affirm="affirm.isOpenFullNameAffirm"
      :affirm-dialog-close="affirmFullNameClose"
      :affirmDialogOk="affirmDialogFullNameOk"
      affirmTitle="提醒"
      affirmMsge="真实姓名保护开启，对外显示名称将展示“x女士/先生”，是否要开启真实姓名保护？"
    ></AffirmDialog>
    <AffirmDialog
      :isopen-affirm="affirm.isShowCallTimeAffirm"
      :affirm-dialog-close="callTimeAffirmClose"
      :affirmDialogOk="callTimeAffirmok"
      affirmTitle="建议联系时间"
      affirmMsge=""
    >
      <template v-slot:icon><i></i></template>
      <template v-slot:content>
        <el-input
          v-model="callTime.body"
          placeholder="8:00-9:00或者18:30以后"
          clearable
          maxlength="16"
        ></el-input>
        <span style="color: #f34b37; font-size: 14px">最多可填写16个字</span>
      </template>
    </AffirmDialog>
  </div>
</template>

<script>
import {
  linkTime,
  getshieldList,
  delShield,
  isShowName,
  myResumeStatus,
  editResumeStatus,
  submitShield,
} from "@/api/commen/setApi";
import { searCompName } from "@/api/commen/register";
import comDialog from "@/components/comDialog.vue";
import AffirmDialog from "@/components/affirmDialog.vue";

export default {
  name: "edtPrivacy",
  components: {
    AffirmDialog,
    comDialog,
  },
  data() {
    return {
      affirm: {
        isOpenResumeAffirm: false,
        isOpenFullNameAffirm: false,
        isShowCallTimeAffirm: false,
      },
      searchCompanyList: [],
      searchCompany: "",
      isShowFullName: "",
      isShowResume: "",
      hideResume: true,
      callTime: {
        status: 0,
        body: "",
      },
      isShowCallTimeDia: false,
      isShowShieldChoose: false,
      shieldList: [],
    };
  },
  computed: {
    getShieldUids() {
      return this.shieldList.map((obj) => obj.com_uid);
    },
  },
  mounted() {
    this.getCallTime(0, 0);
    this.selectShieldList();
    this.getMyResumeStatus();
    this.getIsShowName();
  },
  methods: {
    callTimeAffirmClose() {
      this.affirm.isShowCallTimeAffirm = false;
    },
    callTimeAffirmok() {
      this.getCallTime("1152", this.callTime.body);
      this.affirm.isShowCallTimeAffirm = false;
    },
    openAddShield() {
      this.isShowShieldChoose = true;
    },
    shieldChooseEvent(from) {
      this.isShowShieldChoose = false;
      console.log(from);
    },
    affirmResumeClose() {
      this.isShowResume = !this.isShowResume;
      this.affirm.isOpenResumeAffirm = false;
    },
    affirmDialogResumeOk() {
      this.setResumeStatus("showResume");
      this.affirm.isOpenResumeAffirm = false;
    },
    affirmFullNameClose() {
      this.isShowFullName = !this.isShowFullName;
      this.affirm.isOpenFullNameAffirm = false;
    },
    affirmDialogFullNameOk() {
      this.setResumeStatus("fullName");
      this.affirm.isOpenFullNameAffirm = false;
    },
    openAffirmResumeStatus(from) {
      if (from === "showResume") {
        if (this.isShowResume === true) {
          this.affirm.isOpenResumeAffirm = true;
        } else {
          this.setResumeStatus("showResume");
        }
      } else if (from === "fullName") {
        if (this.isShowFullName === true) {
          this.affirm.isOpenFullNameAffirm = true;
        } else {
          this.setResumeStatus("fullName");
        }
      }
    },
    setResumeStatus(from) {
      if (from === "showResume") {
        let resuStatus = this.isShowResume === true ? "0" : "1";
        editResumeStatus(resuStatus).then((res) => {
          this.$message.success(res.msg);
          this.getMyResumeStatus();
        });
      } else if (from === "fullName") {
        let showFullName = this.isShowFullName === true ? "1" : "0";
        isShowName({ status: showFullName }).then((res) => {
          this.$message.success(res.msg);

          if (res.data.status === "1") {
            this.isShowFullName = true;
          } else {
            this.isShowFullName = false;
          }
        });
      }
    },
    getMyResumeStatus() {
      myResumeStatus().then((res) => {
        if (res.data.status === "0") {
          this.isShowResume = true;
        } else {
          this.isShowResume = false;
        }
      });
    },
    getIsShowName() {
      isShowName({}).then((res) => {
        console.log("隐藏姓名", res);
        if (res.data.status === "1") {
          this.isShowFullName = true;
        } else {
          this.isShowFullName = false;
        }
      });
    },
    selectShieldList() {
      getshieldList().then((res) => {
        this.shieldList = res.data.list;
      });
    },
    getCallTime(status, body) {
      linkTime(status, body).then((res) => {
        this.callTime = res.data;
        console.log("callTime:", this.callTime);
      });
    },
    setCallTime(id) {
      console.log(id);
      if (id === "1152") {
        // this.isShowCallTimeDia = true;
        this.affirm.isShowCallTimeAffirm = true;
      } else {
        this.getCallTime(id, "");
      }
    },
    shieldInputChange(e) {
      this.searchCompanyList = [];
      if (e === "") return;
      if (e.length <= 1) return;
      searCompName({ com_name: this.searchCompany, page: 1 }).then((res) => {
        let resData = res.data.list;
        if (resData.length > 0) {
          resData.forEach((item) => {
            let content = item.name.replace(
              new RegExp(this.searchCompany, "gi"),
              '<span class="highlighted">$&</span>'
            );
            let isHave = 0;
            if (this.getShieldUids && this.getShieldUids.includes(item.uid)) {
              this.shieldList.forEach((shielditem) => {
                if (shielditem.com_uid === item.uid) {
                  isHave = shielditem.id;
                }
              });
            }
            this.searchCompanyList.push({
              name: item.name,
              htmlName: content,
              uid: item.uid,
              have: isHave,
            });
          });
        }
      });
    },
    addShield(item) {
      submitShield(item.uid, item.name).then((res) => {
        this.selectShieldList();
        this.refreshComList();
        this.$message.success(res.msg);
      });
    },
    deleteShield(id) {
      delShield(id).then((res) => {
        this.$message.success(res.msg);
        this.selectShieldList();
        this.refreshComList();
      });
    },
    refreshComList() {
      setTimeout(() => {
        this.shieldInputChange(this.searchCompany);
      }, 100);
    },
  },
};
</script>

<style>
.edtPrivacy-v .highlighted {
  color: #fe6002;
}

.edtPrivacy-v .shield-choose-comname {
  font-weight: 400;
  font-size: 18px;
  color: #191919;
  line-height: 50px;
}

.edtPrivacy-v .shield-choose-butt {
  font-weight: 400;
  font-size: 16px;
  color: #fe6002;
  line-height: 50px;
  float: right;
  cursor: pointer;
}

.edtPrivacy-v .shield-choose-hint {
  font-weight: 400;
  font-size: 16px;
  color: #757575;
  line-height: 19px;
  margin: 12px 0 8px 0;
}

.edtPrivacy-v .el-input .el-input__inner {
  height: 47px;
  font-weight: 400;
  font-size: 18px;
  color: #191919;
  line-height: 24px;
  text-align: left;
}

.edtPrivacy-v .shield-choose {
  border-top: 2px solid rgba(230, 230, 230, 0.8);
  padding: 26px 34px;
}

.edtPrivacy-v .hide-com-havehide {
  height: 56px;
  border-bottom: 2px solid rgba(230, 230, 230, 0.5);
  line-height: 56px;
}

.edtPrivacy-v .hide-com-desc {
  font-weight: 400;
  font-size: 16px;
  color: #f34b37;
  line-height: 19px;
  margin: 16px 0 30px 0;
}

.edtPrivacy-v .hide-com-add {
  position: absolute;
  top: 4px;
  right: 4px;
  cursor: pointer;
}

.edtPrivacy-v .hide-com-add-butt {
  font-weight: 600;
  font-size: 16px;
  color: #fe6002;
  line-height: 19px;
  text-align: left;
}

.edtPrivacy-v .hide-resu-name {
  margin: 16px 0;
  width: 1196px;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  overflow: hidden;
}

.edtPrivacy-v .hide-resu-name-content {
  width: 1148px;
  margin: 20px 24px;
  position: relative;
}

.edtPrivacy-v .hide-resu-name-title {
  font-weight: 600;
  font-size: 22px;
  color: #191919;
  line-height: 28px;
  margin-bottom: 16px;
}

.edtPrivacy-v .hide-resu-name-switch {
  float: right;
  margin: 10px 10px 0px 0;
}

.edtPrivacy-v .hide-resu-name-desc {
  width: 623px;
  height: 18px;
  font-weight: 400;
  font-size: 16px;
  color: #939597;
  line-height: 19px;
  margin-top: 18px;
}

.edtPrivacy-v .hide-calltime {
  width: 1196px;
  height: 206px;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  overflow: hidden;
}

.edtPrivacy-v .hide-calltime-commen {
  margin: 32px 10px 0 0;
  width: 260px;
  height: 48px;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  cursor: pointer;
}

.edtPrivacy-v .hide-calltime-choose {
  background: #f5f6fa;
  border: 1px solid #cccccc;
  color: rgba(0, 0, 0, 0.5);
}

.edtPrivacy-v .hide-calltime-choose-this {
  background: #ffdbc5;
  border: 1px solid #fe6002;
  color: #fe6002;
}

.edtPrivacy-v .hide-calltime-body {
  width: 260px;
  line-height: 46px;
  display: inline-block;
  border-bottom: 1px solid #cccccc;
  text-align: center;
  color: #fe6002;
  font-size: 20px;
}

.edtPrivacy-v .comdialog-choose {
  width: 90%;
  margin: 0 auto;
  margin-top: 20px;
}

.edtPrivacy-v {
}
</style>