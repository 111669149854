/*
 * @Author: your name
 * @Date: 2021-05-24 11:26:33
 * @LastEditTime: 2024-04-23 15:38:58
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /YongHuaShengDa/vue-admin-template/src/store/getters.js
 */
const getters = {
  usertype: state => state.user.usertype,
  sidebar: state => state.app.sidebar,
  device: state => state.app.device,
  token: state => state.user.token,
  userinf: state => state.user.userinf,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  uid: state => state.user.uid,
  edtime: state => state.user.edtime,
  userip: state => state.user.userip,
  isfisterlogin: state => state.user.userip.isfisterlogin,
  socketbindid: state => state.user.socketbindid,
  wdmsgnumber: state => state.user.wdmsgnumber,
  headnavmenindex: state => state.user.headnavmenindex
}
export default getters
