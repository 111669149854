<template>
  <div>
    <div v-if="isOpenExiste" class="modal">
      <div class="modal-content">
        <p class="modal-content-title">手机号码已被绑定</p>
        <p class="modal-content-conter">
          手机号已被<span>{{existeArr.alias}}</span>绑定，请核实输入的信息的准确性，如无误，继续保存将解除与旧账号的绑定，是否确认继续保存？
        </p>
        <div class="modal-content-but">
          <input type="button" class="modal-content-butfalse modal-content-butcom" value="换手机号" @click="affirmPhone(false)">
          <input type="button" class="modal-content-buttrue modal-content-butcom" value="继续保存" @click="affirmPhone(true)">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["isOpenExiste",'existeArr'],
  data() {
    return {

    };
  },
  methods: {
    affirmPhone(isOk){
      this.$emit('buttCallback',isOk)
    }
  }
};
</script>

<style scoped>
.modal-content-but{
  display: flex;
  justify-content: flex-end;
}
.modal-content-but input:hover{
  background-color: #FE6002;
  color: white;
}
.modal-content-butcom{
  height: 44px;
  width: 140px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  color: #757575;
  background-color: #EAECF5;
  font-size: 18px;
  
  font-weight: 600;
}
.modal-content-buttrue{
  margin-left: 10px;
}
.modal-content-title{
  font-size: 24px;
  
  font-weight: 600;
  color: #191919;
}
.modal-content-conter{
  
  /*color: #939597;*/
  color: #717171;
  margin: 36px 0;
  line-height: 30px;
}
.modal-content-conter span{
  color: #FE6002;
  margin: 0 5px;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-content {
  width: 580px;
  background-color: white;
  border-radius: 8px;
  padding: 36px 24px;
}
</style>