<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-08-30 11:10:55
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-06-05 19:15:26
 * @FilePath: /lcwpcvue/src/components/individualUser/companyInJobInf.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="company-jobinf">
    <div class="top-log-name-rz-view">
      <img class="com-log-img" :src="companyInf.logo" alt="" />
      <div class="name-rz" @click="toCompanyDetail">
        <div class="name">{{ companyInf.com_name }}</div>
        <img src="../../assets/company_rz_img.png" alt="" class="rz-img" />
      </div>
    </div>
    <div class="type-num-ear">
      <span>{{ companyInf.hy_name }}</span>
      <el-divider direction="vertical"></el-divider>
      <span>{{ companyInf.mun_name }}</span>
      <span v-show="companyInf.area">
        <el-divider direction="vertical"></el-divider>
        <span>{{companyInf.area}}m²</span>
      </span>
    </div>
    <div class="com-mm-img">
      <el-carousel height="150px" v-show="imgurls.length > 0">
        <el-carousel-item
          v-for="item in imgurls"
          :key="item"
          indicator-position="none"
        >
          <img :src="item" alt="" @click="toCompanyDetail" />
        </el-carousel-item>
      </el-carousel>
      <img v-show="imgurls.length <= 0" src="../../assets/company/job-detail-show-null.jpg" alt="" @click="toCompanyDetail" />
    </div>
    <div class="bot-btn">
      <el-button class="com-zy-btn" plain @click="toCompanyDetail"
        >公司主页</el-button
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "jobinfcardinjobinf",
  props: {
    companyInf: {
      type: Object,
    },
    imgurls: {
      type: Array,
    },
  },
  created() {
    console.log("ppp", this.companyInf);
  },
  methods: {
    toCompanyDetail() {
      this.$router.push(`/companyinf?id=${this.companyInf.uid}`);
    },
  },
};
</script>

<style>
.company-jobinf {
  background-color: #fff;
  width: 361px;
  height: 370px;
  padding: 24px 21px;
  border-radius: 8px;
}
.top-log-name-rz-view {
  display: flex;
  align-items: center;
}
.top-log-name-rz-view .com-log-img {
  width: 60px;
  height: 60px;
  border-radius: 6px;
}
.top-log-name-rz-view .name-rz {
  font-size: 18px;

  font-weight: 600;
  color: #191919;
  line-height: 28px;
  margin-left: 14px;
}
.top-log-name-rz-view .name-rz img {
  width: 50px;
  height: 17px;
}
.type-num-ear {
  font-size: 14px;

  font-weight: 400;
  color: #757575;
  line-height: 16px;
  margin-top: 16px;
}
.com-mm-img img {
  width: 361px;
  height: 202px;
  margin-top: 16px;
  border-radius: 10px;
}
.bot-btn {
  width: 100%;
  text-align: center;
  margin-top: 20px;
}
.bot-btn .com-zy-btn {
  width: 120px;
  height: 36px;
  border: 1px solid #fe6002;
  font-size: 14px;

  font-weight: 400;
  color: #fe6002;
}
</style>