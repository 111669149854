<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2024-06-05 11:30:11
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-06-20 19:08:16
 * @FilePath: /lcwpcvue/src/components/comUser/companyInteraction.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="company-interaction-page">
    <div style="height: 30px"></div>
    <div class="common-title-view" style="margin-bottom: 15px">
      <span class="left-xian-view"></span>
      <span class="text">互动</span>
    </div>
    <div class="coupon-navbar-view">
      <div class="nav-item" @click="changeNavBarId('1')">
        <div class="nav-text" :class="navbarId == '1' ? 'nav-sltext' : ''">
          我的收藏
        </div>
        <div v-show="navbarId == '1'" class="bot-xian"></div>
      </div>
      <div class="nav-item" @click="changeNavBarId('2')">
        <div class="nav-text" :class="navbarId == '2' ? 'nav-sltext' : ''">
          沟通过
        </div>
        <div v-show="navbarId == '2'" class="bot-xian"></div>
      </div>
      <div class="nav-item" @click="changeNavBarId('3')">
        <div class="nav-text" :class="navbarId == '3' ? 'nav-sltext' : ''">
          谁看过我
        </div>
        <div v-show="navbarId == '3'" class="bot-xian"></div>
      </div>
      <div class="nav-item" @click="changeNavBarId('4')">
        <div class="nav-text" :class="navbarId == '4' ? 'nav-sltext' : ''">
          我的浏览记录
        </div>
        <div v-show="navbarId == '4'" class="bot-xian"></div>
      </div>
    </div>

    <div
      class="resume-marage-flter-view"
      v-if="navbarId == '1' || navbarId == '2'"
    >
      <div class="flter-job-name">
        <div class="flter-name">
          <div>
            <el-input
              class="flter-name-input"
              placeholder="请输入姓名查找"
              v-model="userName"
            >
              <el-button
                @click="toFlterByUsername"
                slot="append"
                icon="el-icon-search"
              ></el-button>
            </el-input>
          </div>
        </div>
      </div>
    </div>

    <div class="list-view" v-if="navbarId == 1">
      <div v-show="collectionList.length > 0">
        <collectionuser :listType="'collection'" :listArr="collectionList">
        </collectionuser>
      </div>
      <div v-show="collectionList.length == 0" class="nocoupon-list-view">
        <img class="none-list-img" src="@/assets/coupon_none_list.png" alt="" />
        <div class="none-text">当前暂无收藏</div>
      </div>
    </div>
    <div v-if="navbarId == 2">
      <chateduser :listType="'chated'" :listArr="chatUserList"> </chateduser>
      <div v-show="chatUserList.length == 0" class="nocoupon-list-view">
        <img class="none-list-img" src="@/assets/coupon_none_list.png" alt="" />
        <div class="none-text">暂无内容</div>
      </div>
    </div>
    <div v-if="navbarId == 3">
      <chateduser
          :listType="'wlook'"
          :listArr="chatUserList"
          @updateList="updateList"
      >
      </chateduser>
      <div v-show="chatUserList.length == 0" class="nocoupon-list-view">
        <img class="none-list-img" src="@/assets/coupon_none_list.png" alt="" />
        <div class="none-text">暂无内容</div>
      </div>
    </div>
    <div v-if="navbarId == 4">
      <chateduser :listType="'mlook'" :listArr="chatUserList"> </chateduser>
      <div v-show="chatUserList.length == 0" class="nocoupon-list-view">
        <img class="none-list-img" src="@/assets/coupon_none_list.png" alt="" />
        <div class="none-text">暂无内容</div>
      </div>
    </div>

    <el-pagination
      @current-change="pagesChage"
      background
      layout="prev, pager, next"
      :page-count="allPage"
      :current-page.sync="currentpage"
      class="pag-ina"
    >
    </el-pagination>
  </div>
</template>

<script>
import {
  getComColletUserList,
  getChatedUserList,
  getWolookMeList,
  getMelookedList,
} from "@/api/commen/aboutUserIndexApi.js";
// import {  } from "@/api/commen/aboutUserIndexApi.js";
import { formatnyr } from "@/utils/index.js";
import collectionuser from "@/components/comUser/comChatedUserItem.vue";
import chateduser from "@/components/comUser/comChatedUserItem.vue";
export default {
  components: {
    collectionuser,
    chateduser,
  },
  data() {
    return {
      navbarId: 1,
      collectionList: [],
      chatUserList: [],
      pageIndex: 1,
      allPage: 0,
      currentpage: 0,
      userName: "",
    };
  },
  created() {
    if (this.$route.query.tabid) {
      this.navbarId = this.$route.query.tabid;
    }
    if (this.navbarId == 1) {
      this.getComColletUserList();
    } else if (this.navbarId == 2) {
      this.getChatedUserList();
    }
  },
  methods: {
    updateList(){
      console.log("通知更新列表")
      this.getWolookMeList();
    },
    changeNavBarId(e) {
      this.navbarId = e;
      this.pageIndex = 1;
      this.userName = "";
      this.currentpage = 1;
      if (e == 1) {
        this.getComColletUserList();
      } else if (e == 2) {
        this.getChatedUserList();
      } else if (e == 3) {
        this.chatUserList = [];
        this.getWolookMeList();
      } else if (e == 4) {
        this.chatUserList = [];
        this.getMelookedList();
      }
    },

    toFlterByUsername() {
      if (!this.userName) {
        return;
      }
      if (this.navbarId == 1) {
        this.getComColletUserList();
      } else {
        this.getChatedUserList();
      }
    },

    getComColletUserList() {
      getComColletUserList({
        page: this.pageIndex,
        keyword: this.userName,
      })
        .then((result) => {
          console.log("收藏的简历", result);
          this.allPage = parseInt(result.data.allpage);
          result.data.list.forEach((element) => {
            element.sex_name = element.sex;
            element.city_class = element.city_class_name;
            element.job_class = element.job_class_name;
          });
          this.collectionList = result.data.list;
        })
        .catch(() => {});
    },
    getChatedUserList() {
      getChatedUserList({
        page: this.pageIndex,
        keyword: this.userName,
      })
        .then((result) => {
          console.log("沟通过的", result);
          this.allPage = parseInt(result.data.allpage);
          this.chatUserList = result.data.list;
          result.data.list.forEach((element) => {
            element.time =
              formatnyr(element.time).md;
          });
        })
        .catch(() => {});
    },
    getWolookMeList() {
      getWolookMeList({
        page: this.pageIndex,
      })
        .then((result) => {
          console.log("谁看过我", result);
          this.allPage = parseInt(result.data.allpage);
          this.chatUserList = result.data.list;
          result.data.list.forEach((element) => {
            element.sex_name = element.sex;
            element.time =
              formatnyr(element.createtime).md;
          });
        })
        .catch(() => {});
    },

    /* 我的浏览记录 */
    getMelookedList() {
      getMelookedList({
        page: this.pageIndex,
      })
        .then((result) => {
          console.log("我的浏览记录", result);
          // this.allPage = parseInt(result.data.allpage);
          this.chatUserList = result.data.list;
          this.allPage = parseInt(result.data.allpage);
          result.data.list.forEach((element) => {
            element.time =
              formatnyr(element.createtime).md;
          });
        })
        .catch(() => {});
    },

    pagesChage(e) {
      console.log("chage", e);
      this.pageIndex = e;
      window.scrollTo(200, 200);
      if (this.navbarId == 1) {
        this.getWolookMeList();
      } else if (this.navbarId == 2) {
        this.getChatedUserList();
      } else if (this.navbarId == 3) {
        this.getWolookMeList();
      } else if (this.navbarId == 4) {
        this.getMelookedList();
      }
    },
  },
};
</script>

<style>
.company-interaction-page {
}
</style>