<template>
  <div class="h5-job-list-item-page">
    <!-- 
		job_status 停止招聘 1 true 
		-->
    <div
      class="list"
      v-for="(item, i) in jobsList"
      :key="i"
      @click="tojobinfo(item.id, item.part)"
    >
      <div class="mask" v-if="item.job_status == 1"></div>
      <div class="list_top" @click="tojobinfo(item.id, item.part)">
        <div class="list_top_left">
          <!--  :class="item.job_status == 1 ? 'gray' : ''" -->
          <div
            class="list_top_left_name"
            :class="
              (item.part == '' || item.part == 0) &&
              (item.xsdate == '' || item.xsdate == 0) &&
              (item.urgent == '' || item.urgent == 0) &&
              (item.rec == '' || item.rec == 0)
                ? 'chang'
                : ''
            "
          >
            {{ item.name }}
          </div>
          <div class="littletag">
            <!-- <img
              v-if="item.urgent && item.urgent != '' && item.urgent != 0"
              src="/static/images/index_list_jizhao.png"
              mode=""
            />
            <img
              v-if="item.part && item.part != '' && item.part != 0"
              src="/static/images/index_list_jianzhi.png"
              mode=""
            />

            <img
              v-if="item.xsdate && item.xsdate != '' && item.xsdate != 0"
              src="/static/images/index_list_zhiding.png"
              mode=""
            ></image>
            <image
              v-if="item.rec && item.rec != '' && item.rec != 0"
              src="/static/images/index_list_tuijian.png"
              mode=""
            ></image> -->
            <!-- <tag style="margin-right: -30px;" name="兼职" color="#3CD797"
							v-if="item.part && item.part != '' && item.part != 0"></tag>
						<tag style="margin-right: -30px;" name="置顶" color="#FE6002"
							v-if="item.xsdate && item.xsdate != '' && item.xsdate != 0"></tag>
						<tag style="margin-right: -30px;" name="急招" color="#FF6565"
							v-if="item.urgent && item.urgent != '' && item.urgent != 0"></tag>
						<tag name="推荐" color="#FFA81F" v-if="item.rec && item.rec != '' && item.rec != 0"></tag> -->
          </div>
        </div>
        <div class="let-top-right">
          <span v-show="item.minsalary != 0 && item.maxsalary != 0"
            >{{ item.minsalary / 1000 }}-{{ item.maxsalary / 1000 }}K</span
          >
          <span v-show="item.minsalary == 0 && item.maxsalary == 0">面议</span>
          <!-- <haspart-salary-range
            v-if="item.job_status == 0 || !item.job_status"
            :salary="item.salary"
            :maxsalary="item.maxsalary"
            :minsalary="item.minsalary"
            :part="item.part"
          >
          </haspart-salary-range> -->
          <span
            v-if="item.job_status == 1"
            :class="item.job_status == 1 ? 'gray' : ''"
            >停止招聘</span
          >
        </div>
      </div>

      <div class="com-name">{{ item.com_name }}</div>
      <div
        class="hastag"
        v-if="item.welfare.length > 0 && item.welfare[0] != ''"
      >
        <div class="tags">
          <span v-if="!!item.exp && item.exp != '不限'">{{ item.exp }}</span>
          <span v-if="!!item.edu && item.edu != '不限'">{{ item.edu }}</span>
          <span
            v-for="(data, i) in item.welfare"
            :key="i"
            v-show="data != '' && data != '请选择'"
            >{{ data }}</span
          >
        </div>
      </div>
      <div class="company" @click="tojobinfo(item.id, item.part)">
        <!-- @click="tocominfo(item.uid)" -->
        <div class="con-info">
          <!-- <img class="logo" :src="item.logo" /> -->
          <el-image class="logo" :src="item.logo"></el-image>
          <span class="com_name"
            >{{ item.recruit.name }}
            <label v-if="item.recruit.job != ''">·</label>{{ item.recruit.job }}
          </span>
          <div class="on-lin" v-if="item.recruit.online">
            <div class="dian"></div>
            <span>{{ item.recruit.online }}</span>
          </div>
        </div>
        <div class="con-position">
          <span>{{ item.province }}</span>
          <span v-if="item.city">-{{ item.city }}</span>
          <span v-if="item.three_city">-{{ item.three_city }}</span>
        </div>
      </div>
      <div
        v-if="
          adverts.adverts_img != '' &&
          adverts.adverts_img != undefined &&
          i == 5
        "
        @click.stop="advert"
      >
        <div class="advert containers">
          <img
            class="u-skeleton-rect"
            mode="widthFix"
            :src="adverts.adverts_img"
          />
        </div>
      </div>
      <slot name="hastime" :item="item"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "user-card",
  props: {
    jobsList: {
      type: Array,
      default: () => [],
    },
    adverts: {
      type: Object,
      default: () => {
        return {
          adverts_link: "",
          is_link: "",
          adverts_img: "",
        };
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    tocominfo() {
      // uni.navigateTo({
      // 	url: `/pages/index/cominfo?id=${id}`
      // });
    },
    tojobinfo(id) {
      this.$router.push(`/h5jobdetail?id=${id}`);
      // uni.navigateTo({
      // 	url: `/pages/index/jobinfo?id=${id}&parttype=1`
      // });
    },
  },
};
</script>

<style>
.list {
  /* border-bottom: 12px solid #f5f6fa; */
  /* position: relative; */
  width: 456px;
  background-color: #ffffff;
  border-radius: 8px;
  margin-bottom: 20px;
}

.list:first-child {
  margin-top: 20px;
}

.mask {
  /* position: absolute; */
  width: 100%;
  height: 100%;
  z-index: 33;
  background: rgb(255, 255, 255, 0.5);
}

.gray {
  color: #b1b1b1;
}

.list_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /*	line-height: 108px; */
  padding-top: 27px;
  margin: 0 20px;
}

.list_top_left {
  display: flex;
  align-items: center;
  font-size: 20px;
}

.list_top_left_name {
  max-width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 900;
  line-height: 30px;
}

.chang {
  max-width: 500px;
}

.littletag {
  display: flex;
  align-content: center;
  /* flex: 1; */
  /* height: 108px; */
  overflow: hidden;
}

.littletag img {
  width: 72px;
  height: 36px;
  margin-left: 16px;
}

.ml-8 {
  margin-left: 16px;
}

.let-top-right {
  color: #fe6002;
  font-size: 20px;
  font-weight: 700;
}

.hastag {
  height: 26px;
  overflow: hidden;
  margin-top: 15px;
  /* margin-bottom: 24px; */
}

.h5-job-list-item-page .list .tags {
  height: 75px;
  display: flex;
  flex-wrap: wrap;
  margin-left: 20px;

  /* overflow: hidden;
		white-space: nowrap; */
}

.h5-job-list-item-page .list .tags span {
  margin-right: 8px;
  margin-bottom: 28px;
  padding: 0 8px;
  font-size: 12px;
  color: #757575;
  background: #f5f6fa;
  border-radius: 2px;
}

.h5-job-list-item-page .list .tags span {
  height: 22px;
  line-height: 21px;
}

.h5-job-list-item-page .com-name {
  font-size: 18px;
  
  font-weight: 500;
  color: #222222;
  margin-top: 10px;
  margin-left: 20px;
}

.company {
  border-top: 1px solid#F3F3F3;
  /* margin-bottom: 20px; */
  /* height: 48px; */
  line-height: 24px;
  display: flex;
  justify-content: space-between;
  padding: 13px 0;
  margin: 0 10px;
  margin-top: 12px;
}

.con-info {
  display: flex;
}

.con-info .on-lin {
  display: flex;
  align-items: center;
  margin-left: 12px;
}

.con-info .on-lin .dian {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #ff8136;
}

.con-info .on-lin span {
  font-size: 10px;
  
  font-weight: 400;
  color: #666666;
  margin-left: 4px;
}

.com_name {
  font-size: 16px;
  padding-left: 8px;
  
  font-weight: 500;
  color: #222222;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.logo {
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.con-position {
  width: 100px;
  text-align: right;
  font-size: 12px;
  color: #939597;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.advert {
  margin-bottom: 16px;
}

.advert image {
  width: 100%;
  border-radius: 5px;
}
</style>
