/*
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2024-05-10 14:21:47
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-05-10 15:58:13
 * @FilePath: /lcwpcvue/src/api/indiviuser/aboutUserSetApi.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import request from '@/utils/request'

// Report/myReport

/**
* 获取公司列表
* @url company/companyList
* @param {} params 
* @return 
*/
export function getMyReportList(params) {
  return request({
    url: 'Report/myReport',
    method: 'post',
    data: params,
  })
}
/**
* 删除举报
* @url Report/delReport
* @param {} params 
* @return 
*/
export function delReportById(params) {
  return request({
    url: 'Report/delReport',
    method: 'post',
    data: params,
  })
}