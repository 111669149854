<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-05-11 18:04:13
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-05-28 14:15:44
 * @FilePath: /lcwpcvue/src/components/wxscLogin.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="wxsc">
    <div class="colse-view">
      <!--      <img src="../assets/colse.png" alt=""/>-->
    </div>
    <div class="erwm-img">
      <!-- <img :src="ticketImg" /> -->
      <el-image :src="ticketImg" :fit="fit"></el-image>
      <!--      <wxlogin
              appid="wxe46367f27abe1979"
              scope="snsapi_login"
              :redirect_uri="encodeURIComponent('http://ceshi.petzp.com/wxloginld')"
              href="data:text/css;base64,CgouaW1wb3dlckJveCAucXJjb2RlIHsKIGJvcmRlcjogbm9uZTsKIHdpZHRoOiAxODBweDsKIGhlaWdodDogMTgwcHg7Cn0K"
            ></wxlogin>-->
    </div>
    <div class="t1">请使用微信扫描二维码登录“猎宠网”</div>
    <div class="elbtn">
      <el-button class="elbtn" icon="el-icon-caret-left" @click="toPhoneLogin"
        >手机号/账号登录
      </el-button>
    </div>

    <div class="ts-text">
      <span> 温馨提示：</span
      >第一次扫码，需要先绑定账号后，然后再进行扫码，后期无需再次绑定，直接扫描确认即可
    </div>
  </div>
</template>

<script>
// import wxlogin from "vue-wxlogin";
import requestByNotLoading from "@/utils/requestByNotLoading";
import { bindSocketCid } from "@/api/commen/chatApi";

export default {
  name: "wxsclogin",
  components: {
    // wxlogin,
  },
  data() {
    return {
      ticketImg: "",
      timer: "",
    };
  },
  mounted() {
    this.getQrcodeByGzh();
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    toPhoneLogin() {
      this.$emit("tophonelogin");
    },

    getQrcodeByGzh() {
      let usertype = localStorage.getItem("usertype");
      requestByNotLoading({
        url: "wx/serve",
        method: "post",
        data: {
          type: "getQrcodeByGzh",
          beFrom: "DL",
          userType: localStorage.usertype,
          imgKey: localStorage.getItem("gzhQrcodeByDL" + usertype),
        },
      }).then((req) => {
        this.ticketImg = req.data.qrcodeTicket;
        localStorage.setItem("gzhQrcodeByDL" + usertype, req.data.sceneStr);
        this.timer = setInterval(() => {
          requestByNotLoading({
            url: "wx/serve",
            method: "post",
            data: {
              type: "isLogin",
              userType: localStorage.usertype,
              imgKey: req.data.sceneStr,
            },
          }).then((res) => {
            if (res.code === 200 && res.data.isLogin === "1") {
              localStorage.removeItem("gzhQrcodeByDL" + usertype);
              clearInterval(this.timer);
              let reqData = res.data;
              console.log(reqData);
              // uni.setStorageSync("token", logininf.member.token);
              // uni.setStorageSync("uid", logininf.member.uid);
              this.$store.dispatch("user/setToken", reqData.token);
              this.$store.dispatch("user/setUserUid", reqData.member.uid);
              this.$cookies.set("token", reqData.token, "1d");
              localStorage.setItem("token", reqData.token);
              // this.$store.dispatch("user/setInfo", val.data);
              // this.$store.dispatch("user/setUserUid", val.data.member.uid);
              // this.$store.dispatch("user/setUserUid", val.data.member.uid);

              if (this.$store.state.user.usertype == 1) {
                if (reqData.login_check == "") {
                  // 个人信息
                  this.$router.push("/userregistbasinf");
                  // userregistbasinf
                  // this.showType = 2;
                } else if (reqData.login_check.is_resume == "0") {
                  // this.showType = 2;
                  this.$router.push("/userregistbasinf");
                } else if (reqData.login_check.is_expect == "0") {
                  // this.showType = 3;
                  this.$router.push("/userregistbasinf");
                } else {
                  // this.$refs;
                  console.log("tiaotiaojfladjflidjfidaj");
                  this.$nextTick(() => {
                    this.$router.push("/home");
                  });
                }
              } else if (this.$store.state.user.usertype == 2) {
                // console.log("ppp");
                if (reqData.login_check.length == 0) {
                  // 公司信息
                  this.$router.push("/comregist");

                  console.log("走的是这里111");
                } else if (reqData.login_check.is_company == "0") {
                  // uni.navigateTo({
                  // 	url: `/pagesA/user2/info/info?firstlogin=1`
                  // 	// url: '/pagesA/comAddJob/comAddJob?firstlogin=1'
                  // })
                  this.$router.push("/comregist");
                } else if (reqData.login_check.com_cert == "0") {
                  this.$router.push("/businessauth");
                  // 营业执照
                  // console.log('走的是这里222');
                  // uni.navigateTo({
                  // 	// url: `/pagesA/user2/info/info?firstlogin=1`
                  // 	url: '/pagesA/comAddJob/comAddJob?firstlogin=1'
                  // })
                } else {
                  this.bindSocketCid();
                  // 登录成功
                  this.$router.push("/index");
                }
              }
            }
          });
        }, 3000);
      });
    },

    bindSocketCid() {
      bindSocketCid({
        cid: this.$store.getters.socketbindid,
        pageurl: "login",
      })
        .then((result) => {
          console.log("绑定长链接", result);
        })
        .catch(() => {});
    },
  },
};
</script>

<style>
.wxsc {
  width: 1000px;
  height: 720px;
  background: #ffffff;
  border-radius: 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.colse-view {
  width: 95%;
  text-align: right;
  padding-top: 40px;
}

.colse-view img {
  width: 18px;
  height: 18px;
}

.erwm-img {
  margin-top: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #d9d9d9;
}

.erwm-img img {
  width: 220px;
  height: 220px;
  padding: 30px;
}
.wxlogin-wrap {
  width: 188px;
  height: 188px;
}

.t1 {
  
  font-weight: 600;
  font-size: 20px;
  color: #191919;
  line-height: 28px;
  text-align: right;
  font-style: normal;
  text-transform: none;
  margin: 32px 0 45px 0;
}

.elbtn .el-button {
  background: #f5f6fa;
  color: #222222;
  border: 0rem solid rgba(000, 000, 000, 0);
  font-size: 20px;
  
  font-weight: 400;
  width: 350px;
  height: 60px;
}

.elbtn .el-button i {
  color: #c1c1c1;
}

.ts-text {
  font-size: 16px;
  
  font-weight: 400;
  color: #222222;
  margin-top: 45px;
}

.ts-text span {
  color: #f34b37;
}
</style>