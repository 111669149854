<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-07-19 14:19:27
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-06-28 14:38:51
 * @FilePath: /lcwpcvue/src/views/findJobList.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
--> 
 <!--  -->
<template>
  <div class="find-job-list-page">
    <div class="top-zk-div"></div>

    <div class="find-job-sear-view">
      <searview
        searchpage="fendJobList"
        :searchKey="jobListFilter.search_key"
        :pagescrollhe="pageScrollHe"
        @hotJobs="hotJobsSearch"
        @toSearch="toSearch"
        :slMrValF="className"
      ></searview>
      <filterateview
        searchpage="findjob"
        :jobClass="jobListFilter"
        @toFliterList="fliterTalentList"
        @clearFiltrateFun="clearFiltrateFun"
        ref="filterateview_ref"
      ></filterateview>
    </div>
    <div class="min-jobList-login-tj-view">
      <div class="job-fil-navbar-view">
        <div
          class="navbar-item"
          :class="jobListFilter.jobWeight === 'all' ? 'navbar-item-sl' : ''"
          @click="chooseTag('all')"
        >
          <span>最新职位</span>
          <div
            v-show="jobListFilter.jobWeight === 'all'"
            class="btm-xian"
          ></div>
        </div>
        <div
          class="navbar-item"
          :class="jobListFilter.jobWeight === 'urgent' ? 'navbar-item-sl' : ''"
          @click="chooseTag('urgent')"
        >
          <span>急聘职位</span>
          <div
            v-show="jobListFilter.jobWeight === 'urgent'"
            class="btm-xian"
          ></div>
        </div>
        <div
          class="navbar-item"
          :class="jobListFilter.jobWeight === 'rec' ? 'navbar-item-sl' : ''"
          @click="chooseTag('rec')"
        >
          <span>推荐职位</span>
          <div
            v-show="jobListFilter.jobWeight === 'rec'"
            class="btm-xian"
          ></div>
        </div>
      </div>

      <div class="joblist-login-view">
        <div class="job-list-view" v-show="jobList.length > 0">
          <div>
            <jobcard :jobList="jobList"></jobcard>
          </div>
          <el-pagination
            @current-change="pagesChage"
            background
            layout="prev, pager, next"
            :page-count="allPage"
            :current-page.sync="currentpage"
            class="pag-ina"
          >
          </el-pagination>
        </div>
        <div class="job-list-view" v-show="jobList.length <= 0">
          <div class="job-list-view-c">
            <img src="@/assets/coupon_none_list.png" alt="" />
            <p>暂无数据</p>
          </div>
        </div>
        <div class="findjoblist-login-view">
          <loginview v-show="!isLogin" :pathname="'job'"></loginview>
          <img class="yhjl-view" src="../assets/findjob_yh_jl.png" alt="" />

          <div class="findjob-tjjob-view" v-show="recJobList.length > 0">
            <div class="tj-title-view">
              <img class="tj-title-img" src="../assets/index_jxgw.png" alt="" />
              <span class="tj-view">推荐职位</span>
            </div>
            <div class="tj-list">
              <div class="tjitem" v-for="item in recJobList" :key="item.id">
                <div class="tj-top-view">
                  <span class="tj-t1">{{ item.name }}</span>
                  <span class="spar-gz" v-show="item.minsalary == 0"
                    >￥面议</span
                  >
                  <span class="spar-gz" v-show="item.minsalary != 0">
                    ￥{{ item.minsalary }}-{{ item.maxsalary }}
                  </span>
                </div>
                <div class="com-name">{{ item.com_name }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import searview from "@/components/homeSearComp.vue";
import filterateview from "@/components/filtrateComp.vue";
import jobcard from "@/components/comUser/jobCardInFindJob.vue";
import loginview from "@/components/loginWthiPage.vue";
import { getJobList } from "@/api/commen/findJobList";
import { turnoverTime } from "@/utils/timeUrils";

export default {
  components: {
    searview,
    filterateview,
    jobcard,
    loginview,
  },
  data() {
    return {
      pageScrollHe: 0,
      jobListFilter: {
        // 求职端 所有搜索的筛选项
        page: 1, // 页码
        column: "new", //  recommend推荐 new最新
        provinceid: "all", // 省
        cityid: "all", // 市
        three_cityid: "all", // 区
        is_qg: 1,
        jobclass_id: "all", // 子集？
        job_id: "all", // 父级？
        search_key: "", // 搜索内容
        edu: "all", // 教育经历
        exp: "all", // 工作经历
        type: "all", // 职位类型 全职,兼职
        min_value: "all", // 期望薪资最小值
        max_value: "all", // 期望薪资最大值
        welfares: "all", //福利筛选
        hy: "all",
        jobWeight: "all",
        // parent_job_id:"",
      },
      allPage: 0,
      currentpage: 0,
      jobList: [],
      isLogin: false,
      recJobList: [],
      className: "职位类型",
    };
  },
  async created() {
    this.isLogin = localStorage.token ? true : false;
    if (this.$route.query.kw) {
      this.jobListFilter.search_key = this.$route.query.kw;
    }
    console.log(this.$route.query);
    if (this.$route.query.pid) {
      setTimeout(() => {
        this.hotJobsSearch({
          id: this.$route.query.cid,
          keyid: this.$route.query.pid,
          name: this.$route.query.name,
        });
      }, 500);
      // if (this.$route.query.pid == 0) {
      //   this.jobListFilter.job_id = this.$route.query.pid;
      //   this.jobListFilter.jobclass_id = "all";
      // } else {
      //   this.jobListFilter.job_id = this.$route.query.pid;
      //   this.jobListFilter.jobclass_id = this.$route.query.cid;
      // }
    }
    this.getJobList();
    window.addEventListener("scroll", this.homePageScroll);
    let recJob = sessionStorage.getItem("getRecommendJobList");
    if (recJob) {
      this.recJobList = JSON.parse(recJob);
    }
  },
  methods: {
    chooseTag(idx) {
      this.jobListFilter.jobWeight = idx;
      this.currentpage = 1;
      this.jobListFilter.page = 1;
      this.getJobList();
    },
    hotJobsSearch(jobclass) {
      console.log(jobclass);
      this.jobListFilter.page = 1;
      this.currentpage = 1;
      this.className = jobclass.name;
      if (jobclass.keyid == 0) {
        this.jobListFilter.job_id = jobclass.id;
        this.jobListFilter.jobclass_id = "all";
        this.$refs.filterateview_ref.idxJobs(
          this.jobListFilter.job_id,
          this.jobListFilter.jobclass_id
        );
      } else {
        this.jobListFilter.job_id =
          jobclass.keyid == 0 ? "all" : jobclass.keyid;
        this.jobListFilter.jobclass_id = jobclass.id;
        this.$refs.filterateview_ref.idxJobs(
          this.jobListFilter.job_id,
          this.jobListFilter.jobclass_id
        );
      }

      this.getJobList();
    },
    fliterTalentList(type, id) {
      this.jobListFilter.page = 1;
      this.currentpage = 1;
      if (type === "zw") {
        this.jobListFilter.job_id = id.parent;
        this.jobListFilter.jobclass_id = id.son;
      } else if (type === "pro") {
        this.jobListFilter.provinceid = id.proId;
        this.jobListFilter.cityid = id.cityId;
        this.jobListFilter.three_cityid = id.threeId;
        if (
          this.jobListFilter.provinceid !== "all" ||
          this.jobListFilter.cityid !== "all" ||
          this.jobListFilter.three_cityid !== "all"
        ) {
          this.jobListFilter.is_qg = 0;
        } else {
          this.jobListFilter.is_qg = 1;
        }
      } else if (type === "sal") {
        this.jobListFilter.min_value = id.min_value;
        this.jobListFilter.max_value = id.max_value;
      } else if (type === "wfe") {
        this.jobListFilter.welfares = id;
      } else if (type === "hy") {
        this.jobListFilter.hy = id;
      } else if (type === "edu") {
        this.jobListFilter.edu = id;
      } else if (type === "type") {
        this.jobListFilter.type = id;
      } else if (type === "exp") {
        this.jobListFilter.exp = id;
      }
      this.getJobList();
    },

    clearFiltrateFun() {
      this.jobListFilter = {
        page: 1, // 页码
        column: "new", //  recommend推荐 new最新
        provinceid: "all", // 省
        cityid: "all", // 市
        three_cityid: "all", // 区
        is_qg: 1,
        jobclass_id: "all", // 顶部期望职位
        job_id: "all", // 职业, 例: 宠物医生
        search_key: "", // 搜索内容
        edu: "all", // 教育经历
        exp: "all", // 工作经历
        type: "all", // 职位类型 全职,兼职
        min_value: "all", // 期望薪资最小值
        max_value: "all", // 期望薪资最大值
        welfares: "all", //福利筛选
        hy: "all",
        jobWeight: "all",
      };
      this.currentpage = 1;
      this.getJobList();
    },
    //监听页面滚动
    homePageScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      this.pageScrollHe = scrollTop;
    },
    getJobList() {
      getJobList(this.jobListFilter)
        .then((result) => {
          this.jobList = result.data.jobs;
          this.jobList.forEach((im) => {
            im.jobOnline = turnoverTime(im.lastupdate);
          });
          this.allPage = parseInt(result.data.allpage);
          if (result.data.count === "0") return this.$message.error("暂无数据");
        })
        .catch(() => {});
    },
    pagesChage(e) {
      console.log("chage", e);
      this.jobListFilter.page = e;
      window.scrollTo(200, 200);
      this.getJobList();
    },
    toSearch(kw) {
      if (!kw || kw === "") return;
      this.jobListFilter.search_key = kw;
      if (
        this.jobListFilter.provinceid !== "all" ||
        this.jobListFilter.cityid !== "all" ||
        this.jobListFilter.three_cityid !== "all"
      ) {
        this.jobListFilter.is_qg = 0;
      } else {
        this.jobListFilter.is_qg = 1;
      }
      this.jobListFilter.page = 1;
      this.getJobList();
    },
  },

  //推荐职位
};
</script>

<style>
.find-job-list-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.find-job-sear-view {
  background-color: #fff;
}
.min-jobList-login-tj-view {
  /* margin: 0 220px; */
}
.job-fil-navbar-view {
  width: 100%;
  display: flex;
  background-color: #fff;
  margin-top: 41px;
  font-size: 22px;

  font-weight: 400;
  color: #757575;
  padding: 15px 0;
  border-radius: 8px;
  border-bottom: 2px solid#EAECF5;
}
.job-fil-navbar-view .navbar-item {
  width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.job-fil-navbar-view .navbar-item-sl {
  font-size: 24px;

  font-weight: 600;
  color: #fe6002;
}
.job-fil-navbar-view .btm-xian {
  width: 40px;
  height: 3px;
  background-color: #fe6002;
  position: absolute;
  bottom: -15px;
}
.job-list-view {
  width: 1034px;
  text-align: center;
}

.joblist-login-view {
  display: flex;
}
.findjoblist-login-view {
  margin-top: 20px;
  margin-left: 43px;
}

.findjoblist-login-view .yhjl-view {
  width: 403px;
  height: 200px;
  /* margin-top: 40px; */
}
.findjob-tjjob-view {
  width: 355px;
  background-color: #fff;
  border-radius: 8px;
  margin-top: 40px;
  padding: 25px 24px;
}
.findjob-tjjob-view .tj-title-view {
  display: flex;
  align-items: center;
}
.findjob-tjjob-view .tj-title-view .tj-title-img {
  width: 26px;
  height: 26px;
  margin-right: 10px;
}

.findjob-tjjob-view .tj-title-view .tj-view {
  font-size: 22px;

  font-weight: 600;
  color: #191919;
  line-height: 28px;
}

.findjob-tjjob-view .tj-list .tjitem {
  border-bottom: 1px solid #f5f6fa;
  padding: 20px 0;
}
.findjob-tjjob-view .tj-list .tjitem .tj-top-view {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.findjob-tjjob-view .tj-list .tjitem .tj-top-view .tj-t1 {
  font-size: 18px;

  font-weight: 600;
  color: #191919;
  line-height: 28px;
  width: 233px;
}

.findjob-tjjob-view .tj-list .tjitem .tj-top-view .spar-gz {
  font-size: 16px;

  font-weight: 500;
  color: #fe6002;
  line-height: 19px;
}

.findjob-tjjob-view .tj-list .tjitem .com-name {
  font-size: 14px;

  font-weight: 400;
  color: #757575;
  line-height: 16px;
  margin-top: 8px;
}
.job-list-view-c {
  margin-top: 32px;
  border-radius: 15px;
  height: 100%;
  width: 100%;
  background-color: white;
}
.job-list-view-c img {
  width: 260px;
  height: auto;
  margin: 160px 0 20px 0;
}
.job-list-view-c p {
  font-size: 22px;

  font-weight: 400;
  color: #757575;
}
</style>