<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2024-03-21 09:57:49
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-04-02 18:28:09
 * @FilePath: /lcwpcvue/src/components/comUser/jobCardInCompanyInfPage.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
        

-->
<template>
  <div class="job-card-in-companyinf-page">
    <label v-for="item in jobArr" :key="item.id">
      <el-popover
        placement="bottom-end"
        width="484"
        trigger="manual"
        :visible-arrow="false"
        popper-class="popper-view-st"
        :value="item.id == jobid"
      >
        <div
          slot="reference"
          class="card-item-view"
          :class="item.id == jobid ? 'hover-stly' : ''"
          @mouseover="hadlerMouseOver(item.id)"
          @mouseout="hadlerMoseOut"
          @click="toJobInfPager(item.id,item.part)"
        >
          <div class="card-left-view">
            <div class="name-ll">
              <span class="name">{{ item.name }}</span>
              <span v-show="item.part == 1" class="job-tag-s">兼职</span>
              <img src="@/assets/findjob_ljgt.png" alt="" />
            </div>
            <div class="add-exp-edu">
              <span>{{ item.city }}</span>
              <el-divider direction="vertical"></el-divider>
              <span>{{ item.exp_name }}</span>
              <el-divider direction="vertical"></el-divider>
              <span>{{ item.edu_name }}</span>
            </div>
            <div class="job-tag-view">
              <label v-for="(welfare, index) in item.welfare" :key="index">
                <span class="tag">{{ welfare }}</span>
              </label>
            </div>
          </div>
          <div class="card-right-view">
            <div class="wage-text" v-if="item.part == 1">{{item.salary}}</div>
            <div class="wage-text" v-else-if="item.salary_type != 1">{{ item.minsalary }}-{{ item.maxsalary }}元/月</div>
            <div class="wage-text" v-else-if="item.salary_type == 1">面议</div>

            <div class="up-time-text" v-show="item.lastupdate_name">更新于{{ item.lastupdate_name }}</div>
          </div>
        </div>

        <div class="hover-show-inf">
          <div class="popover-title-view">
            <span class="lef-xian"></span>
            <span class="text">职位要求</span>
          </div>
          <div class="popo-add-exp-edu-view">
            <span>{{ item.city }}</span>
            <el-divider direction="vertical"></el-divider>
            <span>{{ item.exp_name }}</span>
            <el-divider direction="vertical"></el-divider>
            <span>{{ item.edu_name }}</span>
          </div>
          <div class="popover-title-view">
            <span class="lef-xian"></span>
            <span class="text">职位描述</span>
          </div>
          <div class="job-desp-view">
            {{ item.description }}
          </div>
        </div>
      </el-popover>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    jobArr: {
      type: Array,
    },
  },
  data() {
    return {
      popoverVisible: false,
      jobid: "",
    };
  },
  methods: {
    hadlerMouseOver(id) {
      // console.log("悬停");
      this.jobid = id;
      this.popoverVisible = true;
    },
    hadlerMoseOut() {
      this.jobid = 0;
    },
    toJobInfPager(id,isPart) {
      if (isPart !== '1'){
        this.$router.push(`/jobinfopage?id=${id}`);
      }else {
        this.$router.push(`/parttimejobdetail?id=${id}`);
      }
    },
  },
};
</script>

<style>
.job-card-in-companyinf-page {
  position: relative;
}
.job-card-in-companyinf-page .card-item-view {
  width: auto;
  height: 102px;
  border: 1px solid #ccc;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
  margin-top: 24px;
}

.job-card-in-companyinf-page .hover-stly {
  border: 1px solid #fe6002;
  background-color: #fffaf7;
}
.job-card-in-companyinf-page .card-item-view .card-left-view .name-ll {
  display: flex;
  align-items: center;
  
  font-weight: 600;
  font-size: 22px;
  color: #191919;
  line-height: 28px;
}
.job-card-in-companyinf-page .card-item-view .card-left-view .name-ll img {
  width: 84px;
  height: 25px;
  margin-left: 40px;
}
.job-card-in-companyinf-page .card-item-view .card-left-view .add-exp-edu {
  
  font-weight: 400;
  font-size: 14px;
  color: #757575;
  line-height: 16px;
  margin-top: 12px;
}
.job-card-in-companyinf-page .card-item-view .card-left-view .job-tag-view {
  margin-top: 10px;
}
.job-card-in-companyinf-page
  .card-item-view
  .card-left-view
  .job-tag-view
  span {
  display: inline-block;
  background-color: #f5f6fa;
  margin-right: 16px;
  padding: 4px 8px;
  
  font-weight: 400;
  font-size: 14px;
  color: #757575;
  line-height: 16px;
}
.job-card-in-companyinf-page .card-item-view .card-right-view {
  text-align: right;
}

.job-card-in-companyinf-page .card-item-view .card-right-view .wage-text {
  
  font-weight: 600;
  font-size: 20px;
  color: #fe6002;
  line-height: 28px;
}
.job-card-in-companyinf-page .card-item-view .card-right-view .up-time-text {
  
  font-weight: 400;
  font-size: 14px;
  color: #757575;
  line-height: 16px;
  margin-top: 50px;
}
.popper-view-st {
  position: absolute;
  right: 0;
  /* width: 484px; */
  /* margin-top: -10px; */
}
.hover-show-inf {
  padding: 0 10px;
}

.hover-show-inf .popover-title-view {
  margin-top: 24px;
  display: flex;
  align-items: center;
}

.hover-show-inf .popover-title-view .lef-xian {
  display: inline-block;
  background-color: #fe6002;
  width: 4px;
  height: 16px;
  border-radius: 5px;
}
.hover-show-inf .popover-title-view .text {
  display: inline-block;
  
  font-weight: 600;
  font-size: 20px;
  color: #191919;
  line-height: 28px;
  margin-left: 10px;
}

.hover-show-inf .popo-add-exp-edu-view {
  
  font-weight: 400;
  font-size: 16px;
  color: #757575;
  line-height: 19px;
  margin-top: 16px;
}

.hover-show-inf .job-desp-view {
  
  font-weight: 400;
  font-size: 16px;
  color: #757575;
  line-height: 24px;
  margin-top: 16px;
}

</style>