<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2024-02-19 14:34:27
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-02-20 17:17:54
 * @FilePath: /lcwpcvue/src/views/businessAuth.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="com-regist">
    <pageheader class="head-view" :userTypeName="userTypeName"></pageheader>
    <businessauth class="topview"></businessauth>
  </div>
</template>

<script>
import pageheader from "@/components/pageHeader.vue";
import businessauth from "@/components/comUser/businessAuthDialog.vue";
export default {
  components: {
    pageheader,
    businessauth,
  },
};
</script>

<style>
</style>