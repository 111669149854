<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-12-19 18:28:33
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-05-10 14:00:04
 * @FilePath: /lcwpcvue/src/components/comUser/myComboPage.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="mycombo-page">
    <div>
      <div class="combo-top-com-inf">
        <img class="avate-img" :src="myVipComboInf.com_logo" alt="" />
        <div class="right-text-view">
          <div class="com-name">{{ myVipComboInf.name }}</div>
          <div class="vip-rating-text">
            您当前是：<label>{{ myVipComboInf.rating_name }}</label>
          </div>
          <div class="fw-time">
            服务日期为：<label>{{ myVipComboInf.endtime === '0天后到期' ? '已过期' : myVipComboInf.endtime}}</label>
          </div>
        </div>
      </div>

      <div class="my-vip-inf-view">
        <img
          class="vip-inf-bg-img"
          src="@/assets/mycombo_vipinf_img.png"
          alt=""
        />
        <div class="vip-inf-text-view">
          <img
            v-show="myVipComboInf.rating_name == '免费企业'"
            class="vip-text-img"
            src="@/assets/mycombo_vip_text_img1.png"
          />
          <img
            v-show="myVipComboInf.rating_name != '免费企业'"
            class="vip-text-img"
            src="@/assets/mycombo_vip_text_img.png"
          />
          <div class="min-text-view">
            <div class="inf-tt1">
              {{ myVipComboInf.rating_name }}
              <label>| {{ myVipComboInf.endtime !== '0天后到期' ? myVipComboInf.endtime : '已过期'}}</label>
            </div>
            <div class="inf-tt2">你可以享受以下服务，助力招聘人才</div>
          </div>
          <div class="right-btn-view">
            <el-button type="primary" round @click="changeShoeidFun(19)"
              >升级会员</el-button
            >
          </div>
        </div>
      </div>

      <div class="my-combo-view">
        <img
          class="my-combo-bg-img"
          src="@/assets/mycombo_list_bg_img.png"
          alt=""
        />
        <div class="my-combo-text-view">
          <div class="my-combo-title">我的权益</div>
          <div class="mycombo-list-view">
            <label v-for="(item, index) in myVipComboInf.result" :key="index">
              <div class="combo-item" @click="toJobManagePage(index)">
                <div class="item-tt1">{{ item.title }}</div>
                <div class="item-tt2">{{ item.allnum }}</div>
                <div class="item-tt3">
                  {{
                    index == 0
                      ? "已下载"
                      : index == 1
                      ? "已发布"
                      : index == 2
                      ? "已沟通"
                      : index == 3
                      ? "已刷新"
                      : "已用"
                  }}{{ item.usenum }}{{ item.unit }}
                </div>
              </div>
            </label>
          </div>
        </div>
      </div>

      <div class="value-added-service-view" v-show="zzServiceList.length > 0">
        <div class="added-service-title">购买增值服务</div>
        <div class="added-service-list-view">
          <label v-for="item in zzServiceList" :key="item.id">
            <div class="added-service-item">
              <img
                class="service-item-bg-img"
                src="@/assets/mycombo_added_service_bg_img.png"
                alt=""
              />
              <div class="added-service-text-view">
                <div class="left-service-text">
                  <div class="service-tt1">{{ item.name }}</div>
                  <div class="service-tt2">
                    <span class="rmb">￥</span>
                    <span class="nubm">{{ item.service_price }}</span>
                    <span class="time">/{{ item.use }}</span>
                    <label v-for="(soitem, index) in item.arr" :key="index">
                      <span class="nub-it"
                        >{{ soitem.num }}{{ soitem.title }}</span
                      >
                    </label>
                    <!-- <span class="nub-it">3个职位</span>
                    <span class="nub-it">40条直聊</span> -->
                  </div>
                </div>
                <div class="right-pay-btn-view">
                  <el-button
                    type="primary"
                    round
                    @click="payDialogShowFun(item)"
                    >立即开通</el-button
                  >
                </div>
              </div>
            </div>
          </label>
        </div>
      </div>
    </div>
    <div class="mycombo-right-view">
      <img
        @click="changeShoeidFun"
        class="up-vip-img"
        src="@/assets/mycombo_upvip_img.png"
        alt=""
      />
      <zpbb></zpbb>
    </div>

    <el-dialog title="购买VIP" :visible.sync="payDialogShow" width="400px">
      <div class="vip-type-view">
        <img class="vip-type-bg" src="@/assets/vip_pay_bg_img.png" alt="" />
        <div class="vip-type-text-view">
          <img class="lczx-img" src="@/assets/vip_pay_zx_img.png" alt="" />
          <div class="left-text-view">
            <div class="name-tt1">{{ payInf.name }}</div>
            <div class="time">{{ payInf.use }}</div>
          </div>
          <div class="right-text-view">
            <label>￥</label>{{ payInf.service_price }}
          </div>
        </div>
      </div>
      <div class="pay-type-view">
        <div class="paytype-title">支付方式</div>
        <div class="paytype">
          <div
            class="paytype-item"
            @click="changePayType('wxpay')"
            :class="payType == 'wxpay' ? 'item-sl' : ''"
          >
            <img src="@/assets/vip_paytype_wx_img.png" alt="" />
            <span>微信支付</span>
          </div>
          <div
            class="paytype-item"
            @click="changePayType('alipay')"
            :class="payType == 'alipay' ? 'item-sl' : ''"
          >
            <img src="@/assets/vip_paytype_zfb_img.png" alt="" />
            <span>支付宝支付</span>
          </div>
        </div>
      </div>
      <div class="wx-pay-img-view" v-show="payType == 'wxpay'">
        <div class="wxpay-tt1">请使用<label>微信</label>扫描二维码完成支付</div>
        <div class="wxpay-tt2">
          如无法支付或二维码已过期请点击<label>刷新</label>
        </div>
        <img class="wxpay-ewm-img" :src="wxPayImgUrl" alt="" />
        <div class="wxpay-tt2">
          购买付费即表示同意<label>《猎宠网VIP服务协议》</label>
        </div>
      </div>
      <div v-show="payType == 'alipay'" slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toAliPayPage">去支付</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import zpbb from "@/components/comUser/zpbb.vue";
import { getMyVipCombo, getServiceList } from "@/api/compe/myComboApi.js";
import { submitOrder } from "@/api/commen/payApi.js";

export default {
  name: "mycombopage",
  components: {
    zpbb,
  },
  data() {
    return {
      myVipComboInf: {},
      zzServiceList: {},
      payDialogShow: false,
      payInf: {},
      payType: "wxpay",
      wxPayImgUrl: "",
      aliNatoUrl: "",
    };
  },
  created() {
    this.getMyVipCombo();
    this.getServiceList();
  },
  methods: {
    getMyVipCombo() {
      getMyVipCombo()
        .then((result) => {
          console.log("获取我的会员权益", result);
          result.data.result.forEach((item) => {
            let arr = item.num.split("/");
            item.allnum = arr[0];
            item.usenum = arr[1];
          });
          this.myVipComboInf = result.data;
        })
        .catch(() => {});
    },
    getServiceList() {
      getServiceList()
        .then((result) => {
          console.log("获取增值服务", result);
          this.zzServiceList = result.data;
        })
        .catch(() => {});
    },
    payDialogShowFun(item) {
      console.log(item);
      this.payInf = item;
      submitOrder({
        type: 5,
        rating_id: item.detail_id,
        pay_type: this.payType,
      })
        .then((result) => {
          console.log("提交订单", result);
          this.wxPayImgUrl = result.data.pay_img;
          this.payDialogShow = true;
        })
        .catch(() => {});
    },

    changePayType(type) {
      this.payType = type;
      submitOrder({
        type: 5,
        rating_id: this.payInf.detail_id,
        pay_type: this.payType,
      })
        .then((result) => {
          console.log("提交订单", result);
          if (type == "wxpay") {
            this.wxPayImgUrl = result.data.pay_img;
          } else {
            this.aliNatoUrl = result.data.url;
          }
        })
        .catch(() => {});
    },
    toAliPayPage() {
      window.open(this.aliNatoUrl, "_blank");
    },

    changeShoeidFun() {
      this.$router.push(`/vippaypage`);
    },

    toJobManagePage(index) {
      if (index == 3 || index == 4 || index == 5 || index == 6) {
        this.$router.push(`/jobmanage`);
      }
    },
  },
};
</script>

<style>
.mycombo-page {
  display: flex;
}

.mycombo-page .el-dialog__wrapper .el-dialog {
  border-radius: 20px;
}

.combo-top-com-inf {
  width: 802px;
  height: 136px;
  background-color: #fff;
  display: flex;
  padding: 24px 0 0 24px;
  border-radius: 16px;
}

.combo-top-com-inf .avate-img {
  width: 88px;
  height: 88px;
  border-radius: 50%;
}

.combo-top-com-inf .right-text-view {
  margin-left: 20px;
}

.combo-top-com-inf .right-text-view .com-name {
  font-size: 24px;
  
  font-weight: 600;
  color: #222222;
  line-height: 28px;
}

.combo-top-com-inf .right-text-view .vip-rating-text {
  font-size: 18px;
  
  font-weight: 400;
  color: #191919;
  line-height: 24px;
  margin-top: 16px;
}
.combo-top-com-inf .right-text-view .vip-rating-text label {
  font-size: 20px;
  
  font-weight: 600;
  color: #fe6002;
  line-height: 28px;
}
.combo-top-com-inf .right-text-view .fw-time {
  font-size: 18px;
  
  font-weight: 400;
  color: #191919;
  line-height: 24px;
  margin-top: 10px;
}
.combo-top-com-inf .right-text-view .fw-time label {
  font-size: 18px;
  
  font-weight: 600;
  color: #191919;
  line-height: 28px;
}
.my-vip-inf-view {
  position: relative;
  margin-top: 16px;
}

.my-vip-inf-view .vip-inf-bg-img {
  width: 826px;
  height: 100px;
}

.my-vip-inf-view .vip-inf-text-view {
  width: 826px;
  height: 100px;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
}
.my-vip-inf-view .vip-inf-text-view .vip-text-img {
  width: 103px;
  height: 41px;
  margin-left: 24px;
}

.my-vip-inf-view .vip-inf-text-view .min-text-view {
  margin-left: 15px;
  color: #fe6002;
}

.my-vip-inf-view .vip-inf-text-view .min-text-view .inf-tt1 {
  font-size: 24px;
  
  font-weight: 600;
  line-height: 28px;
  display: flex;
  align-items: center;
}
.my-vip-inf-view .vip-inf-text-view .min-text-view .inf-tt1 label {
  font-size: 20px;
  
  font-weight: 600;
  line-height: 28px;
  margin-left: 5px;
}
.my-vip-inf-view .vip-inf-text-view .min-text-view .inf-tt2 {
  font-size: 14px;
  
  font-weight: 600;
  line-height: 16px;
  margin-top: 10px;
}

.my-vip-inf-view .vip-inf-text-view .right-btn-view {
  position: absolute;
  right: 17px;
}

.my-vip-inf-view
  .vip-inf-text-view
  .right-btn-view
  button.el-button.el-button--primary.is-round {
  width: 138px;
  height: 44px;
  font-size: 18px;
  
  font-weight: 600;
  color: #ffffff;
}
.my-combo-view {
  margin-top: 16px;
  position: relative;
}

.my-combo-view .my-combo-bg-img {
  width: 826px;
  height: 429px;
}
.my-combo-view .my-combo-text-view {
  width: 826px;
  position: absolute;
  top: 0;
  padding: 29px 0;
  /* border: 1px solid#000; */
}
.my-combo-view .my-combo-text-view .my-combo-title {
  font-size: 24px;
  
  font-weight: 600;
  color: #191919;
  line-height: 28px;
  margin-left: 25px;
}

.my-combo-view .my-combo-text-view .mycombo-list-view {
  /* margin-top: 20px; */
}
.my-combo-view .my-combo-text-view .mycombo-list-view .combo-item {
  display: inline-block;
  width: 175px;
  height: 178px;
  text-align: center;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 2px 4px 10px 0px rgba(111, 111, 111, 0.15);
  margin-left: 25px;
  margin-top: 20px;
}
.my-combo-view .my-combo-text-view .mycombo-list-view .combo-item .item-tt1 {
  font-size: 20px;
  
  font-weight: 600;
  color: #222222;
  line-height: 28px;
  margin-top: 24px;
}
.my-combo-view .my-combo-text-view .mycombo-list-view .combo-item .item-tt2 {
  font-size: 36px;
  
  font-weight: 600;
  color: #fe6002;
  line-height: 42px;
  margin-top: 8px;
}
.my-combo-view .my-combo-text-view .mycombo-list-view .combo-item .item-tt3 {
  font-size: 18px;
  
  font-weight: 400;
  color: #757575;
  line-height: 24px;
  margin-top: 20px;
}
.value-added-service-view {
  width: 826px;
  height: 306px;
  background-color: #fff;
  border-radius: 8px;
  margin-top: 70px;
}

.value-added-service-view .added-service-title {
  font-size: 24px;
  
  font-weight: 600;
  color: #191919;
  line-height: 28px;
  margin-left: 25px;
  padding-top: 20px;
}
.value-added-service-view .added-service-list-view {
  margin-left: 25px;
}
.value-added-service-view .added-service-list-view .added-service-item {
  margin-top: 20px;
  position: relative;
}

.value-added-service-view
  .added-service-list-view
  .added-service-item
  .service-item-bg-img {
  width: 776px;
  height: 94px;
}
.value-added-service-view
  .added-service-list-view
  .added-service-item
  .added-service-text-view {
  width: 776px;
  height: 94px;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
}
.value-added-service-view
  .added-service-list-view
  .added-service-item
  .added-service-text-view
  .left-service-text {
  margin-left: 20px;
}
.value-added-service-view
  .added-service-list-view
  .added-service-item
  .added-service-text-view
  .left-service-text
  .service-tt1 {
  font-size: 22px;
  
  font-weight: 600;
  color: #362202;
  line-height: 28px;
}
.value-added-service-view
  .added-service-list-view
  .added-service-item
  .added-service-text-view
  .left-service-text
  .service-tt2
  .rmb {
  font-size: 14px;
  
  font-weight: 400;
  color: #f34b37;
  line-height: 16px;
}
.value-added-service-view
  .added-service-list-view
  .added-service-item
  .added-service-text-view
  .left-service-text
  .service-tt2
  .nubm {
  font-size: 24px;
  
  font-weight: 600;
  color: #f34b37;
  line-height: 28px;
}
.value-added-service-view
  .added-service-list-view
  .added-service-item
  .added-service-text-view
  .left-service-text
  .service-tt2
  .time {
  font-size: 13px;
  
  font-weight: 400;
  color: #757575;
  line-height: 15px;
  margin-right: 10px;
}
.value-added-service-view
  .added-service-list-view
  .added-service-item
  .added-service-text-view
  .left-service-text
  .service-tt2
  .nub-it {
  font-size: 14px;
  
  font-weight: 600;
  color: #533f1f;
  line-height: 16px;
  margin-left: 10px;
}
.value-added-service-view
  .added-service-list-view
  .added-service-item
  .added-service-text-view
  .right-pay-btn-view
  button.el-button.el-button--primary.is-round {
  width: 114px;
  height: 38px;
  background-color: #533f1f;
  font-size: 16px;
  
  font-weight: 600;
  color: #ffddb1;
  border: none;
}
.value-added-service-view
  .added-service-list-view
  .added-service-item
  .added-service-text-view
  .right-pay-btn-view {
  position: absolute;
  right: 21px;
}

.mycombo-page .mycombo-right-view {
  margin-left: 24px;
}

.mycombo-page .mycombo-right-view .up-vip-img {
  width: 346px;
  height: 96px;
  margin-bottom: 24px;
}
</style>