<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-07-19 18:35:09
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-06-05 17:43:20
 * @FilePath: /lcwpcvue/src/components/filtrateComp.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="filtrate-view">
    <div
      v-show="['talent', 'findjob'].includes(searchpage)"
      class="filtrate-fli1-view"
    >
      <div class="fil-title-view">职 位</div>
      <div
        class="fil-items-view"
        :class="jobs.moreZwShow ? 'more-fil-items-view' : ''"
      >
        <label v-for="item in jobs.zwFlList" :key="item.id">
          <el-link
            class="fl-item"
            :class="jobs.zwFlChoseId == item.id ? 'fl-item-chose' : ''"
            @click="chageFliter('zw', item, 'pt')"
            :underline="false"
            >{{ item.name }}
          </el-link>
        </label>
      </div>
      <div v-show="!jobs.moreZwShow" class="more-view" @click="showMoreZw">
        <span>更多</span> <i class="el-icon-arrow-down"></i>
      </div>
      <div v-show="jobs.moreZwShow" class="more-view" @click="showMoreZw">
        <span>收起</span> <i class="el-icon-arrow-up"></i>
      </div>
    </div>
    <!--    二级职位-->
    <div
      class="two-city-item"
      v-show="jobs.zwFlChoseId !== 'all' && jobs.zwSonList"
    >
      <label v-for="item in jobs.zwSonList" :key="item.id">
        <el-link
          class="fl-item"
          :underline="false"
          :class="jobs.zwSonChoseId === item.id ? 'fl-item-chose' : ''"
          @click="chageFliter('zw', item, 'son')"
          >{{ item.name }}
        </el-link>
      </label>
    </div>
    <div class="filtrate-fli1-view">
      <div class="fil-title-view">城 市</div>
      <div
        class="fil-items-view"
        :class="moreCityShow ? 'more-fil-items-view' : ''"
      >
        <label v-for="item in provinceList" :key="item.id">
          <el-link
            class="fl-item"
            :underline="false"
            :class="addr.proFlChoseId === item.id ? 'fl-item-chose' : ''"
            @click="chageFliter('pro', item.id)"
            >{{ item.name }}
          </el-link>
        </label>
      </div>
      <div v-show="!moreCityShow" class="more-view" @click="showMoreCity">
        <span>更多</span> <i class="el-icon-arrow-down"></i>
      </div>
      <div v-show="moreCityShow" class="more-view" @click="showMoreCity">
        <span>收起</span> <i class="el-icon-arrow-up"></i>
      </div>
    </div>
    <!--    二级城市-->
    <div class="two-city-item" v-show="cityList && addr.proFlChoseId !== 'all'">
      <label v-for="item in cityList" :key="item.id">
        <el-link
          class="fl-item"
          :underline="false"
          :class="addr.cityFlChoseId === item.id ? 'fl-item-chose' : ''"
          @click="chageFliter('city', item.id)"
          >{{ item.name }}
        </el-link>
      </label>
    </div>
    <!--    三级城市-->
    <div
      v-if="['findjob', 'transfer', 'take'].includes(searchpage)"
      class="two-city-item"
      style="background-color: rgba(248, 248, 248, 0.7)"
      v-show="regionList.length > 0 && addr.cityFlChoseId !== 'all'"
    >
      <label v-for="item in regionList" :key="item.id">
        <el-link
          class="fl-item"
          :underline="false"
          :class="addr.regionFlChoseId === item.id ? 'fl-item-chose' : ''"
          @click="chageFliter('region', item.id)"
          >{{ item.name }}
        </el-link>
      </label>
    </div>

    <div
      v-show="['talent', 'findjob'].includes(searchpage)"
      class="filtrate-fli1-view"
    >
      <div class="fil-title-view">薪 资</div>
      <div
        class="fil-items-view"
        :class="moreXzShow ? 'more-fil-items-view' : ''"
      >
        <label v-for="item in salaryArr" :key="item.id">
          <el-link
            class="fl-item"
            :underline="false"
            :class="salaryChoseId == item.id ? 'fl-item-chose' : ''"
            @click="chageFliter('sal', item.id, item)"
            >{{ item.name }}
          </el-link>
        </label>
      </div>
      <!--      <div v-show="!moreXzShow" class="more-view" @click="showMoreXz">
        <span>更多</span> <i class="el-icon-arrow-down"></i>
      </div>
      <div v-show="moreXzShow" class="more-view" @click="showMoreXz">
        <span>收起</span> <i class="el-icon-arrow-up"></i>
      </div>-->
    </div>
    <!--    行业类型-->
    <div v-show="['comlist'].includes(searchpage)" class="filtrate-fli1-view">
      <div class="fil-title-view">行业类型</div>
      <div
        class="fil-items-view"
        :class="moreHyShow ? 'more-fil-items-view' : ''"
      >
        <label v-for="item in hyList" :key="item.id">
          <el-link
            class="fl-item"
            :underline="false"
            :class="hyId == item.id ? 'fl-item-chose' : ''"
            @click="chageFliter('hy', item.id, item)"
            >{{ item.name }}
          </el-link>
        </label>
      </div>
      <div v-show="!moreXzShow" class="more-view" @click="showHyList">
        <span>更多</span> <i class="el-icon-arrow-down"></i>
      </div>
      <div v-show="moreXzShow" class="more-view" @click="showHyList">
        <span>收起</span> <i class="el-icon-arrow-up"></i>
      </div>
    </div>
    <!--    公司规模-->
    <div v-show="['comlist'].includes(searchpage)" class="filtrate-fli1-view">
      <div class="fil-title-view">公司规模</div>
      <div class="fil-items-view">
        <label v-for="item in comPepleNum" :key="item.id">
          <el-link
            class="fl-item"
            :underline="false"
            :class="comNumIdx == item.id ? 'fl-item-chose' : ''"
            @click="chageFliter('pNum', item.id, item)"
            >{{ item.name }}
          </el-link>
        </label>
      </div>
    </div>
    <div
      v-show="['findjob', 'comlist'].includes(searchpage)"
      class="filtrate-fli1-view"
    >
      <div class="fil-title-view">公司福利</div>
      <div class="fil-items-view">
        <label v-for="item in userClass.welfares" :key="item.id">
          <el-link
            class="fl-item"
            :underline="false"
            :class="welfareId == item.id ? 'fl-item-chose' : ''"
            @click="chageFliter('wfe', item.id, item)"
            >{{ item.name }}
          </el-link>
        </label>
      </div>
      <!--      <div v-show="!moreXzShow" class="more-view" @click="showMoreXz">
              <span>更多</span> <i class="el-icon-arrow-down"></i>
            </div>
            <div v-show="moreXzShow" class="more-view" @click="showMoreXz">
              <span>收起</span> <i class="el-icon-arrow-up"></i>
            </div>-->
    </div>

    <div
      v-if="['transfer', 'take'].includes(searchpage)"
      class="filtrate-fli1-view"
    >
      <div class="fil-title-view">面 积</div>
      <div class="fil-items-view">
        <label v-for="item in areaList" :key="item.id">
          <el-link
            class="fl-item"
            :underline="false"
            :class="areaChoseId == item.query ? 'fl-item-chose' : ''"
            @click="chageFliter('area', item.query, item)"
            >{{ item.title }}
          </el-link>
        </label>
      </div>
      <!-- <div class="more-view">
        <span>更多</span> <i class="el-icon-arrow-down"></i>
      </div> -->
    </div>
    <div
      v-if="['transfer', 'take'].includes(searchpage)"
      class="filtrate-fli1-view"
    >
      <div class="fil-title-view">租 金</div>
      <div class="fil-items-view">
        <label v-for="item in rentList" :key="item.id">
          <el-link
            class="fl-item"
            :underline="false"
            :class="rentChoseId == item.query ? 'fl-item-chose' : ''"
            @click="chageFliter('rent', item.query, item)"
            >{{ item.title }}
          </el-link>
        </label>
      </div>
      <!-- <div class="more-view">
        <span>更多</span> <i class="el-icon-arrow-down"></i>
      </div> -->
    </div>

    <div v-if="searchpage == 'transfer'" class="filtrate-fli1-view">
      <div class="fil-title-view">状 态</div>
      <div class="fil-items-view">
        <label v-for="item in shopStatus" :key="item.id">
          <el-link
            class="fl-item"
            :underline="false"
            :class="statusChoseId == item.id ? 'fl-item-chose' : ''"
            @click="chageFliter('statu', item.id, item)"
            >{{ item.title }}
          </el-link>
        </label>
      </div>
      <!-- <div class="more-view">
        <span>更多</span> <i class="el-icon-arrow-down"></i>
      </div> -->
    </div>

    <div class="more-fli1-view">
      <div
        class="more-fil-title-view2"
        v-if="['talent', 'findjob'].includes(searchpage)"
      >
        更多
      </div>
      <div
        class="more-fil-items-view"
        v-if="['talent', 'findjob'].includes(searchpage)"
      >
        <el-select
          v-if="searchpage === 'findjob'"
          @visible-change="getHyLists"
          v-model="moreHyKey"
          placeholder="公司行业"
          @change="selectHyChange"
        >
          <el-option
            v-for="item in moreHyArr"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="moreEduKey"
          placeholder="学历要求"
          @change="selectEduChange"
        >
          <el-option
            v-for="item in userClass.edu"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="moreExpKey"
          placeholder="工作经验"
          @change="selectExpChange"
        >
          <el-option
            v-for="item in userClass.exp"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-select
          v-if="searchpage === 'talent'"
          v-model="moreSexKey"
          @change="selectSexChange"
          placeholder="性别要求"
        >
          <el-option
            v-for="item in sexFlitArr"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <!--        <el-select
                    v-if="searchpage === 'findjob'"
                    v-model="moreDutyKey"
                    @change="selectDutyChange"
                    @visible-change="getDutyLists"
                    placeholder="到岗时间"
                >
                  <el-option
                      v-for="item in dutyFlitArr"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>-->
        <!--        <el-select
            v-if="searchpage === 'findjob'"
            v-model="moreTypeKey"
            @change="selectTypeChange"
            placeholder="求职类型"
        >
          <el-option
              v-for="item in userClass.type"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          >
          </el-option>
        </el-select>-->
        <!--        <el-select v-model="moreAdtimeKey" placeholder="发布时间"
                           v-if="searchpage === 'talent'">
                   <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    :disabled="item.disabled"
                  >
                  </el-option>
                </el-select>-->
      </div>
      <div
        v-if="searchpage != 'partList'"
        class="filtrate-clear-view"
        @click="clearFiltrateFun"
      >
        <img class="cl-img" src="@/assets/user_resume_del.png" alt="" />
        <span>清空筛选条件</span>
      </div>
    </div>
    <!-- <div class="filtrate-clear-view" @click="clearFiltrateFun">
      <img class="cl-img" src="@/assets/user_resume_del.png" alt="" />
      <span>清空筛选条件</span>
    </div> -->
  </div>
</template>
<!--
传入jobClass:{job_id,jobclass_id} 定位到所选的职位
选职位：zw {"parent","son"}
选择城市：pro {"proId","cityId"}
-->
<script>
import {
  getJobClassList,
  getProvinceList,
  getSalaryList,
  getTransferAreaFil,
} from "@/api/commen/filtrateComp";
import tMapInit from "@/utils/tMapInit.js";
import {
  getCityList,
  getHyList,
  getUserJobStatus,
} from "@/api/commen/register";
import { getCompanyPepleNum } from "@/api/compe/companyInf";

export default {
  name: "filtratecomp",
  props: {
    searchpage: {
      type: String,
    },
    jobClass: {
      type: Object,
    },
  },
  data() {
    return {
      jobs: {
        zwFlList: [],
        zwFlChoseId: "all",
        zwSonList: [],
        zwSonChoseId: "all",
        moreZwShow: false,
      },
      provinceList: [],
      cityList: [],
      regionList: [],
      addr: {
        proFlChoseId: "all",
        cityFlChoseId: "all",
        regionFlChoseId: "all",
      },
      salaryChoseId: "all",
      welfareId: 0,
      areaList: [],
      areaChoseId: "",
      rentList: [],
      rentChoseId: "",
      transferList: [],
      transferChoseId: "",
      shopStatus: [
        { title: "不限", id: 0 },
        { title: "转让中", id: 1 },
        { title: "已转出", id: 2 },
      ],
      statusChoseId: 0,
      map: null,
      T: null,
      moreCityShow: false,
      moreXzShow: false,
      moreEduKey: "",
      moreExpKey: "",
      moreSexKey: "",
      moreTypeKey: "",
      moreAdtimeKey: "",
      moreHyKey: "",
      moreHyArr: "",
      userClass: {
        edu: [],
        exp: [],
        salary: [],
        type: [],
        welfares: [],
      },
      moreDutyKey: "",
      dutyFlitArr: [],
      comPepleNum: [],
      comNumIdx: "all",
      sexFlitArr: [
        { name: "不限", id: 0 },
        { name: "男", id: 1 },
        { name: "女", id: 2 },
      ],
      hyList: [],
      hyId: "all",
      moreHyShow: false,
    };
  },
  computed: {
    salaryArr() {
      let userBz = this.searchpage === "findjob" ? "resu" : "com";
      let tmpArr = [];
      this.userClass.salary.forEach((e) => {
        if (e.id === "all") {
          tmpArr.push(e);
        }
        if (e.type === userBz) {
          tmpArr.push(e);
        }
      });
      return tmpArr;
    },
  },
  mounted() {
    // 初始化天地图
    this.$nextTick(function () {
      // 仅在整个视图都被渲染之后才会运行的代码
      this.initTdtMap();
    });
  },
  created() {
    this.getJobClassList(this.jobClass);
    this.getProvinceList();
    this.getSalaryList();
    this.getTransferAreaFil();
    this.getComPepleNum();
    this.selectHyList();
  },
  methods: {
    seesionEvent(type, name, data) {
      if (type === 1) {
        let dataStr = sessionStorage.getItem(name);
        if (dataStr != null) {
          return JSON.parse(dataStr);
        } else {
          return "";
        }
      } else {
        if (data && data.length > 0) {
          sessionStorage.setItem(name, JSON.stringify(data));
        }
      }
    },
    selectHyList() {
      let sessionName = "filtrate_hylist";
      let cacheHy = this.seesionEvent(1, sessionName);
      if (cacheHy) {
        this.hyList = cacheHy;
      } else {
        getHyList().then((e) => {
          let hys = e.data.hy_list;
          hys.forEach(function (e) {
            if (e.id === "1078") {
              e.id = "all";
              e.name = "不限";
            }
            delete e.sort;
          });
          this.hyList = hys;
          this.seesionEvent(2, sessionName, hys);
        });
      }
    },
    getComPepleNum() {
      let sessionName = "filtrate_PepleNum";
      let pepleNum = this.seesionEvent(1, sessionName);
      if (pepleNum) {
        this.comPepleNum = pepleNum;
      } else {
        getCompanyPepleNum({ name: "job_mun" }).then((e) => {
          let comNum = e.data.list.job_mun;
          comNum.unshift({
            id: "all",
            name: "不限",
          });
          this.seesionEvent(2, sessionName, comNum);
          this.comPepleNum = comNum;
        });
      }
    },
    getDutyLists(e) {
      if (!e) return;
      getUserJobStatus().then((res) => {
        this.dutyFlitArr = res.data.user_report_list;
      });
    },
    getHyLists(e) {
      if (e) {
        let cacheName = "getHyLists_fc" + localStorage.usertype;
        let myList = sessionStorage.getItem(cacheName);
        if (myList) {
          this.moreHyArr = JSON.parse(myList);
        } else {
          getHyList().then((res) => {
            sessionStorage.setItem(cacheName, JSON.stringify(res.data.hy_list));
            this.moreHyArr = res.data.hy_list;
          });
        }
      }
    },
    //定位职位类型
    idxJobs(jobId, jobClassId) {
      if (this.jobs.zwFlList.length <= 0) {
        return;
      }
      this.jobs.zwFlList.forEach((item) => {
        if (item.id === jobId) {
          this.jobs.zwSonList = item.next_list;
        }
      });
      this.jobs.zwSonChoseId = jobClassId; //子集
      this.jobs.zwFlChoseId = jobId; //父级
    },
    showMoreZw() {
      this.jobs.moreZwShow = !this.jobs.moreZwShow;
    },
    showMoreCity() {
      this.moreCityShow = !this.moreCityShow;
    },
    showMoreXz() {
      this.moreXzShow = !this.moreXzShow;
    },
    showHyList() {
      this.moreHyShow = !this.moreHyShow;
    },
    initTdtMap() {
      tMapInit
        .init()
        .then((T) => {
          this.T = T;
          var lo = new T.Geolocation();
          var fu = function (e) {
            console.log(e);
          };
          lo.getCurrentPosition(fu);
        })
        .catch();
    },
    getJobClassList(jobClass) {
      let cacheName = "getJobClassList_fc" + localStorage.usertype;
      let data = sessionStorage.getItem(cacheName);
      if (data) {
        this.jobs.zwFlList = JSON.parse(data);
        if (
          jobClass &&
          jobClass.job_id !== "all" &&
          jobClass.jobclass_id !== "all"
        ) {
          this.idxJobs(jobClass.job_id, jobClass.jobclass_id);
        }
      } else {
        getJobClassList()
          .then((result) => {
            result.data.list.forEach((item) => {
              item.next_list.unshift({
                id: "all",
                keyid: "all",
                name: "全部",
              });
            });
            result.data.list.unshift({
              id: "all",
              keyid: "all",
              name: "全部",
            });
            this.jobs.zwFlList = result.data.list;
            sessionStorage.setItem(
              cacheName,
              JSON.stringify(this.jobs.zwFlList),
              86400
            );
            if (
              Object.keys(jobClass).length > 0 &&
              jobClass.job_id !== "all" &&
              jobClass.jobclass_id !== "all"
            ) {
              this.idxJobs(jobClass.job_id, jobClass.jobclass_id);
            }
          })
          .catch(() => {});
      }
    },
    getProvinceList() {
      let cacheName = "getProvinceList_fc" + localStorage.usertype;
      let list = sessionStorage.getItem(cacheName);
      if (list) {
        this.provinceList = JSON.parse(list);
      } else {
        getProvinceList()
          .then((result) => {
            result.data.province.unshift({
              id: "all",
              keyid: "all",
              name: "全国",
            });
            sessionStorage.setItem(
              cacheName,
              JSON.stringify(result.data.province),
              86400
            );
            this.provinceList = result.data.province;
          })
          .catch(() => {});
      }
    },
    getSalaryList() {
      let cacheName = "getSalaryList_fc" + localStorage.usertype;
      let mylist = sessionStorage.getItem(cacheName);
      if (mylist) {
        this.userClass = JSON.parse(mylist);
      } else {
        getSalaryList()
          .then((result) => {
            result.data.salary.unshift({
              id: "all",
              keyid: "all",
              max_value: "all",
              min_value: "all",
              name: "不限",
            });
            sessionStorage.setItem(cacheName, JSON.stringify(result.data));
            this.userClass = result.data;
          })
          .catch(() => {});
      }
    },
    getTransferAreaFil() {
      let cacheName = "getTransferAreaFil_fc" + localStorage.usertype;
      let myiLst = sessionStorage.getItem(cacheName);
      if (myiLst) {
        let cArr = JSON.parse(myiLst);
        this.areaList = cArr.area;
        this.rentList = cArr.rent;
        this.transferList = cArr.transferFee;
      } else {
        getTransferAreaFil()
          .then((result) => {
            sessionStorage.setItem(cacheName, JSON.stringify(result.data));
            this.areaList = result.data.area;
            this.rentList = result.data.rent;
            this.transferList = result.data.transferFee;
          })
          .catch(() => {});
      }
    },

    chageFliter(type, id, item) {
      switch (type) {
        case "zw": {
          if (item === "pt" && id.id !== "all") {
            this.jobs.zwFlChoseId = id.id;
            this.jobs.zwSonChoseId = "all";
            this.jobs.zwSonList = id.next_list;
          } else if (item === "son" && id.id !== "all") {
            this.jobs.zwSonChoseId = id.id;
          } else if (item === "pt") {
            this.jobs.zwFlChoseId = id.id;
            this.jobs.zwSonChoseId = "all";
            this.zwSonList = [];
          } else if (item === "son") {
            this.jobs.zwSonChoseId = "all";
          }
          this.$emit("toFliterList", "zw", {
            parent: this.jobs.zwFlChoseId,
            son: this.jobs.zwSonChoseId,
          });
          break;
        }
        case "pro": {
          if (id !== "all") {
            getCityList(id).then((res) => {
              res.data.province.unshift({
                id: "all",
                name: "全部",
              });
              this.cityList = res.data.province;
            });
          } else {
            this.cityList = [];
          }
          this.addr.proFlChoseId = id;
          this.addr.cityFlChoseId = "all";
          this.$emit("toFliterList", "pro", {
            proId: id,
            cityId: "all",
            threeId: "all",
          });
          break;
        }
        case "city": {
          if (id !== "all") {
            getCityList(id).then((res) => {
              this.regionList = res.data.province;
              if (this.regionList.length > 0) {
                res.data.province.unshift({
                  id: "all",
                  name: "全部",
                });
              }
            });
          } else {
            this.regionList = [];
          }
          this.addr.regionFlChoseId = "all";
          this.addr.cityFlChoseId = id;
          this.$emit("toFliterList", "pro", {
            proId: this.addr.proFlChoseId,
            cityId: id,
            threeId: "all",
          });
          break;
        }
        case "region": {
          this.addr.regionFlChoseId = id;
          this.$emit("toFliterList", "pro", {
            proId: this.addr.proFlChoseId,
            cityId: this.addr.cityFlChoseId,
            threeId: id,
          });
          break;
        }
        case "sal": {
          this.salaryChoseId = id;
          this.$emit("toFliterList", "sal", item);
          break;
        }
        case "area": {
          this.areaChoseId = id;
          this.$emit("toFliterList", "area", item);
          break;
        }
        case "rent": {
          this.rentChoseId = id;
          this.$emit("toFliterList", "rent", item);
          break;
        }
        case "statu": {
          this.statusChoseId = id;
          this.$emit("toFliterList", "statu", item);
          break;
        }
        case "wfe": {
          this.welfareId = id;
          this.$emit("toFliterList", "wfe", id !== "0" ? id : "all");
          break;
        }
        case "pNum": {
          this.$emit("toFliterList", "scale", id);
          this.comNumIdx = id;
          break;
        }
        case "hy": {
          this.$emit("toFliterList", "hy", id);
          this.hyId = id;
          break;
        }
        default: {
          this.$message.error("类型错误");
        }
      }
    },
    selectHyChange(e) {
      this.$emit("toFliterList", "hy", e !== "1078" ? e : "all");
    },
    selectEduChange(e) {
      this.$emit("toFliterList", "edu", e !== "145" ? e : "all");
    },
    selectSexChange(e) {
      this.$emit("toFliterList", "sex", e !== 0 ? e : "all");
    },
    selectExpChange(e) {
      this.$emit("toFliterList", "exp", e);
    },
    selectTypeChange(e) {
      this.$emit("toFliterList", "type", e !== "0" ? e : "all");
    },
    selectDutyChange(e) {
      this.$emit("toFliterList", "exp", e);
    },
    clearFiltrateFun() {
      this.jobs.zwFlChoseId = "all";
      this.jobs.zwSonChoseId = "all";
      this.addr.proFlChoseId = "all";
      this.addr.cityFlChoseId = "all";
      this.addr.regionFlChoseId = "all";
      this.salaryChoseId = "all";
      this.areaChoseId = "";
      this.rentChoseId = "";
      this.transferChoseId = "";
      this.statusChoseId = 0;
      this.welfareId = 0;
      this.moreHyKey = "";
      this.moreExpKey = "";
      this.moreEduKey = "";
      this.moreDutyKey = "";
      this.moreTypeKey = "";
      this.moreSexKey = "";
      this.hyId = "all";
      this.comNumIdx = "all";
      this.$emit("clearFiltrateFun");
    },
    //获取学历
    getXlClass() {
      /*      if (e) {
              getXlClass({ name: "user_edu" })
                .then((result) => {
                  this.eduFliteArr = result.data.list.user_edu;
                })
                .catch(() => {});
            }*/
    },
  },
};
</script>

<style>
.filtrate-view {
  padding-bottom: 42px;
  width: 1450px;
}

.fil-items-view {
  width: 1260px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.more-fil-items-view {
  -webkit-line-clamp: 3;
  width: 1180px;
}

.filtrate-view .filtrate-fli1-view {
  display: flex;
  align-items: flex-start;
  margin-top: 26px;
  position: relative;
}

.filtrate-view .filtrate-fli1-view .fil-title-view {
  width: 110px;
  height: 40px;
  background: #f5f6fa;
  border-radius: 8px;
  font-size: 20px;

  font-weight: 600;
  color: #191919;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 28px;
}

.filtrate-view .filtrate-fli1-view .fl-item {
  font-size: 18px;

  font-weight: 400;
  color: #757575;
  margin-left: 32px;
  /* margin-bottom: 20px; */
  line-height: 40px;
}

.two-city-item .fl-item {
  font-size: 18px;

  font-weight: 400;
  color: #757575;
  margin: 0 10px;
  line-height: 40px;
}

.two-city-item {
  background-color: #f8f8f8;
  margin-left: 160px;
  margin-top: 10px;
  border-radius: 5px;
  width: 1200px;
  border: 1px solid #e7e7e7;
}

.two-city-item .fl-item-chose {
  font-weight: 600;
  font-size: 18px;
  color: #fe6002 !important;
}

.filtrate-view .filtrate-fli1-view .fl-item-chose {
  font-weight: 600;
  font-size: 20px;
  color: #fe6002;
}

.filtrate-view .filtrate-fli1-view .more-view {
  font-size: 18px;

  font-weight: 400;
  color: #757575;
  position: absolute;
  margin-top: 10px;
  right: 0;
  cursor: pointer;
}

.filtrate-view .filtrate-clear-view {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: right;
  font-weight: 400;
  font-size: 16px;
  color: #757575;
  line-height: 19px;
  cursor: pointer;
}

.filtrate-view .filtrate-clear-view .cl-img {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.more-fli1-view {
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 20px;
}

.more-fli1-view .more-fil-title-view2 {
  font-weight: 600;
  font-size: 20px;
  color: #191919;
  line-height: 28px;
  margin-left: 32px;
}

.more-fli1-view .more-fil-items-view {
  margin-left: 98px;
}

.more-fli1-view .more-fil-items-view .el-select {
  width: 124px;
  height: 36px;
  margin-right: 60px;
}

.more-fli1-view .more-fil-items-view .el-select .el-input__inner {
  background-color: #f5f6fa;
  color: #191919;
  border: none;
}
</style>