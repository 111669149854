<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2024-03-04 18:26:06
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-03-21 18:34:11
 * @FilePath: /lcwpcvue/src/views/userEdtResume.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>

  <div class="user-home-page">
    <div class="top-zk-div"></div>
    <userinfleftmen
      :activeIndex="'/useredtresume'"
      class="left-menu"
    ></userinfleftmen>
    <div class="set-wight-resu">
      <edtresume
          :activeIndex="'/useredtresume'"
          class="userinf-index"
      ></edtresume>
    </div>
  </div>
</template>

<script>
import userinfleftmen from "@/components/userInfPageLeftMenu.vue";
import edtresume from "@/components/edtResume.vue";

export default {
  components: {
    userinfleftmen,
    edtresume,
  },
};
</script>

<style>
.set-wight-resu{
  width: 1220px;
}
</style>