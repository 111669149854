<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-05-25 14:35:00
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-06-21 18:42:42
 * @FilePath: /lcwpcvue/src/components/choseCity.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="chose-city">
    <el-dialog
      title="请选择城市>>"
      :visible="dialogVisible2"
      width="1003px"
      @close="handleClose"
    >
      <div class="citys-ychose">
        <span class="yx-view">已选择</span>
        <span class="tsmin">（最多可以选择5项）</span>
        <label v-for="(item,idx) in sladdreArr" :key="item.id">
          <div class="yxct">
            <span>{{ item.name }}</span>
            <i @click="delSlArr(item,idx)" class="el-icon-close"></i>
          </div>
        </label>
      </div>

      <div class="citys-view">
        <div class="prce">
          <label
            v-for="item in provinceList"
            :key="item.id"
            @click="changProvinceId(item)"
          >
            <div class="item" :class="provinceid == item.id ? 'itsl' : ''">
              {{ item.name }}
            </div>
          </label>
          <!-- <div class="item itsl">上海</div>
          <div class="item">天津</div>
          <div class="item">重庆</div> -->
        </div>
        <div class="citys">
          <!-- <div
            class="item"
            :class="cityId == 'all' ? 'itsl' : ''"
            @click="changeCitysId({ id: 'all' })"
          >
            全部
          </div> -->
          <label v-for="item in cityList" :key="item.id">
            <div
              class="item"
              :class="cityId == item.id ? 'itsl' : ''"
              @click="changeCitysId(item)"
            >
              {{ item.name }}
            </div>
          </label>
          <!-- <div class="item itsl">大兴</div>
          <div class="item">海淀</div> -->
        </div>
        <div class="ara">
          <!-- <div
            v-show="cityId != 'all'"
            class="last"
            :class="areaId == 'all' ? 'arasl' : ''"
            @click="choseArea({ id: 'all' })"
          >
            全部
          </div> -->
          <label v-for="item in areaList" :key="item.id">
            <div
              class="last"
              :class="areaId == item.id ? 'arasl' : ''"
              @click="choseArea(item)"
            >
              {{ item.name }}
            </div>
          </label>

          <el-button type="primary" @click="saveJobAddr">确定</el-button>
          <!-- <div class="last arasl">南城区</div>
          <div class="last">南城区</div>
          <div class="last">南城区</div> -->
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getProvinceList,
  getCityList,
  getAreaList,
} from "@/api/commen/register";
import { Message } from "element-ui";
export default {
  props: {
    dialogVisible2: {
      type: Boolean,
      default: false,
    },
    isShowAll: {
      type: Boolean,
      default: false,
    },
    sladdreArrF: {
      type: Object,
      default: Object,
    },
  },
  data() {
    return {
      provinceList: [],
      cityList: [],
      areaList: [],
      provinceid: 0, // 省id
      cityId: "all", // 市id
      areaId: "all",
      slProArr: [],//省
      slCtArr: [],//市
      slArArr: [],//区
      sladdreArr: [],//已选的地址对象
      chPrIt: {},
      chCityIt: {},
    };
  },
  computed: {
    // sladdreArr: {
    //   get() {
    //     return this.sladdreArrF;
    //   },
    //   set(newValue) {
    //     this.$emit("update:sladdreArrF", newValue);
    //   },
    // },
  },
  created() {
    this.getProvinceList();
    console.log("带过来的数据：",this.sladdreArrF)
    if (Object.keys(this.sladdreArrF).length > 0){
      this.slProArr = this.sladdreArrF.pid;
      this.slCtArr = this.sladdreArrF.cid;
      this.slArArr = this.sladdreArrF.tid;
      this.sladdreArr = this.sladdreArrF.city_arr;
    }
  },
  methods: {
    //获取省份列表
    getProvinceList() {
      getProvinceList()
        .then((response) => {
          console.log("获取列表。。", response);
          this.provinceList = response.data.province;
          this.provinceList.forEach(e=>{
            e.level = "1";
          })
          if (this.isShowAll) {
            this.provinceList.unshift({
              id: "4601",
              keyid: "0",
              name: "不限",
              level:'1'
            });
          }
          this.provinceid = response.data.province[0].id;
          this.chPrIt = response.data.province[0];
          this.getCityList(response.data.province[0].id);
          // this.listLoading = false
        })
        .catch((error) => {
          console.log(error);
          // this.listLoading = false
        });
    },
    getCityList(id) {
      getCityList(id)
        .then((response) => {
          // console.log("获取城市。。", response);
          // response.data.forEach((element) => {
          //   console.log(Date.parse(element.createdAt))
          //   element.addtime = parseTime(Date.parse(element.createdAt))
          // })
          this.cityList = response.data.province;
          this.cityList.forEach(e=>{
            e.level = '2';
          });
          // this.provinceid = response.data.province[0].id;
          //
          // this.listLoading = false
        })
        .catch((error) => {
          console.log(error);
          // this.listLoading = false
        });
    },
    getAreaList(id) {
      getAreaList(id)
        .then((result) => {
          console.log("获取区", result);
          this.areaList = result.data;
          this.areaList.forEach(e=>{
            e.level = '3';
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    changProvinceId(item) {
      if (this.sladdreArr.length >= 5) {
        return Message({
          message: "最多只能选五项",
          type: "error",
          duration: 3 * 1000,
          showClose: true,
          offset: 100,
        });
      }
      this.provinceid = item.id;
      this.cityId = "all";
      this.areaId = "all";
      this.areaList = [];
      this.chPrIt = item;
      var afarr = [];

      this.sladdreArr.push(item);
      var flt2 = item.id.substring(0, 2);
      this.sladdreArr.forEach((element) => {
        var flt1 = element.keyid.substring(0, 2);
        if (flt1 !== flt2) {
          afarr.push(element);
        }
      });

      // 处理市字符串
      let tempCityIds = [];
      this.slCtArr.forEach(e=>{
        var cityId = e.substring(0, 2);
        if (cityId !== flt2){
          tempCityIds.push(e);
        }
      });
      this.slCtArr = tempCityIds;

      // 处理区字符串
      let tempArIds = [];
      this.slArArr.forEach(e=>{
        var areaId = e.substring(0, 2);
        if (areaId !== flt2){
          tempArIds.push(e);
        }
      });
      this.slArArr = tempArIds;

      this.slProArr.push(this.provinceid);
      this.sladdreArr  = Array.from(new Set(afarr));
      this.slProArr = Array.from(new Set(this.slProArr));
      this.getCityList(item.id);
    },

    changeCitysId(item) {
      if (this.sladdreArr.length < 5) {
        this.chCityIt = item;
        let tmpSladdreArr = [];
        this.sladdreArr.push(item);
        this.sladdreArr.forEach((element) => {
          if (element.id !== item.keyid && element.keyid !== item.id) {
            tmpSladdreArr.push(element);
          }
        });
        var flt2 = item.id.substring(0, 2);
        //没有选省的情况下自动添加
        let isHavePid = false;
        this.slProArr.forEach(e=>{
          var cityId = e.substring(0, 2);
          if (cityId === flt2){
            isHavePid = true;
          }
        });
        if (!isHavePid){
          this.slProArr.push(flt2 + '00');
        }
        // 处理区字符串
        let tempArIds = [];
        this.slArArr.forEach(e=>{
          var areaId = e.substring(0, item.id.length);
          if (areaId !== item.id){
            tempArIds.push(e);
          }
        });
        this.slArArr = tempArIds;

        this.slCtArr.push(item.id)
        this.slCtArr = Array.from(new Set(this.slCtArr));
        this.sladdreArr = Array.from(new Set(tmpSladdreArr));
      } else {
        Message({
          message: "最多只能选五项",
          type: "error",
          duration: 3 * 1000,
          showClose: true,
          offset: 100,
        });
      }
      this.getAreaList(item.id);
    },
    choseArea(item) {
      this.areaId = item.id;
      if (item.id == "all") {
        if (this.sladdreArr.length < 5) {
          this.slProArr.push(this.provinceid);
          this.slCtArr.push(this.cityId);
          this.sladdreArr.push(this.chCityIt);
        } else {
          Message({
            message: "最多只能选五项",
            type: "error",
            duration: 3 * 1000,
            showClose: true,
            offset: 100,
          });
        }
        return false;
      }
      if (this.sladdreArr.length < 5) {
        let tempSladdreArr = [];
        this.sladdreArr.push(item);
        this.sladdreArr.forEach((element) => {
          if (element.id !== item.keyid) {
            tempSladdreArr.push(element);
          }
        });
        if (!this.slArArr.includes(item.id)){
          this.slArArr.push(item.id);
        }
        this.sladdreArr = Array.from(new Set(tempSladdreArr));
      } else {
        Message({
          message: "最多只能选五项",
          type: "error",
          duration: 3 * 1000,
          showClose: true,
          offset: 100,
        });
      }
    },

    delSlArr(item,index) {
      this.sladdreArr.splice(index, 1);
      if (item.level === '1'){
        this.slProArr.forEach((im,idx)=>{
          if (im === item.id){
            this.slProArr.splice(idx, 1)
          }
        });
      }else if (item.level === '2'){
        let flt2 = item.id.substring(0, 2);
        let cityClass = 0;
        let deletIdx = 0;
        this.slCtArr.forEach((im,idx)=>{
          let flt = im.substring(0, 2);
          if (flt === flt2){
            cityClass++;
          }
          if (im === item.id){
            deletIdx = idx;
          }
        });
        this.slCtArr.splice(deletIdx, 1)
        if (cityClass <= 1){
          this.slProArr.forEach((im,idx)=>{
            let pidClass = im.substring(0, 2);
            if (pidClass === flt2){
              this.slProArr.splice(idx, 1)
            }
          });
        }
      }else if (item.level === '3'){
        let delIdx = 0;
        let delCity = 0;
        let isDel = false;
        let selfKeyId = item.id.substring(0,item.keyid.length);
        this.slArArr.forEach((e,idx)=>{
          let pid = e.substring(0,item.keyid.length);
          if (pid === item.keyid){
            // 判断是否有同级别的区县，没有则删除上级市
            delCity++;
          }
          if (e === item.id){
            delIdx = idx;
            isDel = true;
          }
        });
        if (isDel){
          //删除自身
          this.slArArr.splice(delIdx, 1);
        }

        //如果同级别的区县就一个，则删除上一级
        let self_sub_2 = item.id.substring(0,2);
        if (delCity <= 1){
          this.slCtArr.forEach((e,idx)=>{
            if (e === selfKeyId){
              this.slCtArr.splice(idx,1);
            }
          });
          delCity = 0;
          this.slCtArr.forEach((e)=>{
            if (e.startsWith(self_sub_2)){
              // 判断是否还有同级别的市，如果没有一并删除省
              delCity++;
            }
          });
          // 如果美容有级别的市级一并删除掉省
          if (delCity < 1){
            this.slProArr.forEach((e,idx)=>{
              if (e.startsWith(self_sub_2)){
                // 删除顶级 省份
                this.slProArr.splice(idx,1);
              }
            })
          }
        }

      }
    },

    saveJobAddr() {
      this.$emit("saveJobAddr", this.slProArr, this.slCtArr, this.sladdreArr,this.slArArr);
    },

    handleClose() {
      this.$emit("closeDilog2");
    },
  },
};
</script>

<style scoped>
.chose-city .el-dialog__wrapper .el-dialog {
  border-radius: 15px;
  height: 639px;
}

.el-dialog__wrapper .el-dialog .el-dialog__title {
  font-size: 26px;

  font-weight: 600;
  color: #222222;
}
.el-dialog__wrapper .el-dialog__body {
  padding: 0px 0;
}
.citys-ychose {
  display: flex;
  align-items: flex-end;
  border-bottom: 1px solid #dddddd;
  padding: 0 20px;
  padding-bottom: 18px;
}

.citys-ychose .yx-view {
  font-size: 20px;
  color: #222222;

  font-weight: 600;
}

.citys-ychose .tsmin {
  font-size: 12px;
  color: #939597;
  font-weight: 400;
}
.citys-ychose .yxct {
  padding: 0 5px;
  height: 26px;
  background-color: #ff8136;
  border-radius: 2px;
  font-size: 14px;

  font-weight: 400;
  color: #fffdfc;
  text-align: center;
  line-height: 26px;
  margin-left: 12px;
}
.citys-ychose .yxct i {
  margin-left: 12px;
}

.citys-view {
  display: flex;
}

.citys-view .prce,
.citys-view .citys {
  /* width: 80px; */
  height: 534px;
  font-size: 18px;

  font-weight: 400;
  color: #222222;
  border-right: 2px solid #e7e8eb;
  overflow: scroll;
}

.citys-view .item {
  width: 80px;
  padding: 14px 40px;
  cursor: pointer;
}
.citys-view .item:hover,
.citys-view .last:hover {
  background-color: rgba(255, 129, 54, 0.15);
  color: #fe6002;
}
.citys-view .itsl {
  background-color: rgba(255, 129, 54, 0.15);
  color: #fe6002;
}
.citys-view .ara {
  margin-left: 33px;
  max-width: 643px;
  height: 534px;
  overflow: scroll;
}

.citys-view .last {
  display: inline-block;
  width: 146px;
  height: 38px;
  background: #f5f6fa;
  border-radius: 1px;
  text-align: center;
  line-height: 38px;
  margin-right: 35px;
  margin-top: 24px;
  margin-bottom: 9px;
  cursor: pointer;
}

.citys-view .arasl {
  background-color: rgba(255, 129, 54, 0.15);
  color: #fe6002;
  border: 1px solid #fe6002;
}

.citys-view .el-button {
  width: 200px;
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
}
</style>