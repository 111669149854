<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-12-14 11:14:05
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-03-12 15:00:51
 * @FilePath: /lcwpcvue/src/components/comUser/addAddressDialog.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="add-addre-dialog">
    <el-dialog
      title="添加地址"
      :visible.sync="addAddrDialogShow"
      :show-close="true"
      width="55%"
      @open="dialogOpne"
      @close="dialogClose"
      :destroy-on-close="false"
    >
      <div class="tip-text-view"></div>

      <div class="edi-lin-item">
        <div class="items">
          <label class="title-address"
            ><span>门店地点</span
            ><span class="warning-banner"
              >(请填写真实地址，若查实造假，账号将被冻结～)</span
            ></label
          >
          <el-autocomplete
            popper-class="my-autocomplete"
            :fetch-suggestions="querySearch"
            placeholder="选择地址"
            @select="handleSelect"
            v-model="searchKeyWord"
            :trigger-on-focus="false"
          >
            <template v-slot="{ item }">
              <div class="addr-name">{{ item.name }}</div>
              <span class="addr-addr">{{ item.fulladdr }}</span>
            </template>
          </el-autocomplete>
        </div>

        <div class="items">
          <label class="title-address"><span>详细地点</span></label>
          <el-input
            placeholder="请填写详细地址"
            v-model="detailedAddress"
          ></el-input>
        </div>
      </div>
      <div v-show="mapShow" ref="tmap" class="tmap" id="tmap"></div>
      <div slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          :disabled="saveBtnDisabled"
          @click="saveAddress"
          >保存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import tMapInit from "@/utils/tMapInit.js";
import { searchAddressByGd } from "@/api/commen/addressManageApi.js";
import ticon from "@/assets/address_manr_mark.webp";
import { gcj02towgs84 } from "@/utils/WSCoordinate.js";
export default {
  name: "addaddressdialog",
  props: {
    addAddrDialogShow: {
      type: Boolean,
      default: false,
    },
    addressByXY: {
      type: Object,
    },
  },
  // watch: {
  //   addAddrDialogShow: function (newdata, old) {
  //     console.log(old);
  //     this.addAddrDialogShow = newdata;
  //   },
  // },
  data() {
    return {
      // querySearchAsync: "",
      map: null,
      T: null,
      searchKeyWord: "",
      querySearchArr: [],
      saveBtnDisabled: true,
      mapShow: true,
      // addAddrDialogShow: false,
      detailedAddress: "", //详细地址
    };
  },
  mounted() {
    // 初始化天地图
    this.$nextTick(function () {
      // 仅在整个视图都被渲染之后才会运行的代码
      this.initTdtMap();
    });
  },
  created() {},
  methods: {
    dialogOpne() {
      console.log("open");
      this.$nextTick(function () {
        this.mapShow = true;

        // 仅在整个视图都被渲染之后才会运行的代码
        this.initTdtMap();
      });
    },
    dialogClose() {
      console.log("close");
      this.$emit("closeMapPop");
      // this.addAddrDialogShow = false;
    },

    querySearch(e, cb) {
      console.log(e);
      var souce = "web";
      this.searchKeyWord = e;
      if (e.length > 0) {
        searchAddressByGd({
          type: "findKeyWord",
          keyword: e,
          souce: souce,
        })
          .then((result) => {
            console.log("搜索地址", result);
            cb(result.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    saveAddress() {
      this.addAddrDialogShow = false;
      this.searchKeyWord = "";
      this.choseAddreItem.detailedAddress = this.detailedAddress;
      console.log("choseAddreItem:", this.choseAddreItem);
      this.$emit("saveAddress", this.choseAddreItem);
    },
    handleSelect(e) {
      console.log("handleSelect(e):", e);
      this.choseAddreItem = e;
      this.searchKeyWord = e.fulladdr;
      var latlng = gcj02towgs84(e.x, e.y);
      this.choseAddreItem.x = latlng[0];
      this.choseAddreItem.y = latlng[1];
      console.log("转码后的xy：", latlng);
      tMapInit
        .init()
        .then((T) => {
          this.T = T;
          this.map.centerAndZoom(new T.LngLat(latlng[0], latlng[1]), 16);
          var icon = new T.Icon({
            iconUrl: ticon,
            iconSize: new T.Point(22, 34),
            iconAnchor: new T.Point(10, 25),
          });
          // var icon = new TIcon('static/marker.png', new TSize(19, 27), {
          // 	anchor: new TPixel(9, 27)
          // });
          //创建标注对象
          var point = new T.LngLat(latlng[0], latlng[1]);
          var marker = new T.Marker(point, {
            icon: icon,
          });
          this.map.clearOverLays();
          this.map.addOverLay(marker);
          this.saveBtnDisabled = false;
        })
        .catch();
    },
    async initTdtMap() {
      var that = this;
      let x = 116.40769;
      let y = 39.89945;
      let isHaveAddress = 0;
      console.log();
      if (that.addressByXY && that.addressByXY.lng && that.addressByXY.lat) {
        x = that.addressByXY.lng;
        y = that.addressByXY.lat;
        isHaveAddress = 1;
      }
      await tMapInit
        .init()
        .then((T) => {
          that.T = T;
          that.map = new T.Map("tmap", {
            projection: "EPSG:4326",
          });
          that.map.centerAndZoom(new T.LngLat(x, y), 16);
          if (isHaveAddress === 1) {
            var marker = new T.Marker(new T.LngLat(x, y));
            that.map.addOverLay(marker);
          }
        })
        .catch(() => {
          // console.log(err);
        });
    },
  },
};
</script>

<style>
.edi-lin-item .items .warning-banner {
  font-size: 16px;
  
  font-weight: 400;
  margin-left: 10px;
  color: red;
}
.add-addre-dialog .el-dialog__wrapper .el-dialog {
  border-radius: 10px;
}
.add-addre-dialog .tip-text-view {
  font-size: 18px;
  
  font-weight: 400;
  color: #757575;
  line-height: 24px;
  padding-top: 11px;
  border-bottom: 2px solid#EAECF5;
}

.add-addre-dialog .edi-lin-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.add-addre-dialog .edi-lin-item .items {
  width: 487px;
  position: relative;
  margin-top: 20px;
}

.add-addre-dialog .edi-lin-item .items .title-address {
  font-size: 16px;
  
  font-weight: 400;
  color: #757575;
  line-height: 30px;
}

.my-autocomplete .addr-name {
  font-size: 16px;
  
  font-weight: 600;
  color: #191919;
  line-height: 19px;
}
.my-autocomplete .addr-addr {
  font-size: 12px;
  
  font-weight: 400;
  color: #939597;
  line-height: 14px;
}
.tmap {
  margin: 0px;
  padding: 0px;
  width: 96%;
  height: 480px;
  z-index: 0;
  margin: 15px 2%;
}
.add-addre-dialog .dialog-footer {
  font-size: 18px;
  
  font-weight: 600;
  margin-top: 0 !important;
  text-align: right;
}
.el-dialog__footer .dialog-footer .el-button{
  width: 380px;
  height: 52px;
  
  font-weight: 600;
  font-size: 18px;
  color: #FFFFFF;
  border: none;
}
</style>