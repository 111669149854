/*
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-10-07 09:27:10
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-01-09 16:19:01
 * @FilePath: /lcwpcvue/src/api/commen/transferShop.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'

/**
* 获取店铺的类型
* @url shop/type
* @param {} params 
* @return 
*/

export function getShopTypeClass() {
  return request({
    url: 'shop/type',
    method: 'get'
  })
}


//获取转店列表
export function getTransferList(params) {
  return request({
    url: 'shop/transferSearch',
    method: 'get',
    params: params
  })
}
//获取转店的详情
export function getTransferDetail(id) {
  return request({
    url: 'shop/transferInfo',
    method: 'get',
    params: { 'id': id ,'from':'PC'}
  })
}

//我的转店信息
export function myShopList() {
  return request({
    url: 'shop/shopList',
    method: 'post'
  })
}
/**
 * 我的转店-删除
 * @param shopType zd:转店 jd：接店
 * @param id
 */
export function delShop(shopType, id) {
  return request({
    url: 'shop/delShop',
    method: 'post',
    data: {
      'shop_type': shopType,
      'id': id
    }
  })
}

//转让状态修改
export function transferQuickEdit(id, field, value) {
  return request({
    url: 'shop/transferQuickEdit',
    method: 'post',
    data: {
      'field': field,
      'value': value,
      'id': id
    }
  })
}

//接店状态修改
export function seekQuickEdit(id, field, value) {
  return request({
    url: 'shop/seekQuickEdit',
    method: 'post',
    data: {
      'field': field,
      'value': value,
      'id': id
    }
  })
}

/**
* 获取配套设置
* @url shop/peitaoV2
* @param {} params 
* @return 
*/

export function getShopFacility() {
  return request({
    url: 'shop/peitaoV2',
    method: 'get'
  })
}

/**
* 添加自定义配套
* @url shop/addpeitao
* @param {} params 
* @return 
*/
export function saveOtherFacility(params) {
  return request({
    url: 'shop/addpeitao',
    method: 'post',
    data: params
  })
}

/**
* 删除自定义配套
* @url shop/delPeitao
* @param {} params 
* @return 
*/
export function delOtherFacility(params) {
  return request({
    url: 'shop/delPeitao',
    method: 'post',
    data: params
  })
}

/**
* 获取更多标签
* @url shop/getMoreData
* @param {} params 
* @return 
*/
export function getShopBq() {
  return request({
    url: 'shop/getMoreData',
    method: 'get'
  })
}
/**
* 添加更多信息
* @url shop/postMore
* @param {} params 
* @return 
*/
export function addMoreTags(params) {
  return request({
    url: 'shop/postMore',
    method: 'post',
    data: params
  })
}

/**
* 检查有没有转店
* @url shop/isCanAddTransfer
* @param {} params 
* @return 
*/

export function checkHaveTransfer() {
  return request({
    url: 'shop/isCanAddTransfer',
    method: 'get'
  })
}


/**
* 新添加转店
* @url shop/transferAdd
* @param {} params 
* @return 
*/
export function addTransfer(params) {
  return request({
    url: 'shop/transferAdd',
    method: 'post',
    data: params
  })
}

/**
* 获取已有的转接店信息
* @url shop/transferData
* @param {} params 
* @return 
*/

export function getEdtiTransferDetail(params) {
  return request({
    url: 'shop/transferData',
    method: 'get',
    params: params
  })
}

/**
* 编辑转店信息
* @url shop/transferEdit
* @param {} params 
* @return 
*/
export function editTransfer(params) {
  return request({
    url: 'shop/transferEdit',
    method: 'post',
    data: params
  })
}


/**
* 转店生成订单
* @url shop/transferBuyInfo
* @param {id} params 
* @return 
*/

export function postTransferOrder(params) {
  return request({
    url: 'shop/transferBuyInfo',
    method: 'post',
    data: params
  })
}

//发布接店时需要的信息
export function getSearchFilter(params) {
  return request({
    url: 'shop/searchFilter',
    method: 'get',
    params: params
  })
}

//发布接店时需要的信息
export function createTakeShop(params) {
  return request({
    url: 'shop/seekaddnew',
    method: 'post',
    data: params
  })
}
//修改数据回显
export function getTakeShopById(id) {
  return request({
    url: 'shop/seekData',
    method: 'get',
    params: {
      id:id
    }
  })
}

export function commonWayPost(params) {
  return request({
    url: 'Sundries/commonWay',
    method: 'post',
    data: params
  })
}

export function userPcApi(obj) {
  return request({
    url: 'UserPc/api',
    method: 'post',
    data:obj
  })
}
