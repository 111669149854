<template>
  <div>
    <el-card class="box-card-header">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="常见问题" name="first"></el-tab-pane>
        <el-tab-pane label="我的反馈记录" name="second"></el-tab-pane>
      </el-tabs>
    </el-card>
    <el-card class="box-card-content" v-show="activeName === 'first'">
      <div>
        <Question>
          <p class="box-card-hint">
            <span class="box-hint-defult"
              >仍无法解决你的问题？请点击
              <span style="color: #0294fe; cursor: pointer" @click="feedback()"
                >我要反馈</span
              >
              或者
              <span
                style="color: #fe6002; cursor: pointer"
                @click="contact_KF()"
                >联系客服</span
              >
            </span>
          </p>
        </Question>
      </div>
    </el-card>
    <el-card class="box-card-content" v-show="activeName === 'second'">
      <div>
        <MyFeedback :getDetails="getDetails" ref="refMyFeedback">
          <p class="box-card-hint">
            <span class="box-hint-defult"
              >请点击
              <span style="color: #0294fe; cursor: pointer" @click="feedback()"
                >我要反馈</span
              >
              或者
              <span
                style="color: #fe6002; cursor: pointer"
                @click="contact_KF()"
                >联系客服</span
              >
            </span>
          </p>
        </MyFeedback>
      </div>
    </el-card>
    <wopener
      :isopen="isOpen"
      :dialogTetle="dialogTetle"
      :dialogClose="dialogClose"
      :dialogDetermine="dialogDetermine"
      :showButt="showDiglogButt"
    >
      <!--      我要反馈-->
      <div v-show="dialogTetle === '我要反馈'" class="slot-feedfack">
        <p class="problem-description">问题描述:</p>
        <el-input
          type="textarea"
          placeholder="请输入内容"
          v-model="feebackArr.content"
          maxlength="200"
          show-word-limit
        >
        </el-input>
        <div class="problem-description">添加图片:</div>
        <el-upload
          class="avatar-uploader"
          :action="uploadUrl"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
        >
          <img v-if="imgUrl" :src="imgUrl" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <div class="problem-description">请留下你的联系方式，以便及时回复</div>
        <div style="margin-bottom: 10px">
          <label class="contact-way">联系人：</label
          ><input
            type="text"
            class="contact-way-content"
            v-model="feebackArr.link_name"
          />
        </div>
        <div>
          <label class="contact-way">手机号：</label
          ><input
            type="text"
            class="contact-way-content"
            v-model="feebackArr.link_num"
          />
        </div>
      </div>
      <!--      详情-->
      <div v-show="dialogTetle === '详情'" class="slot-feedfack">
        <p class="slot-feedfack-details-content">{{ details.content }}</p>
        <div>
          <a :href="details.file" v-show="details.file" target="_blank"
            ><img :src="details.file" class="slot-feedfack-details-img"
          /></a>
        </div>
        <p class="slot-feedfack-details-time">{{ details.createtime }}</p>
        <div
          v-show="details.reply"
          style="border-bottom: 1px solid #d9d9d9; margin: 12px 0 24px 0"
        ></div>
        <p v-show="details.reply" class="slot-feedfack-details-reply">
          <span style="color: #fe6002">回复 : </span>{{ details.reply }}
        </p>
        <p class="slot-feedfack-details-time">{{ details.replytime }}</p>
      </div>
    </wopener>
    <ContactService
      :isOpenContactServier="isOpenContactServier"
      @closeDialog="contact_KF()"
    ></ContactService>
  </div>
</template>
<script>
import Question from "@/components/helpAndFeedback/question.vue";
import wopener from "@/components/helpAndFeedback/wopener-feedback.vue";
import MyFeedback from "@/components/helpAndFeedback/myFeedback.vue";
import { submitFeedback } from "@/api/commen/helpAndFeebback";
import store from "@/store";
import ContactService from "@/components/contactService.vue";

export default {
  name: "helpAndfeedback",
  components: { Question, wopener, MyFeedback, ContactService },
  data() {
    return {
      uploadUrl: process.env.VUE_APP_UPLOUD + "/api/upload/uploadCos",
      isOpenContactServier: false, //联系客服弹窗
      isOpen: false,
      activeName: "first",
      dialogTetle: "我要反馈",
      showDiglogButt: true,
      feebackArr: {
        content: "",
        link_num: "",
        link_name: "",
        file: "",
      },
      details: {},
      imgUrl: "",
    };
  },
  mounted() {
    // this.$refs.refquestion.getIndexList;
  },
  methods: {
    //联系客服
    contact_KF() {
      this.isOpenContactServier = !this.isOpenContactServier;
    },
    //接受子组件的详情用于弹窗显示
    getDetails(obj) {
      this.details = obj;
      this.showDiglogButt = false;
      this.dialogTetle = "详情";
      this.isOpen = true;
      console.log(obj);
    },
    //上传图片回调
    handleAvatarSuccess(res) {
      if (res.code === 200) {
        this.imgUrl = res.data.httpCosUrl;
        this.feebackArr.file = res.data.cosUrl;
      } else {
        this.$message.error(res.msg);
      }
    },
    // 上传图片之前的回调
    beforeAvatarUpload(file) {
      const isJPG = file.type;
      const isLt2M = file.size / 1024 / 1024 < 3;
      if (isJPG.indexOf("image") === -1) {
        this.$message.error("请上传图片");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 3MB!");
      }
      return isJPG && isLt2M;
    },
    handleClick(tab) {
      this.activeName = tab.name;
      if (tab.name === "second") {
        this.$refs.refMyFeedback.getMyFeedbackList();
      }
    },
    feedback() {
      this.isOpen = true;
      let userInfo = store.getters.userinf;
      this.feebackArr.link_num = userInfo.moblie;
      this.feebackArr.link_name = userInfo.name;
      this.dialogTetle = "我要反馈";
      this.showDiglogButt = true;
    },
    dialogClose() {
      this.isOpen = false;
      this.feebackArr.link_num = "";
      this.feebackArr.link_name = "";
      this.feebackArr.content = "";
    },
    // 点击弹窗确认按钮回调
    dialogDetermine(from) {
      console.log(from);
      switch (from) {
        case "我要反馈": {
          this.submitFeedback();
          break;
        }
      }
    },
    //提交反馈
    submitFeedback() {
      if (!this.feebackArr.content)
        return this.$message.error("请填写反馈内容");
      if (!this.feebackArr.link_num)
        return this.$message.error("请填写联系电话");
      if (!this.feebackArr.link_name)
        return this.$message.error("请填写联系人");
      submitFeedback(this.feebackArr).then((req) => {
        if (req.code === 200) {
          this.$message.success(req.msg);
          this.isOpen = false;
        } else {
          this.$message.error(req.msg);
        }
      });
      if (this.activeName === "second") {
        this.$refs.refMyFeedback.getMyFeedbackList();
      }
      this.feebackArr.link_num = "";
      this.feebackArr.link_name = "";
      this.feebackArr.content = "";
    },
  },
};
</script>

<style scoped>
.slot-feedfack-details-img {
  margin-bottom: 12px;
}
.slot-feedfack-details-reply {
  font-size: 16px;
  
  font-weight: 400;
  color: #191919;
  line-height: 22px;
  margin-bottom: 12px;
}

.slot-feedfack-details-content {
  font-size: 18px;
  
  font-weight: 400;
  color: #191919;
  line-height: 24px;
  margin-bottom: 12px;
}

.slot-feedfack-details-time {
  font-size: 14px;
  
  font-weight: 400;
  color: #666666;
  line-height: 16px;
}

.contact-way-content {
  width: 400px;
  height: 42px;
  border-radius: 2px 2px 2px 2px;
  border: 1px solid #e7e8eb;
}

.contact-way {
  font-size: 16px;
  
  font-weight: 400;
  line-height: 19px;
  color: #191919;
}

.avatar-uploader {
  overflow: hidden;
}

.avatar-uploader .el-upload {
  border-radius: 6px;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  border: 1px dashed #d9d9d9;
}

.avatar {
  height: 178px;
  width: 100%;
}

.problem-description {
  font-size: 18px;
  
  font-weight: 600;
  color: #191919;
  line-height: 28px;
  height: 28px;
  margin-bottom: 10px;
}

>>> .el-textarea__inner {
  height: 150px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  margin-bottom: 18px;
}

.slot-feedfack {
  width: 612px;
  padding: 15px 0 30px 0;
  margin: 0 auto;
}

.box-card-hint {
  width: 1196px;
  margin-top: 22px;
  margin-bottom: 20px;
}

.box-hint-defult {
  font-size: 16px;
  
  font-weight: 400;
  color: #b1b1b1;
}

.box-card-header {
  width: 1196px;
  border: 0;
  border-radius: 8px 8px 0 0;
  margin-bottom: 20px;
}

.box-card-content {
  width: 1196px;
  /*height: 800px;*/
  /*overflow: scroll;*/
}

>>> .el-tabs__item {
  font-size: 22px;
  
  font-weight: 400;
  line-height: 72px;
  height: 72px;
}

>>> .el-card__body {
  padding: 0 24px;
}

>>> .el-tabs__header {
  margin-bottom: 0;
  border: 0;
}

>>> .el-collapse-item__header {
  height: 78px;
  font-size: 22px;
  
  font-weight: 600;
  color: #191919;
  line-height: 28px;
}
>>> .el-collapse-item__content {
  padding-bottom: 3px;
  font-size: 16px;
}
>>> .el-collapse {
  border: none;
}
</style>