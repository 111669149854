<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2024-04-17 18:14:51
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-05-29 16:45:37
 * @FilePath: /lcwpcvue/src/components/zczShowMap.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="zcz-map-page">
    <div
      class="zcz-map"
      v-show="mapShow"
      ref="tmap"
      :id="idname"
      :style="{ height: mapheight + 'px', width: mapwidth + 'px' }"
    ></div>
  </div>
</template>

<script>
import tMapInit from "@/utils/tMapInit.js";
// import { searchAddressByGd } from "@/api/commen/addressManageApi.js";
import ticon from "@/assets/address_manr_mark.webp";
// import { gcj02towgs84 } from "@/utils/WSCoordinate.js";
export default {
  props: {
    lat: {
      type: Number,
      // default: 39.908775,
    },
    lng: {
      type: Number,
      // default: 116.397428,
    },
    idname: {
      type: String,
      default: "amap",
    },
    mapwidth: {
      type: String,
      default: "400",
    },
    mapheight: {
      type: String,
      default: "200",
    },
  },
  data() {
    return {
      map: null,
      T: null,
      searchKeyWord: "",
      querySearchArr: [],
      saveBtnDisabled: true,
      mapShow: true,
      // addAddrDialogShow: false,
      detailedAddress: "", //详细地址
    };
  },
  mounted() {
    this.$nextTick(function () {
      // 仅在整个视图都被渲染之后才会运行的代码
      this.initTdtMap();
    });
  },
  created() {
    console.log("zczmap", this.lat, this.lng);
    // 初始化天地图
  },
  methods: {
    async initTdtMap() {
      var that = this;
      let x = that.lng;
      let y = that.lat;
      console.log("zczmap22", x, y);

      await tMapInit
        .init()
        .then((T) => {
          that.T = T;
          that.map = new T.Map(this.idname, {
            projection: "EPSG:4326",
          });
          that.map.disableDrag();
          var icon = new T.Icon({
            iconUrl: ticon,
            iconSize: new T.Point(22, 34),
            iconAnchor: new T.Point(10, 25),
          });
          that.map.centerAndZoom(new T.LngLat(x, y), 14);
          var marker = new T.Marker(new T.LngLat(x, y), {
            icon: icon,
          });
          that.map.addOverLay(marker);
        })
        .catch(() => {
          // console.log(err);
        });
    },
  },
};
</script>

<style>
/* .zcz-map-page {
  max-width: 500px;
  max-height: 500px;
} */
/* .zcz-map {
  max-height: 500px;
} */
</style>