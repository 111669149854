<template>
  <div>
    <div v-if="isOpenContactServier" class="modal">
      <div class="modal-content">
        <div class="title-dev">
          <p class="title-dev-sapn">联系客服</p>
          <img class="title-dev-img" src="../assets/setPageIndex/dialog_close.png" @click="handleClose">
        </div>
        <div class="kefu-wx-img-dev">
            <img src="../assets/kefu_wx.png" id="kefu-wx-img">
        </div>
        <p class="kefu-wx-img-content">微信扫一扫联系猎宠网客服</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "contactService",
  props: ["isOpenContactServier"],
  data() {
    return {

    };
  },
  methods: {
    //关闭弹窗事件
    handleClose(){
      this.$emit("closeDialog")
    }
  }
};
</script>

<style scoped>
#kefu-wx-img{
  height: 280px;
  width: 280px;
  border: 1px solid #d9d9d9;
}
.kefu-wx-img-dev{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.kefu-wx-img-content{
  width: 100%;
  text-align: center;
  margin:25px 0 30px 0;
  font-size: 24px;
  
  font-weight: 600;
  color: #191919;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index:999;
}
.modal-content {
  width: 680px;
  background-color: white;
  border-radius: 16px;
}
.title-dev{
  height: 79px;
  border-bottom: 3px solid #EAECF5;
}
.title-dev-sapn{
  font-size: 24px;
  
  font-weight: 600;
  color: #191919;
  line-height: 81px;
  margin-left: 34px;
}
.title-dev-img{
  width: 18px;
  height: 18px;
  float: right;
  position: relative;
  right: 24px;
  top: -45px;
  cursor:pointer;
}
</style>