/*
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-05-26 14:56:18
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-06-03 09:58:00
 * @FilePath: /lcwpcvue/src/api/commen/register.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'

//省份
export function getProvinceList() {
  return request({
    url: 'Cityclass/provinceList',
    method: 'get'
  })
}

//市
export function getCityList(id) {
  return request({
    url: 'Cityclass/cityList',
    method: 'post',
    data: { 'provinceid': id }
  })
}


//区
export function getAreaList(id) {
  return request({
    url: 'address/getAddrList',
    method: 'get',
    params: { 'id': id }
  })
}



//获取行业类型
export function getHyList() {
  return request({
    url: 'resume/hyList',
    method: 'post',
    // params: { 'provinceid': id }
  })
}


//获取职位

export function getZwList() {
  return request({
    url: 'job/classlist',
    method: 'post',
    // params: { 'provinceid': id }
  })
}


/**
* 新用户注册接口
* @url member/register 
* @param params
* @return 
*/
export function registerByPhone(params) {
  return request({
    url: 'member/register',
    method: 'post',
    data: params
  })
}



//新建个人基本信息

/**
 * name:  // 名字
   sex: // 性别
   edu: ,
   show_name:,
   birthday: , // 出生日期
   job_type: this.job_type, // 职场人, 学生
   firstyear: this.firstyear, // 进入宠物行业时间
   telphone: this.telphone, // 电话
   wxnum: this.wxnum, // 微信号
   living: this.living, // 居住地
   domicile: this.domicile, // 户籍地
   uid: uni.getStorageSync('uid') // 用户uid, 用户
   */
export function addUserBasInf(params) {
  return request({
    url: 'resume/postResumeResult',
    method: 'post',
    data: params
  })
}

//获取学历列表
export function getXlClass(params) {
  return request({
    url: 'Userclass/getUserClass',
    method: 'post',
    data: params
  })
}

//添加&修改求职意向
/**
 * job_classid: this.job_classid, // 期望职位id
   city_classid: this.city_classid, // 工作城市id
   hy: this.hyid, // 行业id
   maxsalary: this.maxsalary, // 最高薪资
   minsalary: this.minsalary, // 最低薪资
   jobstatus: this.jobstatus, // 求职状态
   report: this.report, // 随时到岗  周内到岗 月内到岗
   type: this.type // 工作性质
 */
export function addJobIntention(params) {
  return request({
    url: 'resume/editResumeExpect',
    method: 'post',
    data: params
  })
}

//获取求职状态
export function getUserJobStatus() {
  return request({
    url: 'Userclass/getUserClassV2',
    method: 'post'
  })
}


//添加教育经历
/**
 * 
 * @param {
 * name: this.sc_name,
   education,
   specialty: this.zy_name,
   sdate,
   edate,
   content: this.scExp
 * } params 
 * @returns 
 */
export function addResumeEdu(params) {
  return request({
    url: 'resume/addResumeEdu',
    method: 'post',
    data: params
  })
}

//编辑教育经历
export function ediResumeEdu(params) {
  return request({
    url: 'resume/editResumeEdu',
    method: 'post',
    data: params
  })
}

//搜索学校
export function searSchoolName(params) {
  return request({
    url: 'resume/searchSchool',
    method: 'get',
    params
  })
}

//搜索公司名称
export function searCompName(params) {
  return request({
    url: 'Shield/searchCompany',
    method: 'post',
    data: params
  })
}

//添加工作经验
/**
 * 
 * @param {
 * name: this.com_name,
 * title: this.job_tit,
 * sdate,
 * edate,
 * content: this.content,
 * is_hide: this.ishide ? 1 : 0,
 * eid: 0,
 * com_id: this.com_id
 * } params 
 * @returns 
 */
export function addJobExp(params) {
  return request({
    url: 'resume/addResumeWork',
    method: 'post',
    data: params
  })
}

//获取默认头像
/**
 * Sundries/commonWay?type=via
 * @param {} params 
* @returns 
*/

export function getUserMrAvatarImg() {
  return request({
    url: 'Sundries/commonWay?type=via',
    method: 'get',
  })
}

export function comParamsByQcc(kw) {
  return request({
    url: 'company/comParamsByQcc',
    method: 'post',
    data: {
      kw: kw
    }
  })
}






