<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2024-05-08 11:07:27
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-06-12 10:42:21
 * @FilePath: /lcwpcvue/src/views/fromBackstage.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div></div>
</template>

<script>
// import { Loading } from "element-ui";

export default {
  data() {
    return {};
  },
  created() {
/*    Loading.service({
      text: "跳转66中...",
      background: "rgba(0,0,0,0)",
    });*/
    console.log("00000", this.$route.query);
    var pagequery = this.$route.query;
    var path = decodeURIComponent(pagequery.path);
    if (pagequery.ticket) {
      this.$store.dispatch("user/resetToken").then(() => {});
      var token = decodeURIComponent(pagequery.ticket);
      localStorage.setItem("token", token);
      this.$store.dispatch("user/setToken", token);
      this.$store.dispatch("user/setUserType", pagequery.usertype);
      this.$cookies.set("usertype", pagequery.usertype);
      localStorage.setItem("usertype", pagequery.usertype);
      this.$store.dispatch("user/setInfo").then((res) => {
        console.log("000999900000", res);
        this.$router.push(`${path}`);
      })
    } else {
      this.$router.push(`${path}`);
    }
  },
};
</script>

<style>
</style>