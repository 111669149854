<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2024-05-13 16:36:31
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-05-13 17:53:18
 * @FilePath: /lcwpcvue/src/views/informationDetail.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="information-detail-page">
    <div class="top-zk-div"></div>

    <div class="top-information-inf-view">
      <div class="title">{{ detailInf.title }}</div>
      <div class="info-bot-inf-view">
        <div class="left-view">
          <div class="topis-text"># {{hyName}}</div>
          <div class="auth">作者：{{detailInf.author}}</div>
          <div class="auth">来源：{{detailInf.source}}</div>
        </div>
        <div class="right-time-look-view">
          <div class="item">
            <img src="@/assets/companyinf_uptime_img.webp" alt="" />
            <span>更新于 {{ detailInf.time }}</span>
          </div>
          <div class="item">
            <img src="@/assets/companyinf_look_img.webp" alt="" />
            <span>浏览 {{ detailInf.hits }}次</span>
          </div>
        </div>
      </div>
    </div>

    <div class="fwb-view">
      <div v-html="detailInf.content"></div>
    </div>
  </div>
</template>

<script>
import { getInformationDetail } from "@/api/commen/informationAip.js";
export default {
  data() {
    return {
      id: "",
      detailInf: "",
      hyName:'',
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.hyName = this.$route.query.hy;
    this.getInformationDetail();
  },
  methods: {
    getInformationDetail() {
      getInformationDetail({
        id: this.id,
      })
        .then((result) => {
          console.log("获取资讯详情", result);
          result.data.time = new Date(result.data.datetime * 1000).format(
            "yyyy-M-dd"
          );
          this.detailInf = result.data;
        })
        .catch(() => {});
    },
  },
};
</script>

<style>
.information-detail-page {
  width: 100%;
  height: 100%;
  overflow: scroll;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.top-information-inf-view {
  width: 1010px;
  margin-top: 40px;
}
.information-detail-page .fwb-view img{
  width: 100%;
  height: auto;
}
.top-information-inf-view .title {
  
  font-weight: 600;
  font-size: 24px;
  color: #222222;
  line-height: 33px;
}
.top-information-inf-view .info-bot-inf-view {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
}
.top-information-inf-view .info-bot-inf-view .left-view {
  display: flex;
  align-items: center;
}

.top-information-inf-view .info-bot-inf-view .left-view .topis-text {
  
  font-weight: 600;
  font-size: 14px;
  color: #222222;
  line-height: 20px;
}

.top-information-inf-view .info-bot-inf-view .left-view .auth {
  
  font-weight: 400;
  font-size: 14px;
  color: #939597;
  line-height: 20px;
  margin-left: 32px;
}

.top-information-inf-view .info-bot-inf-view .right-time-look-view {
  display: flex;
  align-items: center;
}
.top-information-inf-view .info-bot-inf-view .right-time-look-view .item {
  display: flex;
  align-items: center;
  
  font-weight: 400;
  font-size: 12px;
  color: #939597;
  line-height: 17px;
  margin-left: 15px;
}
.top-information-inf-view .info-bot-inf-view .right-time-look-view .item img {
  width: 13px;
  height: 13px;
  margin-right: 4px;
}
.fwb-view {
  width: 1010px;
  background-color: #fff;
  margin-top: 28px;
  padding: 24px 20px;
}
</style>