<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2024-03-04 11:02:51
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-03-07 17:33:45
 * @FilePath: /lcwpcvue/src/views/mySubAccount.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="user-home-page">
    <div class="top-zk-div"></div>
    <userinfleftmen
      :activeIndex="'/mysubaccount'"
      class="left-menu"
    ></userinfleftmen>
    <subaccount class="userinf-index"></subaccount>
  </div>
</template>

<script>
import userinfleftmen from "@/components/userInfPageLeftMenu.vue";
import subaccount from "@/components/comUser/subAccount/subAccountIndex.vue";

export default {
  components: {
    userinfleftmen,
    subaccount,
  },
};
</script>

<style>
</style>