/*
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-11-21 14:07:35
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-05-06 15:44:44
 * @FilePath: /lcwpcvue/src/api/compe/companyInf.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'
//获取公司信息
export function getCompanyInf() {
  return request({
    url: 'Business/detail',
    method: 'get'
  })
}

//公司规模列表
export function getCompanyPepleNum(params) {
  return request({
    url: 'comClass/getComClass',
    method: 'post',
    data: params
  })
}


/**
 * 创建公司信息
 * @paramdata = {
                logo: this.avatarUrl, // 公司logo
                name: this.allname, // 公司全称
                alias: this.companyname,
                hy: this.hy_id, // 行业id
                mun: this.groupId, // 人员规模
                linkman: this.contacts, // 联系人
                address: this.address,
                provinceid: this.provinceid,
                cityid: this.cityid,
                three_cityid: this.three_cityid,
                x: this.x,
                y: this.y,
                uid: uni.getStorageSync('uid'),
                obj: JSON.stringify(this.comObjInf)
              } params 
 * @return 
 */
export function createCompanyInfo(params) {
  return request({
    url: 'Business/createCompany',
    method: 'post',
    data: params
  })
}

/**
 * 获取公司认证信息
 * @param {} params 
 * @return 
 */
export function getCertStatus() {
  return request({
    url: 'company/getCertStatus',
    method: 'get',
  })
}



/**
 * 公司认证-上传营业执照
 * @param {cert,obj} params 
 * @return 
 */
export function updateCertImg(params) {
  return request({
    url: 'Business/submitCert',
    method: 'post',
    data: params
  })
}
/**
 * 公司认证-上传诊疗许可
 * @param {pic} params 
 * @return 
 */
export function updateZlxkImg(params) {
  return request({
    url: 'Business/subZlxk',
    method: 'post',
    data: params
  })
}


//编辑公司信息
export function updateCompanyInfo(params) {
  return request({
    url: 'Business/updateinfo',
    method: 'post',
    data: params
  })
}

//更新公司联系方式
export function updateCompanyLink(params) {
  return request({
    // url: 'Business/updatelink_new',
    url: 'v2/Business/updatelink',
    method: 'post',
    data: params
  })
}

//获取公司热门福利
export function getBusinessWeifare() {
  return request({
    url: 'Business/welfaredetail',
    method: 'get'
  })
}

//更新公司福利
export function updateCompanyWelfare(params) {
  return request({
    url: 'Business/updatewelfare',
    method: 'post',
    data: params
  })
}

//添加公司福利标签
export function addCompanyWelfare(params) {
  return request({
    url: 'Business/addwelfare',
    method: 'post',
    data: params
  })
}

//更新公司介绍
export function updateCompanyContent(params) {
  return request({
    url: 'Business/updatecontent',
    method: 'post',
    data: params
  })
}

/**
 * 公司上传公司环境照片
 * @url Business/updateshow
 * @param {pic} params 
 * @return 
 */
export function submitCompanyWorkImg(params) {
  return request({
    url: 'Business/updateshow',
    method: 'post',
    data: params
  })
}
/**
 * 获取公司职位
 * @url Business/worklist
 * @param {pic} params 
 * @return 
 */

export function getWorkIdToLinkInf() {
  return request({
    url: 'Business/worklist',
    method: 'get'
  })
}



/**
 * 检查手机号是否绑定
 * @url userPc/api
 * @param {} params 
 * @return 
 */
export function checkPhoneIsHave(params) {
  return request({
    url: 'userPc/api',
    method: 'post',
    data: params
  })
}



/**
 * 修改公司联系人（仅限手机号）
 * @url applets/update_moblie
 * @param {} params 
 * @return 
 */
export function updateLinkPhone(params) {
  return request({
    url: 'applets/update_moblie',
    method: 'post',
    data: params
  })
}
/**
 * 修改公司联系人（仅限手机号）V2
 * @url v2/applets/update_moblie
 * @param {} params 
 * @return 
 */
export function updateLinkPhoneV2(params) {
  return request({
    url: 'v2/applets/update_moblie',
    method: 'post',
    data: params
  })
}

/**
 * 修改公司备用电话
 * @url applets/setByphone
 * @param {} params 
 * @return 
 */

export function updateByphone(params) {
  return request({
    url: 'applets/setByphone',
    method: 'post',
    data: params
  })
}

/**
 * 解绑手机号
 * @url sundries/commonWay
 * @param {type,verify,uid} params 
 * @return 
 */
export function unbindPhoneNum(params) {
  return request({
    url: 'sundries/commonWay',
    method: 'post',
    data: params
  })
}



/**
 * 公司详情页，获取公司详情
 * @url company/companyInfo
 * @param {company_uid} params 
 * @return 
 */

export function getCompanyDetailInfo(params) {
  return request({
    url: 'company/companyInfo',
    method: 'post',
    data: params
  })
}


/**
 * 公司添加微信号
 * @url business/editWxnum
 * @param {wxnum} params 
 * @return 
 */
export function updateCompWxNum(params) {
  return request({
    url: 'business/editWxnum',
    method: 'post',
    data: params
  })
}







