<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2024-02-26 18:04:16
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-02-26 18:13:37
 * @FilePath: /lcwpcvue/src/views/addJobYx.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="com-regist">
    <jobyx @saveJobYx="saveJobYx" class="wxsc-view"></jobyx>
  </div>
</template>

<script>
import jobyx from "@/components/individualUser/jobYx.vue";
export default {
  components: {
    jobyx,
  },
  methods: {
    saveJobYx() {
      this.$router.push("/eduexperience");
    },
  },
};
</script>

<style>
</style>