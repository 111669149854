import request from "@/utils/request";

export function userPcApi(obj) {
    return request({
        url: 'UserPc/api',
        method: 'post',
        data: obj
    })
}
export function appletsUpdateMoblie(code, moblie, isCheckPhone) {
    return request({
        url: 'applets/update_moblie',
        method: 'post',
        data: {
            'code': code,
            'moblie': moblie,
            'isCheckPhone': isCheckPhone,
        }
    })
}
export function wxServe(obj) {
    return request({
        url: 'wx/serve',
        method: 'post',
        data: obj
    })
}
export function commonWay(verify, uid) {
    return request({
        url: 'sundries/commonWay',
        method: 'post',
        data: {
            type: 'unbundle',
            verify: verify,
            uid: uid
        }
    })
}
// 求职设置可联系的时间段
export function linkTime(status, body) {
    return request({
        url: 'Userclass/linkTime',
        method: 'post',
        data: {
            status: status,
            body: body
        }
    })
}
// 获取屏蔽企业的列表
export function getshieldList() {
    return request({
        url: 'Shield/shieldList',
        method: 'post'
    })
}
// 解除屏蔽的企业
export function delShield(id) {
    return request({
        url: 'Shield/delShield',
        method: 'post',
        data: {
            id: id
        }
    })
}
// 隐藏简历
export function editResumeStatus(status) {
    return request({
        url: 'Resume/editResumeStatus',
        method: 'post',
        data: {
            status: status
        }
    })
}
// 简历状态（隐藏/开放）
export function myResumeStatus() {
    return request({
        url: 'Resume/myResumeStatus',
        method: 'get'
    })
}
// 是否显示全称
export function isShowName(obj) {
    return request({
        url: 'resume/ShowName',
        method: 'post',
        data: obj
    })
}
// 屏蔽企业
export function submitShield(com_uid, name) {
    return request({
        url: 'Shield/submitShield',
        method: 'post',
        data: {
            com_uid: com_uid,
            name: name
        }
    })
}
// 实名认证
export function resumeAuth(params) {
    return request({
        url: 'member/certification',
        method: 'post',
        data: params
    })
}