/*
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2024-05-13 14:23:58
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-05-13 16:50:28
 * @FilePath: /lcwpcvue/src/api/commen/informationAip.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import request from '@/utils/request'
/**
* 获取资讯分类
* @url Circle/newsGroupList
* @param { } params 
* @return 
*/
export function getInformationClass() {
  return request({
    url: 'Circle/newsGroupList',
    method: 'post'
  })
}

/**
* 获取资讯列表
* @url Circle/newsList
* @param { page ,group_id} params 
* @return 
*/
export function getInformationList(params) {
  return request({
    url: 'Circle/newsList',
    method: 'post',
    data: params
  })
}

/**
* 获取资讯详情
* @url Circle/newsContent
* @param { id} params 
* @return 
*/
export function getInformationDetail(params) {
  return request({
    url: 'Circle/newsContent',
    method: 'post',
    data: params
  })
}
