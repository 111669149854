<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2024-02-21 17:52:57
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-02-27 16:32:13
 * @FilePath: /lcwpcvue/src/views/registByPhone.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="com-regist">
    <register
      v-show="!wxScShow"
      class="wxsc-view"
      @passdChangeShowType="passdChangeShowType"
    ></register>
    <!-- <wxsclogin
      v-show="wxScShow"
      class="wxsc-view"
      @tophonelogin="toPhoneLogin"
    ></wxsclogin> -->
  </div>
</template>

<script>
import register from "@/components/regist.vue";
// import wxsclogin from "@/components/wxscLogin.vue";

export default {
  components: {
    register,
    // wxsclogin,
  },
  data() {
    return {
      wxScShow: false,
    };
  },
  methods: {
    passdChangeShowType() {
      this.wxScShow = true;
    },
    toPhoneLogin() {
      this.wxScShow = false;
    },
  },
};
</script>

<style>
.wxsc-view {
  /* margin-top: 11.87rem; */
  margin-top: 171px;
}
</style>