<template>
  <div class="take-tra-page">
    <label class="" v-for="(item, i) in list" :key="i">
      <label v-if="list.length == 1">
        <div v-if="list[i].transfer_status == 1" class="dd-txt">
          已到底了，您还可以查看该区域已转出店铺成交价格
        </div>
      </label>
      <label v-if="list.length > 1">
        <label v-if="i == 0">
          <div v-if="list[i].transfer_status == 1" class="dd-txt">
            已到底了，您还可以查看该区域已转出店铺成交价格
          </div>
        </label>
        <label v-if="i > 0">
          <div
            v-if="
              list[i].transfer_status == 1 && list[i - 1].transfer_status == 0
            "
            class="dd-txt"
          >
            已到底了，您还可以查看该区域已转出店铺成交价格
          </div>
        </label>
      </label>
      <div class="list-item" v-if="tabId == '1'" @click="toDetailPage(item.id)">
        <div class="hd-img">
          <img
            :lazy-load="true"
            class="stro-img"
            :src="item.pic"
            mode="aspectFill"
          />
          <!-- <img
            v-if="item.have_video == 1"
            class="pl-video"
            src="../../static/video.png"
            mode=""
          /> -->
        </div>

        <div class="right-view">
          <div class="title">
            <img
              v-if="item.xsdate != 0"
              src="@/assets/h5img/zjd_tj.png"
              mode=""
            />
            <div class="t">{{ item.title }}</div>
          </div>
          <div class="addr">
            <!-- <image src="../../static/images/zjd_dw.png"></image> -->
            <div class="t">{{ item.city_name }}</div>
            <div class="shuxian"></div>
            <div class="t">面积{{ item.area }}m²</div>
          </div>
          <div class="bot">
            <div class="je">
              <label>{{ item.rent }}</label
              >元/月
            </div>
            <!-- <div class="mj">{{ item.area }}m²</div> -->
          </div>
          <div class="bj">
            <div class="it typ">{{ item.type_name }}</div>
            <label v-for="(it, id) in item.more.slice(0, 2)" :key="id">
              <div class="it">{{ it }}</div>
            </label>
          </div>
        </div>
        <label v-if="item.transfer_status == 1">
          <img class="yz-img" src="@/assets/h5img/zjd_lis_yzc.png" />
          <div class="yz-zz"></div>
        </label>
      </div>
      <div
        class="transfer-lis"
        v-if="tabId == '2'"
        @click="toDetailPage(item.id, item)"
      >
        <div class="name">{{ item.title }}</div>
        <div class="txt addr">
          <img src="@/assets/h5img/zjd_dw.png" />
          <div>{{ item.all_city_name }}</div>
        </div>
        <div class="txt lxbj">类型：{{ item.type_name }}</div>
        <div class="txt mj">面积：{{ item.area }}</div>
        <div class="txt prc-view">
          <div class="p p1">
            转让费：
            <label v-if="item.transfer != 0">
              {{ item.transfer }}
              <label v-if="item.transfer != '不限'">万元以下</label>
            </label>
            <label v-else>面议</label>
          </div>
          <div class="p p2">
            租金：
            <label>
              {{ item.rent }}
              <label v-if="item.rent != '不限'">元/月以下</label>
            </label>
          </div>
        </div>
      </div>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    tabId: {
      type: Number,
      default: () => 1,
    },
  },
  data() {
    return {};
  },
  created() {
    console.log(this.tabId);
  },
  methods: {
    toDetailPage(id, item) {
      if (this.tabId == 1) {
        this.$router.push(`/h5trandetail?id=${id}`);
      } else {
        this.$router.push(`/h5takedetail?inf=${JSON.stringify(item)}`);
      }
    },
    todetail(e, status) {
      if (status != 1) {
        // uni.navigateTo({
        //   url: e,
        //   // url:''
        // });
      }
    },
  },
};
</script>

<style lang="scss">
.take-tra-page {
  .dd-txt {
    font-size: 12px;
    color: #939597;
    width: 100%;
    text-align: center;
    padding: 10px 0;
  }

  .list-item {
    display: flex;
    align-items: flex-start;
    padding: 15px 12px;
    // border-bottom: 1px solid#F0F1F5;
    position: relative;
    background-color: #fff;
    // margin: 0 8px;
    margin-top: 8px;
    border-radius: 6px;

    .hd-img {
      position: relative;

      .stro-img {
        width: 114px;
        height: 114px;
        border-radius: 6px;
        // position: relative;
      }

      .pl-video {
        width: 20px;
        height: 20px;
        position: absolute;
        bottom: 10px;
        right: 5px;
      }
    }

    .right-view {
      margin-left: 12px;

      .title {
        display: flex;
        align-items: center;
        width: 288px;

        img {
          width: 36px;
          height: 20px;
          margin-left: 8px;
        }

        .t {
          // margin-left: 8px;
          width: 223px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 20px;
          
          font-weight: 800;
          line-height: 25px;
          color: #222222;
        }
      }

      .addr {
        display: flex;
        align-items: center;
        margin-top: 9px;

        img {
          width: 12px;
          height: 13px;
        }

        .t {
          // margin-left: 8px;
          font-size: 14px;
          
          font-weight: 400;
          color: #222222;
        }

        .shuxian {
          width: 1px;
          height: 10px;
          background-color: #c5c9cd;
          margin: 0 7px;
        }
      }

      .bj {
        margin-top: 9px;
        margin-bottom: 4px;
        display: flex;
        align-items: center;
        max-width: 310px;
        height: 30px;
        overflow: scroll;
        scrollbar-width: none; /* firefox */
        -ms-overflow-style: none; /* IE 10+ */
        transition: width 0.3s ease;
        &::-webkit-scrollbar {
          display: none;
        }

        .it {
          color: #b1b1b1;
          font-size: 14px;
          
          font-weight: 400;
          padding: 3px 8px;
          border-radius: 2px;
          margin-right: 8px;
          white-space: nowrap;
          border-radius: 2px;
          border: 1px solid #c5c9cd;
        }

        // .typ {
        // 	background-color: #fff3ec;
        // 	color: #ff8136;
        // }
      }

      .bot {
        display: flex;
        align-items: center;
        margin-top: 10px;

        .je {
          font-size: 14px;
          color: #f34b37;
          font-weight: 600;
          
          line-height: 20px;

          label {
            font-size: 22px;
            margin-right: 5px;
          }
        }

        .mj {
          font-size: 15px;
          color: #222222;
          font-weight: 400;
          margin-left: 10px;
        }
      }
    }

    .yz-zz {
      width: 432px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba($color: #000000, $alpha: 0.08);
      border-radius: 6px;
      // backdrop-filter: blur(30px);
    }

    .yz-img {
      width: 90px;
      height: 90px;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  .transfer-lis {
    padding: 16px 12px;
    background-color: #fff;
    border-radius: 6px;
    margin: 0 8px;
    margin-top: 8px;
    width: 430px;

    .name {
      font-size: 20px;
      color: #222222;
      font-weight: 800;
    }

    .txt {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #757575;
      margin-top: 10px;
      font-weight: 400;
    }

    .addr {
      img {
        width: 14px;
        height: 15px;
        margin-right: 5px;
      }
    }

    .prc-view {
      position: relative;
      margin-top: 15px;

      label {
        font-size: 16px;
        color: #f34b37;
        font-weight: 600;
      }

      .p2 {
        position: absolute;
        right: 32px;
      }
    }
  }
}
</style>
