<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-12-13 16:35:47
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2023-12-13 17:20:07
 * @FilePath: /lcwpcvue/src/components/comUser/chosePartJobTime.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="partjob-time-view">
    <el-dialog
      title="选择时间"
      :show-close="true"
      width="40%"
      :visible.sync="partJobDialogShow"
      @close="closeDateChoose"
    >
      <div class="w">
        <div class="table">
          <div>
            <div class="first bac"></div>
            <div class="text bac">周一</div>
            <div class="text bac">周二</div>
            <div class="text bac">周三</div>
            <div class="text bac">周四</div>
            <div class="text bac">周五</div>
            <div class="text bac">周六</div>
            <div class="text bac">周日</div>
          </div>
          <div>
            <div class="text">上午</div>
            <div
              class="text"
              v-for="(item, index) in m"
              @click="to_val(index, 1, item.id)"
              :key="index"
            >
              <img v-if="item.checked" src="@/assets/chotime.png" />
            </div>
          </div>
          <div>
            <div class="text">下午</div>
            <div
              class="text"
              v-for="(item, index) in a"
              @click="to_val(index, 2, item.id)"
              :key="index"
            >
              <img v-if="item.checked" src="@/assets/chotime.png" />
            </div>
          </div>
          <div>
            <div class="text">晚上</div>
            <div
              class="text"
              v-for="(item, index) in n"
              @click="to_val(index, 3, item.id)"
              :key="index"
            >
              <img v-if="item.checked" src="@/assets/chotime.png" />
            </div>
          </div>
        </div>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDateChoose">取 消</el-button>
        <el-button type="primary" @click="savePartTime">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    partJobDialogShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      m: [
        {
          id: "0101",
          checked: false,
        },
        {
          id: "0201",
          checked: false,
        },
        {
          id: "0301",
          checked: false,
        },
        {
          id: "0401",
          checked: false,
        },
        {
          id: "0501",
          checked: false,
        },
        {
          id: "0601",
          checked: false,
        },
        {
          id: "0701",
          checked: false,
        },
      ],
      a: [
        {
          id: "0102",
          checked: false,
        },
        {
          id: "0202",
          checked: false,
        },
        {
          id: "0302",
          checked: false,
        },
        {
          id: "0402",
          checked: false,
        },
        {
          id: "0502",
          checked: false,
        },
        {
          id: "0602",
          checked: false,
        },
        {
          id: "0702",
          checked: false,
        },
      ],
      n: [
        {
          id: "0103",
          checked: false,
        },
        {
          id: "0203",
          checked: false,
        },
        {
          id: "0303",
          checked: false,
        },
        {
          id: "0403",
          checked: false,
        },
        {
          id: "0503",
          checked: false,
        },
        {
          id: "0603",
          checked: false,
        },
        {
          id: "0703",
          checked: false,
        },
      ],
    };
  },
  methods: {
    closeDateChoose(){
      this.$emit('closeDateChoose');
    },
    to_val(index, ids) {
      // var id = id;
      // var ids = ids;
      // var index = index;
      let m = this.m;
      let a = this.a;
      let n = this.n;
      if (ids == 1) {
        m[index].checked = !m[index].checked;

        this.m = m;
      }
      if (ids == 2) {
        a[index].checked = !a[index].checked;

        this.a = a;
      }
      if (ids == 3) {
        n[index].checked = !n[index].checked;

        this.n = n;
      }
    },
    savePartTime() {
      let m = this.m;
      let a = this.a;
      let n = this.n;
      let arr = m.concat(a, n);
      let arrs = [];
      arr.forEach((item) => {
        if (item.checked == true) {
          arrs.push(item.id);
        }
      });
      if (arrs.length == 0) {
        this.$message({
          message: "请选择日期",
          type: "warning",
        });
        return;
      }

      this.$emit("partJobTimeArr", arrs);
    },
  },
};
</script>

<style>
.partjob-time-view .el-dialog__wrapper .el-dialog {
  border-radius: 15px;
}

.table {
  margin: 20px 32px;
}
.table .bac {
  background-color: #ff8136;
  color: #ffffff;
}
.table > div {
  display: flex;
}
.table > div:first-child .text {
  border-top: 1px solid #ff8136;
}

.table > div .first {
  width: 12.5%;
  border: 1px solid #ff8136;
  height: 70px;
  border-right: none;
  box-sizing: initial;
  text-align: center;
  font-size: 28px;
}
.table > div .text:last-child {
  border-right: 1px solid #ff8136;
}
.table > div .text {
  width: 12.5%;
  height: 70px;
  border: 1px solid #ff8136;
  border-right: none;
  border-top: none;
  box-sizing: initial;
  line-height: 70px;
  text-align: center;
  font-size: 28px;
}
.table > div .first .text {
  border: none;
  height: 30px;
  display: block;
  width: auto;
  line-height: normal;
}
img {
  width: 46px;
  height: 44px;
  vertical-align: middle;
}
</style>