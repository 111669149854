<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-06-01 10:58:47
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-06-20 15:57:06
 * @FilePath: /lcwpcvue/src/components/choseZw.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="chose-city">
    <el-dialog
      title="期望职位"
      :visible="dialogVisible1"
      width="1003px"
      @close="handleClose"
    >
      <div class="zws-ychose" v-show="!isOneJob">
        <span class="yx-view">已选择</span>
        <span class="tsmin">（最多可以选择3项）</span>
        <label
          v-show="slJobArr.length > 0"
          v-for="(item, index) in slJobArr"
          :key="item.id"
        >
          <div class="yxct">
            <span>{{ item.name }}</span>
            <i @click="delSlJob(index)" class="el-icon-close"></i>
          </div>
        </label>
      </div>
      <div class="sle-view">
        <div class="zw-fa-view">
          <label v-for="item in zwList" :key="item.id">
            <div
              class="item"
              :class="faSlId == item.id ? 'zw-fa-sl' : ''"
              @click="changFaId(item)"
            >
              <div class="name">{{ item.name }}</div>
              <span class="sl-num" v-show="item.num > 0">{{ item.num }}</span>
            </div>
          </label>
        </div>
        <div class="chled-view">
          <label v-for="item in chelList" :key="item.id">
            <div
              class="last"
              :class="soSlId == item.id ? 'arasl' : ''"
              @click="changSoId(item)"
            >
              {{ item.name }}
            </div>
          </label>
          <el-button v-show="!isOneJob" type="primary" @click="saveJobArr"
            >确定</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getZwList } from "@/api/commen/register";
import { Message } from "element-ui";
export default {
  name: "chosezw",
  props: {
    dialogVisible1: {
      type: Boolean,
      default: false,
    },
    isOneJob: {
      type: Boolean,
      default: false,
    },
    slJobArrF: {
      type: Array,
      default: Array,
    },
  },

  data() {
    return {
      zwList: [],
      chelList: [],
      // slJobArr: [],
      faSlId: "",
      soSlId: "",
    };
  },
  computed: {
    slJobArr: {
      get() {
        return this.slJobArrF;
      },
      set(newValue) {
        this.$emit("update:slJobArrF", newValue);
      },
    },
  },
  created() {
    this.getZwList();
    // this.slJobArr = this.slJobArrF
  },
  methods: {
    getZwList() {
      getZwList()
        .then((result) => {
          console.log("获取职位", result);
          result.data.list.forEach((element) => {
            element.num = 0;
            // element.next_list.forEach((ii) => {
            //   ii.faid = element.id;
            // });
          });
          this.zwList = result.data.list;
          this.chelList = result.data.list[0].next_list;
          this.faSlId = result.data.list[0].id;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changFaId(e) {
      console.log('ZW:',e);
      this.faSlId = e.id;
      this.chelList = e.next_list;
    },
    changSoId(e) {
      console.log('Z1W:',e);
      if (this.isOneJob) {
        // var jobClass = e;
        console.log(e);
        this.$emit("saveJobArr", e);
      } else {
        console.log(this.slJobArr)
        if (this.slJobArr.length < 3) {
          this.soSlId = e.id;
          this.slJobArr.push(e);
          this.zwList.forEach((it) => {
            if (it.id == this.faSlId) {
              it.num = it.num + 1;
            }
          });
        } else {
          Message({
            message: "最多只能选三项",
            type: "error",
            duration: 3 * 1000,
            showClose: true,
            offset: 100,
          });
        }
      }
    },

    delSlJob(index) {
      this.zwList.forEach((it) => {
        console.log(it.id, this.slJobArr[index]);
        if (it.id == this.slJobArr[index].keyid) {
          it.num = it.num - 1;
        }
      });
      this.slJobArr.splice(index, 1);
    },
    saveJobArr() {
      this.$emit("saveJobArr", this.slJobArr);
    },
    handleClose() {
      console.log("打印了啊啊");
      // this.dialogVisible1 = false;
      this.$emit("closeDilog1");
    },
  },
};
</script>

<style >
.el-dialog {
  border-radius: 20px;
}
.chose-city .el-dialog__wrapper .el-dialog {
  border-radius: 15px;
  height: 639px;
}

.el-dialog__wrapper .el-dialog .el-dialog__title {
  font-size: 26px;

  font-weight: 600;
  color: #222222;
}
.el-dialog__wrapper .el-dialog__body {
  padding: 0px 0;
}

.chose-city .zws-ychose {
  display: flex;
  align-items: flex-end;
  border-bottom: 1px solid #dddddd;
  padding: 0 20px;
  padding-bottom: 18px;
}

.chose-city .zws-ychose .yx-view {
  font-size: 20px;
  color: #222222;

  font-weight: 600;
}

.chose-city .zws-ychose .tsmin {
  font-size: 12px;
  color: #939597;
  font-weight: 400;
}

.chose-city .zws-ychose .yxct {
  display: inline-block;
  height: 26px;
  background-color: #ff8136;
  border-radius: 2px;
  font-size: 14px;

  font-weight: 400;
  color: #fffdfc;
  text-align: center;
  line-height: 26px;
  margin-left: 12px;
  padding: 0 6px;
}

.chose-city .zws .yxct i {
  margin-left: 12px;
}

.chose-city .sle-view {
  display: flex;
}

.chose-city .zw-fa-view {
  background-color: #f5f6fa;
  width: 220px;
  height: 540px;
  font-size: 18px;

  font-weight: 400;
  color: #222222;
  overflow: scroll;
  border-bottom-left-radius: 15px;
  position: relative;
}
.chose-city .zw-fa-view .item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 52px;
  position: relative;
  cursor: pointer;
}

.chose-city .zw-fa-view .item .sl-num {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  font-size: 8px;

  font-weight: 500;
  color: #ffffff;
  background-color: #fe6002;
  text-align: center;
  line-height: 14px;
  position: absolute;
  right: 9px;
}
.chose-city .zw-fa-view .item:hover {
  background-color: #fff;
}
.chose-city .zw-fa-sl {
  height: 52px;
  background-color: #fff;

  font-weight: 500;
  border-left: 3px solid#FE6002;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chose-city .chled-view {
  margin-left: 54px;
  width: 738px;
}

.chose-city .chled-view .last {
  display: inline-block;
  width: 199px;
  height: 45px;
  background: #f5f6fa;
  border-radius: 1px;
  text-align: center;
  line-height: 45px;
  margin-right: 35px;
  margin-top: 24px;
  margin-bottom: 9px;
}
.chose-city .chled-view .last:hover {
  background-color: rgba(255, 129, 54, 0.15);
  color: #fe6002;
}
/* .chose-hy .last:nth-child(5n) {
  margin-right: 0;
} */

.chose-city .chled-view .arasl {
  background-color: rgba(255, 129, 54, 0.15);
  color: #fe6002;
  border: 1px solid #fe6002;
}

.chose-city .chled-view .el-button {
  width: 200px;
  position: absolute;
  bottom: 20px;
  right: 20px;
}
</style>