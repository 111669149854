<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2024-03-04 17:02:49
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-03-04 17:11:44
 * @FilePath: /lcwpcvue/src/views/vipPayPage.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="user-home-page">
    <div class="top-zk-div"></div>
    <userinfleftmen class="left-menu"></userinfleftmen>
    <vippaypage class="userinf-index"></vippaypage>
  </div>
</template>

<script>
import userinfleftmen from "@/components/userInfPageLeftMenu.vue";
import vippaypage from "@/components/comUser/vipPayPage.vue";

export default {
  components: {
    userinfleftmen,
    vippaypage,
  },
};
</script>

<style>
</style>