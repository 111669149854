<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-11-15 14:56:45
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-05-10 13:57:56
 * @FilePath: /lcwpcvue/src/components/comUser/rightMyQy.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="right-my-qy">
    <div class="havebg-view">
      <img class="bg-img" src="@/assets/companyinf_qqbg_img.webp" />
      <div class="title-view">
        <div class="title-left-view">
          <div class="tt1">{{ myVipComboInf.rating_name }}</div>
          <div class="tt2">你可享受以下服务，助力招聘人才</div>
        </div>
        <div class="title-right-btn-view">
          <el-button
            @click="changeShoeidFun(19)"
            type="primary"
            size="small"
            round
            >升级会员</el-button
          >
        </div>
      </div>
    </div>
    <div class="bg-white-view">
      <div class="qy-title-view">我的权益</div>
      <div class="qt-items-view">
        <label v-for="(item, index) in myVipComboInf.result" :key="index">
          <div class="qy-item" @click="toEquityPage">
            <div class="tt1">{{ item.title }}</div>
            <div class="nums">{{ item.allnum }}</div>
            <div class="tt2">
              {{
                index == 0
                  ? "已下载"
                  : index == 1
                  ? "已发布"
                  : index == 2
                  ? "已沟通"
                  : index == 3
                  ? "已刷新"
                  : "已用"
              }}{{ item.usenum }}{{ item.unit }}
            </div>
          </div>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { getMyVipCombo } from "@/api/compe/myComboApi.js";
export default {
  data() {
    return {
      usertype: "",
      myVipComboInf: {},
    };
  },
  created() {
    if (localStorage.usertype == "2") {
      this.getMyVipCombo();
    }
  },
  methods: {
    getMyVipCombo() {
      getMyVipCombo()
        .then((result) => {
          console.log("获取我的会员权益", result);
          result.data.result.forEach((item) => {
            let arr = item.num.split("/");
            item.allnum = arr[0];
            item.usenum = arr[1];
          });
          this.myVipComboInf = result.data;
        })
        .catch(() => {});
    },
    changeShoeidFun() {
      this.$router.push(`/vippaypage`);
    },
    toEquityPage() {
      this.$router.push(`/myequitypage`);
    },
  },
};
</script>

<style>
.right-my-qy .havebg-view {
  position: relative;
}
.right-my-qy .havebg-view .bg-img {
  width: 346px;
  height: 230px;
}
.right-my-qy .havebg-view .title-view {
  width: 300px;
  display: flex;
  position: absolute;
  top: 0;
  padding: 27px 17px;
}
.havebg-view .title-view .title-left-view .tt1 {
  font-size: 24px;
  
  font-weight: 600;
  color: #fe6002;
  line-height: 28px;
}
.havebg-view .title-view .title-left-view .tt2 {
  font-size: 14px;
  
  font-weight: 400;
  color: #fe6002;
  line-height: 16px;
  margin-top: 4px;
}
.havebg-view .title-view .title-right-btn-view {
  position: absolute;
  right: 0;
}
.havebg-view
  .title-view
  .title-right-btn-view
  .el-button.el-button--primary.is-round {
  font-size: 14px;
  
  font-weight: 600;
  color: #ffffff;
}
.bg-white-view {
  background-color: #ffff;
  width: 296px;
  padding: 16px;
  border-radius: 16px;
  position: relative;
  margin-top: -140px;
  margin-left: 10px;
}
.bg-white-view .qy-title-view {
  font-size: 20px;
  
  font-weight: 600;
  color: #222222;
  line-height: 28px;
}
.bg-white-view .qt-items-view .qy-item {
  display: inline-block;
  text-align: center;
  margin-top: 16px;
  width: 73px;
}
.bg-white-view .qt-items-view .qy-item .tt1 {
  font-size: 14px;
  
  font-weight: 400;
  color: #222222;
  line-height: 16px;
}
.bg-white-view .qt-items-view .qy-item .nums {
  font-size: 18px;
  
  font-weight: 600;
  color: #fe6002;
  line-height: 28px;
  margin-top: 5px;
}
.bg-white-view .qt-items-view .qy-item .tt2 {
  font-size: 12px;
  
  font-weight: 400;
  color: #757575;
  line-height: 14px;
  margin-top: 5px;
}
</style>