<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-05-11 18:30:52
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-08-15 11:59:02
 * @FilePath: /lcwpcvue/src/views/login.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="login">
    <pageheader
      @changeUserType="changeUserType"
      class="head-view"
      :userTypeName="userTypeName"
    ></pageheader>
    <LoginView
      v-if="showType == 0"
      class="login-view"
      @changeShowType="changeShowType"
      @towxlogin="toWxLogin"
    ></LoginView>
    <!-- v-show="usertype != '0' && showType == 0" -->

    <!-- <ForgetPass class="wxsc-view"></ForgetPass> -->
    <!-- <ChangeUsertype /> -->
    <!-- <Register></Register> -->
    <!-- <EduExperience
      v-show="showType == 4"
      class="wxsc-view"
      @saveEduExp="saveEduExp"
    ></EduExperience> -->

    <el-dialog
      title=""
      :visible.sync="changeUserTypeDialogVisible"
      width="40%"
      center
      @close="changeUserTypeDialogClose"
    >
      <div class="dial">
        <div
          class="type-item"
          @mouseover="mousov1"
          @mouseleave="mousle1"
          :class="mouse1 ? 'heover' : ''"
        >
          <img src="../assets/login/ch_usertype1.png" alt="" />
          <el-button @click="setUserType(1)" class="bot-btn bt1"
            >我要找工作</el-button
          >
        </div>
        <div style="width: 117px"></div>
        <div
          class="type-item"
          @mouseover="mousov2"
          @mouseleave="mousle2"
          :class="mouse2 ? 'heover' : ''"
        >
          <img src="../assets/login/ch_usertype.png" alt="" />
          <el-button @click="setUserType(2)" class="bot-btn bt2"
            >我要招人才</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import pageheader from "@/components/pageHeader.vue";
import LoginView from "@/components/loginwWin.vue";
// import ChangeUsertype from "@/components/choseUserType.vue";
// import store from "@/store";
import { MessageBox } from "element-ui";
import { bindSocketCid } from "@/api/commen/chatApi.js";
export default {
  name: "Login",
  components: {
    pageheader,
    // ChangeUsertype,
    LoginView,
  },
  data() {
    return {
      token: "",
      usertype: "",
      showType: 0,
      userTypeName: "",
      userTypeShow: true,
      changeUserTypeDialogVisible: false,
      mouse1: false,
      mouse2: false,
    };
  },
  created() {
    this.usertype = localStorage.usertype;
    if (!localStorage.usertype) {
      this.changeUserTypeDialogVisible = true;
    }
  },
  methods: {
    changeShowType(val) {
      console.log("0p0p00p", val);
      if (val.code != 200) {
        if (val.code == 14) {
          // this.show = true; // 新用户,要注册
          // setIsFisterLoginState
          this.$store.dispatch("user/setIsFisterLoginState", true);
          MessageBox({
            title: "提示",
            message: val.msg,
          })
            .then(() => {
              this.$router.push("/registbyphone");
            })
            .catch(() => {});
        }
        return false;
      }
      if (val.code == 200) {
        // uni.setStorageSync("token", logininf.member.token);
        // uni.setStorageSync("uid", logininf.member.uid);
        this.$store.dispatch("user/setToken", val.data.token);
        this.$store.dispatch("user/setUserUid", val.data.member.uid);
        this.$cookies.set("token", val.data.token, "1d");
        localStorage.setItem("token", val.data.token);
        this.$store.dispatch("user/setWdMsgNumber");

        // this.$store.dispatch("user/setInfo", val.data);
        // this.$store.dispatch("user/setUserUid", val.data.member.uid);
        // this.$store.dispatch("user/setUserUid", val.data.member.uid);
      }
      console.log(localStorage.usertype);
      if (localStorage.usertype == 1) {
        console.log(val.data.login_check);
        if (val.data.login_check == "") {
          // 个人信息
          this.$router.push("/userregistbasinf");
          // userregistbasinf
          // this.showType = 2;
        } else if (val.data.login_check.is_resume == "0") {
          // this.showType = 2;
          this.$router.push("/userregistbasinf");
        } else if (val.data.login_check.is_expect == "0") {
          // this.showType = 3;
          this.$router.push("/userregistbasinf");
        } else {
          // this.$refs;
          this.bindSocketCid();
          console.log("tiaotiaojfladjflidjfidaj");
          this.$nextTick(() => {
            this.$router.push("/home");
          });
        }
      } else if (localStorage.usertype == 2) {
        // console.log("ppp");
        if (val.data.login_check.length == 0) {
          // 公司信息
          this.$router.push("/comregist");

          console.log("走的是这里111");
        } else if (val.data.login_check.is_company == "0") {
          // uni.navigateTo({
          // 	url: `/pagesA/user2/info/info?firstlogin=1`
          // 	// url: '/pagesA/comAddJob/comAddJob?firstlogin=1'
          // })
          this.$router.push("/comregist");
        } else if (val.data.login_check.com_cert == "0") {
          this.$router.push("/businessauth");
          // 营业执照
          // console.log('走的是这里222');
          // uni.navigateTo({
          // 	// url: `/pagesA/user2/info/info?firstlogin=1`
          // 	url: '/pagesA/comAddJob/comAddJob?firstlogin=1'
          // })
        } else {
          this.bindSocketCid();
          const curr = localStorage.getItem("preRoute");
          console.log("//", curr);

          if (curr == null) {
            this.$router.push("/home");
          } else {
            if (curr == "/findjob") {
              if (this.usertype == 1) {
                this.$router.push({ path: curr });
              } else {
                this.$router.push("/findtalentslist");
              }
            } else if (curr == "/findtalentslist") {
              if (this.usertype == 1) {
                this.$router.push("/findjob");
              } else {
                this.$router.push({ path: curr });
              }
            } else {
              this.$router.push({ path: curr });
            }
          }
          // 登录成功
        }
      }
    },

    bindSocketCid() {
      console.log("走不走绑定啊", localStorage.wssclientid);
      bindSocketCid({
        cid: localStorage.wssclientid,
        pageurl: "login",
      })
        .then((result) => {
          console.log("绑定长链接", result);
        })
        .catch((err) => {
          console.log("绑定", err);
        });
    },

    // saveBaseInf() {
    //   this.showType = 3;
    // },
    // saveJobYx() {
    //   this.showType = 4;
    // },
    saveEduExp() {
      this.showType = 5;
    },
    toWxLogin() {
      //   console.log("比年");
      //   this.showType = 6;
    },
    toPhoneLogin() {
      this.showType = 0;
    },

    changeUserType() {
      console.log("0000000", localStorage.usertype);
      if (localStorage.usertype == 1) {
        this.$store.dispatch("user/setUserType", "2");
        this.$cookies.set("usertype", "2");
        localStorage.setItem("usertype", "2");
        this.usertype = 2;
        // location.reload();
      } else {
        this.$store.dispatch("user/setUserType", "1");
        this.$cookies.set("usertype", "1");
        localStorage.setItem("usertype", "1");
        this.usertype = 1;
        // location.reload();
      }
      console.log("9999999", localStorage.usertype);

      // this.changeUserTypeDialogVisible = true;
    },

    changeUserTypeDialogClose() {
      if (!localStorage.getItem("usertype")) {
        this.$store.dispatch("user/setUserType", 2);
        localStorage.setItem("usertype", 2);
        this.$cookies.set("usertype", 2);
      }
    },

    setUserType(type) {
      // this.$store.dispatch("setIsAutnenticated", !this.isEmpty(decoded));
      // this.$store.dispatch("setUser", decoded)
      // console.log(store.dispatch());
      this.$store.dispatch("user/setUserType", type);
      this.$cookies.set("usertype", type);
      localStorage.setItem("usertype", type);

      // console.log("----", this.$store.state);
      // this.$store.state.user;
      this.changeUserTypeDialogVisible = false;
    },

    mousov1() {
      this.mouse1 = true;
    },
    mousov2() {
      this.mouse2 = true;
    },
    mousle1() {
      this.mouse1 = false;
    },
    mousle2() {
      this.mouse2 = false;
    },
  },
};
</script>

<style >
.login {
  width: 1920px;
  height: 100%;
  background: linear-gradient(316deg, #ff8840 0%, #fffbf6 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.login-view {
  margin-top: 171px;
}
.head-view {
  position: fixed;
  top: 0;
}
.wxsc-view {
  /* margin-top: 11.87rem; */
  margin-top: 171px;
}
</style>