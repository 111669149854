<template>
  <div>
    <div
      class="job-card"
      v-for="item in jobList"
      :key="item.id"
      @click="toJobDetail(item.id)"
    >
      <div class="jobcard-left-view">
        <div class="title-tag-chatimg">
          <span class="job-title">{{ item.name }}</span>
          <!-- <span
            v-show="item.urgent && item.urgent != '' && item.urgent != 0"
            class="tag"
            >急聘</span
          >
          <span
            v-show="item.xsdate && item.xsdate != '' && item.xsdate != 0"
            class="tag"
            >置顶</span
          >
          <span
            v-show="item.rec && item.rec != '' && item.rec != 0"
            class="tag tjtag"
            >推荐</span
          > -->
          <el-popover
            placement="bottom-start"
            width="300"
            trigger="hover"
            :offset:="500"
          >
            <img
              slot="reference"
              class="chat-img"
              src="../../assets/findjob_ljgt.png"
              alt=""
              @click.stop="toChatPage(item.uid)"
            />
            <div
              class="popover-link-inf-view"
              @click.stop="toChatPage(item.uid)"
            >
              <img class="user-logo" :src="item.logo" alt="" />
              <div class="link-inf-left-view">
                <div class="top-inf">
                  <span class="link-name">{{ item.recruit.name }}</span>
                  <span class="dian"></span>
                  <span class="onlin">{{ item.recruit.online }}</span>
                </div>
                <div class="bot-inf">
                  {{ item.recruit.job }}·{{ item.com_name }}
                </div>
              </div>
            </div>
          </el-popover>
        </div>
        <div class="pr-add-exp">
          <!-- <span class="pr-view"
            >{{ item.minsalary }}-{{ item.maxsalary }}元/月</span
          > -->
          <div class="add-exp-pat">
<!--            <span>工作类型</span>
            <el-divider direction="vertical"></el-divider>-->
            <span>招募人数: {{item.num}} 位</span>
          </div>
        </div>
        <!-- <div class="other-tag">
          <label v-for="i in item.welfare" :key="i">
            <span>{{ i }}</span>
          </label>
          
        </div> -->

        <div class="bot-com-addr-name">
          <div class="comaddr">
            <img src="../../assets/jobinf_addr_img.png" alt="" />
            <span>{{ item.city }}</span>
          </div>
          <div class="comname">{{ item.com_name }}</div>
        </div>
      </div>
      <div class="jobcard-right-view">
        <div class="part-gz-view">
          {{ item.salary }} <el-divider direction="vertical"></el-divider>
          {{ item.billing }}
        </div>
        <div class="up-tiem">{{item.jobOnline}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { checkUserIsCanChat } from "@/api/indiviuser/jobInfoPage";
import { checkIsLahe } from "@/api/commen/aboutTalentsApi.js";
export default {
  props: {
    jobList: {
      type: Array,
    },
  },
  data() {
    return {
      isLogin: false,
    };
  },
  created() {
    this.isLogin = localStorage.token ? true : false;
  },
  methods: {
    toJobDetail(id) {
      let newRouter = this.$router.resolve({
        path:`/parttimejobdetail?id=${id}`
      });
      window.open(newRouter.href,"_blank")
    },
    toChatPage(uid) {
      if (!this.isLogin) {
        this.$store.dispatch("user/setUserType", 1);
        this.$cookies.set("usertype", 1);
        localStorage.setItem("usertype", 1);
        this.$router.push(`/login`);
        return false;
      }
      checkUserIsCanChat()
        .then((result) => {
          console.log("ooopppooo", result);
          if (result.data.score > result.data.myscore || result.code == 102) {
            // 102简历未通过审核
            this.$message({
              message: result.msg,
              type: "warning",
            });
            // uni.showToast({
            //   title: res.msg,
            //   icon: "none",
            // });
            setTimeout(() => {
              this.$router.push(`/useredtresume`);
            }, 1500);
          } else {
            checkIsLahe({ to_uid: uid })
              .then((result) => {
                console.log("是否拉黑", result);
                if (result.data.hmd == 1) {
                  this.$confirm(
                    "您已将对方拉黑，确认要给他发送消息吗？",
                    "提示",
                    {
                      confirmButtonText: "确定",
                      cancelButtonText: "取消",
                      type: "warning",
                    }
                  )
                    .then(() => {
                      this.$router.push(`/chatpage?chatid=${uid}`);
                    })
                    .catch(() => {});
                } else {
                  if (result.data.to_hmd == 1) {
                    this.$confirm(
                      "对方已将你拉黑，您不能发送消息给TA!",
                      "提示",
                      {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                      }
                    )
                      .then(() => {})
                      .catch(() => {});
                  } else {
                    this.$router.push(`/chatpage?chatid=${uid}`);
                  }
                }
              })
              .catch(() => {});
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style>
.job-card {
  width: 986px;
  background-color: #fff;
  display: flex;
  align-items: center;
  border-radius: 16px;
  margin-top: 20px;
  padding: 30px 24px;
  justify-content: space-between;
}
.job-card .jobcard-left-view {
}
.job-card .jobcard-left-view .title-tag-chatimg {
  display: flex;
  align-items: center;
}
.job-card .jobcard-left-view .title-tag-chatimg .job-title {
  font-size: 22px;
  
  font-weight: 600;
  color: #191919;
  line-height: 28px;
}

.job-card .jobcard-left-view .title-tag-chatimg .tag {
  display: inline-block;
  width: 32px;
  height: 17px;
  background: #f34b37;
  border-radius: 0px 4px 0px 4px;
  font-size: 12px;
  
  font-weight: 400;
  color: #ffffff;
  line-height: 17px;
  text-align: center;
  margin-left: 20px;
}

.job-card .jobcard-left-view .title-tag-chatimg .tjtag {
  background: #fe6002;
}

.job-card .jobcard-left-view .title-tag-chatimg .chat-img {
  width: 84px;
  height: 25px;
  margin-left: 40px;
}

.job-card .jobcard-left-view .pr-add-exp {
  display: flex;
  align-items: center;
  margin-top: 12px;
}
.job-card .jobcard-left-view .pr-add-exp .pr-view {
  font-size: 20px;
  
  font-weight: 600;
  color: #fe6002;
}

.job-card .jobcard-left-view .pr-add-exp .add-exp-pat {
  font-size: 14px;
  
  font-weight: 400;
  color: #757575;
}

.job-card .jobcard-left-view .bot-com-addr-name {
  display: flex;
  align-items: center;
  margin-top: 18px;
}
.job-card .jobcard-left-view .bot-com-addr-name .comaddr {
  display: flex;
  align-items: center;
  font-size: 14px;
  
  font-weight: 400;
  color: #757575;
  line-height: 16px;
}
.job-card .jobcard-left-view .bot-com-addr-name .comaddr img {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}

.job-card .jobcard-left-view .bot-com-addr-name .comname {
  font-size: 18px;
  
  font-weight: 400;
  color: #191919;
  line-height: 24px;
  margin-left: 24px;
}

.job-card .jobcard-left-view .other-tag {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.job-card .jobcard-left-view .other-tag span {
  background: #f5f6fa;
  font-size: 14px;
  
  font-weight: 400;
  color: #757575;
  padding: 4px 8px;
  margin-right: 16px;
}
.job-card .jobcard-right-view {
  text-align: right;
}

.job-card .jobcard-right-view .part-gz-view {
  font-size: 20px;
  
  font-weight: 600;
  color: #fe6002;
  line-height: 28px;
}
.job-card .jobcard-right-view .up-tiem {
  font-size: 14px;
  
  font-weight: 400;
  color: #757575;
  line-height: 16px;
  margin-top: 50px;
}
</style>