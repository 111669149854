<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-10-17 17:07:47
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-06-07 16:45:43
 * @FilePath: /lcwpcvue/src/components/userInfPageLeftMenu.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="user-index-left-navm">
    <div class="el-menu-page">
      <div class="top-title">
        <span @click="toUserIndex" v-show="usertype == 1">个人中心</span>
        <span @click="toUserIndex" v-show="usertype == 2">企业中心</span>
      </div>

      <el-menu
        :default-active="privateIdx"
        class="el-menu-vertical-demo"
        @open="handleOpen"
        @close="handleClose"
        @select="handleSelect"
        v-if="usertype == 1"
      >
        <!-- <el-menu-item index="1" style="border-bottom: 1px solid #e7e8eb">
          <span slot="title">个人中心</span>
        </el-menu-item> -->
        <!-- <el-divider class="edevider"></el-divider> -->
        <el-menu-item index="/useredtresume">
          <img
            v-show="privateIdx === '/useredtresume'"
            class="lf-menu-img"
            src="../assets/leftnavmen/resu_set_c.png"
            alt=""
          />
          <img
            v-show="privateIdx !== '/useredtresume'"
            class="lf-menu-img"
            src="../assets/leftnavmen/resu_set.png"
            alt=""
          />
          <span slot="title">我的简历</span>
        </el-menu-item>
        <el-menu-item index="/chatpage">
          <img
            v-show="privateIdx !== '/chatpage'"
            class="lf-menu-img"
            src="@/assets/company_xx_img.webp"
          />
          <img
            v-show="privateIdx === '/chatpage'"
            class="lf-menu-img"
            src="@/assets/leftnavmen/company_xx_img2.png"
          />
          <span slot="title">消息</span>
        </el-menu-item>
        <el-menu-item index="/jobInformation">
          <img
            v-show="privateIdx !== '/jobInformation'"
            class="lf-menu-img"
            src="../assets/leftnavmen/my-resume-on.png"
          />
          <img
            v-show="privateIdx === '/jobInformation'"
            class="lf-menu-img"
            src="../assets/leftnavmen/my-resume-open.png"
          />
          <span slot="title">我的求职</span>
        </el-menu-item>
        <el-menu-item index="/privacy">
          <img
            v-show="privateIdx !== '/privacy'"
            class="lf-menu-img"
            src="../assets/leftnavmen/resu_privacy_set.png"
          />
          <img
            v-show="privateIdx === '/privacy'"
            class="lf-menu-img"
            src="../assets/leftnavmen/resu_privacy_set_c.png"
          />
          <span slot="title">隐私设置</span>
        </el-menu-item>
        <el-menu-item index="/jobreport">
          <img
            v-show="privateIdx !== '/jobreport'"
            class="lf-menu-img"
            src="@/assets/company_bzfk_img.webp"
          />
          <img
            v-show="privateIdx === '/jobreport'"
            class="lf-menu-img"
            src="@/assets/leftnavmen/company_bzfk_img2.png"
          />
          <span slot="title">职位举报</span>
        </el-menu-item>
        <el-menu-item index="/mytransfer" v-show="hasShop > 0">
          <img
            v-show="privateIdx !== '/mytransfer'"
            class="lf-menu-img"
            src="@/assets/company_wdzjd_img.webp"
          />
          <img
            v-show="privateIdx === '/mytransfer'"
            class="lf-menu-img"
            src="@/assets/leftnavmen/company_wdzjd_img2.png"
          />
          <span slot="title">转接店</span>
        </el-menu-item>

        <el-menu-item index="/myorderpage">
          <img
            v-show="privateIdx != '/myorderpage'"
            class="lf-menu-img"
            src="@/assets/company_wddd_img.webp"
          />
          <img
            v-show="privateIdx == '/myorderpage'"
            class="lf-menu-img"
            src="@/assets/leftnavmen/company_wddd_img2.png"
          />
          <span slot="title">我的订单</span>
        </el-menu-item>
        <el-menu-item index="/setpage">
          <img
            v-show="privateIdx != '/setpage'"
            class="lf-menu-img"
            src="@/assets/company_set_img.webp"
          />
          <img
            v-show="privateIdx == '/setpage'"
            class="lf-menu-img"
            src="@/assets/leftnavmen/company_set_img2.png"
          />
          <span slot="title">账号与安全中心</span>
        </el-menu-item>
      </el-menu>

      <el-menu
        :default-active="privateIdx"
        class="el-menu-vertical-demo"
        @open="handleOpen"
        @close="handleClose"
        @select="handleSelect"
        v-if="usertype == 2"
      >
        <!-- <el-menu-item index="1" style="border-bottom: 1px solid #e7e8eb">
          <span slot="title">个人中心</span>
        </el-menu-item> -->
        <!-- <el-divider class="edevider"></el-divider> -->
        <el-menu-item index="/myequitypage">
          <div class="el-men-item">
            <img
              v-show="privateIdx != '/myequitypage'"
              class="lf-menu-img"
              src="@/assets/company_wdqy_img.webp"
              alt=""
            />
            <img
              v-show="privateIdx == '/myequitypage'"
              class="lf-menu-img"
              src="@/assets/leftnavmen/company_wdqy_img2.png"
              alt=""
            />
            <span slot="title">我的权益</span>
          </div>
        </el-menu-item>
        <el-menu-item index="/companyinfdetail">
          <div class="el-men-item">
            <img
              v-if="privateIdx != '/companyinfdetail'"
              class="lf-menu-img"
              src="@/assets/company_gszy_img.webp"
              alt=""
            />
            <img
              v-if="privateIdx == '/companyinfdetail'"
              class="lf-menu-img"
              src="@/assets/company_gszy_img2.png"
              alt=""
            />
            <span slot="title">公司主页</span>
          </div>
        </el-menu-item>
        <el-menu-item index="/resumemanage">
          <div class="el-men-item">
            <img
              v-show="privateIdx != '/resumemanage'"
              class="lf-menu-img"
              src="@/assets/company_jlgl_img.webp"
              alt=""
            />
            <img
              v-show="privateIdx == '/resumemanage'"
              class="lf-menu-img"
              src="@/assets/leftnavmen/company_jlgl_img2.png"
              alt=""
            />
            <span slot="title">简历管理</span>
          </div>
        </el-menu-item>
        <el-menu-item index="/jobmanage">
          <div class="el-men-item">
            <img
              v-show="privateIdx != '/jobmanage'"
              class="lf-menu-img"
              src="@/assets/company_zwgl_img.webp"
              alt=""
            />
            <img
              v-show="privateIdx == '/jobmanage'"
              class="lf-menu-img"
              src="@/assets/leftnavmen/company_zwgl_img2.png"
              alt=""
            />
            <span slot="title">职位管理</span>
          </div>
        </el-menu-item>
        <el-menu-item index="/mytransfer" v-show="hasShop > 0">
          <div class="el-men-item">
            <img
              v-show="privateIdx != '/mytransfer'"
              class="lf-menu-img"
              src="@/assets/company_wdzjd_img.webp"
              alt=""
            />
            <img
              v-show="privateIdx == '/mytransfer'"
              class="lf-menu-img"
              src="@/assets/leftnavmen/company_wdzjd_img2.png"
              alt=""
            />
            <span slot="title">我的转接店</span>
          </div>
        </el-menu-item>
        <el-menu-item index="/mysubaccount" v-show="subNumber > 0">
          <div class="el-men-item">
            <img
              v-show="privateIdx != '/mysubaccount'"
              class="lf-menu-img"
              src="@/assets/leftnavmen/child-num-nc.png"
              alt=""
            />
            <img
              v-show="privateIdx == '/mysubaccount'"
              class="lf-menu-img"
              src="@/assets/leftnavmen/child-num-c.png"
              alt=""
            />
            <span slot="title">子账号</span>
          </div>
        </el-menu-item>
        <el-menu-item index="/chatpage">
          <div class="el-men-item">
            <img
              v-show="privateIdx != '/chatpage'"
              class="lf-menu-img"
              src="@/assets/company_xx_img.webp"
              alt=""
            />
            <img
              v-show="privateIdx == '/chatpage'"
              class="lf-menu-img"
              src="@/assets/leftnavmen/company_xx_img2.png"
              alt=""
            />
            <span slot="title">消息</span>
          </div>
        </el-menu-item>
        <el-menu-item index="/companyinteraction">
          <div class="el-men-item">
            <img
              v-show="privateIdx != '/companyinteraction'"
              class="lf-menu-img"
              src="@/assets/leftnavmen/company_left_hd_img_none.png"
              alt=""
            />
            <img
              v-show="privateIdx == '/companyinteraction'"
              class="lf-menu-img"
              src="@/assets/leftnavmen/company_left_hd_img.png"
              alt=""
            />
            <span slot="title">互动</span>
          </div>
        </el-menu-item>
        <el-menu-item index="/addressmanage">
          <div class="el-men-item">
            <img
              v-show="privateIdx != '/addressmanage'"
              class="lf-menu-img"
              src="@/assets/company_dzgl_img.webp"
              alt=""
            />
            <img
              v-show="privateIdx == '/addressmanage'"
              class="lf-menu-img"
              src="@/assets/leftnavmen/company_dzgl_img2.png"
              alt=""
            />
            <span slot="title">地址管理</span>
          </div>
        </el-menu-item>
        <el-menu-item index="/mycouponpage">
          <div class="el-men-item">
            <img
              v-show="privateIdx != '/mycouponpage'"
              class="lf-menu-img"
              src="@/assets/company_yhq_img.webp"
              alt=""
            />
            <img
              v-show="privateIdx == '/mycouponpage'"
              class="lf-menu-img"
              src="@/assets/leftnavmen/company_yhq_img2.png"
              alt=""
            />
            <span slot="title">我的优惠券</span>
          </div>
        </el-menu-item>
        <el-menu-item index="/myorderpage">
          <div class="el-men-item">
            <img
              v-show="privateIdx != '/myorderpage'"
              class="lf-menu-img"
              src="@/assets/company_wddd_img.webp"
              alt=""
            />
            <img
              v-show="privateIdx == '/myorderpage'"
              class="lf-menu-img"
              src="@/assets/leftnavmen/company_wddd_img2.png"
              alt=""
            />
            <span slot="title">我的订单</span>
          </div>
        </el-menu-item>
        <el-menu-item index="/helpfkPage">
          <div class="el-men-item">
            <img
              v-show="privateIdx != '/helpfkPage'"
              class="lf-menu-img"
              src="@/assets/company_bzfk_img.webp"
              alt=""
            />
            <img
              v-show="privateIdx == '/helpfkPage'"
              class="lf-menu-img"
              src="@/assets/leftnavmen/company_bzfk_img2.png"
              alt=""
            />
            <span slot="title">帮助与反馈</span>
          </div>
        </el-menu-item>
        <el-menu-item index="/setpage">
          <div class="el-men-item">
            <img
              v-show="privateIdx != '/setpage'"
              class="lf-menu-img"
              src="@/assets/company_set_img.webp"
              alt=""
            />
            <img
              v-show="privateIdx == '/setpage'"
              class="lf-menu-img"
              src="@/assets/leftnavmen/company_set_img2.png"
              alt=""
            />
            <span slot="title">设置</span>
          </div>
        </el-menu-item>
      </el-menu>
    </div>
    <div class="dlapp-erm-view">
      <img src="../assets/download_qrcode.png" alt="" />
    </div>
  </div>
</template>

<script>
// import store from "@/store";
import { isHaveChildAuth } from "@/api/compe/myComboApi.js";
export default {
  props: {
    activeIndex: {
      type: String,
    },
  },
  data() {
    return {
      usertype: 0,
      subNumber: 0,
      hasShop: 0,
      privateIdx: this.activeIndex,
    };
  },
  created() {
    this.usertype = localStorage.usertype;
    console.log("-----", this.privateIdx);
    if (this.usertype == 2) {
      this.getMyVipCombo();
    }
    this.$store.watch((state) => {
      this.hasShop = state.user.userinf.has_shop;
    });
  },
  methods: {
    getMyVipCombo() {
      let isShow = sessionStorage.getItem(
        "isHaveChildAuth:" + this.$store.state.user.uid
      );
      if (isShow != null) {
        this.subNumber = isShow;
      } else {
        isHaveChildAuth().then((e) => {
          if (e.data.isHave == "0") {
            this.subNumber = 0;
          } else {
            this.subNumber = 1;
          }
          sessionStorage.setItem(
            "isHaveChildAuth:" + this.$store.state.user.uid,
            this.subNumber
          );
        });
      }
    },
    toUserIndex() {
      this.$router.push("/userhomepage");
    },
    handleOpen(key, keypath) {
      console.log(key, keypath);
    },
    handleSelect(key) {
      console.log(key);
      this.privateIdx = key;
      this.$router.push(key);
      // this.onUpdate(key);
    },
    handleClose() {},
  },
};
</script>

<style>
.top-title {
  padding: 20px 41px;
  font-size: 28px;
  background-color: #38383d;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  margin-bottom: 13px;
}
.top-title span {
  font-weight: 600;
  line-height: 33px;
  background: linear-gradient(180deg, #ffe5bf 0%, #fbc87b 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
}
.el-menu-page {
  width: 225px;
  background-color: #fff;
  border-radius: 16px;
}

/* .el-men-item {
  padding: 20px 0;
} */
.edevider {
  margin: 1px 20px;
}
.lf-menu-img {
  width: 24px;
  height: 24px;
  margin-right: 8px;

}
.el-menu-vertical-demo span {
  font-size: 18px;
}
.el-menu-vertical-demo.el-menu {
  border-right: none;
  border-radius: 16px;
}
.dlapp-erm-view {
  width: 225px;
  margin-top: 30px;
  
}
.dlapp-erm-view img {
  width: 225px;
  height: auto;
}
.user-index-left-navm .el-menu-item{
  height: 70px;
  display: flex;
  align-items: center;
}
.user-index-left-navm .el-menu-item.is-active {
  background-color: #ffeadd;
  border-right: 4px solid #ff8136;
}
.user-index-left-navm .el-menu-item {
  margin-left: 15px;
  border-radius: 8px 0px 0px 8px;
}
</style>