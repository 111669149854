<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-06-20 13:55:45
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-03-04 17:56:18
 * @FilePath: /lcwpcvue/src/components/comUser/registHead.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="hd-view">
    <img class="img1" src="../../assets/registuser/com_reg_1.png" alt="" />
    <div class="ttt1">完成认证 <span>获取猎宠网专属招聘权益</span></div>

    <div class="right-view">
      <div class="rz-gg-item">
        <img src="@/assets/releasejob_rz1_img.png" alt="" />
        <span>海量人才库</span>
      </div>
      <div class="rz-gg-item">
        <img src="@/assets/releasejob_rz3_img.png" alt="" />
        <span>免费发布职位</span>
      </div>
      <div class="rz-gg-item">
        <img src="@/assets/releasejob_rz2_img.png" alt="" />
        <span>免费直聊名额</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.hd-view {
  width: 958px;
  height: 60px;
  background: #fff5f0;
  border-radius: 10px;
  display: flex;
  align-items: center;
  position: relative;
}
.img1 {
  width: 44px;
  height: 35px;
  margin-left: 48px;
}
.ttt1 {
  font-size: 18px;
  
  font-weight: 500;
  color: #222222;
  margin-left: -20px;
}
.ttt1 span {
  color: #fe6002;
}
.hd-view .right-view {
  display: flex;
  position: absolute;
  right: 50px;
}

.hd-view .right-view .rz-gg-item {
  font-size: 14px;
  
  font-weight: 400;
  color: #757575;
  line-height: 16px;
  margin-left: 30px;
}

.hd-view .right-view .rz-gg-item img {
  width: 22px;
  height: 22px;
  margin-right: 8px;
}
</style>