<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-07-18 18:27:29
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-03-05 18:21:10
 * @FilePath: /lcwpcvue/src/components/individualUser/inUserInfCard.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="user-inf-view" @click="toResumeDetail(listdata.uid)">
    <div class="head-img-view">
      <img :src="listdata.resume_photo" alt="" />
    </div>
    <div class="user-name">{{ listdata.uname }}</div>
    <div class="bg-view">
      <label v-for="(item, index) in listdata.name" :key="index">
        <span class="bq">{{ item }}</span>
      </label>
      <!-- <span class="bq">宠物医生</span> -->
    </div>
    <div class="ag-xl-exp-add">
      <span>{{ listdata.age }}</span>
      <el-divider direction="vertical"></el-divider>
      <span>{{ listdata.edu_name }}</span>
      <el-divider direction="vertical"></el-divider>
      <span>{{ listdata.exp_name }}</span>
      <el-divider direction="vertical"></el-divider>
      <label v-for="(ct, index) in listdata.city_classid" :key="index">
        <span
          >{{ ct }}
          <label v-show="index != listdata.city_classid.length - 1"
            >/</label
          ></span
        >
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    listdata: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  methods: {
    toResumeDetail(id) {
      this.$router.push(`/talentsdetail?id=${id}`);
    },
  },
};
</script>

<style>
.user-inf-view {
  width: 340px;
  height: 210px;
  background: #ffffff;
  border-radius: 16px;
  text-align: center;
  display: inline-block;
  margin-top: 24px;
}
.head-img-view img {
  width: 74px;
  height: 74px;
  border-radius: 50%;
  margin-top: 12px;
}
.user-inf-view .user-name {
  font-size: 20px;
  
  font-weight: 600;
  color: #191919;
  margin-top: 6px;
  line-height: 28px;
}
.user-inf-view .bg-view {
  margin-top: 14px;
}
.user-inf-view .bg-view .bq {
  background: #f5f6fa;
  font-size: 12px;
  
  font-weight: 400;
  color: #757575;
  padding: 4px 8px;
  margin-right: 14px;
}
.ag-xl-exp-add {
  font-size: 12px;
  
  font-weight: 400;
  color: #757575;
  line-height: 14px;
  margin-top: 18px;
  overflow: hidden;
  white-space: nowrap;
}
</style>