// //引入方法 <script type="text/javascript" src='{yun:}$config.sy_weburl{/yun}/js/indexingConversion.js'></script>
// var PI_DIX = 3.1415926535897932384626;

// function gcj02towgs84(lng, lat) {
// 	// console.log('--------------gcj02towgs84--------------')
// 	var x_PI = 3.14159265358979324 * 3000.0 / 180.0;
// 	var ee = 0.00669342162296594323;
// 	var a = 6378245.0;
// 	var lat = +lat;
// 	var lng = +lng;
// 	if (out_of_china(lng, lat)) {
// 		return [lng, lat]
// 	} else {
// 		var dlat = transformlat(lng - 105.0, lat - 35.0);
// 		var dlng = transformlng(lng - 105.0, lat - 35.0);
// 		var radlat = lat / 180.0 * PI_DIX;
// 		var magic = Math.sin(radlat);
// 		magic = 1 - ee * magic * magic;
// 		var sqrtmagic = Math.sqrt(magic);
// 		dlat = (dlat * 180.0) / ((a * (1 - ee)) / (magic * sqrtmagic) * PI_DIX);
// 		dlng = (dlng * 180.0) / (a / sqrtmagic * Math.cos(radlat) * PI_DIX);
// 		var mglat = lat + dlat;
// 		var mglng = lng + dlng;
// 		return [lng * 2 - mglng, lat * 2 - mglat]
// 	}
// }

// /**
//  *  将GCJ-02(火星坐标)转为WGS-84:
//  */
// function transformFromGCJToWGS(latitude, longitude) {
// 	var threshold = 0.00001;

// 	// The boundary
// 	var minLat = latitude - 0.5;
// 	var maxLat = latitude + 0.5;
// 	var minLng = longitude - 0.5;
// 	var maxLng = longitude + 0.5;

// 	var delta = 1;
// 	var maxIteration = 30;

// 	while (true) {
// 		var leftBottom = transformFromWGSToGCJ(minLat, minLng);
// 		var rightBottom = transformFromWGSToGCJ(minLat, maxLng);
// 		var leftUp = transformFromWGSToGCJ(maxLat, minLng);
// 		var midPoint = transformFromWGSToGCJ((minLat + maxLat) / 2, (minLng + maxLng) / 2);
// 		delta = Math.abs(midPoint.latitude - latitude) + Math.abs(midPoint.longitude - longitude);

// 		if (maxIteration-- <= 0 || delta <= threshold) {
// 			return {
// 				latitude: (minLat + maxLat) / 2,
// 				longitude: (minLng + maxLng) / 2
// 			};
// 		}

// 		if (isContains({
// 				latitude: latitude,
// 				longitude: longitude
// 			}, leftBottom, midPoint)) {
// 			maxLat = (minLat + maxLat) / 2;
// 			maxLng = (minLng + maxLng) / 2;
// 		} else if (isContains({
// 				latitude: latitude,
// 				longitude: longitude
// 			}, rightBottom, midPoint)) {
// 			maxLat = (minLat + maxLat) / 2;
// 			minLng = (minLng + maxLng) / 2;
// 		} else if (isContains({
// 				latitude: latitude,
// 				longitude: longitude
// 			}, leftUp, midPoint)) {
// 			minLat = (minLat + maxLat) / 2;
// 			maxLng = (minLng + maxLng) / 2;
// 		} else {
// 			minLat = (minLat + maxLat) / 2;
// 			minLng = (minLng + maxLng) / 2;
// 		}
// 	}

// }

// function out_of_china(lng, lat) {
// 	var lat = +lat;
// 	var lng = +lng;
// 	// 纬度3.86~53.55,经度73.66~135.05
// 	return !(lng > 73.66 && lng < 135.05 && lat > 3.86 && lat < 53.55);
// }

// function transformlat(lng, lat) {
// 	var lat = +lat;
// 	var lng = +lng;
// 	var ret = -100.0 + 2.0 * lng + 3.0 * lat + 0.2 * lat * lat + 0.1 * lng * lat + 0.2 * Math.sqrt(Math.abs(lng));
// 	ret += (20.0 * Math.sin(6.0 * lng * PI_DIX) + 20.0 * Math.sin(2.0 * lng * PI_DIX)) * 2.0 / 3.0;
// 	ret += (20.0 * Math.sin(lat * PI_DIX) + 40.0 * Math.sin(lat / 3.0 * PI_DIX)) * 2.0 / 3.0;
// 	ret += (160.0 * Math.sin(lat / 12.0 * PI_DIX) + 320 * Math.sin(lat * PI_DIX / 30.0)) * 2.0 / 3.0;
// 	return ret
// };

// function transformlng(lng, lat) {
// 	var lat = +lat;
// 	var lng = +lng;
// 	var ret = 300.0 + lng + 2.0 * lat + 0.1 * lng * lng + 0.1 * lng * lat + 0.1 * Math.sqrt(Math.abs(lng));
// 	ret += (20.0 * Math.sin(6.0 * lng * PI_DIX) + 20.0 * Math.sin(2.0 * lng * PI_DIX)) * 2.0 / 3.0;
// 	ret += (20.0 * Math.sin(lng * PI_DIX) + 40.0 * Math.sin(lng / 3.0 * PI_DIX)) * 2.0 / 3.0;
// 	ret += (150.0 * Math.sin(lng / 12.0 * PI_DIX) + 300.0 * Math.sin(lng / 30.0 * PI_DIX)) * 2.0 / 3.0;
// 	return ret
// };



// module.exports = {
// 	gcj02towgs84: gcj02towgs84,
// 	// wgs84ToGcj02: wgs84ToGcj02
// 	// isLocationOutOfChina: isLocationOutOfChina,
// 	// transformFromWGSToGCJ: transformFromWGSToGCJ,
// 	// transformFromGCJToBaidu: transformFromGCJToBaidu,
// 	// transformFromBaiduToGCJ: transformFromBaiduToGCJ,
// 	// transformFromGCJToWGS: transformFromGCJToWGS
// }


// gcj02转wgs84
function gcj02towgs84(lng, lat) {
	lat = parseFloat(lat)
	lng = parseFloat(lng)
	// 定义一些常量
	var PI = 3.1415926535897932384626
	var a = 6378245.0
	var ee = 0.00669342162296594323

	function transformlat(lng, lat) {
		var ret = -100.0 + 2.0 * lng + 3.0 * lat + 0.2 * lat * lat + 0.1 * lng * lat + 0.2 * Math.sqrt(Math.abs(lng))
		ret += (20.0 * Math.sin(6.0 * lng * PI) + 20.0 * Math.sin(2.0 * lng * PI)) * 2.0 / 3.0
		ret += (20.0 * Math.sin(lat * PI) + 40.0 * Math.sin(lat / 3.0 * PI)) * 2.0 / 3.0
		ret += (160.0 * Math.sin(lat / 12.0 * PI) + 320 * Math.sin(lat * PI / 30.0)) * 2.0 / 3.0
		return ret
	}

	function transformlng(lng, lat) {
		var ret = 300.0 + lng + 2.0 * lat + 0.1 * lng * lng + 0.1 * lng * lat + 0.1 * Math.sqrt(Math.abs(lng))
		ret += (20.0 * Math.sin(6.0 * lng * PI) + 20.0 * Math.sin(2.0 * lng * PI)) * 2.0 / 3.0
		ret += (20.0 * Math.sin(lng * PI) + 40.0 * Math.sin(lng / 3.0 * PI)) * 2.0 / 3.0
		ret += (150.0 * Math.sin(lng / 12.0 * PI) + 300.0 * Math.sin(lng / 30.0 * PI)) * 2.0 / 3.0
		return ret
	}
	// 判断是否在国内，不在国内则不做偏移
	function out_of_china(lng, lat) {
		return (lng < 72.004 || lng > 137.8347) || ((lat < 0.8293 || lat > 55.8271) || false)
	}
	if (out_of_china(lng, lat)) {
		return [lng, lat]
	} else {
		var dlat = transformlat(lng - 105.0, lat - 35.0)
		var dlng = transformlng(lng - 105.0, lat - 35.0)
		var radlat = lat / 180.0 * PI
		var magic = Math.sin(radlat)
		magic = 1 - ee * magic * magic
		var sqrtmagic = Math.sqrt(magic)
		dlat = (dlat * 180.0) / ((a * (1 - ee)) / (magic * sqrtmagic) * PI)
		dlng = (dlng * 180.0) / (a / sqrtmagic * Math.cos(radlat) * PI)
		var mglat = lat + dlat
		var mglng = lng + dlng
		return [lng * 2 - mglng, lat * 2 - mglat]
	}
}

// wgs84转gcj02
function wgs84togcj02(lng, lat) {
	lat = parseFloat(lat)
	lng = parseFloat(lng)
	// 定义一些常量
	var PI = 3.1415926535897932384626
	var a = 6378245.0
	var ee = 0.00669342162296594323

	function transformlat(lng, lat) {
		var ret = -100.0 + 2.0 * lng + 3.0 * lat + 0.2 * lat * lat + 0.1 * lng * lat + 0.2 * Math.sqrt(Math.abs(lng))
		ret += (20.0 * Math.sin(6.0 * lng * PI) + 20.0 * Math.sin(2.0 * lng * PI)) * 2.0 / 3.0
		ret += (20.0 * Math.sin(lat * PI) + 40.0 * Math.sin(lat / 3.0 * PI)) * 2.0 / 3.0
		ret += (160.0 * Math.sin(lat / 12.0 * PI) + 320 * Math.sin(lat * PI / 30.0)) * 2.0 / 3.0
		return ret
	}

	function transformlng(lng, lat) {
		var ret = 300.0 + lng + 2.0 * lat + 0.1 * lng * lng + 0.1 * lng * lat + 0.1 * Math.sqrt(Math.abs(lng))
		ret += (20.0 * Math.sin(6.0 * lng * PI) + 20.0 * Math.sin(2.0 * lng * PI)) * 2.0 / 3.0
		ret += (20.0 * Math.sin(lng * PI) + 40.0 * Math.sin(lng / 3.0 * PI)) * 2.0 / 3.0
		ret += (150.0 * Math.sin(lng / 12.0 * PI) + 300.0 * Math.sin(lng / 30.0 * PI)) * 2.0 / 3.0
		return ret
	}
	// 判断是否在国内，不在国内则不做偏移
	function out_of_china(lng, lat) {
		return (lng < 72.004 || lng > 137.8347) || ((lat < 0.8293 || lat > 55.8271) || false)
	}
	if (out_of_china(lng, lat)) {
		return [lng, lat]
	} else {
		var dlat = transformlat(lng - 105.0, lat - 35.0)
		var dlng = transformlng(lng - 105.0, lat - 35.0)
		var radlat = lat / 180.0 * PI
		var magic = Math.sin(radlat)
		magic = 1 - ee * magic * magic
		var sqrtmagic = Math.sqrt(magic)
		dlat = (dlat * 180.0) / ((a * (1 - ee)) / (magic * sqrtmagic) * PI)
		dlng = (dlng * 180.0) / (a / sqrtmagic * Math.cos(radlat) * PI)
		var mglat = lat + dlat
		var mglng = lng + dlng
		return [mglng, mglat]
	}
}


module.exports = {
	gcj02towgs84,
	wgs84togcj02
}