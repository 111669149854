<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2024-02-26 18:11:09
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-03-12 10:32:35
 * @FilePath: /lcwpcvue/src/views/eduExperience.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="com-regist">
    <eduexperience class="wxsc-view" @saveEduExp="saveEduExp"></eduexperience>
  </div>
</template>

<script>
import eduexperience from "@/components/individualUser/eduExperience.vue";
export default {
  components: {
    eduexperience,
  },
  methods: {
    saveEduExp() {
      if (localStorage.isStu == 1) {
        this.$router.push("/home");
      } else {
        this.$router.push("/jobexperience");
      }
    },
  },
};
</script>

<style>
</style>