<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2024-04-29 14:42:43
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-06-07 17:05:17
 * @FilePath: /lcwpcvue/src/views/jobInformation.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="user-home-page">
    <div class="top-zk-div"></div>
    <userinfleftmen
      :activeIndex="'/jobInformation'"
      class="left-menu"
    ></userinfleftmen>
    <job-informationize
      class="userinf-index"
      :toPath="path"
    ></job-informationize>
  </div>
</template>
<script>
import userinfleftmen from "@/components/userInfPageLeftMenu.vue";
import JobInformationize from "@/components/resume/jobInformationize.vue";

export default {
  name: "jobInformation",
  components: {
    userinfleftmen,
    JobInformationize,
  },
  data() {
    return {
      path: 0,
    };
  },
  created() {
    if (this.$route.query.type) {
      this.path = this.$route.query.type;
    }
  },
};
</script>

<style>
</style>