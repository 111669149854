/*
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-12-11 17:50:56
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-08-14 10:30:53
 * @FilePath: /lcwpcvue/src/api/compe/jobsApi.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'
/** 
 * 获取经验要求
 */
export function getReleaseJobCalss(params) {
  return request({
    url: 'comClass/getComClass',
    method: 'post',
    data: params,
  })
}

/* 判断待职位是否重复, 薪资是否太低等 */
export function testingJobStatus(params) {
  return request({
    url: 'Comjob/testing',
    method: 'get',
    params: params,
  })
}


/**
 * 新建全职工作
 */
export function addFullJob(params) {
  return request({
    url: 'Comjob/release',
    method: 'post',
    data: params,
  })
}

/**
* 编辑全职工作
* @url Comjob/fullTimeEdit
* @param {} params 
* @return 
*/

export function editFullJob(params) {
  return request({
    url: 'Comjob/fullTimeEdit',
    method: 'post',
    data: params,
  })
}




/* 新建兼职工作 */
export function addPartJob(params) {
  return request({
    url: 'Comjob/releasepartTime',
    method: 'post',
    data: params,
  })
}

/**
* 获取我的职位管理列表
* @url Comjob/jobListV2    v2/Comjob/jobListV3
* @param {column} params 
* @return 
*/
export function getMyJobManageList(params) {
  return request({
    // url: 'v2/Comjob/jobListV2',
    url: 'v2/Comjob/jobListV3',
    method: 'post',
    data: params,
  })
}
/**
* 获取职位管理的数字
* @url Comjob/jobListNum
* @param {} params 
* @return 
*/
export function getMyJobManageNube(params) {
  return request({
    url: 'Comjob/jobListNum',
    method: 'post',
    data: params,
  })
}

/**
* 刷新职位
* @url Comjob/addedServices
* @param {} params 
* @return 
*/

export function refrMyJob(params) {
  return request({
    url: 'Comjob/addedServices',
    method: 'post',
    data: params,
  })
}

/**
* 关闭全职职位
* @url Comjob/fullTimeDown
* @param {id: ,r_status: } params 
* @return 
*/

export function colseMyFullJob(params) {
  return request({
    url: 'Comjob/fullTimeDown',
    method: 'post',
    data: params,
  })
}
/**
* 关闭兼职职位
* @url Comjob/partdown
* @param {id: ,r_status: } params 
* @return 
*/

export function colseMyPartTimeJob(params) {
  return request({
    url: 'Comjob/partdown',
    method: 'post',
    data: params,
  })
}

/**
* 删除全职职位
* @url Comjob/fullTimeDel
* @param {id: ,r_status: } params 
* @return 
*/

export function delMyFullJob(params) {
  return request({
    url: 'Comjob/fullTimeDel',
    method: 'post',
    data: params,
  })
}

/**
* 删除兼职职位
* @url Comjob/delPartTime
* @param {id: ,r_status: } params 
* @return 
*/

export function delMyPartTimeJob(params) {
  return request({
    url: 'Comjob/delPartTime',
    method: 'post',
    data: params,
  })
}

/**
* 获取职位刷新记录
* @url Comjob/addedServices
* @param {id: ,r_status: } params 
* @return 
*/
export function getRefrHistoyList(params) {
  return request({
    url: 'Comjob/addedServices',
    method: 'post',
    data: params,
  })
}

/**
* 审核上架全职职位
* @url Comjob/fullTimeAgain
* @param {id: } params 
* @return 
*/
export function onFullJobInMarange(params) {
  return request({
    url: 'Comjob/fullTimeAgain',
    method: 'post',
    data: params,
  })
}
/**
* 直接上架全职职位
* @url Comjob/fullTimeUpAgain
* @param {id: } params 
* @return 
*/
export function fullTimeUpAgain(params) {
  return request({
    url: 'Comjob/fullTimeUpAgain',
    method: 'post',
    data: params,
  })
}

/**
* 获取职位置顶/急招/推荐套餐
* @url Comjob/jobRating
* @param {rating_type: } params 
* @return 
*/
export function getJobPriceList(params) {
  return request({
    url: 'Comjob/jobRating',
    method: 'post',
    data: params,
  })
}


/**
* 获取权益剩余的点数
* @url Comjob/addedServices
* @param {} params 
* @return 
*/
export function getJobManagerServices(params) {
  return request({
    url: 'Comjob/addedServices',
    method: 'post',
    data: params
  })
}

/**
* 获取置顶、急聘、推荐还剩多少
* @url CompanyStatis/rating
* @param {} params 
* @return 
*/

export function getJobManagerTopUgRec() {
  return request({
    url: 'CompanyStatis/rating',
    method: 'post'
  })
}

/**
* 有权益的扣除权益
* @url Comjob/setJobAdvantage
* @param {type,num,jobId} params 
* @return 
*/

export function deductionInterest(params) {
  return request({
    url: 'Comjob/setJobAdvantage',
    method: 'post',
    data: params
  })
}

/**
 * 修改兼职
 * @url Comjob/editPartTime
 * @return
 */
export function updatePartJob(params) {
  return request({
    url: 'Comjob/editPartTime',
    method: 'post',
    data: params
  })
}


/**
* 获取技能标签
* @url v2/Comjob/getSkill
* @param {job1 ,job1_son } params 
* @return 
*/
export function getJobsSkillsList(params) {
  return request({
    url: 'v2/Comjob/getSkill',
    method: 'get',
    params: params
  })
}

/**
* 获取职位福利
* @url v2/Comjob/getJobWf
* @param {} params 
* @return 
*/
export function getJobsWeafList() {
  return request({
    url: 'v2/Comjob/getJobWf',
  })
}

/**
 * 查看剩余发布职位数
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getJobNum() {
  return request({
    url: 'v2/Comjob/isHaveJobNum',
    method: 'get'
  })
}

/**
 * 
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function payJob(params) {
  return request({
    url: 'v2/CompanyService/payJob',
    method: 'post',
    data: params
  })
}