<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2024-03-18 18:51:55
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-03-21 18:31:53
 * @FilePath: /lcwpcvue/src/views/privacy.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->

<template>
  <div class="user-home-page">
    <div class="top-zk-div"></div>
    <userinfleftmen
      :activeIndex="'/privacy'"
      class="left-menu"
    ></userinfleftmen>
    <edt-privacy class="userinf-index"></edt-privacy>
  </div>
</template>

<script>
import userinfleftmen from "@/components/userInfPageLeftMenu.vue";
import EdtPrivacy from "@/components/edtPrivacy.vue";

export default {
  name: "Privacy",
  components: {
    EdtPrivacy,
    userinfleftmen,
  },
};
</script>

<style>
</style>