<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2024-03-01 17:56:09
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-03-18 14:59:25
 * @FilePath: /lcwpcvue/src/views/userProfile.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="profile-page">
    <span v-html="html"></span>
    <!-- {{ html }} -->
  </div>
</template>

<script>
import { getUserProfile } from "@/api/commen/login";
export default {
  data() {
    return {
      html: "",
    };
  },
  created() {
    this.getUserProfile(this.$route.query.url);
  },
  methods: {
    getUserProfile(url) {
      getUserProfile(url)
        .then((result) => {
          // console.log(result);
          this.html = result.data;
        })
        .catch(() => {});
    },
  },
};
</script>

<style>
.profile-page {
  padding: 50px;
}
</style>