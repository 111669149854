


// var loading;
import axios from 'axios'

import router from "./router/index";
// import {
//   getToken
// } from '@/libs/util'
// 2021年6月22日：统一ui框架为elementUI
import {
  Message,
  Loading
} from 'element-ui';

// import store from '@/store/index.js'
// import { mapActions, mapState } from 'vuex'
const instance = axios.create({
  // baseURL: baseUrl,
  timeout: 105000,
  // withCredentials: true,
  headers: {
    'Content-Type': 'application/json; charset=utf-8'
  }
})

let loadingInstance = null; // 记录页面中存在的loading
let loadingCount = 0; // 记录当前正在请求的数量

function showLoading(data) {
  if (loadingCount === 0) {
    loadingInstance = Loading.service({
      lock: true,
      text: data || '加载中……'
    });
  }
  loadingCount++
}

function hideLoading() {
  loadingCount--
  if (loadingInstance && loadingCount === 0) {
    loadingInstance.close()
    loadingInstance = null
  }
}

// 这里的response包含每次请求的内容
instance.interceptors.request.use(
  async request => {
    if (!request.loadingHide) {
      showLoading(request.loadingText)
    }
    // 全局给header添加token
    if (localStorage.eleToken) {
      // 如果是CAS平台
      // 在请求头部增加 切换的租户 字段（User-Information）发给后台
      request.headers.Authorization = localStorage.eleToken
      // request.headers['Tenant_id'] = getCurrentTenantId()
      // request.headers['Tenant-ID'] = localStorage.getItem('tenantId')
      // if (request.url.indexOf('login') === -1 && request.url.indexOf('refreshToken') === -1 && request.url.indexOf('selectUnReadMessage') === -1) {
      //   await store.dispatch('refreshToken')
      // }
    }
    return request
  },
  error => {
    return Promise.reject(error)
  }
)

// 拦截器
instance.interceptors.response.use(
  (response) => {
    if (!response.config.loadingHide) {
      hideLoading()
    }

    // paas token失效
    // || response.data.code == 401
    if (response.data.code === 'E0B00001' && response.data.data === 'E0B00002') {
      Message({
        message: response.data.msg,
        type: 'warning'
      });
      let timer = setTimeout(() => {
        router.replace({
          name: 'login',
          params: {
            clear: true
          }
        })
        clearTimeout(timer)
      }, 1000)
      return
    }
    return response
  },
  (error) => {
    if (!error.config.loadingHide) {
      hideLoading()
    }
    if (error.response.status === 500 || error.response.status === 502) {
      Message({
        message: error.response.data.msg,
        type: 'warning'
      });
    } else if (error.response.status === 404) {
      Message({
        message: '请求接口不存在' + error.response.status,
        type: 'error'
      });
    } else if (error.response.status === 401) {
      Message({
        message: 'token失效，请重新登陆',
        type: 'warning'
      });
      router.replace({
        name: 'login',
        params: {
          clear: true
        }

      })
    } else {
      Message({
        message: error.response.status,
        type: 'warning'
      });
    }

    return Promise.reject(error)
  }
)

const http = {
  get: (path, data, config) =>
    instance.get(path, {
      params: data,
      ...config
    }),
  post: (path, data, config) => instance.post(path, data, config),
  put: (path, data, config) => instance.put(path, data, config),
  delete: (path, data, config) =>
    instance.delete(path, {
      data,
      ...config
    })
}
export default http



// import axios from "axios";
// import {
//   Loading,
//   Message
// } from "element-ui";
// import router from "./router/index";

// var loading;

// function showLoading() {
//   loading = Loading.service({
//     text: "拼命加载中...",
//     background: "rgba(0,0,0,0.7)",
//     lock: true,
//   });
// }

// function hindLoading() {
//   loading.close();
// }

// //请求拦截
// axios.interceptors.request.use(config => {
//   showLoading();
//   // if (config.method == 'post' || config.method == 'delete') {
//   if (localStorage.eleToken)
//     //设置统一的请求头
//     config.headers.Authorzation = localStorage.eleToken;
//   // }
//   return config;
// }, error => {
//   return Promise.reject(error);
// });

// //响应拦截
// axios.interceptors.response.use(
//   response => {
//     hindLoading();
//     return response;
//   }, error => {
//     // Do something with response error
//     hindLoading();
//     Message.error(error.response.data);
//     //获取错误的状态码
//     const {
//       status
//     } = error.response;
//     if (status == 401) {
//       Message.error("token失效，请重新登录");
//       //清除token
//       localStorage.removeItem("eleToken");
//       //跳转到登录页面
//       router.push("/login");
//     }

//     return Promise.reject(error);
//   }
// );

// export default axios;