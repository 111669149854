<template>
  <div class="jobexper">
    <div class="title-view">
      <div class="title">工作经验</div>
    </div>
    <el-form :model="ruleForm" status-icon class="demo-ruleForm">
      <div class="choseview chsl-input">
        <el-form-item label="">
          <div class="tt">公司名称</div>
          <el-autocomplete
            @input="inputEvents"
            v-model="ruleForm.name"
            :fetch-suggestions="remoteMethod"
            placeholder="填写公司名称"
            @select="experComhandleSelect"
            :trigger-on-focus="false"
          ></el-autocomplete>
        </el-form-item>
      </div>

      <div class="choseview time-sle">
        <el-form-item label="" class="time-sle-for">
          <div class="tt">在职时间</div>
          <!--          <el-date-picker
            v-model="time"
            type="monthrange"
            range-separator="至"
            start-placeholder="入职时间"
            end-placeholder="离职时间"
            value-format="timestamp"
            prefix-icon="none"
            @change="choseExpTime"
          >
          </el-date-picker>-->
          <el-date-picker
            v-model="ruleForm.sdate"
            type="month"
            placeholder="入职时间"
            format="yyyy-MM"
            value-format="timestamp"
            :picker-options="setMonthDisabled"
          >
          </el-date-picker>
          &nbsp; — &nbsp;
          <el-date-picker
            v-model="ruleForm.edate"
            type="month"
            placeholder="离职时间"
            format="yyyy-MM"
            value-format="timestamp"
            :picker-options="setMonthDisabled"
          >
          </el-date-picker>
        </el-form-item>
      </div>

      <div class="choseview time-sle">
        <el-form-item label="" class="time-sle-for">
          <div class="tt">职位名称</div>
          <el-input
            type="text"
            placeholder="请填写你在公司担任的职位"
            v-model="ruleForm.title"
          ></el-input>
        </el-form-item>
      </div>
      <div class="choseview time-sle">
        <el-form-item label="" class="time-sle-for">
          <div class="tt">
            工作内容<span>可简单叙述，在个人简历中详细修改</span>
          </div>
          <el-input
            type="textarea"
            placeholder="可填写在校期间学习到的主要技能及获得的荣誉"
            maxlength="300"
            show-word-limit
            :rows="6"
            v-model="ruleForm.content"
          ></el-input>
        </el-form-item>
      </div>

      <div class="hindreg" v-show="ruleForm.com_id != ''">
        <span>对这家公司隐藏我的简历</span>
        <el-switch
          v-model="hindState"
          active-color="#FF8136"
          inactive-color="#DCDCDC"
          @change="hindChange"
        >
        </el-switch>
      </div>

      <div class="add-eduexp">
        <span class="tt1">继续添加工作经验</span>
        <div class="rg-view" @click="addNewJobExp">
          <i class="el-icon-plus"></i>
          <el-button type="text">添加</el-button>
        </div>
      </div>

      <el-form-item class="subm">
        <el-button type="info" plain @click="banckFunction">上一步</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >下一步</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { searCompName, addJobExp } from "@/api/commen/register";
export default {
  data() {
    return {
      compList: [],
      ruleForm: {
        name: "",
        title: "",
        sdate: "",
        edate: "",
        content: "",
        is_hide: 1,
        eid: "",
        com_id: "",
      },
      time: "",
      hindState: false,
      setMonthDisabled: {
        // 返回禁用时间
        disabledDate(time) {
          // 获取当前的月份信息
          const date = new Date(); // 获取当前的时间基本信息，值是这样的： Tue Jul 20 2021 14:59:43 GMT+0800 (中国标准时间)
          const year = date.getFullYear(); // 获取当前年份，值是这样的： 2021
          let month = date.getMonth() + 1; // 获取当前月份，值是这样的： 6   getMonth()方法返回值是0-11，也就是1月份到12月份，所以要加上1，才是当前月份
          if (month >= 1 && month <= 9) {
            // 如果是1月到9月就要在前面补上一个0   比如：02、07    月份这样表示
            month = "0" + month;
          }
          const nowDate = year.toString() + month.toString(); // 转换成字符串拼接，最终得到年和月，比如此时的时间是2021年7月20号，所以nowDate的值是：202107

          // 获取时间选择器的月份信息
          const timeyear = time.getFullYear(); // 获取时间选择器的年份（有很多）
          let timemonth = time.getMonth() + 1; // 与上面同理
          if (timemonth >= 1 && timemonth <= 9) {
            timemonth = "0" + timemonth;
          }
          const elTimeData = timeyear.toString() + timemonth.toString();

          // 返回，时间选择器的日期月份大于当前日期的月份，又因为disabledDate函数是控制月份禁用不可选
          // 所以，最终就是：时间选择器的月份大于当前的月份，就都禁用掉，所以就实现了最终效果：
          // 小于等于当前月份都不可选
          return elTimeData > nowDate; // 这里虽然是字符串，但是弱类型语言js会做一个转换，是可以比较大小的如： '202107' > '202008'
        },
      },
    };
  },

  methods: {
    inputEvents(e) {
      console.log(e, "---", this.ruleForm.name);
      this.ruleForm.is_hide = 0;
      this.ruleForm.com_id = "";
      this.hindState = false;
    },
    submitForm() {
      if (!this.ruleForm.name) return this.$message.warning("请填写公司名称");
      if (!this.ruleForm.sdate) return this.$message.warning("请填写开始时间");
      if (!this.ruleForm.edate) return this.$message.warning("请填写结束时间");
      if (!this.ruleForm.title) return this.$message.warning("请填写职位名称");
      this.ruleForm.sdate = this.ruleForm.sdate / 1000;
      this.ruleForm.edate = this.ruleForm.edate / 1000;
      if (this.ruleForm.sdate >= this.ruleForm.edate) {
        this.$message.warning("开始时间不能晚于结束时间");
        this.ruleForm.sdate = "";
        this.ruleForm.edate = "";
        return false;
      }
      addJobExp(this.ruleForm)
        .then((result) => {
          console.log("添加工作经验", result);
          this.$emit("saveJobExp");
        })
        .catch((err) => {
          console.log(err);
        });
    },

    remoteMethod(e, cb) {
      cb([]);
      if (e && e.length > 1) {
        searCompName({ com_name: e, page: 1 })
          .then((result) => {
            if (result.data.list.length > 0) {
              result.data.list.forEach((element) => {
                element.value = element.name;
              });
              cb(result.data.list);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    choseExpTime(res) {
      console.log(res);
      this.ruleForm.sdate = res[0];
      this.ruleForm.edate = res[1];
    },
    addNewJobExp() {
      if (this.ruleForm.name == "") {
        this.$message({
          message: "公司名称不能为空",
          type: "warning",
        });
        return false;
      }
      if (this.ruleForm.sdate == "") {
        this.$message({
          message: "入职时间不能为空",
          type: "warning",
        });
        return false;
      }
      if (this.ruleForm.edate == "") {
        this.$message({
          message: "离职时间不能为空",
          type: "warning",
        });
        return false;
      }
      this.ruleForm.sdate = this.ruleForm.sdate / 1000;
      this.ruleForm.edate = this.ruleForm.edate / 1000;
      if (this.ruleForm.sdate >= this.ruleForm.edate) {
        this.$message.warning("开始时间不能晚于结束时间");
        this.ruleForm.sdate = "";
        this.ruleForm.edate = "";
        return false;
      }
      addJobExp(this.ruleForm)
        .then((result) => {
          console.log("添加工作经验", result);
          this.$message({
            message: "保存成功！",
            type: "success",
          });
          this.ruleForm = {
            name: "",
            title: "",
            sdate: "",
            edate: "",
            content: "",
            is_hide: "",
            eid: "",
            com_id: "",
          };
        })
        .catch((err) => {
          console.log(err);
        });
    },
    hindChange(e) {
      if (!this.ruleForm.com_id) {
        this.hindState = false;
      }
      console.log(e);
      if (e) {
        this.ruleForm.is_hide = 1;
      } else {
        this.ruleForm.is_hide = 0;
      }
    },
    experComhandleSelect(e) {
      console.log(e);
      this.ruleForm.com_id = e.uid;
      if (!e.uid) {
        this.hindState = false;
        this.ruleForm.is_hide = 0;
      }
    },
    banckFunction() {
      this.$router.go(-1);
    },
  },
};
</script>

<style >
.jobexper {
  width: 861px;
  background-color: #ffffff;
  border-radius: 20px;
  padding: 54px 71px;
}
.title-view .title {
  font-size: 30px;

  font-weight: 600;
  color: #222222;
  margin-bottom: 50px;
}

.title-view .t2 {
  font-size: 20px;

  font-weight: 500;
  color: #b1b1b1;
  margin-top: 12px;
  margin-bottom: 50px;
}

.choseview {
  position: relative;
}

.choseview .tt,
.choseviewlong .tt {
  font-size: 14px;
  color: #606266;
}

.choseview .tt span,
.choseviewlong .tt span {
  font-size: 12px;
  color: #b1b1b1;
}

.chsl-input.el-cascader .el-input .el-input__inner {
  /* text-align: center; */
}

.chsl-input .el-select {
  width: 860px;
  margin-right: 57px;
}

.el-date-editor.el-range-editor.el-input__inner.el-date-editor--monthrange {
  width: 860px;
}

.el-picker-panel.el-date-range-picker.el-popper {
  width: 860px;
}
.el-date-range-picker .el-picker-panel__body {
  width: 860px;
}

.hindreg {
  width: 861px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  color: #606266;
  margin-top: 10px;
}

.jobexper .subm {
  width: 860px;
  text-align: right;
  margin-top: 40px;
}
.jobexper .subm .el-button {
  width: 369px;
  height: 40px;
  font-size: 14px;
}
.add-eduexp {
  width: 861px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add-eduexp .tt1 {
  font-size: 14px;

  font-weight: 400;
  color: #191919;
  line-height: 28px;
}
.add-eduexp .rg-view {
  font-size: 13px;
  font-weight: 600;
  color: #fe6002;
}
.add-eduexp .rg-view .el-button {
  width: auto;
  color: #fe6002;
  font-size: 13px;
}
</style>