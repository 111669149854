/*
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-12-14 13:35:42
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2023-12-14 13:35:53
 * @FilePath: /lcwpcvue/src/utils/tMapInit.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// 初始化地图
export default {
  init() {
    return new Promise((resolve, reject) => {
      // 如果已加载直接返回
      if (window.T) {
        console.log('地图脚本初始化成功...')
        resolve(window.T)
        reject('error')
      }
    })
  }
}
