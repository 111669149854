<template>
  <div class="send-code-content">
    <input
      type="text"
      class="glb-phone-code-inp glb-phone-code-fist"
      v-model="phone"
      placeholder="输入手机号"
    />
    <input
      type="text"
      class="glb-phone-code-inp"
      v-model="code"
      placeholder="请输入验证码"
    />
    <div v-bind:class="className">
      <span
        class="glb-phone-code-butt"
        v-show="countdown === 0"
        @click="startCountdown"
        >获取验证码</span
      >
      <span class="glb-phone-code-butt" v-if="countdown > 0"
        >{{ countdown }} 秒后重新获取</span
      >
    </div>
  </div>
</template>

<script>
import { sendPhoneCode } from "@/api/commen/login";
export default {
  name: "sendTelCode",
  components: {},
  props: ["eventTpye"],
  data() {
    return {
      countdown: 0,
      timer: null,
      className: "glb-phone-code-butt-before",
      code: "",
      phone: "",
    };
  },
  methods: {
    startCountdown() {
      sendPhoneCode({ event: this.eventTpye, moblie: this.phone }).then(
        (req) => {
          console.log(req);
          if (req.code === 200) {
            this.$message.success(req.msg);
            this.myCountDown(60);
          }
        }
      );
    },
    myCountDown(num) {
      this.countdown = num;
      this.className = "glb-phone-code-butt-after";
      this.timer = setInterval(() => {
        this.countdown--;
        if (this.countdown === 0) {
          clearInterval(this.timer);
          this.className = "glb-phone-code-butt-before";
          this.timer = null;
        }
      }, 1000);
    },
  },
};
</script>

<style scoped>
.send-code-content {
  margin-left: 95px;
}
.glb-phone-code-fist {
  margin: 25px 0;
}
.glb-phone-code-inp {
  width: 452px;
  height: 50px;
  font-size: 20px;
  letter-spacing: 1px;
  border-radius: 2px 2px 2px 2px;
  border: 1px solid #e7e8eb;
  padding-left: 16px;
}
.glb-phone-code-butt-before {
  padding-left: 10px;
  border-left: 1px solid #b1b1b1;
  position: relative;
  top: -36px;
  right: -300px;
}
.glb-phone-code-butt-after {
  padding-left: 10px;
  border-left: 1px solid #b1b1b1;
  position: relative;
  top: -36px;
  right: -270px;
}
.glb-phone-code-butt {
  margin-left: 30px;
  font-size: 16px;
  
  font-weight: 400;
  color: #fe6002;
  line-height: 19px;
}
input::-webkit-input-placeholder {
  /*WebKit browsers*/
  font-size: 16px;
  
  font-weight: 400;
  color: #b1b1b1;
}

input::-moz-input-placeholder {
  /*Mozilla Firefox*/
  font-size: 16px;
  
  font-weight: 400;
  color: #b1b1b1;
}

input::-ms-input-placeholder {
  /*Internet Explorer*/
  font-size: 16px;
  
  font-weight: 400;
  color: #b1b1b1;
}
</style>