<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2024-05-13 11:48:33
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-05-13 17:56:15
 * @FilePath: /lcwpcvue/src/views/informationList.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="information-list-page">
    <div class="top-zk-div"></div>
    <div class="information-group">
      <div class="top-navbar-view" ref="top-navbar-view-ref">
        <div v-for="item in informationClass" :key="item.id">
          <div
            class="navbar-item"
            :class="clsaaId == item.id ? 'navbar-item-sl' : ''"
            @click="changeClassId(item.id)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="top-navbar-butt" @click.stop="unfold()">
        {{isUnfold}}
      </div>
    </div>
    <div class="information-list-view">
      <label v-for="item in informationList" :key="item.id">
        <div
          class="information-item"
          @mouseover="hadlerMouseOver(item.id)"
          @mouseout="hadlerMoseOut"
          :class="item.id == isHover ? 'over-information' : ''"
          @click="toInformationDetail(item.id,item.group_name)"
        >
          <img class="information-img" :src="item.thumbnail" alt="" />
          <div class="information-title">
            {{ item.title }}
          </div>
          <div class="infor-titme">{{ item.time }}</div>
          <div class="topic-view"><span>#</span>{{item.group_name}}</div>
        </div>
      </label>
    </div>
    <el-pagination
        v-show="informationList.length > 0"
        @current-change="pagesChage"
        background
        layout="prev, pager, next"
        :page-count="allPage"
        :current-page.sync="currentpage"
        class="pag-ina"
    >
    </el-pagination>
    <div v-show="informationList.length <= 0" style="height: 400px;width: 900px;background-color: white;">
      <NullPage
          title="暂无内容"
      ></NullPage>
    </div>
  </div>
</template>

<script>
import {
  getInformationClass,
  getInformationList,
} from "@/api/commen/informationAip.js";
import NullPage from "@/components/nullPage.vue";

export default {
  components: {NullPage},
  data() {
    return {
      informationClass: [],
      clsaaId: 0,
      informationList: [],
      isHover: 0,
      isUnfold:'展开',
      page:1,
      allPage: 0,
      currentpage: 1,
    };
  },
  created() {
    this.getInformationClass();
  },
  methods: {
    pagesChage(e) {
      this.page = e;
      this.getInformationList();
      window.scrollTo(0,0)
    },
    unfold(){
      if (this.isUnfold === '展开'){
        this.isUnfold = '收起';
        this.$refs["top-navbar-view-ref"].style.height='auto';
      }else {
        this.isUnfold = '展开';
        this.$refs["top-navbar-view-ref"].style.height='26px';
      }
    },
    hadlerMouseOver(id) {
      this.isHover = id;
    },
    hadlerMoseOut() {
      this.isHover = 0;
    },
    getInformationClass() {
      getInformationClass()
        .then((result) => {
          console.log("获取咨询分类", result);
          result.data.unshift({
            id:'0',
            name:'全部',
          });
          this.informationClass = result.data;
          this.clsaaId = result.data[0].id;
          this.getInformationList();
        })
        .catch(() => {});
    },
    getInformationList() {
      getInformationList({
        page: this.page,
        group_id: this.clsaaId,
      }).then((result) => {
          if (result.data.list){
            result.data.list.forEach((it) => {
              it.time = new Date(it.datetime * 1000).format("yyyy年MM月dd日");
            });
            this.informationList = result.data.list;
          }else {
            this.informationList = [];
          }
        this.allPage = parseInt(result.data.allpage);
        })
        .catch(() => {});
    },
    changeClassId(id) {
      this.page = 1;
      this.currentpage = 1;
      this.clsaaId = id;
      this.getInformationList();
    },
    toInformationDetail(id,hy) {
      let newRouter = this.$router.resolve({
        path:`/informationdetail?id=${id}&hy=${hy}`
      });
      window.open(newRouter.href,"_blank")
    },
  },
};
</script>

<style>
.information-list-page {
  width: 100%;
  height: 100%;
  overflow: scroll;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.information-list-page .information-group{
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  width: 1020px;
  height: 85px;
  /*background-color: white;*/
  /*padding: 10px 10px;*/
}
.information-list-page .top-navbar-view {
  max-width: 950px;
  height: 38px;
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  overflow: auto;
  scrollbar-width: none; /* 对于Firefox */
  white-space: nowrap;
}

.information-list-page .top-navbar-view::-webkit-scrollbar {
  display: none; /* 对于Chrome, Safari和Opera */
}
.information-list-page .top-navbar-butt{
  font-size: 16px;
  line-height: 36px;
  color: #fe6002;
  font-weight: bold;
  cursor: pointer;
}
.information-list-page .top-navbar-view .navbar-item {
  
  font-weight: 400;
  font-size: 16px;
  color: #222222;
  line-height: 22px;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 5px 10px;
  border-radius: 22px;
  white-space: nowrap;
  cursor: pointer;
}
.information-list-page .top-navbar-view .navbar-item:hover{
  background-color: rgba(255, 129, 54, 0.15);
}

.information-list-page .top-navbar-view .navbar-item-sl {
  background-color: #fe6002;
  color: #fff;
}
.information-list-view {
  margin-top: 40px;
  width: 1032px;
}
.information-list-view .information-item {
  display: inline-block;
  width: 322px;
  height: 358px;
  border-radius: 8px;
  background-color: #faf9f9;
  margin: 0 11px;
  margin-bottom: 22px;
}

.information-list-view .over-information {
  background-color: #fff;
}
.information-list-view .information-item .information-img {
  width: 322px;
  height: 191px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.information-list-view .information-item .information-title {
  height: 58px;
  padding: 0 16px;
  margin-top: 16px;
  
  font-weight: 600;
  font-size: 18px;
  color: #222222;
  line-height: 27px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.information-list-view .information-item .infor-titme {
  padding: 0 16px;
  margin-top: 8px;
  
  font-weight: 400;
  font-size: 14px;
  color: #939597;
  line-height: 20px;
}
.information-list-view .information-item .topic-view {
  padding: 0 16px;
  margin-top: 15px;
  padding-bottom: 16px;
  
  font-weight: 500;
  font-size: 14px;
  color: #939597;
  line-height: 20px;
}
.information-list-view .information-item .topic-view span {
  color: #ff8136;
  margin-right: 8px;
}
</style>