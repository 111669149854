<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2024-03-19 11:56:50
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-05-10 16:01:38
 * @FilePath: /lcwpcvue/src/components/individualUser/jobReportCompon.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="job-report-components">
    <div style="height: 30px"></div>
    <div class="common-title-view">
      <span class="left-xian-view"></span>
      <span class="text">职位举报</span>
    </div>

    <div class="report-table-view">
      <el-table
        :header-cell-style="{ 'background-color': '#EAECF5' }"
        :data="reportList"
        :border="true"
        style="width: 100%"
      >
        <el-table-column prop="job_name" label="职位" width="116px">
        </el-table-column>
        <el-table-column prop="r_name" label="举报公司" width="210px">
        </el-table-column>
        <el-table-column width="290px" prop="r_reason" label="举报原因">
        </el-table-column>
        <el-table-column width="208px" label="处理结果">
          <template slot-scope="scope">
            <div>
              <span>{{ scope.row.rtime }}</span>
              <span
                v-show="scope.row.result == ''"
                class="stutas"
                style="color: #0294fe"
                >处理中</span
              >
              <span v-show="scope.row.result != ''" class="stutas">已完成</span>
            </div>
            <div>
              <span v-show="scope.row.result != ''"
                >反馈：{{ scope.row.result }}</span
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column width="92px" label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="delReportById(scope.row.id)"
              >删除</el-button
            ></template
          >
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import {
  getMyReportList,
  delReportById,
} from "@/api/indiviuser/aboutUserSetApi.js";
export default {
  data() {
    return {
      reportList: [],
    };
  },
  created() {
    this.getMyReportList();
  },
  methods: {
    getMyReportList() {
      getMyReportList({ page: 1, type: "job" })
        .then((result) => {
          console.log("获取举报", result);
          this.reportList = result.data.list;
        })
        .catch(() => {});
    },

    /* 删除举报 */
    delReportById(id) {
      console.log(id);
      delReportById({ id: id })
        .then((result) => {
          this.$message({
            message: result.msg,
            type: "success",
          });
          this.getMyReportList();
        })
        .catch(() => {});
    },
  },
};
</script>

<style>
.job-report-components {
}
.report-table-view {
  background-color: #fff;
  width: 1148px;
  padding: 33px 24px;
  margin-top: 16px;
  border-radius: 8px;
}
</style>