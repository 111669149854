<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-12-12 14:54:53
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-05-29 19:02:35
 * @FilePath: /lcwpcvue/src/components/choseAddressDialog.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="chose-address-dialog">
    <el-dialog
      title="选择地址"
      :visible.sync="addressDialogShow"
      width="50%"
      @close="dialogClose"
      :show-close="true"
    >
      <div class="table-view">
        <label v-for="item in addressList" :key="item.id">
          <div class="add-item">
            <el-radio-group v-model="choseItem">
              <el-radio
                style="display: flex; align-items: center; zoom: 1.5"
                v-model="choseItem"
                :label="item"
                name=""
              >
                <div class="tt1">
                  {{ item.address1 }}
                </div>
                <div class="tt2">{{ item.prov_name }}-{{ item.city_name }}</div>
              </el-radio>
            </el-radio-group>
          </div>
        </label>

        <!-- <div class="add-item">
          <el-radio
            style="display: flex; align-items: center; zoom: 1.5"
            v-model="radio"
            label="2"
          >
            <span class="tt1">新悦财富中心</span>
            <span class="tt2">邹平市醴泉五路与鹤伴四路交叉路口</span></el-radio
          >
        </div> -->
      </div>

      <span slot="footer" class="dialog-footer-btn-view">
        <el-button
          @click="addAddrDialogShow = true"
          plain
          style="
            border: 1px solid #fe6002;
            color: #fe6002;
            background-color: rgba(254, 96, 2, 0.1);
          "
          >添加地址</el-button
        >
        <el-button type="primary" @click="submitAddress">使用该地址</el-button>
      </span>
    </el-dialog>

    <addaddredialog
      :addAddrDialogShow="addAddrDialogShow"
      @saveAddress="saveAddress"
      @closeMapPop="addAddrDialogShow = false"
    ></addaddredialog>
  </div>
</template>

<script>
import {
  getAddressList,
  getAddressId,
  addAddress,
} from "@/api/commen/addressManageApi.js";
import addaddredialog from "@/components/comUser/addAddressDialog.vue";
export default {
  components: {
    addaddredialog,
  },
  props: {
    addressDialogShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      addAddrDialogShow:false,
      choseAddreDialogVisible: true,
      addressList: [],
      choseItem: {},
    };
  },
  created() {
    this.getAddressList();
  },
  methods: {
    dialogClose() {
      console.log("close");
      this.$emit("closeAddresPop");
      // this.addAddrDialogShow = false;
    },
    getAddressList() {
      getAddressList()
        .then((result) => {
          console.log("获取地址列表", result);
          this.addressList = result.data;
        })
        .catch(() => {});
    },
    submitAddress() {
      if (Object.keys(this.choseItem).length <= 0){
        return this.$message.error('请选择地址');
      }
      this.$emit("submitAddress", this.choseItem);
    },

    saveAddress(e) {
      console.log("saveAddress(e)", e);

      getAddressId({ lat: e.y, lng: e.x })
        .then((result) => {
          console.log("获取城市id", result);
          var addinf = result.data;
          addAddress({
            provinceid: addinf.province_id,
            cityid: addinf.city_id,
            three_cityid: addinf.three_city_id,
            address1: e.fulladdr,
            address2: "",
            x: e.x,
            y: e.y,
          })
            .then((res) => {
              console.log("保存地址", res);
              this.$message({
                message: "添加成功！",
                type: "success",
                center: true,
              });
              this.getAddressList();
            })
            .catch(() => {});
        })
        .catch(() => {});

      // this.newCompanyInfObj.x = e.x;
      // this.newCompanyInfObj.y = e.y;

      // this.newCompanyInfObj.address = e.fulladdr;
      // this.addAddrDialogShow = false;
    },
  },
};
</script>

<style>
.chose-address-dialog .el-dialog__wrapper .el-dialog {
  border-radius: 15px;
}
.table-view {
  padding: 20px 31px;
}
.add-item {
  margin-bottom: 20px;
}
.add-item .tt1 {
  font-size: 14px;
  font-weight: 600;
  color: #2f2f2f;
  width: 560px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.add-item .tt2 {
  display: block;
  font-size: 10px;
  margin-top: 5px;
}
.chose-address-dialog .dialog-footer-btn-view {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.chose-address-dialog .dialog-footer-btn-view .el-button {
  width: 200px;
}
</style>