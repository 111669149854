/*
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2024-03-22 11:09:32
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-06-05 17:07:22
 * @FilePath: /lcwpcvue/src/api/commen/aboutUserIndexApi.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import request from "@/utils/request";
/**
* 获取沟通过的列表
* @url chat/chatListV2
* @param {page} params 
* @return 
*/
export function getChatedUserList(params) {
  return request({
    url: 'chat/chatListV2',
    method: 'post',
    data: params,
  })
}

/**
* 获取收藏的简历列表
* @url Comcollection/collectionListV2
* @param {page} params 
* @return 
*/

export function getComColletUserList(params) {
  return request({
    url: 'Comcollection/collectionListV2',
    method: 'post',
    data: params,
  })
}

/**
* 获取面试的列表
* @url Interview/getInterviewList
* @param {page} params 
* @return 
*/

export function getInterviewList(params) {
  return request({
    url: 'Interview/getInterviewList',
    method: 'post',
    data: params,
  })
}

/**
* 企业的谁看过我
* @url Interaction/seeMeCom
* @param {page} params 
* @return 
*/

export function getWolookMeList(params) {
  return request({
    url: 'Interaction/seeMeCom',
    method: 'post',
    data: params,
  })
}

/**
* 企业的我看过谁
* @url v2/company/lookList?page=1
* @param {page} params 
* @return 
*/
export function getMelookedList(params) {
  return request({
    url: 'v2/company/lookList',
    method: 'get',
    params: params,
  })
}