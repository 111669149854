<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-05-23 16:38:24
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-06-07 17:55:41
 * @FilePath: /lcwpcvue/src/components/individualUser/userBasInf.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="basinf">
    <div class="title-view">
      <div class="title">个人基本信息</div>
      <div class="t2">请填写真实信息，招聘者可通过这些条件搜索到你哦～</div>
    </div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="basinf-ruleForm-ref"
      status-icon
      class="basinf-demo-ruleForm"
    >
      <div class="name-view">
        <el-form-item class="name-input-view" label=" 姓名" prop="name">
          <el-input
            type="text"
            v-model="ruleForm.name"
            autocomplete="off"
            placeholder="请填写真实姓名"
          ></el-input>
        </el-form-item>
        <el-form-item class="checkbox-input-view" label="" prop="">
          <el-checkbox v-model="ruleForm.show_name"></el-checkbox>
          <span>显示先生/女士</span>
        </el-form-item>
        <el-form-item label="">
          <div class="avatar-uploader">
            <img
              @click="choseAvatarDialogVisible = true"
              v-show="ruleForm.resume_photo != ''"
              :src="ruleForm.resume_photo"
            />
            <img
              @click="choseAvatarDialogVisible = true"
              class="avatar-uploader"
              v-show="ruleForm.resume_photo == ''"
              src="@/assets/registuser/head_imgup.png"
              alt=""
            />
          </div>
        </el-form-item>
      </div>

      <div class="choseview">
        <el-form-item label=" " prop="sex">
          <div class="tt">性别</div>
          <div class="choseview-input-view">
            <!-- <el-input type="text" autocomplete="off"></el-input> -->
            <span
              @click="choseSex(1)"
              class="ch-item"
              :class="ruleForm.sex == 1 ? 'sl-sex' : ''"
              >男</span
            >
            <span
              @click="choseSex(2)"
              class="ch-item"
              :class="ruleForm.sex == 2 ? 'sl-sex' : ''"
              >女</span
            >
          </div>
        </el-form-item>
      </div>

      <div class="choseview">
        <el-form-item label=" " prop="birthday">
          <div class="tt">出生日期</div>
          <el-date-picker
            :clearable="false"
            v-model="ruleForm.birthday"
            type="date"
            placeholder="请选择出生年月"
            popper-class="popclass"
            @change="choseBirthday"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            prefix-icon="none"
          >
          </el-date-picker>
        </el-form-item>
        <div class="tstext">
          根据《劳动法》《未成年人保护法》等相关法律规定，申请注册猎宠网，请选择与你身份证一致的真实年龄，并确保你已年满16周岁
        </div>
      </div>
      <div class="choseview">
        <el-form-item label=" " prop="edu">
          <div class="tt">最高学历</div>
          <!-- <div class="chose-input">
            <span class="t1">请选择最高学历</span>
            <i class="el-icon-arrow-down"></i>
          </div> -->
          <!-- <el-cascader
            :options="options"
            v-model="selectedOptions"
            @change="handleChange"
          >
          </el-cascader> -->
          <el-select
            class="degree-select"
            v-model="ruleForm.edu"
            @change="slXlFun"
            placeholder="请选择最高学历"
          >
            <el-option
              v-for="item in eduList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="choseview">
        <el-form-item label=" " prop="job_type">
          <div class="tt">求职身份</div>
          <div class="choseview-input-view">
            <!-- <el-input type="text" autocomplete="off"></el-input> -->
            <span
              class="ch-item"
              :class="ruleForm.job_type == 1 ? 'sl-sex' : ''"
              @click="choseUserType(1)"
              >我是职场人</span
            >
            <span
              class="ch-item"
              :class="ruleForm.job_type == 2 ? 'sl-sex' : ''"
              @click="choseUserType(2)"
              >我是学生</span
            >
          </div>
        </el-form-item>
      </div>

      <div class="choseview" v-show="ruleForm.job_type == 1">
        <el-form-item label="">
          <div class="tt">
            <span style="color: #f56c6c; font-size: 11px">*</span> 进入行业时间
          </div>
          <!-- <div class="chose-input">
            <span class="t1">请选择进入宠物行业工作时间</span>
            <i class="el-icon-arrow-down"></i>
          </div> -->
          <!-- <div class="chosetime"></div> -->
          <el-date-picker
            :clearable="false"
            class="date-pick"
            v-model="ruleForm.firstyear"
            type="month"
            placeholder="请选择进入宠物行业工作时间"
            popper-class="popclass"
            value-format="timestamp"
            format="yyyy-MM"
            prefix-icon="none"
            @change="choseJobTime"
            :picker-options="setMonthDisabled"
          >
          </el-date-picker>
          <div
            ref="firstyear-userbasinf-ref"
            style="color: #f56c6c; font-size: 9.5px; display: none"
          >
            请选择进入行业时间
          </div>
        </el-form-item>
      </div>

      <el-form-item class="subm">
        <el-button type="primary" @click="submitForm('ruleForm')">{{
          submitBtnText
        }}</el-button>
      </el-form-item>
    </el-form>
    <div class="progess-view" v-show="showProgre">
      <el-progress
        text-color="#fe6002"
        type="circle"
        :percentage="uppercent"
        color="#fe6002"
      ></el-progress>
    </div>
    <el-dialog
      :show-close="false"
      :close-on-click-modal="false"
      title=""
      :visible.sync="choseAvatarDialogVisible"
      width="780px"
    >
      <div class="chose-avatar-dialog">
        <div class="title">默认头像</div>
        <div class="mr-avatar-img-list">
          <label v-for="(item, index) in userHeadMrList" :key="index">
            <img
              :class="lsAvatar == item ? 'mr-ava-sl' : ''"
              :src="item"
              alt=""
              @click="choseMrAvatar(item)"
            />
          </label>
        </div>
        <!-- <el-upload
          
          ref="upload"
          :action="updateUrl"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
          :on-progress="progressUpload"
        > -->
        <div class="avatar-upload-view">
          <el-button slot="trigger" type="primary" plain icon="el-icon-plus"
            >上传头像</el-button
          >
          <zczheardimg
            style="position: absolute; top: 0; left: 250px; opacity: 0"
            :lookImgPath="avatar"
            @success-img-path="successimgpath"
          />
          <div slot="tip" class="el-upload__tip">
            支持jpg、png、jpej、bmp格式，小于10M
          </div>
        </div>

        <!-- </el-upload> -->

        <div class="foot-btn-view">
          <el-button type="primary" @click="setAvatarUrl">确定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// postResumeBasInf
import { editResumBasInf } from "@/api/indiviuser/userInf";
import { jsGetAge } from "@/utils/jsGetAge.js";
import { parseTime } from "@/utils/index.js";
import {
  getXlClass,
  addUserBasInf,
  getUserMrAvatarImg,
} from "@/api/commen/register";
export default {
  data() {
    return {
      updateUrl: process.env.VUE_APP_UPLOUD + "/api/upload/uploadCos",
      ruleForm: {
        name: "", // 名字
        sex: "", // 性别
        resume_photo: "",
        edu: "",
        show_name: 0,
        birthday: "", // 出生日期
        job_type: "", // 职场人, 学生
        firstyear: "", // 进入宠物行业时间
        telphone: "", // 电话
        wxnum: "", // 微信号
        living: "", // 居住地
        domicile: "", // 户籍地
        uid: "", // 用户uid, 用户
      },
      rules: {
        name: [
          { required: true, message: "姓名不能为空", trigger: "blur" },
          { min: 2, message: "姓名不能少于2个字", trigger: "blur" },
        ],
        sex: [{ required: true, message: "请选择性别", trigger: "blur" }],
        edu: [{ required: true, message: "请选择最高学历", trigger: "blur" }],
        job_type: [
          { required: true, message: "请选择求职身份", trigger: "blur" },
        ],
        birthday: [
          { required: true, message: "请选择出生年月", trigger: "blur" },
        ],
      },
      eduList: [],
      submitBtnText: "下一步",
      isFisterLogin: false,
      uppercent: 0,
      showProgre: false,
      choseAvatarDialogVisible: false,
      userHeadMrList: [],
      lsAvatar: "",
      setMonthDisabled: {
        // 返回禁用时间
        disabledDate(time) {
          // 获取当前的月份信息
          const date = new Date(); // 获取当前的时间基本信息，值是这样的： Tue Jul 20 2021 14:59:43 GMT+0800 (中国标准时间)
          const year = date.getFullYear(); // 获取当前年份，值是这样的： 2021
          let month = date.getMonth() + 1; // 获取当前月份，值是这样的： 6   getMonth()方法返回值是0-11，也就是1月份到12月份，所以要加上1，才是当前月份
          if (month >= 1 && month <= 9) {
            // 如果是1月到9月就要在前面补上一个0   比如：02、07    月份这样表示
            month = "0" + month;
          }
          const nowDate = year.toString() + month.toString(); // 转换成字符串拼接，最终得到年和月，比如此时的时间是2021年7月20号，所以nowDate的值是：202107

          // 获取时间选择器的月份信息
          const timeyear = time.getFullYear(); // 获取时间选择器的年份（有很多）
          let timemonth = time.getMonth() + 1; // 与上面同理
          if (timemonth >= 1 && timemonth <= 9) {
            timemonth = "0" + timemonth;
          }
          const elTimeData = timeyear.toString() + timemonth.toString();

          // 返回，时间选择器的日期月份大于当前日期的月份，又因为disabledDate函数是控制月份禁用不可选
          // 所以，最终就是：时间选择器的月份大于当前的月份，就都禁用掉，所以就实现了最终效果：
          // 小于等于当前月份都不可选
          return elTimeData > nowDate; // 这里虽然是字符串，但是弱类型语言js会做一个转换，是可以比较大小的如： '202107' > '202008'
        },
      },
    };
  },
  created() {
    this.isFisterLogin = this.$store.state.user.isfisterlogin;
    if (this.$store.state.user.isfisterlogin) {
      this.submitBtnText = "下一步";
    } else {
      this.submitBtnText = "保存";
    }
    if (localStorage.userBaseInf) {
      this.ruleForm = JSON.parse(localStorage.userBaseInf);
    }

    this.getXlClass();
    this.getUserMrAvatarImg();
  },
  methods: {
    //获取个人信息

    //
    submitForm() {
      this.$refs["basinf-ruleForm-ref"].validate((valid) => {
        if (this.ruleForm.job_type == "1" && !this.ruleForm.firstyear) {
          this.$refs["firstyear-userbasinf-ref"].style.display = "block";
          return false;
        }
        let str = parseTime(this.ruleForm.birthday);
        let age = jsGetAge(str.split(" ")[0]);
        if (age < 16) {
          return this.$message.error("’出生日期‘请选择大于16周岁的日期");
        }
        if (valid) {
          localStorage.setItem("userBaseInf", JSON.stringify(this.ruleForm));
          this.ruleForm.firstyear = this.ruleForm.firstyear / 1000;
          if (this.ruleForm.show_name) {
            this.ruleForm.show_name = 1;
          } else {
            this.ruleForm.show_name = 0;
          }
          if (this.isFisterLogin) {
            addUserBasInf(this.ruleForm)
              .then((result) => {
                console.log("注册基本信息", result);
                if (result.code === 200) {
                  this.$message({
                    message: "提交成功！",
                    type: "success",
                  });
                  this.$emit("saveBaseInf");
                }
              })
              .catch(() => {});
          } else {
            editResumBasInf()
              .then((result) => {
                console.log("编辑个人信息", result);
              })
              .catch((err) => {
                console.log(err);
              });
          }
        } else {
          return false;
        }
      });
    },
    /* 获取默认头像 */

    getUserMrAvatarImg() {
      getUserMrAvatarImg()
        .then((result) => {
          console.log("获取默认头像", result);
          var i = [];
          result.data.forEach((it) => {
            i.push(process.env.VUE_APP_IMG + it);
          });
          this.userHeadMrList = i;
        })
        .catch(() => {});
    },

    //上传头像
    beforeAvatarUpload(e) {
      // this.showProgre = true;
      const isLt10M = e.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        this.$message.error("文件大于10M");
        return false;
      }
      return true;
    },

    successimgpath(e) {
      this.choseAvatarDialogVisible = false;
      this.showProgre = false;
      this.ruleForm.resume_photo = e.data.httpCosUrl;
    },
    handleAvatarSuccess(e) {
      console.log("上传完", e);
      this.choseAvatarDialogVisible = false;
      this.showProgre = false;
      this.ruleForm.resume_photo = e.data.httpCosUrl;

      /*      editResumBasInf(this.ruleForm)
        .then((result) => {
          console.log("头像", result);
        })
        .catch(() => {});*/
    },
    progressUpload(e) {
      console.log("上传中...", e);
      this.uppercent = parseInt(e.percent);
    },

    //选择性别
    choseSex(e) {
      this.ruleForm.sex = e;
    },

    //获取学历列表
    getXlClass() {
      getXlClass({
        name: "user_edu",
      })
        .then((result) => {
          console.log("获取学历", result);
          this.eduList = result.data.list.user_edu;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //选择学历
    slXlFun(e) {
      console.log(e);
      this.ruleForm.edu = e;
    },

    //选择身份
    choseUserType(e) {
      if (e == 2) {
        localStorage.setItem("isStu", 1);
      } else {
        localStorage.removeItem("isStu");
      }
      this.ruleForm.job_type = e;
    },
    checkBirthday(rule, value, callback) {
      if (!value) return callback("请选择大于16周岁的日期");
      let str = parseTime(value);
      let age = jsGetAge(str.split(" ")[0]);
      console.log(age);
      if (age < 16) {
        return callback("请选择大于16周岁的日期");
      } else {
        return callback("");
      }
    },

    //出生日期
    choseBirthday(e) {
      // let str = parseTime(e);
      // let age = jsGetAge(str.split(" ")[0]);
      // if (age < 16) {
      //   return 1;
      // }
      this.ruleForm.birthday = e;
    },
    //进入行业时间
    choseJobTime(e) {
      this.ruleForm.firstyear = e;
    },

    choseMrAvatar(url) {
      this.lsAvatar = url;
    },
    setAvatarUrl() {
      this.choseAvatarDialogVisible = false;
      this.ruleForm.resume_photo = this.lsAvatar;
      /*      editResumBasInf(this.ruleForm)
        .then((result) => {
          console.log("头像", result);
        })
        .catch(() => {});*/
    },
  },
};
</script>
<!-- <style lang="sass">

</style> -->

<style>
.basinf .el-dialog__wrapper .el-dialog {
  border-radius: 20px;
}
.basinf {
  /* height: 800px; */
  background-color: #ffffff;
  border-radius: 24px;
  padding: 54px 71px;
}

.basinf .title-view .title {
  font-size: 30px;

  font-weight: 600;
  color: #222222;
}

.basinf .title-view .t2 {
  font-size: 20px;

  font-weight: 500;
  color: #b1b1b1;
  margin-top: 12px;
}

.name-view {
  display: flex;
  align-items: flex-end;
}

.name-view .name-input-view {
  width: 396px;
}

.name-view .checkbox-input-view {
  margin-left: 80px;
}

.name-view .checkbox-input-view .el-checkbox__inner {
  border-radius: 50%;
}

/* .name-view .checkbox-input-view .checkbox-selector:checked {
 background: #0242c6;

    background-size: 100% 100%
} */
.name-view .checkbox-input-view span {
  margin-left: 5px;
  color: #757575;
  font-size: 13px;
}

.name-view .avatar-uploader img {
  width: 140px;
  height: 140px;
  margin-left: 113px;
}

.choseview {
  position: relative;
}

.choseview .tt {
  font-size: 14px;
  color: #606266;
}

.choseview .choseview-input-view {
  display: flex;
  align-items: center;
}

.choseview .choseview-input-view .ch-item {
  display: block;
  width: 401px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #e4e7ed;
  text-align: center;
  color: #b1b1b1;
}

.choseview .choseview-input-view .ch-item:first-child {
  margin-right: 58px;
}

.choseview .choseview-input-view .sl-sex {
  background-color: #fff5f0;
  color: #fe6002;
  border: 1px solid #fe6002;
}
.chose-input {
  width: 830px;
  height: 40px;
  border: 1px solid #e4e7ed;
  border-radius: 4px;
  padding: 0 15px;
  color: #b1b1b1;
  position: relative;
  display: flex;
  align-items: center;
}

.chose-input .t1 {
  font-size: 14px;

  font-weight: 400;
}

.chose-input i {
  position: absolute;
  right: 15px;
}

.basinf .tstext {
  font-size: 15px;
  line-height: 22px;

  font-weight: 500;
  color: #939597;
}

.choseview .date-pick {
  /* position: absolute;
  margin-top: -40px; */
  /* opacity: 0; */
}

.choseview .pick-i {
  position: absolute;
}

.el-date-editor__trigger {
  display: none;
}
.choseview .el-date-editor.el-input {
  width: 400px;
}

.basinf .choseview .degree-select .el-input__inner {
  width: 400px;
}
.basinf .choseview .el-date-editor .el-input__suffix {
  display: none;
}
/*.basinf .choseview .degree-select .el-input__suffix {*/
/*  width: 860px;*/
/*  display: none;*/
/*}*/

.basinf .el-select {
  width: 860px;
}

.el-picker-panel {
  width: 860px;
}

.basinf .el-picker-panel__content .el-month-table {
  width: 860px;
}

.basinf .el-picker-panel.el-date-picker.el-popper.popclass {
  width: 860px;
}

::v-deep .choseview .el-cascader {
  width: 860px;
}

.el-cascader-menu {
  width: 860px;
}
.basinf .subm {
  width: 860px;
  text-align: right;
  margin-top: 40px;
}
.basinf .subm .el-button {
  width: 369px;
  height: 40px;
  font-size: 14px;
}

::v-deep .el-cascader-menu {
  width: 860px;
}

.progess-view {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(000, 000, 000, 0.7);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chose-avatar-dialog {
  padding: 50px 39px;
}

.chose-avatar-dialog .title {
  font-weight: 600;
  font-size: 22px;
  color: #b1b1b1;
}
.chose-avatar-dialog .mr-avatar-img-list {
  border-bottom: 1px solid#EAECF5;
  padding-bottom: 30px;
}
.chose-avatar-dialog .mr-avatar-img-list img {
  width: 125px;
  height: 125px;
  margin-right: 22px;
  margin-top: 30px;
  border-radius: 50%;
}
.chose-avatar-dialog .mr-avatar-img-list .mr-ava-sl {
  border: 2px solid#FE6002;
}
.avatar-upload-view {
  width: 100%;
  text-align: center;
  margin-top: 60px;
  position: relative;
}

.avatar-upload-view .el-button {
  width: 268px;
  height: 60px;
  font-size: 20px;
}

.avatar-upload-view img {
  width: 268px;
  height: 60px;
}

.avatar-upload-view .el-upload__tip {
  font-weight: 400;
  font-size: 18px;
  color: #b1b1b1;
  line-height: 24px;
  margin-top: 30px;
}
.basinf .foot-btn-view {
  width: 100%;
  text-align: center;
  margin-top: 70px;
  text-align: center;
}

.basinf .foot-btn-view .el-button {
  width: 368px;
  height: 50px;

  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
  line-height: 28px;
}
/*.basinf .choseview .el-input .el-input__inner{
  width: 400px;
}*/
</style>

