/*
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-12-20 17:33:12
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-08-15 09:11:27
 * @FilePath: /lcwpcvue/src/api/commen/payApi.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'
/**
* 所有支付
* @url Rating/submitRatingByPc
* @param {type//1会员支付 5增值服务 10置顶 11急聘 12优先 13自动刷新 33购买当日畅聊 ,rating_id//会员套餐id,pay_type//支付方式 } params 
* @return 
*/


export function submitOrder(params) {
  return request({
    url: 'Rating/submitRatingByPc',
    method: 'post',
    data: params,
  })
}


export function getOrderStatus(orderId) {
  return request({
    url: 'v2/Rating/orderStatus',
    method: 'post',
    data: {
      orderId:orderId
    },
  })
}
