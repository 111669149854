<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2024-05-07 11:08:06
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-05-07 15:02:13
 * @FilePath: /lcwpcvue/src/views/companyList.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="company-list-page">
    <div class="top-zk-div"></div>
    <div class="find-job-sear-view" style="margin: 0 auto;background-color: white;width: 100%">
      <searview
          searchpage="comlist"
          :searchKey="comparams.search_key"
          :pagescrollhe="pageScrollHe"
          @hotJobs="hotJobsSearch"
          @toSearch="toSearch"
      ></searview>
      <filterateview
          searchpage="comlist"
          :jobClass="jobArr"
          @toFliterList="fliterTalentList"
          @clearFiltrateFun="clearFiltrateFun"
          ref="filterateview_ref"
      ></filterateview>
    </div>
    <div class="comlist-content">
      <!--      头部导航-->
      <div class="job-fil-navbar-view">
        <div class="navbar-item" :class="comparams.famous === 'all' ? 'navbar-item-sl' : ''" @click="chooseTag('all')">
          <span>企业列表</span>
          <div v-show="comparams.famous === 'all'" class="btm-xian"></div>
        </div>
        <div class="navbar-item" :class="comparams.famous === 'famous' ? 'navbar-item-sl' : ''" @click="chooseTag('famous')">
          <span>名企招聘</span>
          <div v-show="comparams.famous === 'famous'" class="btm-xian"></div>
        </div>
      </div>
<!--      企业列表-->
    <div class="comlist-content-div">
      <div v-for="(im,idx) in companyList" :key="idx" class="comlist-item" :style="idx % 4 === 3 ? 'margin-right:0' : ''"
        @click.stop="toOpenComInfo(im.uid)"
      >
        <div style="display: flex">
          <img :src="im.logo" class="comlist-item-img">
          <div>
            <p class="comlist-name overflw-comm">{{im.alias}}</p>
            <p class="comlist-params">{{im.cityName}} | {{im.hyName}} | {{im.munName}}</p>
          </div>
        </div>
        <div  style="border-bottom: 1px solid #EAECF5"></div>
        <div style="display: flex;justify-content: flex-start;margin-top: 12px">
          <img src="../assets/com_detail_hot.png" style="width: 22px;height: 22px;margin-right: 8px">
          <div class="comlist-jobnum overflw-comm">正在热招<span style="color: #FE6002">{{im.jobNum}}个</span>{{im.jobName}}相关职位</div>
        </div>
      </div>
    </div>
<!--      分页-->
      <el-pagination
          @current-change="pagesChage"
          background
          layout="prev, pager, next"
          :page-count="allPage"
          :current-page.sync="currentpage"
          class="pag-ina"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {getV2CompanyList} from "@/api/indiviuser/companyList.js";
import searview from "@/components/homeSearComp.vue";
import filterateview from "@/components/filtrateComp.vue";
export default {
  components: {searview,filterateview},
  data() {
    return {
      jobArr:{
        job_id:'all',
        jobclass_id:'all'
      },
      isLogin: false,
      companyList: [],
      recJobList: [],
      comparams:{
        provinceid:"all",
        welfare:"all",
        cityid: "all",
        search_key: "all",
        page: "1",
        fin_id: "all",
        hy: "all",
        mun: "all",
        famous:'famous',
      },
      allPage:0,
      currentpage:1,
      pageScrollHe: 0,
    };
  },
  created() {
    this.isLogin = localStorage.token ? true : false;
    this.getCompanyList();
    let recJob = sessionStorage.getItem("getRecommendJobList");
    if (recJob) {
      this.recJobList = JSON.parse(recJob);
    }
  },
  methods: {
    clearFiltrateFun() {
      this.comparams = {
            provinceid:"all",
            welfare:"all",
            cityid: "all",
            search_key: "all",
            page: 1,
            fin_id: "all",
            hy: "all",
            mun: "all",
            famous:this.comparams.famous,
          };
      this.currentpage = 1;
      this.getCompanyList();
    },
    fliterTalentList(type, id) {
      console.log(type)
      console.log(id)
      this.currentpage = 1;
      this.comparams.page = 1;
      if (type === "pro") {
        this.comparams.provinceid = id.proId;
        this.comparams.cityid = id.cityId;
      } else if (type === "wfe") {
        this.comparams.welfare = id;
      } else if (type === "hy") {
        this.comparams.hy = id;
      } else if (type === "scale") {
        this.comparams.mun = id;
      }
      this.getCompanyList();
    },
    toSearch(kw) {
      this.comparams.search_key = kw;
      this.getCompanyList();
    },
    hotJobsSearch(params) {
      if (localStorage.usertype === "1") {
        this.$router.push(`/findjob?pid=${params.keyid}&cid=${params.id}`);
      } else {
        this.$router.push(
            `/findtalentslist?pid=${params.keyid}&cid=${params.id}`
        );
      }
    },
    toOpenComInfo(uid){
      let newRouter = this.$router.resolve({
        path:`/companyinf?id=${uid}`
      });
      window.open(newRouter.href,"_blank")
    },
    pagesChage(e) {
      console.log("chage", e);
      this.comparams.page = e;
      window.scrollTo(200, 200);
      this.getCompanyList();
    },
    chooseTag(type) {
      this.comparams.famous = type;
      this.getCompanyList();
    },
    getCompanyList() {
      console.log("请求条件",this.comparams)
      getV2CompanyList(this.comparams)
          .then((result) => {
            console.log("获取公司列表", result);
            if (result.data.companys && result.data.companys.length > 0){
              this.companyList = result.data.companys;
            }else {
              this.companyList = [];
            }
            this.allPage = parseInt(result.data.allpage);
          }).catch(() => {});
    },
    toJobDetail(id) {
      this.$route.push(`/jobinfopage?id=${id}`);
    },
  },
};
</script>

<style>
.company-list-page {
  
  font-weight: 400;
}
.company-list-page .comlist-content {
  width: 1476px;
  margin: 0 auto;
}
.company-list-page .comlist-content-div{
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.company-list-page .comlist-item{
  width: 345px;
  height: 177px;
  box-sizing: border-box;
  background-color: white;
  cursor: pointer;
  margin-top: 30px;
  margin-right: 30px;
  padding: 24px 16px;
  border-radius: 8px;
}
.company-list-page  .comlist-item-img{
  width: 70px;
  height: 70px;
  margin-right: 16px;
}
.company-list-page .comlist-item:hover{
  background-color: #fff5f0;
  /*border: #FE6002 1px solid;*/
}
.comlist-name{
  width: 227px;
  height: 28px;
  font-weight: 600;
  font-size: 20px;
  color: #191919;
  margin: 3px 0 16px 0;
}
.comlist-params{
  height: 20px;
  font-size: 14px;
  color: #757575;
  line-height: 16px;
  margin-bottom: 21px;
}
.comlist-jobnum{
  font-size: 14px;
  color: #757575;
  line-height: 27px;
}
.overflw-comm{
  overflow: hidden; /* 确保溢出的内容会被隐藏 */
  white-space: nowrap; /* 确保文本在一行内显示 */
  text-overflow: ellipsis; /* 使用ellipsis显示文本溢出的部分 */
}
</style>