<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2024-03-22 09:08:11
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-04-26 11:58:25
 * @FilePath: /lcwpcvue/src/components/comUser/comChatedUser.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <div style="height: 30px"></div>
    <div class="common-title-view">
      <span class="left-xian-view"></span>
      <span class="text">沟通过</span>
    </div>
    <chateduser :listType="'chated'" :listArr="chatUserList"> </chateduser>
    <div v-show="chatUserList.length == 0" class="nocoupon-list-view">
      <img class="none-list-img" src="@/assets/coupon_none_list.png" alt="" />
      <div class="none-text">暂无内容</div>
    </div>
  </div>
</template>

<script>
import chateduser from "@/components/comUser/comChatedUserItem.vue";
import { getChatedUserList } from "@/api/commen/aboutUserIndexApi.js";
import { formatnyr } from "@/utils/index.js";
export default {
  components: {
    chateduser,
  },
  data() {
    return {
      chatUserList: [],
    };
  },
  created() {
    this.getChatedUserList();
  },
  methods: {
    getChatedUserList() {
      getChatedUserList()
        .then((result) => {
          console.log("沟通过的", result);
          this.chatUserList = result.data.list;
          result.data.list.forEach((element) => {
            element.time =
              formatnyr(element.time).md + " " + formatnyr(element.time).hm;
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style>
.nocoupon-list-view {
  width: 1148px;
}
</style>