<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2024-03-04 11:22:17
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-03-07 17:35:02
 * @FilePath: /lcwpcvue/src/views/myCouponPage.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="user-home-page">
    <div class="top-zk-div"></div>
    <userinfleftmen
      :activeIndex="'/mycouponpage'"
      class="left-menu"
    ></userinfleftmen>
    <mycouponlist class="userinf-index"></mycouponlist>
  </div>
</template>

<script>
import userinfleftmen from "@/components/userInfPageLeftMenu.vue";
import mycouponlist from "@/components/comUser/couponList.vue";

export default {
  components: {
    userinfleftmen,
    mycouponlist,
  },
};
</script>

<style>
</style>