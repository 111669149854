<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-12-15 11:05:01
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-03-04 13:13:29
 * @FilePath: /lcwpcvue/src/components/comUser/subAccount/subAccountIndex.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="sub-account-view">
    <div class="sub-top-basinf-view">
      <img class="user-avater-img" src="../../../assets/com_mr.png" alt="" />
      <div class="right-text-view">
        <div class="name-vipst-sfsta">
          <span class="name">{{ userInf.name }}</span>
          <img
            class="vip-sta-img"
            :src="userInf.vip_icon"
            alt=""
          />
<!--          <img class="sf-stat" src="" alt="" />-->
          <div class="sf-sta-img">
<!--            <img src="../../../assets/findjob_yrzimg.png" alt="" />
            <span>身份未认证</span>-->
            <img :src="userInf.yyzz_status_icon" alt="" />
          </div>
        </div>
        <div class="bot-com-sta">
          <div class="tt1">您当前是：<span>{{userInfoFull.isVip}}</span></div>
          <div class="tt2">服务日期：<span>{{userInfoFull.endTime}}</span></div>
        </div>
      </div>
    </div>
    <div class="ts-vip-img">
      <img
        src="../../../assets/subaccount_byvip_img.png"
        alt=""
        @click="changeShoeidFun(19)"
      />
    </div>
    <div class="common-title-view">
      <span class="left-xian-view"></span>
      <span class="text">子账号列表</span>
    </div>
    <div class="subaccount-list-view">
      <div class="tips-view">
        <img src="../../../assets/subaccount_tips_img.png" alt="" />
      </div>
      <div class="add-btn-view">
        <el-button @click="addSubAcc" type="primary" round icon="el-icon-plus"
          >关联子账号</el-button
        >
      </div>

      <div class="subacc-table-view">
        <el-table
          :data="subAccountList"
          :border="true"
          :header-cell-style="{ background: '#eaecf5' }"
          style="border: 1px solid #e7e8eb"
        >
          <el-table-column align="center" height="50" label="账号/用户名">
            <template slot-scope="scope">
              <div>{{ scope.row.linktel }}</div>
              <div>{{ scope.row.com_name }}</div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="remark_name"
            height="50"
            label="备注昵称"
          >
          </el-table-column>
          <el-table-column align="center" prop="addtime" label="关联日期">
          </el-table-column>

          <el-table-column align="center" label="操作" width="260">
            <template slot-scope="scope">
              <span v-show="scope.row.bind_status == 1">申请中</span>
              <span v-show="scope.row.bind_status == 3">
                <span style="margin-right: 10px;cursor: pointer" @click="anewApply(scope.row)" v-show="userInf.is_vip !== '0'">重新关联</span>
                <span style="cursor: pointer" @click="delAccount(scope.row.id)">删除</span>
              </span>
              <div v-show="scope.row.bind_status == 2">
                <el-button @click="ediSubAccComboNumb(scope.row)" v-show="userInf.is_vip !== '0'"
                  >分配套餐</el-button
                >
                <el-button @click="editInfDialogShowFun(scope.row)" v-show="userInf.is_vip !== '0'"
                  >修改</el-button
                >
                <el-button @click="delModelShow(scope.row.id)"
                  >取消关联</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-dialog
      title="修改账户信息"
      :visible.sync="editSubInfDialog"
      width="40%"
    >
      <div class="editSub-form-view">
        <el-form label-position="left" ref="" size="medium">
          <div class="edi-lin-item">
            <el-form-item label="">
              <div class="items">
                <div class="title"><span>备注昵称</span></div>
                <el-input
                  type="text"
                  v-model="editInfObj.remark_name"
                  placeholder="请填写备注昵称"
                ></el-input>
              </div>
            </el-form-item>
          </div>
          <div class="edi-lin-item">
            <el-form-item label="">
              <div class="items">
                <div class="title"><span>手机号码</span></div>
                <el-input
                  v-model="editInfObj.moblie"
                  type="text"
                  placeholder="请填写手机号码"
                ></el-input>
              </div>
            </el-form-item>
          </div>
          <div class="edi-lin-item">
            <el-form-item label="">
              <div class="items">
                <div class="title"><span>微信号</span></div>
                <el-input
                  v-model="editInfObj.wxnum"
                  type="text"
                  placeholder="请填写微信号"
                ></el-input>
              </div>
            </el-form-item>
          </div>
          <div class="edi-lin-item">
            <el-form-item label="">
              <div class="items">
                <div class="title"><span>登录密码</span></div>
                <el-input
                  v-model="editInfObj.password"
                  type="text"
                  placeholder="请填写登录密码"
                ></el-input>
              </div>
            </el-form-item>
          </div>
          <div class="dialog-footer-btn">
            <el-button @click="editSubInfDialog = false">取 消</el-button>
            <el-button type="primary" @click="submitSubAccInfo"
              >确 定</el-button
            >
          </div>
        </el-form>
      </div>
    </el-dialog>

    <el-dialog
      v-if="comboSubDialog"
      title="分配套餐"
      :visible.sync="comboSubDialog"
      width="55%"
    >
      <div class="combo-tip-view">
        说明：1、分配数量小于可分配的数量；否则无法成功分配
        <div>
          &emsp;&emsp;&emsp;2、时间会员可分配套餐为：职位置顶、职位推荐、紧急招聘
        </div>
      </div>

      <div class="editcombo-form-view">
        <el-form label-position="left" ref="" size="medium">
          <div class="top-title-view">
            <div class="title-text">主账号可分配</div>
            <div class="title-text">子账号</div>
            <div class="title-text">分配数</div>
          </div>
          <!-- <div v-for="item in comboDialogArr" :key="item.id"> -->
          <div class="combo-items-view">
            <div class="combo-title">发布职位：</div>
            <el-input
              v-model="comboDialogArr[0].company_val"
              disabled
            ></el-input>
            <i class="el-icon-arrow-right"></i>
            <el-input
              v-model="comboDialogArr[0].account_val"
              disabled
            ></el-input>
            <i class="el-icon-arrow-right"></i>
            <el-input v-model="comboToSubObj.assign_job_num"></el-input>
          </div>
          <!-- </div> -->

          <!-- <div class="combo-items-view">
            <div class="combo-title">刷新职位：</div>
            <el-input
              v-model="comboDialogArr[1].company_val"
              disabled
            ></el-input>
            <i class="el-icon-arrow-right"></i>
            <el-input
              v-model="comboDialogArr[1].account_val"
              disabled
            ></el-input>
            <i class="el-icon-arrow-right"></i>
            <el-input v-model="comboToSubObj.assign_auto_refresh"></el-input>
          </div> -->
          <div class="combo-items-view">
            <div class="combo-title">自动刷新：</div>
            <el-input
              v-model="comboDialogArr[1].company_val"
              disabled
            ></el-input>
            <i class="el-icon-arrow-right"></i>
            <el-input
              v-model="comboDialogArr[1].account_val"
              disabled
            ></el-input>
            <i class="el-icon-arrow-right"></i>
            <el-input v-model="comboToSubObj.assign_auto_refresh"></el-input>
          </div>
          <div class="combo-items-view">
            <div class="combo-title">下载简历：</div>
            <el-input
              v-model="comboDialogArr[2].company_val"
              disabled
            ></el-input>
            <i class="el-icon-arrow-right"></i>
            <el-input
              v-model="comboDialogArr[2].account_val"
              disabled
            ></el-input>
            <i class="el-icon-arrow-right"></i>
            <el-input v-model="comboToSubObj.assign_down_resu"></el-input>
          </div>
          <div class="combo-items-view">
            <div class="combo-title">邀请面试：</div>
            <el-input
              v-model="comboDialogArr[3].company_val"
              disabled
            ></el-input>
            <i class="el-icon-arrow-right"></i>
            <el-input
              v-model="comboDialogArr[3].account_val"
              disabled
            ></el-input>
            <i class="el-icon-arrow-right"></i>
            <el-input v-model="comboToSubObj.assign_interview_num"></el-input>
          </div>
          <div class="combo-items-view">
            <div class="combo-title">职位置顶：</div>
            <el-input
              v-model="comboDialogArr[5].company_val"
              disabled
            ></el-input>
            <i class="el-icon-arrow-right"></i>
            <el-input
              v-model="comboDialogArr[5].account_val"
              disabled
            ></el-input>
            <i class="el-icon-arrow-right"></i>
            <el-input v-model="comboToSubObj.assign_job_top_time"></el-input>
          </div>
          <div class="combo-items-view">
            <div class="combo-title">职位紧急：</div>
            <el-input
              v-model="comboDialogArr[6].company_val"
              disabled
            ></el-input>
            <i class="el-icon-arrow-right"></i>
            <el-input
              v-model="comboDialogArr[6].account_val"
              disabled
            ></el-input>
            <i class="el-icon-arrow-right"></i>
            <el-input v-model="comboToSubObj.assign_urgent_time"></el-input>
          </div>
          <div class="combo-items-view">
            <div class="combo-title">职位推荐：</div>
            <el-input
              v-model="comboDialogArr[7].company_val"
              disabled
            ></el-input>
            <i class="el-icon-arrow-right"></i>
            <el-input
              v-model="comboDialogArr[7].account_val"
              disabled
            ></el-input>
            <i class="el-icon-arrow-right"></i>
            <el-input v-model="comboToSubObj.assign_rec_time"></el-input>
          </div>
          <div class="combo-items-view">
            <div class="combo-title">直&emsp;&emsp;聊：</div>
            <el-input
              v-model="comboDialogArr[4].company_val"
              disabled
            ></el-input>
            <div class="chat-tips-view">
              所有子账号的聊天权益，共享主账号的直聊数量。
            </div>
          </div>
          <div class="dialog-footer-btn">
            <el-button size="medium" type="primary" @click="submitComboToSub"
              >保存</el-button
            >
          </div>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getMySubAccountList,
  getSubInfByUserName,
  bindSubAccount,
  getSubAccountInfById,
  getSubAccountComboNumber,
  editSubAcccountInf,
  comboNuberToSub,
  delSubAccount, delAccount,
} from "@/api/compe/subAccountApi.js";
import { parseTime } from "@/utils/index.js";
import {rsaEncrypt} from "@/utils/Rsa/rsa";
export default {
  name: "subaccount",
  data() {
    return {
      userInf:{},
      subAccountList: [],
      editSubInfDialog: false,
      comboSubDialog: false,
      editInfObj: {},
      comboDialogArr: [{}],
      comboToSubObj: {
        id: "",
        assign_job_num: "",
        assign_down_resu: "",
        assign_interview_num: "",
        assign_auto_refresh: "",
        assign_job_top_time: "",
        assign_urgent_time: "",
        assign_rec_time: "",
      },
    };
  },
  // props: {
  //   onUpdate: Function,
  // },
  computed:{
    userInfoFull(){
      let endTime = '永久';
      let isVip = '免费企业';
      if (this.userInf.is_vip === '1'){
        endTime = parseTime(this.userInf.vip_etime,'{y}-{m}-{d}');
        isVip = '会员企业';
      }
      return {endTime:endTime,isVip:isVip};
    }
  },
  created() {
    this.userInf = this.$store.state.user.userinf;
    this.getMySubAccountList();
  },
  methods: {
    // 删除 非解除绑定
    delAccount(id){
      delAccount(id).then(()=>{
        this.getMySubAccountList();
      });
    },
    // 重新绑定
    anewApply(obj){
      bindSubAccount({com_uid:obj.account_uid}).then(()=>{
        this.getMySubAccountList();
      });
    },
    getMySubAccountList() {
      getMySubAccountList({ type: 0 })
        .then((result) => {
          console.log("获取子账号列表", result);
          result.data.list.forEach((element) => {
            element.addtime = parseTime(parseInt(element.ctime));
          });
          this.subAccountList = result.data.list;
        })
        .catch(() => {});
    },
    addSubAcc() {
      this.$prompt("请填写要关联的账号", "关联新账号", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        // inputPattern:
        //   /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
        // inputErrorMessage: "邮箱格式不正确",
      })
        .then(({ value }) => {
          console.log(value);
          getSubInfByUserName({
            account: value,
          })
            .then((result) => {
              console.log("查询绑定账号信息", result);
              this.$alert(
                `<p align="center">您确定要关联账号：<text style="font-weight: bold;">${result.data.info.linktel}</text><br>企业名称：<text style="font-weight: bold;">${result.data.info.name}</text></p>`,
                "关联新账号",
                {
                  showCancelButton: true,
                  dangerouslyUseHTMLString: true,
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                }
              )
                .then(() => {
                  bindSubAccount({ com_uid: result.data.info.uid })
                    .then((bindres) => {
                      console.log("申请绑定子账号", bindres);
                      this.getMySubAccountList();
                      if (bindres.code == 200) {
                        this.$message({
                          message: bindres.msg,
                          type: "success",
                        });
                      }
                    })
                    .catch(() => {});
                })
                .catch(() => {});
            })
            .catch(() => {});
          // this.$message({
          //   type: "success",
          //   message: "你的邮箱是: " + value,
          // });
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "取消输入",
          // });
        });
    },

    editInfDialogShowFun(item) {
      console.log(item);
      getSubAccountInfById({ id: item.id })
        .then((result) => {
          console.log("获取子账号信息", result);
          this.editInfObj = result.data.info;
          this.editSubInfDialog = true;
        })
        .catch(() => {});
    },
    submitSubAccInfo() {
      console.log(this.editInfObj);
      this.editInfObj.password = rsaEncrypt(this.editInfObj.password);
      editSubAcccountInf(this.editInfObj)
        .then((result) => {
          console.log("修改子账户信息", result);
          this.editSubInfDialog = false;
          this.getMySubAccountList();
          if (result.code == 200) {
            this.$message({
              message: "修改成功！",
              type: "success",
              center: true,
            });
          }
        })
        .catch(() => {});
    },

    /* 获取子主账号的配额 */
    ediSubAccComboNumb(item) {
      this.comboToSubObj.id = item.id;
      getSubAccountComboNumber({ id: item.id })
        .then((result) => {
          console.log("获取子主配额", result);
          this.comboDialogArr = result.data.config;
          this.comboSubDialog = true;
        })
        .catch(() => {});
    },
    submitComboToSub() {
      this.comboSubDialog = false;
      comboNuberToSub(this.comboToSubObj)
          .then((e) => {
            this.comboToSubObj = {
              id: "",
              assign_job_num: "",
              assign_down_resu: "",
              assign_interview_num: "",
              assign_auto_refresh: "",
              assign_job_top_time: "",
              assign_urgent_time: "",
              assign_rec_time: "",
            }
            this.$message.success(e.msg);
          })
          .catch(() => {
          });
    },

    delModelShow(id) {
      this.$confirm("取消关联后，将无法管理和收到该账号的任何消息。", "提醒", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.delSubAccount(id);
        })
        .catch(() => {});
      // console.log(id);
    },
    /* 解除绑定 */
    delSubAccount(id) {
      delSubAccount({ id: id })
        .then((result) => {
          console.log("解除绑定", result);
          this.getMySubAccountList();
          if (result.code == 200) {
            this.$message({
              message: "解绑成功！",
              type: "success",
              center: true,
            });
          }
        })
        .catch(() => {});
    },
    changeShoeidFun() {
      // this.onUpdate(key);
      this.$router.push('/vippaypage')
    },
  },
};
</script>

<style>
.sub-top-basinf-view {
  width: 1196px;
  background-color: #fff;
  border-radius: 16px;
  display: flex;
  align-items: center;
  padding: 24px 0;
}
.sub-top-basinf-view .user-avater-img {
  width: 88px;
  height: 88px;
  border-radius: 50%;
  margin-left: 24px;
}
.sub-top-basinf-view .right-text-view {
  margin-left: 18px;
}
.sub-top-basinf-view .right-text-view .name-vipst-sfsta {
  display: flex;
  align-items: center;
}
.sub-top-basinf-view .right-text-view .name-vipst-sfsta .name {
  font-size: 24px;
  
  font-weight: 600;
  color: #222222;
  line-height: 28px;
}
.sub-top-basinf-view .right-text-view .name-vipst-sfsta .vip-sta-img {
  width: auto;
  height: 18px;
  margin-left: 16px;
}
.sub-top-basinf-view .right-text-view .name-vipst-sfsta .sf-sta-img img {
  width: auto;
  height: 20px;
  margin-right: 10px;
  margin-left: 10px;
}
.sub-top-basinf-view .right-text-view .bot-com-sta {
  display: flex;
  align-items: center;
  margin-top: 14px;
}
.sub-top-basinf-view .right-text-view .bot-com-sta .tt1 {
  font-size: 18px;
  
  font-weight: 400;
  color: #191919;
  line-height: 24px;
}
.sub-top-basinf-view .right-text-view .bot-com-sta .tt1 span {
  font-size: 20px;
  
  font-weight: 600;
  color: #fe6002;
  line-height: 28px;
}
.sub-top-basinf-view .right-text-view .bot-com-sta .tt2 {
  font-size: 18px;
  
  font-weight: 400;
  color: #191919;
  line-height: 24px;
  margin-left: 15px;
}
.sub-top-basinf-view .right-text-view .bot-com-sta .tt2 span {
  font-size: 18px;
  
  font-weight: 600;
  color: #191919;
  line-height: 28px;
}
.sub-account-view .ts-vip-img {
  margin-top: 40px;
}
.sub-account-view .ts-vip-img img {
  width: 1196px;
  height: 80px;
}
.sub-account-view .common-title-view {
  display: flex;
  align-items: center;
  margin-top: 40px;
}

.common-title-view .left-xian-view {
  display: inline-block;
  width: 4px;
  height: 20px;
  background-color: #fe6002;
  border-radius: 5px;
}
.common-title-view .text {
  font-size: 24px;
  
  font-weight: 600;
  color: #222222;
  line-height: 28px;
  margin-left: 16px;
}
.subaccount-list-view {
  margin-left: 24px;
}
.subaccount-list-view .tips-view {
  margin-top: 16px;
}
.subaccount-list-view .tips-view img {
  width: 1148px;
  height: 40px;
}
.subaccount-list-view .add-btn-view {
  margin-top: 16px;
}
.subacc-table-view {
  width: 1148px;
  height: 554px;
  background-color: #fff;
  padding: 33px 24px;
  margin-top: 16px;
  border-radius: 8px;
}
/* .el-table__body {
  border: 1px solid #e7e8eb;
} */

/* ::v-deep .el-table th {
  border-color: #e7e8eb !important;
}
::v-deep .el-table td {
  border-color: #e7e8eb !important;
}
::v-deep .el-table--group,
::v-deep .el-table--border {
  border: 0.1px solid #e7e8eb !important;
} */
.el-table {
  border-bottom: 1px solid #e7e8eb;
  border-right: 1px solid #e7e8eb;
  margin: 0 auto;
}
.el-table th {
  border: 1px solid #ffffff !important;
  border-right: none !important;
  border-bottom: none !important;
}
.el-table td {
  border: 1px solid #e7e8eb;
  border-right: none !important;
}
.subacc-table-view .cell {
  font-weight: 700;
  font-size: 20px;
  color: #3b3b3b;
}

.sub-account-view .el-dialog__wrapper .el-dialog {
  border-radius: 20px;
}
.editSub-form-view {
  padding: 30px 140px;
}
.editcombo-form-view .dialog-footer-btn,
.editSub-form-view .dialog-footer-btn {
  width: 100%;
  text-align: center;
}
.editcombo-form-view .dialog-footer-btn {
  margin-top: 60px;
  padding-bottom: 20px;
}
.editcombo-form-view
  .dialog-footer-btn
  button.el-button.el-button--primary.el-button--medium {
  width: 380px;
  height: 52px;
}
.combo-tip-view {
  border-top: 2px solid #eaecf5;
  padding: 27px 47px;
  margin-top: 10px;
  font-size: 16px;
  
  font-weight: 600;
  color: #f34b37;
}
.combo-tip-view div {
  margin-top: 6px;
}
.editcombo-form-view {
  padding: 0 131px;
}
.editcombo-form-view .top-title-view {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  padding-left: 116px;
  margin-top: 27px;
  margin-bottom: 4px;
}

.editcombo-form-view .top-title-view .title-text {
  font-size: 18px;
  
  font-weight: 400;
  color: #666666;
  width: 300px;
  text-align: center;
}

.editcombo-form-view .combo-items-view {
  display: flex;
  align-items: center;
  margin-top: 18px;
}
.editcombo-form-view .combo-items-view .combo-title {
  width: 140px;
  font-size: 18px;
  
  font-weight: 400;
  color: #000000;
  line-height: 24px;
}
.editcombo-form-view .combo-items-view .el-input {
  width: 156px;
  height: 36px;
}
.editcombo-form-view .combo-items-view .el-input__inner {
  /*background-color: #f5f6fa;*/
}
.editcombo-form-view .combo-items-view i {
  font-size: 20px;
  margin: 0 30px;
}
.editcombo-form-view .combo-items-view .chat-tips-view {
  font-size: 19px;
  margin-left: 20px;
  font-weight: 600;
  color: #f34b37;
}
.sub-account-view{
  width: 1198px;
}
</style>