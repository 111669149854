<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-06-08 17:02:11
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-06-27 19:17:26
 * @FilePath: /lcwpcvue/src/views/listIndex.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="home-page" @scroll="homePageScroll">
    <div class="top-zk-div"></div>
    <img class="top-img" src="../assets/index_tb.png" />
    <searview
      :searchpage="'home'"
      :pagescrollhe="pageScrollHe"
      @toSearch="toSearch"
      @hotJobs="toJobList"
    ></searview>
    <div class="class-baner-view">
      <div class="class-view">
        <label class="popover-class-view">
          <label v-for="(item, index) in jobClassArr" :key="item.id">
            <el-popover
              :key="item.id"
              placement="right"
              trigger="hover"
              width="350"
              :visible-arrow="false"
              v-if="classPageIndex == 1 && index <= 8"
              @hide="popoverHideFun"
              @show="popoverShowFun(item.id)"
            >
              <div class="popover-show-view" @mouseout="popoverSlotMoseOut">
                <div class="popover-show-title">{{ item.name }}</div>
                <div class="popover-show-items-view">
                  <label v-for="nextitem in item.next_list" :key="nextitem.id">
                    <div
                      class="popo-item"
                      @click="toSearchByClass(nextitem)"
                      @mouseover="
                        popoverItemMouseOver(nextitem.keyid, nextitem.id)
                      "
                      @mouseout="popoverItemMoseOut"
                      :class="
                        popoItemId == nextitem.id ? 'popover-hover-cs' : ''
                      "
                    >
                      {{ nextitem.name }}
                    </div>
                  </label>
                </div>
              </div>
              <div
                class="fenlei-item"
                slot="reference"
                :class="popoClassId == item.id ? 'popover-slot-hover-cs' : ''"
                @mouseover="popoverSlotMouseOver(item.id)"
              >
                <span class="fa-text">{{ item.name }}</span>
                <label
                  v-for="(nextitem, index) in item.next_list"
                  :key="nextitem.id"
                >
                  <label v-show="item.name.length <= 6">
                    <span v-show="index < 3" class="son-text">{{
                      nextitem.name
                    }}</span>
                  </label>
                  <label v-show="item.name.length > 6">
                    <span v-show="index < 2" class="son-text">{{
                      nextitem.name
                    }}</span>
                  </label>
                </label>
                <i class="el-icon-caret-right right-ic-jt"></i>
              </div>
            </el-popover>
            <el-popover
              :key="item.id"
              placement="right"
              trigger="hover"
              width="350"
              :visible-arrow="false"
              v-if="classPageIndex == 2 && index > 8"
            >
              <div class="popover-show-view">
                <div class="popover-show-title">{{ item.name }}</div>
                <div class="popover-show-items-view">
                  <label v-for="nextitem in item.next_list" :key="nextitem.id">
                    <div
                      class="popo-item"
                      @click="toSearchByClass(nextitem)"
                      @mouseover="popoverItemMouseOver(nextitem.id)"
                      @mouseout="popoverItemMoseOut"
                      :class="
                        popoItemId == nextitem.id ? 'popover-hover-cs' : ''
                      "
                    >
                      {{ nextitem.name }}
                    </div>
                  </label>
                </div>
              </div>
              <div class="fenlei-item" slot="reference">
                <span class="fa-text">{{ item.name }}</span>
                <label
                  v-for="(nextitem, index) in item.next_list"
                  :key="nextitem.id"
                >
                  <label v-show="item.name.length <= 6">
                    <span v-show="index < 3" class="son-text">{{
                      nextitem.name
                    }}</span>
                  </label>
                  <label v-show="item.name.length > 6">
                    <span v-show="index < 2" class="son-text">{{
                      nextitem.name
                    }}</span>
                  </label>
                </label>
                <i class="el-icon-caret-right right-ic-jt"></i>
              </div>
            </el-popover>
          </label>
        </label>
        <div class="page-fy-view">
          <div class="pageall-num-view">{{ classPageIndex }}/2</div>
          <div class="btn-view">
            <el-button
              type="text"
              plain
              size="mini"
              style="background: #fff5f0"
              class="el-pagechang-btn"
              @click="classPageChangeJian"
              :disabled="classBtndis2"
              ><i class="el-icon-caret-left"></i
            ></el-button>
            <el-button
              type="text"
              plain
              size="mini"
              style="background: #fff5f0"
              class="el-pagechang-btn"
              @click="classPageChangeJia"
              :disabled="classBtndis1"
            >
              <i class="el-icon-caret-right"></i>
            </el-button>
          </div>
        </div>
      </div>
      <div class="banner-view">
        <el-carousel height="500px" :interval="5000" arrow="always">
          <el-carousel-item v-for="item in bannerImgs" :key="item.id">
            <el-image
              class="banner-item-img"
              :src="item.adverts_img"
            ></el-image>
            <!-- <img class="banner-item-img" :src="item.adverts_img" alt="" /> -->
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="min-inf-img-view">
      <div
        class="index-img-inf-view"
        v-for="item in centerAdImg"
        :key="item.id"
      >
        <el-image
          :src="item.adverts_img"
          @click="toAdvertising(item.adverts_link)"
        ></el-image>
        <!-- <img
          :src="item.adverts_img"
          alt=""
          @click="toAdvertising(item.adverts_link)"
        /> -->
      </div>
    </div>

    <div class="index-com-css" v-if="isLogin && usertype == 1">
      <div class="top-title-view">
        <img src="../assets/index_jxgw.png" alt="" />
        <span class="title-text">精选岗位</span>
      </div>
      <div class="index-com-tab-view">
        <div class="tab-view">
          <label v-for="item in jxJobClassArr" :key="item.job_id">
            <div class="tab-items" @click="changeTjJobClass(item.job_id)">
              <span
                :class="jxJobClassId == item.job_id ? 'index-tab-sl' : ''"
                >{{ item.name }}</span
              >
              <div v-show="jxJobClassId == item.job_id" class="xian"></div>
            </div>
          </label>
        </div>
        <div class="right-ckgd-view" @click="toFindJobList">查看更多 >></div>
      </div>

      <div class="job-inf-card">
        <jobinfcard :jobarr="jxJobList" class="inucad"></jobinfcard>
      </div>
    </div>

    <div class="index-com-css" v-if="!isLogin">
      <div class="top-title-view">
        <img src="../assets/index_jxgw.png" alt="" />
        <span class="title-text">精选岗位</span>
      </div>
      <div class="index-com-tab-view">
        <div class="tab-view">
          <label v-for="item in jxJobClassArr" :key="item.job_id">
            <div class="tab-items" @click="changeTjJobClass(item.job_id)">
              <span
                :class="
                  jxJobClassId == item.job_id
                    ? 'index-tab-sl'
                    : 'tab-items-hover'
                "
                >{{ item.name }}</span
              >
              <div v-show="jxJobClassId == item.job_id" class="xian"></div>
            </div>
          </label>
        </div>
        <div class="right-ckgd-view" @click="toFindJobList">查看更多 >></div>
      </div>

      <div class="job-inf-card">
        <jobinfcard :jobarr="jxJobList" class="inucad"></jobinfcard>
      </div>
    </div>

    <div class="index-com-css" v-show="isLogin && usertype == 1">
      <div class="top-title-view">
        <img src="../assets/index_mqzp.png" alt="" />
        <span class="title-text">名企招聘</span>
      </div>
      <div class="mqzp-card">
        <comusercard :mqarr="mqComList"></comusercard>
      </div>
      <div class="ckgd-btn-view">
        <el-button class="ck-btn" type="text" plain @click="toCompanyList"
          >查看更多</el-button
        >
      </div>
    </div>
    <div class="index-com-css" v-show="!isLogin">
      <div class="top-title-view">
        <img src="../assets/index_mqzp.png" alt="" />
        <span class="title-text">名企招聘</span>
      </div>
      <div class="mqzp-card">
        <comusercard :mqarr="mqComList"></comusercard>
      </div>
      <div class="ckgd-btn-view">
        <el-button class="ck-btn" type="text" plain @click="toCompanyList"
          >查看更多</el-button
        >
      </div>
    </div>

    <div class="index-com-css" v-show="isLogin && usertype == 2">
      <div class="top-title-view">
        <img src="../assets/index_rctj.png" alt="" />
        <span class="title-text">人才推荐</span>
      </div>
      <div class="home-rczp-card">
        <label v-for="(item, index) in preciseTeList" :key="item.id">
          <userinfcard :listdata="item"></userinfcard>
          <div v-show="(index + 1) % 4 != 0" class="ccard-fgx"></div>
        </label>
      </div>
      <div class="ckgd-btn-view">
        <el-button class="ck-btn" type="text" plain @click="toTalentsList"
          >查看更多</el-button
        >
      </div>
    </div>

    <div class="index-yqlj-view">
      <div class="yqlj-top-view">
        <div class="top-left-title">
          <img src="../assets/index_yqlj.png" alt="" />
          <span>友情链接</span>
        </div>
        <div class="right-sq" @click="applyLinkFun">申请链接 >></div>
      </div>
      <div class="bot-lj-view">
        <label v-for="item in linkList" :key="item.id">
          <a :href="item.link_url" target="_blank">{{ item.link_name }}</a>
        </label>
      </div>
    </div>

    <flotnavmenn class="flot-nav-menn" @flotWtfkFun="flotWtfkFun"></flotnavmenn>

    <!-- 反馈 -->

    <el-dialog title="我要反馈" :visible.sync="fkDialogVisible" width="600px">
      <el-form :model="feebackArr" status-icon :rules="rules" ref="ruleForm">
        <div class="slot-feedfack">
          <p class="problem-description">问题描述:</p>
          <el-input
            type="textarea"
            placeholder="请输入内容"
            v-model="feebackArr.content"
            maxlength="200"
            show-word-limit
          >
          </el-input>
          <div class="problem-description">添加图片:</div>
          <el-upload
            class="avatar-uploader"
            :action="uploadUrl"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img v-if="imgUrl" :src="imgUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <div class="problem-description">
            请留下你的联系方式，以便及时回复
          </div>
          <div class="fk-link-inf">
            <div class="contact-way-fk">联系人：</div>
            <el-input
              v-model="feebackArr.link_name"
              placeholder="请输入内容"
            ></el-input>
          </div>
          <el-form-item label="" prop="link_num">
            <div class="fk-link-inf">
              <div class="contact-way-fk">手机号：</div>
              <el-input
                v-model="feebackArr.link_num"
                placeholder="请输入内容"
              ></el-input>
            </div>
          </el-form-item>
        </div>
        <div class="home-fk-dialog-bot-btn">
          <el-button @click="fkDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="submitFeedback">提交</el-button>
        </div>
      </el-form>
    </el-dialog>
    <ComDialog
      :isopen="applyLink.isOpenApply"
      dialogTetle="申请友情链接"
      @dialogFrameEvent="dialogFrameEvent"
      myWidth="600px"
      :is-show-butt="true"
    >
      <div class="home-comdialog-content">
        <div>
          <label>连接标题</label>
          <el-input
            v-model="applyLink.linkName"
            placeholder="请填写链接标题"
          ></el-input>
        </div>
        <div>
          <label>连接地址</label>
          <el-input
            v-model="applyLink.linkUrl"
            placeholder="请填写链接地址"
          ></el-input>
        </div>
        <div class="home-captcha">
          <div>
            <label>验证码</label>
            <el-input
              v-model="applyLink.code"
              placeholder="请填写右侧验证码"
            ></el-input>
          </div>
          <div style="margin-top: 16px; width: 205px">
            <img :src="applyLink.captcha" />
            <span
              style="padding-left: 8px; cursor: pointer"
              @click="refreshCode"
              >看不清？</span
            >
          </div>
        </div>
      </div>
    </ComDialog>
  </div>
</template>

<script>
import searview from "@/components/homeSearComp.vue";
import jobinfcard from "@/components/comUser/jobInfCard.vue";
import comusercard from "@/components/comUser/comUserCard.vue";
import userinfcard from "@/components/individualUser/inUserInfCard.vue";
import {
  getHomeBannerList,
  getHomeCompyList,
  getHomeTanlList,
  getHomeHotClass,
  friendlyLink,
  captchaCreate,
} from "@/api/commen/homeApi.js";
import { getJobClassList } from "@/api/commen/filtrateComp";
import { getPcJobList } from "@/api/commen/findJobList";
import { submitFeedback } from "@/api/commen/helpAndFeebback";
import flotnavmenn from "@/components/flotNavMenN.vue";
import ComDialog from "@/components/comDialog.vue";
import { Message } from "element-ui";
// import store from "@/store";
export default {
  components: {
    ComDialog,
    searview,
    jobinfcard,
    comusercard,
    userinfcard,
    flotnavmenn,
  },
  data() {
    var validatePass2 = (rule, value, callback) => {
      const reg = /^1[3456789]\d{9}$/;
      console.log(value);
      console.log(reg.test(value));
      if (!reg.test(value)) {
        // 手机号码不符合规则
        callback(new Error("联系方式格式不正确"));
      } else {
        // 手机号码符合规则
        callback();
      }
    };
    return {
      uploadUrl: process.env.VUE_APP_UPLOUD + "/api/upload/uploadCos",
      isLogin: false,
      id: "",
      searchKey: "",
      usertype: 1,
      preciseTeList: [],
      bannerImgs: [],
      centerAdImg: [],
      jobClassArr: [],
      classPageIndex: 1,
      classBtndis1: false,
      classBtndis2: false,
      pageScrollHe: 0,
      classHoverId: 0,
      jxJobClassArr: [],
      jxJobClassId: "",
      jxJobList: [],
      mqComList: [],
      fkDialogVisible: false,
      feebackArr: {
        content: "",
        link_num: "",
        link_name: "",
        file: "",
      },
      details: {},
      imgUrl: "",
      linkList: [],
      applyLink: {
        isOpenApply: false,
        captcha: "",
        linkUrl: "",
        linkName: "",
        code: "",
      },
      popoItemId: 0,
      popoClassId: 0,
      slot: true,
      rules: {
        link_num: [{ validator: validatePass2, trigger: "blur" }],
      },
    };
  },
  mounted() {
    if (!localStorage.usertype) {
      this.$store.dispatch("user/setUserType", 2);
      this.$cookies.set("usertype", 2);
      localStorage.setItem("usertype", 2);
      console.log("home,mounted,", localStorage.usertype);
      
    }
    this.isLogin = localStorage.token ? true : false;

    this.id = this.$route.query.id;
    this.usertype = localStorage.getItem("usertype");
    this.getHomeBannerList();
    this.getJobClassList();
    // this.getUserInf();
    window.addEventListener("scroll", this.homePageScroll);
    if (this.usertype == 1) {
      this.getHomeCompyList();
      this.getHomeHotClass();
    } else {
      this.getTalentsList();
      if (!this.isLogin) {
        this.getHomeCompyList();
        this.getHomeHotClass();
      }
    }
  },
  created() {
    this.getLinkList();
  },
  methods: {
    popoverItemMouseOver(pid, popoitemid) {
      this.popoClassId = pid;
      this.popoItemId = popoitemid;
    },
    popoverItemMoseOut() {
      this.popoItemId = 0;
    },

    popoverSlotMouseOver(id) {
      this.popoClassId = id;
    },

    popoverSlotMoseOut() {
      // this.popoClassId = 0;
      this.slot = true;
    },
    popoverShowFun(id) {
      this.popoClassId = id;
    },

    popoverHideFun() {
      // console.log("aaaaaaa");
      if (this.slot) {
        this.popoClassId = 0;
      }
    },

    dialogFrameEvent(isOk) {
      console.log(isOk);
      if (isOk === "ok") {
        friendlyLink({ event: "insert", ...this.applyLink }).then((res) => {
          this.applyLink.isOpenApply = false;
          this.$message.success(res.msg);
        });
      } else {
        this.applyLink.isOpenApply = false;
      }
    },
    //申请友情链接
    applyLinkFun() {
      this.applyLink.isOpenApply = true;
      captchaCreate().then((res) => {
        this.applyLink.captcha = res.data.img;
      });
    },
    refreshCode() {
      captchaCreate().then((res) => {
        this.applyLink.captcha = res.data.img;
      });
    },
    getLinkList() {
      friendlyLink({ event: "get" }).then((res) => {
        console.log("yo qing lian jie");
        console.log(res);
        let arrLink = res.data.list;
        if (Object.keys(arrLink).length > 0) {
          this.linkList = arrLink;
        }
      });
    },
    toJobList(params) {
      console.log(params);
      if (this.isLogin) {
        if (localStorage.usertype === "1") {
          this.$router.push(`/findjob?pid=${params.keyid}&cid=${params.id}&name=${params.name}`);
        } else {
          this.$router.push(
            `/findtalentslist?pid=${params.keyid}&cid=${params.id}&name=${params.name}`
          );
        }
      } else {
        this.$router.push(`/findjob?pid=${params.keyid}&cid=${params.id}&name=${params.name}`);
      }
    },
    toSearch(kw) {
      console.log(kw);
      if (this.usertype === "1") {
        this.$router.push(`/findjob?kw=${kw}`);
      } else {
        this.$router.push(`/findtalentslist?kw=${kw}`);
      }
    },
    toSearchByClass(it) {
      console.log('.......',it);
      if (this.usertype === "1") {
        this.$router.push(`/findjob?pid=${it.keyid}&cid=${it.id}&name=${it.name}`);
      } else {
        this.$router.push(`/findtalentslist?pid=${it.keyid}&cid=${it.id}&name=${it.name}`);
      }
    },

    getHomeHotClass() {
      let isHave = localStorage.getItem("homeHotClass");
      if (isHave) {
        this.jxJobClassArr = JSON.parse(isHave);
        this.jxJobClassId = this.jxJobClassArr[0].job_id;
        this.getHomeJobList(this.jxJobClassId);
      } else {
        getHomeHotClass()
          .then((result) => {
            // console.log("获取热门分类", result);
            this.jxJobClassArr = result.data;
            localStorage.setItem(
              "homeHotClass",
              JSON.stringify(this.jxJobClassArr)
            );
            this.jxJobClassId = result.data[0].job_id;
            this.getHomeJobList(result.data[0].job_id);
          })
          .catch(() => {});
      }
    },

    classMouseover(id) {
      this.classHoverId = id;
    },
    classMouseout() {
      this.classHoverId = 0;
    },

    toListPage(e) {
      console.log(e);
    },
    popoverShow(e) {
      console.log(e);
    },
    //监听页面滚动
    homePageScroll() {
      // 滚动条距文档顶部的距离
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // 滚动条滚动的距离
      // let scrollStep = scrollTop - this.oldScrollTop;
      this.pageScrollHe = scrollTop;
    },
    //获取分类
    getJobClassList() {
      getJobClassList()
        .then((result) => {
          // console.log("获取分类", result);
          this.jobClassArr = result.data.list;
        })
        .catch(() => {});
    },

    getHomeBannerList() {
      // if (this.usertype == 1) {
      //   type = "resume";
      // } else {
      //   type = "company";
      // }
      getHomeBannerList({
        from: "pc",
        position: "joblist",
      })
        .then((result) => {
          // console.log("获取banner", result);
          this.bannerImgs = result.data.banner;
          this.centerAdImg = result.data.centreAd;
        })
        .catch(() => {});
    },

    /* 获取推荐人才 */
    getTalentsList() {
      // "type": "userList"
      getHomeTanlList({ type: "userList" })
        .then((result) => {
          // console.log("推荐人才", result);
          this.preciseTeList = result.data.data;
        })
        .catch(() => {});
    },

    changeTjJobClass(id) {
      this.jxJobClassId = id;
      this.getHomeJobList(id);

      // this.getHomeJobList(tp);
    },

    /* 精选岗位 */

    getHomeJobList(id) {
      let params = {
        page: 1, // 页码
        column: "new", //  recommend推荐 new最新
        provinceid: "all", // 省
        cityid: "all", // 市
        three_cityid: "all", // 区
        is_qg: 0,
        jobclass_id: id, // 顶部期望职位
        job_id: "all", // 职业, 例: 宠物医生
        search_key: "", // 搜索内容
        edu: "all", // 教育经历
        exp: "all", // 工作经历
        type: "all", // 职位类型 全职,兼职
        min_value: "all", // 期望薪资最小值
        max_value: "all", // 期望薪资最大值
        welfares: "all", //福利筛选
      };
      getPcJobList(params).then((result) => {
        // console.log("精选职位", result.data.jobs);
        this.jxJobList = result.data.jobs;
      });
    },

    /* 名企招聘 */
    getHomeCompyList() {
      getHomeCompyList({ type: "hotjob" })
        .then((result) => {
          // console.log("名企招聘", result);
          this.mqComList = result.data.data;
        })
        .catch(() => {});
    },
    // getUserInf() {
    //   if (this.usertype == 1) {

    //   } else {

    //   }
    // },

    toTalentsList() {
      this.$router.push("/findtalentslist");
    },

    toFindJobList() {
      this.$router.push("/findjob");
    },

    classPageChangeJia() {
      if (this.classPageIndex < 2) {
        this.classBtndis2 = false;
        this.classPageIndex = this.classPageIndex + 1;
      } else {
        this.classBtndis1 = true;
      }
    },
    classPageChangeJian() {
      if (this.classPageIndex > 1) {
        this.classBtndis1 = false;

        this.classPageIndex = this.classPageIndex - 1;
      } else {
        this.classBtndis2 = true;
      }
    },

    /* 问题反馈 */
    flotWtfkFun() {
      console.log(this.$store.state.user.userinf);
      if (this.isLogin) {
        this.feebackArr.link_name = this.$store.state.user.userinf.name;
        this.feebackArr.link_num = this.$store.state.user.userinf.moblie;
      }
      this.fkDialogVisible = true;
    },

    //上传图片回调
    handleAvatarSuccess(res) {
      console.log(res.data);
      if (res.code === 200) {
        this.imgUrl = res.data.httpCosUrl;
        this.feebackArr.file = res.data.cosUrl;
      } else {
        this.$message.error(res.msg);
      }
    },
    // 上传图片之前的回调
    beforeAvatarUpload(file) {
      const isJPG = file.type;
      const isLt2M = file.size / 1024 / 1024 < 3;
      if (isJPG.indexOf("image") === -1) {
        this.$message.error("请上传图片");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 3MB!");
      }
      return isJPG && isLt2M;
    },

    validatePhoneNumber() {
      const reg = /^1[3456789]\d{9}$/;
      if (!reg.test(this.ruleForm.moblie)) {
        // 手机号码不符合规则
        return false;
      }
      // 手机号码符合规则
      return true;
    },

    //提交反馈
    submitFeedback() {
      if (!this.feebackArr.content)
        return this.$message.error("请填写反馈内容");
      if (!this.feebackArr.link_num)
        return this.$message.error("请填写联系电话");
      if (!this.feebackArr.link_name)
        return this.$message.error("请填写联系人");
      if (!this.validatePhoneNumber()) {
        Message({
          message: "请输入正确的手机号",
          type: "error",
          duration: 3 * 1000,
          showClose: true,
          offset: 100,
        });
        return;
      }
      submitFeedback(this.feebackArr).then((req) => {
        if (req.code === 200) {
          this.$message.success(req.msg);
        } else {
          this.$message.error(req.msg);
        }
        this.fkDialogVisible = false;
      });
      this.feebackArr.link_num = "";
      this.feebackArr.link_name = "";
      this.feebackArr.content = "";
    },
    toCompanyList() {
      this.$router.push("/companylist?navId=famous");
    },
    toAdvertising(url) {
      window.open(url);
    },
  },
};
</script>

<style>
.flot-nav-menn {
  position: fixed;
  right: 54px;
  z-index: 9999;
  bottom: 300px;
}
.home-page {
  width: 100%;
  height: 100%;
  overflow: scroll;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin: 0 220px; */
}

.home-page .el-dialog__wrapper .el-dialog {
  border-radius: 10px;
}
/* body {
} */
.top-zk-div {
  height: 70px;
  width: 10px;
}
.top-img {
  width: 1480px;
  height: 160px;
  /* height: 100%; */
}
html,
body {
  padding: 0 0 100px 0;
  margin: 0;
  background-color: #faf9f9;
  height: 100%;
}
/*.navmen {
  width: 100%;
} */

.sear-view {
  width: 1480px;
  display: flex;
  align-items: start;
  margin-top: 20px;
}

.input-with-select {
  border: 2px solid #fe6002;
  box-shadow: 2px 4px 10px 0px rgba(111, 111, 111, 0.15);
  border-radius: 27px;
}

.home-page .el-input-group__prepend {
  border-radius: 24px;
}

.el-input-group__prepend {
  /* border-top-left-radius: 24px;
  border-bottom-left-radius: 24px; */
}
.popover-class-view {
  width: 100%;
  display: inline-block;
  height: 428px;
  overflow: hidden;
}

.popover-show-view {
  padding: 8px 10px;
}

.popover-show-view .popover-show-title {
  font-size: 18px;

  font-weight: 600;
  color: #191919;
  line-height: 28px;
}
.popover-show-view .popover-show-items-view .popo-item {
  display: inline-block;
  font-size: 16px;

  font-weight: 400;
  color: #272727;
  line-height: 19px;
  margin-top: 24px;
  margin-right: 24px;
}

.popover-show-view .popover-show-items-view .popover-hover-cs {
  color: #fe6002;
}

.lef-search .rm-ss {
  font-size: 16px;

  font-weight: 400;
  color: #757575;
  margin-top: 36px;
  margin-left: 33px;
}
.lef-search .rm-ss .ss-item {
  margin-left: 20px;
}

.right-dl-app-view {
  margin-left: 110px;
  /* position: absolute;
  right: 20%; */
}
.right-dl-app-view img {
  width: 311px;
  height: 114px;
  display: block;
}
.class-baner-view {
  /* width: 1920px; */
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.class-baner-view .class-view {
  width: 479px;
  height: 448px;
  background: #ffffff;
  border-radius: 15px 15px 15px 15px;
  padding: 26px 8px;
  /* overflow: hidden; */
  position: relative;
  /* overflow: hidden; */

  /* margin-left: 220px; */
}
.class-baner-view .class-view .fenlei-item {
  /* width: 410px; */
  display: flex;
  align-items: center;
  position: relative;
  /* border: 1px solid #000; */
  padding: 15px 24px;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical; */
}
.class-baner-view .class-view .fenlei-item .fa-text {
  font-size: 18px;

  font-weight: 600;
  color: #191919;
  overflow: hidden;
}

.class-baner-view .class-view .fenlei-item .son-text {
  font-size: 16px;

  font-weight: 400;
  color: #757575;
  margin-left: 20px;
  line-height: 30px;
}

.class-baner-view .class-view .fenlei-item .right-ic-jt {
  color: #757575;
  position: absolute;
  right: 5px;
  font-size: 16px;
  line-height: 38px;
}

.class-baner-view .class-view .page-fy-view {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0 0 0;
  border-top: 2px solid#F5F6FA;
  margin: 0 24px;
}

.class-baner-view .class-view .page-fy-view .pageall-num-view {
  font-size: 18px;

  font-weight: 600;
  color: #fe6002;
}

.el-pagechang-btn {
  font-size: 12px;
  width: 24px;
  height: 24px;
  background: #fff5f0;
  border-radius: 4px 4px 4px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner-view {
  width: 958px;
  height: 500px;
  margin-left: 26px;
}
/* .el-carousel__container {
  height: 1000px;
} */
.banner-view .banner-item-img {
  width: 958px;
  height: 500px;
  border-radius: 16px;
}
.banner-view .el-carousel__button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.min-inf-img-view {
  width: 1480px;
  /* margin: 0 220px; */
  justify-content: space-between;
  margin-top: 56px;
}
.min-inf-img-view .index-img-inf-view {
  width: 331px;
  height: 140px;
  margin-right: 50px;
  margin-top: 24px;
  border-radius: 10px;
  display: inline-block;
}

.min-inf-img-view .index-img-inf-view img {
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.min-inf-img-view .index-img-inf-view:nth-child(4n) {
  margin-right: 0;
}

.index-com-css {
  width: 1480px;
  /* margin: 0 220px; */
  margin-top: 80px;
}

.index-com-css .top-title-view {
  display: flex;
  align-items: center;
  justify-content: center;
}

.index-com-css .top-title-view img {
  width: 40px;
  height: 40px;
}

.index-com-css .top-title-view .title-text {
  font-size: 36px;

  font-weight: 600;
  color: #191919;
  margin-left: 16px;
}
.index-com-css .index-com-tab-view {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 3px solid#EAECF5;
  padding: 13px 0;
  margin-top: 48px;
}
.index-com-css .index-com-tab-view .tab-view {
  display: flex;
  align-items: center;
}
.index-com-css .index-com-tab-view .tab-view .tab-items {
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  position: relative;
}
.index-com-css .index-com-tab-view .tab-view .tab-items span {
  font-size: 22px;

  font-weight: 400;
  color: #757575;
}
.index-com-css .index-com-tab-view .tab-view .tab-items:nth-child(1) {
  margin-left: 24px;
}
.index-com-css .index-com-tab-view .tab-view .tab-items .index-tab-sl {
  color: #fe6002;
}

.index-com-css .index-com-tab-view .tab-view .tab-items .xian {
  width: 40px;
  height: 4px;
  background-color: #fe6002;
  position: absolute;
  bottom: -17px;
  border-radius: 2px;
}

.index-com-css .index-com-tab-view .right-ckgd-view {
  font-size: 18px;

  font-weight: 600;
  color: #fe6002;
}
.job-inf-card {
  margin-top: 16px;
}
.job-inf-card .card-fgx {
  width: 29px;
  display: inline-block;
  /* margin-right: 29px; */
}

.ccard-fgx {
  width: 40px;
  display: inline-block;
}
.mqzp-card {
  margin-top: 16px;
}
/* .user-card div:nth-child(2) {
  border: 1px solid #000;
} */
.ckgd-btn-view {
  width: 100%;
  margin-top: 40px;
  text-align: center;
}
.ckgd-btn-view .ck-btn {
  width: 150px;
  height: 40px;
  border: 1px solid#fe6002;
  border-radius: 8px;
  font-size: 18px;

  font-weight: 400;
  color: #fe6002;
}
.index-yqlj-view {
  /* margin: 0 220px; */
  width: 1432px;
  height: 118px;
  background: #ffffff;
  border-radius: 16px;
  padding: 32px 24px;
  margin-top: 80px;
}
.index-yqlj-view .yqlj-top-view {
  display: flex;
  align-items: center;
  position: relative;
  border-bottom: 2px solid #eaecf5;
  padding-bottom: 18px;
}
.index-yqlj-view .yqlj-top-view .top-left-title {
  display: flex;
  align-items: center;
}
.index-yqlj-view .yqlj-top-view .top-left-title img {
  width: 30px;
  height: 30px;
}
.index-yqlj-view .yqlj-top-view .top-left-title span {
  font-size: 24px;

  font-weight: 600;
  color: #191919;
  margin-left: 12px;
}

.index-yqlj-view .yqlj-top-view .right-sq {
  font-size: 18px;

  font-weight: 500;
  color: #ff8136;
  position: absolute;
  right: 0;
  cursor: pointer;
}
.bot-lj-view {
  margin-top: 8px;
}
.bot-lj-view a {
  font-size: 18px;

  font-weight: 400;
  color: #b1b1b1;
  margin-right: 32px;
  line-height: 28px;
  text-decoration: none;
}

.home-class-folat-view {
  width: 470px;
  position: absolute;
  right: -520px;
  margin-top: -60px;
  background: #ffffff;
  box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.3);
  border-radius: 16px 16px 16px 16px;
  z-index: 99;
  padding: 20px 25px;
}

.home-class-folat-view .fa-text {
  font-weight: 600;
  font-size: 18px;
  color: #191919;
  line-height: 28px;
}
.popover-slot-hover-cs {
  background-color: #fff5f0;
}

.home-class-folat-view .son-text {
  display: inline-block;

  font-weight: 400;
  font-size: 16px;
  color: #646464;
  line-height: 19px;
  margin-right: 24px;
  margin-top: 24px;
}

.home-page .slot-feedfack {
  width: 612px;
  padding-top: 15px;
  margin: 0 auto;
}

.home-page .slot-feedfack-details-img {
  margin-bottom: 12px;
}
.home-page .slot-feedfack-details-reply {
  font-size: 16px;

  font-weight: 400;
  color: #191919;
  line-height: 22px;
  margin-bottom: 12px;
}

.home-page .slot-feedfack-details-content {
  font-size: 18px;

  font-weight: 400;
  color: #191919;
  line-height: 24px;
  margin-bottom: 12px;
}

.home-page .slot-feedfack-details-time {
  font-size: 14px;

  font-weight: 400;
  color: #666666;
  line-height: 16px;
}

.home-page .problem-description {
  font-size: 18px;

  font-weight: 600;
  color: #191919;
  line-height: 28px;
  height: 28px;
  margin-bottom: 10px;
}

.home-page .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  border: 1px dashed #d9d9d9;
}

.home-page .el-textarea__inner {
  height: 150px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  margin-bottom: 18px;
}
.home-page .slot-feedfack .fk-link-inf {
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.home-page .slot-feedfack .fk-link-inf .contact-way-fk {
  white-space: nowrap;
}

.home-page .slot-feedfack .fk-link-inf .el-input__inner {
  width: 300px;
}
.home-page .el-input .el-input__suffix {
  display: none;
}
.home-page .home-fk-dialog-bot-btn {
  text-align: right;
  padding: 30px 40px;
}

.home-page .home-fk-dialog-bot-btn .el-button {
  width: 180px;
}
.home-page .bot-lj-view a:hover {
  color: #fe6002;
}
.home-page .home-captcha {
  display: flex;
  justify-content: space-between;
}

.home-page .home-captcha img {
  width: 180px;
  height: auto;
}
.home-page .home-comdialog-content {
  width: 70%;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 10px;
}
.home-page .home-comdialog-content label {
  color: #999999;
  font-size: 16px;
  line-height: 25px;
}
.home-page .home-comdialog-content > div {
  margin-top: 20px;
}
.home-page .home-comdialog-content .home-captcha .el-input__inner {
  width: 210px;
}
.home-page .tab-items .tab-items-hover {
  cursor: pointer;
}
.home-page .tab-items .tab-items-hover:hover {
  color: #fe6002 !important;
}
</style>