<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2020-08-28 15:20:13
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-06-04 13:59:30
 * @FilePath: /lcwpcvue/src/views/Index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="index">
    <!-- <el-container>
      <el-header><navmen></navmen></el-header>
      <el-main
        ><div class="rightContainer">
          <router-view></router-view></div
      ></el-main>
      <el-footer> <pagefoot></pagefoot> </el-footer>
    </el-container> -->
    <navmen @showChoseType="showChoseType"></navmen>
    <div class="rightContainer">
      <router-view></router-view>
    </div>
    <pagefoot v-if="footShow"></pagefoot>
    <changeusertype
      @closeChoseTypePop="choseUserTypeClose"
      :changeUserTypeDialogVisible="choseUserTypeShow"
    ></changeusertype>
  </div>
</template>

<script>
// @ is an alias to /src
// import LoginView form '..'
import navmen from "@/components/headNavMen.vue";
import pagefoot from "@/components/pageFootComp.vue";
import changeusertype from "@/components/choseUserType.vue";
export default {
  name: "Index",
  components: {
    navmen,
    pagefoot,
    changeusertype,
  },
  data() {
    return {
      windowWidth: 0,
      choseUserTypeShow: false,
      footShow: false,
    };
  },
  created() {
    console.log("", window.innerWidth);
    this.windowWidth = window.innerWidth;
  },
  mounted() {
    this.footShow = true;
  },
  methods: {
    showChoseType() {
      this.choseUserTypeShow = true;
    },
    choseUserTypeClose() {
      this.choseUserTypeShow = false;
    },
  },
};
</script>
<style scoped>
body {
}
.index {
  width: 100%;
  /* width: 1920px; */
  height: 100%;
  /* background: linear-gradient(316deg, #ff8840 0%, #fffbf6 100%); */
}
</style>
