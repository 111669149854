<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-07-31 16:27:15
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-06-12 09:24:18
 * @FilePath: /lcwpcvue/src/components/individualUser/userCardInFindI.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <label v-for="(item, index) in userList" :key="item.id">
      <div
        class="user-card"
        @mouseover="hadlerMouseOver(index)"
        @mouseout="hadlerMoseOut"
        :class="isHover && cardIndex == index ? 'over-user-card' : ''"
        @click.stop.capture="toDetailPage(item.uid)"
      >
        <div class="headimg-gender-view">
          <img class="head-img" :src="item.resume_photo" alt="" />
          <img
            v-show="item.sex_name == '男'"
            class="gender-img"
            src="../../assets/gender_nan.png"
            alt=""
          />
          <img
            v-show="item.sex_name == '女'"
            class="gender-img"
            src="../../assets/gender_nv.png"
            alt=""
          />
        </div>

        <div class="min-name-age-exp-bq-view">
          <div class="name-sta-view">
            <span class="name">{{ item.name }}</span>
            <span class="tag-view" v-show="item.top == 1">置顶</span>
            <span class="tag-view2" v-show="item.newtab[2] != ''">兼职</span>
            <div v-show="item.newtab[1] == '在线'" class="lin-statu-view">
              在线
            </div>
          </div>
          <div class="age-edu-exp">
            <span>{{ item.age }}</span>
            <el-divider
              v-if="item.edu_name != ''"
              direction="vertical"
            ></el-divider>
            <span>{{ item.edu_name }}</span>
            <el-divider direction="vertical"></el-divider>
            <span>{{ item.exp_name }}</span>
          </div>
          <div class="dg-time-view">{{item.jobstatus_name}} {{item.report_name}}</div>
          <div class="user-bq-view">
            <label v-show="item.newtab[0] !== ''">
              <span
                class="bq-item"
                style="color: #fe6002; background-color: #fff5f0"
                >{{ item.newtab[0] }}</span
              >
            </label>
            <label v-for="(i, index) in item.tags" :key="index">
              <span class="bq-item">{{ i }}</span>
            </label>
          </div>
        </div>
        <div class="right-add-qwzw-view">
          <div class="add-city-view">
            <img class="lg-img" src="@/assets/user_card_city.png" alt="" />
            <span class="sp-title">期望城市：</span>
            <label v-for="(ii, index) in item.city_class" :key="index">
              <span class="add-city-name">{{ ii }}</span>
              <span
                v-show="item.city_class && index != item.city_class.length - 1"
                class="add-city-name"
                >/</span
              >
            </label>
          </div>
          <div class="add-city-view">
            <img class="lg-img" src="../../assets/user_card_qwjob.png" alt="" />
            <span class="sp-title">期望职位：</span>
            <label v-for="(it, index) in item.job_class" :key="index">
              <span class="add-city-name">{{ it }}</span>
              <span
                v-show="item.job_class && index != item.job_class.length - 1"
                class="add-city-name"
                >、</span
              >
            </label>
          </div>
          <div class="update-time-view">{{item.jobOnline}}</div>
          <div class="col-look-btn-view" v-show="isHover && cardIndex == index">
            <!-- <el-button
              @click.stop="collectionUser(item.uid)"
              type="primary"
              icon="el-icon-star-off"
              plain
              >收藏</el-button
            > -->
            <button
              @click.stop="collectionUser(item.uid)"
              class="collect-btn"
              :class="cardIndex == index && collBtnHover ? 'hover-style' : ''"
              @mouseover="collBtnMouseOver()"
              @mouseout="collBtnMoseOut"
            >
              <i v-show="item.is_coll == 0" class="el-icon-star-off"></i>
              <span v-show="item.is_coll == 0">收藏</span>
              <i v-show="item.is_coll == 1" class="el-icon-star-on"></i>
              <span v-show="item.is_coll == 1">已收藏</span>
            </button>
            <!-- <el-button type="primary">查看人才</el-button> -->
            <button
              class="collect-btn"
              :class="cardIndex == index && ckBtnHover ? 'hover-style' : ''"
              @mouseover="ckBtnMouseOver()"
              @mouseout="clBtnMoseOut"
            >
              <span>查看人才</span>
            </button>
          </div>
        </div>
      </div>
    </label>
  </div>
</template>

<script>
export default {
  name: "usercardinfindi",
  props: {
    userList: {
      type: Array,
    },
  },
  data() {
    return {
      isHover: false,
      cardIndex: 0,
      collBtnHover: false,
      ckBtnHover: true,
    };
  },
  methods: {
    hadlerMouseOver(index) {
      // console.log("悬停");
      this.cardIndex = index;
      this.isHover = true;
    },
    hadlerMoseOut() {
      this.isHover = false;
    },

    toDetailPage(id) {
      if (!localStorage.token) {
        this.$router.push("/login");
        return;
      }
      let newRouter = this.$router.resolve({
        path: `/talentsdetail?id=${id}`,
      });
      window.open(newRouter.href, "_blank");
    },
    collectionUser(uid) {
      this.$emit("collectionUser", uid);
    },
    collBtnMouseOver() {
      this.collBtnHover = true;
      this.ckBtnHover = false;
    },
    collBtnMoseOut() {
      this.collBtnHover = false;
      this.ckBtnHover = true;
    },
    ckBtnMouseOver() {
      this.ckBtnHover = true;
    },
    clBtnMoseOut() {},
  },
};
</script>

<style>
.user-card {
  width: 986px;
  background-color: #fff;
  display: flex;
  /* align-items: center; */
  border-radius: 16px;
  margin-top: 20px;
  padding: 30px 24px;
  /* justify-content: space-between; */
}
.over-user-card {
  background-color: #fff5f0;
  border: 1px solid #fe6002;
}

.headimg-gender-view {
  position: relative;
}

.headimg-gender-view .head-img {
  width: 88px;
  height: 88px;
  border-radius: 50%;
  /* border: 1px solid #000; */
}

.user-card .headimg-gender-view .gender-img {
  position: absolute;
  width: 24px;
  height: 24px;
  margin-left: -30px;
  top: 70px;
}

.min-name-age-exp-bq-view .name-sta-view .lin-statu-view {
  font-size: 14px;
  font-weight: 600;
  color: #fe6002;
  position: absolute;
  right: 20px;
}
.min-name-age-exp-bq-view {
  text-align: left;
  margin-left: 24px;
  width: 456px;
}
.min-name-age-exp-bq-view .name-sta-view {
  display: flex;
  align-items: center;
  position: relative;
}
.min-name-age-exp-bq-view .name-sta-view .name {
  font-size: 22px;
  font-weight: 600;
  color: #191919;
  line-height: 28px;
}

.min-name-age-exp-bq-view .name-sta-view .tag-view {
  background-color: #ffd9c3;
  font-size: 12px;

  font-weight: 400;
  color: #f34b37;
  line-height: 14px;
  padding: 0 4px;
  margin-left: 12px;
}
.min-name-age-exp-bq-view .name-sta-view .tag-view2 {
  background-color: #b3dfff;
  font-size: 12px;

  font-weight: 400;
  color: #0294fe;
  line-height: 14px;
  padding: 0 4px;
  margin-left: 12px;
}

.min-name-age-exp-bq-view .age-edu-exp {
  font-size: 14px;

  font-weight: 400;
  color: #757575;
  line-height: 16px;
  margin-top: 16px;
}

.min-name-age-exp-bq-view .dg-time-view {
  font-size: 16px;

  font-weight: 400;
  color: #191919;
  line-height: 19px;
  margin-top: 16px;
}
.min-name-age-exp-bq-view .user-bq-view {
  margin-top: 16px;
}
.min-name-age-exp-bq-view .user-bq-view .bq-item {
  display: inline-block;
  background: #f5f6fa;
  font-size: 14px;

  font-weight: 400;
  color: #757575;
  padding: 4px 8px;
  margin-right: 16px;
  margin-bottom: 10px;
}

.right-add-qwzw-view {
  margin-top: 5px;
  border-left: 2px dashed #eaecf5;
  padding-left: 24px;
  width: 410px;
}

.right-add-qwzw-view .add-city-view {
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  word-wrap: break-word;
  word-break: break-all;
  width: 410px;
  overflow: hidden;
}

.right-add-qwzw-view .add-city-view .lg-img {
  width: 16px;
  height: 16px;
}

.right-add-qwzw-view .add-city-view .sp-title {
  font-size: 14px;
  font-weight: 400;
  color: #b1b1b1;
  line-height: 16px;
  margin-left: 6px;
  white-space: nowrap;
}

.right-add-qwzw-view .add-city-view .add-city-name {
  font-size: 16px;
  font-weight: 400;
  color: #191919;
  line-height: 19px;
  white-space: nowrap;
}

.right-add-qwzw-view .update-time-view {
  width: 100%;
  text-align: right;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #757575;
  line-height: 16px;
}

.right-add-qwzw-view .col-look-btn-view {
  width: 388px;
  display: flex;
  align-items: center;
  justify-content: right;
  margin-top: 20px;
}
.right-add-qwzw-view .col-look-btn-view .collect-btn {
  width: 104px;
  height: 36px;
  border: 1px solid#FE6002;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 600;
  font-size: 14px;
  color: #fe6002;
  line-height: 16px;
  margin-right: 16px;
  background-color: #fff5f0;
}
.right-add-qwzw-view .col-look-btn-view .collect-btn span {
  margin-left: 5px;
}

.right-add-qwzw-view .col-look-btn-view .hover-style {
  background-color: #fe6002;
  color: #fff;
}
</style>