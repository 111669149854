/*
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2024-03-04 14:23:20
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-06-11 17:28:22
 * @FilePath: /lcwpcvue/src/api/compe/aboutResumeMa.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'

/**
* 获取navbar数字
* @url Annex/num
* @param {} params 
* @return 
*/
export function getResumeManageNub() {
  return request({
    url: 'Annex/num',
    method: 'get',
  })
}


/**
* 获取简历管理
* @url Annex/listnew
* @param {column,page} params 
* @return 
*/
export function getResumeManageList(params) {
  return request({
    url: 'Annex/listnew',
    method: 'get',
    params: params,
  })
}


/**
* 添加简历备注
* @url company/resuNote
* @param {uid,content} params 
* @return 
*/
export function addResumeNote(params) {
  return request({
    url: 'company/resuNote',
    method: 'post',
    data: params,
  })
}

/**
* 不合适
* @url Business/postUnMatch
* @param {uid} params 
* @return 
*/
export function doesnFit(params) {
  return request({
    url: 'Business/postUnMatch',
    method: 'post',
    data: params,
  })
}

/**
* 获取招聘职位搜索条件
* @url v2/job/getFullJob
* @param {} params 
* @return 
*/
export function getMyAllJobs() {
  return request({
    url: 'v2/job/getFullJob',
    method: 'get'
  })
}

/**
 * 删除记录（简历管理）
 */
export function delResuManagement(id,type) {
  return request({
    url: 'v2/company/delResumeManagement',
    method: 'POST',
    data:{
      id:id,
      type:type
    }
  })
}