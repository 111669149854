<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-12-25 18:42:37
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2023-12-25 18:42:55
 * @FilePath: /lcwpcvue/src/components/salary-range.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!-- 薪资级别处理 -->
<template>
  <div class="salary">
    <span v-if="maxsalary == 0 && minsalary == 0">面议</span>
    <span v-if="maxsalary == 0 && minsalary != 0"
      >{{ minsalary / 1000 }}k以上</span
    >
    <span v-if="maxsalary != 0 && minsalary == 0"
      >{{ maxsalary / 1000 }}k以下</span
    >
    <span v-if="maxsalary != 0 && minsalary != 0"
      >{{ minsalary / 1000 }}-{{ maxsalary / 1000 }}k</span
    >
  </div>
</template>

<script>
export default {
  name: "salary-range",
  props: {
    maxsalary: {
      type: [String, Number],
      default: 0,
    },
    minsalary: {
      type: [String, Number],
      default: 0,
    },
  },
};
</script>
<style>
.salary {
  font-weight: 900;
  color: #fe6002;
}
</style>
