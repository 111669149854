<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2024-05-22 09:16:24
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-07-24 14:33:05
 * @FilePath: /lcwpcvue/src/views/h5Page/h5HomePage.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="h5-home-page">
    <div class="nologin-ser">
      <!-- <div class="no-posi" @click="todetails" data-url="/pages/component/addressone">
				{{ cityval }}
				<u-icon class="arrow-down" name="arrow-down" size="12" color="#222222"></u-icon>
			</div> -->
      <div class="no-search">
        <el-input
          placeholder="搜索职位、公司"
          class="input-with-select"
          v-model="keyword"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            type="primary"
            @click="toFindJobListPage"
            >搜索</el-button
          >
        </el-input>
      </div>
    </div>
    <div class="h5-home-banner-view">
      <el-carousel height="100px">
        <el-carousel-item v-for="(item, index) in Banners" :key="index">
          <!-- <img class="" :src="item.banner_image" /> -->
          <el-image
            class="h5-home-banner-img"
            :src="item.banner_image"
          ></el-image>
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="home-comm-css">
      <div class="comm-title-view">
        <img
          class="title-img"
          src="@/assets/h5img/h5_home_hot_img.png"
          alt=""
        />
        <span>热门职位</span>
      </div>
      <div class="hot-job-list">
        <label v-for="item in hotJobList" :key="item.job_id">
          <div class="ho-item" @click="toFindJobPage(item.job_id, item.name)">
            {{ item.name }}
          </div>
        </label>
      </div>
    </div>

    <div class="home-comm-css">
      <div class="comm-title-view">
        <img class="title-img" src="@/assets/h5img/index_jxgw.png" mode="" />
        <span>精选岗位</span>
      </div>
      <usercard :jobsList="jobs_List"></usercard>
    </div>

    <div class="home-comm-css">
      <div class="comm-title-view">
        <img class="title-img" src="@/assets/h5img/index_mqzp.png" mode="" />
        <span>名企招聘</span>
      </div>
      <companylistinhome :companyList="commList"> </companylistinhome>
    </div>
    <openmini></openmini>
    <utabbar activeIndex="/h5homepage"></utabbar>

    <div class="h5-dowl-flot-view" v-if="downloadDialogShow">
      <div class="tc-view">
        <el-image :src="imgDomain + '/data/upload/webdef/bg2.png'"></el-image>
        <el-button @click="toDownloadPage" class="dol-btn" type="primary"
          >立即下载APP</el-button
        >
        <div class="colse-fl-img-view">
          <img
            src="@/assets/del_setpage.png"
            alt=""
            @click="downloadDialogShow = false"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utabbar from "@/components/h5Components/uTabbar.vue";
import openmini from "@/components/h5Components/h5TominPro.vue";

import usercard from "@/components/h5Components/user-card.vue";
import companylistinhome from "@/components/h5Components/company-listInHome.vue";
import {
  getH5BannerList,
  getHomeCompyList,
  // getHomeTanlList,
  getHomeHotClass,
  // friendlyLink,
  // captchaCreate,
} from "@/api/commen/homeApi.js";
import { getJobList } from "@/api/commen/findJobList";
export default {
  components: {
    utabbar,
    usercard,
    companylistinhome,
    openmini,
  },
  data() {
    return {
      imgDomain: process.env.VUE_APP_IMG,
      Banners: [],
      hotJobList: [],
      jobs_List: [],
      commList: [],
      keyword: "",
      downloadDialogShow: true,
    };
  },
  created() {
    this.downloadDialogShow = true;
    this.getHomeBannerList();
    this.getHomeHotJob();
    this.getHomeJobList();
    this.getHomeCompyList();
  },
  methods: {
    toFindJobListPage() {
      this.$router.push(`/h5findjobpage?keyword=${this.keyword}`);
    },
    toFindJobPage(jobclaid, name) {
      this.$router.push(`/h5findjobpage?jobclassid=${jobclaid}&name=${name}`);
    },
    /* 获取banner图 */
    getHomeBannerList() {
      // if (this.usertype == 1) {
      //   type = "resume";
      // } else {
      //   type = "company";
      // }
      getH5BannerList({
        type: "resume",
        position: "joblist",
        cid: "",
      })
        .then((result) => {
          console.log("获取banner", result);
          this.Banners = result.data.banners;
        })
        .catch(() => {});
    },

    /* 热门职位 */
    getHomeHotJob() {
      getHomeHotClass()
        .then((result) => {
          console.log("热门职位", result);
          this.hotJobList = result.data;
        })
        .catch(() => {});
    },
    /* 精选岗位 */
    getHomeJobList() {
      getJobList({
        page: 1, // 页码
        column: "new", //  recommend推荐 new最新
        provinceid: "all", // 省
        cityid: "all", // 市
        three_cityid: "all", // 区
        is_qg: 0,
        jobclass_id: "all", // 顶部期望职位
        job_id: "all", // 职业, 例: 宠物医生
        search_key: "", // 搜索内容
        edu: "all", // 教育经历
        exp: "all", // 工作经历
        type: "all", // 职位类型 全职,兼职
        min_value: "all", // 期望薪资最小值
        max_value: "all", // 期望薪资最大值
        welfares: "all", //福利筛选
      })
        .then((result) => {
          console.log("精选职位", result);
          // this.$nextTick(function () {
          this.jobs_List = result.data.jobs;
        })
        .catch(() => {});
      // getHomeJobList({ type: "siftjob", dataType: dtype })
      //   .then((result) => {
      //     console.log("精选岗位", result);
      //     this.jxJobList = result.data.list;
      //   })
      //   .catch(() => {});
    },
    /* 名企招聘 */
    getHomeCompyList() {
      getHomeCompyList({ type: "hotjob" })
        .then((result) => {
          console.log("名企招聘", result);
          result.data.data.forEach((it) => {
            it.logo = it.hot_pic;
            it.name = it.username;
            it.job = it.jobList;
            it.company_uid = it.uid;
          });
          this.commList = result.data.data;
        })
        .catch(() => {});
    },
    toDownloadPage() {
      var u = navigator.userAgent;
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      console.log(isAndroid, isiOS);
      if (isAndroid) {
        window.open(
          "https://a.app.qq.com/o/simple.jsp?pkgname=io.dcloud.W2Amobile.petzp.com"
        );
      } else if (isiOS) {
        window.location.href =
          "https://itunes.apple.com/cn/app//id1448449433?mt=8";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.h5-home-page {
  width: 100%;
  height: 100%;
  overflow: scroll;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  transition: width 0.3s ease;
  &::-webkit-scrollbar {
    display: none;
  }
}

::v-deep .el-input-group--append .el-input__inner {
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
}

::v-deep .el-input-group__append {
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
}

.nologin-ser {
  width: 90%;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  padding: 30px;
  z-index: 999;
}
.h5-home-banner-view {
  width: 450px;
  height: 130px;
  margin-top: 160px;
}
.h5-home-banner-view .h5-home-banner-img {
  width: 100%;
  height: 120px;
}

.home-comm-css {
  margin-top: 30px;
}

.home-comm-css .comm-title-view {
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-comm-css .comm-title-view .title-img {
  width: 29px;
  height: 29px;
}

.home-comm-css .comm-title-view span {
  font-size: 22px;
  margin-left: 5px;
  font-weight: bold;
}

.hot-job-list {
  background-color: #ffffff;
  margin: 15px 8px 0 8px;
  border-radius: 8px;
  padding: 10px;
}

.hot-job-list .ho-item {
  display: inline-block;
  padding: 10px 16px;
  border: 1px solid#ccc;
  margin-right: 15px;
  margin-bottom: 15px;
  border-radius: 5px;
  font-size: 16px;
}
.h5-dowl-flot-view {
  width: 100%;
  height: 99%;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .tc-view {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .dol-btn {
      width: 300px;
      height: 50px;
      position: relative;
      font-size: 20px;
      margin-top: -90px;
    }
    .colse-fl-img-view {
      margin-top: 60px;
      img {
        width: 30px;
        height: 30px;
      }
    }
  }
}
</style>