/*
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2024-01-05 09:50:21
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-01-10 11:15:42
 * @FilePath: /lcwpcvue/src/api/commen/takeShowList.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from "@/utils/request";

export function seekSearchNew(params) {
    return request({
        url: 'shop/seekSearchNew',
        method: 'get',
        params: params
    })
}
export function shopType() {
    return request({
        url: 'shop/type',
        method: 'get'
    })
}

/**
* 接店详情
* @url shop/seekData
* @param {id} params 
* @return 
*/

export function getTakeShopDetail(params) {
    return request({
        url: 'shop/seekData',
        method: 'get',
        params: params
    })
}
/**
* 获取接店联系号码
* @url shop/seekLinkTel
* @param {id} params 
* @return 
*/
export function getTakeShopLinkPhone(params) {
    return request({
        url: 'shop/seekLinkTel',
        method: 'get',
        params: params
    })
}