<template>
  <div class="job-informationize">
    <!--      navList-->
    <div class="jobmsg-navbar-view">
      <div
        class="nav-item"
        @click="changeNavBarId(im.idx)"
        v-for="im in navList"
        :key="im.idx"
      >
        <div class="nav-text" :class="navbarId === im.idx ? 'nav-sltext' : ''">
          {{ im.name }}
        </div>
        <div v-show="navbarId === im.idx" class="bot-xian"></div>
      </div>
    </div>

    <div
      class="resume-marage-flter-view"
      v-if=" navbarId == '1'"
    >
      <div class="flter-job-name">
        <div class="flter-name">
          <div>
            <el-input
              class="flter-name-input"
              placeholder="请输入姓名查找"
              v-model="userName"
            >
              <el-button
                @click="getChatingList"
                slot="append"
                icon="el-icon-search"
              ></el-button>
            </el-input>
          </div>
        </div>
      </div>
    </div>

    <!--      内容-->
    <div class="jobmsg-content">
      <!--      我的投递-->
      <deliver-job
        :jobList="seeMeCompanyList"
        v-if="[0, 4, 3].includes(navbarId)"
      ></deliver-job>
      <!--      谁浏览了我-->
      <CompanysItem
        @refresh="companysItemEvent"
        v-if="[2, 5, 1].includes(navbarId)"
        :wherefrom="navbarId"
        :companylist="seeMeCompanyList"
        @updateList="updateList"
      >
      </CompanysItem>
    </div>
    <NullPage title="暂无信息" v-show="seeMeCompanyList.length <= 0" type="1">
    </NullPage>
    <el-pagination
      v-show="seeMeCompanyList.length > 0"
      @current-change="pagesChage"
      background
      layout="prev, pager, next"
      :page-count="allPage"
      :current-page.sync="currentpage"
      class="pag-ina"
    >
    </el-pagination>
  </div>
</template>

<script>
import DeliverJob from "@/components/resume/jobInformationize/deliverJob.vue";
import CompanysItem from "@/components/resume/jobInformationize/companysItem.vue";
import {
  chatListV2,
  getInterviewList,
  memberCollectionList,
  seeMeNew,
  sendAnnex,
  userLookJob,
} from "@/api/commen/resume";
import NullPage from "@/components/nullPage.vue";
import { parseTime } from "@/utils/datePas";

export default {
  name: "resumeMessage",
  props: ["toPath"],
  components: {
    NullPage,
    CompanysItem,
    DeliverJob,
  },
  data() {
    return {
      page: 1,
      allPage: 0,
      navbarId: 0,
      currentpage: 0,
      seeMeCompanyList: [], //谁看过我
      navList: [
        { idx: 0, name: "我的投递" },
        { idx: 1, name: "沟通过" },
        { idx: 2, name: "待面试" },
        { idx: 3, name: "浏览记录" },
        { idx: 4, name: "我的收藏" },
        { idx: 5, name: "谁浏览了我" },
      ],
      userName:''
    };
  },
  created() {
    if (this.toPath) {
      this.navbarId = parseInt(this.toPath);
    }
    this.changeNavBarId(this.navbarId);
  },
  methods: {
    updateList(type){
      if (type === 5){
        this.showLookMe();
      }
    },
    pagesChage(e) {
      this.page = e;
      switch (this.navbarId) {
        case 5:
          this.showLookMe();
          break;
        case 2:
          this.getInterviews();
          break;
        case 0:
          this.getJobDeliver();
          break;
        case 1:
          this.getChatingList();
          break;
        case 3:
          this.getLookJobList();
          break;
        case 4:
          this.myCollect();
          break;
      }
      window.scrollTo(0, 0);
    },
    changeNavBarId(idx) {
      this.navbarId = idx;
      this.page = 1;
      this.currentpage = 1;
      this.allPage = 0;
      this.seeMeCompanyList = [];
      this.userName=""
      switch (this.navbarId) {
        case 5:
          this.showLookMe();
          break;
        case 2:
          this.getInterviews();
          break;
        case 0:
          this.getJobDeliver();
          break;
        case 1:
          this.getChatingList();
          break;
        case 3:
          this.getLookJobList();
          break;
        case 4:
          this.myCollect();
          break;
      }
    },
    companysItemEvent(type) {
      if (type === "2") {
        this.getInterviews();
      }
    },
    // 我的收藏
    myCollect() {
      memberCollectionList(this.page, "job").then((e) => {
        let datas = e.data;
        if (datas && datas.joblist.length > 0) {
          this.allPage = parseInt(datas.allpage);
          this.seeMeCompanyList = datas.joblist;
          this.seeMeCompanyList.forEach((e) => {
            e.id = e.job_id;
            e.name = e.job_name;
            e.com_name = e.company_name;
            e.lastupdate_name = parseTime(
              e.datetime,
              "{m}月{d}日" + " 您收藏了TA"
            );
            e.city = e.province + e.city;
          });
        }
      });
    },
    //浏览记录
    getLookJobList() {
      userLookJob(this.page).then((e) => {
        let datas = e.data;
        console.log(datas);
        if (datas.list && datas.list.length > 0) {
          this.allPage = parseInt(datas.allpage);
          this.seeMeCompanyList = datas.list;
          this.seeMeCompanyList.forEach((e) => {
            e.id = e.job_id;
            e.name = e.job_name;
            e.city = e.province + e.city;
            e.lastupdate_name = parseTime(e.time, "{m}月{d}日") + " 浏览过";
          });
        }
        console.log(e);
      });
    },
    //沟通过
    getChatingList() {
      chatListV2(this.page,this.userName).then((e) => {
        this.seeMeCompanyList = e.data.list;
        if (e.data.list && e.data.list.length > 0) {
          this.seeMeCompanyList.forEach((e) => {
            e.time = parseTime(e.time, "{y}年{m}月{d}日") + " 与您沟通过";
            e.to_uid = e.com_uid;
          });
          this.allPage = parseInt(e.data.allpage);
        }
      });
    },
    // 谁浏览了我
    showLookMe() {
      seeMeNew(this.page).then((e) => {
        console.log(e);
        if (e.data.list) {
          this.seeMeCompanyList = e.data.list;
          this.seeMeCompanyList.forEach((e) => {
            e.to_uid = e.company_uid;
          });
          this.allPage = parseInt(e.data.allpage);
        }
      });
    },
    // 待面试
    getInterviews() {
      getInterviewList(this.page, 3).then((e) => {
        console.log("comlist", e);
        this.allPage = parseInt(e.data.allpage);
        this.seeMeCompanyList = e.data.list.filter((item) => {
          // 1-待处理,2-没有面试,3-同意，4-拒绝,5-完成面试,6.取消面试, 7-面试中
          let statusArr = [
            "待处理",
            "没有面试",
            "待面试",
            "已拒绝",
            "已完成",
            "已取消",
            "面试中",
          ];
          if (["1", "2", "3", "4", "5", "6", "7"].includes(item.type)) {
            item.typeName = statusArr[item.type - 1];
          } else {
            item.typeName = "未知";
          }
          item.name = item.company_name;
          let se = item.intertime.split("-");
          item.startDate = parseTime(se[0], "{m}月{d}日");
          item.startTime = parseTime(se[0], "{h}:{i}");
          item.endTime = parseTime(se[1], "{h}:{i}");
          return item;
        });
      });
    },
    // 我的投递
    getJobDeliver() {
      sendAnnex(this.page).then((res) => {
        if (res.data.list) {
          this.allPage = parseInt(res.data.allPage);
          this.seeMeCompanyList = res.data.list;
          this.seeMeCompanyList.forEach(function (e) {
            if (e.lastupdate_name) {
              let timeArr = e.lastupdate_name.split("-");
              if (timeArr.length === 3) {
                e.lastupdate_name =
                  timeArr[1] + "月" + timeArr[2] + "日 投递过";
              }
            }
          });
        }
      });
    },
  },
};
</script>

<style scoped>
.job-informationize {
  /* margin-top: 63px; */
  margin-left: 24px;
  width: 1196px;
}

.job-informationize .jobmsg-content {
  margin-top: 15px;
}

.job-informationize .jobmsg-navbar-view {
  width: 1196px;
  height: 68px;
  background-color: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  align-items: center;
}

.job-informationize .jobmsg-navbar-view .nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 135px;
  margin-left: 24px;
  height: 68px;
  position: relative;
  cursor: pointer;
}

.job-informationize .jobmsg-navbar-view .nav-item .nav-text {
  font-size: 22px;
  font-weight: 400;
  color: #303030;
  line-height: 28px;
}

.job-informationize .jobmsg-navbar-view .nav-item .nav-sltext {
  color: #fe6002;
  font-weight: 600;
}

.job-informationize .jobmsg-navbar-view .nav-item .bot-xian {
  width: 50px;
  height: 4px;
  background-color: #fe6002;
  position: absolute;
  bottom: 0;
  border-radius: 4px;
}
</style>