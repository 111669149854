<template>
  <div class="h5-take-detail-page">
    <div class="h5-back-view" @click="backFun">
      <i class="el-icon-arrow-left"></i>
    </div>
    <div class="cont">
      <div class="title2">{{ inf.title }}</div>
      <div class="add-tiem">
        <img src="@/assets/companyinf_uptime_img.webp" mode="" />
        <div class="time">{{ inf.date }} 发布</div>
      </div>
      <div class="item">
        <div class="ttte1">意向区域</div>
        <div class="ttte2">{{ inf.all_city_name }}</div>
      </div>
      <div class="item">
        <div class="ttte1">意向类型</div>
        <div class="ttte2">{{ inf.type_name }}</div>
      </div>
      <div class="item">
        <div class="ttte1">意向面积</div>
        <div class="ttte2">{{ inf.area_key.key }}</div>
      </div>
      <div class="item">
        <div class="ttte1">意向转让费</div>
        <!-- <div class="t2" v-if="inf.transfer!=0">
					{{ inf.transfer }}
					<block v-if="inf.transfer != '不限' && inf.transfer != '面议'">万以下</block>
				</div> -->
        <div class="ttte2">{{ inf.fee_key.key }}</div>
      </div>
      <div class="item">
        <div class="ttte1">意向租金</div>
        <div class="ttte2">
          {{ inf.rent_key.key }}
        </div>
      </div>
      <div class="desp">
        <div class="title2">店铺要求</div>
        <div class="txt">{{ inf.desc }}</div>
      </div>
    </div>

    <div class="none-take" v-show="showStatus == 1">
      <div class="tc-view">
        <image
          :src="
            process.env.VUE_APP_IMG + '/data/sharelogo/app/zjd_nonetake.png'
          "
        ></image>
        <div class="ttt1">去发布转店信息</div>
        <div class="ttt2">发布完转店信息，升级VIP后就能查看接店信息啦~</div>
        <div class="bt-view">立即发布</div>
      </div>
    </div>
    <openmini style="position: fixed; left: -20px"></openmini>
  </div>
</template>

<script>
import openmini from "@/components/h5Components/h5TominPro.vue";
export default {
  components: {
    openmini,
  },
  data() {
    return {
      inf: {},
      showStatus: 0,
      showmodel: false,
      isyl: false,
      trid: "",
      othermoduleshow: false,
    };
  },
  created() {
    this.inf = JSON.parse(this.$route.query.inf);
  },
  methods: {
    backFun() {
      this.$router.go(-1);
    },
    geTraStroDate() {
      // api.get({
      //   url: "shop/seekData",
      //   data: {
      //     id: id,
      //   },
      //   success: (data) => {
      //     console.log("获取接店的编辑", data);
      //     if (data.data.info.area == "0-0") {
      //       data.data.info.area = "不限";
      //     } else {
      //       data.data.info.area = data.data.info.area + "m²";
      //     }
      //     if (data.data.info.transfer_fee == "0-0") {
      //       data.data.info.transfer = "不限";
      //     } else {
      //       data.data.info.transfer = data.data.info.transfer_fee;
      //     }
      //     if (data.data.info.rent == "0-0") {
      //       data.data.info.rent = "不限";
      //     }
      //     // data.data.info.
      //     this.inf = data.data.info;
      //   },
      // });
    },

    modelConfirm() {
      this.todetailes("../payVIP/payVIP?id=" + this.trid);
    },
    modelCancel() {},
    colose() {
      this.showStatus = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.h5-take-detail-page {
  width: 100%;
  height: 100%;
  overflow: scroll;
  position: relative;
  display: flex;
  flex-direction: column;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  transition: width 0.3s ease;
  &::-webkit-scrollbar {
    display: none;
  }
}

.cont {
  padding: 0 16px;

  .title2 {
    margin-top: 16px;
    font-size: 32px;
    color: #222222;
    font-weight: 500;
  }

  .add-tiem {
    display: flex;
    align-items: center;
    width: 100%;
    height: 32px;
    background-color: #f5f6fa;
    border-radius: 4px;
    margin-top: 8px;

    img {
      width: 16px;
      height: 16px;
      margin-left: 8px;
      margin-right: 6px;
    }
  }

  .item {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    margin: 12px 0;
    position: relative;

    .ttte1 {
      color: #939597;
    }

    .ttte2 {
      width: 100%;
      text-align: right;
      position: absolute;
    }
  }

  .desp {
    padding-top: 16px;

    .txt {
      font-size: 14px;
      color: #757575;
      font-weight: 400;
      margin-top: 10px;
      line-height: 23px;
    }
  }
}

.none-take {
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.6);
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .tc-view {
    background-color: #ffffff;
    border-radius: 12px;
    text-align: center;
    padding-bottom: 48px;

    img {
      width: 562px;
      height: 302px;
    }

    .ttt1 {
      font-size: 36px;
      color: #222222;
      font-weight: 600;
      margin-top: 40px;
    }

    .ttt2 {
      font-size: 28px;
      width: 456px;
      color: #222222;
      text-align: left;
      margin-left: 52px;
      margin-top: 10px;
    }

    .bt-view {
      width: 480px;
      height: 80px;
      background-color: #fe6002;
      border-radius: 4px;
      font-size: 28px;
      color: #ffffff;
      line-height: 80px;
      margin-left: 40px;
      margin-top: 48px;
    }
  }

  .colo {
    width: 66px;
    height: 66px;
  }
}
</style>