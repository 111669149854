<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-11-15 13:43:56
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-03-04 19:16:19
 * @FilePath: /lcwpcvue/src/components/comUser/zpbb.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="zpbb-view">
    <div class="title-view">招聘必备</div>
    <div class="btn-items">
      <div class="item" @click="toDetailPage('/releasejob')">
        <img src="../../assets/userinf_jlyh.png" alt="" />
        <div>发布职位</div>
      </div>
      <div class="item" @click="toDetailPage('/jobmanage')">
        <img src="../../assets/userinf_jlzd.png" alt="" />
        <div>职位管理</div>
      </div>
      <div class="item" @click="toDetailPage('/resumemanage')">
        <img src="../../assets/userinf_jldt.png" alt="" />
        <div>简历管理</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    toDetailPage(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style>
.zpbb-view {
  background-color: #fff;
  width: 292px;
  padding: 16px 27px;
  border-radius: 16px;
}
.zpbb-view .title-view {
  font-size: 20px;
  
  font-weight: 600;
  color: #222222;
  line-height: 28px;
}
.zpbb-view .btn-items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  
  font-weight: 400;
  color: #757575;
  line-height: 19px;
  margin-top: 16px;
}
.zpbb-view .btn-items .item {
  text-align: center;
}
.zpbb-view .btn-items .item img {
  width: 50px;
  height: 50px;
}
</style>