<template>
  <div>
    <input type="text" class="glb-phone-code-inp" v-model="code" placeholder="请输入验证码">
    <div :class=className>
      <span class="glb-phone-code-butt"  v-show="countdown === 0" @click="startCountdown">获取验证码</span>
      <span class="glb-phone-code-butt" v-if="countdown > 0">{{ countdown }} 秒后重新获取</span>
    </div>
  </div>
</template>

<script>
import {sendPhoneCode} from "@/api/commen/login";
import {userPcApi} from "@/api/commen/setApi";
import {Message} from "element-ui";
  export default {
    name:"createPhoneCode",
    props:['eventTpye','phoneOrEmail','isCheck'],
    data(){
      return{
        countdown: 0,
        timer: null,
        className: "glb-phone-code-butt-before",
        code:''
      }
    },
    methods:{
      startCountdown(){
        // 发送手机验证码
        if (this.eventTpye !== 'sendEmail'){
          if (!this.phoneOrEmail) return this.$message.error('请填写手机号码');
          if (this.isCheck){
            userPcApi({type:'checkPhone',usertype:localStorage.usertype,telType:'tel',phone:this.phoneOrEmail}).then(e=>{
              if (Object.keys(e.data).length === 0){
                sendPhoneCode({event:this.eventTpye,moblie:this.phoneOrEmail}).then((req)=>{
                  console.log(req)
                  if (req.code === 200){
                    Message({
                      message: '发送成功',
                      type: 'success',
                      duration: 1000,
                    })
                    this.myCountDown(60);
                  }
                });
              }else {
                return this.$message.error('该手机号已绑定其他账号，请更换其他号码！');
              }
            });
          }else{
            sendPhoneCode({event:this.eventTpye,moblie:this.phoneOrEmail}).then((req)=>{
              console.log(req)
              if (req.code === 200){
                Message({
                  message: '发送成功',
                  type: 'success',
                  duration: 1000,
                })
                this.myCountDown(60);
              }
            });
          }
          // 发送邮箱验证码
        }else if (this.eventTpye === 'sendEmail'){
          if (!this.phoneOrEmail) return this.$message.error('请填写邮箱地址');
          userPcApi({type:this.eventTpye,newEmail:this.phoneOrEmail}).then(()=>{
            Message({
              message: '发送成功',
              type: 'success',
              duration: 1000,
            })
            this.myCountDown(60);
          });
        }
      },
      myCountDown(num){
        this.countdown = num;
        this.className = "glb-phone-code-butt-after";
        this.timer = setInterval(() => {
          this.countdown--;
          if (this.countdown === 0) {
            clearInterval(this.timer);
            this.className = "glb-phone-code-butt-before";
            this.timer = null;
          }
        }, 1000);
      }


    }
  }
</script>

<style scoped>
  .glb-phone-code-inp{
    width: 382px;
    height: 50px;
    border-radius: 2px 2px 2px 2px;
    border: 1px solid #E7E8EB;
    padding-left: 16px;
  }
  .glb-phone-code-butt-before{
    width: 140px;
    text-align: center;
    padding-left: 10px;
    border-left: 1px solid #B1B1B1;
    position: relative;
    top: -36px;
    right: -250px;
    cursor: pointer;
  }
  .glb-phone-code-butt-after{
    width: 160px;
    text-align: center;
    padding-left: 10px;
    border-left: 1px solid #B1B1B1;
    position: relative;
    top: -36px;
    right: -220px;
  }
  .glb-phone-code-butt{
    font-size: 16px;
    font-weight: 400;
    color: #FE6002;
    line-height: 19px;
  }
  input::-webkit-input-placeholder{ /*WebKit browsers*/
    font-size: 16px;
    
    font-weight: 400;
    color: #B1B1B1;
  }

  input::-moz-input-placeholder{ /*Mozilla Firefox*/
    font-size: 16px;
    
    font-weight: 400;
    color: #B1B1B1;
  }

  input::-ms-input-placeholder{ /*Internet Explorer*/
    font-size: 16px;
    
    font-weight: 400;
    color: #B1B1B1;
  }
</style>