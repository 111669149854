<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2024-03-22 10:52:32
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-04-26 13:18:35
 * @FilePath: /lcwpcvue/src/components/comUser/comCollectionUser.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <div style="height: 30px"></div>
    <div class="common-title-view">
      <span class="left-xian-view"></span>
      <span class="text">收藏</span>
    </div>
    <div class="list-view">
      <div v-show="collectionList.length > 0">
        <collectionuser :listType="'collection'" :listArr="collectionList">
        </collectionuser>
      </div>
      <div v-show="collectionList.length == 0" class="nocoupon-list-view">
        <img class="none-list-img" src="@/assets/coupon_none_list.png" alt="" />
        <div class="none-text">当前暂无收藏</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getComColletUserList } from "@/api/commen/aboutUserIndexApi.js";
// import { formatnyr } from "@/utils/index.js";
import collectionuser from "@/components/comUser/comChatedUserItem.vue";
export default {
  components: {
    collectionuser,
  },
  data() {
    return {
      collectionList: [],
    };
  },
  created() {
    this.getComColletUserList();
  },
  methods: {
    getComColletUserList() {
      getComColletUserList({
        page: 1,
      })
        .then((result) => {
          console.log("收藏的简历", result);
          result.data.list.forEach((element) => {
            element.city_class = element.city_class_name;
            element.job_class = element.job_class_name;
          });
          this.collectionList = result.data.list;
        })
        .catch(() => {});
    },
  },
};
</script>

<style>
.list-view {
  width: 1100px;
}
</style>