<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2024-05-26 11:57:07
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-05-29 17:22:38
 * @FilePath: /lcwpcvue/src/components/h5Components/h5TominPro.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="h5-nav-min-page">
    <span>登录获取更多求职招聘信息！</span>
    <div class="right-brn" @click="toMiniProFun">登录/注册</div>
  </div>
</template>

<script>
import {cresteHttpUrl} from "@/api/h5/h5Redirect";

export default {
  methods: {
    toMiniProFun() {
      cresteHttpUrl().then(e=>{
        window.open(e.data.url);
      });
    },
  },
};
</script>

<style>
.h5-nav-min-page {
  z-index: 999999;
  position: fixed;
  bottom: 60px;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(000, 000, 000, 0.7);
  color: #fff;
  padding: 10px 10%;
  font-size: 15px;
}

.h5-nav-min-page .right-brn {
  width: 90px;
  height: 30px;
  background-color: #fe6002;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
</style>