<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2020-08-28 15:20:13
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-07-25 13:44:10
 * @FilePath: /lcwpcvue/src/App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="app" :style="{ zoom: esize }">
    <router-view></router-view>
  </div>
</template>
<script>
// import jwt_decode from "jwt-decode";
import axios from "axios";
import { bindSocketCid } from "@/api/commen/chatApi.js";
export default {
  name: "app",
  data() {
    return {
      esize: 1,
      windowWtdth: 0,
    };
  },
  async mounted() {
    if (this.isMobile()) {
      // alert("手机端");
      console.log("手机端", this.$route);
      if (this.$route.name == "jobinfopage") {
        this.$router.push(`/h5jobdetail?id=${this.$route.query.id}`);
      } else if (this.$route.name == "transferdetail") {
        this.$router.push(`/h5trandetail?id=${this.$route.query.id}`);
      } else if (this.$route.name == "companyinf") {
        this.$router.push(`/h5cominfo?id=${this.$route.query.id}`);
      } else if (this.$route.name == "findtalentslist") {
        this.$router.push(`/h5findtalentlist`);
      } else if (this.$route.name == "findjob") {
        this.$router.push(`/h5findjobpage`);
      } else if (this.$route.name == "transfershoplist") {
        this.$router.push(`/h5takeandtranstrolist`);
      } else if (this.$route.name == "h5information") {
        this.$router.push(`/h5information?id=${this.$route.query.id}`);
      } else {
        this.$router.push(`/h5homepage`);
      }
      // this.$route.path;
    } else {
      // alert("pc端");
    }
    this.initWebSocket();
    window.addEventListener("onmessageWS", this.getSocketData);
    window.addEventListener("resize", (e) => {
      // console.log(e.currentTarget.devicePixelRatio);
      this.esize = e.currentTarget.devicePixelRation;
    });
  },
  created() {
    // if (localStorage.eleToken) {
    //   // const decode = jwt_decode(localStorage.eleToken);
    //   // this.$store.dispatch("setIsAutnenticated", !this.isEmpty(decode));
    //   // this.$store.dispatch("setUser", decode);
    // }
    //  usertype = localStorage.usertype,

    /* 处理多账号登录 */
    var token = localStorage.token;

    let _this = this;
    if (!this.isMobile()) {
      // 当前页面可见度发生变化时触发（切换标签页或最小化窗口）
      document.addEventListener("visibilitychange", function () {
        // 用本地的cookie里的token和vuex的store里的token进行比较
        // console.log("aaa", _this.$store.getters.token);
        // console.log("bbb", localStorage.token);
        if (
          _this.$store.getters.token !== localStorage.token &&
          _this.$route.path != "/login"
        ) {
          _this
            .$confirm(
              "当前页面下有其他账号在不同设备、浏览器或选项卡发生了登录操作。请刷新以同步至最新登录账号，或切换其他账号登录。",
              "登录账号发生变更",
              {
                confirmButtonText: "刷新页面",
                cancelButtonText: "登录其他账号",
                type: "",
                showClose: false,
              }
            )
            .then(() => {
              location.reload();
            })
            .catch(() => {
              // 退出登录，并 跳转到登录页
              _this.$store
                .dispatch("user/resetToken")
                .then(() => {
                  _this.$router.push("/login");
                })
                .catch(() => {
                  _this.$router.push("/login");
                });
              // .then(() => {
              //   location.href = process.env.VUE_APP_CONTEXT_PATH + "index";
              // })
              // .catch(() => {
              //   _this.$router.push("/login");
              // });
            });
        }
      });
    }

    axios
      .get("/ip/cityjson?ie=utf-8")
      .then((res) => {
        // console.log("mmmm", res);
        var m = JSON.parse(res.data.match(/.*(\{[^\\}]+\}).*/)[1] || "{}");
        // console.log(m);
        _this.$store.dispatch("user/setUserIp", m.cip);
        // uni.setStorageSync("Ip", m.cip);
      })
      .catch((err) => {
        console.error(err);
      });

    if (token) {
      if (_this.$route.path != "/userprofile") {
        // this.$router.push("/home");
      }
      _this.$store.dispatch("user/setWdMsgNumber");
      // if (condition) {
      // }
    }
    _this.windowWidth = window.innerWidth;
    // axios({
    //   method: "get",
    //   url: "",
    // });
  },
  updated() {
    window.scroll(0, 0);
  },
  methods: {
    isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },

    async initWebSocket() {
      this.$websocket.initWebSocket();
    },
    getSocketData(res) {
      var msgData = res.detail.data;
      if (msgData.type === "initialization") {
        localStorage.setItem("wssclientid", msgData.client_id);
      }
      if (localStorage.token) {
        if (msgData.type == "initialization") {
          bindSocketCid({
            cid: msgData.client_id,
            pageurl: "login",
          })
            .then(() => {})
            .catch(() => {});
        } else {
          if (
            msgData.sourceType == "text" ||
            msgData.sourceType == "badgeNum"
          ) {
            this.$store.dispatch("user/setWdMsgNumber");
            // this.getTabnums();
          }
        }
      }

      // this.PieValue = Number(res.detail.data.sendInfoStr.onlineUserCount);
      // this.userNumValue = Number(res.detail.data.sendInfoStr.totalUserCount);
    },

    /* 获取角标 */
    getTabnums() {},

    isEmpty(value) {
      return (
        value === undefined ||
        value === null ||
        (typeof value === "object" && Object.keys(value).length === 0) ||
        (typeof value === "string" && value.trim().length === 0)
      );
    },
  },
};
</script>
<style>
html,
body,
#app {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Microsoft YaHei", "微软雅黑", "Arial", "sans-serif";
  background-color: #f5f5f7;
}

.common-title-view {
  display: flex;
  align-items: center;
}

.common-title-view .left-xian-view {
  display: inline-block;
  width: 4px;
  height: 20px;
  background-color: #fe6002;
  border-radius: 5px;
}
.common-title-view .text {
  font-size: 24px;

  font-weight: 600;
  color: #222222;
  line-height: 28px;
  margin-left: 16px;
}
.vip-type-view {
  height: 100px;
  border-top: 2px solid #eaecf5;
  padding-left: 33px;
  padding-top: 40px;
  position: relative;
}
.vip-type-view .vip-type-bg {
  width: 450px;
  height: 100px;
}

.vip-type-view .vip-type-text-view {
  width: 450px;
  height: 100px;
  position: absolute;
  top: 40px;
  display: flex;
  align-items: center;
}

.vip-type-view .vip-type-text-view .lczx-img {
  width: 60px;
  height: 24px;
  position: absolute;
  top: -10px;
}

.vip-type-view .vip-type-text-view .left-text-view {
  margin-left: 16px;
}
.vip-type-view .vip-type-text-view .left-text-view .name-tt1 {
  font-size: 22px;

  font-weight: 600;
  color: #562c00;
  line-height: 28px;
}

.vip-type-view .vip-type-text-view .left-text-view .time {
  font-size: 14px;

  font-weight: 400;
  color: #562c00;
  line-height: 16px;
  margin-top: 5px;
}
.vip-type-view .vip-type-text-view .right-text-view {
  font-size: 32px;

  font-weight: 600;
  color: #f34b37;
  line-height: 38px;
  position: absolute;
  bottom: 10px;
  right: 20px;
}

.vip-type-view .vip-type-text-view .right-text-view label {
  font-size: 16px;

  font-weight: 400;
  color: #f34b37;
  line-height: 19px;
}

.pay-type-view {
  margin-top: 30px;
  padding: 0 33px;
}
.pay-type-view .paytype-title {
  font-size: 16px;

  font-weight: 600;
  color: #191919;
  line-height: 19px;
}

.pay-type-view .paytype {
  display: flex;
  align-items: center;
  margin-top: 16px;
}

.pay-type-view .paytype .paytype-item {
  width: 166px;
  height: 48px;
  display: flex;
  align-items: center;
  border: 1px solid#CCCCCC;
  border-radius: 4px;
  margin-right: 48px;
  cursor: pointer;
}

.pay-type-view .paytype .paytype-item img {
  width: 40px;
  height: 40px;
  margin-left: 24px;
}

.pay-type-view .paytype .paytype-item span {
  font-size: 14px;

  font-weight: 400;
  color: #191919;
  line-height: 16px;
}

.pay-type-view .paytype .item-sl {
  border: 1px solid#FE6002;
  background-color: #fffaf7;
}

.wx-pay-img-view {
  width: 100%;
  text-align: center;
  margin-top: 40px;
}

.wx-pay-img-view .wxpay-tt1 {
  font-size: 14px;

  font-weight: 600;
  color: #757575;
  line-height: 16px;
}
.wx-pay-img-view .wxpay-tt1 label {
  color: #fe6002;
}
.wx-pay-img-view .wxpay-tt2 {
  font-size: 12px;

  font-weight: 400;
  color: #757575;
  line-height: 14px;
  margin-top: 4px;
}
.wx-pay-img-view .wxpay-tt2 label {
  color: #0294fe;
  cursor: pointer;
}

.wx-pay-img-view .wxpay-ewm-img {
  width: 150px;
  height: 150px;
}
.dialog-footer {
  width: 100%;
  text-align: center;
  margin-top: 30px;
}
/* .dialog-footer button.el-button.el-button--primary {
  width: 400px;
  height: 50px;
} */

.el-image-viewer__canvas img {
  width: auto;
  height: auto;
}
.el-message {
  top: 1000px;
}

/* .el-picker-panel {
  width: auto;
}
.el-date-picker {
  width: auto;
} */
/* .el-message.el-message--error {
  top: 300px;
} */
::-webkit-scrollbar {
  width: 7px;
  height: 5px;
}
::-webkit-scrollbar-thumb {
  background-color: #727272;
  border-radius: 4px;
}
::-webkit-scrollbar-track {
  background-color: #efefef;
}
.h5-back-view {
  padding: 20px 0 10px 10px;
  font-size: 30px;
  width: 100%;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: center;
}
.h5-back-view span {
  font-size: 20px;
  flex: 2;
  width: 100%;
}
.overflow-hidden {
  overflow: hidden; /* 确保溢出的内容会被隐藏 */
  white-space: nowrap; /* 确保文本在一行内显示 */
  text-overflow: ellipsis; /* 使用ellipsis显示文本溢出的部分 */
}
</style>
