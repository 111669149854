<template>
  <div :class="$options.name">
    <el-upload
      v-show="!resultImg"
      class="upload-el"
      ref="fileUpload"
      :action="action"
      :on-change="selectChange"
      :show-file-list="false"
      :auto-upload="false"
      :http-request="httpRequest"
    >
      <div>
        <!--       :file-list="uploadFile"上传的+号icon -->

        <!-- <span class="icon upload-icon" /> -->
        <!-- <img :src="showimg" alt="" /> -->
        <img :src="lookImgPath" class="com-avatar-img" alt="" />
      </div>

      <div slot="tip" class="el-upload__tip">
        {{ uploadTip }}
      </div>
    </el-upload>

    <!-- <figure v-show="resultImg" class="result-img">
      <el-button
        style="
          border: 1px solid #dcdfe6;
          width: 100px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
        "
        @click="updateCropper"
        >重新上传</el-button
      >

      <img
        :src="lookImgPath + resultImg"
        style="border: 1px solid #999; width: 150px; height: 80px"
      />
    </figure> -->

    <cropper
      v-if="showCropper"
      :cropperDialogShow="showCropper"
      :cropperImg="cropperImg"
      @update-cropper="updateCropper"
      @closeCropper="closeCropper"
      @upload-img="uploadImg"
      :fixedNumber="fixedNumber"
    />
  </div>
</template>
 
 
<script>
import Cropper from "./cutHeardImgCom.vue";
// import { loading } from "@/components/ImgPrune/common";
import { uploadBase64Header } from "@/api/commen/login.js";
export default {
  name: "UploadImg",

  components: {
    Cropper,
  },

  props: {
    // 图片剪裁比例
    fixedNumber: {
      type: Array,

      default: () => [1, 1],
    },

    // 图片服务器上传地址

    action: {
      type: String,
      default: process.env.VUE_APP_UPLOUD + "/api/upload/uploadCos",
    },

    // 查看图片路径

    lookImgPath: {
      type: String,

      default: "",
    },

    // 图片服务器上传接口名称

    fileName: {
      type: String,

      default: "formFile",
    },

    // 上传按钮文字

    uploadBtnName: {
      type: String,

      default: "上传",
    },

    // 上传文件类型限制（如：.jpg,.webp）

    accept: {
      type: String,

      default: "",
    },

    // 上传按钮下面提示的信息

    uploadTip: {
      type: String,

      default: "",
    },

    // 上传图片 大小限制

    imgUploadSize: {
      type: Number,

      default: 5,
    },
  },

  data() {
    return {
      uploadData: {
        // 上传需要的额外参数
        // siteId: 1,
        // source: 1,
        // fileName: ''
      },

      // action: 'https://jsonplaceholder.typicode.com/posts/', // 上传地址，必填 原版

      cropperImg: "", // 需要裁剪的图片

      showCropper: false, // 是否显示裁剪框

      uploadFile: "", // 裁剪后的文件

      resultImg: "", // 上传成功，后台返回的路径
      isUpImg: false,
      showimg: "",
    };
  },
  methods: {
    httpRequest() {
      uploadBase64Header({
        file: this.showimg,
      })
        .then((result) => {
          console.log("上传头像", result);
          this.$emit("success-img-path", result);
          this.showCropper = false;
          this.isUpImg = true;
        })
        .catch(() => {});
    },

    // 选择文件

    selectChange(file) {
      if (!this.isUpImg) {
        this.showCropper = true;
      }
      const { raw, name } = file;
      // console.log("raw", file, raw);
      this.openCropper(raw);
      this.uploadData.fileName = name; // 文件名字（需要传递-否则服务器异常）
    },

    /**
     * @param {file} 上传的文件
     */

    openCropper(file) {
      // console.log("图片大小控制", file);
      var files = file;
      let isLt5M = files.size / 1024 / 1024 > this.imgUploadSize;
      console.log("当前图片兆为：", files.size / 1024 / 1024); // size为字节：先/1024转为KB，再/1024转为兆
      // let isLt5M = files.size > (5 << 20); // 原版
      if (isLt5M) {
        this.$message.error(`请上传${this.imgUploadSize}M内的图片`);

        return false;
      }
      var reader = new FileReader();
      // console.log("reader", reader);
      reader.onload = (e) => {
        // console.log("e", e);
        let data;
        if (typeof e.target.result === "object") {
          // 把Array Buffer转化为blob 如果是base64不需要
          data = window.URL.createObjectURL(new Blob([e.target.result]));
        } else {
          data = e.target.result;
        }
        // console.log("data", data);
        this.cropperImg = data;
      };

      // 转化为base64
      reader.readAsDataURL(files);
      // 转化为blob
      // reader.readAsArrayBuffer(files);
    },

    // 上传图片（file为剪裁后的图片）

    uploadImg(base64) {
      this.showimg = base64;

      // var blob = this.base64ImgtoFile(base64, "image/jpeg");
      // var formData = new FormData();
      // let fileOfBlob = new File([blob], new Date() + ".jpg"); // 重命名了
      // formData.append("file", fileOfBlob);
      // this.file = formData;
      this.$refs.fileUpload.submit();
      // this.showCropper = false;
    },
    // 更新图片
    updateCropper() {
      this.$refs.fileUpload.$children[0].$el.click();
    },
    // 关闭窗口
    closeCropper() {
      this.showCropper = false;
    },
  },
};
</script>
 
 
<style lang="scss" scoped>
.UploadImg {
  .el-upload {
    display: block;

    width: 100px;

    margin: 30px auto 0;
  }

  .upload-icon {
    display: block;

    margin: 0 auto;

    height: 44px;

    width: 52px;

    background-image: url(~@/assets/auth_com_zlxk.png);

    background-position: 100% 100%;

    margin-bottom: 20px;
  }

  .video-image {
    display: flex;

    figure {
      width: 100px;

      img {
        width: 100%;

        display: block;
      }
    }
  }
}

.result-img {
  display: flex;

  > img {
    margin-left: 15px;
  }
}
</style>
