<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-12-14 11:14:05
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-05-28 17:09:37
 * @FilePath: /lcwpcvue/src/components/comUser/addAddressDialog.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="add-addre-dialog">
    <el-dialog
      title=""
      :visible.sync="addAddrDialogShow"
      :show-close="true"
      width="660px"
      @open="dialogOpne"
      @close="dialogClose"
      :destroy-on-close="true"
    >
      <div class="tip-text-view"></div>
      <div style="padding-bottom: 20px">
        <zczmap
          :lat="addressByXY.lat"
          :lng="addressByXY.lng"
          :idname="'mapchatbig'"
          :mapwidth="'620'"
          :mapheight="'500'"
          style="margin-left: 24px; margin-top: 10px"
        ></zczmap>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "addaddressdialog",
  props: {
    addAddrDialogShow: {
      type: Boolean,
      default: false,
    },
    addressByXY: {
      type: Object,
    },
  },
  data() {
    return {
      // querySearchAsync: "",
      map: null,
      T: null,
      searchKeyWord: "",
      querySearchArr: [],
      saveBtnDisabled: true,
      mapShow: true,
      detailedAddress: "", //详细地址
    };
  },
  mounted() {},
  methods: {
    dialogOpne() {
      console.log("open");
      console.log(this.addressByXY);
    },
    dialogClose() {
      console.log("close");
      // this.addressByXY = {};
      this.$emit("closeMapPop");
      // this.addAddrDialogShow = false;
    },
  },
};
</script>

<style>
.edi-lin-item .items .warning-banner {
  font-size: 16px;
  
  font-weight: 400;
  margin-left: 10px;
  color: red;
}
.add-addre-dialog .el-dialog__wrapper .el-dialog {
  border-radius: 10px;
}
.add-addre-dialog .tip-text-view {
  font-size: 18px;
  
  font-weight: 400;
  color: #757575;
  line-height: 24px;
  padding-top: 11px;
  border-bottom: 2px solid#EAECF5;
}

.add-addre-dialog .edi-lin-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.add-addre-dialog .edi-lin-item .items {
  width: 487px;
  position: relative;
  margin-top: 20px;
}

.add-addre-dialog .edi-lin-item .items .title-address {
  font-size: 16px;
  
  font-weight: 400;
  color: #757575;
  line-height: 30px;
}

.my-autocomplete .addr-name {
  font-size: 16px;
  
  font-weight: 600;
  color: #191919;
  line-height: 19px;
}
.my-autocomplete .addr-addr {
  font-size: 12px;
  
  font-weight: 400;
  color: #939597;
  line-height: 14px;
}
.tmap {
  margin: 0px;
  padding: 0px;
  width: 96%;
  height: 480px;
  z-index: 0;
  margin: 15px 2%;
}
.add-addre-dialog .dialog-footer {
  font-size: 18px;
  
  font-weight: 600;
  margin-top: 0 !important;
  text-align: right;
}
.el-dialog__footer .dialog-footer .el-button {
  width: 380px;
  height: 52px;
  
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
  border: none;
}
</style>