/*
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2020-08-28 15:20:13
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-06-12 11:01:08
 * @FilePath: /lcwpcvue/src/main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// import "babel-polyfill"
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import axios from './http';
import '../theme/index.css';
import '@/icons' // icon
import VueAMap from 'vue-amap';
// import 'amfe-flexible';
import websocket from '@/utils/webSocket';
import VueCookies from 'vue-cookies'
import zczmap from "@/components/zczShowMap.vue";
// import zczheardimg from "@/components/cutHeardImgCom.vue";
import zczheardimg from "@/components/zczHeadUp.vue";
// import vConsole from 'vconsole'; //打印调试日志 正式包去掉
import VueClipboard from 'vue-clipboard2';
import '@/public.css';
import VueCropper from 'vue-cropper'
Vue.use(VueCropper)

// 64be44b42ec05d81adec333161ccd860
Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.prototype.$websocket = websocket;
// Vue.prototype.$vConsole = new vConsole() //打印调试日志 正式包去掉

Vue.use(ElementUI)
Vue.use(VueAMap);
Vue.use(VueCookies)
Vue.use(VueClipboard)
Vue.component('zczmap', zczmap)
Vue.component('zczheardimg', zczheardimg)

// Vue.prototype.width = window.innerWidth;
// Vue.prototype.height = window.innerHeight;


router.beforeEach((to, from, next) => {
  if (to.path == '/login') {
    //保存当前路由
    localStorage.setItem("preRoute", router.currentRoute.fullPath)
  }
  store.dispatch("user/setNavmenIndex", to.path);
  next()
})
console.log('环境', process.env.NODE_ENV);
if (process.env.NODE_ENV === "production") {
  console.log = () => { }
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')