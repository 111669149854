<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-06-08 17:02:11
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-04-19 17:55:54
 * @FilePath: /lcwpcvue/src/views/listIndex.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="aboutus-page">
    <div class="top-zk-div"></div>
    <img class="top-img" src="../assets/index_tb.png" />
    <div class="ab-content">
      <div class="ab-content-left">

        <div v-for="me in menu" :key="me.idx"
             class="ab-content-item"
             :class="menuChliIdx === me.idx ? 'ab-content-choose-item' : ''"
             @click="chooseItem(me)"
        >
          <div :class="[1,5].includes(me.idx) ? '':'ab-content-item-hover'">
            <img v-show="me.uc" :src="menuParentIdx === me.idx ? me.uc : me.un" alt="">
            <span v-show="!me.uc">&nbsp;&nbsp;&nbsp;</span>
            <span class="ab-content-name" :style="me.idx === menuParentIdx ? 'color:#FE6002' : ''">{{me.name}}</span>
          </div>
        </div>

      </div>
      <div class="ab-content-right">
        <div class="ab-html-content" v-html="htmlContent"></div>
      </div>
    </div>
  </div>
</template>

<script>

import {userPcApi} from "@/api/commen/setApi";

export default {
  data() {
    return {
      htmlContent:'',
      menuParentIdx: 1,
      menuChliIdx: 2,
      menu: [
        {idx: 1,parent: 8,uc:require('@/assets/setPageIndex/about-mi-choose.png'),un:require('@/assets/setPageIndex/about-mi-unchoose.png'),name: '关于猎宠'},
        {idx: 2,parent: 1,uc:'',un:'',name: '公司简介'},
        {idx: 3,parent: 1,uc:'',un:'',name: '网站资质'},
        {idx: 4,parent: 1,uc:'',un:'',name: '网站合作'},
        {idx: 5,parent: 9,uc:require('@/assets/setPageIndex/use-and-help-choose.png'),un:require('@/assets/setPageIndex/use-and-help-unchoose.png'),name: '使用与帮助'},
        {idx: 6,parent: 5,uc:'',un:'',name: '用户协议'},
        {idx: 7,parent: 5,uc:'',un:'',name: '隐私政策'}
      ]
    }
  },
  created() {
    this.menuParentIdx = parseInt(this.$route.query.parent);
    this.menuChliIdx = parseInt(this.$route.query.chli);
    this.getMenuContent(this.menuChliIdx);
  },
  methods:{
    chooseItem(item){
      if ([1,5].includes(item.idx)) return;
      this.menuParentIdx = item.parent;
      this.menuChliIdx = item.idx;
      this.getMenuContent(this.menuChliIdx);
    },
    getMenuContent(idx){
      userPcApi({type:'footContent',name:idx}).then(res=>{
        console.log(res)
        if (res.data.content){
          this.htmlContent = res.data.content;
        }else {
          this.$message.error('内容缺失，请联系网站客服人员！');
        }
      });
    },
  },
};
</script>

<style>
.aboutus-page{
  width: 1480px;
  margin: 0 auto;
}
.aboutus-page .ab-content-right .ab-html-content strong{
  font-weight: bold;
}
.aboutus-page .ab-content-right .ab-html-content p{
  margin: 16px 0;
}
.aboutus-page .ab-content{
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}
.aboutus-page .ab-content-left{
  max-height: 800px;
  background: #FFFFFF;
  border-radius: 8px 8px 8px 8px;
  padding-left: 30px;
  padding-top: 20px;
}
.aboutus-page .ab-content-right{
  width: 1180px;
  min-height: 600px;
  background: #FFFFFF;
  border-radius: 8px 8px 8px 8px;
}
.aboutus-page .ab-html-content img{
  width: 100%;
  height: auto;
}
.aboutus-page .ab-html-content{
  margin: 40px;
}
.aboutus-page .ab-content-item{
  height: 60px;
  width: 250px;
  line-height: 60px;
  color: #666666;
  border-radius: 8px 0 0 8px;
}
.aboutus-page .ab-content-item-hover{
  border-radius: 8px 0 0 8px;
  cursor: pointer;
}
.aboutus-page .ab-content-item-hover:hover{
  background-color: #fff5f0;
  border-right: 4px solid #FE6002;
}
.aboutus-page .ab-content-choose-item{
  background-color: #fff5f0;
  color: #fe6002 !important;
}
.aboutus-page .ab-content-item img{
  width: 24px;
  height: 24px;
}
.aboutus-page .ab-content-name{
  width: 72px;
  height: 28px;
  
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  padding-left: 10px;
}

</style>