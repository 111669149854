<template>
  <div class="my-transfer">
    <div style="height: 30px"></div>

    <div style="width: 100%">
      <div class="common-title-view">
        <span class="left-xian-view"></span>
        <span class="text">转店</span>
      </div>
    </div>
    <div style="position: relative">
      <img
        @click="newsBuyStore"
        src="../../assets/setPageIndex/transfer_add.png"
        class="transfer-add"
      />
    </div>
    <el-card class="box-card">
      <div v-for="item in transferList" :key="item.id" class="transfer-item" @click="toDetail(item.id)">
        <div class="transfer-item-row1 hide">
          <p class="transfer-item-title">{{ item.title }}</p>
          <p v-show="item.s_status !== '2'" class="transfer-item-state">
            {{ item.state }}
          </p>
          <p v-show="item.s_status === '2'" class="transfer-item-ok">
            转店成功
          </p>
        </div>
        <div class="transfer-item-row2 hide">
          <p class="transfer-item-time">更新时间：{{ item.time }}</p>
        </div>
        <div class="transfer-item-row3">
          <div class="transfer-item-del" @click.stop="transferDel(item.id)">
            <i class="el-icon-delete"></i>
          </div>
          <div class="transfer-item-butt">
            <img
              v-show="item.pay_status === '2' && item.s_status !== '2'"
              src="../../assets/setPageIndex/transfer_sale.png"
              @click.stop="rollOut(item.id)"
            />
            <img
              v-show="item.s_status !== '2'"
              src="../../assets/setPageIndex/transfer_update.png"
              @click.stop="updateTransfer(item.id)"
            />
            <img
              v-show="item.pay_status === '1'"
              src="../../assets/setPageIndex/transfer_pay.png"
              @click.stop="subbmitOrderTransfer(item.id)"
            />
          </div>
        </div>
      </div>
    </el-card>
    <AffirmDialog
      :isopenAffirm="isopen"
      :affirmDialogClose="affirmDialogClose"
      :affirmDialogOk="affirmDialogOk"
      :affirmTitle="affirmDialogArr.affirmTitle"
      :affirmMsge="affirmDialogArr.affirmMsge"
      :affirmFrom="affirmDialogArr.affirmFrom"
      :dataId="affirmDialogArr.dataId"
    >
    </AffirmDialog>
    <el-dialog
      title="购买VIP"
      :visible.sync="payTransfferDialogShow"
      @close="closeDigByPay"
      width="410px"
    >
      <div class="vip-type-view">
        <img class="vip-type-bg" src="@/assets/vip_pay_bg_img.png" alt="" />
        <div class="vip-type-text-view">
          <img class="lczx-img" src="@/assets/vip_pay_zx_img.png" alt="" />
          <div class="left-text-view">
            <div class="name-tt1">转店VIP套餐</div>
            <div class="time">使用时间：转掉为止</div>
          </div>
          <div class="right-text-view">
            <label>￥</label>{{ payInf.order_price }}
          </div>
        </div>
      </div>
      <div class="pay-type-view">
        <div class="paytype-title">支付方式</div>
        <div class="paytype">
          <div
            class="paytype-item"
            @click="changePayType('wxpay')"
            :class="payType == 'wxpay' ? 'item-sl' : ''"
          >
            <img src="@/assets/vip_paytype_wx_img.png" alt="" />
            <span>微信支付</span>
          </div>
          <div
            class="paytype-item"
            @click="changePayType('alipay')"
            :class="payType == 'alipay' ? 'item-sl' : ''"
          >
            <img src="@/assets/vip_paytype_zfb_img.png" alt="" />
            <span>支付宝支付</span>
          </div>
        </div>
      </div>
      <div class="wx-pay-img-view" v-show="payType == 'wxpay'">
        <div class="wxpay-tt1">请使用<label>微信</label>扫描二维码完成支付</div>
        <div class="wxpay-tt2">
          如无法支付或二维码已过期请点击<label>刷新</label>
        </div>
        <img class="wxpay-ewm-img" :src="payInf.pay_img" alt="" />
        <div class="wxpay-tt2">
          购买付费即表示同意<label>《猎宠网VIP服务协议》</label>
        </div>
      </div>
      <div v-show="payType == 'alipay'" slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toAliPayPage">去支付</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { delShop, transferQuickEdit } from "@/api/commen/transferShop";
import AffirmDialog from "@/components/affirmDialog.vue";
import { submitOrder } from "@/api/commen/payApi";

export default {
  components: { AffirmDialog },
  props: ["transferList"],
  data() {
    return {
      payTransfferDialogShow: false,
      isopen: false,
      affirmDialogArr: {
        dataId: "",
        affirmTitle: "",
        affirmMsge: "",
        affirmFrom: "",
      },
      payType: "wxpay",
      shopId: 0,
      payInf: {
        // payType: '',
        // order_price: 0,
        // pay_img: ''
      },
    };
  },
  methods: {
    toDetail(id){
      console.log(id)
      if (!id && !isNaN(id)) return;
      this.$router.push('transferdetail?id='+id);
    },
    subbmitOrderTransfer(id) {
      this.shopId = id;
      this.changePayType(this.payType);
      this.payTransfferDialogShow = true;
    },
    closeDigByPay() {
      this.payTransfferDialogShow = false;
    },
    toAliPayPage() {
      window.open(this.payInf.url, "_blank");
    },
    changePayType(type) {
      this.payType = type;
      submitOrder({
        pay_type: this.payType,
        type: 27,
        storeId: this.shopId,
        rating_id: "0",
      })
        .then((result) => {
          console.log("提交订单", result);
          if (type == "wxpay") {
            this.payInf = result.data;
          } else {
            this.payInf = result.data;
          }
        })
        .catch(() => {});
    },
    newsBuyStore() {
      this.$router.push("/publishtransffer");
    },
    updateTransfer(id) {
      this.$router.push("/publishtransffer?id=" + id);
    },
    affirmDialogOk(fromType, dataId) {
      if (fromType === "zd") {
        delShop(fromType, dataId).then((req) => {
          if (req.code === 200) {
            this.isopen = false;
            this.$emit("updateTransferList");
            this.$message.success(req.msg);
          }
        });
      } else if (fromType === "zc") {
        transferQuickEdit(dataId, "transfer_status", 1).then((req) => {
          if (req.code === 200) {
            this.isopen = false;
            this.$emit("updateTransferList");
            this.$message.success(req.msg);
          }
        });
      }
    },
    // 转出
    rollOut(id) {
      this.affirmDialogArr.dataId = id;
      this.affirmDialogArr.affirmTitle = "提醒";
      this.affirmDialogArr.affirmMsge = "一经确认转店成功就将不能再修改";
      this.affirmDialogArr.affirmFrom = "zc";
      this.isopen = true;
    },
    // 关闭弹窗
    affirmDialogClose() {
      this.isopen = false;
    },
    // 删除
    transferDel(id) {
      this.affirmDialogArr.dataId = id;
      this.affirmDialogArr.affirmTitle = "提醒";
      this.affirmDialogArr.affirmMsge = "删除后将无法恢复，您确定要删除吗？";
      this.affirmDialogArr.affirmFrom = "zd";
      this.isopen = true;
    },
  },
};
</script>
<style scoped>
>>> .el-card__body {
  padding: 0;
}

.common-title-view {
  margin-bottom: 16px;
}

.box-card {
  width: 1196px;
  margin-left: 24px;
  padding: 0;
}

.transfer-item {
  width: 1148px;
  height: 174px;
  padding: 34px 24px 0 24px;
  box-sizing: border-box;
  border-bottom: #d9d9d9 1px solid;
  margin: 0 auto;
  cursor: pointer;
}

.transfer-item-title {
  float: left;
  font-size: 22px;
  
  font-weight: 600;
  color: #191919;
  line-height: 28px;
}

.transfer-item-state {
  float: right;
  font-size: 16px;
  
  font-weight: 600;
  color: #f34b37;
  line-height: 19px;
}

.transfer-item-time {
  font-size: 16px;
  
  font-weight: 400;
  color: #939597;
  line-height: 19px;
}

.transfer-item-ok {
  float: right;
  height: 22px;
  font-size: 16px;
  
  font-weight: 600;
  color: #cccccc;
  line-height: 19px;
}

.transfer-item-del {
  float: left;
  width: 22px;
  color: #999999;
  cursor: pointer;
}

.transfer-item-butt {
  float: right;
}

.transfer-item-butt img {
  height: 32px;
  width: 72px;
  margin-left: 16px;
  cursor: pointer;
}

.transfer-item-row1 {
  height: 28px;
  margin-bottom: 16px;
}

.transfer-item-row2 {
  height: 22px;
  margin-bottom: 16px;
}

.transfer-item-row3 {
  line-height: 32px;
}

.transfer-item:last-child {
  border-bottom: 0;
}

.hide {
  overflow: hidden;
}
.my-transfer {
  margin-bottom: 30px !important;
}
.my-transfer .box-card:hover{
  background-color: #fff5f0;
  border: #fe6002 1px solid;
}
</style>