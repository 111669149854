<template>
  <div class="h5-information-page">
    <div class="detail-view">
      <div class="title">{{ detail.title }}</div>
      <div class="time">{{ detail.time }}</div>
      <div class="txt">
        <!-- <text>打分卡奋达科技理发卡电极法徕卡的</text> -->
        <!-- <u-parse :html="detail.content" :show-with-animation="true"></u-parse> -->
        <div v-html="detail.content"></div>
      </div>
    </div>

    <!-- <div class="pl">
      <div class="tak-title">评论 {{ commentLen }}</div>

      <div class="tallke-list">
        <label v-for="item in detail.comments" :key="item.id">
          <div class="fa-item">
            <img class="fa-head-img" :src="item.avater" mode="" />
            <div class="tallke-text">
              <div class="fa-name-btn">
                <div class="name">
                  <div class="n1">{{ item.nickname }}</div>
                  <div class="n2">{{ item.job }}</div>
                </div>
                <div class="right-view" @click="postUserUp(item.id, 2)">
                  <span>{{ item.up }}</span>
                 
                </div>
              </div>
              <div
                class="ta-text"
                @click="getInputFocus(item.id, item.nickname)"
              >
                {{ item.content }}
              </div>
             

              <label
                v-show="item.child_comment"
                v-for="citem in item.child_comment"
                :key="citem.id"
              >
                <div class="son-item">
                  <img class="son-head-img" :src="citem.avater" mode="" />
                  <div class="tallke-text">
                    <div class="fa-name-btn">
                      <div class="name">
                        <div class="n1">{{ citem.nickname }}</div>
                  
                      </div>
                      <div class="right-view" @click="postUserUp(citem.id, 2)">
                        <span>{{ citem.up }}</span>
                        <img
                          :src="
                            citem.is_up == '0'
                              ? '/static/images/circle_dz.png'
                              : '/static/images/circle_dz2.png'
                          "
                          mode=""
                        />
                      </div>
                    </div>

                    <div class="ta-text">{{ citem.content }}</div>

                    <div class="bot-time">
                      <div class="time">{{ citem.time }}</div>
                    </div>
                  </div>
                </div>
              </label>
            </div>
          </div>
        </label>
      </div>
    </div> -->

    <div style="height: 155px"></div>
  </div>
</template>

<script>
import { getInformationDetail } from "@/api/commen/informationAip.js";
export default {
  data() {
    return {
      id: "",
      detail: "",
      hyName: "",
      commentLen: 0,
    };
  },
  mounted() {
  },
  created() {
    // this.hyName = this.$route.query.hy;
    this.id = this.$route.query.id;
    this.getInformationDetail();
  },
  methods: {
    getInformationDetail() {
      getInformationDetail({
        id: this.id,
      })
        .then((result) => {
          console.log("获取资讯详情", result);
          result.data.time = new Date(result.data.datetime * 1000).format(
            "yyyy-M-dd"
          );
          this.detail = result.data;
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss">
.h5-information-page {
  width: 100%;
  height: 100%;
  overflow: scroll;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  transition: width 0.3s ease;
  &::-webkit-scrollbar {
    display: none;
  }
  img {
    width: 100%;
    height: auto;
  }
}

.detail-view {
  padding: 0 32px;

  .title {
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #222222;
    margin-top: 120px;
  }

  .time {
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #b1b1b1;
    margin-top: 24px;
  }

  // .img {
  //   margin-top: 48px;

  //   img {
  //     width: 686px;
  //   }
  // }

  .txt {
    margin-top: 32px;
    font-size: 28px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #222222;
    padding-bottom: 64px;
  }
}
</style>