<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-12-18 18:02:20
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-03-20 18:46:13
 * @FilePath: /lcwpcvue/src/components/comUser/vipPayPage.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="myequity-page">
    <div class="equity-top-inf-view">
      <img
        class="equity-inf-bg-img"
        src="@/assets/equity_inf_bg_img.png"
        alt=""
      />
      <div class="equity-com-inf">
<!--        <img class="user-avater-img" src="@/assets/com_mr.png" alt="" />-->
        <img class="user-avater-img" :src="userInfo.logo" alt="" />
        <div class="equity-inf-text">
          <div class="tt1">{{ userInfo.name }}</div>
          <div class="tt2" v-show="userInfo.is_vip !== '1'">你还不是会员，开通立享以下会员特权</div>
          <div class="myequity-is-vip" v-show="userInfo.is_vip === '1'">
            <img :src="userInfo.icon" alt="">
            <span>会员到期时间：{{userInfo.vip_etime}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="vip-combo-list-view">
      <label v-for="item in vipComboList" :key="item.id">
        <div
          class="vipcombo-item"
          :class="ratingId == item.id && item.id != 105 ? 'eq-it-sl' : ''"
          @click="changeVipCombo(item)"
        >
          <img
            v-show="ratingId == 105 && item.id == 105"
            class="tj-bg-img"
            src="@/assets/vip_xzdt.png"
          />
          <div :class="ratingId == item.id ? 'eq-it-sl-choose' : ''">
            <div class="vip-name">{{ item.name }}</div>
            <div class="vip-sp-text">
              <label>￥</label>{{ item.service_price }}
            </div>
            <div class="vipcombo-time-text">/{{ item.service_time }}天</div>
          </div>
        </div>
      </label>
    </div>
    <div class="combo-equity-view">
      <div class="title-view">开通即享以下权益</div>
      <div class="equity-list-view">
        <span v-for="(item, index) in comboEquity" :key="index">
          <div class="equity-item">
            <img class="it-img" :src="item.icon" alt="" />
            <div class="it-tt1">{{ item.num }}</div>
            <div class="it-tt2">{{ item.title }}</div>
          </div>
        </span>
      </div>
      <div class="submit-btn-view">
        <el-button type="primary" @click="showPayDialog">立即开通</el-button>
      </div>
      <div class="pay-vip-tips-view">
        <div class="xy-text">
          购买付费即表示同意
          <span
            style="color: #3291e1; cursor: pointer"
            @click="openAgreementFun"
          >
            《猎宠网VIP服务协议》
          </span>
        </div>
        <div class="tips-text-view">
          <div class="tips-title">购买说明：</div>
          <div class="tips-text">
            1、生效时间&有效期：VIP购买成功后立即生效，有效期每个月按照30天自然日计算<br />
            2、服务退款：VIP服务为持续消耗型商品，购买后不支持退款<br />
            3、VIP服务的最终解释权归猎宠网所有，如您有其他问题，可联系我的客服
          </div>
        </div>
      </div>
    </div>
    <com-dialog
      :isopen="isOpenAgreement"
      dialogTetle="猎宠网VIP服务协议"
      @dialogFrameEvent="agreementEvent"
      myWidth="1280px"
    >
      <div v-html="vipServe" class="vipServe-content"></div>
    </com-dialog>
    <el-dialog
      title="购买VIP"
      :visible.sync="payVipDialogShow"
      class="vip-pay-dialog-css"
    >
      <div class="vip-type-view">
        <img class="vip-type-bg" src="@/assets/vip_pay_bg_img.png" alt="" />
        <div class="vip-type-text-view">
          <img class="lczx-img" src="@/assets/vip_pay_zx_img.png" alt="" />
          <div class="left-text-view">
            <div class="name-tt1">{{ payVipInf.name }}</div>
            <div class="time">/{{ payVipInf.service_time }}天</div>
          </div>
          <div class="right-text-view">
            <label>￥</label>{{ payVipInf.service_price }}
          </div>
        </div>
      </div>
      <div class="pay-type-view">
        <div class="paytype-title">支付方式</div>
        <div class="paytype">
          <div
            class="paytype-item"
            @click="changePayType('wxpay')"
            :class="payType == 'wxpay' ? 'item-sl' : ''"
          >
            <img src="@/assets/vip_paytype_wx_img.png" alt="" />
            <span>微信支付</span>
          </div>
          <div
            class="paytype-item"
            @click="changePayType('alipay')"
            :class="payType == 'alipay' ? 'item-sl' : ''"
          >
            <img src="@/assets/vip_paytype_zfb_img.png" alt="" />
            <span>支付宝支付</span>
          </div>
        </div>
      </div>
      <div class="wx-pay-img-view" v-show="payType == 'wxpay'">
        <div class="wxpay-tt1">请使用<label>微信</label>扫描二维码完成支付</div>
        <div class="wxpay-tt2">
          如无法支付或二维码已过期请点击<label>刷新</label>
        </div>
        <img class="wxpay-ewm-img" :src="wxPayImgUrl" alt="" />
        <div class="wxpay-tt2">
          购买付费即表示同意<label @click="isOpenAgreement = true"
            >《猎宠网VIP服务协议》</label
          >
        </div>
      </div>
      <div v-show="payType == 'alipay'" slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toAliPayPage">去支付</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getVipComboList, submitVipOrder } from "@/api/compe/aboutVipApi.js";
import ComDialog from "@/components/comDialog.vue";
import { getUserProfile } from "@/api/commen/login";
import {parseTime} from "@/utils/datePas";

export default {
  name: "vippaypage",
  components: { ComDialog },
  data() {
    return {
      vipComboList: [],
      comboEquity: {},
      ratingId: 105,
      payVipDialogShow: false,
      payVipInf: {},
      payType: "wxpay",
      wxPayImgUrl: "",
      aliNatoUrl: "",
      isOpenAgreement: false,
      vipServe: "",
      userInfo:{}
    };
  },
  created() {
    this.getVipComboList();
    // this.userInfo = this.$store.state.user.userinf;
  },
  methods: {
    agreementEvent() {
      this.isOpenAgreement = false;
    },
    openAgreementFun() {
      if (!this.vipServe) {
        getUserProfile("/vipServe").then((res) => {
          this.vipServe = res.data;
          this.isOpenAgreement = true;
        });
      }else {
        this.isOpenAgreement = true;
      }
    },
    getVipComboList() {
      getVipComboList()
        .then((result) => {
          console.log("获取会员套餐", result);
          this.userInfo = result.data;
          this.userInfo.vip_etime = parseTime(this.userInfo.vip_etime,'{y}-{m}-{d}');
          this.payVipInf = result.data.list[1];
          this.vipComboList = result.data.list;
          this.comboEquity = result.data.list[1].arr;
        })
        .catch(() => {});
    },
    changeVipCombo(e) {
      console.log(e);
      this.payVipInf = e;
      this.ratingId = e.id;
      this.comboEquity = e.arr;
    },
    changePayType(type) {
      this.payType = type;
      submitVipOrder({
        type: 1,
        rating_id: this.ratingId,
        pay_type: this.payType,
      })
        .then((result) => {
          console.log("提交订单", result);
          if (type == "wxpay") {
            this.wxPayImgUrl = result.data.pay_img;
          } else {
            this.aliNatoUrl = result.data.url;
          }
        })
        .catch(() => {});
    },
    showPayDialog() {
      submitVipOrder({
        type: 1,
        rating_id: this.ratingId,
        pay_type: this.payType,
      })
        .then((result) => {
          console.log("提交订单", result);
          this.wxPayImgUrl = result.data.pay_img;
          this.payVipDialogShow = true;
        })
        .catch(() => {});
    },
    toAliPayPage() {
      window.open(this.aliNatoUrl, "_blank");
    },
  },
};
</script>

<style>
.myequity-page .el-dialog__wrapper .el-dialog {
  border-radius: 20px;
}

span.el-breadcrumb__inner.is-link {
  color: #0294fe;
}
.breadcrumb {
  margin-top: 50px;
}
.equity-top-inf-view {
  margin-top: 5px;
  position: relative;
}
.equity-top-inf-view .equity-inf-bg-img {
  width: 1196px;
  height: 138px;
}

.equity-top-inf-view .equity-com-inf {
  width: 1196px;
  height: 138px;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
}
.equity-top-inf-view .equity-com-inf .user-avater-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-left: 24px;
}
.equity-top-inf-view .equity-com-inf .equity-inf-text {
  margin-left: 20px;
}
.equity-top-inf-view .equity-com-inf .equity-inf-text .tt1 {
  font-size: 24px;
  
  font-weight: 600;
  color: #ffffff;
  line-height: 28px;
}
.equity-top-inf-view .equity-com-inf .equity-inf-text .tt2 {
  font-size: 18px;
  
  font-weight: 400;
  color: #ffffff;
  line-height: 24px;
  margin-top: 15px;
}
.myequity-page .myequity-is-vip{
  margin-top: 15px;
  color: white;
  font-size: 18px;
  
  font-weight: 400;
}
.myequity-page .equity-top-inf-view .equity-com-inf .equity-inf-text .myequity-is-vip img {
  height: 23px;
  width: auto;
  margin-right: 15px;
}
.vip-combo-list-view {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 38px;
  padding: 0 26px;
}

.vip-combo-list-view .vipcombo-item {
  width: 141px;
  height: 187px;
  background-color: #f5f6fa;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #e7e8eb;
  position: relative;
  cursor: pointer;
}
.vip-combo-list-view .vipcombo-item:hover{
  background-color: #fff5f0;
}
.vip-combo-list-view .eq-it-sl {
  background-color: #fff5f0;
  border: 1px solid #fd8035;
}
.vip-combo-list-view .vipcombo-item .tj-bg-img {
  width: 175px;
  height: auto;
  position: absolute;
  z-index: 9;
}
.vip-combo-list-view .vipcombo-item .vip-name {
  font-size: 20px;
  
  font-weight: 600;
  color: #604517;
  line-height: 28px;
}
.vip-combo-list-view .vipcombo-item .vip-sp-text {
  font-size: 32px;
  
  font-weight: 600;
  color: #f34b37;
  line-height: 38px;
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.vip-combo-list-view .vipcombo-item .vip-sp-text label {
  font-size: 24px;
  line-height: 28px;
}
.vip-combo-list-view .vipcombo-item .vipcombo-time-text {
  font-size: 18px;
  
  font-weight: 400;
  color: #757575;
  line-height: 24px;
  margin-top: 10px;
}

.combo-equity-view {
  width: 1144px;
  height: 790px;
  background-color: #fff;
  border-radius: 4px;
  padding: 40px 26px;
  position: relative;
  top: -10px;
  z-index: 99;
}
/* .combo-equity-view:before, */
/*.combo-equity-view:after {
  position: absolute;
  top: -80px;
  content: "";
  border: 25px solid transparent;
  border-right-color: white;
}

.combo-equity-view:after {
  top: -49px;
  left: 65px;
  border-color: transparent transparent #fff;
}*/
.myequity-page .vip-combo-list-view .eq-it-sl-choose:after{
  position: absolute;
  content: "";
  border: 25px solid transparent;
  border-right-color: white;
  top: 130px;
  left: 50px;
  border-color: transparent transparent #fff;
  z-index: 99;
}

.combo-equity-view .title-view {
  font-size: 24px;
  
  font-weight: 600;
  color: #191919;
  line-height: 28px;
  /* margin-top: 35px; */
}

.combo-equity-view .equity-list-view {
  /* margin: 0 42px; */
}

.combo-equity-view .equity-list-view .equity-item {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  width: 160px;
  margin-top: 40px;
}

.combo-equity-view .equity-list-view .equity-item .it-img {
  width: 60px;
  height: 60px;
}

.combo-equity-view .equity-list-view .equity-item .it-tt1 {
  font-size: 22px;
  
  font-weight: 600;
  color: #222222;
  line-height: 28px;
  margin-top: 10px;
}

.combo-equity-view .equity-list-view .equity-item .it-tt2 {
  font-size: 20px;
  
  font-weight: 400;
  color: #939597;
  line-height: 28px;
  margin-top: 5px;
}
.combo-equity-view .submit-btn-view {
  width: 100%;
  text-align: center;
  margin-top: 50px;
}

.combo-equity-view .submit-btn-view button.el-button.el-button--primary {
  width: 800px;
  height: 74px;
  font-size: 24px;
  
  font-weight: 600;
  color: #ffffff;
  background-color: #222222;
  border: none;
}

.combo-equity-view .pay-vip-tips-view {
  width: 1095px;
  height: 176px;
  background-color: #f5f6fa;
  border-radius: 8px;
  margin-top: 50px;
  padding: 16px 24px;
}

.combo-equity-view .pay-vip-tips-view .xy-text {
  font-size: 20px;
  
  font-weight: 400;
  color: #303030;
}

.combo-equity-view .pay-vip-tips-view .xy-text a {
  text-decoration: none;
  color: #3291e1;
}

.combo-equity-view .pay-vip-tips-view .tips-text-view {
  margin-top: 24px;
}

.combo-equity-view .pay-vip-tips-view .tips-text-view .tips-title {
  font-size: 20px;
  
  font-weight: 600;
  color: #303030;
}

.combo-equity-view .pay-vip-tips-view .tips-text-view .tips-text {
  font-size: 20px;
  
  font-weight: 400;
  color: #303030;
  line-height: 28px;
  margin-top: 15px;
}
.vip-pay-dialog-css .el-dialog {
  width: 524px;
}
/* .vip-pay-dialog-css .el-dialog__body,
.vip-pay-dialog-css .el-dialog__header {
  width: 524px;
} */

.vip-type-view {
  height: 100px;
  border-top: 2px solid #eaecf5;
  padding-left: 33px;
  padding-top: 40px;
  position: relative;
}
.vip-type-view .vip-type-bg {
  width: 450px;
  height: 100px;
}

.vip-type-view .vip-type-text-view {
  width: 450px;
  height: 100px;
  position: absolute;
  top: 40px;
  display: flex;
  align-items: center;
}

.vip-type-view .vip-type-text-view .lczx-img {
  width: 60px;
  height: 24px;
  position: absolute;
  top: -10px;
}

.vip-type-view .vip-type-text-view .left-text-view {
  margin-left: 16px;
}
.vip-type-view .vip-type-text-view .left-text-view .name-tt1 {
  font-size: 22px;
  
  font-weight: 600;
  color: #562c00;
  line-height: 28px;
}

.vip-type-view .vip-type-text-view .left-text-view .time {
  font-size: 14px;
  
  font-weight: 400;
  color: #562c00;
  line-height: 16px;
  margin-top: 5px;
}
.vip-type-view .vip-type-text-view .right-text-view {
  font-size: 32px;
  
  font-weight: 600;
  color: #f34b37;
  line-height: 38px;
  position: absolute;
  bottom: 10px;
  right: 20px;
}

.vip-type-view .vip-type-text-view .right-text-view label {
  font-size: 16px;
  
  font-weight: 400;
  color: #f34b37;
  line-height: 19px;
}

.pay-type-view {
  margin-top: 30px;
  padding: 0 33px;
}
.pay-type-view .paytype-title {
  font-size: 16px;
  
  font-weight: 600;
  color: #191919;
  line-height: 19px;
}

.pay-type-view .paytype {
  display: flex;
  align-items: center;
  margin-top: 16px;
}

.pay-type-view .paytype .paytype-item {
  width: 166px;
  height: 48px;
  display: flex;
  align-items: center;
  border: 1px solid#CCCCCC;
  border-radius: 4px;
  margin-right: 48px;
  cursor: pointer;
}

.pay-type-view .paytype .paytype-item img {
  width: 40px;
  height: 40px;
  margin-left: 24px;
}

.pay-type-view .paytype .paytype-item span {
  font-size: 14px;
  
  font-weight: 400;
  color: #191919;
  line-height: 16px;
}

.pay-type-view .paytype .item-sl {
  border: 1px solid#FE6002;
  background-color: #fffaf7;
}

.wx-pay-img-view {
  width: 100%;
  text-align: center;
  margin-top: 40px;
}

.wx-pay-img-view .wxpay-tt1 {
  font-size: 14px;
  
  font-weight: 600;
  color: #757575;
  line-height: 16px;
}
.wx-pay-img-view .wxpay-tt1 label {
  color: #fe6002;
}
.wx-pay-img-view .wxpay-tt2 {
  font-size: 12px;
  
  font-weight: 400;
  color: #757575;
  line-height: 14px;
  margin-top: 4px;
}
.wx-pay-img-view .wxpay-tt2 label {
  color: #0294fe;
  cursor: pointer;
}

.wx-pay-img-view .wxpay-ewm-img {
  width: 150px;
  height: 150px;
}
.myequity-page .dialog-footer {
  width: 100%;
  text-align: center;
  margin-top: 30px;
}
.myequity-page .dialog-footer button.el-button.el-button--primary {
  width: 400px;
  height: 50px;
}
.myequity-page .vipServe-content {
  border-top: 2px solid rgba(230, 230, 230, 0.5);
  padding: 50px 50px 80px;
}
/* .vip-combo-list-view .vipcombo-item .{} */
</style>