/*
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-08-01 10:51:47
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-08-14 16:56:38
 * @FilePath: /lcwpcvue/src/api/commen/aboutTalentsApi.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'
//获取求职者列表
export function getTalentsList(params) {
  return request({
    url: 'Personnel/personnelListV2',
    method: 'post',
    data: params
  })
}

//省份
export function getProvinceList() {
  return request({
    url: 'Cityclass/provinceList',
    method: 'get'
  })
}


/**
* 获取求职详细信息
* @url Personnel/personnelInfo
* @param {uid } params 
* @return 
*/

export function getTalentsDetail(params) {
  return request({
    url: 'Personnel/personnelInfo',
    method: 'post',
    data: params
  })
}

/**
* 检查能否查看联系方式
* @url Personnel/getContactV2
* @param {uid } params 
* @return 
*/

export function checkCanGetPhone(params) {
  return request({
    url: 'Personnel/getContactV2',
    method: 'get',
    params: params
  })
}

/**
* 检查能否直聊
* @url Chat/isCanChat(废弃)  v2/WschatV2/isCanChat
* @param {to_uid } params 
* @return 
*/

export function checkCanChat(params) {
  return request({
    url: 'v2/WschatV2/isCanChat',
    method: 'post',
    data: params
  })
}

/**
* 判断是否拉黑
* @url Chat/checkSetUp
* @param {to_uid } params 
* @return 
*/

export function checkIsLahe(params) {
  return request({
    url: 'Chat/checkSetUp',
    method: 'post',
    data: params
  })
}

/**
* 推荐人才
* @url Chat/checkSetUp
* @param {to_uid } params 
* @return 
*/

/**
* 获取举报选项
* @url Report/reasonClass
* @param { } params 
* @return 
*/

export function getReportRadios() {
  return request({
    url: 'Report/reasonClass',
    method: 'post'
  })
}


/**
* 举报简历
* @url Report/doReport
* @param {type,reason_class,eid,c_uid, r_reason,r_pic} params 
* @return 
*/
export function postReport(params) {
  return request({
    url: 'Report/doReport',
    method: 'post',
    data: params
  })
}

/**
* 收藏简历
* @url Comcollection/doCollection
* @param {} params 
* @return 
*/
export function postCollection(params) {
  return request({
    url: 'Comcollection/doCollection',
    method: 'post',
    data: params
  })
}










