<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-10-07 09:26:32
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-06-06 15:52:20
 * @FilePath: /lcwpcvue/src/views/transferDetail.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="transfer-inf-page">
    <div class="top-zk-div"></div>
<!--    <div v-if="openshareQrcode" class="mask" @click="closeModal"></div>-->
<!--    <div v-if="isShowQrcodeImg" class="mask" @click="closeModal"></div>-->
    <div style="width: 100%; background-color: white">
      <div class="top-name-uptime-look-share">
        <div class="left-view">
          <div class="title">{{ storeInf.title }}</div>
          <div class="up-look-jb-view">
            <div class="items">
              <img src="../assets/jobinf_uptime_img.png" alt="" />
              <span>更新于 {{ storeInf.update_time }}</span>
            </div>
            <div class="items">
              <img src="../assets/jobinf_look_img.png" alt="" />
              <span>浏览 {{ storeInf.views }}次</span>
            </div>
            <!--          <div class="items">-->
            <!--            <img src="../assets/jobinf_jb_img.png" alt=""/>-->
            <!--            <span>举报</span>-->
            <!--          </div>-->
          </div>
        </div>
      </div>
    </div>
    <div class="min-banner-inf-link-view">
      <div class="banner-view">
        <div class="top-banner-view">
          <div class="video-view">
            <!--            <img v-if="isVideoOrImg === 1" class="video-cover-img" :src="currentImage" @click="amplificationImg" style="cursor: pointer"/>-->
            <el-image
              class="video-cover-img"
              v-if="isVideoOrImg === 1"
              ref="imagepreview"
              :src="currentImage"
              :preview-src-list="pics"
            >
            </el-image>
            <video v-else class="video-cover-img" ref="myVideo">
              <source :src="storeInf.video" type="video/mp4" />
            </video>
            <div class="fola-view">
              <img
                v-show="isVideoOrImg === 0"
                class="play-img"
                src="../assets/transfer_spplay.png"
                alt=""
                @click.prevent="lookImg('video')"
              />
              <div class="bot-change-view" v-if="storeInf.video">
                <span
                  :class="isVideoOrImg === 0 ? 'ischose' : ''"
                  @click.prevent="choseVideoOrImg(0)"
                  >视频</span
                >
                <span
                  :class="isVideoOrImg === 1 ? 'ischose' : ''"
                  @click.prevent="choseVideoOrImg(1)"
                  >图片</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="bot-scro-view">
          <div
            class="aleft-div slide-left-right-div"
            @click.prevent="exeNextImg(1)"
          >
            <i class="el-icon-arrow-left icon-arrowhead-left"></i>
          </div>
          <label v-for="(item, idx) in pics" :key="idx">
            <img
              class="scro-img"
              :class="idx == imgIdx ? 'Selected-img' : ''"
              :src="item"
              @click.prevent="lookImg(item, idx)"
            />
            <!-- <el-image
              class="scro-img"
              :class="idx == imgIdx ? 'Selected-img' : ''"
              :src="item"
              @click.prevent="lookImg(item, idx)"
            ></el-image> -->
          </label>
          <div
            class="towards-right-div slide-left-right-div"
            @click.prevent="exeNextImg(2)"
          >
            <i class="el-icon-arrow-right icon-arrowhead-right"></i>
          </div>
        </div>
      </div>
      <div class="min-lefit-view">
        <div class="transfer-inf-top-sp-view">
          <span class="sp1">{{ storeInf.rent }}</span>
          <span class="sp2">元/月</span>
          <span v-if="storeInf.rent_type_str !== '其他'" class="sp3">{{
            storeInf.rent_type_str
          }}</span>
          <span v-else class="sp3">{{ storeInf.rent_type_body }}</span>
        </div>
        <el-popover
          ref="popover2"
          placement="bottom"
          title=""
          width="200"
          trigger="click"
        >
          <div class="share-view" slot="reference" @click="openshareQrcodeFun">
            <img src="../assets/jobinf_share_img.png" alt="" />
            <span>分享</span>
          </div>
          <div class="share-ewm-img" v-show="shareEwmImg">
            <img :src="shareEwmImg" alt="" />
          </div>
        </el-popover>
        <div class="area-transfer-pr-view">
          <div class="mm-item">
            <div class="tt1">
              <span class="ar-t1">{{ storeInf.area }}</span>
              <span class="ar-t2">m²</span>
            </div>
            <div class="tt2">店铺面积</div>
          </div>
          <div class="mm-item">
            <div class="tt1" v-show="storeInf.salary_type != '1'">
              <span class="ar-t1">{{ storeInf.transfer_fee }}</span>
              <span class="ar-t2">万元</span>
            </div>
            <div class="tt1" v-show="storeInf.salary_type == '1'">
              <span class="ar-t1">面议</span>
            </div>
            <div class="tt2">店铺转让费</div>
          </div>
        </div>
        <div class="ls-kj-vipnum">
          <div class="inf-item">
            <span class="lf-xian"></span>
<!--            <span class="lf-txt">每月流水：{{storeInf.month_tally}}</span>-->
            <span class="lf-txt">每月流水：{{month_tally}}</span>
          </div>
          <div class="inf-item">
            <span class="lf-xian"></span>
<!--            <span class="lf-txt">店铺卡金：{{storeInf.card_tally}}k</span>-->
            <span class="lf-txt">店铺卡金：{{card_tally}}</span>
          </div>
          <div class="inf-item">
            <span class="lf-xian"></span>
<!--            <span class="lf-txt">会员数量：{{storeInf.mem_num}}</span>-->
            <span class="lf-txt">会员数量：{{mem_num}}</span>
          </div>
        </div>
        <div class="link-man-view">
          <div class="linman-inf-view">
            <img class="link-head-img" src="../assets/com_mr.png" alt="" />
            <div class="right-name-lin-add-view">
              <div class="name-sta-view">
                <span class="name">{{storeInf.link_man}}</span>
<!--                <span class="dian"></span>
                <span class="islin">在线</span>-->
              </div>
              <div class="add">{{ storeInf.address }}</div>
            </div>
          </div>
          <div class="bot-btn-view"  slot="reference" @click.prevent="lookStorePhone">
            <span>{{buttContent}}</span>
            <span style="font-size: 14px;font-weight: 400" v-show="buttContent === '联系TA'">&nbsp;&nbsp;(了解更多)</span>
          </div>
<!--          <el-popover
              ref="popover2"
              placement="top"
              title=""
              width="200"
              trigger="click"
          >
            <div class="bot-btn-view"  slot="reference" @click.prevent="lookStorePhone">
              <span>{{buttContent}}</span>
              <span style="font-size: 14px;font-weight: 400" v-show="buttContent === '联系TA'">&nbsp;&nbsp;(了解更多)</span>
            </div>
            <div class="share-ewm-img" v-show="shareEwmImg">
              <img :src="shareEwmImg" alt="" />
            </div>
          </el-popover>-->
        </div>
      </div>
    </div>

    <div class="com-sty dpts-view" v-show="storeInf.more_info.length > 0">
      <div class="title-one">店铺特色</div>
      <div class="dpts-item-view-sl">
        <span
          class="items-sl"
          v-for="(im, idx) in storeInf.more_info"
          :key="idx"
        >
          {{ im }}
        </span>
      </div>
    </div>

    <div class="com-sty dpjs-view">
      <div class="title-one">店铺介绍</div>
      <div class="stro-dexp-view">{{ storeInf.shop_desc }}</div>
      <div class="title-one" v-show="storeInf.peitaos.length > 0">配套设施</div>
      <div class="peitao-view" v-show="storeInf.peitaos.length > 0">
        <label v-for="(item, idx) in storeInf.peitaos" :key="idx">
          <div class="pt-item">
            <img :src="item.icon" />
            <span class="it-txt">{{ item.name }}</span>
          </div>
        </label>
      </div>
    </div>

    <div class="com-sty dpts-view" v-if="storeInf.other_peitao.length > 0">
      <div class="title-one">其他设施</div>
      <div class="dpts-item-view">
        <label v-for="(item, idx) in storeInf.other_peitao" :key="idx">
          <span class="items">{{ item }}</span>
        </label>

        <!-- <span class="items">美容台</span>
        <span class="items">美容台</span>
        <span class="items">美容台</span>
        <span class="items">美容台</span>
        <span class="items">美容台</span> -->
      </div>
    </div>
    <div class="com-sty com-addr" v-show="storeInf.lat && storeInf.lng">
      <div class="title-one">位置地图</div>
      <!-- <div class="addr-view">
        <img src="../assets/jobinf_addr_img.png" alt="" />
        <span>shanghai</span>
      </div> -->
      <div class="map">
        <zczmap
          v-if="storeInf.lat"
          :lat="storeInf.lat"
          :lng="storeInf.lng"
          idname="tramap"
          :mapwidth="'1130'"
          :mapheight="'335'"
        ></zczmap>
        <!-- <el-amap
          class="amap-box"
          :amap-manager="amapManager"
          :vid="'amap-vue'"
          :zoom="zoom"
          :plugin="plugin"
          :center="center"
          :events="events"
        >
          <el-amap-marker :position="center" :key="index"></el-amap-marker>
        </el-amap> -->
      </div>
    </div>

    <div class="tj-store-view" v-show="recStoreList">
      <div class="top-title-view">
        <img src="../assets/transfer_detail_tjimg.png" alt="" />
        <span class="title-text">推荐店铺</span>
      </div>

      <div class="tj-store-list-view">
        <label v-for="(item, index) in recStoreList" :key="item.id">
          <div
            class="tj-transfer-store-item"
            @click.prevent="recToDetail(item.id)"
          >
            <div class="head-img">
              <img :src="item.image" alt="" />
            </div>
            <div class="bot-store-ing">
              <div class="store-name">
                {{ item.title }}
              </div>
              <div class="jobtype-num-spr">
                <span class="jobtype-num">{{ item.typeName }}</span>
                <el-divider direction="vertical"></el-divider>
                <span class="jobtype-num">{{ item.storearea }} ㎡</span>
                <span class="spr-view"
                  >{{ item.storerent }}<label> 元/月</label></span
                >
              </div>
            </div>
          </div>
          <div v-show="(index + 1) % 4 != 0" class="ccard-fgx"></div>
        </label>
      </div>
    </div>
    <DialogFrame
      :isopen="openCodeDialog"
      dialog-tetle="短信验证"
      @dialogFrameEvent="dialogFrameEvent"
    >
      <SendTelCode event-tpye="login" ref="SendTelCode-ref"></SendTelCode>
    </DialogFrame>
  </div>
</template>

<script>
import {
  getTransferDetail,
  commonWayPost,
  userPcApi,
} from "@/api/commen/transferShop";
import DialogFrame from "@/components/ transferShop/dialogFrame.vue";
import SendTelCode from "@/components/ transferShop/sendTelCode.vue";
export default {
  components: { DialogFrame, SendTelCode },
  data() {
    return {
      id: "",
      index: "",
      storeInf: {},
      recStoreList: [],
      currentImage: "",
      isVideoOrImg: 0,
      openVideo: false,
      openCodeDialog: false,
      buttContent: "联系TA",
      isShowQrcodeImg: false, //电话联系弹窗
      openshareQrcode: false, //分享弹窗
      shareEwmImg: "",
      pics: [],
      imgIdx: 0,
      imgPage: 1,
      month_tally:'******',
      card_tally:'******',
      mem_num:'******',
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.getTransferDetail();
  },
  beforeDestroy() {
    this.shareEwmImg = "";
    this.buttContent = "联系TA";
  },
  mounted() {
    window.scrollTo(0, 0);
    document.title = "转店详情";
  },
  methods: {
    closeModal() {
      this.openshareQrcode = false;
      this.isShowQrcodeImg = false;
    },
    dialogFrameEvent(e) {
      if (e === "close") {
        this.openCodeDialog = false;
      } else {
        this.visitorLogin();
      }
    },
    // 分享按钮
    openshareQrcodeFun() {
      this.getXCXQrcodeImg();
    },
    //游客短信验证查看电话号码
    visitorLogin() {
      let SendTelCodeRef = this.$refs["SendTelCode-ref"];
      if (!SendTelCodeRef.phone) {
        return this.$message.error("请输入手机号");
      } else if (!SendTelCodeRef.code) {
        return this.$message.error("请输入验证码");
      }
      let reqParams = {
        code: SendTelCodeRef.code,
        id: this.id,
        phone: SendTelCodeRef.phone,
        type: "storCheckCode",
      };
      commonWayPost(reqParams).then((res) => {
        if (res.code === 200) {
          this.openshareQrcode = false;
          this.openCodeDialog = false;
          this.buttContent = res.data.storTel;
          this.isShowQrcodeImg = true;
          this.month_tally = this.storeInf.month_tally ? this.storeInf.month_tally : '保密';
          this.card_tally = this.storeInf.card_tally ? this.storeInf.card_tally : '保密';
          this.mem_num = this.storeInf.mem_num ? this.storeInf.mem_num : '保密';
          localStorage.setItem("lookStoreTel", res.data.myTel);
          this.$message.success("获取成功");
          // this.getXCXQrcodeImg();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //获取小程序二维码
    getXCXQrcodeImg() {
      if (!this.shareEwmImg) {
        userPcApi({ type: "createQrcode", id: this.id, exeType: "store" }).then(
          (res) => {
            if (res.code === 200) {
              this.shareEwmImg = res.data.img;
            }
          }
        );
      }
    },
    //点击查看电话号码按钮
    lookStorePhone() {
      if (this.buttContent === "联系TA") {
        commonWayPost({
          type: "storLogin",
          id: this.id,
          myTel: localStorage.getItem("lookStoreTel"),
        }).then((res) => {
          if (res.data.storTel === "0") {
            this.openCodeDialog = true;
          } else {
            // this.getXCXQrcodeImg();
            this.buttContent = res.data.storTel;
            this.month_tally = this.storeInf.month_tally ? this.storeInf.month_tally : '保密';
            this.card_tally = this.storeInf.card_tally ? this.storeInf.card_tally : '保密';
            this.mem_num = this.storeInf.mem_num ? this.storeInf.mem_num : '保密';
            this.isShowQrcodeImg = true;
            this.openshareQrcode = false;
          }
        });
      } else {
        this.isShowQrcodeImg = !this.isShowQrcodeImg;
        this.openshareQrcode = false;
      }
    },
    choseVideoOrImg(type) {
      if (type === 1) {
        this.imgIdx = 0;
        this.openVideo = false;
        this.currentImage = this.pics[0];
      } else {
        if (this.storeInf.video) {
          this.currentImage = this.storeInf.avatar;
        } else {
          return this.$message.error("暂无视频");
        }
      }
      this.isVideoOrImg = type;
    },
    amplificationImg() {
      this.$refs.imagepreview.clickHandler();
    },
    //左右滑动按钮
    exeNextImg(direction) {
      this.isVideoOrImg = 1;
      let oldImgArr = this.storeInf.pics;
      if (direction === 1) {
        if (this.imgIdx <= 0 && this.imgPage === 2) {
          this.pics = oldImgArr.slice(0, 5);
          this.imgIdx = 4;
          this.currentImage = this.pics[this.imgIdx];
          this.imgPage = 1;
        } else if (this.imgIdx <= 4 && this.imgIdx > 0) {
          this.imgIdx -= 1;
          this.currentImage = this.pics[this.imgIdx];
        }
      } else {
        if (this.imgIdx < 4 && this.imgIdx < this.pics.length - 1) {
          this.imgIdx += 1;
        } else if (this.imgIdx === 4) {
          this.imgIdx = 0;
          if (oldImgArr.length > 5) {
            this.pics = oldImgArr.slice(5, oldImgArr.length);
            this.currentImage = this.pics[0];
            this.imgPage = 2;
          } else {
            this.currentImage = this.pics[0];
          }
        }
        this.currentImage = this.pics[this.imgIdx];
      }
    },
    lookImg(url, idx) {
      if (url === "video") {
        let en = this.$refs.myVideo;
        this.openVideo = true;
        if (en.paused) {
          en.play();
        } else {
          en.pause();
        }
      } else {
        this.imgIdx = idx;
        this.openVideo = false;
        this.isVideoOrImg = 1;
        this.currentImage = url;
      }
    },
    //推荐里点击详情
    recToDetail(id) {
      this.id = id;
      this.getTransferDetail();
      window.scrollTo(0, 0);
    },
    //获取转店详情
    getTransferDetail() {
      this.buttContent = "联系TA";
      this.month_tally = "******";
      this.card_tally = "******";
      this.mem_num = "******";
      getTransferDetail(this.id)
        .then((result) => {
          result.data.info.lat = parseFloat(result.data.info.lat);
          result.data.info.lng = parseFloat(result.data.info.lng);
          this.storeInf = result.data.info;
          if (!this.storeInf.video) {
            this.isVideoOrImg = 1;
            this.currentImage = this.storeInf.pics[0];
          }
          this.pics = this.storeInf.pics;
          this.recStoreList = result.data.recList;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style>
.transfer-inf-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.top-name-uptime-look-share {
  display: flex;
  align-items: center;
  width: 1480px;
  margin: 0 auto;
  padding: 20px 0;
  justify-content: space-between;
}

.top-name-uptime-look-share .left-view {
  /*margin-left: 220px;*/
}

.top-name-uptime-look-share .left-view .breadcrumb-view {
  display: flex;
  align-items: center;
  background: #f5f6fa;
  border-radius: 16px;
  padding: 4px 12px;
  font-size: 16px;

  font-weight: 400;
  color: #757575;
  line-height: 19px;
}

.top-name-uptime-look-share .left-view .breadcrumb-view .breadcrumb-item i {
  margin: 0 16px;
}

.top-name-uptime-look-share .left-view .title {
  font-size: 36px;

  font-weight: 600;
  color: #191919;
  line-height: 42px;
  margin-top: 24px;
}

.top-name-uptime-look-share .left-view .up-look-jb-view {
  display: flex;
  align-items: center;
  margin-top: 24px;
}

.top-name-uptime-look-share .left-view .up-look-jb-view .items {
  display: flex;
  align-items: center;
  margin-right: 36px;
}

.top-name-uptime-look-share .left-view .up-look-jb-view .items img {
  width: 16px;
  height: 16px;
  margin-right: 6px;
}

.top-name-uptime-look-share .left-view .up-look-jb-view .items span {
  font-size: 14px;

  font-weight: 400;
  color: #b1b1b1;
  line-height: 16px;
}

.min-lefit-view .share-view {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: absolute;
  right: 30px;
  top: 30px;
}

.min-lefit-view .share-view img {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}

.min-lefit-view .share-view {
  font-size: 18px;

  font-weight: 400;
  color: #fe6002;
  line-height: 19px;
}

.min-banner-inf-link-view {
  display: flex;
  margin: 0 220px;
  margin-top: 20px;
}

.min-banner-inf-link-view .banner-view {
  margin: 0;
}

.min-banner-inf-link-view .banner-view .top-banner-view {
}

.min-banner-inf-link-view .banner-view .top-banner-view .video-view {
  position: relative;
}

.min-banner-inf-link-view
  .banner-view
  .top-banner-view
  .video-view
  .video-cover-img {
  width: 920px;
  height: 518px;
  margin: 0;
  padding: 0;
  border-radius: 10px;
  background-color: white;
}

.min-banner-inf-link-view .banner-view .top-banner-view .video-view .fola-view {
  width: 1px;
  height: 1px;
  position: absolute;
  top: 0;
  left: 458px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.min-banner-inf-link-view
  .banner-view
  .top-banner-view
  .video-view
  .fola-view
  .play-img {
  width: 93px;
  height: 93px;
  margin-top: 212px;
  cursor: pointer;
}

.min-banner-inf-link-view
  .banner-view
  .top-banner-view
  .video-view
  .fola-view
  .bot-change-view {
  width: 110px;
  height: 32px;
  background-color: rgba(000, 000, 000, 0.7);
  border-radius: 4px;
  font-size: 14px;

  font-weight: 400;
  color: #ffffff;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 470px;
  cursor: pointer;
}

.min-banner-inf-link-view
  .banner-view
  .top-banner-view
  .video-view
  .fola-view
  .bot-change-view
  .ischose {
  width: 44px;
  height: 24px;
  background-color: #fe6002;
  border-radius: 4px;
  text-align: center;
  line-height: 24px;
}

.bot-change-view > span:first-child {
  margin-right: 14px;
}

.min-banner-inf-link-view .banner-view .bot-scro-view {
  width: 920px;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  margin-top: 24px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  background-color: white;
}

.min-banner-inf-link-view .banner-view .bot-scro-view .scro-img {
  display: inline-block;
  width: 160px;
  height: 90px;
  margin-right: 30px;
  cursor: pointer;
}

.min-banner-inf-link-view .min-lefit-view {
  width: 435px;
  height: 582px;
  background-color: #fff;
  border-radius: 16px;
  padding: 25px 40px;
  position: relative;
}

.min-banner-inf-link-view .min-lefit-view .transfer-inf-top-sp-view {
  margin-top: 20px;
}

.min-banner-inf-link-view .min-lefit-view .transfer-inf-top-sp-view .sp1 {
  font-size: 48px;

  font-weight: 600;
  color: #fe6002;
  line-height: 56px;
}

.min-banner-inf-link-view .min-lefit-view .transfer-inf-top-sp-view .sp2 {
  font-size: 24px;

  font-weight: 600;
  color: #fe6002;
  line-height: 56px;
  margin-left: 8px;
}

.min-banner-inf-link-view .min-lefit-view .transfer-inf-top-sp-view .sp3 {
  font-size: 16px;

  font-weight: 400;
  color: #757575;
  line-height: 56px;
  margin-left: 8px;
}

.min-banner-inf-link-view .min-lefit-view .area-transfer-pr-view {
  display: flex;
  align-items: center;
  border-bottom: 2px solid #f5f6fa;
  padding: 30px 0;
}

.min-banner-inf-link-view .min-lefit-view .area-transfer-pr-view .mm-item {
  margin-right: 100px;
}

.min-banner-inf-link-view
  .min-lefit-view
  .area-transfer-pr-view
  .mm-item
  .tt1
  .ar-t1 {
  font-size: 36px;
  font-weight: 600;
  color: #222222;
  line-height: 42px;
}

.min-banner-inf-link-view
  .min-lefit-view
  .area-transfer-pr-view
  .mm-item
  .tt1
  .ar-t2 {
  font-size: 24px;

  font-weight: 600;
  color: #222222;
  line-height: 42px;
}

.min-banner-inf-link-view .min-lefit-view .area-transfer-pr-view .mm-item .tt2 {
  font-size: 16px;

  font-weight: 400;
  color: #757575;
  line-height: 19px;
  margin-top: 6px;
}

.min-banner-inf-link-view .min-lefit-view .ls-kj-vipnum {
  margin-top: 45px;
}

.min-banner-inf-link-view .min-lefit-view .ls-kj-vipnum .inf-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.min-banner-inf-link-view .min-lefit-view .ls-kj-vipnum .inf-item .lf-xian {
  width: 5px;
  height: 15px;
  background-color: #ff8136;
}

.min-banner-inf-link-view .min-lefit-view .ls-kj-vipnum .inf-item .lf-txt {
  font-size: 20px;

  font-weight: 400;
  color: #191919;
  line-height: 19px;
  margin-left: 12px;
}

.min-banner-inf-link-view .min-lefit-view .link-man-view {
  margin-top: 45px;
  position: relative;
}

.min-banner-inf-link-view .min-lefit-view .link-man-view .linman-inf-view {
  display: flex;
  align-items: center;
}

.min-banner-inf-link-view
  .min-lefit-view
  .link-man-view
  .linman-inf-view
  .link-head-img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.min-banner-inf-link-view
  .min-lefit-view
  .link-man-view
  .linman-inf-view
  .right-name-lin-add-view {
  margin-left: 18px;
}

.min-banner-inf-link-view
  .min-lefit-view
  .link-man-view
  .linman-inf-view
  .right-name-lin-add-view
  .name-sta-view {
  display: flex;
  align-items: center;
}

.min-banner-inf-link-view
  .min-lefit-view
  .link-man-view
  .linman-inf-view
  .right-name-lin-add-view
  .name-sta-view
  .name {
  font-size: 18px;

  font-weight: 400;
  color: #222222;
  line-height: 24px;
}

.min-banner-inf-link-view
  .min-lefit-view
  .link-man-view
  .linman-inf-view
  .right-name-lin-add-view
  .name-sta-view
  .dian {
  width: 6px;
  height: 6px;
  background-color: #ff8136;
  border-radius: 50%;
  margin-left: 12px;
}

.min-banner-inf-link-view
  .min-lefit-view
  .link-man-view
  .linman-inf-view
  .right-name-lin-add-view
  .name-sta-view
  .islin {
  font-size: 14px;

  font-weight: 400;
  color: #939597;
  line-height: 16px;
  margin-left: 7px;
}

.min-banner-inf-link-view
  .min-lefit-view
  .link-man-view
  .linman-inf-view
  .right-name-lin-add-view
  .add {
  font-size: 16px;

  font-weight: 400;
  color: #191919;
  line-height: 19px;
  margin-top: 16px;
}

.min-banner-inf-link-view .min-lefit-view .link-man-view .bot-btn-view {
  width: 435px;
  height: 60px;
  background-color: #fe6002;
  border-radius: 8px;
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
  line-height: 60px;
  text-align: center;
  margin-top: 40px;
  cursor: pointer;
}

/* .com-sty {
  width: 1408px;
} */

.transfer-inf-page .com-sty {
  background-color: #fff;
  margin-top: 40px;
  width: 1408px;
  /* height: 691px; */
  border-radius: 16px;
  /* margin-left: 220px; */
  padding: 36px;
}

.transfer-inf-page .com-sty .title-one {
  font-size: 32px;

  font-weight: 600;
  color: #191919;
  line-height: 38px;
}

.dpts-view .dpts-item-view {
  margin-top: 24px;
}
.dpts-view .dpts-item-view-sl {
  margin-top: 24px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.dpts-view .dpts-item-view-sl .items-sl {
  background-color: #f5f6fa;
  padding: 8px 24px;
  border-radius: 8px;
  font-size: 18px;

  font-weight: 400;
  color: #757575;
  line-height: 24px;
  margin: 0 16px 10px 0;
}

.dpjs-view .stro-dexp-view {
  white-space: pre-wrap;
  font-size: 18px;

  font-weight: 400;
  color: #191919;
  line-height: 30px;
  /* margin-top: 24px; */
  border-bottom: 4px solid #f5f6fa;
  padding: 30px 0;
  margin-bottom: 30px;
}

.dpjs-view .peitao-view {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 24px;
}

.dpjs-view .peitao-view .pt-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 60px;
  margin-bottom: 5px;
}

.dpjs-view .peitao-view .pt-item img {
  width: 60px;
  height: 60px;
}

.dpjs-view .peitao-view .pt-item .it-txt {
  font-size: 18px;

  font-weight: 400;
  color: #222222;
  line-height: 24px;
}

.com-addr .map {
  /* width: 986px;
  height: 213px; */
  margin-top: 24px;
}

.com-addr .map .amap-box {
  width: 1408px;
  height: 335px;
}

.tj-store-view {
  margin-top: 80px;
}

.tj-store-view .top-title-view {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tj-store-view .top-title-view img {
  width: 42px;
  height: 42px;
}

.tj-store-view .top-title-view .title-text {
  font-size: 32px;

  font-weight: 600;
  color: #191919;
  margin-left: 12px;
}

.tj-store-list-view {
  /* margin-left: 220px; */
  width: 1480px;
}

.tj-store-list-view .tj-transfer-store-item {
  width: 341px;
  border-radius: 8px;
  background-color: #fff;
  display: inline-block;
  margin-top: 40px;
  cursor: pointer;
}

.tj-store-list-view .tj-transfer-store-item .head-img img {
  width: 342px;
  height: 192px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.tj-store-list-view .tj-transfer-store-item .bot-store-ing {
  padding: 0 24px;
}

.tj-store-list-view .tj-transfer-store-item .bot-store-ing .store-name {
  font-size: 20px;

  font-weight: 600;
  color: #191919;
  height: 28px;
  line-height: 28px;
  width: 100%;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-top: 16px;
}

.tj-store-list-view .tj-transfer-store-item .bot-store-ing .jobtype-num-spr {
  margin-top: 13px;
  position: relative;
  padding-bottom: 20px;
}

.tj-store-list-view
  .tj-transfer-store-item
  .bot-store-ing
  .jobtype-num-spr
  .jobtype-num {
  display: inline-block;
  font-size: 14px;

  font-weight: 400;
  color: #757575;
  line-height: 16px;
}

.tj-store-list-view
  .tj-transfer-store-item
  .bot-store-ing
  .jobtype-num-spr
  .spr-view {
  font-size: 20px;

  font-weight: 600;
  color: #fe6002;
  line-height: 28px;
  position: absolute;
  right: 0;
}

.tj-store-list-view
  .tj-transfer-store-item
  .bot-store-ing
  .jobtype-num-spr
  .spr-view
  label {
  font-size: 14px;
}

.transfer-inf-page .ccard-fgx {
  width: 37px;
  display: inline-block;
}

.qrcode-image {
  height: 265px;
  width: 240px;
  background-color: white;
  position: absolute;
  top: -185px;
  left: 95px;
  border: 1px solid #d9d9d9;
}

.triangle {
  width: 20px;
  height: 20px;
  background-color: white;
  transform: rotate(45deg);
  position: absolute;
  left: 210px;
  border: 1px solid #939597;
}

.share-qrcode-image {
  height: 240px;
  width: 240px;
  background-color: white;
  position: absolute;
  top: 25px;
  left: -140px;
  border: 1px solid #d9d9d9;
}

.share-qrcode-triangle {
  width: 20px;
  height: 20px;
  background-color: white;
  transform: rotate(45deg);
  position: absolute;
  left: -35px;
  top: 15px;
  border: 2px solid #939597;
}

.slide-left-right-div {
  width: 46px;
  height: 90px;
  background: #000000;
  opacity: 0.6;
  position: absolute;
  cursor: pointer;
}

.aleft-div {
  border-radius: 8px 0 0 8px;
}

.towards-right-div {
  border-radius: 0 10px 10px 0;
  right: 0;
}

.bot-scro-view .icon-arrowhead-left {
  color: white;
  font-size: 30px;
  line-height: 90px;
  margin-left: 8px;
}

.bot-scro-view .icon-arrowhead-right {
  color: white;
  font-size: 30px;
  line-height: 90px;
  margin-left: 8px;
}

.Selected-img {
  border: 3px solid #fe6002;
}
.video-view-content {
  width: 100%;
  height: 100%;
  cursor: pointer;
  background-color: #5da5ff;
}
#qrcode-image-div {
  z-index: 1;
}
.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>