<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2024-05-22 14:42:24
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-07-24 14:49:06
 * @FilePath: /lcwpcvue/src/views/h5Page/h5FindJobPage.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="h5-findjob-list-page">
    <div class="nologin-ser">
      <div class="no-search">
        <el-input
          v-model="joblistData.search_key"
          placeholder="搜职位、公司"
          class="input-with-select"
        >
          <el-button
            @click="toSearch"
            slot="append"
            icon="el-icon-search"
            type="primary"
            >搜索</el-button
          >
        </el-input>
      </div>
    </div>
    <div class="h5-home-banner-view">
      <el-carousel height="100px">
        <el-carousel-item v-for="(item, index) in Banners" :key="index">
          <!-- <img class="" :src="item.banner_image" /> -->
          <el-image
            class="h5-home-banner-img"
            :src="item.banner_image"
            fit="fit"
          ></el-image>
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="condition">
      <div class="now-around">
        <span class="current" slot="reference" @click="isyxzw = true">{{
          jobname
        }}</span>
      </div>
      <div class="fr">
        <span @click="isCityOneShow = true">{{ cityval }}</span>
        <!-- <span>筛选</span> -->
      </div>
    </div>

    <!-- 求职端列表 -->

    <div>
      <ul v-infinite-scroll="load">
        <usercard :jobsList="jobs_List"></usercard>
      </ul>
    </div>
    <!-- 招聘方列表 -->
    <!-- <div v-if="usertype == 2">
      <user1-card
        ref="user1card"
        :jobsList="jobs_List"
        :adverts="adverts"
      ></user1-card>
    </div> -->
    <openmini></openmini>
    <utabbar activeIndex="/h5findjobpage"></utabbar>

    <h5chosezw
      :dialogVisible1="isyxzw"
      @closeDilog1="closeDilog1"
      @saveJobArr="saveYxJobArr"
    ></h5chosezw>
    <h5chosecity
      :dialogVisible2="isCityOneShow"
      @closeDilog2="closeDilog2"
      @saveJobAddr="saveJobAddrOne"
    ></h5chosecity>
  </div>
</template>
<!-- <template>
  <div class="h5-findjob-page">
    找工作 <utabbar activeIndex="/h5findjobpage"></utabbar>
  </div>
</template> -->

<script>
import utabbar from "@/components/h5Components/uTabbar.vue";
import openmini from "@/components/h5Components/h5TominPro.vue";
import { getH5BannerList } from "@/api/commen/homeApi.js";
import { getJobList } from "@/api/commen/findJobList";
import usercard from "@/components/h5Components/user-card.vue";
import h5chosezw from "@/components/h5Components/h5ChoseZwtypeCom.vue";
import h5chosecity from "@/components/h5Components/h5ChoseCityOne.vue";
export default {
  components: {
    utabbar,
    usercard,
    h5chosezw,
    h5chosecity,
    openmini,
  },
  data() {
    return {
      Banners: [],
      current: 2,
      cityval: "区域",
      jobname: "职位类别",
      jobs_List: [],
      joblistData: {
        page: 1, // 页码
        column: "new", //  recommend推荐 new最新
        provinceid: "all", // 省
        cityid: "all", // 市
        three_cityid: "all", // 区
        is_qg: 0,
        jobclass_id: "all", // 顶部期望职位
        job_id: "all", // 职业, 例: 宠物医生
        search_key: "", // 搜索内容
        edu: "all", // 教育经历
        exp: "all", // 工作经历
        type: "all", // 职位类型 全职,兼职
        min_value: "all", // 期望薪资最小值
        max_value: "all", // 期望薪资最大值
        welfares: "all", //福利筛选
      },
      allpage: 0,
      loading: false,
      isyxzw: false,
      isCityOneShow: false,
      // isInfiniteScrollDisabled: false,
    };
  },
  created() {
    if (this.$route.query.jobclassid) {
      this.joblistData.jobclass_id = this.$route.query.jobclassid;
      this.jobname = this.$route.query.name;
    }
    if (this.$route.query.keyword) {
      this.joblistData.search_key = this.$route.query.keyword;
    }
    this.getHomeBannerList();
    this.getHomeJobList();
  },
  computed: {
    noMore() {
      return this.joblistData.page >= this.allpage;
    },
    isInfiniteScrollDisabled() {
      return this.loading;
    },
  },
  methods: {
    /* 获取banner图 */
    getHomeBannerList() {
      // if (this.usertype == 1) {
      //   type = "resume";
      // } else {
      //   type = "company";
      // }
      getH5BannerList({
        type: "resume",
        position: "joblist",
        cid: "",
      })
        .then((result) => {
          console.log("获取banner", result);
          this.Banners = result.data.banners;
        })
        .catch(() => {});
    },
    getHomeJobList() {
      getJobList(this.joblistData)
        .then((result) => {
          console.log("职位列表", result);
          // this.$nextTick(function () {
          this.allpage = result.data.allpage;
          if (this.page == 1) {
            this.jobs_List = result.data.jobs;
          } else {
            this.jobs_List = this.jobs_List.concat(result.data.jobs);
          }
        })
        .catch(() => {});
    },
    load() {
      console.log("0000");
      this.loading = true;
      if (this.joblistData.page < this.allpage) {
        this.joblistData.page = this.joblistData.page + 1;
        this.getHomeJobList();
        this.loading = false;
      }
    },
    toSearch() {
      this.jobs_List = [];
      this.getHomeJobList();
    },
    closeDilog1() {
      this.isyxzw = false;
    },
    closeDilog2() {
      this.isCityOneShow = false;
    },
    saveYxJobArr(e) {
      console.log("职位", e);
      this.joblistData.page = 1;
      this.joblistData.job_id = e.keyid;
      this.joblistData.jobclass_id = e.id;
      this.jobname = e.name;
      this.jobs_List = [];
      this.getHomeJobList();
      this.isyxzw = false;
    },
    saveJobAddrOne(e) {
      console.log("城市", e);
      this.joblistData.provinceid = e.keyid;
      this.joblistData.cityid = e.id;
      this.joblistData.page = 1;
      this.jobs_List = [];
      this.cityval = e.name;
      this.getHomeJobList();

      this.isCityOneShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.h5-findjob-list-page {
  width: 100%;
  height: 100%;
  overflow: scroll;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  transition: width 0.3s ease;
  &::-webkit-scrollbar {
    display: none;
  }
}

.nologin-ser {
  width: 90%;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  padding: 30px;
  z-index: 999;

  ::v-deep .el-input-group--append .el-input__inner {
    border-top-left-radius: 80px;
    border-bottom-left-radius: 80px;
  }

  ::v-deep .el-input-group__append {
    border-top-right-radius: 80px;
    border-bottom-right-radius: 80px;
  }
}
.h5-home-banner-view {
  width: 450px;
  height: 130px;
  margin-top: 170px;
}
.h5-home-banner-view .h5-home-banner-img {
  width: 100%;
  height: 120px;
}

.scroll-tab::after {
  position: absolute;
  content: "";
  width: 25px;
  height: 40px;
  top: 0;
  right: -1px;
  color: #000000;
  box-shadow: inset -15px 0px 5px -7px #fff;
}

.job-name {
  display: inline-block;
  margin-right: 18px;
  font-size: 16px;
  font-weight: 900;
  color: #999999;
}

.active {
  color: #fe6002;
  font-size: 20px;
}

.condition {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 86%;
  height: 19px;
  line-height: 19px;
  background-color: #ffffff;
  padding: 0 16px;
  padding-top: 30px;
  padding-bottom: 22px;
}

.openotice {
  margin-top: 8px;
  height: 36px;
  line-height: 36px;
  background-color: #fff1e9;
  border-radius: 4px;
  padding: 0 15px;
  font-size: 12px;

  font-weight: 400;
  color: #fe6002;
}

.goopenotice {
  width: 52px;
  height: 48px;
  text-align: center;
  font-size: 14px;

  font-weight: 400;
  color: #ffffff;
  line-height: 48px;
  border-radius: 24px;
  background: #ffaa53;
  background: linear-gradient(123deg, #ffaa53 0%, #ff7725 100%);
}

.now-around span {
  margin-right: 32px;
  color: #222222;
  font-weight: 700;
  white-space: nowrap;
}

.now-around {
  white-space: nowrap;
  /* 	max-width: 300px;
		overflow: hidden;
		text-overflow: ellipsis; */
}

.now-around .current {
  position: relative;
  color: #fe6002;
  font-size: 20px;
  font-weight: 900;
  white-space: nowrap;
}

.now-around .current::after {
  position: absolute;
  bottom: 4px;
  right: -6px;
  content: "";
  width: 0;
  border-top: 3px solid transparent;
  border-bottom: 3px solid #fe6002;
  border-left: 3px solid transparent;
  border-right: 3px solid #fe6002;
}

.fr {
  width: 300px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.fr span {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 25px;
  line-height: 25px;
  padding: 0 14px 0 8px;
  /* border: 2px solid #C5C9CD; */
  font-size: 18px;
  color: #939597;
  font-weight: 700px;
}

.fr span:first-child {
  margin-right: 16px;
}

.fr span::after {
  position: absolute;
  bottom: 6px;
  right: 5px;
  content: "";
  width: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid #757575;
  border-left: 4px solid transparent;
  border-right: 4px solid #757575;
}

/* 选择器 */
.h5-findjob-list-page .title {
  height: 100upx;
  line-height: 88upx;
  font-size: 32upx;

  font-weight: 500;
  color: #222222;
}

.h5-findjob-list-page .city__val {
  margin: 32upx;
  width: 686upx;
  height: 298upx;
  padding: 30upx;
  background: #fff5f0;
  border-radius: 12upx;
}

.h5-findjob-list-page .city_flx {
  display: flex;
  justify-content: space-between;
  color: #777;
  font-size: 24upx;
}

.h5-findjob-list-page .city_flx .num {
  color: #ff8136;
}

.h5-findjob-list-page .city_flx text {
  font-size: 24upx;
}

.h5-findjob-list-page .sel_city {
  font-size: 32upx !important;

  font-weight: 700;
  color: #222222;
}

.h5-findjob-list-page .city__val .list {
  display: flex;
  flex-wrap: wrap;
}

.h5-findjob-list-page .city__val .list > view {
  margin-top: 24upx;
  position: relative;
  display: block;
  background-color: #ff8136;
  font-size: 28upx;
  color: #fff;
  border-radius: 4upx;
  padding: 8upx 16upx;
  margin-right: 20upx;
}

.h5-findjob-list-page .city__val .list .close {
  padding-left: 14upx;
}

.h5-findjob-list-page .province {
  background-color: #f8f8f8;
  width: 320upx;
  text-align: left;
  position: fixed;
  left: 0;
  top: 458upx;
  bottom: 120upx;
  overflow-y: auto;
}

.h5-findjob-list-page .province text {
  padding-left: 48upx;
  display: block;
  height: 100upx;
  line-height: 100upx;
  color: #666668;
  position: relative;
}

.h5-findjob-list-page .province .sf-view {
  display: flex;
  align-items: center;
}

.h5-findjob-list-page .province .num-jb {
  background-color: #fe6002;
  padding: 5upx 8upx;
  font-size: 16upx;
  color: #ffffff;
  border-radius: 45upx;
  margin-left: 8upx;
}

.h5-findjob-list-page .province .current {
  color: #000;
  background-color: #fff;
}

.h5-findjob-list-page .province text.current::after {
  display: block;
  content: "";
  background-color: #ff8136;
  width: 6upx;
  height: 36upx;
  position: absolute;
  left: 32upx;
  top: 50%;
  transform: translateY(-50%);
}

.h5-findjob-list-page .city {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  position: fixed;
  left: 320upx;
  top: 458upx;
  bottom: 120upx;
  overflow-y: auto;
  width: 430upx;
}

.h5-findjob-list-page .city text {
  display: block;
  width: 336upx;
  margin: 16upx 0 16upx 64upx;
  border: 1px solid #e7e9ec;
  color: #757575;
  text-align: center;
  height: 72upx;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4upx;
}

.h5-findjob-list-page .city text.current {
  color: #ff8136;
  border-color: #ff8136;
}

.h5-findjob-list-page .Area {
  position: fixed;
  width: calc(100% - 430upx);
  /* top: 458upx; */
  right: 0;
  bottom: 120upx;
  overflow-y: auto;
  text-align: center;
}

.h5-findjob-list-page .Area text {
  display: block;
  color: #666668;
  height: 100upx;
  display: flex;
  justify-content: center;
  align-items: center;
}

.h5-findjob-list-page .Area text.current {
  color: #ff8136;
}

.h5-findjob-list-page .foots_btn {
  background: #ffffff;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 11111;
  height: 120upx;
  padding: 0 32upx;
  padding-top: 15upx;
}

.h5-findjob-list-page .foots_btn button {
  background: #ff8136;
  color: #ffffff;
  height: 90upx;
  line-height: 90upx;
  font-size: 32upx;
}

.h5-findjob-list-page .top-100 {
  top: 100upx;
}

.h5-findjob-list-page .top-80 {
  top: 340upx;
}
</style>