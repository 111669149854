<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2024-02-28 16:02:21
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-04-25 16:30:12
 * @FilePath: /lcwpcvue/src/components/flotNavMenN.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="flot-nav-menn">
    <div class="fl-nav-1">
      <img @click="toUserHome" src="@/assets/releasejob_rz1_img.png" alt="" />
      <img @click="toChatPage" src="@/assets/releasejob_rz2_img.png" alt="" />
    </div>
    <!-- <div class="flot-min-sc-top" @click="scrollPageTop">
      <div class="sc-tp">
        <i class="el-icon-arrow-up"></i>
      </div>
      <div class="txt">TOP</div>
    </div> -->
    <el-backtop
      class="flot-min-sc-top"
      :bottom="40"
      :right="40"
      :visibility-height="50"
    >
      <div
        style="
           {
            height: 84px;
            width: 64px;
            background-color: #fff;
            text-align: center;
            color: #191919;
            border-radius: 6px;
            font-size: 30px;
            padding: 5px 1px;
          }
        "
      >
        <div class="sc-tp">
          <i class="el-icon-arrow-up"></i>
        </div>
        <div class="txt">TOP</div>
      </div>
    </el-backtop>

    <div class="flot-bot-navm">
      <el-popover placement="left" width="200" trigger="click">
        <div class="popo-content-view">
          <img src="@/assets/kefu_wx.png" class="dlo-app-img" alt="" />
          <div class="text-view">微信扫一扫，联系客服</div>
        </div>
        <div class="itm" slot="reference">客服</div>
      </el-popover>

      <div class="itm" @click="flotWtfkFun">反馈</div>
      <el-popover placement="left" width="200" trigger="click">
        <div class="itm" slot="reference">APP</div>
        <div class="popo-content-view">
          <img src="@/assets/dl_ewm.png" class="dlo-app-img" alt="" />
          <div class="text-view">微信扫一扫，下载APP</div>
        </div>
      </el-popover>
      <el-popover placement="left" width="200" trigger="click">
        <div class="popo-content-view">
          <img src="@/assets/miniprogranm_ewm.jpg" class="dlo-app-img" alt="" />
          <div class="text-view">微信扫一扫，打开小程序</div>
        </div>
        <div class="itm" slot="reference">小程序</div>
      </el-popover>
    </div>
    <chageusertype
      :changeUserTypeDialogVisible="showChoseUserType"
      @closeChoseTypePop="closeChoseTypePop"
    ></chageusertype>
  </div>
</template>

<script>
import chageusertype from "@/components/choseUserType.vue";
export default {
  components: {
    chageusertype,
  },
  data() {
    return {
      isLogin: false,
      usertype: 1,
      showChoseUserType: false,
    };
  },
  created() {
    this.isLogin = localStorage.token ? true : false;
    this.usertype = localStorage.getItem("usertype");
  },
  methods: {
    scrollPageTop() {
      this.$emit("scrollPageTop");
    },
    flotWtfkFun() {
      this.$emit("flotWtfkFun");
    },
    toUserHome() {
      if (!this.isLogin) {
        this.showChoseUserType = true;
        return false;
      }
      this.$router.push(`/userhomepage`);
    },
    toChatPage() {
      if (!this.isLogin) {
        this.showChoseUserType = true;
        return false;
      }
      this.$router.push(`/chatpage`);
    },

    closeChoseTypePop() {
      this.showChoseUserType = false;
    },
  },
};
</script>

<style>
.flot-nav-menn {
  width: 64px;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
}
.fl-nav-1 {
  background-color: #fff;
  width: 64px;
  text-align: center;
  margin-bottom: 64px;
  padding: 10px 0;
  border-radius: 6px;
}
.fl-nav-1 img {
  padding: 20px 0;
}

.flot-min-sc-top {
  /* width: 64px; */

  /* background-color: #fff;
  width: 64px;
  text-align: center;
  margin-bottom: 50px;
  padding: 10px 0;
  border-radius: 6px; */
}
.el-backtop.flot-min-sc-top {
  /* height: 64px;
  width: 64px;
  margin-left: 10px; */
  padding: 10px 0;
}

.sc-tp {
  font-size: 30px;
  color: #191919;
  font-weight: 800;
}

.flot-min-sc-top .txt {
  font-weight: 500;
  font-size: 15px;
  color: #191919;
  padding: 0 15px;
}

.flot-bot-navm {
  background-color: #fff;
  width: 64px;
  text-align: center;
  padding: 10px 0;
  border-radius: 6px;
}

.flot-bot-navm .itm {
  
  font-weight: 400;
  font-size: 14px;
  color: #191919;
  line-height: 16px;
  padding: 20px 0;
  cursor: pointer;
}
.popo-content-view {
  width: 100%;
  text-align: center;
}
.popo-content-view .dlo-app-img {
  width: 200px;
  height: 200px;
}
</style>