<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-07-18 11:15:18
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-05-14 11:51:49
 * @FilePath: /lcwpcvue/src/components/individualUser/inUserCard.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div
    class="iu-card"
    :class="isHover ? 'hover-css' : ''"
    @click="toDetailPage(jobItem.id)"
    @mouseover="hadlerMouseOver"
    @mouseout="hadlerMoseOut"
  >
    <div class="usercard-top-name-lin">
      <div class="title-sl overflow-hidden">{{ jobItem.name }}</div>
      <!-- <div class="lin-view">
        <span class="dian"></span>
        <span class="lin">在线</span>
      </div> -->
      <!--      <div class="gz-view" v-show="jobItem.minsalary !== '0'">￥{{ jobItem.minsalary }}-{{ jobItem.maxsalary }}</div>-->
      <!--      <div class="gz-view" v-show="jobItem.minsalary === '0'">￥面议</div>-->
    </div>
    <div class="salary-view">
      <div v-show="jobItem.minsalary !== '0'">
        ￥{{ jobItem.minsalary }}-{{ jobItem.maxsalary }}
      </div>
      <div v-show="jobItem.minsalary === '0'">￥面议</div>
    </div>
    <div class="add-exp-edu">
      <span>{{ jobItem.city }}</span>
      <el-divider direction="vertical"></el-divider>
      <span>{{ jobItem.exp_name }}</span>
      <el-divider direction="vertical"></el-divider>
      <span>{{ jobItem.edu_name }}</span>
    </div>
    <!-- <div class="user-ba-view">
      <span>经验足</span>
      <span>住宿无需求</span>
      <span>有提成</span>
      <span>技术好</span>
      <span>环境好</span>
    </div> -->
    <div class="bot-con-inf">
      <img class="com-head-img" src="../../assets/com_mr.png" alt="" />
      <span class="com-name overflow-hidden">{{ jobItem.com_name }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: ["jobItem"],
  data() {
    return {
      isHover: false,
    };
  },
  created() {
    console.log(this.jobItem);
  },
  methods: {
    hadlerMouseOver() {
      // console.log("悬停");
      this.isHover = true;
    },
    hadlerMoseOut() {
      this.isHover = false;
    },
    toDetailPage(id) {
      // this.$router.push(`/jobinfopage?id=${id}`);
      console.log('----------将打开新的-----------',id)
      this.$emit('updateJobDetail',id);
    },
  },
};
</script>

<style>
.iu-card {
  width: 270px;
  height: 140px;
  background-color: #fff;
  border-radius: 16px;
  padding: 12px 16px;
  display: inline-block;
  margin-top: 24px;
  /* margin-right: 29px; */
}
.iu-card .hover-css {
  border: 1px solid #fe6002;
}

.usercard-top-name-lin {
  display: flex;
  align-items: center;
  position: relative;
}

.usercard-top-name-lin .title-sl {
  font-size: 18px;
  
  font-weight: 600;
  color: #191919;
  line-height: 28px;
}
.iu-card .salary-view {
  font-size: 16px;
  
  font-weight: 600;
  color: #fe6002;
  line-height: 19px;
}
.usercard-top-name-lin .gz-view {
  font-size: 14px;
  
  font-weight: 600;
  color: #fe6002;
  line-height: 19px;
  position: absolute;
  right: 0;
}
.iu-card .add-exp-edu {
  font-size: 12px;
  
  font-weight: 400;
  color: #757575;
  line-height: 14px;
  margin-top: 16px;
}

.iu-card .user-ba-view {
  display: flex;
  align-items: center;
}
.iu-card .user-ba-view span {
  background-color: #f5f6fa;
  font-size: 12px;
  
  font-weight: 400;
  color: #757575;
  text-align: center;
  margin-right: 14px;
  padding: 4px 8px;
  margin-top: 16px;
}
.bot-con-inf {
  border-top: 2px solid #f5f6fa;
  margin-top: 20px;
  display: flex;
  align-items: center;
  padding-top: 12px;
}
.bot-con-inf .com-head-img {
  width: 30px;
  height: 30px;
}
.bot-con-inf .com-name {
  font-size: 12px;
  
  font-weight: 400;
  color: #757575;
  line-height: 14px;
  margin-left: 12px;
}
</style>