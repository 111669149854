/*
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2024-01-02 09:30:12
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-01-02 11:20:15
 * @FilePath: /lcwpcvue/src/utils/Rsa/rsa.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import JSEncrypt from "jsencrypt";
// 加密公钥
const key = `-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDOCNQJHlskf5GB/wWgEGNXqfhf
gXn+XSGJRWzLWjsdJnIctYBCDrHgwiAmC3x3mcn3xEFFORJjSl4D+7+N71JQhGL9
En4Dt2dJG2slBeaK1aLSCMMvn6nON6ansLofqj3WGKHp3/49rSs1QGBG3rYD/gJ1
fN2RqlvUMbkZuMWUUwIDAQAB
-----END PUBLIC KEY-----`


// 加密
export function rsaEncrypt(msg) {
	const jsencrypt = new JSEncrypt()
	jsencrypt.setPublicKey(key)
	const encryptMsg = jsencrypt.encrypt(msg)
	return encryptMsg
}
