<template>
  <div class="user-home-page">
    <div class="top-zk-div"></div>
    <userinfleftmen class="left-menu"></userinfleftmen>
    <comchateduser class="userinf-index"></comchateduser>
  </div>
</template>

<script>
import userinfleftmen from "@/components/userInfPageLeftMenu.vue";
import comchateduser from "@/components/comUser/comChatedUser.vue";
export default {
  components: {
    userinfleftmen,
    comchateduser,
  },
};
</script>

<style>
</style>