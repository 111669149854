<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2024-05-22 14:56:54
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-06-07 13:58:26
 * @FilePath: /lcwpcvue/src/views/h5Page/h5TakeAndTranStroList.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="h5-take-tran-stro-page">
    <div class="h5-ta-navbar-view">
      <div class="items" @click="chageTabId(1)">
        <span :class="tabId == 1 ? 'tab-sl' : ''">旺铺转让</span>
        <img v-show="tabId == 1" src="@/assets/h5img/tab_botm.png" alt="" />
      </div>
      <div class="items" @click="chageTabId(2)">
        <span :class="tabId == 2 ? 'tab-sl' : ''">开店选址</span>
        <img v-show="tabId == 2" src="@/assets/h5img/tab_botm.png" alt="" />
      </div>
    </div>
    <div style="margin-top: 100px"></div>

    <div class="dro-down">
      <div
        class="item dx"
        :class="
          cityid == '' && cityName != '全国' && provinceid == '' ? '' : 'sl'
        "
        @click="isCityOneShow = true"
      >
        <div class="txt">
          {{ cityName }}
          <i v-show="!isCityOneShow" class="el-icon-arrow-down"></i>
          <i v-show="isCityOneShow" class="el-icon-arrow-up"></i>
        </div>
      </div>
      <div class="item" :class="type != '' ? 'sl' : ''">
        <el-popover
          v-model="typePopoShow"
          placement="bottom"
          width="200"
          trigger="click"
        >
          <div class="txt" slot="reference">
            {{ typeName }}
            <i v-show="!typePopoShow" class="el-icon-arrow-down"></i>
            <i v-show="typePopoShow" class="el-icon-arrow-up"></i>
          </div>
          <div class="area-ls">
            <label v-for="(item, index) in shopTypeList" :key="index">
              <div
                @click="choseType(item)"
                class="it"
                :class="item.id == type ? 'sl' : ''"
              >
                {{ item.name }}
              </div>
            </label>
          </div>
        </el-popover>
      </div>
      <div class="item" :class="area != '' ? 'sl' : ''">
        <el-popover
          v-model="areaPopoShow"
          placement="bottom"
          width="200"
          trigger="click"
        >
          <div class="txt" slot="reference">
            {{ areaName }}
            <i v-show="!areaPopoShow" class="el-icon-arrow-down"></i>
            <i v-show="areaPopoShow" class="el-icon-arrow-up"></i>
          </div>
          <div class="area-ls">
            <label v-for="(item, index) in areaList" :key="index">
              <div
                @click="choseArea(item)"
                class="it"
                :class="item.query == area ? 'sl' : ''"
              >
                {{ item.title }}
              </div>
            </label>
          </div>
        </el-popover>
      </div>
    </div>

    <div class="zr-div" v-if="list.length > 0" v-infinite-scroll="load">
      <storetake :list.sync="list" :tabId.sync="tabId" />
    </div>

    <div class="nolist" v-if="list.length == 0">
      <img src="@/assets/h5img/zjd_nonelist.png" mode="" />
      <div class="txt">当前地区暂无店铺转让，为你推荐了附近店铺转让</div>
    </div>
    <div class="zr-view-none" v-if="list2.length > 0" v-infinite-scroll="load">
      <storetake :list.sync="list2" :tabId.sync="tabId" />
    </div>
    <!-- <u-mask
      :mask-click-able="true"
      :zoom="false"
      :show="maskShow"
      :z-index="99"
      @click="maskShow = false"
      :custom-style="{
        background: 'rgba(0, 0, 0, 0.5)',
        margin: '210px 0 0 0',
      }"
    >-->

    <!-- </u-mask> -->

    <!-- <div class="blank" style="height: 100px"></div> -->
    <div style="height: 300px"></div>
    <openmini></openmini>
    <utabbar activeIndex="/h5takeandtranstrolist"></utabbar>

    <h5chosecity
      :dialogVisible2="isCityOneShow"
      @closeDilog2="closeDilog2"
      @saveJobAddr="saveJobAddrOne"
    ></h5chosecity>
  </div>
</template>

<script>
import utabbar from "@/components/h5Components/uTabbar.vue";
import openmini from "@/components/h5Components/h5TominPro.vue";
import { getTransferList } from "@/api/commen/transferShop";
import { seekSearchNew } from "@/api/commen/takeShowList";
import storetake from "@/components/h5Components/storeTake.vue";
import h5chosecity from "@/components/h5Components/h5ChoseCityOne.vue";
import {
  getTransferTypeFil,
  getTransferAreaFil,
} from "@/api/commen/filtrateComp";
export default {
  components: {
    utabbar,
    storetake,
    h5chosecity,
    openmini,
  },
  data() {
    return {
      tabId: 1,
      list: [],
      list2: [],
      allPage: 0,
      datas: {
        size: 10,
        page: 1,
        wd: "",
        province: "",
        city: "",
        district: "",
        area: "",
        rent: "",
        transferFee: "",
        type: "",
        shopstatus: "",
      },
      provinceid: "",
      cityid: "",
      cityName: "城市",
      type: "",
      typeName: "门店类型",
      area: "",
      areaName: "面积",
      rent: "",
      isCityOneShow: false,
      shopTypeList: [],
      areaList: [],
      typePopoShow: false,
      areaPopoShow: false,
      allpage: 0,
      loading: false,
    };
  },
  computed: {
    noMore() {
      return this.joblistData.page >= this.allpage;
    },
    isInfiniteScrollDisabled() {
      return this.loading;
    },
  },
  created() {
    this.getTransferList(false);
    this.getTransferTypeFil();
    this.getTransferAreaFil();
  },
  methods: {
    chageTabId(e) {
      this.tabId = e;
      this.list = [];
      if (e == 1) {
        this.getTransferList();
      } else {
        this.getTakeList();
      }
    },

    //获取转店列表
    getTransferList(fl) {
      getTransferList(this.datas)
        .then((result) => {
          console.log("获取转店列表", result);
          // this.list = result.data.list;
          this.allpage = result.data.count;
          if (result.data.list.length > 0 && !fl) {
            if (this.datas.page == 1) {
              this.list = result.data.list;
            } else {
              this.list = this.list.concat(result.data.list);
            }
          } else if (result.data.list.length > 0 && fl) {
            this.list2 = this.list2.concat(result.data.list);
          }
          // else {
          //   if (this.cityid != "" && this.area != "" && this.type != "") {
          //     console.log("都有");
          //     this.datas.type = "";
          //     this.getTransferList(true);
          //     this.isnone = true;
          //     return;
          //   }
          //   if (this.cityid != "" && this.area != "") {
          //     this.datas.area = "";
          //     this.getTransferList(true);
          //     this.isnone = true;
          //     return;
          //   }
          //   if (this.cityid != "" && this.type != "") {
          //     console.log("城市和类型");
          //     this.datas.type = "";
          //     this.getTransferList(true);
          //     this.isnone = true;
          //     return;
          //   }
          //   if (this.type != "" && this.area != "") {
          //     this.datas.area = "";
          //     this.getTransferList(true);
          //     this.isnone = true;
          //     return;
          //   }
          //   if (this.cityid != "") {
          //     this.datas.cityid = "";
          //     console.log("走这", this.provinceid, this.cityid);
          //     this.getTransferList(true);
          //     this.isnone = true;
          //     return;
          //   }
          //   if (this.area != "") {
          //     this.datas.area = "";
          //     this.getTransferList(true);
          //     this.isnone = true;
          //     return;
          //   }
          //   if (this.type != "") {
          //     this.datas.type = "";
          //     this.getTransferList(true);
          //     this.isnone = true;
          //     return;
          //   }
          // }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    /* 获取接店列表 */
    getTakeList() {
      seekSearchNew(this.searchParams).then((req) => {
        console.log("接店", req);
        this.allpage = req.data.list.allpage;
        if (this.datas.page == 1) {
          this.list = req.data.list.list;
        } else {
          this.list = this.list.concat(req.data.list.list);
        }
      });
    },

    load() {
      console.log("0000", this.datas.page, this.allpage);
      this.loading = true;
      if (this.datas.page < this.allpage) {
        this.datas.page = this.datas.page + 1;
        if (this.tabId == 1) {
          this.getTransferList();
        } else {
          this.getTakeList();
        }
        this.loading = false;
      }
    },
    handleClick(e) {
      console.log(e);
    },
    closeDilog2() {
      this.isCityOneShow = false;
    },
    saveJobAddrOne(e) {
      console.log("城市", e);
      this.provinceid = e.keyid;
      this.cityid = e.id;
      this.datas.province = e.keyid;
      if (e.keyid != e.id) {
        this.datas.city = e.id;
      } else {
        this.datas.city = "";
      }

      this.list = [];
      this.getTransferList();
      // this.joblistData.page = 1;
      this.cityName = e.name;
      // this.getHomeJobList();

      this.isCityOneShow = false;
    },

    getTransferTypeFil() {
      getTransferTypeFil()
        .then((result) => {
          console.log("电批类型", result);

          result.data.list.unshift({
            id: "",
            name: "不限",
          });
          this.shopTypeList = result.data.list;
        })
        .catch(() => {});
    },
    getTransferAreaFil() {
      getTransferAreaFil()
        .then((result) => {
          this.areaList = result.data.area;
          // this.rentList = result.data.rent;
          // this.transferList = result.data.transferFee;
        })
        .catch(() => {});
    },
    choseType(e) {
      this.type = e.id;
      this.typeName = e.name;
      this.list = [];
      this.list2 = [];
      this.typePopoShow = false;
      this.datas.type = e.id;
      this.datas.page = 1;
      if (this.tabId == 1) {
        this.getTransferList(this.isnone);
      } else {
        this.getSeekList();
      }
    },
    choseArea(e) {
      this.area = e.query;
      this.areaName = e.title;
      this.list = [];
      this.list2 = [];
      this.datas.area = e.query;
      this.datas.page = 1;
      this.areaPopoShow = false;
      if (this.tabId == 1) {
        this.getTransferList();
      } else {
        this.getSeekList();
      }
    },
  },
};
</script>


<style lang="scss">
.h5-take-tran-stro-page {
  width: 100%;
  height: 100%;
  overflow: scroll;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  transition: width 0.3s ease;
  &::-webkit-scrollbar {
    display: none;
  }
}
.slot-wrap {
  /* 如果您想让slot内容占满整个导航栏的宽度 */
  flex: 1;
  /* 如果您想让slot内容与导航栏左右有空隙 */
  padding: 0 30px;
  background-color: #ffffff;
}

.h5-ta-navbar-view {
  width: 100%;
  display: flex;
  justify-content: start;
  background-color: #ffffff;
  padding: 20px 0;
  position: fixed;
  top: 0;
  z-index: 999;
  .items {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 30px;
    span {
      font-size: 20px;
      font-weight: 500;
    }
    img {
      width: 32px;
      height: 9px;
    }
    .tab-sl {
      font-weight: 800;
    }
  }
}
.h5-take-tran-stro-page {
  .top {
    display: flex;
    position: relative;
    align-items: center;
    padding: 120px 0 30px 0;

    .tab-bar {
      display: flex;
      align-items: flex-end;

      .items {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 48px;

        .txt {
          font-size: 32px;
          color: #939597;
          font-weight: 800;
        }

        .chose {
          font-size: 36px;
          color: #222222;
          font-weight: 800;
        }

        image {
          width: 52px;
          height: 14px;
          margin-top: 4px;
        }
      }
    }
  }

  /* #ifdef APP */
  .search-view {
    width: 132px;
    height: 52px;
    background-color: #f5f6fa;
    border-radius: 26px;
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;

    image {
      width: 24px;
      height: 26px;
      margin-left: 24px;
    }

    .text {
      font-size: 24px;
      color: #222222;
      margin-left: 12px;
      font-weight: 400;
    }
  }

  /* #endif */
  /* #ifdef MP-WEIXIN */
  .search-view {
    width: 132px;
    height: 52px;
    background-color: #f5f6fa;
    border-radius: 26px;
    display: flex;
    align-items: center;
    position: absolute;
    right: 60px;

    image {
      width: 24px;
      height: 26px;
      margin-left: 24px;
    }

    .text {
      font-size: 12px;
      color: #222222;
      margin-left: 12px;
      font-weight: 400;
    }
  }

  /* #endif */
}

.dro-down {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  padding-bottom: 7px;
  padding-top: 10px;
  width: 480px;
  padding-left: 55px;
  position: fixed;
  top: 69px;
  z-index: 99;

  .item {
    display: flex;
    align-items: center;
    height: 25px;
    margin-right: 49px;
    font-size: 19px;
    color: #939597;
    font-weight: 500;
    white-space: nowrap;

    img {
      width: 12px;
      height: 14px;
      margin-left: 4px;
    }

    .adr {
      width: 8px;
      height: 8px;
      position: relative;
      margin-bottom: -20px;
    }
  }

  .sl {
    font-size: 19px;
    color: #fe6002;
    font-weight: 500;
  }
}

.zr-view {
  margin: 120px 0px 0 0px;
}

.zr-view-none {
  margin: 10px 0 0 0;
}

.fb-view {
  position: fixed;
  display: flex;
  justify-content: center;
  bottom: 158px;
  width: 750px;
  text-align: center;

  .btn {
    width: 276px;
    height: 96px;
    background: linear-gradient(132deg, #ff911b 0%, #ff5c15 100%);
    border-radius: 48px;
    border: 2px solid #ffffff;
    color: #ffffff;
    display: flex;
    justify-content: center;

    .jh {
      font-size: 46px;

      font-weight: 700;
      line-height: 96px;
    }

    span {
      font-size: 36px;

      font-weight: 500;
      line-height: 96px;
      margin-left: 12px;
    }
  }
}

.nolist {
  margin-top: 100px;
  margin-left: 32px;
  display: flex;
  align-items: center;
  padding-top: 80px;
  padding-bottom: 60px;

  img {
    width: 12px;
    height: 12px;
  }

  .txt {
    font-size: 12px;
    color: #b1b1b1;
    margin-left: 6px;
  }
}

.area-ls {
  background-color: #ffffff;
  padding-top: 15px;

  .it {
    padding: 10px 16px;
    margin: 0 10px;
    color: #999999;
    font-size: 14px;
    position: relative;
    display: flex;
    align-items: center;

    .chose-g {
      width: 16px;
      height: 16px;
      position: absolute;
      right: 16px;
      font-weight: 400;
    }
  }
  .it:first-child {
    margin-top: 0px;
  }

  .sl {
    color: #fe6002;
    font-weight: 500;
  }
}
</style>