<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-05-22 17:53:53
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-06-07 17:46:29
 * @FilePath: /lcwpcvue/src/components/choseUserType.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <el-dialog
      :visible.sync="changeUserTypeDialogVisible"
      width="40%"
      center
      @open="dialogOpne"
      @close="dialogClose"
      :destroy-on-close="true"
    >
      <div class="dial">
        <div
          class="type-item"
          @mouseover="mousov1"
          @mouseleave="mousle1"
          :class="mouse1 ? 'heover' : ''"
        >
          <img src="../assets/login/ch_usertype1.png" alt="" />
          <el-button @click="setUserType(1)" class="bot-btn bt1"
            >我要找工作</el-button
          >
        </div>
        <div style="width: 117px"></div>
        <div
          class="type-item"
          @mouseover="mousov2"
          @mouseleave="mousle2"
          :class="mouse2 ? 'heover' : ''"
        >
          <img src="../assets/login/ch_usertype.png" alt="" />
          <el-button @click="setUserType(2)" class="bot-btn bt2"
            >我要招人才</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import store from "@/store";
export default {
  props: {
    changeUserTypeDialogVisible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      // centerDialogVisible: true,
      mouse1: false,
      mouse2: false,
    };
  },

  methods: {
    setUserType(type) {
      // this.$store.dispatch("setIsAutnenticated", !this.isEmpty(decoded));
      // this.$store.dispatch("setUser", decoded)
      // console.log(store.dispatch());
      this.$store.dispatch("user/setUserType", type);
      this.$cookies.set("usertype", type);
      localStorage.setItem("usertype", type);
      // this.$store.state.user;
      this.changeUserTypeDialogVisible = false;
      this.$router.push("/login");
    },
    dialogOpne() {
      console.log("open");
    },
    dialogClose() {
      console.log("close");
      this.$emit("closeChoseTypePop");
    },

    mousov1() {
      this.mouse1 = true;
    },
    mousov2() {
      this.mouse2 = true;
    },
    mousle1() {
      this.mouse1 = false;
    },
    mousle2() {
      this.mouse2 = false;
    },
  },
};
</script>

<style scoped>
.dial {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 0;
}
.type-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  width: 320px;
  height: 375px;
  border: 1px solid#FF8136;
  box-shadow: 0px 8px 16px -1px rgba(175, 180, 197, 0.16);
  border-radius: 8px;
}

.type-item img {
  width: 264px;
  height: 235px;
}

.type-item .bot-btn {
  width: 135px;
  height: 45px;
  font-size: 20px;
  
  font-weight: 500;
  color: #ffffff;
  border: none;
  margin-top: 51px;
}
.type-item .bt1 {
  background: linear-gradient(132deg, #ffbe4d 0%, #ff8942 100%);
  border-radius: 4px;
}

.type-item .bt2 {
  background: linear-gradient(133deg, #7cc3ff 0%, #5da5ff 100%);
  border-radius: 4px;
}

.heover {
  background-color: #fff5f0;
}
</style>