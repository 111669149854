<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-12-18 13:43:05
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2023-12-18 17:18:07
 * @FilePath: /lcwpcvue/src/components/comUser/couponList.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="coupon-list-page">
    <div class="coupon-navbar-view">
      <div class="nav-item" @click="changeNavBarId(1)">
        <div class="nav-text" :class="navbarId == 1 ? 'nav-sltext' : ''">
          未使用（{{ num1 }}）
        </div>
        <div v-show="navbarId == 1" class="bot-xian"></div>
      </div>
      <div class="nav-item" @click="changeNavBarId(2)">
        <div class="nav-text" :class="navbarId == 2 ? 'nav-sltext' : ''">
          已使用（{{ num2 }}）
        </div>
        <div v-show="navbarId == 2" class="bot-xian"></div>
      </div>
      <div class="nav-item" @click="changeNavBarId(3)">
        <div class="nav-text" :class="navbarId == 3 ? 'nav-sltext' : ''">
          已过期（{{ num3 }}）
        </div>
        <div v-show="navbarId == 3" class="bot-xian"></div>
      </div>
    </div>
    <div class="coupon-list-view">
      <span v-for="item in couponListArr" :key="item.id">
        <div class="coupon-item">
          <img
            class="coupon-bg-img"
            src="@/assets/coupon_list_bg_img.png"
            alt=""
          />
          <div class="coupon-text-view">
            <div class="coupon-mz-text">
              <div class="tt1"><label>￥</label>{{ item.amount }}</div>
              <div v-show="item.scope == 0" class="tt2">无门槛使用</div>
              <div v-show="item.scope > 0" class="tt2">
                满{{ item.scope }}元使用
              </div>
            </div>
            <div class="coupon-title-view">
              <div class="title">{{ item.name }}</div>
              <div class="time">有效期至{{ item.end_time }}</div>
            </div>
            <div class="right-btn">
              <el-button v-show="navbarId == 1" type="primary" round size="mini" @click="quUseCoupon"
                >立即使用</el-button
              >
              <el-button
                v-show="navbarId == 2"
                type="info"
                round
                size="mini"
                disabled
                >已使用</el-button
              >
              <el-button
                v-show="navbarId == 3"
                type="info"
                round
                size="mini"
                disabled
                >已过期</el-button
              >
            </div>
          </div>
        </div>
      </span>
    </div>
    <div v-show="couponListArr.length == 0" class="nocoupon-list-view">
      <img class="none-list-img" src="@/assets/coupon_none_list.png" alt="" />
      <div class="none-text">当前暂无优惠券</div>
    </div>
  </div>
</template>

<script>
import { getMyCouponList } from "@/api/compe/couponApi.js";
export default {
  name: "couponlist",
  data() {
    return {
      navbarId: 1,
      couponListArr: [],
      num1: 0,
      num2: 0,
      num3: 0,
    };
  },
  created() {
    this.getMyCouponList(this.navbarId);
  },
  methods: {
    quUseCoupon(){
      this.$router.push("/vippaypage");
    },
    changeNavBarId(id) {
      this.navbarId = id;
      this.getMyCouponList(id);
    },
    /* 获取优惠券列表 */
    getMyCouponList(st) {
      getMyCouponList({ status: st })
        .then((result) => {
          console.log("获取我的优惠券", result);
          this.num1 = result.data.num1;
          this.num2 = result.data.num2;
          this.num3 = result.data.num3;
          this.couponListArr = result.data.list;
        })
        .catch(() => {});
    },
  },
};
</script>

<style>
.coupon-navbar-view {
  width: 1196px;
  height: 68px;
  background-color: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  align-items: center;
}
.coupon-navbar-view .nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 135px;
  margin-left: 24px;
  height: 68px;
  position: relative;
  cursor: pointer;
}
.coupon-navbar-view .nav-item .nav-text {
  font-size: 22px;
  
  font-weight: 400;
  color: #303030;
  line-height: 28px;
}
.coupon-navbar-view .nav-item .nav-sltext {
  color: #fe6002;
  font-weight: 600;
}
.coupon-navbar-view .nav-item .bot-xian {
  width: 50px;
  height: 4px;
  background-color: #fe6002;
  position: absolute;
  bottom: 0;
  border-radius: 4px;
}
.coupon-list-view {
  margin-top: 28px;
  padding: 0 24px;
}
.coupon-list-view .coupon-item {
  width: 480px;
  height: 120px;
  display: inline-block;
  position: relative;
  margin-top: 24px;
  margin-right: 60px;
}
.coupon-list-view .coupon-item .coupon-bg-img {
  width: 480px;
  height: 120px;
}
.coupon-list-view .coupon-item .coupon-text-view {
  width: 480px;
  height: 120px;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
}
.coupon-list-view .coupon-item .coupon-mz-text {
  width: 150px;
  text-align: center;
  margin-left: 10px;
}
.coupon-list-view .coupon-item .coupon-mz-text .tt1 {
  font-size: 44px;
  
  font-weight: 600;
  color: #f34b37;
  line-height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.coupon-list-view .coupon-item .coupon-mz-text .tt1 label {
  font-size: 20px;
  
  font-weight: 500;
  color: #f34b37;
  line-height: 23px;
}
.coupon-list-view .coupon-item .coupon-mz-text .tt2 {
  font-size: 12px;
  
  font-weight: 400;
  color: #222222;
  line-height: 14px;
  margin-top: 4px;
}
.coupon-list-view .coupon-item .coupon-text-view .coupon-title-view {
  margin-left: 20px;
}

.coupon-list-view .coupon-item .coupon-text-view .coupon-title-view .title {
  font-size: 18px;
  
  font-weight: 600;
  color: #222222;
  line-height: 28px;
}
.coupon-list-view .coupon-item .coupon-text-view .coupon-title-view .time {
  font-size: 14px;
  
  font-weight: 400;
  color: #757575;
  line-height: 16px;
  margin-top: 5px;
}
.coupon-list-view .coupon-item .right-btn {
  position: absolute;
  right: 35px;
}

.coupon-list-view .coupon-item .right-btn {
}
.nocoupon-list-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 240px;
}
.nocoupon-list-view .none-list-img {
  width: 260px;
  height: 208px;
}
.nocoupon-list-view .none-text {
  font-size: 18px;
  
  font-weight: 400;
  color: #939597;
  line-height: 24px;
  margin-top: 12px;
}
</style>