/*
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2024-05-07 14:44:16
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-05-07 14:46:16
 * @FilePath: /lcwpcvue/src/api/indiviuser/companyList.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'
/**
* 获取公司列表
* @url company/companyList
* @param {} params 
* @return 
*/
export function getCompanyList(params) {
  return request({
    url: 'company/companyList',
    method: 'post',
    data: params,
  })
}

/**
 * 获取公司列表 V2
 * @url company/companyList
 * @param {} params
 * @return
 */
export function getV2CompanyList(params) {
  return request({
    url: 'v2/company/companyList',
    method: 'post',
    data: params,
  })
}