/*
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-12-18 15:56:59
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2023-12-18 16:08:50
 * @FilePath: /lcwpcvue/src/api/compe/couponApi.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'

/**
 * 获取我的优惠券列表
 * @param {status//优惠券状态} params 
 * @return 
 */
export function getMyCouponList(params) {
  return request({
    url: 'coupon/mylist',
    method: 'get',
    params: params,
  })
}
