/*
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-06-06 14:48:43
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-05-14 17:28:13
 * @FilePath: /lcwpcvue/src/api/indiviuser/userInf.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'

//注册个人信息
export function postResumeBasInf(params) {
  return request({
    url: 'resume/postResumeResult',
    method: 'post',
    data: params,
  })
}

//修改个人信息
export function editResumBasInf(params) {
  return request({
    url: 'resume/updateMyResume',
    method: 'post',
    data: params
  })
}

//获取个人简历详情
export function getResumInf(params) {
  return request({
    url: 'resume/index',
    method: 'post',
    data: params
  })
}
//获取简历待完善项目
export function getResumScoreInf(params) {
  return request({
    url: 'resume/ResumeScore',
    method: 'post',
    data: params
  })
}

//获取学历列表
export function getUserClass(params) {
  return request({
    url: 'Userclass/getUserClass',
    method: 'post',
    data: params
  })
}
//获取基本信息resume/myResumeV2
export function getUserBaseInf() {
  return request({
    url: 'resume/myResumeV2',
    method: 'post'
  })
}

// //上传保存头像
// export function postUserAvatarImg(params) {
//   return request({
//     url: 'resume/updateMyResume',
//     method: 'post',
//     params: params
//   })
// }

//编辑保存个人基本信息
export function updateUserBaseInf(params) {
  return request({
    url: 'resume/updateMyResume',
    method: 'post',
    data: params
  })
}

//求职意向expect/expectIndex
export function getUserExpectInf() {
  return request({
    url: 'expect/expectIndex',
    method: 'post'
  })
}


//获取求职状态
export function getUserQzStatus() {
  return request({
    url: 'Userclass/getUserClassV2',
    method: 'post'
  })
}

//更新求职状态
export function updateQzStatus(params) {
  return request({
    url: 'Userclass/editUserClassV2',
    method: 'post',
    data: params
  })
}


/**
* 添加求职意向&更新求职意向
* @url resume/editResumeExpect
* @param {cid,pageurl} params 
* @return 
*/
// export function addQzYxInf(params) {
//   return request({
//     url: 'resume/editResumeExpect',
//     method: 'post',
//     data: params

//   })
// }


//更新求职意向
export function updateQzYxInf(params) {
  return request({
    url: 'resume/editResumeExpect',
    method: 'post',
    data: params

  })
}


//获取热门标签&获取已选择的标签
export function getUserJnHotTag() {
  return request({
    url: 'resume/getSkillTag',
    method: 'get',
  })
}

//添加个人标签
export function addJnTag(params) {
  return request({
    url: 'resume/postResumeSkillTag',
    method: 'post',
    data: params

  })
}

//删除已选的标签
export function delJnTag(params) {
  return request({
    url: 'resume/postResumeSkillTag',
    method: 'post',
    data: params

  })
}
//添加工作经验resume/addResumeWork

export function addJobExperience(params) {
  return request({
    url: 'resume/addResumeWork',
    method: 'post',
    data: params
  })
}

//编辑工作经验resume/editResumeWork
export function editJobExperience(params) {
  return request({
    url: 'resume/editResumeWork',
    method: 'post',
    data: params
  })
}

//工作经历检索公司名称Shield/searchCompany
export function expSearchCompany(params) {
  return request({
    url: 'Shield/searchCompany',
    method: 'post',
    params: params
  })
}

//添加工作经验resume/addResumeEdu
export function addUserEdu(params) {
  return request({
    url: 'resume/addResumeEdu',
    method: 'post',
    data: params
  })
}


//编辑工作经验resume/editResumeEdu
export function editUserEdu(params) {
  return request({
    url: 'resume/editResumeEdu',
    method: 'post',
    data: params
  })
}

//教育经历检索学校名称resume/searchSchool
export function eduScnameSearch(params) {
  return request({
    url: 'resume/searchSchool',
    method: 'get',
    params: params
  })
}

//添加培训经历resume/addResumeTraining
export function addPxExp(params) {
  return request({
    url: 'resume/addResumeTraining',
    method: 'post',
    data: params
  })
}

//编辑培训经历resume/editResumeTraining
export function editPxExp(params) {
  return request({
    url: 'resume/editResumeTraining',
    method: 'post',
    data: params
  })
}

//添加技能证书resume/addSkill
export function addJnzs(params) {
  return request({
    url: 'resume/addSkill',
    method: 'post',
    data: params
  })
}

//编辑技能证书resume/editSkill
export function editJnzs(params) {
  return request({
    url: 'resume/editSkill',
    method: 'post',
    data: params
  })
}
//删除工作经历/教育经历/培训经历/技能证书resume/delResumeDetail
export function delResumeOthere(params) {
  return request({
    url: 'resume/delResumeDetail',
    method: 'post',
    data: params
  })
}

//添加作品
export function editShow(picArr) {
  return request({
    url: 'resume/editShow',
    method: 'post',
    data: {
      picurl: picArr
    }
  })
}

//系统信息
export function getSysPush(params) {
  return request({
    url: 'Wschat/sysPush',
    method: 'GET',
    params: params
  })
}

//系统信息
export function refreshResumeV2() {
  return request({
    url: 'resume/refreshResumeV2',
    method: 'POST'
  })
}

/**
* 获取简历优化价格
* @url Rating/getOptimizePrice
* @param {} params 
* @return 
*/
export function getOptimizePrice() {
  return request({
    url: 'Rating/getOptimizePrice',
    method: 'get'
  })
}

/**
* 获取简历置顶价格
* @url Resurating/top
* @param {} params 
* @return 
*/
export function getTopPrice() {
  return request({
    url: 'Resurating/top',
    method: 'post'
  })
}
/**
* 简历代投提交
* @url resume/expectAssistSend
* @param {} params 
* @return 
*/
export function postAssistSend() {
  return request({
    url: 'resume/expectAssistSend',
    method: 'post'
  })
}

/**
* 支付接口
* @url Resurating/resumeOrderByPc
* @param {} params 
* @return 
*/

export function postOrder(params) {
  return request({
    url: 'Resurating/resumeOrderByPc',
    method: 'post',
    data: params
  })
}

/**
* 提交简历优化
* @url Sundries/commonWay
* @param {} params 
* @return 
*/

export function postCommonWay(params) {
  return request({
    url: 'Sundries/commonWay',
    method: 'post',
    data: params
  })
}

export function echoCity() {
  return request({
    url: 'v2/resume/echoCity',
    method: 'get'
  })
}