/*
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2020-08-28 15:20:13
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-07-24 15:58:30
 * @FilePath: /lcwpcvue/src/router/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
// import cookies from 'vue-cookies'
// import store from "@/store";
import Index from '../views/Index.vue'
// import Nofind from '../views/404.vue';
import Login from '../views/login.vue';
import Home from '../views/home.vue';
import Comregist from '../views/comregist.vue';
import Job from '../views/job.vue';
import FindJobList from '../views/findJobList.vue';
import FindTalentsList from '../views/findTalentsList.vue';
import PartTimeJobList from '@/views/partTimeJobList.vue';
import TransferShopList from '@/views/transferShopList.vue';
import TakeShopList from '@/views/takeShopList.vue';
import JobInfoPage from '@/views/jobInfoPage.vue';
import TransferDetail from '@/views/transferDetail.vue';
import UserHomePage from '@/views/userHomePage.vue';
import WxScGdPage from '@/views/wxScGdPage.vue';
import PublishTransffer from '@/views/publishTransffer.vue';
import TalentsDetail from '@/views/talentsDetail.vue';
import TakeShopDetail from '@/views/takeShopDetail.vue';
import ReleaseJob from '@/views/releaseJob.vue';
import BusinessAuth from '@/views/businessAuth.vue';
import RegistRelJob from '@/views/registRelJob.vue';
import RegistByPhone from '@/views/registByPhone.vue';
import ForgetPass from '@/views/vForgetPass.vue';
import UserRegistBasInf from '@/views/userRegistBasInf.vue';
import AddJobYx from '@/views/addJobYx.vue';
import EduExperience from '@/views/eduExperience.vue';
import JobExperience from '@/views/jobExperience.vue';
import WxLoginEwm from '@/views/wxLoginEwm';
import publishTake from "@/views/publishTake.vue";
import UserProfile from '@/views/userProfile.vue';
import CompanyInfDetail from '@/views/companyInfDetail.vue';
import MyEquityPage from '@/views/myEquityPage.vue';
import ResumeManage from '@/views/resumeManage.vue';
import JobManage from '@/views/jobManage.vue';
import MyTransfer from '@/views/myTransfer.vue';
import MySubAccount from '@/views/mySubAccount.vue';
import ChatPage from '@/views/chatPage.vue';
import AddressManage from '@/views/addressManage.vue';
import MyCouponPage from '@/views/myCouponPage.vue';
import MyOrderPage from '@/views/myOrderPage.vue';
import HelpFkPage from '@/views/helpFkPage.vue';
import SetPage from '@/views/setPage.vue';
import VipPayPage from '@/views/vipPayPage.vue';
import UserEdtResume from '@/views/userEdtResume.vue';
import Privacy from "@/views/privacy.vue";
import JobReport from '@/views/jobReport.vue';
import CompanyInf from '@/views/companyInf.vue';
import CompanyChatedUser from '@/views/companyChatedUser.vue';
import CompanyCollectionUser from '@/views/companyCollectionUser.vue';
import WaitingInterview from '@/views/waitingInterview.vue';
import PartTimeJobDetail from '@/views/partTimeJobDetail.vue';
import AboutUs from "@/views/aboutUs.vue";
import JobInformation from "@/views/jobInformation.vue";
import CompanyList from '@/views/companyList.vue';
import FromBackstage from '@/views/fromBackstage.vue';
import InformationList from '@/views/informationList.vue';
import InformationDetail from '@/views/informationDetail.vue';
import transfer from "@/views/transfer.vue";
import CompanyInteraction from '@/views/companyInteraction.vue';

/* H5页面 */
import H5HomePage from '@/views/h5Page/h5HomePage.vue';
import H5FindJobPage from '@/views/h5Page/h5FindJobPage.vue';
import H5TakeAndTranStroList from '@/views/h5Page/h5TakeAndTranStroList.vue';
import H5JobDetail from '@/views/h5Page/h5JobDetail.vue';
import H5Cominfo from '@/views/h5Page/h5Cominfo.vue';
import H5TranDetail from '@/views/h5Page/h5TranDetail.vue';
import H5FindTalentList from '@/views/h5Page/h5FindTalentList.vue';
import H5TakeDetail from '@/views/h5Page/h5TakeDetail.vue';
import H5Information from '@/views/h5Page/h5Information.vue';
// import Nofind from '@/views/404.vue';

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, resolve, reject) {
  if (resolve || reject) return originalPush.call(this, location, resolve, reject)
  return originalPush.call(this, location).catch(e => e)
}

Vue.use(VueRouter)



const routes = [
  {
    path: '/',
    redirect: '/home',
    meta: {
      title: ''
    }
  },
  {
    path: '/index',
    name: 'index',
    component: Index,
    meta: {
      title: ''
    },
    children: [
      {
        path: '',
        component: Home,
        meta: {
          title: '猎宠网（宠物人才网）petzp.com',
          keywords: '宠物人才网,宠物人才,宠物招聘网,宠物美容师招聘,猎宠网,上海宠物人才网,宠物医生招聘,宠物医生找工作,宠物店转让,宠物店接店，找店面，宠物美容师',
        }
      },
      {
        path: '/home',
        name: 'home',
        component: Home,
        meta: {
          title: '猎宠网（宠物人才网）petzp.com',
          keywords: '宠物人才网,宠物人才,宠物招聘网,宠物美容师招聘,猎宠网,上海宠物人才网,宠物医生招聘,宠物医生找工作,宠物店转让,宠物店接店，找店面，宠物美容师'
        }
      },
      {
        path: '/aboutUs',
        name: 'aboutUs',
        component: AboutUs,
        meta: {
          title: '猎宠网（宠物人才网）petzp.com'
        }
      },
      {
        path: '/findjob',
        name: 'findjob',
        component: FindJobList,
        meta: {
          title: '猎宠网招聘信息|招聘 - ,宠物人才网猎宠网',
          keywords: '猎宠网,招聘,招聘信息- '
        }
      },
      {
        path: '/findtalentslist',
        name: 'findtalentslist',
        component: FindTalentsList,
        meta: {
          title: '猎宠网（Petzp.com）宠物人才网,宠物美容师、宠物医生招聘求职网站人才列表-人才列表',
          keywords: ',人才列表'
        }
      },
      {
        path: '/parttimejoblist',
        name: 'parttimejoblist',
        component: PartTimeJobList,
        meta: {
          title: '猎宠网（Petzp.com）宠物人才网,宠物美容师、宠物医生招聘求职网站猎宠网-兼职',
          keywords: '猎宠网，兼职'
        }
      },
      {
        path: '/transfershoplist',
        name: 'transfershoplist',
        component: TransferShopList,
        meta: {
          title: '猎宠网（Petzp.com）宠物店转店-猎宠网',
          keywords: '宠物店转店,宠物店转让-猎宠网'
        }
      },
      {
        path: '/takeshoplist',
        name: 'takeshoplist',
        component: TakeShopList,
        meta: {
          title: '猎宠网宠物店接店-猎宠网',
          keywords: '接店-猎宠网'
        }
      },
      {
        path: '/jobinfopage',
        name: 'jobinfopage',
        component: JobInfoPage
      },
      {
        path: '/transferdetail',
        name: 'transferdetail',
        component: TransferDetail
      },
      {
        path: '/userhomepage',
        name: 'userhomepage',
        component: UserHomePage,
      },
      {
        path: '/publishtransffer',
        name: 'publishtransffer',
        component: PublishTransffer
      },
      {
        path: '/publishtake',
        name: 'publishtake',
        component: publishTake
      },
      {
        path: '/talentsdetail',
        name: 'talentsdetail',
        component: TalentsDetail
      },
      {
        path: '/takeshopdetail',
        name: 'takeshopdetail',
        component: TakeShopDetail
      },
      {
        path: '/releasejob',
        name: 'releasejob',
        component: ReleaseJob
      },
      {
        path: '/companyinfdetail',
        name: 'companyinfdetail',
        component: CompanyInfDetail
      },
      {
        path: '/myequitypage',
        name: 'myequitypage',
        component: MyEquityPage
      },
      {
        path: '/resumemanage',
        name: 'resumemanage',
        component: ResumeManage
      },
      {
        path: '/jobmanage',
        name: 'jobmanage',
        component: JobManage
      },
      {
        path: '/mytransfer',
        name: 'mytransfer',
        component: MyTransfer
      },
      {
        path: '/mysubaccount',
        name: 'mysubaccount',
        component: MySubAccount
      },
      {
        path: '/chatpage',
        name: 'chatpage',
        component: ChatPage
      }, {
        path: '/addressmanage',
        name: 'addressmanage',
        component: AddressManage
      },
      {
        path: '/mycouponpage',
        name: 'mycouponpage',
        component: MyCouponPage
      },
      {
        path: '/myorderpage',
        name: 'myorderpage',
        component: MyOrderPage
      },
      {
        path: '/helpfkPage',
        name: 'helpfkPage',
        component: HelpFkPage
      },
      {
        path: '/setpage',
        name: 'setpage',
        component: SetPage
      },
      {
        path: '/vippaypage',
        name: 'vippaypage',
        component: VipPayPage
      },
      {
        path: '/useredtresume',
        name: 'useredtresume',
        component: UserEdtResume
      },
      {
        path: '/privacy',
        name: 'Privacy',
        component: Privacy
      },
      {
        path: '/jobInformation',
        name: 'JobInformation',
        component: JobInformation
      },
      {
        path: '/jobreport',
        name: 'jobreport',
        component: JobReport
      },
      {
        path: '/companyinf',
        name: 'companyinf',
        component: CompanyInf
      },
      {
        path: '/companychateduser',
        name: 'companychateduser',
        component: CompanyChatedUser
      },
      {
        path: '/companycollectionuser',
        name: 'companycollectionuser',
        component: CompanyCollectionUser
      },
      {
        path: '/waitinginterview',
        name: 'waitinginterview',
        component: WaitingInterview
      },
      {
        path: '/parttimejobdetail',
        name: 'parttimejobdetail',
        component: PartTimeJobDetail
      },
      {
        path: '/companylist',
        name: 'companylist',
        component: CompanyList
      },
      {
        path: '/informationlist',
        name: 'informationlist',
        component: InformationList
      },
      {
        path: '/informationdetail',
        name: 'informationdetail',
        component: InformationDetail
      },
      {
        path: '/companyinteraction',
        name: 'companyinteraction',
        component: CompanyInteraction
      },

      //CompanyList


    ]

  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/comregist',
    name: 'comregist',
    component: Comregist
  },
  {
    path: '/businessauth',
    name: 'businessauth',
    component: BusinessAuth
  },
  {
    path: '/registreljob',
    name: 'registreljob',
    component: RegistRelJob
  },
  {
    path: '/job',
    name: 'job',
    component: Job
  },
  {
    path: '/registbyphone',
    name: 'registbyphone',
    component: RegistByPhone
  },

  {
    path: '/wxloginld',
    name: 'wxloginld',
    component: WxScGdPage
  }, {
    path: '/forgetpass',
    name: 'forgetpass',
    component: ForgetPass
  },
  {
    path: '/userregistbasinf',
    name: 'userregistbasinf',
    component: UserRegistBasInf
  },
  {
    path: '/addjobyx',
    name: 'addjobyx',
    component: AddJobYx
  },
  {
    path: '/eduexperience',
    name: 'eduexperience',
    component: EduExperience
  },
  {
    path: '/jobexperience',
    name: 'jobexperience',
    component: JobExperience
  },
  {
    path: '/wxsclogin',
    name: 'wxsclogin',
    component: WxLoginEwm
  },
  {
    path: '/userprofile',
    name: 'userprofile',
    component: UserProfile
  },
  {
    path: '/frombackstage',
    name: 'frombackstage',
    component: FromBackstage
  },
  {
    path: '/transfer',
    name: 'transfer',
    component: transfer
  },
  {
    path: '/h5homepage',
    name: 'h5homepage',
    component: H5HomePage
  },
  {
    path: '/h5findjobpage',
    name: 'h5findjobpage',
    component: H5FindJobPage
  },
  {
    path: '/h5takeandtranstrolist',
    name: 'h5takeandtranstrolist',
    component: H5TakeAndTranStroList
  },
  {
    path: '/h5jobdetail',
    name: 'h5jobdetail',
    component: H5JobDetail
  },
  {
    path: '/h5cominfo',
    name: 'h5cominfo',
    component: H5Cominfo
  },
  {
    path: '/h5trandetail',
    name: 'h5trandetail',
    component: H5TranDetail
  },
  {
    path: '/h5findtalentlist',
    name: 'h5findtalentlist',
    component: H5FindTalentList
  },
  {
    path: '/h5takedetail',
    name: 'h5takedetail',
    component: H5TakeDetail
  },
  {
    path: '/h5information',
    name: 'h5information',
    component: H5Information
  },
  {
    path: "*",
    name: "/",
    component: Home,
  },
  // 
  // H5TakeDetail H5Information
  /*  {
      path: '*',
      redirect: '/home',
      component: Home,
    },*/



]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.afterEach(() => {
  // 使用Vue的$nextTick方法确保DOM已经更新完成
  Vue.nextTick(() => {
    // 使用原生JavaScript方法滚动到页面顶部
    window.scrollTo(0, 0);
  });
});

// 添加路由守卫
router.beforeEach((to, from, next) => {
  const isLogin = localStorage.getItem('token') ? true : false;

  if (to.path == "/login") {
    next();
  } else if (to.path == "/home") {
    next();
  } else if (to.path == "/findtalentslist") {
    next();
  } else if (to.path == "/findjob" || to.path == '/jobinfopage') {
    next();
  } else if (to.path == "/transferdetail") {
    next();
  } else if (to.path == "/transfershoplist" || to.path == "/publishtransffer") {
    next();
  } else if (to.path == "/takeshoplist" || to.path == '/takeshopdetail' || to.path == '/publishtake') {
    next();
  } else if (to.path == "/wxloginld") {
    next();
  } else if (to.path == "/registbyphone") {
    next();
  } else if (to.path == "/comregist") {
    next();
  } else if (to.path == "/businessauth") {
    next();
  } else if (to.path == "/registreljob") {
    next();
  } else if (to.path == "/forgetpass") {
    next();
  } else if (to.path == "/wxsclogin") {
    next();
  } else if (to.path == "/userprofile") {
    next();
  } else if (to.path == "/talentsdetail") {
    next();
  } else if (to.path == "/userregistbasinf") {
    next();
  } else if (to.path == "/eduexperience") {
    next();
  } else if (to.path == "/companyinf") {
    next();
  } else if (to.path == "/parttimejoblist" || to.path == '/parttimejobdetail') {
    next();
  } else if (to.path == "/aboutUs") {
    next();
  } else if (to.path == "/companylist") {
    next();
  } else if (to.path == "/frombackstage") {
    next();
  } else if (to.path == "/informationlist" || to.path == "/informationdetail") {
    next();
  } else if (to.path == "/h5homepage" || to.path == "/h5findjobpage" || to.path == "/h5takeandtranstrolist" || to.path == "/h5jobdetail" || to.path == "/h5cominfo" || to.path == "/h5trandetail" || to.path == '/h5findtalentlist' || to.path == '/h5takedetail' || to.path == '/h5information') {
    next();
  } else if (to.path == '/transfer') {
    next();
  } else {
    if (isLogin) {
      console.log('111');
      next()
    } else {
      console.log('222');
      next("/login")
    }
    // isLogin ? next() : next("/login")
  }
  let title = (to.meta.title ? to.meta.title : '猎宠网（petzp.com）宠物人才网'),
    keywords = (to.meta.keywords ? to.meta.keywords : '猎宠网（petzp.com）宠物人才网')
  document.title = title
  document.keywords = keywords

})

export default router