<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-06-01 10:07:40
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-06-20 17:34:04
 * @FilePath: /lcwpcvue/src/components/choseHy.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="chose-city">
    <el-dialog
      title="期望行业"
      :visible="dialogVisible3"
      width="1003px"
      @close="handleClose"
    >
      <div class="citys-ychose">
        <!-- <span class="yx-view">已选择</span> -->
        <span class="tsmin">添加期望行业关键词，投递的简历会更匹配哦～</span>
        <!-- <div class="yxct">
          <span>山东省</span>
          <i class="el-icon-close"></i>
        </div> -->
      </div>

      <div class="chose-hy">
        <label v-for="item in hyList" :key="item.id">
          <div
            class="last"
            :class="hyId == item.id ? 'arasl' : ''"
            @click="choseJobHy(item)"
          >
            {{ item.name }}
          </div>
        </label>
        <!-- <div class="last">全部</div>
        <div class="last arasl">南城区</div>
        <div class="last">南城区</div>
        <div class="last">南城区</div>
        <div class="last">南城区</div>
        <div class="last">南城区</div>
        <div class="last">南城区</div> -->
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getHyList } from "@/api/commen/register";

export default {
  name: "jobhy",
  props: {
    dialogVisible3: {
      type: Boolean,
      default: false,
    },
    hyIdF: {
      type: String,
    },
  },
  data() {
    return {
      hyList: {},
      // hyId: "",
    };
  },
  computed: {
    hyId: {
      get() {
        return this.hyIdF;
      },
      set(newValue) {
        this.$emit("update:hyIdF", newValue);
      },
    },
  },
  created() {
    this.getHyList();
  },
  methods: {
    //获取行业列表
    getHyList() {
      getHyList()
        .then((result) => {
          console.log("获取行业列表", result);
          this.hyList = result.data.hy_list;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    choseJobHy(item) {
      this.hyId = item.id;
      this.$emit("changeJobHy", item);
    },
    handleClose() {
      this.$emit("closeDilog3");
    },
  },
};
</script>

<style scoped>
.chose-city .el-dialog__wrapper .el-dialog {
  border-radius: 15px;
  height: 639px;
}

.el-dialog__wrapper .el-dialog .el-dialog__title {
  font-size: 26px;

  font-weight: 600;
  color: #222222;
}
.el-dialog__wrapper .el-dialog__body {
  padding: 0px 0;
}
.citys-ychose {
  display: flex;
  align-items: flex-end;
  border-bottom: 1px solid #dddddd;
  padding: 0 20px;
  padding-bottom: 18px;
  margin-top: 22px;
}

.chose-hy {
  margin-left: 30px;
}

.chose-hy .last {
  display: inline-block;
  width: 157px;
  height: 45px;
  background: #f5f6fa;
  border-radius: 1px;
  text-align: center;
  line-height: 45px;
  margin-right: 35px;
  margin-top: 24px;
  margin-bottom: 9px;
  cursor: pointer;
}
.chose-hy .last:hover {
  background-color: rgba(255, 129, 54, 0.15);
  color: #fe6002;
}
.chose-hy .last:nth-child(5n) {
  margin-right: 0;
}

.chose-hy .arasl {
  background-color: rgba(255, 129, 54, 0.15);
  color: #fe6002;
  border: 1px solid #fe6002;
}
</style>