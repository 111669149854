<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-11-15 17:18:24
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-07-09 18:39:36
 * @FilePath: /lcwpcvue/src/components/comUser/companyInfDetail.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="company-page">
    <div class="comindex-left-view">
      <div class="common-white company-base-inf" v-show="!baseEditShow">
        <img class="user-avatar-img" :src="companyInf.info.logo" alt="" />
        <div class="name-type-num-are">
          <div class="name">
            <span>{{ companyInf.info.name.substring(0,18) }}</span>
            <span v-show="companyInf.info.name.length > 18">...</span>
            <img :src="companyInf.info.yyzz_status_icon" alt="" />
          </div>
          <div class="type-num-are">
            <span>{{ companyInf.info.hy_name }}</span>
            <el-divider direction="vertical"></el-divider>
            <span>{{ companyInf.info.mun_name }}</span>
            <el-divider
              v-if="companyInf.info.area != ''"
              direction="vertical"
            ></el-divider>
            <span v-show="companyInf.info.area != ''"
              >店面{{ companyInf.info.area }}m²</span
            >
          </div>
        </div>
        <div class="right-edit-view" @click="baseEditShow = true">
          <!-- <img src="../../assets/user_resume_edit.png" alt="" />
          <span>编辑</span> -->
          <el-button type="text" icon="el-icon-edit-outline">编辑</el-button>
        </div>
      </div>
      <div class="common-white companyinf-view" v-show="!baseEditShow">
        <div class="common-title-view">
          <span class="title">公司信息</span>
          <span class="tag">必填</span>
        </div>
        <div class="company-inf-view">
          <div class="inf-item">
            <img src="../../assets/company/cominfo-home.png" alt="" />
            <span class="tt1">店铺名称：</span>
            <span class="tt2">{{ companyInf.info.alias }}</span>
          </div>
          <div class="inf-item">
            <img src="../../assets/company/cominfo-time.png" alt="" />
            <span class="tt1">成立时间：</span>
            <span class="tt2" v-show="companyInf.info.sdate != ''">{{
              companyInf.info.sdate
            }}</span>
            <span
              class="tt2"
              v-show="companyInf.info.sdate == ''"
              style="color: #fe6002"
              >未填写</span
            >
          </div>
          <div class="inf-item">
            <img src="../../assets/company/cominfo-address.png" alt="" />
            <span class="tt1">公司地址：</span>
            <span class="tt2">{{ companyInf.info.address }}</span>
          </div>
        </div>
        <div class="right-edit-view" @click="baseEditShow = true">
          <!-- <img src="../../assets/user_resume_edit.png" />
          <span>编辑</span> -->
          <el-button type="text" icon="el-icon-edit-outline">编辑</el-button>
        </div>
      </div>

      <div class="edi-baseinf-view" v-show="baseEditShow">
        <div class="topo-title-view">基础信息</div>
        <div class="edit-view">
          <el-form label-position="left" ref="">
            <div class="userava-up-view">
              <zczheardimg
                class="avatar-uploader"
                :lookImgPath="baseObj.logo"
                @success-img-path="successimgpath"
              />
              <!-- <el-upload
                class="avatar-uploader"
                :action="uploadUrl"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
              >
                <div style="width: 300px; height: 60px"></div>
              </el-upload> -->
              <img class="avatar" style="width:80px" />
              <div class="avatr-up-text">
                <div class="tt1">企业LOGO</div>
                <div class="tt2" style="color: #fe6002">
                  上传logo提升企业形象，吸引更多优秀人才
                </div>
              </div>
            </div>

            <div class="qzzt-edi-sl-view">
              <div class="edi-lin-item">
                <el-form-item label="">
                  <div class="items">
                    <div class="title">
                      <span>公司全称</span>
                      <!-- <img style="width: 45px;height:15px;margin-left: 5px;" v-if="companyInf.info.yyzz_status=='1'" :src="companyInf.info.yyzz_status_icon"  /> -->
                      <span
                        style="
                          display: inline-flex;
                          align-items: center;
                          font-size: 10px;
                          color: #fe6002;
                          margin-left: 10px;
                        "
                        v-if="companyInf.info.yyzz_status === '1'"
                      >
                        <img
                          style="width: 10px; height: 11px; margin-right: 3px"
                          src="@/assets/img/h1.png"
                          alt=""
                        />
                        <span>已认证</span>
                      </span>
                    </div>
                    <!-- <el-input v-model="baseObj.name"></el-input>-->
                    <el-input
                      v-model="baseObj.name"
                      placeholder="请输入内容"
                      clearable
                      suffix-icon="el-icon-arrow-right"
                      @click="authDialogShow = true"
                    >
                    </el-input>
                    <div
                      class="zd-ingp-view"
                      @click="authDialogShow = true"
                    ></div>
                  </div>
                </el-form-item>
                <el-form-item label="">
                  <div class="items">
                    <div class="title"><span>店铺名称</span></div>
                    <el-input v-model="baseObj.alias"></el-input>
                  </div>
                </el-form-item>
              </div>

              <div class="edi-lin-item">
                <el-form-item label="">
                  <div class="items" @click="isCompanyHy = true">
                    <div class="title">所属行业</div>
                    <el-input
                      v-model="baseObj.hy_name"
                      placeholder="请输入内容"
                      clearable
                      suffix-icon="el-icon-arrow-right"
                    >
                    </el-input>
                    <div class="zd-ingp-view"></div>
                  </div>
                </el-form-item>
                <el-form-item label="">
                  <div class="items">
                    <div class="title"><span>公司规模</span></div>
                    <el-select
                      v-model="baseObj.mun_name"
                      placeholder="请选择规模"
                      @visible-change="getCompanyPepleNum"
                      @change="xlChose"
                    >
                      <el-option
                        v-for="item in gmChoseArr"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                  </div>
                </el-form-item>
              </div>

              <div class="edi-lin-item">
                <el-form-item label="">
                  <div class="items">
                    <div class="title">成立时间</div>
                    <el-date-picker
                      v-model="baseObj.sdate"
                      type="month"
                      value-format="yyyy-MM"
                      placeholder="选择月"
                    >
                    </el-date-picker>
                  </div>
                </el-form-item>
                <el-form-item label="">
                  <div class="items">
                    <div class="title"><span>店铺面积</span></div>
                    <el-input v-model="baseObj.area"></el-input>
                  </div>
                </el-form-item>
              </div>

              <div class="edi-one-item">
                <el-form-item label="">
                  <div class="items" @click="addressDialogShow = true">
                    <div class="title">公司地址</div>
                    <el-input
                      v-model="baseObj.address"
                      placeholder="请选择公司地址"
                      clearable
                      suffix-icon="el-icon-arrow-right"
                    >
                    </el-input>
                    <div class="zd-ingp-view"></div>
                  </div>
                </el-form-item>
              </div>
            </div>
            <el-form-item class="yx-el-for-it" style="text-align: right">
              <el-button
                class="btn btn-save"
                type="primary"
                @click="updateCompanyInfo"
                >保存</el-button
              >
              <el-button class="btn btn-center" @click="baseEditShow = false"
                >取消</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>

      <div class="common-white link-view" v-show="!linkEditShow">
        <div class="common-title-view">
          <span class="title">联系方式</span>
          <span class="tag">待完善</span>
        </div>
        <div class="link-inf-view">
          <div class="link-flex">
            <div class="company-inf-view">
              <div class="inf-item">
                <span class="tt1">联系人：</span>
                <span class="tt2">{{ companyInf.link.linkman }}</span>
              </div>
              <div class="inf-item">
                <span class="tt1">职&emsp;位：</span>
                <span class="tt2">{{ companyInf.link.work_name }}</span>
              </div>
            </div>
            <div class="company-inf-view">
              <div class="inf-item">
                <span class="tt1">电&emsp;&emsp;话：</span>
                <span class="tt2">{{ companyInf.link.linktel }}</span>
              </div>
              <div class="inf-item">
                <span class="tt1">微&emsp;&emsp;信：</span>
                <span class="tt2" v-show="companyInf.link.wxnum != ''">{{
                  companyInf.link.wxnum
                }}</span>
                <span
                  class="tt2"
                  style="color: #fe6002"
                  v-show="companyInf.link.wxnum == ''"
                  >未填写</span
                >
              </div>
              <div class="inf-item">
                <span class="tt1">备用电话：</span>
                <span class="tt2" v-show="companyInf.link.byphone != ''">{{
                  companyInf.link.byphone
                }}</span>
                <span
                  class="tt2"
                  style="color: #fe6002"
                  v-show="companyInf.link.byphone == ''"
                  >未填写</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="right-edit-view" @click="linkEditShow = true">
          <!-- <img src="../../assets/user_resume_edit.png" alt="" />
          <span>编辑</span> -->
          <el-button type="text" icon="el-icon-edit-outline">编辑</el-button>
        </div>
      </div>

      <div class="edi-other-nowr-view" v-show="linkEditShow">
        <div class="topo-title-view">
          <div class="com-title-view">
            <span class="title-text">联系方式</span>
          </div>
        </div>
        <div class="edit-view">
          <el-form label-position="left">
            <div class="edi-lin-item">
              <el-form-item label="">
                <div class="items">
                  <div class="title"><span>联系人</span></div>
                  <el-input v-model="linkObj.linkman"></el-input>
                </div>
              </el-form-item>
              <el-form-item label="">
                <div class="items">
                  <div class="title"><span>职位</span></div>
                  <el-select
                    v-model="linkObj.link_work"
                    placeholder="请选择职位"
                    @visible-change="getWorkIdToLinkInf"
                    @change="xlChose"
                  >
                    <el-option
                      v-for="item in linkWorkIdArr"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </div>
              </el-form-item>
            </div>

            <div class="edi-lin-item">
              <el-form-item label="">
                <div class="items" @click="changePhoneDialogShow('tel')">
                  <div class="title"><span>电话</span></div>
                  <!-- <el-input v-model="linkObj.linktel"></el-input> -->
                  <el-input
                    v-model="linkObj.linktel"
                    placeholder="请输入内容"
                    clearable
                    suffix-icon="el-icon-arrow-right"
                  >
                  </el-input>
                  <div class="zd-ingp-view"></div>
                </div>
              </el-form-item>
              <el-form-item label="">
                <div class="items">
                  <div class="title"><span>微信</span></div>
                  <el-input v-model="linkObj.wxnum"></el-input>
                </div>
              </el-form-item>
            </div>
            <div class="edi-lin-item">
              <el-form-item label="">
                <div class="items" @click="changePhoneDialogShow('bytel')">
                  <div class="title"><span>备用电话</span></div>
                  <el-input
                    v-model="linkObj.byphone"
                    placeholder="请输入内容"
                    clearable
                    suffix-icon="el-icon-arrow-right"
                  >
                  </el-input>
                  <div class="zd-ingp-view"></div>
                </div>
              </el-form-item>
            </div>

            <el-form-item class="yx-el-for-it" style="text-align: right">
              <el-button
                @click="updateCompanyLink"
                class="btn btn-save"
                type="primary"
                >保存</el-button
              >
              <el-button @click="linkEditShow = false" class="btn btn-center"
                >取消</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>

      <div class="companyinf-big-title-view">
        <span class="xian"></span>
        <span>详细信息</span>
      </div>
      <div class="common-white company-detail-view">
        <div class="common-title-view">
          <span class="title">公司环境</span>
        </div>
        <div
          @click="workErpEditShow = true"
          class="right-edit-view"
          v-show="!workErpEditShow"
        >
          <img src="../../assets/user_resume_edit.png" alt="" />
          <span>编辑</span>
        </div>
        <div class="work-erp-view">
          <!--          显示视频-->
          <div
            @click="isOpenVideo = true"
            v-show="videoUrl"
            class="el-image"
            style="
              width: 100px;
              height: 100px;
              border: #e6e6e6 solid 1px;
              margin: 0 20px 20px 0;
              cursor: pointer;
            "
          >
            <img
              src="@/assets/transfer_spplay.png"
              style="
                width: 40px;
                height: 40px;
                position: absolute;
                left: 30px;
                top: 30px;
              "
            />
            <img
              @click.stop="delWorkImg(index, 'video')"
              v-show="workErpEditShow"
              class="colse-img-video"
              src="@/assets/com_index_del_img.png"
              alt=""
            />
            <img :src="doorPhoto" style="width: 100%; height: 100%" />
          </div>
          <!--          显示图片-->
          <label v-for="(item, index) in workErpImg" :key="index">
            <div class="com-work-img">
              <el-image
                style="
                  border: #e6e6e6 1px solid;
                  width: 100px;
                  height: 100px;
                  margin: 0 20px 20px 0;
                "
                :src="item"
                :preview-src-list="workErpImg"
              >
              </el-image>
              <img
                @click="delWorkImg(index, item.type)"
                v-show="workErpEditShow"
                class="colse-img"
                src="@/assets/com_index_del_img.png"
                alt=""
              />
            </div>
          </label>
          <el-upload
            :show-file-list="false"
            v-show="workErpEditShow"
            class="el-upload-view"
            :action="uploadUrl"
            multiple
            :auto-upload="true"
            list-type="picture-card"
            :on-remove="handleRemove"
            :on-success="handleWorkImgSuccess"
            style="margin-top: 10px"
            :before-upload="beforeWorkImgUpload"
            :on-exceed="uploadhandleExceed"
            ref="workimgupload"
            :limit="9"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </div>

        <div class="yx-el-for-it" v-show="workErpEditShow">
          <el-button
            @click="submitWorkErpImg"
            class="btn btn-save"
            type="primary"
            >保存</el-button
          >
          <el-button class="btn btn-center" @click="unfollowUpload"
            >取消</el-button
          >
        </div>
      </div>
      <div class="common-white company-detail-view" v-show="!comflEditShow">
        <div class="common-title-view">
          <span class="title">公司福利</span>
        </div>
        <div class="company-fl-view">
          <label
            v-for="(item, index) in companyInf.welfare.welfare_list"
            :key="index"
          >
            <span>{{ item }}</span>
          </label>
        </div>

        <div class="right-edit-view" @click="showComFl">
          <!-- <img src="../../assets/user_resume_edit.png" alt="" />
          <span>编辑</span> -->
          <el-button type="text" icon="el-icon-edit-outline">编辑</el-button>
        </div>
      </div>

      <div class="edi-other-nowr-view" v-show="comflEditShow">
        <div class="topo-title-view">
          <div class="com-title-view">
            <span class="title-text">公司福利</span>
          </div>
        </div>
        <div class="edit-view">
          <el-form label-position="left">
            <div class="sl-bq-btn">
              <label
                v-for="(item, index) in companyInf.welfare.welfare_list"
                :key="index"
              >
                <div class="sel-jn-tag">
                  <span>{{ item }}</span
                  ><i class="el-icon-close" @click="delComWelf(index)"></i>
                </div>
              </label>
              <div class="addbq-bt" @click="addCompanyWei">+ 添加</div>
            </div>

            <div class="tt3">热门标签：</div>
            <div class="hot-bq-view">
              <label v-for="(item, index) in hotComWeifArr" :key="index">
                <div
                  v-show="item.status == 0"
                  class="hotbq-item"
                  :class="item.check ? 'hotbq-item-sl' : ''"
                  @click="choseComWeif(item, index)"
                >
                  {{ item.name }}
                </div>
              </label>
            </div>
            <el-form-item class="yx-el-for-it" style="text-align: right">
              <el-button
                @click="updateCompanyWelfare"
                class="btn btn-save"
                type="primary"
                >保存</el-button
              >
              <el-button @click="comflEditShow = false" class="btn btn-center"
                >取消</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>

      <div class="common-white company-detail-view" v-show="!comdespEditShow">
        <div class="common-title-view">
          <span class="title">公司介绍</span>
        </div>
        <div class="company-desp-view">
          <span>{{ companyInf.content.content }}</span>
        </div>

        <div class="right-edit-view" @click="comdespEditShow = true">
          <img src="../../assets/user_resume_edit.png" alt="" />
          <span>编辑</span>
        </div>
      </div>

      <div class="edi-other-nowr-view" v-show="comdespEditShow">
        <div class="topo-title-view">
          <div class="com-title-view">
            <span class="title-text">公司介绍</span>
          </div>
        </div>
        <div class="edit-view">
          <el-form label-position="left">
            <div class="texterea-view">
              <el-form-item>
                <el-input
                  type="textarea"
                  placeholder="描述一下你培训的专业及课程内容"
                  rows="5"
                  maxlength="500"
                  v-model="companyContent"
                >
                </el-input>
                <div class="tips-text-view">
                  <span class="tp-text">建议填写字数在20个字以上</span>
                  <span class="text-num">0/500</span>
                </div>
              </el-form-item>
            </div>

            <el-form-item class="yx-el-for-it" style="text-align: right">
              <el-button
                class="btn btn-save"
                type="primary"
                @click="updateCompanyContent"
                >保存</el-button
              >
              <el-button class="btn btn-center" @click="comdespEditShow = false"
                >取消</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <div class="comindex-right-view">
      <div class="right-btn-view">
        <el-button @click="toCompanyYl" type="primary" plain
          >预览公司主页</el-button
        >
      </div>
      <companyqy class="companyqy"></companyqy>
      <zpbb class="zpbb"></zpbb>
    </div>

    <chosehy
      :dialogVisible3="isCompanyHy"
      @closeDilog3="closeDilog3"
      @changeJobHy="changeCompayHy"
    ></chosehy>
    <choseaddress
      @closeAddresPop="closeAddresPop"
      :addressDialogShow="addressDialogShow"
      @submitAddress="submitAddress"
    ></choseaddress>
    <el-dialog
      :title="phoneType == 'tel' ? '修改联系电话' : '修改备用电话'"
      :visible.sync="sedCoedDialogShow"
      width="30%"
      :lose-on-click-modal="false"
    >
      <div class="send-phone-code-dialog">
        <el-form
          status-icon
          :rules="rules"
          ref="ruleForm"
          class="demo-ruleForm"
        >
          <el-form-item class="input-view" prop="moblie">
            <el-input
              placeholder="请输入手机号"
              type="text"
              v-model="changePhone"
              clearable
            >
            </el-input>
          </el-form-item>

          <el-form-item class="input-view code-view" label="">
            <!-- <el-input
            type="password"
            v-model="ruleForm.pass"
            autocomplete="off"
            style="border: none"
          ></el-input> -->
            <el-input
              v-model="phoneNumCode"
              type="text"
              placeholder="请输入验证码"
            >
              <i
                slot="suffix"
                class="el-icon-circle-close codeclear"
                @click="phoneNumCode = ''"
              ></i>
            </el-input>
            <el-col class="code-x"></el-col>
            <el-button v-show="!smsFlag" type="text" @click="sendCode"
              >发送验证码</el-button
            >
            <el-button
              v-show="smsFlag"
              type="text"
              @click="sendCode"
              disabled
              >{{ sendTime }}</el-button
            >
          </el-form-item>
          <el-form-item class="sub-btn">
            <el-button type="primary" @click="updateLinkPhone">确定</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>

    <div class="video-open">
      <el-dialog
        :visible.sync="isOpenVideo"
        :show-close="false"
        :before-close="closeVideo"
        v-if="isOpenVideo"
      >
        <div style="height: 650px; display: flex; justify-content: center">
          <video
            controls
            style="max-height: 650px; min-width: 400px"
            ref="videoPlayer"
          >
            <source :src="videoUrl" type="video/mp4" />
            您的浏览器不支持 video 标签。
          </video>
        </div>
      </el-dialog>
    </div>

    <businessauthdialog
      v-if="authDialogShow"
      :authDialogShow="authDialogShow"
      @closeAuthPop="claseDia"
    ></businessauthdialog>
  </div>
</template>

<script>
import {
  getCompanyInf,
  getCompanyPepleNum,
  updateCompanyInfo,
  updateCompanyLink,
  getBusinessWeifare,
  updateCompanyWelfare,
  addCompanyWelfare,
  updateCompanyContent,
  submitCompanyWorkImg,
  getWorkIdToLinkInf,
  checkPhoneIsHave,
  updateByphone,
  // updateLinkPhone,
  // unbindPhoneNum,
  updateLinkPhoneV2,
} from "@/api/compe/companyInf.js";
import { sendPhoneCode } from "@/api/commen/login";
import { Loading, Message } from "element-ui";
import choseaddress from "@/components/choseAddressDialog.vue";
import zpbb from "@/components/comUser/zpbb.vue";
import companyqy from "@/components/comUser/rightMyQy.vue";
import chosehy from "@/components/choseHy.vue";
import businessauthdialog from "@/components/comUser/businessAuthInInfDialog.vue";

export default {
  components: {
    zpbb,
    companyqy,
    chosehy,
    choseaddress,
    businessauthdialog,
  },
  data() {
    return {
      isOpenVideo: false,
      uploadUrl: process.env.VUE_APP_UPLOUD + "/api/upload/uploadCos",
      companyInf: {},
      baseEditShow: false,
      linkEditShow: false,
      imgEditShow: false,
      comflEditShow: false,
      comdespEditShow: false,
      isCompanyHy: false,
      workErpEditShow: false,
      gmChoseArr: [],
      hotComWeifArr: [],
      baseObj: {
        logo: "", // 公司logo
        name: "", // 公司全称
        alias: "",
        hy: "", // 行业id
        hy_name: "",
        mun: "", // 人员规模
        mun_name: "",
        linkman: "", // 联系人
        sdate: "",
        address: "",
        provinceid: "",
        cityid: "",
        three_cityid: "",
        x: "",
        y: "",
        uid: "",
        obj: "",
        area: "",
        addr_id: "",
      },
      linkObj: {
        link_img: "",
        linkman: "",
        linktel: "",
        wxnum: "",
        link_work: "",
        byphone: "",
        isCheckPhone: 1,
        code: "",
      },
      companyContent: "",
      addressDialogShow: false,
      workErpImg: [],
      doorPhoto: "",
      videoUrl: "",
      edWelffareArr: [],
      linkWorkIdArr: [],
      sedCoedDialogShow: false,
      smsFlag: false,
      sendTime: "重新获取",
      snsMsgWait: 60,
      rules: {},
      changePhone: "",
      phoneNumCode: "",
      phoneType: "tel",
      loadingInstance: "",
      authDialogShow: false,
      linkWorkId: "",
    };
  },
  created() {
    this.getCompanyInf();
  },
  methods: {
    claseDia() {
      this.authDialogShow = false;
    },
    closeVideo() {
      const video = this.$refs.videoPlayer;
      if (video) {
        video.pause();
        video.currentTime = 0; // 可选，将视频 currentTime 重置为 0
        video.addEventListener("ended", function () {
          this.src = "";
        });
      }
      this.isOpenVideo = false;
    },
    //获取公司详细信息
    getCompanyInf() {
      this.workErpImg = [];
      getCompanyInf()
        .then((result) => {
          console.log("公司详细信息", result);
          this.companyInf = result.data;
          this.baseObj.logo = result.data.info.logo;
          this.baseObj.name = result.data.info.name;
          this.baseObj.alias = result.data.info.alias;
          this.baseObj.hy = result.data.info.hy;
          this.baseObj.hy_name = result.data.info.hy_name;
          this.baseObj.mun = result.data.info.mun;
          this.baseObj.sdate = result.data.info.sdate;
          this.baseObj.area = result.data.info.area;
          this.baseObj.uid = result.data.uid;
          this.baseObj.address = result.data.info.address;
          this.baseObj.mun_name = result.data.info.mun_name;
          this.companyContent = result.data.content.content;
          this.linkObj.linkman = result.data.link.linkman;
          this.linkObj.linktel = result.data.link.linktel;
          this.linkObj.wxnum = result.data.link.wxnum;
          this.linkObj.link_work = result.data.link.work_name;
          this.linkWorkId = result.data.link.work_id;
          this.linkObj.byphone = result.data.link.byphone;
          result.data.show.allshow.forEach((element) => {
            if (element != result.data.show.video) {
              this.workErpImg.push(element);
            }
          });
          if (result.data.show.video != "") {
            this.doorPhoto = result.data.show.video_cover;
            this.videoUrl = result.data.show.video;
          }
        })
        .catch(() => {});
    },
    //获取公司规模列表
    getCompanyPepleNum() {
      getCompanyPepleNum({ name: "job_mun" })
        .then((result) => {
          console.log("公司规模", result);
          this.gmChoseArr = result.data.list.job_mun;
        })
        .catch(() => {});
    },
    /* 获取公司职位列表 */
    getWorkIdToLinkInf() {
      getWorkIdToLinkInf()
        .then((result) => {
          console.log("获取公司职位", result);
          this.linkWorkIdArr = result.data.list;
        })
        .catch(() => {});
    },
    changeCompayHy(e) {
      console.log(e);
      this.isCompanyHy = false;
      this.baseObj.hy = e.id;
      this.baseObj.hy_name = e.name;
    },
    handleAvatarSuccess(e) {
      this.baseObj.logo = process.env.VUE_APP_IMG + e.data.url.substring(1);
    },
    successimgpath(e) {
      this.baseObj.logo = e.data.httpCosUrl;
    },
    beforeWorkImgUpload(file) {
      this.loadingInstance = Loading.service({ background: "rgba(0,0,0,0.1)" });
      let fileType = file.type;
      let typeArr = ["image/jpeg", "image/png", "video/mp4"];
      if (!typeArr.includes(fileType)) {
        this.$message.error("媒体类型异常");
        return false;
      }
      let astrictNum = 5;
      let isMp4 = file.name.endsWith(".mp4");
      if (isMp4) {
        astrictNum = 50;
      }
      let isLt2M = file.size / 1024 / 1024 > astrictNum;
      if (isLt2M) {
        this.$message.error("媒体超过限制大小5M");
        return false;
      }
      if (this.workErpImg.length > 9) {
        console.log("超出限制....");
        this.$message.error("最多可上传9张图片");
        return false;
      }
      if (this.videoUrl && fileType === "video/mp4") {
        this.$message.error("最多可上传一个视频");
        return false;
      }
      return true;
    },
    unfollowUpload() {
      this.workErpEditShow = false;
      this.getCompanyInf();
    },
    uploadhandleExceed() {
      this.$message.warning("超出最大上传范围！");
    },
    handleWorkImgSuccess(e) {
      this.loadingInstance.close();
      if (e.code === 0) {
        return this.$message.error(e.msg);
      }
      if (e.data.httpsCover === "") {
        this.workErpImg.push(e.data.httpCosUrl);
      } else {
        this.doorPhoto = e.data.httpsCover;
        this.videoUrl = e.data.httpCosUrl;
      }
    },

    /* 更新公司环境照片 */
    submitWorkErpImg() {
      var list = [];
      if (this.videoUrl) {
        list.push(this.videoUrl);
      }
      this.workErpImg.forEach((element) => {
        list.push(element);
      });
      submitCompanyWorkImg({
        door_photo: this.doorPhoto,
        video: this.videoUrl,
        show: String(list),
      })
        .then((result) => {
          console.log("保存环境图片", result);
          this.getCompanyInf();
          this.workErpEditShow = false;
          if (result.code == 200) {
            this.$message({
              message: "上传成功！",
              type: "success",
              center: true,
            });
          }
        })
        .catch(() => {});
    },

    //更新公司信息
    updateCompanyInfo() {
      updateCompanyInfo(this.baseObj)
        .then((result) => {
          console.log("更新公司信息", result);
          this.baseEditShow = false;
          if (result.code == 200) {
            this.$message({
              message: "修改成功！",
              type: "success",
              center: true,
            });
            this.getCompanyInf();
            this.$store.dispatch("user/setInfo").then(() => {
              // location.reload();
            });
          }
        })
        .catch(() => {});
    },

    //更新联系方式
    updateCompanyLink() {
      this.linkObj.code = this.phoneNumCode;
      this.linkObj.byphone = "";
      // this.linkObj.link_work = this.linkWorkId;
      updateCompanyLink(this.linkObj)
        .then((result) => {
          console.log("联系方式", result);
          this.linkEditShow = false;
          this.getCompanyInf();
          if (result.code == 200) {
            this.$message({
              message: "保存成功！",
              type: "success",
              center: true,
            });
          }
        })
        .catch(() => {});
    },
    changePhoneDialogShow(type) {
      this.phoneType = type;
      this.phoneNumCode = "";
      if (type == "tel") {
        this.changePhone = this.linkObj.linktel;
      } else {
        this.changePhone = this.linkObj.byphone;
      }
      this.sedCoedDialogShow = true;
    },

    /* 更改联系人手机号 */
    updateLinkPhone() {
      if (this.phoneType == "tel") {
        updateLinkPhoneV2({
          moblie: this.changePhone,
          code: this.phoneNumCode,
        })
          .then((result) => {
            console.log("修改手机号", result);
            if (result.code == 200) {
              this.$message({
                message: "修改成功！",
                type: "success",
                center: true,
              });
              this.sedCoedDialogShow = true;
            } else {
              this.$message({
                message: result.msg,
                type: "warning",
                center: true,
              });
            }
          })
          .catch(() => {});
      } else {
        updateByphone({
          moblie: this.changePhone,
          code: this.phoneNumCode,
        })
          .then((result) => {
            if (result.code == 200) {
              this.$message({
                message: "修改成功！",
                type: "success",
                center: true,
              });
              this.sedCoedDialogShow = true;
            } else {
              this.$message({
                message: result.msg,
                type: "warning",
                center: true,
              });
            }
          })
          .catch(() => {});
      }

      // updateLinkPhone({
      //   moblie: this.changePhone,
      //   code: this.phoneNumCode,
      //   isCheckPhone: 1,
      // })
      //   .then((result) => {
      //     console.log("修改公司联系人", result);
      //     if (result.code == 200) {
      //       this.$message({
      //         message: "修改成功！",
      //         type: "success",
      //         center: true,
      //       });
      //     } else {
      //       if (result.code == 15) {
      //         this.$confirm(
      //           `手机号已被${result.data.name}绑定，请核实输入的信息的准确性，如无误，继续保存将解除与旧账号的绑定，是否确认继续保存？`,
      //           "提示",
      //           {
      //             confirmButtonText: "确定",
      //             cancelButtonText: "取消",
      //             type: "warning",
      //           }
      //         )
      //           .then(() => {
      //             unbindPhoneNum({
      //               type: "unbundle",
      //               verify: result.data.verify,
      //               uid: result.data.uid,
      //             })
      //               .then((res) => {
      //                 console.log("解绑手机号", res);
      //                 this.sedCoedDialogShow = false;
      //                 this.getCompanyInf();
      //               })
      //               .catch(() => {});
      //             // this.$message({
      //             //   type: "success",
      //             //   message: "删除成功!",
      //             // });
      //           })
      //           .catch(() => {});
      //         // this.unbundleData = res.data;
      //         // this.wxPhonelogin = false;
      //         // this.phoneCatContent = `<span style="font-size: 14px;">手机号已被<span style="color: rgb(247, 150, 70);">${res.data.name}</span>绑定，请核实输入的信息的准确性，如无误，继续保存将解除与旧账号的绑定，是否确认继续保存？</span>`;
      //         // this.phoneCatShow = true;
      //       } else {
      //         this.$message.error(result.msg);
      //       }
      //     }
      //   })
      //   .catch(() => {});
    },
    //更新公司福利
    updateCompanyWelfare() {
      updateCompanyWelfare({
        welfare: String(this.companyInf.welfare.welfare_list),
      })
        .then((result) => {
          console.log("更新公司福利", result);
          this.comflEditShow = false;
          this.getCompanyInf();
          if (result.code == 200) {
            this.$store.dispatch("user/setInfo");
            this.$message({
              message: "保存成功！",
              type: "success",
              center: true,
            });
          }
        })
        .catch(() => {});
    },

    //更新公司介绍
    updateCompanyContent() {
      updateCompanyContent({ content: this.companyContent })
        .then((result) => {
          console.log("更新公司福利", result);
          this.comdespEditShow = false;
          if (result.code == 200) {
            this.$message({
              message: "修改成功！",
              type: "success",
              center: true,
            });
            this.getCompanyInf();
          }
        })
        .catch(() => {});
    },

    //获取公司福利

    getBusinessWeifare() {
      getBusinessWeifare()
        .then((result) => {
          console.log("获取公司福利", result);
          result.data.list.forEach((el) => {
            if (el.status == 1) {
              el.check = true;
            } else {
              el.check = false;
            }
          });
          this.hotComWeifArr = result.data.list;
        })
        .catch(() => {});
    },

    showComFl() {
      this.getBusinessWeifare();
      this.comflEditShow = true;
    },

    //添加公司福利
    addCompanyWei() {
      this.$prompt("", "添加公司福利", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        // inputErrorMessage: "邮箱格式不正确",
      })
        .then(({ value }) => {
          console.log(value);
          addCompanyWelfare({ name: value })
            .then((result) => {
              console.log("添加公司福利", result);
              this.companyInf.welfare.welfare_list.push(value);
            })
            .catch(() => {});
          // this.$message({
          //   type: "success",
          //   message: "你的邮箱是: " + value,
          // });
        })
        .catch(() => {});
    },

    choseComWeif(item, index) {
      console.log(item, index);
      this.companyInf.welfare.welfare_list.push(item.name);
    },

    delWorkImg(index, type) {
      console.log(type);
      if (type === "video") {
        this.doorPhoto = "";
        this.videoUrl = "";
      } else {
        this.workErpImg.splice(index, 1);
      }
    },

    delComWelf(index) {
      this.companyInf.welfare.welfare_list.splice(index, 1);
    },

    addUserJnTag() {},

    xlChose(e) {
      this.linkWorkId = e;
    },
    // expComQuerySearchAsync() {},
    // experComhandleSelect() {},
    handleRemove(file, fileList) {
      this.loadingInstance.close();
      console.log(file, fileList);
    },
    closeDilog3() {
      this.isCompanyHy = false;
    },
    closeAddresPop() {
      this.addressDialogShow = false;
    },
    submitAddress(e) {
      console.log("选择地址", e);
      this.addressDialogShow = false;
      this.baseObj.address = e.address1;
      this.baseObj.addr_id = e.id;
      this.baseObj.x = e.x;
      this.baseObj.y = e.y;
      this.baseObj.provinceid = e.provinceid;
      this.baseObj.cityid = e.cityid;
      this.baseObj.three_cityid = e.three_cityid;
    },
    toCompanyYl() {
      this.$router.push(`/companyinf?id=${this.companyInf.uid}&isyl=1`);
    },
    sendCode() {
      if (this.changePhone == "") {
        Message({
          message: "请输入手机号",
          type: "error",
          duration: 3 * 1000,
          showClose: true,
          offset: 100,
        });
        return;
      }
      if (this.changePhone.length != 11) {
        Message({
          message: "请输入正确的手机号",
          type: "error",
          duration: 3 * 1000,
          showClose: true,
          offset: 100,
        });
        return;
      }
      if (!this.validatePhoneNumber()) {
        Message({
          message: "请输入正确的手机号",
          type: "error",
          duration: 3 * 1000,
          showClose: true,
          offset: 100,
        });
        return;
      }
      // usertype: this.usertype,
      // 			event: 'login',
      // 			moblie: this.tel
      checkPhoneIsHave({
        type: "checkPhone",
        usertype: localStorage.usertype,
        telType: this.phoneType,
        phone: this.changePhone,
      })
        .then((result) => {
          if (Object.keys(result.data).length > 0) {
            this.$confirm(
              `该手机号已被其他账号绑定，请核实输入的信息的准确性！`,
              "提示",
              {
                confirmButtonText: "确定",
                type: "warning",
              }
            )
              .then(() => {})
              .catch(() => {});
          } else {
            this.countdown();
            sendPhoneCode({
              usertype: localStorage.usertype,
              event: "update_moblie",
              moblie: this.changePhone,
            })
              .then((result) => {
                console.log("发送验证码", result);
                if (result.code == 200) {
                  Message({
                    message: "发送成功",
                    type: "success",
                    duration: 3 * 1000,
                    showClose: true,
                    offset: 100,
                  });
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        })
        .catch(() => {});
    },
    countdown() {
      // 60秒后重新获取验证码
      this.smsFlag = true;
      this.sendTime = this.snsMsgWait + "s后重发";
      this.snsMsgWait = this.snsMsgWait - 1;

      var inter = setInterval(
        function () {
          this.smsFlag = true;
          this.bordercolor = "no";
          this.sendTime = this.snsMsgWait + "s后重发";
          this.snsMsgWait = this.snsMsgWait - 1;
          if (this.snsMsgWait < 0) {
            clearInterval(inter);
            this.bordercolor = "basics";
            this.sendColor = "#02c598";
            this.sendTime = "获取验证码";
            this.snsMsgWait = 60;
            this.smsFlag = false;
          }
        }.bind(this),
        1000
      );
    },
    validatePhoneNumber() {
      const reg = /^1[3456789]\d{9}$/;
      if (!reg.test(this.changePhone)) {
        // 手机号码不符合规则
        return false;
      }
      // 手机号码符合规则
      return true;
    },
  },
};
</script>

<style>
.send-phone-code-dialog {
  padding: 20px;
}

.send-phone-code-dialog .sub-btn {
  margin-top: 30px;
  width: 100%;
  text-align: center;
}
.company-page {
  display: flex;
}

.company-page .el-dialog__wrapper .el-dialog {
  border-radius: 10px;
}
.common-white {
  width: 800px;
  background-color: #fff;
  border-radius: 16px;
  padding: 30px 24px;
}
.company-base-inf {
  display: flex;
  align-items: center;
  position: relative;
}
.company-base-inf .user-avatar-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.company-base-inf .name-type-num-are {
  margin-left: 24px;
}
.company-base-inf .name-type-num-are .name {
  display: flex;
  align-items: center;
  font-size: 24px;

  font-weight: 600;
  color: #191919;
  line-height: 28px;
}
.company-base-inf .name-type-num-are img {
  height: 24px;
  width: auto;
  margin-left: 16px;
}
.company-base-inf .type-num-are {
  font-size: 18px;

  font-weight: 400;
  color: #757575;
  line-height: 24px;
  margin-top: 24px;
}

.common-white .right-edit-view {
  display: flex;
  align-items: center;
  font-size: 14px;

  font-weight: 400;
  color: #fe6002;
  line-height: 16px;
  position: absolute;
  right: 24px;
  top: 30px;
}
.common-white .right-edit-view img {
  width: 16px;
  height: 16px;
}

.companyinf-view {
  margin-top: 24px;
  position: relative;
}

.common-white .common-title-view {
  font-size: 22px;

  font-weight: 600;
  color: #191919;
  line-height: 28px;
  display: flex;
  align-items: center;
}
.companyinf-view .common-title-view .tag {
  height: 17px;
  background-color: #f34b37;
  border-radius: 3px;
  font-size: 12px;

  font-weight: 400;
  color: #ffffff;
  line-height: 17px;
  padding: 2px 4px;
  margin-left: 8px;
}
.link-view .common-title-view .tag {
  height: 17px;
  background-color: #fff5f0;
  border-radius: 3px;
  font-size: 12px;

  font-weight: 400;
  color: #fe6002;
  line-height: 17px;
  padding: 2px 4px;
  margin-left: 8px;
}
.common-white .company-inf-view .inf-item {
  display: flex;
  align-items: center;
  margin-top: 12px;
}

.common-white .company-inf-view .inf-item img {
  width: 20px;
  height: 20px;
}
.common-white .company-inf-view .inf-item .tt1 {
  font-size: 16px;

  font-weight: 400;
  color: #757575;
  line-height: 19px;
  margin-left: 8px;
}
.common-white .company-inf-view .inf-item .tt2 {
  font-size: 18px;

  font-weight: 400;
  color: #191919;
  line-height: 19px;
}
.link-view {
  margin-top: 24px;
  position: relative;
}
.link-inf-view .link-flex {
  display: flex;
}
.common-white .link-flex .company-inf-view {
  flex: 1;
}
.companyinf-big-title-view {
  display: flex;
  align-items: center;
  font-size: 24px;

  font-weight: 600;
  color: #222222;
  line-height: 28px;
  margin-top: 24px;
}
.companyinf-big-title-view .xian {
  width: 4px;
  height: 20px;
  background-color: #fe6002;
  margin-right: 16px;
  border-radius: 5px;
}
.company-detail-view {
  margin-top: 24px;
  position: relative;
}
.company-detail-view .company-fl-view span {
  display: inline-block;
  background-color: #f5f6fa;
  padding: 6px 14px;
  font-size: 16px;

  font-weight: 400;
  color: #757575;
  line-height: 19px;
  margin-right: 12px;
  margin-top: 16px;
}
.company-detail-view .company-desp-view {
  white-space: pre-wrap;
  font-size: 18px;

  font-weight: 400;
  color: #757575;
  line-height: 28px;
  margin-top: 16px;
}
.comindex-right-view {
  margin-left: 24px;
}
.comindex-right-view .right-btn-view .el-button.el-button--primary.is-plain {
  width: 346px;
  height: 44px;
  font-size: 18px;

  font-weight: 600;
}
.company-page .comindex-right-view .companyqy {
  margin-top: 24px;
}

/* .userava-up-view .avatar-uploader{
  width:50px;
  height:50px;

} */
.userava-up-view .avatar-uploader img{
  width: 100px;
  height: 100px;
  border-radius: 50%;

}

.comindex-right-view .zpbb {
  margin-top: 24px;
}
.other-resume-inf-view {
  width: 800px;
}
.work-erp-view {
  margin-top: 30px;
}
.work-erp-view .com-work-img {
  display: inline;
  position: relative;
}
.work-erp-view .com-work-img img {
  /*width: 140px;*/
  /*height: 140px;*/
  /*margin-right: 20px;*/
  /*margin-bottom: 20px;*/
}

.work-erp-view .com-work-img .colse-img {
  width: 22px;
  height: 22px;
  position: absolute;
  right: 25px;
}

.work-erp-view .el-upload-view {
  display: inline;
}
.company-page .video-open .el-dialog {
  width: 100px;
  background-color: transparent;
}
.company-page .video-open .el-dialog .el-dialog__header {
  display: none;
}
.company-page .el-image .colse-img-video {
  position: absolute;
  left: 102px;
  width: 22px;
  height: 22px;
}
</style>