<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-07-19 14:21:56
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-06-11 16:37:57
 * @FilePath: /lcwpcvue/src/views/transferShopList.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="transfer-shop-list-page">
    <div class="top-zk-div"></div>
    <div class="find-job-sear-view">
      <searview
        ref="searview-ref"
        :pagescrollhe="pageScrollHe"
        searchpage="transfer"
        @toSearch="searchFun"
      ></searview>
      <filterateview
        :searchpage="'transfer'"
        @toFliterList="fliterTalentList"
        @clearFiltrateFun="clearFiltrateFun"
      ></filterateview>
    </div>

    <div class="min-transfer-list-tj-view">
      <div class="transfer-navbar-view">
        <div
          @click="flShopType('')"
          class="navbar-item"
          :class="datas.type == '' ? 'navbar-item-sl' : ''"
        >
          <span>所有店铺</span>
          <div v-show="datas.type == ''" class="btm-xian"></div>
        </div>
        <label v-for="item in shopTypeList" :key="item.id">
          <div
            @click="flShopType(item.id)"
            class="navbar-item"
            :class="item.id == datas.type ? 'navbar-item-sl' : ''"
          >
            <span>{{ item.name }}</span>
            <div v-show="item.id == datas.type" class="btm-xian"></div>
          </div>
        </label>
      </div>

      <div class="tranlist-right-view">
        <div class="list-left-view" v-if="transferList.length > 0">
          <trnasferlistitem :transferlist="transferList">
            <el-pagination
              @current-change="pagesChage"
              background
              layout="prev, pager, next"
              :page-count="allPage"
              :current-page.sync="currentpage"
              class="pag-ina"
            >
            </el-pagination>
          </trnasferlistitem>
        </div>
        <div
          v-if="transferList.length <= 0"
          class="tranfer-list-item-null"
        >
          <div class="null-img">
            <img src="@/assets/coupon_none_list.png">
            <div>暂无内容</div>
          </div>
        </div>
        <div class="right-view-rollout">
          <img
            class="fb-btn"
            @click="toPublitshTransffer"
            src="../assets/tran_take_shop.png"
            alt=""
          />
          <img class="yhjl-view" src="../assets/findjob_yh_jl.png" alt="" />
          <img
            class="sac-ewm-img"
            src="../assets/tran_take_sc_ewm.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import searview from "@/components/homeSearComp.vue";
import filterateview from "@/components/filtrateComp.vue";
import trnasferlistitem from "@/components/trnasferListItem.vue";
import { getTransferList } from "@/api/commen/transferShop";
import { getTransferTypeFil } from "@/api/commen/filtrateComp";
import {Message} from "element-ui";

export default {
  components: {
    searview,
    filterateview,
    trnasferlistitem,
  },
  data() {
    return {
      transferList: [],
      datas: {
        size: 10,
        page: "1",
        wd: "",
        province: "",
        city: "",
        district: "",
        area: "",
        rent: "",
        transferFee: "",
        type: "",
        shopstatus: "",
      },
      allPage: 0,
      currentpage: 0,
      shopTypeList: [],
      pageScrollHe: 0,
    };
  },
  created() {
    this.getTransferList();
    this.getTransferTypeFil();
    window.addEventListener("scroll", this.homePageScroll);
  },
  methods: {
    clearFiltrateFun() {
      this.datas.page = 1;
      this.datas.wd = "";
      this.datas.province = "";
      this.datas.city = "";
      this.datas.area = "";
      this.datas.rent = "";
      this.datas.shopstatus = "";
      this.$refs["searview-ref"].searchKey = "";
      this.getTransferList();
    },
    //监听页面滚动
    homePageScroll() {
      // console.log(e);
      // 滚动条距文档顶部的距离
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // 滚动条滚动的距离
      // let scrollStep = scrollTop - this.oldScrollTop;
      this.pageScrollHe = scrollTop;
      // console.log("header 滚动距离 ", scrollTop);
      //翻页
    },
    pagesChage(e) {
      console.log("chage", e);
      this.datas.page = e;
      window.scrollTo(220, 220);
      this.getTransferList();
    },
    //获取转店列表
    getTransferList() {
      getTransferList(this.datas)
        .then((result) => {
          console.log("获取转店列表", result);
          this.transferList = result.data.list;
          this.allPage = parseInt(result.data.count);
          if (this.allPage === 0) {
            Message({
              message: "没有数据",
              type: "error",
              duration: 1000,
              offset: 100,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getTransferTypeFil() {
      getTransferTypeFil()
        .then((result) => {
          console.log("电批类型", result);
          this.shopTypeList = result.data.list;
        })
        .catch(() => {});
    },

    searchFun(keyword) {
      this.datas.wd = keyword;
      this.getTransferList();
    },
    flShopType(id) {
      this.datas.type = id;
      this.currentpage = 1;
      this.getTransferList();
    },
    fliterTalentList(type, id) {
      if (type === "area") {
        this.datas.area = id.query;
      } else if (type === "pro") {
        if (id.proId === "all") {
          this.datas.province = "";
          this.datas.city = "";
        } else {
          this.datas.province = id.proId;
          this.datas.city = id.cityId === 'all' ? '' : id.cityId;
        }
      } else if (type === "rent") {
        this.datas.rent = id.query;
      } else if (type === "statu") {
        this.datas.shopstatus = id.id;
      }
      this.getTransferList();
    },
    toPublitshTransffer() {
      this.$router.push("/publishtransffer");
    },
  },
};
</script>

<style>
.transfer-shop-list-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.min-transfer-list-tj-view {
  /* margin: 0 220px; */
}
.transfer-navbar-view {
  width: 100%;
  display: flex;
  background-color: #fff;
  margin-top: 41px;
  font-size: 22px;
  
  font-weight: 400;
  color: #757575;
  padding: 15px 0;
  border-radius: 8px;
  border-bottom: 2px solid#EAECF5;
}
.transfer-navbar-view .navbar-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px;
  position: relative;
}

.transfer-navbar-view .navbar-item-sl {
  font-size: 24px;
  
  font-weight: 600;
  color: #fe6002;
}
.transfer-navbar-view .btm-xian {
  width: 40px;
  height: 3px;
  background-color: #fe6002;
  position: absolute;
  bottom: -15px;
}

.tranlist-right-view {
  display: flex;
}
.tranlist-right-view .right-view-rollout {
  width: 403px;
  margin-left: 44px;
}

.tranlist-right-view .right-view-rollout .fb-btn {
  width: 403px;
  height: 177px;
  margin-top: 24px;
  cursor: pointer;
}

.tranlist-right-view .right-view-rollout .yhjl-view {
  width: 403px;
  height: 200px;
  margin-top: 40px;
}

.tranlist-right-view .right-view-rollout .sac-ewm-img {
  width: 403px;
  height: 320px;
  margin-top: 40px;
}
.tranfer-list-item-null {
  margin-top: 24px;
  background-color: #fff;
  width: 1040px;
  height: 800px;
}
.pag-ina {
  margin-top: 30px;
  text-align: center;
}
.tranfer-list-item-null .null-img{
  width: 360px;
  margin: 0 auto;
  margin-top: 220px;
}
.tranfer-list-item-null .null-img img{
  width: 360px;
  height: auto;
}
.tranfer-list-item-null .null-img div{
  font-size: 22px;
  font-weight: 400;
  color: #757575;
  text-align: center;
  margin-top: 20px;
}
</style>