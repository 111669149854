/*
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-12-12 15:39:52
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2023-12-18 13:16:13
 * @FilePath: /lcwpcvue/src/api/commen/addressManageApi.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'

/* 获取地址列表 */
export function getAddressList(params) {
  return request({
    url: 'address/addrList',
    method: 'post',
    data: params
  })
}

/* 搜索地址 */
export function searchAddressByGd(params) {
  return request({
    url: 'Sundries/commonWay',
    method: 'post',
    data: params
  })
}

/* 地理捏解析 */
export function getAddressId(params) {
  return request({
    url: 'applets/reverse_geocoding',
    method: 'get',
    params: params
  })
}

/* 添加地址 */
export function addAddress(params) {
  return request({
    url: 'address/add',
    method: 'post',
    data: params
  })
}
/* 删除地址 */

export function delAddress(params) {
  return request({
    url: 'address/del',
    method: 'post',
    data: params
  })
}

/* 根据id查找绑定的职位 */
export function getJobsByAddrId(addrId) {
  return request({
    url: 'Address/jobs',
    method: 'post',
    data: {
      addr_id:addrId
    }
  })
}