<template>
  <div class="h5-trandetail-page">
    <div
      style="
        position: absolute;
        z-index: 9999;
        background-color: rgba(000, 000, 000, 0.5);
        color: #fff;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px;
        font-size: 30px;
      "
      @click="backFun"
    >
      <i class="el-icon-arrow-left"></i>
    </div>
    <div class="top-view">
      <el-carousel height="250px">
        <el-carousel-item v-if="stroInf.video != ''">
          <video :src="stroInf.video"></video>
        </el-carousel-item>
        <el-carousel-item v-for="item in stroInf.pics" :key="item">
          <el-image
            style="width: 100%; height: 400px"
            :src="item"
            :preview-src-list="stroInf.pics"
          >
          </el-image>
        </el-carousel-item>
      </el-carousel>

      <!-- <swiper class="top-swp" :indicator-dots="false" :autoplay="false">
        <swiper-item class="sp-item" v-if="stroInf.video != ''">
          <image :src="stroInf.cover"></image>
          <image
            @click="palyVideo"
            class="pla"
            src="../../../static/images/zjd_detail_bf.png"
            mode=""
          >
          </image>
          <div class="zdy-doc">1/{{ swpLe }}</div>
        </swiper-item>
        <swiper-item
          class="sp-item"
          v-for="(item, index) in stroInf.pics"
          :key="index"
        >
          <image @click="prImg(index)" :src="item" mode=""></image>
          <div v-if="stroInf.video == ''" class="zdy-doc">
            {{ index + 1 }}/{{ swpLe }}
          </div>
          <div v-if="stroInf.video != ''" class="zdy-doc">
            {{ index + 2 }}/{{ swpLe }}
          </div>
        </swiper-item>
      </swiper> -->
      <!-- <div class="vdio" @click="coloseVideo" v-show="videoShow">
        <video id="myVideo" :src="stroInf.video" controls></video>
      </div> -->
      <div class="bar-img">
        <!-- <image
          class="bac-img"
          @click="tobanck"
          src="../../../static/images/zjd_detail_fh.png"
        ></image> -->
        <!-- #ifdef APP -->
        <!-- <image
          class="share-img"
          v-if="stroInf.transfer_status != 1 && ised == 0"
          src="../../../static/images/zjd_detail_fx.png"
          @click="shareShow = true"
        ></image> -->
        <!-- #endif -->
      </div>
      <!-- <div class="pl-img"><image src="../../../static/images/zjd_detail_bf.png" mode=""></image></div> -->
    </div>
    <div class="model-sty" style="margin-top: -16px">
      <div class="title">{{ stroInf.title }}</div>
      <div class="addtime-bro">
        刷新时间 {{ stroInf.update_timeNew }} | 浏览 {{ stroInf.views }}
      </div>
      <div class="prc-mj">
        <div class="item">
          <div class="ttt1">
            {{ stroInf.rent }}
            <label>元/月</label>
          </div>
          <div class="ttt2" v-if="stroInf.rent_type_str == '其他'">
            {{ stroInf.rent_type_body }}
          </div>
          <div class="ttt2" v-else>{{ stroInf.rent_type_str }}</div>
        </div>
        <div class="item">
          <div v-if="stroInf.salary_type == 1" class="t1">面议</div>
          <div v-else class="ttt1">{{ stroInf.transfer_fee }}万</div>
          <div class="ttt2">转让费</div>
        </div>
        <div class="item">
          <div class="ttt1">{{ stroInf.area }}<label>m²</label></div>
          <div class="ttt2">面积</div>
        </div>
      </div>
    </div>
    <div class="yzc-zz" v-if="stroInf.transfer_status == 1">
      <!-- <image src="../../../static/images/zjd_yzc_detail.png" mode=""></image> -->
    </div>
    <div class="model-sty">
      <div class="title">店铺介绍</div>

      <div class="dp-jb-inf">
        <div class="jb-item" v-if="stroInf.month_tally">
          <div class="fangku"></div>
          <div class="txt">每月流水：{{ stroInf.month_tally }}</div>
        </div>
        <div class="jb-item" v-if="stroInf.card_tally">
          <div class="fangku"></div>
          <div class="txt">店铺卡金：{{ stroInf.card_tally }}</div>
        </div>
        <div class="jb-item" v-if="stroInf.mem_num">
          <div class="fangku"></div>
          <div class="txt">会员数量：{{ stroInf.mem_num }}</div>
        </div>
      </div>
      <div class="bj">
        <label v-for="(it, id) in stroInf.more_info" :key="id">
          <div class="it">{{ it }}</div>
        </label>
      </div>
      <div class="description" v-if="stroInf.shop_desc">
        <span v-if="more">{{ more }}...</span>
        <!-- <rich-text v-if="more" :nodes="more + '...'" style="display: inline;"></rich-text> -->
        <!-- <u-parse v-if="more" :html="more"></u-parse> -->
        <span style="color: #ff8136" @click="showdescription" v-if="more"
          >查看全部</span
        >
        <!-- <u-parse v-if="!more" :html="stroInf.shop_desc"></u-parse> -->
        <span v-if="!more">{{ stroInf.shop_desc }}</span>
        <!-- 	 -->
        <!-- <rich-text v-if="!more" :nodes="stroInf.shop_desc"></rich-text> -->
        <!-- <text v-if="!more">{{ stroInf.shop_desc }}</text> -->
      </div>
      <!-- <u-read-more ref="uReadMore" :fontSize="24" open-text="收起" close-text="展开" :toggle="true" :show-height="140" text-indent="0" color="#f34b37">
				<rich-text style="font-size: 24px; color:#757575; line-height: 35px;" :nodes="stroInf.shop_desc"></rich-text>
			</u-read-more> -->
    </div>
    <div class="model-sty">
      <div class="title">配套设施</div>
      <div class="pt-list">
        <label v-for="(item, index) in stroInf.peitaos" :key="index">
          <div class="item">
            <img :src="item.icon" />
            <div class="txt">{{ item.name }}</div>
          </div>
        </label>
      </div>
    </div>
    <div class="model-sty" v-if="stroInf.other_peitao != ''">
      <div class="title">其他设施</div>
      <div class="other-ss">
        <label v-for="(item, index) in stroInf.other_peitao" :key="index">
          <div class="item">{{ item }}</div>
        </label>
      </div>
    </div>
    <div class="model-sty" v-if="stroInf.pay_status == 1">
      <div class="title">地理位置</div>
      <div class="addr">
        <div class="ad">{{ stroInf.address }}</div>
        <div class="btn" @click="toAmapDh">
          <img
            src="https://mp-168c5a18-1927-4a65-8b2c-95087d865588.cdn.bspapp.com/cloudstorage/h2.png"
          />
          去导航
        </div>
      </div>
    </div>
    <div style="margin-top: 100px"></div>
    <openmini style="position: fixed; left: -20px; bottom: 0"></openmini>
  </div>
</template>

<script>
import { getTransferDetail } from "@/api/commen/transferShop";
import openmini from "@/components/h5Components/h5TominPro.vue";
export default {
  components: {
    openmini,
  },
  data() {
    return {
      videoContext: "",
      id: "",
      stroInf: {
        video: "",
      },
      opacity: "0",
      navbarH: "0",
      list: [],
      // content:
      // 	'店铺介绍店铺介绍店铺介绍店铺介绍店铺介绍店铺介绍店铺介绍店铺介绍店铺介绍店铺介绍店铺介绍店铺介绍店铺介绍店铺介绍店铺介绍店铺介绍店铺介绍店铺介绍店铺介绍店铺介绍店铺介绍店铺介绍店铺介绍店铺介绍店铺介绍店铺介绍',
      // shadowStyle: {
      // 	backgroundImage: 'none',
      // 	paddingTop: '0',
      // 	marginTop: '20px'
      // }
      shareList: [
        {
          type: 2,
          icon: "/pagesA/static/img/d1.png",
          text: "微信好友",
          provider: "weixin",
          scene: "WXSceneSession",
        },
        {
          type: 3,
          icon: "/pagesA/static/img/d2.png",
          text: "朋友圈",
          provider: "weixin",
          scene: "WXSenceTimeline",
        },
        {
          type: 1,
          icon: "/pagesA/static/img/d3.png",
          text: "QQ",
          provider: "qq",
          scene: "",
        },
        // {
        // 	type: 4,
        // 	icon: '/pagesA/static/img/d4.png',
        // 	text: '空间'
        // },
      ],
      shareShow: false, //分享面板显示状态
      ised: 0,
      swpLe: 0,
      videoShow: false,
      flag: true,
      more: "",
      shareImge: "",
      othermoduleshow: false,
      savePhone: "",
      sendTime: "获取验证码",
      snsMsgWait: 60,
      bordercolor: "no",
      smsFlag: false,
      code: "",
      notokentcshow: false,
      chekTel: "",
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.getTransferDetail();
  },

  methods: {
    backFun() {
      this.$router.go(-1);
    },
    //获取转店详情
    getTransferDetail() {
      getTransferDetail(this.id)
        .then((result) => {
          console.log(result);
          result.data.info.update_timeNew =
            result.data.info.update_time.split(" ")[0];
          result.data.info.lat = parseFloat(result.data.info.lat);
          result.data.info.lng = parseFloat(result.data.info.lng);

          // this.stroInf = result.data.info;
          this.stroInf = result.data.info;
          if (result.data.info.video != "") {
            this.swpLe = result.data.info.pics.length + 1;
          } else {
            this.swpLe = result.data.info.pics.length;
          }
          if (result.data.info.shop_desc.length > 100) {
            this.more = result.data.info.shop_desc.slice(0, 100);
          }
          if (!this.stroInf.video) {
            this.isVideoOrImg = 1;
            this.currentImage = this.stroInf.pics[0];
          }
          this.pics = this.stroInf.pics;
          this.recStoreList = result.data.recList;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //获取转店详情
    getTakedetail() {
      // api.get({
      //   url: "shop/transferInfo",
      //   data: {
      //     id: this.id,
      //     // id: 5793
      //   },
      //   success: (data) => {
      //     console.log("获取转店详情", data);
      //     data.data.info.update_timeNew =
      //       data.data.info.update_time.split(" ")[0];
      //     if (data.data.info.video != "") {
      //       this.swpLe = data.data.info.pics.length + 1;
      //     } else {
      //       this.swpLe = data.data.info.pics.length;
      //     }
      //     if (data.data.info.shop_desc.length > 100) {
      //       this.more = data.data.info.shop_desc.slice(0, 100);
      //     }
      //     if (data.data.info.rent_type_str == "其他") {
      //       if (data.data.info.rent_type_body == "") {
      //         data.data.info.rent_type_body = "其他";
      //       }
      //     }
      //     // this.list = this.list.concat(data.data.info.pics);
      //     this.stroInf = data.data.info;
      //   },
      // });
    },

    close() {},
    open() {},

    colsenotoken() {
      this.notokentcshow = false;
    },

    palyVideo() {
      this.videoShow = true;
      this.videoContext.play();
    },
    coloseVideo() {
      this.videoShow = false;
      this.videoContext.pause();
    },
    // 展示文本详情
    showdescription() {
      this.more = "";
    },
    toAmapDh() {
      const destination = `${this.stroInf.lng},${this.stroInf.lat}`;
      window.location.href = `https://uri.amap.com/marker?position=${destination}&name=${this.stroInf.address}&keywords=高德地图官网&dev=0&id=BGVJIFRK&coordinate=gaode&callnative=0`;
    },
    // goChatPage() {
    // 	uni.navigateTo({
    // 		url: '/pagesA/chat/chat?uid=' + this.stroInf.uid,
    // 		success: res => {},
    // 		fail: () => {},
    // 		complete: () => {}
    // 	});
    // }
  },
};
</script>

<style lang="scss" scoped>
.h5-trandetail-page {
  width: 100%;
  height: 100%;
  overflow: scroll;
  position: relative;
  display: flex;
  flex-direction: column;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  transition: width 0.3s ease;
  &::-webkit-scrollbar {
    display: none;
  }
}
.top-view {
  text-align: center;
  position: relative;

  .bar-img {
    width: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    top: 52px;
    padding: 0 32px;

    .bac-img {
      width: 52px;
      height: 52px;
      position: absolute;
      left: 32px;
      top: 32px;
    }

    /* #ifdef APP */
    .share-img {
      width: 52px;
      height: 52px;
      position: absolute;
      right: 32px;
      top: 32px;
    }

    /* #endif */
    /* #ifdef MP-WEIXIN */
    .share-img {
      width: 52px;
      height: 52px;
      position: absolute;
      right: 232px;
      top: 32px;
    }

    .wx-share-btn2 {
      width: 52px;
      height: 52px;
      position: absolute;
      right: 232px;
      top: 32px;
      opacity: 0;
    }

    /* #endif */

    // image {
    // 	width: 52px;
    // 	height: 52px;
    // 	position: absolute;
    // 	left: 32px;
    // 	top: 32px;
    // }
    // image:last-child {
    // 	right: 32px;
    // }
  }

  .pl-img {
    position: absolute;
    top: 241px;
    width: 100%;

    img {
      width: 134px;
      height: 132px;
    }
  }

  .top-swp {
    width: 100%;
    height: 560px;
    z-index: -99;

    .sp-item {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      image {
        width: 100%;
        height: 100%;
      }

      .pla {
        width: 134px;
        height: 132px;
        position: absolute;
      }

      .zdy-doc {
        position: absolute;
        right: 32px;
        bottom: 64px;
        background-color: rgba($color: #000000, $alpha: 0.6);
        border-radius: 24px;
        padding: 7px 20px;
        color: #ffffff;
        font-size: 24px;
      }
    }
  }
}

.model-sty {
  background-color: #ffffff;
  margin: 8px 16px;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 8px 16px -1px rgba(175, 180, 197, 0.16);
  position: relative;
  z-index: 99;

  .title {
    font-size: 20px;
    color: #222222;
    font-weight: 800;
    width: 390px;
    line-height: 30px;
  }

  .addtime-bro {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #757575;
    margin-top: 6px;
  }

  .prc-mj {
    display: flex;
    align-items: center;
    margin-top: 20px;

    .item {
      flex: 1;
      text-align: center;

      .ttt1 {
        color: #f34b37;
        font-size: 24px;
        font-weight: 600;

        label {
          font-size: 15px;
          margin-left: 3px;
        }
      }

      .ttt2 {
        font-size: 15px;
        color: #757575;
        font-weight: 400;
        margin-top: 10px;
      }
    }

    .item:nth-child(1) {
      flex: 1.3;
    }
  }

  .bj {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;

    .it {
      margin-top: 10px;
      background-color: #f5f6fa;
      border-radius: 2px;
      font-size: 12px;
      color: #757575;
      padding: 4px 8px;
      margin-right: 8px;
    }
  }

  .pt-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .item {
      width: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 12px;
      font-size: 15px;

      img {
        width: 35px;
        height: 35px;
        margin-bottom: 5px;
      }

      .txt {
        width: 85px;
        text-align: center;
      }
    }

    .item:nth-child(1) {
      width: 44px;
      margin-right: 17px;
    }

    .item:nth-child(5n + 0) {
      width: 44px;
    }

    .item:nth-child(5n + 1) {
      width: 49px;
      margin-right: 17px;
    }

    // .item {
    // 	display: flex;
    // 	flex-direction: column;
    // 	align-items: center;
    // 	width: 145px;
    // 	margin-top: 20px;
    // 	image {
    // 		width: 64px;
    // 		height: 64px;
    // 	}
    // 	.txt {
    // 		font-size: 22px;
    // 		color: #222222;
    // 		font-weight: 400;
    // 	}
    // }
    // .item:nth-child(1) {
    // 	width: 64px;
    // 	margin-right: 30px;
    // }
    // .item:nth-child(5n + 0) {
    // 	width: 64px;
    // 	margin-left: 30px;
    // }
    // .item:nth-child(5n + 1) {
    // 	width: 64px;
    // 	margin-right: 30px;
    // }
  }

  .other-ss {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-left: -10px;

    .item {
      background-color: #fff5f0;
      padding: 6px 8px;
      border-radius: 6px 0px 6px 0px;
      margin-left: 10px;
      margin-top: 10px;
      font-size: 14px;
      color: #fe6002;
      font-weight: 400;
    }
  }

  .addr {
    display: flex;
    align-items: center;
    margin-top: 12px;
    position: relative;

    .ad {
      font-size: 15px;
      color: #757575;
      font-weight: 400;
      width: 294px;
      white-space: normal;
      line-height: 20px;
    }

    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80px;
      height: 28px;
      border: 1px solid #fe6002;
      border-radius: 14px;
      position: absolute;
      right: 0;
      color: #fe6002;
      font-size: 15px;
      font-weight: 400;

      img {
        width: 16px;
        height: 16px;
        margin: 2px;
      }
    }
  }

  .description {
    margin-top: 1ch;
    // padding-bottom: 48px;
    display: block;
    font-size: 15px;
    
    font-weight: 400;
    color: #222222;
    line-height: 26px;
  }

  .dp-jb-inf {
    margin-top: 12px;

    .jb-item {
      display: flex;
      align-items: center;
      margin-top: 10px;

      .fangku {
        width: 3px;
        height: 10px;
        background: #ff8136;
      }

      .txt {
        font-size: 17px;
        
        font-weight: 400;
        color: #222222;
        margin-left: 5px;
      }
    }
  }
}

.yzc-zz {
  width: 343px;
  height: 144px;
  background-color: rgba($color: #ffffff, $alpha: 0.5);
  position: absolute;
  margin: 8px 16px;
  border-radius: 8px;
  margin-top: -157px;

  img {
    position: absolute;
    width: 56px;
    height: 56px;
    right: 0;
  }
}

.bottom-call {
  position: fixed;
  bottom: 0;
  background-color: #ffffff;
  height: 70px;
  width: 100%;
  display: flex;

  .btn {
    width: 345px;
    height: 48px;
    background-color: #fe6002;
    border-radius: 4px;
    color: #ffffff;
    text-align: center;
    line-height: 48px;
    font-size: 18px;
    font-weight: 500;
    margin-left: 16px;
    margin-top: 8px;
  }

  // .btn:first-child {
  // 	background-color: #ffffff;
  // 	color: #fe6002;
  // 	border: 1px solid#fe6002;
  // }
}

.notokentc {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;

  .tc-view {
    width: 560px;
    height: 488px;
    background-color: #fff;
    border-radius: 16px;
    padding: 42px 60px;

    .title {
      font-size: 32px;
      
      font-weight: 500;
      color: #333333;
      margin-bottom: 18px;
    }

    .input-view {
      width: 440px;
      height: 80px;
      background-color: #f5f6fa;
      border-radius: 4px;
      margin-top: 28px;
      display: flex;
      align-items: center;
      padding-left: 32px;

      .ip {
        width: 80%;
        height: 32px;
        font-size: 28px;
      }

      .ip2 {
        font-size: 28px;
        width: 286px;
        border-right: 2px solid#CCCCCC;
      }

      .rt-view {
        width: 154px;
        font-size: 20px;
        
        font-weight: 400;
        color: #ff8136;
        text-align: center;
      }
    }

    .bto-btn {
      width: 440px;
      height: 80px;
      background-color: #e1e2e5;
      border-radius: 4px;
      font-size: 32px;
      
      font-weight: 400;
      color: #ffffff;
      text-align: center;
      line-height: 80px;
      margin-top: 44px;
      z-index: 9999;
    }

    .havephone {
      background-color: #ff8136;
    }
  }
}
</style>