<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-07-11 16:06:54
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2023-07-11 16:23:36
 * @FilePath: /lcwpcvue/src/views/job.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <div>{{ id }}</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: "aaa",
    };
  },
  created() {
    console.log(window.location.href);
    // this.$router.push({
    //   name: "home",
    //   query: {
    //     id: 111,
    //   },
    // });
  },
};
</script>

<style>
</style>