<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-12-20 18:55:27
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-06-05 17:11:22
 * @FilePath: /lcwpcvue/src/components/comUser/myOrder.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="myorder-page">
    <div style="height: 30px"></div>

    <div class="common-title-view">
      <span class="left-xian-view"></span>
      <span class="text">我的订单</span>
    </div>
    <div class="order-list-view">
      <div v-for="item in orderList" :key="item.id">
        <div class="order-item">
          <div class="order-left-text-view">
            <div class="order-title">{{ item.name }}</div>
            <div class="order-inf-text">订单编号：{{ item.order_id }}</div>
            <div class="order-inf-text">订单金额：{{ item.order_price }}元</div>
            <div class="order-inf-text">订单时间：{{ item.createtime }}</div>
          </div>
          <div class="order-right-text-view">
            <div
              :class="
                item.status_name == '待支付'
                  ? 'order-status-text'
                  : 'status-have-paid'
              "
            >
              {{ item.status_name }}
            </div>
            <div class="bott-btn-view">
              <el-button
                v-if="item.status_name == '待支付'"
                type="primary"
                plain
                @click="delOrder(item)"
                >取消
              </el-button>
              <span v-if="item.status_name == '支付成功'">
                <el-button
                  type="primary"
                  plain
                  v-show="!item.invoice.status"
                  @click="invoinceDialogShowFun(item)"
                  >申请发票</el-button
                >
                <div
                  v-if="item.invoice.status == 2"
                  @click="invoinceDialogShowFun(item)"
                >
                  <img
                    class="look-invoice-warning"
                    src="@/assets/warning.png"
                    alt=""
                  />
                  <span class="look-invoice-err">&nbsp;开票失败&nbsp;</span>
                  <span class="look-invoice look-invoice-err">重新开票</span>
                </div>
                <span
                  v-if="item.invoice.status == 0"
                  class="invoice-status-text"
                  >开票中</span
                >
                <span
                  v-if="item.invoice.status == 1"
                  class="look-invoice look-invoice-common"
                  @click="lookInvoice(item.invoice.id)"
                  >查看发票</span
                >
              </span>
            </div>
          </div>
        </div>
      </div>

      <el-pagination
        v-show="orderList.length != 0"
        @current-change="pagesChage"
        background
        layout="prev, pager, next"
        :page-count="allPage"
        :current-page.sync="currentpage"
        class="pag-ina"
      >
      </el-pagination>
      <div v-show="orderList.length < 1" class="nocoupon-list-view">
        <img class="none-list-img" src="@/assets/coupon_none_list.png" alt="" />
        <div class="none-text">当前暂无订单</div>
      </div>
      <ComDialog
        :isopen="invoiceDialogShow"
        dialogTetle="发票"
        @dialogFrameEvent="dialogFrameEvent"
        myWidth="500px"
        :isShowButt="true"
      >
        <div class="form-view">
          <el-form label-position="left" ref="">
            <div class="edi-one-item">
              <el-form-item label="">
                <div class="items">
                  <div class="title">公司名称</div>
                  <!-- <el-input placeholder="请填写公司名称" clearable> </el-input> -->
                  <el-autocomplete
                    v-model="invoiceEditObj.invoicetitle"
                    :fetch-suggestions="expComQuerySearchAsync"
                    placeholder="填写公司名称"
                    @select="experComhandleSelect"
                    clearable
                  ></el-autocomplete>
                </div>
              </el-form-item>
            </div>

            <div class="edi-one-item">
              <el-form-item label="">
                <div class="items">
                  <div class="title">纳税人识别号</div>
                  <el-input
                    v-model="invoiceEditObj.registerno"
                    placeholder="请填写纳税人识别号"
                    clearable
                  >
                  </el-input>
                </div>
              </el-form-item>
            </div>
            <div class="edi-one-item">
              <el-form-item label="">
                <div class="items">
                  <div class="title">发票金额</div>
                  <el-input
                    placeholder="请填写金额"
                    clearable
                    v-model="orderPrice"
                  ></el-input>
                </div>
              </el-form-item>
            </div>

            <div class="edi-one-item">
              <el-form-item label="">
                <div class="items">
                  <div class="title">发票内容</div>
                  <el-input
                    value="*人力资源服务*招聘服务费"
                    placeholder="请填写发票内容"
                    clearable
                  >
                  </el-input>
                </div>
              </el-form-item>
            </div>
            <div class="edi-one-item">
              <el-form-item label="">
                <div class="items">
                  <div class="title">电子邮箱</div>
                  <el-input
                    placeholder="请填写邮箱"
                    clearable
                    v-model="invoiceEditObj.email"
                  >
                  </el-input>
                </div>
              </el-form-item>
            </div>
            <div class="edi-one-item">
              <el-form-item label="">
                <div class="items">
                  <div class="title">开户银行</div>
                  <el-input
                    placeholder="请填写（选填）"
                    clearable
                    v-model="invoiceEditObj.bank"
                  >
                  </el-input>
                </div>
              </el-form-item>
            </div>
            <div class="edi-one-item">
              <el-form-item label="">
                <div class="items">
                  <div class="title">银行账号</div>
                  <el-input
                    placeholder="请填写（选填）"
                    clearable
                    v-model="invoiceEditObj.bankno"
                  >
                  </el-input>
                </div>
              </el-form-item>
            </div>
            <div class="edi-one-item">
              <el-form-item label="">
                <div class="items">
                  <div class="title">公司地址</div>
                  <el-input
                    placeholder="请填写（选填）"
                    clearable
                    v-model="invoiceEditObj.opaddress"
                  >
                  </el-input>
                </div>
              </el-form-item>
            </div>
            <div class="edi-one-item">
              <el-form-item label="">
                <div class="items">
                  <div class="title">联系电话</div>
                  <el-input
                    placeholder="请填写（选填）"
                    clearable
                    v-model="invoiceEditObj.opphone"
                  >
                  </el-input>
                </div>
              </el-form-item>
            </div>
          </el-form>
        </div>
      </ComDialog>
    </div>
    <ComDialog
      :isopen="isShowInvoiceByok"
      dialogTetle="已开票"
      @dialogFrameEvent="closeInvoiceByok"
      myWidth="500px"
    >
      <div class="ok-invoice-body">
        <div class="ok-invoice-content">
          <div class="ok-invoice-content-title">
            <p>发票类型</p>
            <p>抬头名称</p>
            <p>开票金额</p>
            <p>申请时间</p>
            <p>开票时间</p>
          </div>
          <div class="ok-invoice-content-body">
            <p>{{ lookInvoiceArr.type }}</p>
            <p>{{ lookInvoiceArr.invoicetitle }}</p>
            <p>
              {{ lookInvoiceArr.price }}<span style="font-size: 12px">￥</span>
            </p>
            <p>{{ lookInvoiceArr.addtime }}</p>
            <p>{{ lookInvoiceArr.lasttime }}</p>
          </div>
        </div>
      </div>
    </ComDialog>
  </div>
</template>

<script>
import {
  getQzOrderList,
  getZpOrderList,
  getCompanyListInInvoice,
  getCompanyNoCode,
  getHistoryInvoiceInf,
  submitInvoiceRep,
  qzDelOrder,
  zpDelOrder,
} from "@/api/commen/orderApi.js";
import { parseTime } from "@/utils";
import ComDialog from "@/components/comDialog.vue";
export default {
  name: "myorder",
  components: {
    ComDialog,
  },
  data() {
    return {
      userType: 1,
      orderList: [],
      invoiceDialogShow: false,
      isShowInvoiceByok: false,
      invoiceEditObj: {
        id: "",
        order_id: "",
        invoicetitle: "",
        registerno: "",
        email: "",
        bank: "",
        bankno: "",
        opaddress: "",
        opphone: "",
      },
      orderPrice: "￥",
      lookInvoiceArr: {},
      pageIndex: 1,
      allPage: 0,
      currentpage: 0,
    };
  },
  created() {
    this.userType = localStorage.usertype;
    console.log("userType :", this.userType);
    if (this.userType == 1) {
      this.getQzOrderList();
    } else {
      this.getZpOrderList();
    }
  },
  methods: {
    closeInvoiceByok() {
      this.isShowInvoiceByok = false;
    },
    dialogFrameEvent(eve) {
      this.invoiceDialogShow = false;
      if (eve === "ok") {
        this.submitInvoiceRep();
      }
    },
    //查看发票
    lookInvoice(id) {
      console.log(id);
      //查询已开发票信息
      submitInvoiceRep({ query: 1, id: id }).then((result) => {
        console.log("查询已开发票信息", result);
        if (result.code === 200) {
          this.lookInvoiceArr = result.data;
          this.lookInvoiceArr.addtime = parseTime(
            this.lookInvoiceArr.addtime,
            "{y}-{m}-{d} {h}:{i}"
          );
          this.lookInvoiceArr.lasttime = parseTime(
            this.lookInvoiceArr.lasttime,
            "{y}-{m}-{d} {h}:{i}"
          );
          this.isShowInvoiceByok = true;
        }
      });
    },
    getQzOrderList() {
      getQzOrderList({
        page: this.pageIndex,
      })
        .then((result) => {
          console.log("求职订单列表", result);
          this.allPage = parseInt(result.append.allpage);
          this.orderList = result.data.length > 1 ? result.data : [];
          console.log("my order:", this.orderList);
        })
        .catch(() => {});
    },
    getZpOrderList() {
      getZpOrderList({
        page: this.pageIndex,
      })
        .then((result) => {
          console.log("企业订单列表", result);
          this.allPage = parseInt(result.append.allpage);
          this.orderList = result.data.length > 1 ? result.data : [];
        })
        .catch(() => {});
    },

    pagesChage(e) {
      this.pageIndex = e;
      window.scrollTo(200, 200);
      if (this.userType == 1) {
        this.getQzOrderList();
      } else {
        this.getZpOrderList();
      }
    },

    invoinceDialogShowFun(item) {
      console.log(item);
      this.orderPrice = "￥" + item.order_price;
      this.invoiceEditObj.order_id = item.order_id;
      getHistoryInvoiceInf()
        .then((result) => {
          console.log("获取历史开票信息", result);
          if (result.data) {
            this.invoiceEditObj.invoicetitle = result.data.invoicetitle;
            this.invoiceEditObj.registerno = result.data.registerno;
            this.invoiceEditObj.email = result.data.email;
            this.invoiceEditObj.bank = result.data.bank;
            this.invoiceEditObj.bankno = result.data.bankno;
            this.invoiceEditObj.opaddress = result.data.opaddress;
            this.invoiceEditObj.opphone = result.data.opphone;
          }
          this.invoiceDialogShow = true;
        })
        .catch(() => {});
      if (item.invoice.status) {
        this.invoiceEditObj.id = item.invoice.id;
        //查询已开发票信息
        submitInvoiceRep({
          query: 1,
          id: this.invoiceEditObj.id,
        })
          .then((result) => {
            console.log("查询已开发票信息", result);
            this.invoiceEditObj.invoicetitle = result.data.invoicetitle;
            this.invoiceEditObj.registerno = result.data.registerno;
            this.invoiceEditObj.email = result.data.email;
            this.invoiceEditObj.bank = result.data.bank;
            this.invoiceEditObj.bankno = result.data.bankno;
            this.invoiceEditObj.opaddress = result.data.opaddress;
            this.invoiceEditObj.opphone = result.data.opphone;
          })
          .catch(() => {});
      } else {
        this.invoiceEditObj.id = "";
      }
    },
    // invoinceDialogShowAganFun(item) {
    //   this.orderPrice = "￥" + item.order_price;
    //   this.invoiceEditObj.order_id = item.order_id;
    //   getHistoryInvoiceInf()
    //     .then((result) => {
    //       console.log("获取历史开票信息", result);
    //       if (result.data) {
    //         this.invoiceEditObj.invoicetitle = result.data.invoicetitle;
    //         this.invoiceEditObj.registerno = result.data.registerno;
    //         this.invoiceEditObj.email = result.data.email;
    //         this.invoiceEditObj.bank = result.data.bank;
    //         this.invoiceEditObj.bankno = result.data.bankno;
    //         this.invoiceEditObj.opaddress = result.data.opaddress;
    //         this.invoiceEditObj.opphone = result.data.opphone;
    //       }
    //       this.invoiceDialogShow = true;
    //     })
    //     .catch(() => {});
    // },
    expComQuerySearchAsync(queryString, cb) {
      console.log(queryString, cb);
      if (queryString) {
        getCompanyListInInvoice({ kw: queryString })
          .then((result) => {
            console.log("获取公司", result);
            result.data.Result.Data.forEach((element) => {
              element.value = element.Name;
            });
            cb(result.data.Result.Data);
          })
          .catch(() => {});
      }
    },
    experComhandleSelect(e) {
      console.log(e);
      getCompanyNoCode({ name: e.Name })
        .then((result) => {
          console.log("查询纳税人编号", result);
          if (result.code == 200) {
            this.invoiceEditObj.registerno = result.data.code;
          }
        })
        .catch(() => {});
    },
    submitInvoiceRep() {
      submitInvoiceRep(this.invoiceEditObj)
        .then((result) => {
          console.log("提交发票", result);
          this.invoiceDialogShow = false;
          if (result.code == 200) {
            this.$message({
              message: "提交成功！",
              type: "success",
              center: true,
            });
            if (this.userType == 1) {
              this.getQzOrderList();
            } else {
              this.getZpOrderList();
            }
          }
        })
        .catch(() => {});
    },
    delOrder(item) {
      this.$confirm("是否要取消此订单?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (this.userType == 1) {
            qzDelOrder({ order_id: item.order_id })
              .then((result) => {
                console.log("删除订单", result);
                this.$message({
                  message: "删除成功！",
                  type: "success",
                  center: true,
                });
                this.getQzOrderList();
              })
              .catch(() => {});
          } else {
            zpDelOrder({ id: item.id })
              .then((result) => {
                console.log("删除订单", result);
                this.$message({
                  message: "删除成功！",
                  type: "success",
                  center: true,
                });
                this.getZpOrderList();
              })
              .catch(() => {});
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style>
.order-list-view .order-item {
  background-color: #fff;
  width: 1148px;
  height: 110px;
  display: flex;
  justify-content: space-between;
  padding: 20px 24px;
  margin-top: 24px;
}

.order-list-view .order-item .order-left-text-view .order-title {
  font-size: 22px;

  font-weight: 600;
  color: #191919;
  line-height: 28px;
  padding-bottom: 10px;
}

.order-list-view .order-item .order-left-text-view .order-inf-text {
  font-size: 16px;

  font-weight: 400;
  color: #939597;
  line-height: 19px;
  margin-top: 6px;
}

.order-list-view .order-item .order-right-text-view {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}

.status-have-paid {
  font-weight: 600;
  font-size: 16px;
  color: #b1b1b1;
  line-height: 9px;
}

.order-list-view .order-item .order-right-text-view .order-status-text {
  font-size: 16px;

  font-weight: 600;
  color: #f34b37;
  line-height: 19px;
}
.order-list-view
  .order-item
  .order-right-text-view
  .bott-btn-view
  .invoice-status-text {
  font-size: 15px;

  font-weight: 400;
  color: #fe6002;
  line-height: 16px;
}

.order-list-view .fy-page {
  margin-top: 20px;
  width: 100%;
  text-align: center;
}
.form-view {
  padding: 0 140px;
  border-top: 2px solid #eaecf5;
  padding-top: 20px;
}
.look-invoice {
  text-decoration-line: underline;
  cursor: pointer;
}
.look-invoice-err {
  font-weight: 400;
  font-size: 15px;
  color: #f34b37;
  text-transform: none;
}
.look-invoice-common {
  font-weight: 400;
  font-size: 15px;
  color: #fe6002;
  line-height: 16px;
}
.look-invoice-warning {
  width: 17px;
  height: 17px;
}
.myorder-page {
  /*width: 60%;*/
  width: 1197px;
}
.ok-invoice-body {
  border-top: 2px solid #eaecf5;
}
.ok-invoice-content {
  height: 280px;
  width: 400px;
  margin: 20px auto;
}
.ok-invoice-content-title {
  margin-right: 10px;
  color: rgba(0, 0, 0, 0.3);
  font-weight: 400;
}
.ok-invoice-content-body {
  font-weight: 600;
  color: #191919;
  overflow: hidden;
}
.ok-invoice-content div {
  float: left;
  line-height: 50px;

  font-size: 18px;
}
</style>