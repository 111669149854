<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-07-18 11:15:18
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-04-19 10:52:42
 * @FilePath: /lcwpcvue/src/components/
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="job-info-div">
    <label v-for="(item, index) in jobarr" :key="item.id">
      <div
        class="jobinf-card"
        :class="item.id == jobid ? 'hover-css' : ''"
        @mouseover="hadlerMouseOver(item.id)"
        @mouseout="hadlerMoseOut"
        @click="toJobInfDetail(item.id)"
      >
        <div class="usercard-top-name-lin">
          <div class="title overflw">{{ item.name }}</div>
          <div class="lin-view">
            <span class="dian"></span>
            <span class="lin">{{ item.recruit.online }}</span>
          </div>
          <div class="gz-view" v-show="item.minsalary !== '0'">￥{{ item.minsalary }}-{{ item.maxsalary }}</div>
          <div class="gz-view" v-show="item.minsalary === '0'">￥面议</div>
        </div>
        <div class="add-exp-edu">
          <span>{{ item.city }}</span>
          <el-divider direction="vertical"></el-divider>
          <span>{{ item.exp }}</span>
          <el-divider direction="vertical"></el-divider>
          <span>{{ item.edu }}</span>
        </div>
        <div class="user-ba-view">
          <label v-for="(tag, index) in item.welfare" :key="index">
            <span>{{ tag }}</span>
          </label>
        </div>
        <div class="bot-con-inf">
          <img class="com-head-img" :src="item.logo" alt="" />
          <span class="com-name">{{ item.com_name }}</span>
        </div>
      </div>
      <div v-show="(index + 1) % 3 != 0" class="card-fgx"></div>
    </label>
  </div>
</template>

<script>
export default {
  name: "jobinfcard",
  props: {
    jobarr: {
      type: Array,
    },
  },
  data() {
    return {
      jobid: 0,
    };
  },
  created() {
    console.log(",,,,,,,", this.jobarr);
  },
  methods: {
    toJobInfDetail(id) {
      this.$router.push(`/jobinfopage?id=${id}`);
    },
    hadlerMouseOver(id) {
      // console.log("悬停");
      this.jobid = id;
    },
    hadlerMoseOut() {
      this.jobid = 0;
    },
  },
};
</script>

<style>
.jobinf-card {
  width: 415px;
  height: 171px;
  background-color: #fff;
  border-radius: 16px;
  padding: 24px 28px;
  display: inline-block;
  margin-top: 24px;
  cursor: pointer;
}
.jobinf-card .hover-css {
  border: 1px solid #fe6002;
}

.usercard-top-name-lin {
  display: flex;
  align-items: center;
  position: relative;
}

.usercard-top-name-lin .title {
  font-size: 22px;
  
  font-weight: 600;
  color: #191919;
  max-width: 180px;
}
.usercard-top-name-lin .lin-view {
  display: flex;
  align-items: center;
  margin-left: 12px;
}
.usercard-top-name-lin .lin-view .dian {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #fe6002;
}
.usercard-top-name-lin .lin-view .lin {
  font-size: 14px;
  
  font-weight: 400;
  color: #757575;
  margin-left: 4px;
}

.usercard-top-name-lin .gz-view {
  font-size: 20px;
  
  font-weight: 600;
  color: #fe6002;
  position: absolute;
  right: 0;
}
.jobinf-card .add-exp-edu {
  font-size: 14px;
  
  font-weight: 400;
  color: #757575;
  margin-top: 16px;
}

.jobinf-card .user-ba-view {
  min-height: 35px;
  overflow: hidden;
  white-space: nowrap;
}
.jobinf-card .user-ba-view span {
  display: inline-block;
  background-color: #f5f6fa;
  font-size: 12px;
  
  font-weight: 400;
  color: #757575;
  text-align: center;
  margin-right: 14px;
  padding: 4px 8px;
  margin-top: 16px;
}
.bot-con-inf {
  border-top: 2px solid #f5f6fa;
  margin-top: 20px;
  display: flex;
  align-items: center;
  padding-top: 12px;
}
.bot-con-inf .com-head-img {
  width: 40px;
  height: 40px;
}
.bot-con-inf .com-name {
  font-size: 16px;
  
  font-weight: 400;
  color: #757575;
  margin-left: 12px;
}
.jobinf-card .overflw{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.job-info-div .jobinf-card:hover{
  background-color: #fff5f0;
}
</style>