/*
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2024-01-02 11:45:26
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-05-22 16:09:48
 * @FilePath: /lcwpcvue/src/api/commen/homeApi.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'
/**
* 获取首页banner
* @url Adverts/getAdverts
* @param {type,position: 'joblist',cid, } params 
* @return 
*/


export function getHomeBannerList(params) {
  return request({
    url: 'Adverts/getAdverts',
    method: 'post',
    data: params,
  })
}

/**
* 获取精选岗位 
* @url userPc/api
* @param {type,dataType } params 
* @return 
*/
export function getHomeJobList(params) {
  return request({
    url: 'userPc/api',
    method: 'post',
    data: params,
  })
}

/**
* 获取名企招聘 
* @url userPc/api
* @param {type,dataType } params 
* @return 
*/
export function getHomeCompyList(params) {
  return request({
    url: 'userPc/api',
    method: 'post',
    data: params,
  })
}

/**
* 获取 人才推荐
* @url userPc/api
* @param {type,dataType } params 
* @return 
*/
export function getHomeTanlList(params) {
  return request({
    url: 'userPc/api',
    method: 'post',
    data: params,
  })
}

/**
* 热门职位
* @url Comjob/hotjob
* @param { } params 
* @return 
*/
export function getHomeHotClass() {
  return request({
    url: 'Comjob/hotjob',
    method: 'get'
  })
}

/**
* 获取角标
* @url Wschat/msgNum
* @param { } params 
* @return 
*/
export function getMsgNumber() {
  return request({
    url: 'Wschat/msgNum',
    method: 'get'
  })
}

/**
 * 获取友情链接
 * @url webApi/getFriendlyLink
 * @return
 */
export function friendlyLink(obj) {
  return request({
    url: 'v2/webApi/getFriendlyLink',
    method: 'post',
    data: obj
  })
}

/**
 * 获取图片验证码
 */
export function captchaCreate() {
  return request({
    url: 'v2/webApi/getCaptcha',
    method: 'post'
  })
}

/**
 * h5获取banner
 * @url Adverts/getAdverts
 * @return
 */

export function getH5BannerList(params) {
  return request({
    // url: 'Adverts/getAdverts',
    url: 'v2/webApi/getBanner',
    method: 'get',
    params: params
  })
}
