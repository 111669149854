<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-10-17 17:26:46
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-06-07 10:13:30
 * @FilePath: /lcwpcvue/src/views/userHomePage.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <!--  -->
  <div class="user-home-page">
    <div class="top-zk-div"></div>
    <userinfleftmen
      v-if="showPageId != 20"
      :on-update="updatePageIndexData"
      class="left-menu"
    ></userinfleftmen>
    <span v-if="usertype == 1">
      <userinfindex class="userinf-index"></userinfindex>
      <!-- 
      <chatpage v-if="showPageId == 7" class="userinf-index"></chatpage> -->
    </span>
    <span v-if="usertype == 2">
      <companyindex
        :on-update="updatePageIndexData"
        class="userinf-index"
      ></companyindex>
    </span>

    <!-- <companyinfdetail
        v-if="showPageId == 3"
        class="userinf-index"
      ></companyinfdetail>
      <releasejob v-if="showPageId == 20"></releasejob>
      
     
      <myequitypage
        :on-update="updatePageIndexData"
        v-if="showPageId == 2"
        class="userinf-index"
      ></myequitypage>
    
      
      <mytransfer v-if="showPageId == 6" class="userinf-index"></mytransfer>
      -->
  </div>
</template>

<script>
import userinfleftmen from "@/components/userInfPageLeftMenu.vue";
import userinfindex from "@/components/userInfIndex.vue";

import companyindex from "@/components/comUser/companyUserIndex.vue";

// import releasejob from "@/components/comUser/releaseJob.vue";
// import myequitypage from "@/components/comUser/myEquityPage.vue";
// import mytransfer from "@/components/ transferShop/transferAndBuy.vue";

export default {
  components: {
    userinfleftmen,
    companyindex,
    userinfindex,

    // edtresume,
    // releasejob,
    // addressmanage,
    // subaccount,
    // mycouponlist,
    // vippaypage,
    // myequitypage,
    // myorderpage,
    // chatpage,
    // setpages,
    // helpfeed,
    // mytransfer,
    // resumemange,
    // jobmaage,
  },
  data() {
    return {
      usertype: 0,
      showPageId: 0,
    };
  },
  beforeCreate() {},
  created() {
    this.usertype = localStorage.usertype;
    console.log(this.$route.query);
    if (this.$route.query.showpageid) {
      this.showPageId = this.$route.query.showpageid;
    }
    console.log(this.showPageId);
  },
  mounted() {
    // if (this.$route.query.showpageid) {
    //   this.showPageId = this.$route.query.showpageid;
    // }
  },
  beforeUpdate() {
    // if (this.$route.query.showpageid) {
    //   this.showPageId = this.$route.query.showpageid;
    // }
  },
  methods: {
    updatePageIndexData(e) {
      console.log("=====", e);
      this.showPageId = e;
    },
  },
};
</script>

<style>
.user-home-page {
  width: 100%;
  display: flex;
  justify-content: center;
  /* margin-top: 32px; */
}
.left-menu {
  margin-top: 135px;
}

.userinf-index {
  margin-top: 135px;
  margin-left: 24px;
}
</style>