<template>
  <div class="user-home-page">
    <div class="top-zk-div"></div>
    <userinfleftmen
      :activeIndex="'/mytransfer'"
      class="left-menu"
    ></userinfleftmen>
    <mytransfer class="userinf-index"></mytransfer>
  </div>
</template>

<script>
import userinfleftmen from "@/components/userInfPageLeftMenu.vue";
import mytransfer from "@/components/ transferShop/transferAndBuy.vue";

export default {
  components: {
    userinfleftmen,
    mytransfer,
  },
};
</script>

<style>
</style>