<template>
  <div>
    <!-- <swiper :indicator-dots="indicatorDots" :autoplay="false">
			<label>
				<swiper-item> -->
    <div class="emoji_item">
      <label v-for="(item, key) in emojiObj.map1" :key="key">
        <img :src="item" mode="widthFix" @click="sendEmoji" :data-emoji="key" />
      </label>
    </div>
    <div class="emoji_item">
      <img
        v-for="(item, key) in emojiObj.map2"
        :src="item"
        mode="widthFix"
        :key="item"
        @click="sendEmoji"
        :data-emoji="key"
      />
    </div>
    <div class="emoji_item">
      <img
        v-for="(item, key) in emojiObj.map3"
        :src="item"
        mode="widthFix"
        :key="item"
        @click="sendEmoji"
        :data-emoji="key"
      />
    </div>
    <!-- </swiper-item>
			</label>
			<label class="second">
				<swiper-item> -->
    <div class="emoji_item">
      <img
        v-for="(item, key) in emojiObj.map4"
        :src="item"
        mode="widthFix"
        :key="item"
        @click="sendEmoji"
        :data-emoji="key"
      />
    </div>
    <div class="emoji_item">
      <img
        v-for="(item, key) in emojiObj.map5"
        :src="item"
        mode="widthFix"
        :key="item"
        @click="sendEmoji"
        :data-emoji="key"
      />
    </div>
    <div class="emoji_item">
      <img
        v-for="(item, key) in emojiObj.map6"
        :src="item"
        mode="widthFix"
        :key="item"
        @click="sendEmoji"
        :data-emoji="key"
      />
    </div>
    <!-- </swiper-item>
			</label>
		</swiper> -->
  </div>
</template>

<script>
export default {
  name: "emoji",
  data() {
    return {
      emoji: {
        map: {
          "[):]": "ee_1.png",
          "[:D]": "ee_2.png",
          "[;)]": "ee_3.png",
          "[:-o]": "ee_4.png",
          "[:p]": "ee_5.png",
          "[(H)]": "ee_6.png",
          "[:@]": "ee_7.png",
          "[:s]": "ee_8.png",
          "[:$]": "ee_9.png",
          "[:(]": "ee_10.png",
          "[:'(]": "ee_11.png",
          "[<o)]": "ee_12.png",
          "[(a)]": "ee_13.png",
          "[8o|]": "ee_14.png",
          "[8-|]": "ee_15.png",
          "[+o(]": "ee_16.png",
          "[|-)]": "ee_17.png",
          "[:|]": "ee_18.png",
          "[*-)]": "ee_19.png",
          "[:-#]": "ee_20.png",
          "[^o)]": "ee_21.png",
          "[:-*]": "ee_22.png",
          "[8-)]": "ee_23.png",
          "[del]": "btn_del.png",
          "[(|)]": "ee_24.png",
          "[(u)]": "ee_25.png",
          "[(S)]": "ee_26.png",
          "[(*)]": "ee_27.png",
          "[(#)]": "ee_28.png",
          "[(R)]": "ee_29.png",
          "[({)]": "ee_30.png",
          "[(})]": "ee_31.png",
          "[(k)]": "ee_32.png",
          "[(F)]": "ee_33.png",
          "[(W)]": "ee_34.png",
          "[(D)]": "ee_35.png",
        },
      },
      emojiObj: {
        // 相对 emoji.js 路径
        path: "@/assets/faces/",
        map1: {
          "[):]": require("@/assets/faces/ee_1.png"),
          "[:D]": require("@/assets/faces/ee_2.png"),
          "[;)]": require("@/assets/faces/ee_3.png"),
          "[:-o]": require("@/assets/faces/ee_4.png"),
          "[:p]": require("@/assets/faces/ee_5.png"),
          "[(H)]": require("@/assets/faces/ee_6.png"),
          "[:@]": require("@/assets/faces/ee_7.png"),
        },
        map2: {
          "[:s]": require("@/assets/faces/ee_8.png"),
          "[:$]": require("@/assets/faces/ee_9.png"),
          "[:(]": require("@/assets/faces/ee_10.png"),
          "[:'(]": require("@/assets/faces/ee_11.png"),
          "[<o)]": require("@/assets/faces/ee_12.png"),
          "[(a)]": require("@/assets/faces/ee_13.png"),
          "[8o|]": require("@/assets/faces/ee_14.png"),
        },
        map3: {
          "[8-|]": require("@/assets/faces/ee_15.png"),
          "[+o(]": require("@/assets/faces/ee_16.png"),
          "[|-)]": require("@/assets/faces/ee_17.png"),
          "[:|]": require("@/assets/faces/ee_18.png"),
          "[*-)]": require("@/assets/faces/ee_19.png"),
          "[:-#]": require("@/assets/faces/ee_20.png"),
          "[del]": require("@/assets/faces/del.png"),
        },
        map4: {
          "[^o)]": require("@/assets/faces/ee_21.png"),
          "[:-*]": require("@/assets/faces/ee_22.png"),
          "[8-)]": require("@/assets/faces/ee_23.png"),
          "[(|)]": require("@/assets/faces/ee_24.png"),
          "[(u)]": require("@/assets/faces/ee_25.png"),
          "[(S)]": require("@/assets/faces/ee_26.png"),
          "[(*)]": require("@/assets/faces/ee_27.png"),
        },
        map5: {
          "[(#)]": require("@/assets/faces/ee_28.png"),
          "[(R)]": require("@/assets/faces/ee_29.png"),
          "[({)]": require("@/assets/faces/ee_30.png"),
          "[(})]": require("@/assets/faces/ee_31.png"),
          "[(k)]": require("@/assets/faces/ee_32.png"),
          "[(F)]": require("@/assets/faces/ee_33.png"),
          "[(W)]": require("@/assets/faces/ee_34.png"),
          "[(D)]": require("@/assets/faces/ee_35.png"),
        },
        map6: {
          "[del]": require("@/assets/faces/del.png"),
        },
      },
      indicatorDots: true,
    };
  },
  methods: {
    sendEmoji(e) {
      let emoji = e.currentTarget.dataset.emoji;
      let emojicontent = {
        msg: emoji,
        type: "emoji",
      };
      this.$emit("newEmojiStr", emojicontent);
    },
  },
};
</script>

<style>
.emoji_list {
  margin-top: 30px;
  width: 100%;
  height: 145px;
  background-color: #dddddd;
  padding-top: 10px;
  padding-left: 3%;
  display: none;
}

.showEmoji {
  margin-top: 30px;
  width: 100%;
  height: 145px;
  background-color: #dddddd;
  padding-top: 10px;
  padding-left: 3%;
  display: block;
}

.emoji_list image,
.showEmoji image {
  width: 26px;
  height: 26px;
  margin: 5px 2%;
}

.emoji {
  width: 26px;
  height: 26px;
  margin: 0 0;
}

.emoji_item {
  display: flex;
  justify-content: space-around;
}

.emoji_item image {
  width: 80rpx;
}
</style>
