/*
 * @Author: your name
 * @Date: 2021-05-24 11:26:33
 * @LastEditTime: 2024-06-12 10:42:23
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /YongHuaShengDa/vue-admin-template/src/store/modules/user.js
 */
// import { login } from '@/api/commen/login'
// import { getToken,setToken, removeToken } from '@/utils/auth'
// import { resetRouter } from '@/router'
// import jwt_decode from "jwt-decode";
// import { formatDate } from '@/utils/datePas';
import { getIndUserInf, getComUserInf } from '@/api/commen/login'
import { getMsgNumber } from "@/api/commen/homeApi.js";

const getDefaultState = () => {
  return {
    usertype: 2,
    token: '',
    uid: '',
    name: '',
    avatar: '',
    edtime: '',
    userip: '',
    userinf: '',
    isfisterlogin: true,
    socketbindid: '',
    wdmsgnumber: '',
    headnavmenindex: '/home'
  }
}

const state = getDefaultState()

const mutations = {
  SET_USERTYPE: (state, usertype) => {
    console.log('用户类型', usertype);
    state.usertype = usertype
  },
  RESET_STATE: (state) => {
    state.usertype = 0
    state.token = ''
    state.uid = ''
    state.name = ''
    state.avatar = ''
    state.edtime = ''
    state.userip = ''
    state.userinf = ''
    state.isfisterlogin = true
    state.token = ''
  },
  SET_TOKEN: (state, token) => {
    // console.log('token', state, token);
    state.token = token
  },
  SET_USERINF: (state, userInf) => {
    // console.log('token', state, token);
    state.userinf = userInf
  },

  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_UID: (state, userId) => {
    console.log('setUserIds', userId);
    state.uid = userId
  },
  SET_EDTIME: (state, edTime) => {
    console.log('存贮过期', edTime);
    state.edtime = edTime
  },
  SET_USERIP: (state, userIp) => {
    state.userip = userIp
  },
  SET_LOGINSTATE: (state, isfisterlogin) => {
    state.isfisterlogin = isfisterlogin

  },
  SET_SOCKETID: (state, socketbindid) => {
    state.socketbindid = socketbindid

  },
  SET_WDMSGNUMBER: (state, msgnum) => {
    state.wdmsgnumber = msgnum

  },
  SET_NAVMENINDEX: (state, index) => {
    state.headnavmenindex = index

  }

}

const actions = {

  setUserType({ commit }, usertype) {
    commit('SET_USERTYPE', usertype)
  },
  setUserIp({ commit }, userip) {
    commit('SET_USERIP', userip)
  },
  setToken({ commit }, token) {
    commit('SET_TOKEN', token)
    // commit('SET_TOKEN', 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJpc3MiLCJhdWQiOiJhdWQiLCJqdGkiOiI3MTVhZjYzMTNmNTBiNzRkYTdmZWU1YWIzNTE0NDAzNSIsImlhdCI6MTY4NjkwMjAzOCwibmJmIjoxNjg2OTAyMDM4LCJleHAiOjE2ODk0OTQwMzgsInVpZCI')

  },
  setUserUid({ commit }, uid) {
    commit('SET_UID', uid)
  },
  setIsFisterLoginState({ commit }, isF) {
    commit('SET_LOGINSTATE', isF)
  },
  setUserSocketBindId({ commit }, socketid) {
    commit('SET_SOCKETID', socketid)
  },

  setWdMsgNumber({ commit }) {
    getMsgNumber()
      .then((result) => {
        var num = parseInt(result.data.num);
        commit('SET_WDMSGNUMBER', num)
      })
      .catch(() => { });
  },

  setNavmenIndex({ commit }, index) {
    commit('SET_NAVMENINDEX', index)
  },
  // user login
  // login({ commit }, userInfo) {

  // },



  // // get user info
  setInfo({ commit }) {
    console.log(';.;.;.;.;.;.;', localStorage.usertype);
    return new Promise((resolve) => {
      if (localStorage.usertype == 1) {
        // console.log('mmmmmm', state.usertype);
        getIndUserInf().then((result) => {
          console.log('求职用户信息', result);
          commit('SET_UID', result.data.uid)
          commit('SET_NAME', result.data.name)
          commit('SET_AVATAR', result.data.resume_photo)
          commit('SET_USERINF', result.data)
          resolve(result)
        }).catch(() => {

        });
      } else {
        getComUserInf().then((result) => {
          console.log('招聘用户信息', result);
          commit('SET_UID', result.data.uid)
          commit('SET_NAME', result.data.name)
          commit('SET_AVATAR', result.data.resume_photo)
          commit('SET_USERINF', result.data)
          resolve(result)
        }).catch(() => {

        });
      }
    })
  },
  // user logout
  logout({ commit }) {
    return new Promise((resolve) => {
      localStorage.removeItem("token");
      commit('RESET_STATE')
      resolve()
    })
  },
  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      // localStorage.removeItem("usertype");
      console.log('清除掉');
      localStorage.removeItem("token");
      console.log(localStorage.token);
      commit('RESET_STATE')
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

