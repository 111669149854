<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-09-27 14:00:07
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2023-10-07 14:47:19
 * @FilePath: /lcwpcvue/src/components/trnasferListItem.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="transferList-item">
    <div
      :class="item.transfer_status === '0' ? 'tranfer-list-item' : 'store-sold-out-div'"
      v-for="item in transferlist"
      :key="item.id"
      @click="toTransferDetail(item.id,item.transfer_status)"
    >
      <img
          v-show="item.have_video ==='1'"
          src="@/assets/transfer_spplay.png"
          style="width: 40px;height: 40px;position: absolute;left: 130px;top: 90px;"
      />
      <img v-if="item.transfer_status === '1'" src="@/assets/store-sold-out.png" class="store-sold-out-img">
      <div class="shop-top-img">
        <img :src="item.avatar" alt="" />
      </div>
      <div class="name-lxsta-addr-tag">
        <div class="name-istj">
          <span class="istj-view" v-show="item.xsdate != 0">推荐</span>
          <span class="com-name-sl overflow-hidden" :style="item.transfer_status === '1' ? 'color: rgba(25, 25,25, 0.5)' : ''">{{ item.title }}</span>
        </div>
        <div class="type-status">
          <span>{{ item.type_name }}</span>
          <el-divider direction="vertical"></el-divider>
          <span>{{item.transfer_status ==='1' ? '已转出' : '转让中'}}</span>
        </div>
        <div class="address-view">
          <img src="../assets/jobinf_addr_img.png" alt="" />
          <span
            >{{ item.province_name }}{{ item.city_name
            }}{{ item.district_name }}</span
          >
        </div>
        <div class="tags-view">
          <label v-for="(more, index) in item.more" :key="index">
            <span class="tags-sl">{{ more }}</span>
          </label>
          <!-- <span class="tag">停车位</span>
          <span class="tag">医疗设备</span>
          <span class="tag">美容工具</span> -->
        </div>
      </div>
      <div class="right-shopinf-view">
        <div class="pr-items">
          <div class="tt1" :style="item.transfer_status === '1' ? 'color: rgba(254, 96, 2, 0.5)' : ''">{{ item.area }}m²</div>
          <div class="tt2">店铺面积</div>
        </div>
        <div class="shuxian-fg"></div>
        <!-- <el-divider direction="vertical"></el-divider> -->

        <div class="pr-items">
          <div class="tt1" :style="item.transfer_status === '1' ? 'color: rgba(254, 96, 2, 0.5)' : ''">{{ item.rent }}元/月</div>
          <div class="tt2">转让费 {{ item.transfer_fee }}</div>
        </div>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    transferlist: {
      type: Array,
    },
  },
  data() {
    return {};
  },
  methods: {
    toTransferDetail(id,status) {
      console.log('status:',status)
      if (status === "0"){
        let newRouter = this.$router.resolve({
          path:`/transferdetail?id=${id}`
        });
        window.open(newRouter.href,"_blank")
      }
    },
  },
};
</script>

<style>
.tranfer-list-item {
  width: 986px;
  background-color: #fff;
  display: flex;
  border-radius: 16px;
  margin-top: 20px;
  padding: 30px 24px;
  position: relative;
  cursor: pointer;
  border: #fff 1px solid;
}
.tranfer-list-item:hover{
  background-color: #fff5f0;
  border: #fe6002 1px solid;
}
.shop-top-img img {
  width: 200px;
  height: 150px;
  border-radius: 8px;
}
.transferList-item .name-lxsta-addr-tag {
  margin-left: 16px;
  /*margin-top: 8px;*/
}
.transferList-item .name-lxsta-addr-tag .name-istj {
  display: flex;
  align-items: center;
  /*margin-top: 6px;*/
}

.name-lxsta-addr-tag .name-istj .istj-view {
  display: inline-block;
  width: 36px;
  height: 18px;
  background-color: #fe6002;
  font-size: 12px;
  
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 18px;
  border-top-right-radius: 6px;
  border-bottom-left-radius: 6px;
  margin-right: 6px;
}

.name-lxsta-addr-tag .name-istj .com-name-sl {
  display: inline-block;
  font-size: 22px;
  
  font-weight: 600;
  color: #191919;
  width: 490px;
  margin-top: 3px;
}
.name-lxsta-addr-tag .type-status {
  font-size: 14px;
  
  font-weight: 400;
  color: #757575;
  line-height: 16px;
  margin-top: 16px;
}
.name-lxsta-addr-tag .address-view {
  display: flex;
  align-items: center;
  font-size: 14px;
  
  font-weight: 400;
  color: #757575;
  margin-top: 16px;
}
.name-lxsta-addr-tag .address-view img {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}
.name-lxsta-addr-tag .tags-view {
  margin-top: 18px;
}
.name-lxsta-addr-tag .tags-view span {
  display: inline-block;
  background-color: #f5f6fa;
  padding: 4px 8px;
  font-size: 12px;
  
  font-weight: 400;
  color: #757575;
  line-height: 14px;
}
.right-shopinf-view {
  position: absolute;
  right: 23px;
  display: flex;
}

.right-shopinf-view .pr-items .tt1 {
  font-size: 20px;
  
  font-weight: 600;
  color: #fe6002;
  line-height: 28px;
}

.right-shopinf-view .pr-items .tt2 {
  font-size: 14px;
  
  font-weight: 400;
  color: #757575;
  line-height: 16px;
  margin-top: 8px;
}
.right-shopinf-view .shuxian-fg {
  width: 1px;
  height: 40px;
  background-color: #f5f6fa;
  margin: 0 20px;
}
.store-sold-out-div{
  background-color: #fff;
  z-index: 222;
  width: 986px;
  display: flex;
  border-radius: 16px;
  margin-top: 20px;
  padding: 30px 24px;
  position: relative;
  border: #fff 1px solid;
}
.store-sold-out-img{
  position: absolute;
  top: 47px;
  left: 490px;
  width: 120px;
  height: 120px;
}
.transferList-item .tags-sl {
  display: flex;
  flex-wrap: wrap;
  
  font-weight: 400;
  margin-right: 16px;
  margin-bottom: 10px;
}

.transferList-item .tags-sl span {
  padding: 0 8px;
  font-size: 12px;
  color: #757575;
  background: #f5f6fa;
  border-radius: 4px;
  height: 25px;
  line-height: 25px;
}
</style>