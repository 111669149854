<template>
  <div class="user-inf-index">
    <div class="userinf-page-top-view">
      <div class="avatar-bjbtn-topview">
        <div class="left-avatar-view">
          <img :src="userInf.resume_photo" alt="" class="avatar-img" />
          <div class="name-status-view">
            <div class="name-view">
              <span class="name">{{ getSalutation }}，{{ userInf.name }}</span>
              <img
                :src="
                  userInf.sex === '1'
                    ? require('../assets/gender_nan.png')
                    : require('../assets/gender_nv.png')
                "
                alt=""
                class="sex-img"
              />
            </div>
            <div class="rz-status">
              <div class="status-item" v-show="userInf.wxnum">
                <img src="../assets/findjob_yrzimg.png" alt="" />
                <span>微信已绑定</span>
              </div>
              <div
                class="status-item"
                style="cursor: pointer"
                v-show="!userInf.wxnum"
                @click="rutDestination('/setpage')"
              >
                <img src="../assets/findjob_wrzimg.png" alt="" />
                <span>微信未绑定</span>
              </div>
              <div class="status-item" v-show="userInf.authCount != 0">
                <img src="../assets/findjob_yrzimg.png" alt="" />
                <span>身份已认证</span>
              </div>
              <div
                class="status-item"
                style="cursor: pointer"
                v-show="userInf.authCount == 0"
                @click="rutDestination('/setpage')"
              >
                <img src="../assets/findjob_wrzimg.png" alt="" />
                <span>身份未认证</span>
              </div>
            </div>
          </div>
        </div>
        <div class="right-time-look-btn-view">
          <div class="top-time-look-view">
            <div class="up-look-item">
              <img src="../assets/jobinf_uptime_img.png" alt="" />
              <span>更新于 {{ getOnlineTime }}</span>
            </div>
            <div class="up-look-item">
              <img src="../assets/jobinf_look_img.png" alt="" />
              <span>浏览 {{ userInf.hits }}次</span>
            </div>
          </div>
          <div class="bbj-btn-view">
            <el-button  type="primary" class="bj-btn"  plain @click="previewResume"
              >预览简历</el-button
            >
            <el-button @click="toEdtResume" class="bj-btn" type="primary"
              >编辑简历</el-button
            >
          </div>
        </div>
      </div>
      <div class="bottom-num-view">
        <div
          class="nums-item"
          @click="rutDestination('/jobInformation?type=0')"
        >
          <div class="num1">{{ sysPush.shoudao }}</div>
          <div class="num2">我的投递</div>
        </div>
        <div
          class="nums-item"
          @click="rutDestination('/jobInformation?type=1')"
        >
          <div class="num1">{{ userInf.link_num }}</div>
          <div class="num2">沟通过</div>
        </div>
        <div
          class="nums-item"
          @click="rutDestination('/jobInformation?type=2')"
        >
          <!--          <div class="num1">{{ userInf.wait_interview }}</div>-->
          <div class="num1">
            <div
              class="userinfo-new-params"
              v-show="userInf.wait_interview !== '0'"
            >
              {{ userInf.wait_interview }}
            </div>
            {{ myParams.interview }}
          </div>
          <div class="num2">待面试</div>
        </div>
        <div
          class="nums-item"
          @click="rutDestination('/jobInformation?type=3')"
        >
          <div class="num1">{{ userInf.job_history }}</div>
          <div class="num2">浏览记录</div>
        </div>
        <div
          class="nums-item"
          @click="rutDestination('/jobInformation?type=4')"
        >
          <div class="num1">{{ userInf.collection_num }}</div>
          <div class="num2">我的收藏</div>
        </div>
        <div
          class="nums-item"
          @click="rutDestination('/jobInformation?type=5')"
        >
          <!--          <div class="num1">{{ userInf.kanguo }}</div>-->
          <div class="num1">
            <div class="userinfo-new-params" v-show="userInf.kanguo !== '0'">
              {{ userInf.kanguo }}
            </div>
            {{ myParams.seeMe }}
          </div>
          <div class="num2">谁浏览了我</div>
        </div>
      </div>
    </div>

    <div class="model-title-view">
      <div class="lt-xian"></div>
      <div class="title-text">简历管理</div>
    </div>
    <div class="resume-baseinf-view">
      <img class="left-img" src="@/assets/resume_logo.png" alt="" />
      <div class="min-text-view">
        <div class="inf-text-inf">
          <span>简历状态：</span>
          <span class="spantext1">{{
            userInf.r_status == 0
              ? "审核中"
              : userInf.r_status == 3
              ? "未通过"
              : "正常"
          }}</span>
          <el-button
              v-show="userInf.status === '2'"
              style="height: 25px;padding: 0 10px;margin-left: 20px;font-weight: bold"
              type="primary"
              plain
              @click="toPrivacyPage"
          >简历已隐藏</el-button>
        </div>
        <div class="inf-text-inf">
          <span>求职状态：</span>
          <span>{{ userInf.report }}</span>
        </div>
        <div class="progss-view">
          <el-progress
            :stroke-width="8"
            :percentage="userInf.integrity"
            :show-text="false"
            class="progss-wzd"
          ></el-progress>
          <div class="pro-text">
            简历完整度<span>{{ userInf.integrity }}%</span>
          </div>
        </div>
        <div class="bot-bjbtn-rejl-btn">
          <el-button
            class="jl-act-btn"
            type="primary"
            plain
            @click="toEdtResume"
            >编辑简历</el-button
          >
          <el-button
            class="jl-act-btn"
            type="primary"
            icon="el-icon-refresh"
            @click="refreshResumeV2Fun"
            >刷新简历</el-button
          >
          <div class="ref-text-view" v-show="!isShowRefreshMsg">
            <span>刷新简历可提升排名，每天一刷，提高求职成功率！</span>
            <img src="../assets/colse.png" alt="" @click="closeRefreshMsg()" />
          </div>
        </div>
      </div>
      <div class="right-jljh-jlzd-jldt-view">
        <div class="item" @click="resumePayFun('optimize')">
          <img src="../assets/userinf_jlyh.png" alt="" />
          <div class="it-text">简历优化</div>
        </div>
        <div class="item" @click="resumePayFun('top')">
          <img src="../assets/userinf_jlzd.png" alt="" />
          <div class="it-text">简历置顶</div>
        </div>
        <div class="item" @click="resumePayFun('cu')">
          <img src="../assets/userinf_jldt.png" alt="" />
          <div class="it-text">简历代投</div>
        </div>
      </div>
    </div>

    <div class="model-title-view" v-show="jobList.length > 0">
      <div class="lt-xian"></div>
      <div class="title-text">可能感兴趣的职位</div>
    </div>
    <jobcard :jobList="jobList"></jobcard>

    <el-dialog
      width="400px"
      :title="payDialogTile"
      :visible.sync="payDialogShow"
    >
      <div class="pay-inf-view">
        <div class="pay-chose-list-view" v-if="resumeType == 'top'">
          <label v-for="item in topArr.day_num" :key="item">
            <div
              class="chose-item"
              :class="choseComboId == item ? 'chose-item-sl' : ''"
              @click="chageComboId(item, parseInt(item * topArr.day_price))"
            >
              <div class="day-text">{{ item }}天</div>
              <div class="mony-text">
                <span>￥</span>{{ item * topArr.day_price }}
              </div>
            </div>
          </label>
        </div>

        <div class="pay-type-view" v-show="payViewShow">
          <div class="paytype-title">{{ payDialogTile }}</div>
          <div class="paytype">
            <div
              class="paytype-item"
              @click="changePayType('wxpay')"
              :class="payType == 'wxpay' ? 'item-sl' : ''"
            >
              <img src="@/assets/vip_paytype_wx_img.png" alt="" />
              <span>微信支付</span>
            </div>
            <div
              class="paytype-item"
              @click="changePayType('alipay')"
              :class="payType == 'alipay' ? 'item-sl' : ''"
            >
              <img src="@/assets/vip_paytype_zfb_img.png" alt="" />
              <span>支付宝支付</span>
            </div>
          </div>
        </div>
        <div v-show="payViewShow">
          <div class="wx-pay-img-view" v-show="payType == 'wxpay'">
            <div class="wxpay-tt1">
              请使用<label>微信</label>扫描二维码完成支付
            </div>
            <div class="wxpay-tt2">
              如无法支付或二维码已过期请点击<label>刷新</label>
            </div>
            <img class="wxpay-ewm-img" :src="wxPayImgUrl" alt="" />
            <div class="wxpay-tt2" @click="openAgreementFun">
              购买付费即表示同意<label>《猎宠网VIP服务协议》</label>
            </div>
          </div>
          <div v-show="payType == 'alipay'" slot="footer" class="dialog-footer">
            <el-button type="primary" @click="toAliPayPage">去支付</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
    <com-dialog
      :isopen="isOpenAgreement"
      dialogTetle="猎宠网VIP服务协议"
      @dialogFrameEvent="agreementEvent"
      myWidth="1280px"
    >
      <div v-html="vipServe" class="vipServe-content"></div>
    </com-dialog>
  </div>
</template>

<script>
import store from "@/store";
import jobcard from "../components/comUser/jobCardInFindJob.vue";
import { parseTime } from "@/utils/datePas";
import {
  getSysPush,
  refreshResumeV2,
  getOptimizePrice,
  getTopPrice,
  postAssistSend,
  postOrder,
  postCommonWay,
} from "@/api/indiviuser/userInf";
import { getJobList } from "@/api/commen/findJobList";
import { userPcApi } from "@/api/commen/setApi";
import { getUserProfile } from "@/api/commen/login";
import { myCenterParams } from "@/api/commen/resume";
import ComDialog from "@/components/comDialog.vue";
export default {
  components: {
    ComDialog,
    jobcard,
  },
  data() {
    return {
      jobList: [],
      isLogin: false,
      isShowRefreshMsg: 1,
      userInf: {},
      sysPush: {
        seeme: 0,
        shoudao: 0,
      },
      payDialogShow: false,
      resumeType: "",
      payViewShow: true,
      aliNatoUrl: "",
      payDialogTile: "",
      topArr: [],
      choseComboId: 0,
      vipServe: "",
      payType: "wxpay",
      wxPayImgUrl: "",
      myParams: {},
      isOpenAgreement:false
    };
  },
  computed: {
    getOnlineTime() {
      if (!this.userInf.online) return "";
      let timestamp = this.userInf.online * 1000;
      let currentTime = new Date().getTime();
      let timeDiff = currentTime - timestamp;
      const hoursDiff = timeDiff / (1000 * 60 * 60);
      console.log("格式加时间：", parseTime(timestamp, "{y}-{m}-{d}"));
      if (hoursDiff < 1) {
        return "1小时内";
      } else if (hoursDiff < 2) {
        return "2小时内";
      } else if (hoursDiff < 6) {
        return "6小时内";
      } else if (hoursDiff < 12) {
        return "12小时内";
      } else {
        return parseTime(timestamp, "{y}-{m}-{d}");
      }
    },
    getSalutation() {
      let hour = new Date().getHours();
      let msg = "";
      if (hour >= 5 && hour < 9) {
        msg = "早上好";
      } else if (hour >= 9 && hour < 12) {
        msg = "上午好";
      } else if (hour >= 12 && hour < 14) {
        msg = "中午好";
      } else if (hour >= 14 && hour < 18) {
        msg = "下午好";
      } else {
        msg = "晚上好";
      }
      return msg;
    },
  },
  created() {
    this.getSysPushFun();
    this.getJobList();
    store.dispatch("user/setInfo").then(() => {
      this.userInf = store.getters.userinf;
    });
    this.isShowRefreshMsg = localStorage.getItem("isShowRefreshMsg");
    this.getMyParams();
  },
  methods: {
    toPrivacyPage(){
      this.$router.push('/privacy');
    },
    previewResume() {
      this.$router.push(`/talentsdetail?id=${this.userInf.uid}&isyl=1`);
    },
    getMyParams() {
      myCenterParams().then((e) => {
        if (e.data) {
          this.myParams = e.data;
        }
      });
    },
    rutDestination(destination) {
      this.$router.push(destination);
    },
    closeRefreshMsg() {
      this.isShowRefreshMsg = 1;
      localStorage.setItem("isShowRefreshMsg", 1);
    },
    toEdtResume() {
      this.$router.push("/useredtresume");
    },
    getSysPushFun() {
      getSysPush().then((res) => {
        this.sysPush.seeme = res.data.seeme;
        this.sysPush.shoudao = res.data.shoudao;
      });
    },
    refreshResumeV2Fun() {
      refreshResumeV2().then(() => {
        this.$message.success("刷新成功");
      });
    },
    getJobList() {
      userPcApi({ type: "intendedCity" }).then((res) => {
        let cityArr = {};
        if (res.data.city.length > 1) {
          let randomIndex = Math.floor(Math.random() * res.data.city.length);
          cityArr = res.data.city[randomIndex];
        } else {
          cityArr = res.data.city[0];
        }
        let jobArr = {};
        if (res.data.jobs.length > 1) {
          let randomIndex = Math.floor(Math.random() * res.data.jobs.length);
          jobArr = res.data.jobs[randomIndex];
        } else {
          jobArr = res.data.jobs[0];
        }
        let params = {
          page: 1, // 页码
          column: "new", //  recommend推荐 new最新
          provinceid: cityArr.provinceid, // 省
          cityid: cityArr.cityid === "0" ? "all" : cityArr.cityid, // 市
          three_cityid: "all", // 区
          is_qg: 0,
          jobclass_id: jobArr.job1_son === "0" ? jobArr.job1 : jobArr.job1_son, // 顶部期望职位
          job_id: "all", // 职业, 例: 宠物医生
          search_key: "", // 搜索内容
          edu: "all", // 教育经历
          exp: "all", // 工作经历
          type: "all", // 职位类型 全职,兼职
          min_value: "all", // 期望薪资最小值
          max_value: "all", // 期望薪资最大值
          welfares: "all", //福利筛选
        };
        console.log(888888);
        getJobList(params)
          .then((result) => {
            console.log("获取职位列表", result);
            if (result.data.jobs) {
              this.jobList = result.data.jobs;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    resumePayFun(type) {
      this.resumeType = type;
      if (type == "optimize") {
        this.payDialogTile = "简历优化";
        getOptimizePrice()
          .then((result) => {
            console.log("简历优化价格", result);
            if (result.data.price == 0) {
              this.$confirm("是否要立即体验?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              })
                .then(() => {
                  postCommonWay({
                    type: "buyResumeSeo",
                    pay_type: "wxpay",
                    rating_id: 5, // 简历置顶参数
                    rating_type: "once", // 后端有 day 日 week 周 month 月
                    optimize_resume: 1,
                  })
                    .then((result) => {
                      if (result.code == 200) {
                        this.$message({
                          message: result.msg,
                          type: "success",
                        });
                      }
                    })
                    .catch(() => {});
                })
                .catch(() => {
                  this.$message({
                    type: "info",
                    message: "已取消",
                  });
                });
            } else {
              this.payDialogShow = true;
              this.payViewShow = true;
              postOrder({
                rating_id: 5,
                rating_type: "once",
                pay_type: this.payType,
                optimize_resume: 1,
              })
                .then((result) => {
                  console.log("简历置顶下单", result);
                  if (this.payType == "wxpay") {
                    this.wxPayImgUrl = result.data.pay_img;
                  } else {
                    this.aliNatoUrl = result.data.url;
                  }
                })
                .catch(() => {});
            }
          })
          .catch(() => {});
      } else if (type == "top") {
        this.payDialogShow = true;
        this.payDialogTile = "简历置顶";
        getTopPrice()
          .then((result) => {
            console.log("简历置顶", result);
            this.topArr = result.data;
            this.choseComboId = result.data.day_num[0];
            postOrder({
              rating_id: 2,
              rating_type: "day",
              pay_type: this.payType,
              rating_num: result.data.day_num[0],
            })
              .then((result) => {
                console.log("简历置顶下单", result);
                if (this.payType == "wxpay") {
                  this.wxPayImgUrl = result.data.pay_img;
                } else {
                  this.aliNatoUrl = result.data.url;
                }
              })
              .catch(() => {});
          })
          .catch(() => {});
      } else {
        this.$confirm("是否要立即体验?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            postAssistSend()
              .then((result) => {
                if (result.code == 200) {
                  this.$message({
                    message: result.msg,
                    type: "success",
                  });
                }
              })
              .catch(() => {});
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      }
    },
    chageComboId(combid) {
      console.log("00000000", combid);
      this.choseComboId = combid;
      postOrder({
        rating_id: 2,
        rating_type: "day",
        pay_type: this.payType,
        rating_num: this.choseComboId,
      })
        .then((result) => {
          console.log("简历置顶下单", result);
          if (this.payType == "wxpay") {
            this.wxPayImgUrl = result.data.pay_img;
          } else {
            this.aliNatoUrl = result.data.url;
          }
        })
        .catch(() => {});
    },

    changePayType(type) {
      this.payType = type;
      postOrder({
        rating_id: 2,
        rating_type: "day",
        pay_type: this.payType,
        rating_num: this.choseComboId,
      })
        .then((result) => {
          console.log("简历置顶下单", result);
          if (this.payType == "wxpay") {
            this.wxPayImgUrl = result.data.pay_img;
          } else {
            this.aliNatoUrl = result.data.url;
          }
        })
        .catch(() => {});
    },
    toAliPayPage() {
      window.open(this.aliNatoUrl, "_blank");
    },
    openAgreementFun() {
      console.log("打开vip协议");
      if (!this.vipServe) {
        getUserProfile("/vipServe").then((res) => {
          this.vipServe = res.data;
          this.isOpenAgreement = true;
        });
      } else {
        this.isOpenAgreement = true;
      }
    },
    agreementEvent() {
      this.isOpenAgreement = false;
    },
  },
};
</script>

<style>
.user-inf-index .el-dialog__wrapper .el-dialog {
  border-radius: 20px;
}
.user-inf-index .job-card {
  width: 1180px !important;
}
.userinf-page-top-view {
  background-color: #fff;
  width: 1183px;
  height: 244px;
  border-radius: 16px;
  padding: 16px 24px;
}
.userinf-page-top-view .avatar-bjbtn-topview {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.userinf-page-top-view .avatar-bjbtn-topview .left-avatar-view {
  display: flex;
  align-items: center;
}
.userinf-page-top-view .avatar-bjbtn-topview .left-avatar-view .avatar-img {
  width: 88px;
  height: 88px;
  border-radius: 50%;
}
.userinf-page-top-view
  .avatar-bjbtn-topview
  .left-avatar-view
  .name-status-view {
  margin-left: 17px;
}
.userinf-page-top-view
  .avatar-bjbtn-topview
  .left-avatar-view
  .name-status-view
  .name-view {
  display: flex;
  align-items: center;
  position: relative;
}

.userinf-page-top-view
  .avatar-bjbtn-topview
  .left-avatar-view
  .name-status-view
  .name-view
  .name {
  font-size: 20px;

  font-weight: 600;
  color: #222222;
  line-height: 28px;
}

.userinf-page-top-view
  .avatar-bjbtn-topview
  .left-avatar-view
  .name-status-view
  .name-view
  .sex-img {
  width: 20px;
  height: 20px;
  position: absolute;
  left: -40px;
  top: 50px;
}
.userinf-page-top-view
  .avatar-bjbtn-topview
  .left-avatar-view
  .name-status-view
  .rz-status {
  display: flex;
  align-items: center;
  margin-top: 16px;
}

.userinf-page-top-view
  .avatar-bjbtn-topview
  .left-avatar-view
  .name-status-view
  .rz-status
  .status-item {
  display: flex;
  align-items: center;
  font-size: 14px;

  font-weight: 400;
  color: #b1b1b1;
  line-height: 16px;
  margin-right: 16px;
}
.userinf-page-top-view
  .avatar-bjbtn-topview
  .left-avatar-view
  .name-status-view
  .rz-status
  .status-item
  img {
  width: 14px;
  height: 14px;
  margin-right: 8px;
}
.userinf-page-top-view .right-time-look-btn-view {
  text-align: right;
}

.userinf-page-top-view .right-time-look-btn-view .top-time-look-view {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.userinf-page-top-view
  .right-time-look-btn-view
  .top-time-look-view
  .up-look-item {
  display: flex;
  align-items: center;
  font-size: 14px;

  font-weight: 400;
  color: #b1b1b1;
  line-height: 16px;
  margin-left: 36px;
}

.userinf-page-top-view
  .right-time-look-btn-view
  .top-time-look-view
  .up-look-item
  img {
  width: 16px;
  height: 16px;
  margin-right: 2px;
}
.userinf-page-top-view .right-time-look-btn-view .bbj-btn-view {
  margin-top: 29px;
}
.userinf-page-top-view .right-time-look-btn-view .bbj-btn-view .bj-btn {
  width: 258px;
  height: 52px;
  font-size: 18px;

  font-weight: 600;
}
.bottom-num-view {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 70px;
}
.bottom-num-view .nums-item {
  flex: 1;
  text-align: center;
  border-right: 1px solid #e7e8eb;
  cursor: pointer;
}
.bottom-num-view .nums-item:last-child {
  border-right: none;
}

.bottom-num-view .nums-item .num1 {
  font-size: 34px;

  font-weight: 500;
  color: #222222;
  line-height: 40px;
  position: relative;
}
.bottom-num-view .nums-item .num2 {
  font-size: 18px;

  font-weight: 400;
  color: #757575;
  line-height: 24px;
}
.model-title-view {
  margin-top: 40px;
  display: flex;
  align-items: center;
}
.model-title-view .lt-xian {
  width: 4px;
  height: 20px;
  background-color: #fe6002;
  border-radius: 5px;
}

.model-title-view .title-text {
  font-size: 24px;

  font-weight: 600;
  color: #222222;
  line-height: 28px;
  margin-left: 16px;
}

.resume-baseinf-view {
  background-color: #fff;
  width: 1183px;
  /* height: 244px; */
  border-radius: 16px;
  padding: 16px 24px;
  margin-top: 24px;
  display: flex;
  align-items: flex-start;
  position: relative;
}

.resume-baseinf-view .left-img {
  width: 145px;
  height: 164px;
}
.resume-baseinf-view .min-text-view {
  margin-left: 40px;
}
.resume-baseinf-view .min-text-view .inf-text-inf {
  font-size: 18px;

  font-weight: 400;
  color: #191919;
  line-height: 24px;
  margin-top: 10px;
}
.resume-baseinf-view .min-text-view .inf-text-inf .spantext1 {
  color: #f34b37;
}

.resume-baseinf-view .min-text-view .progss-view {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.resume-baseinf-view .min-text-view .progss-view .progss-wzd {
  width: 150px;
}

.resume-baseinf-view .min-text-view .progss-view .pro-text {
  font-size: 18px;

  font-weight: 400;
  color: #191919;
  margin-left: 12px;
}
.resume-baseinf-view .min-text-view .progss-view .pro-text span {
  color: #fe6002;
}

.resume-baseinf-view .min-text-view .bot-bjbtn-rejl-btn {
  display: flex;
  align-items: center;
  margin-top: 24px;
  position: relative;
}

.resume-baseinf-view .min-text-view .bot-bjbtn-rejl-btn .jl-act-btn {
  width: 140px;
  height: 44px;
  font-size: 18px;
  font-weight: 600;
}

.resume-baseinf-view .min-text-view .bot-bjbtn-rejl-btn .ref-text-view {
  width: 330px;
  height: 25px;
  background: #fff8f4;
  border-radius: 2px;
  display: flex;
  align-items: center;
  font-size: 12px;

  font-weight: 400;
  color: #fe6002;
  line-height: 14px;
  margin-left: 14px;
  padding-left: 8px;
  /* position: absolute; */
}
.resume-baseinf-view .min-text-view .bot-bjbtn-rejl-btn .ref-text-view img {
  width: 12px;
  height: 12px;
  margin-left: 10px;
  cursor: pointer;
}

.resume-baseinf-view .right-jljh-jlzd-jldt-view {
  display: flex;
  align-items: center;
  position: absolute;
  right: 24px;
}

.resume-baseinf-view .right-jljh-jlzd-jldt-view .item {
  text-align: center;
  margin-left: 60px;
}

.resume-baseinf-view .right-jljh-jlzd-jldt-view .item img {
  width: 60px;
  height: 60px;
}

.resume-baseinf-view .right-jljh-jlzd-jldt-view .item .it-text {
  font-size: 18px;

  font-weight: 400;
  color: #757575;
  line-height: 24px;
}
.user-inf-index .userinfo-new-params {
  width: 24px;
  height: 24px;
  background-color: red;
  border-radius: 12px;
  font-size: 12px;
  color: white;
  line-height: 22px;
  text-align: center;
  position: absolute;
  left: 120px;
  top: -10px;
}
</style>