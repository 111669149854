<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-06-20 17:16:09
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-02-21 15:57:37
 * @FilePath: /lcwpcvue/src/components/comUser/stepCom.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="step-pg">
    <div class="step-item">
      <div class="step-num" :class="stepnub == 1 ? 'step-sl' : ''">1</div>
      <div class="tt1" :class="stepnub == 1 ? 'ttt2sl' : ''">创建公司信息</div>
      <div class="xian"></div>
    </div>
    <div class="step-item">
      <div class="step-num" :class="stepnub == 2 ? 'step-sl' : ''">2</div>
      <div class="tt1" :class="stepnub == 2 ? 'ttt2sl' : ''">企业认证</div>
      <div class="xian"></div>
    </div>
    <div class="step-item">
      <div class="step-num" :class="stepnub == 3 ? 'step-sl' : ''">3</div>
      <div class="tt1" :class="stepnub == 3 ? 'ttt2sl' : ''">发布职位</div>
      <div class="xian"></div>
    </div>
    <div class="step-item">
      <div class="step-num" :class="stepnub == 4 ? 'step-sl' : ''">4</div>
      <div class="tt1" :class="stepnub == 4 ? 'ttt2sl' : ''">招聘人才</div>
    </div>
    <!-- <el-steps :active="1">
      <el-step
        title="步骤 1"
        description="这是一段很长很长很长的描述性文字"
      ></el-step>
      <el-step
        title="步骤 2"
        description="这是一段很长很长很长的描述性文字"
      ></el-step>
      <el-step title="步骤 3" description="这段就没那么长了"></el-step>
    </el-steps> -->
  </div>
</template>
 
<script>
export default {
  props: {
    stepnub: {
      type: Number,
      default: 1,
    },
  },
};
</script>

<style>
.step-pg {
  display: flex;
  align-items: center;
  margin-left: 48px;
  margin-top: 50px;
}
.step-item {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.step-item .step-num {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  font-size: 20px;
  
  font-weight: 500;
  color: #939597;
  text-align: center;
  line-height: 32px;
  margin-right: 15px;
  border: 1px solid #e7e8eb;
}

.step-item .step-sl {
  background-color: #fe6002;
  color: #ffffff;
  border: none;
}

.step-pg .step-item .tt1 {
  display: inline-block;
  font-size: 20px;
  
  font-weight: 500;
  color: #939597;
}

.step-pg .step-item .ttt2sl {
  display: block;
  color: #191919;
  font-size: 20px;
  
  font-weight: 600;
}
.step-pg .xian {
  width: 80px;
  height: 1px;
  background-color: #cccccc;
  margin-left: 5px;
}
</style>