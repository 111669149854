<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-05-23 14:07:51
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-05-30 17:17:56
 * @FilePath: /lcwpcvue/src/components/regist.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="regist-view">
    <div class="title">注册账号</div>
    <div class="sendcode">
      <el-form
        :model="ruleForm"
        status-icon
        :rules="rules"
        ref="ruleForm"
        class="demo-ruleForm"
      >
        <label v-show="!succ">
          <el-form-item class="input-view" label="" prop="moblie">
            <el-input
              placeholder="请输入手机号"
              type="text"
              v-model="ruleForm.moblie"
              clearable
            >
            </el-input>
          </el-form-item>

          <el-form-item class="input-view code-view" label="" prop="checkCode">
            <!-- <el-input
            type="password"
            v-model="ruleForm.pass"
            autocomplete="off"
            style="border: none"
          ></el-input> -->
            <el-input
              v-model="ruleForm.code"
              type="text"
              placeholder="请输入验证码"
            >
              <i
                v-if="ruleForm.code.length > 0"
                slot="suffix"
                class="el-icon-circle-close codeclear"
                @click="ruleForm.code = ''"
              ></i>
            </el-input>
            <el-col v-show="ruleForm.code != ''" class="code-x"></el-col>
            <!-- <el-button type="text" @click="sendCode">发送验证码</el-button> -->
            <el-button v-show="!smsFlag" type="text" @click="sendCode"
              >发送验证码</el-button
            >
            <el-button
              v-show="smsFlag"
              type="text"
              @click="sendCode"
              disabled
              >{{ sendTime }}</el-button
            >
          </el-form-item>

          <el-form-item class="input-view" label="" prop="password">
            <el-input
              type="password"
              v-model="ruleForm.password"
              autocomplete="off"
              placeholder="请输入密码"
            ></el-input>
          </el-form-item>

          <div class="passwqd">
            <span :class="[level.includes('low') ? 'passlow' : '']">危险</span>
            <span :class="[level.includes('middle') ? 'passmiddle' : '']"
              >一般</span
            >
            <span :class="[level.includes('high') ? 'passhigh' : '']"
              >安全</span
            >
          </div>
          <el-form-item class="input-view" label="" prop="checkPass">
            <el-input
              type="password"
              v-model="ruleForm.checkPass"
              autocomplete="off"
              placeholder="请确认密码"
            ></el-input>
          </el-form-item>

          <div class="login-xy">
            <el-tooltip
              class="item"
              effect="dark"
              content="请勾选下方协议"
              placement="top-start"
              :manual="true"
              v-model="toolShow"
              :hide-after="800"
            >
              <el-checkbox
                v-model="allow"
                @change="checkboxChang"
              ></el-checkbox>
            </el-tooltip>
            <div class="xy-view">
              同意<a href="www.baidu.com">《猎宠网注册协议》</a>和<a
                href="www.baidu.com"
                >《猎宠网用户隐私协议》</a
              >
            </div>
          </div>
        </label>

        <label v-show="succ">
          <div class="succ-img">
            <img src="../assets/login/forgetpasssucc.png" alt="" />
            <span> <i class="el-icon-success"></i> 恭喜你注册成功！</span>
          </div>
        </label>

        <el-form-item class="subm" :class="succ ? 'topla' : ''">
          <el-button v-show="!succ" type="primary" @click="submitForm()">{{
            submitBtnText
          }}</el-button>
          <el-button v-show="succ" type="primary" @click="toBaseInfPage"
            >下一步</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <div class="wxlogin" v-show="!succ" @click="changeLoginType">
      <span>——————————————</span>
      <img class="wx-img" src="../assets/login_wximg.png" alt="" />
      <el-button type="text">使用第三方微信登录/注册</el-button>
      <span>——————————————</span>
    </div>
  </div>
</template>

<script>
import {
  sendPhoneCode,
  // updatdePassword,
  // userLoginByCode,
  // userLoginByPassw,userPcApi
} from "@/api/commen/login";
import { registerByPhone } from "@/api/commen/register";
import { userPcApi } from "@/api/commen/setApi.js";
import { Message } from "element-ui";
import { rsaEncrypt } from "@/utils/Rsa/rsa";
export default {
  name: "regist",
  data() {
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      // moblie: ,
      // usertype: ,
      // password: ,
      // code: ,
      // ip:
      ruleForm: {
        moblie: "",
        pass: "",
        usertype: "",
        code: "",
        password: "",
        checkPass: "",
        ip: "",
      },
      rules: {
        moblie: [
          { required: false, message: "手机号不能为空", trigger: "blur" },
        ],
        code: [{ required: true, message: "验证码不能为空", trigger: "blur" }],
        password: [{ validator: this.checkPassword, trigger: "change" }],
        checkPass: [{ validator: validatePass2, trigger: "blur" }],
        // code: [
        // { required: true, message: "验证码不能为空", trigger: "blur" },
        // ]

        // age: [{ validator: checkAge, trigger: "blur" }],
      },
      level: [],
      submitBtnText: "注册",
      succ: false,
      allow: true,
      codeEvent: "login",
      isFisterLogin: false,
      toolShow: false,
      smsFlag: false,
      sendTime: "重新获取",
      snsMsgWait: 60,
    };
  },
  created() {
    this.isFisterLogin = this.$store.state.user.isfisterlogin;
    console.log(this.isFisterLogin);
    if (this.$store.state.user.isfisterlogin) {
      this.codeEvent = "register";
    } else {
      this.codeEvent = "login";
    }
    this.ruleForm.usertype = localStorage.usertype;
    this.ruleForm.ip = this.$store.state.user.userip;
    // this.$store.state.user.userip
  },
  methods: {
    //发送验证码
    sendCode() {
      if (!this.allow) {
        Message({
          message: "请阅读并勾选下方协议",
          type: "error",
          duration: 3 * 1000,
          showClose: true,
          offset: 100,
        });
        this.toolShow = true;
        return;
      }
      if (this.ruleForm.moblie == "") {
        Message({
          message: "请输入手机号",
          type: "error",
          duration: 3 * 1000,
          showClose: true,
          offset: 100,
        });
        return;
      }
      if (this.ruleForm.moblie.length != 11) {
        Message({
          message: "请输入正确的手机号",
          type: "error",
          duration: 3 * 1000,
          showClose: true,
          offset: 100,
        });
        return;
      }
      userPcApi({
        type: "checkPhone",
        usertype: this.ruleForm.usertype,
        telType: "tel",
        phone: this.ruleForm.moblie,
      })
        .then((result) => {
          if (Object.keys(result.data).length > 0) {
            this.$confirm(
              `该手机号已被其他账号绑定，请核实输入的信息的准确性！`,
              "提示",
              {
                confirmButtonText: "确定",
                type: "warning",
              }
            )
              .then(() => {})
              .catch(() => {});
          } else {
            this.countdown();
            console.log(this.codeEvent);
            sendPhoneCode({
              usertype: this.ruleForm.usertype,
              event: this.codeEvent,
              moblie: this.ruleForm.moblie,
            })
              .then((result) => {
                console.log("发送验证码", result);
                if (result.code == 200) {
                  Message({
                    message: "发送成功",
                    type: "success",
                    duration: 3 * 1000,
                    showClose: true,
                    offset: 100,
                  });
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        })
        .catch(() => {});
    },

    submitForm() {
      var that = this;
      console.log(that.codeEvent);
      // if (this.ruleForm.password == this.ruleForm.checkPass) {
      // this.ruleForm.password = rsaEncrypt(that.ruleForm.password);
      console.log(that.ruleForm.password);
      // let pas = ;
      registerByPhone({
        moblie: that.ruleForm.moblie,
        code: that.ruleForm.code,
        usertype: that.ruleForm.usertype,
        password: rsaEncrypt(that.ruleForm.password),
      })
        .then((result) => {
          console.log("注册账号", result);
          if (result.code == 200) {
            this.$message({
              message: "注册成功！",
              type: "success",
              center: true,
            });
            this.$store.dispatch("user/setToken", result.data.token);
            this.$store.dispatch("user/setUserUid", result.data.member.uid);
            this.$cookies.set("token", result.data.token, "1d");
            localStorage.setItem("token", result.data.token);
            setTimeout(() => {
              this.succ = true;
            }, 2000);
          } else {
            this.$message({
              message: result.msg,
              type: "warning",
              center: true,
            });
            setTimeout(() => {
              this.$router.push("/login");
            }, 1000);
          }
        })
        .catch(() => {});
      // }
    },
    toBaseInfPage() {
      // this.$emit("passdChangeShowType");
      if (this.ruleForm.usertype == 1) {
        this.$router.push("/userregistbasinf");
      } else {
        this.$router.push("/comregist");
      }
    },
    checkboxChang() {
      this.toolShow = false;
    },
    changeLoginType() {
      this.$emit("passdChangeShowType");
    },

    countdown() {
      // 60秒后重新获取验证码
      this.smsFlag = true;
      this.sendTime = this.snsMsgWait + "s后重发";
      this.snsMsgWait = this.snsMsgWait - 1;

      var inter = setInterval(
        function () {
          this.smsFlag = true;
          this.bordercolor = "no";
          this.sendTime = this.snsMsgWait + "s后重发";
          this.snsMsgWait = this.snsMsgWait - 1;
          if (this.snsMsgWait < 0) {
            clearInterval(inter);
            this.bordercolor = "basics";
            this.sendColor = "#02c598";
            this.sendTime = "获取验证码";
            this.snsMsgWait = 60;
            this.smsFlag = false;
          }
        }.bind(this),
        1000
      );
    },

    // 校验密码
    checkPassword(rule, value, callback) {
      this.level = [];
      if (!value) {
        return callback("密码不能为空");
      }
      if (value.length < 6) {
        return callback("密码不少于6位");
      }
      if (value.length > 16) {
        return callback("密码不大于16位");
      }
      // 校验是数字
      const regex1 = /^\d+$/;
      // 校验字母
      const regex2 = /^[A-Za-z]+$/;
      // 校验符号
      const regex3 =
        /^[`~!@#$%^&*()_\-+=<>?:"{}|,.\\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]+$/;
      if (regex1.test(value)) {
        this.level.push("low");
      } else if (regex2.test(value)) {
        this.level.push("low");
      } else if (regex3.test(value)) {
        this.level.push("low");
      } else if (/^[A-Za-z\d]+$/.test(value)) {
        this.level.push("low");
        this.level.push("middle");
      } else if (
        /^[`~!@#$%^&*()_\-+=<>?:"{}|,.\\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、\d]+$/.test(
          value
        )
      ) {
        this.level.push("low");
        this.level.push("middle");
      } else if (
        /^[`~!@#$%^&*()_\-+=<>?:"{}|,.\\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、A-Za-z]+$/.test(
          value
        )
      ) {
        this.level.push("low");
        this.level.push("middle");
      } else if (
        /^[`~!@#$%^&*()_\-+=<>?:"{}|,.\\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、A-Za-z\d]+$/.test(
          value
        )
      ) {
        this.level.push("low");
        this.level.push("middle");
        this.level.push("high");
      }
      return callback();
    },
  },
};
</script>

<style>
.regist-view {
  width: 986px;
  height: 848px;
  background-color: #ffffff;
  border-radius: 24px;
}

.regist-view .title {
  width: 866px;
  margin-left: 68px;
  font-size: 32px;
  
  font-weight: 600;
  color: #222222;
  border-bottom: 2px solid #dddddd;
  padding: 30px 0;
}

.sendcode {
  width: 678px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 163px;
}

.demo-ruleForm {
  width: 678px;
  /* margin-top: 0.37rem; */
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
}

.regist-view .el-input__inner {
  height: 60px;
}

.regist-view .sendcode .code-view {
  /* display: flex;
  position: relative; */
  width: 678px;
}

.regist-view .sendcode .code-view .code-x {
  width: 1px;
  /* height: 26px; */
  background-color: #c1c1c1;
  position: absolute;
  /* left: 10.95rem; */
  right: 162px;
  /* top: 17px; */
}
.regist-view .sendcode .code-view .el-button {
  width: 100px;
  height: 60px;
  color: #fe6002;
  position: absolute;
  /* left: 5.95rem; */
  right: 32px;
  font-size: 18px;
  
  font-weight: 400;
}

.regist-view .sendcode .codeclear {
  position: relative;
  /* right: 50px; */
}

.regist-view .sendcode .code-view .codeclear {
  right: 80px;
}

.sendcode .el-form-item--feedback .el-input__validateIcon {
  display: none;
  height: 60px;
}

.sendcode .el-form-item {
  margin-bottom: 0;
}

.sendcode .input-view {
  margin-top: 45px;
}

.login-xy {
  display: flex;
  align-items: center;
  font-size: 12px;
  
  font-weight: 400;
  color: #939597;
  margin-top: 32px;
}

.login-xy .el-checkbox__input {
  margin-right: 2px;
}

.login-xy .xy-view a {
  text-decoration: none;
  color: #3291e1;
}

.subm {
  margin-top: 38px;
  width: 100%;
  text-align: center;
}

.subm .el-button {
  width: 369px;
  height: 60px;
  font-size: 20px;
}
.botm-tetx {
  margin-top: 52px;
  font-size: 16px;
  
  font-weight: 400;
  color: #222222;
  margin-left: 163px;
}
.botm-tetx .t2 {
  margin-top: 5px;
}
.botm-tetx .t2 label {
  color: #f34b37;
}
.passwqd {
  margin-top: 20px;
}
.passwqd span {
  display: inline-block;
  width: 219px;
  height: 30px;
  background-color: #f5f6fa;
  font-size: 14px;
  
  font-weight: 400;
  color: #757575;
  line-height: 30px;
  margin-right: 10px;
}
.passwqd span:last-child {
  margin-right: 0;
}
.passwqd .passlow {
  background-color: #f34b37;
  color: #fff;
}

.passwqd .passmiddle {
  background-color: #3291e1;
  color: #fff;
}

.passwqd .passhigh {
  background-color: #59d773;
  color: #fff;
}

.wxlogin {
  display: flex;
  align-items: center;
  margin-left: 163px;
  font-size: 16px;
  
  font-weight: 400;
  color: #222222;
  margin-top: 40px;
}

.wxlogin span {
  color: #dddddd;
}

.wxlogin .wx-img {
  width: 30px;
  height: 30px;
  margin-left: 18px;
  margin-right: 8px;
}

.wxlogin .el-button span {
  color: #222222;
  margin-right: 17px;
}

.succ-img {
  margin-top: 93px;
}

.succ-img img {
  width: 241px;
  height: 215px;
}

.succ-img span {
  display: block;
  font-size: 24px;
  
  font-weight: 600;
  color: #222222;
  margin-top: 76px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.succ-img .el-icon-success {
  color: #59d773;
  zoom: 1.2;
  margin-right: 10px;
}

.topla {
  margin-top: 80px;
}

/* input__inner */
</style>