<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-06-20 13:56:31
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-06-07 11:04:17
 * @FilePath: /lcwpcvue/src/components/comUser/comBasInf.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="combasinf">
    <registhead></registhead>
    <steppro :stepnub="1"></steppro>
    <div class="bas-inf">
      <el-form
        :model="newCompanyInfObj"
        :rules="basRules"
        ref="newCompanyInfObj"
      >
        <el-form-item>
          <div class="headimgview">
            <zczheardimg
              class="avatar-uploader"
              :lookImgPath="newCompanyInfObj.logo"
              @success-img-path="successimgpath"
            />

            <div class="left-text">
              <div class="t11">企业logo</div>
              <div class="t2">上传logo提升企业形象，吸引更多优秀人才</div>
            </div>
          </div>
        </el-form-item>

        <div class="choseview chsl-input">
          <el-form-item label="" prop="tmpComName">
            <div class="tt">公司全称</div>
            <el-autocomplete
              v-model="tmpComName"
              :fetch-suggestions="remoteMethod"
              placeholder="请输入内容"
              @select="comNameHandleSelect"
              :trigger-on-focus="false"
            ></el-autocomplete>
            <!-- <el-select
              filterable
              remote
              placeholder="请填写公司名称"
              :remote-method="remoteMethod"
              v-model="newCompanyInfObj.name"
            >
              <el-option
                v-for="item in compList"
                :key="item.uid"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select> -->
          </el-form-item>

          <div class="tip-text">
            <div class="tip1">
              1、请输入您所属公司营业执照上的<span>公司全称</span>
            </div>
            <div class="tip1">
              2、请注意区分<span>总公司</span>和<span>分公司</span>
            </div>
            <div class="tip1">
              3、公司全称将用于后续的企业认证使用，请确保<span>准确性</span>
            </div>
          </div>
        </div>

        <div class="choseview time-sle">
          <el-form-item label="" prop="alias">
            <div class="tt">店铺名称</div>
            <el-input
              type="text"
              placeholder="请填写店铺名称或公司简称"
              v-model="newCompanyInfObj.alias"
            ></el-input>
          </el-form-item>
        </div>

        <div class="choseview">
          <el-form-item label="" prop="hy">
            <div class="tt">所属行业</div>
            <div class="chose-input" @click="isJobHyShow = true">
              <span class="t1-ch" v-show="newCompanyInfObj.hyName == ''"
                >请选择行业类别</span
              >
              <span class="t2" v-show="newCompanyInfObj.hyName != ''">{{
                newCompanyInfObj.hyName
              }}</span>
              <i class="el-icon-arrow-right"></i>
            </div>
          </el-form-item>
        </div>

        <!-- <div class="choseview">
          <el-form-item label="">
            <div class="items">
              <div class="title"><span>公司规模</span></div>
              <el-select
                v-model="baseObj.mun_name"
                placeholder="请选择规模"
                @visible-change="getCompanyPepleNum"
                @change="xlChose"
              >
                <el-option
                  v-for="item in gmChoseArr"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </div>
          </el-form-item>
        </div> -->

        <div class="choseview">
          <el-form-item label="" prop="mun">
            <div class="tt">公司规模</div>
            <el-select
              placeholder="请选择人员规模"
              v-model="newCompanyInfObj.mun"
            >
              <el-option
                v-for="item in gmChoseArr"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>

        <div class="choseview time-sle">
          <el-form-item label="" prop="linkman">
            <div class="tt">联系人</div>
            <el-input
              v-model="newCompanyInfObj.linkman"
              type="text"
              placeholder="请填写联系人"
            ></el-input>
          </el-form-item>
        </div>

        <div class="choseview time-sle">
          <el-form-item label="" prop="provinceid">
            <div class="tt">公司地址</div>
            <el-input
              readonly
              placeholder="请选择公司地址"
              clearable
              suffix-icon="el-icon-arrow-right"
              v-model="newCompanyInfObj.address"
            >
            </el-input>
            <div class="zd-ingp-view" @click="addAddrDialogShow = true"></div>
          </el-form-item>
        </div>

        <div class="rsg-bbasinf-dialog-footer">
          <el-form-item label="">
            <el-button
              type="primary"
              @click="submitCompnInf('newCompanyInfObj')"
              >下一步</el-button
            >
          </el-form-item>
        </div>
      </el-form>
    </div>

    <chosejobhy
      :dialogVisible3="isJobHyShow"
      @closeDilog3="closeDilog3"
      @changeJobHy="changeJobHy"
    ></chosejobhy>
    <addaddredialog
      :addAddrDialogShow="addAddrDialogShow"
      @saveAddress="saveAddress"
      @closeMapPop="addAddrDialogShow = false"
    ></addaddredialog>
  </div>
</template>

<script>
import registHead from "@/components/comUser/registHead.vue";
import steppro from "@/components/comUser/stepCom.vue";
import ChoseJobHy from "../choseHy.vue";
import addaddredialog from "@/components/comUser/addAddressDialog.vue";
import {
  getCompanyPepleNum,
  createCompanyInfo,
} from "@/api/compe/companyInf.js";
import { getAddressId, addAddress } from "@/api/commen/addressManageApi.js";
import requestByNotLoading from "@/utils/requestByNotLoading";
export default {
  components: {
    registhead: registHead,
    steppro,
    chosejobhy: ChoseJobHy,
    addaddredialog,
  },
  data() {
    return {
      updateUrl: process.env.VUE_APP_UPLOUD + "/api/upload/upload",
      basRules: {
        name: [{ required: true, message: "请输入公司全称", trigger: "blur" }],
        alias: [{ required: true, message: "请输入店铺全称", trigger: "blur" }],
        hy: [{ required: true, message: "请选择所属行业", trigger: "blur" }],
        mun: [{ required: true, message: "请选择公司规模", trigger: "blur" }],
        linkman: [{ required: true, message: "请填写联系人", trigger: "blur" }],
        provinceid: [
          { required: true, message: "请选择公司地址", trigger: "blur" },
        ],
      },
      headImageUrl: "",
      isJobHyShow: false,
      addAddrDialogShow: false,
      compList: [],
      gmChoseArr: [],
      newCompanyInfObj: {
        logo: "https://mp-168c5a18-1927-4a65-8b2c-95087d865588.cdn.bspapp.com/cloudstorage/com_mr.png", // 公司logo
        name: "", // 公司全称
        alias: "",
        hy: "", // 行业id
        mun: "", // 人员规模
        linkman: "", // 联系人
        address: "",
        provinceid: "",
        cityid: "",
        three_cityid: "",
        x: "",
        y: "",
        uid: "",
        obj: "{}",
        hyName: "", //占用不传递给后端
      },
      tmpComName: "",
      cropperDialogShow: false,
    };
  },
  mounted() {
    this.getCompanyPepleNum();
    let comTmpData = sessionStorage.getItem("createComnpanyTmpData");
    if (comTmpData) {
      this.newCompanyInfObj = JSON.parse(comTmpData);
      this.tmpComName = this.newCompanyInfObj.name;
    }
  },
  methods: {
    selectChange() {
      this.cropperDialogShow = true;
    },
    successimgpath(e) {
      this.newCompanyInfObj.logo = e.data.httpCosUrl;
    },
    handleAvatarSuccess(e) {
      this.newCompanyInfObj.logo = e.data.url.replace(
        "./data",
        process.env.VUE_APP_IMG + "/data/"
      );
    },
    remoteMethod(e, cb) {
      if (e === this.newCompanyInfObj.name) {
        return;
      }
      if (e !== "" && e.length > 1) {
        requestByNotLoading({
          url: "company/comParamsByQcc",
          method: "post",
          data: {
            kw: e,
          },
        }).then((res) => {
          if (res.data.Result.length > 0) {
            res.data.Result.forEach((element) => {
              element.value = element.Name;
            });
            this.compList = res.data.Result;
            cb(this.compList);
          } else {
            cb([]);
          }
        });
      } else {
        cb([]);
      }
    },
    comNameHandleSelect(item) {
      console.log("comNameHandleSelect:", item);
      this.newCompanyInfObj.name = item.name = this.tmpComName = item.Name;
      delete item.value;
      delete item.Name;
      this.newCompanyInfObj.obj = JSON.stringify(item);
    },
    //获取公司规模列表
    getCompanyPepleNum() {
      getCompanyPepleNum({ name: "job_mun" })
        .then((result) => {
          console.log("公司规模", result);
          this.gmChoseArr = result.data.list.job_mun;
        })
        .catch(() => {});
    },
    closeDilog3() {
      this.isJobHyShow = false;
    },
    changeJobHy(e) {
      console.log(e);
      this.isJobHyShow = false;
      this.newCompanyInfObj.hy = e.id;
      this.newCompanyInfObj.hyName = e.name;
    },
    saveAddress(e) {
      console.log("saveAddress(e)", e);
      this.newCompanyInfObj.x = e.x;
      this.newCompanyInfObj.y = e.y;
      getAddressId({ lat: e.y, lng: e.x })
        .then((result) => {
          console.log("获取城市id", result);
          this.newCompanyInfObj.provinceid = result.data.province_id;
          this.newCompanyInfObj.cityid = result.data.city_id;
          this.newCompanyInfObj.three_cityid = result.data.three_city_id;
        })
        .catch(() => {});
      this.newCompanyInfObj.address = e.fulladdr + e.detailedAddress;
      this.addAddrDialogShow = false;
    },

    submitCompnInf(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          sessionStorage.setItem(
            "createComnpanyTmpData",
            JSON.stringify(this.newCompanyInfObj)
          );
          if (this.tmpComName !== this.newCompanyInfObj.name) {
            this.newCompanyInfObj.name = this.tmpComName;
            this.newCompanyInfObj.obj = "{}";
          }
          createCompanyInfo(this.newCompanyInfObj)
            .then((result) => {
              console.log("新建公司信息", result);
              //第一次注册，将地址保存一下
              this.$message({
                message: "注册成功！",
                type: "success",
                center: true,
              });
              addAddress({
                provinceid: this.newCompanyInfObj.provinceid,
                cityid: this.newCompanyInfObj.cityid,
                three_cityid: this.newCompanyInfObj.three_cityid,
                address1: this.newCompanyInfObj.address,
                address2: this.newCompanyInfObj.address,
                x: this.newCompanyInfObj.x,
                y: this.newCompanyInfObj.y,
                is_com: 1,
              })
                .then((result) => {
                  console.log("保存地址", result);
                  this.$router.push("/businessauth");
                  // setTimeout(() => {
                  //   this.$router.push("/businessauth");
                  // }, 3000);
                })
                .catch(() => {});
            })
            .catch(() => {});
        }
      });
    },
  },
};
</script>

<style>
.combasinf {
  background-color: #fff;
  width: 975px;
  padding: 25px 23px;
  border-radius: 20px;
}
.bas-inf {
  margin-top: 65px;
  padding-left: 48px;
}
.avatar-uploader {
  height: 100px;
  display: flex;
  align-items: center;
}
.avatar-uploader img {
  width: 100px;
  height: 100px;
}
.headimgview {
  display: flex;
}

.headimgview .left-text {
  margin-left: 20px;
}
.headimgview .left-text .t11 {
  font-size: 22px;

  font-weight: 500;
  color: #222222;
}
.headimgview .left-text .t2 {
  font-size: 18px;

  font-weight: 500;
  color: #939597;
}

.choseview {
  position: relative;
  width: 860px;
}

.choseview .tt,
.choseviewlong .tt {
  font-size: 14px;
  color: #606266;
}

.choseview .tt span,
.choseviewlong .tt span {
  font-size: 12px;
  color: #b1b1b1;
}

.chsl-input .el-select {
  width: 860px;
  margin-right: 57px;
}

.el-input__inner {
  height: 40px;
}

.chsl-input .tip-text {
  font-size: 13px;

  font-weight: 500;
  color: #939597;
  margin-bottom: 20px;
}

.chsl-input .tip-text .tip1 {
  margin-top: 5px;
}
.chsl-input .tip-text .tip1 span {
  color: #f34b37;
}
.time-sle {
  width: 860px;
}

.chose-input {
  width: 830px;
  height: 40px;
  border: 1px solid #e4e7ed;
  border-radius: 4px;
  padding: 0 15px;
  color: #b1b1b1;
  position: relative;
  display: flex;
  align-items: center;
}

.chose-input .ch {
  font-size: 14px;

  font-weight: 400;
}

.chose-input .t2 {
  color: #222;
}

.chose-input i {
  position: absolute;
  right: 15px;
}

.combasinf .el-select {
  width: 860px;
}

.time-sle .zd-ingp-view {
  width: 100%;
  height: 46px;
  /* border: 1px solid #000; */
  position: absolute;
  top: 30px;
}

.rsg-bbasinf-dialog-footer {
  width: 860px;
  text-align: right;
  margin-top: 10px;
}
.rsg-bbasinf-dialog-footer .el-button--primary {
  font-size: 18px;
  width: 368px;
  height: 50px;
}
</style>