<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-07-21 14:06:25
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-06-07 17:47:28
 * @FilePath: /lcwpcvue/src/components/loginWthiPage.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="index-login">
    <div class="login-title">找工作 招人才 上猎宠网</div>
    <div class="login-view-username-pass">
      <div class="login-navbar-view">
        <div class="nav-item" v-if="usertype == 1">
          <el-button size="mini" class="na-txt na-txt-sl" type="text"
            >求职者</el-button
          >
          <div class="bot-lin"></div>
        </div>
        <div class="nav-item" v-if="usertype == 2">
          <el-button size="mini" class="na-txt" type="text">企业登录</el-button>
          <div class="bot-lin"></div>
        </div>
      </div>

      <el-form
        :model="ruleForm"
        status-icon
        :rules="rules"
        ref="ruleForm"
        class="demo-ruleForm"
      >
        <el-form-item
          v-show="loginType == 1"
          class="input-view"
          label=""
          prop="moblie"
        >
          <el-input
            placeholder="请输入手机号"
            type="text"
            v-model="ruleForm.moblie"
            clearable
          >
            <!-- <i slot="suffix" class="el-icon-circle-close codeclear"></i
          > -->
          </el-input>
        </el-form-item>
        <el-form-item
          v-show="loginType == 2"
          class="input-view"
          label=""
          prop="username"
        >
          <el-input
            placeholder="请输入账号"
            type="text"
            v-model="ruleForm.username"
            autocomplete="off"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item
          v-show="loginType == 1"
          class="input-view code-view"
          label=""
          prop="checkCode"
        >
          <el-input
            v-model="ruleForm.code"
            type="text"
            placeholder="请输入验证码"
          >
            <i slot="suffix" class="el-icon-circle-close codeclear"></i>
          </el-input>
          <el-col class="code-x"></el-col>
          <!-- <el-divider direction="vertical"></el-divider> -->
          <el-button v-show="!smsFlag" type="text" @click="sendCode"
            >发送验证码</el-button
          >
          <el-button v-show="smsFlag" type="text" @click="sendCode" disabled>{{
            sendTime
          }}</el-button>
        </el-form-item>
        <el-form-item
          v-show="loginType == 2"
          class="input-view code-view"
          label=""
          prop="password"
        >
          <el-input
            type="password"
            v-model="ruleForm.passwordShow"
            autocomplete="off"
            placeholder="请输入密码"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item class="remerme" label="" prop="pass">
          <div class="cherem">
            <!-- <div class="chbox-view">
              <el-checkbox></el-checkbox>
              <label>记住我</label>
            </div> -->
            <el-button
              v-show="loginType == 1"
              type="text"
              @click="loginType = 2"
              >密码登录</el-button
            >
            <el-button
              v-show="loginType == 2"
              type="text"
              @click="loginType = 1"
              >验证码登录</el-button
            >
            <el-button class="wj-btn" type="text" @click="frogetPassword"
              >忘记密码</el-button
            >
          </div>
        </el-form-item>
        <el-form-item class="sub-btn">
          <el-button type="primary" @click="submitForm('ruleForm')"
            >立即登录</el-button
          >
        </el-form-item>
      </el-form>

      <div class="login-xy">
        <el-tooltip
          class="item"
          effect="dark"
          content="请勾选下方协议"
          placement="top-start"
          :manual="true"
          v-model="toolShow"
          :hide-after="800"
        >
          <el-checkbox v-model="allow" @change="checkboxChang"></el-checkbox>
        </el-tooltip>
        <!-- <div class="xy-view">
          同意<a href="https://www.baidu.com">《猎宠网注册协议》</a>和<a
            href="www.baidu.com"
            >《猎宠网用户隐私协议》</a
          >
        </div> -->
        <div class="xy-view">
          同意
          <span @click="selectRegisterAgreement('register')"
            >《猎宠网注册协议》</span
          >和
          <span @click="selectRegisterAgreement('privacy')"
            >《猎宠网用户隐私协议》</span
          >
        </div>
      </div>

      <div class="bot-zc-view">没有账号？立即<label>注册</label></div>
    </div>
  </div>
</template>

<script>
import {
  sendPhoneCode,
  userLoginByCode,
  userLoginByPassw,
} from "@/api/commen/login";
import { Message, MessageBox } from "element-ui";
import { rsaEncrypt } from "@/utils/Rsa/rsa";
export default {
  props: {
    pathname: {
      type: String,
    },
  },
  data() {
    return {
      usertype: 0,
      ruleForm: {
        moblie: "",
        password: "",
        code: "",
        appletsid: "",
        unionid: "",
        username: "",
      },
      passwordShow: "",
      rules: {
        username: [{ required: false, message: "手机号不能为空", trigger: "" }],
        // checkCode: [
        //   { required: true, message: "验证码不能为空", trigger: "blur" },
        // ],

        moblie: [
          {
            required: true,
            message: "手机号不能为空",
            trigger: "",
          },
        ],
        // code: [
        // { required: true, message: "验证码不能为空", trigger: "blur" },
        // ]

        // age: [{ validator: checkAge, trigger: "blur" }],
      },
      allow: true,
      smsFlag: false,
      sendTime: "重新获取",
      snsMsgWait: 60,
      toolShow: false,
      loginType: 1,
    };
  },
  created() {
    this.usertype = localStorage.usertype;
    console.log(this.$cookies.get("usertype"), localStorage.usertype);
    this.ruleForm = {
      moblie: "",
      code: "",
      appletsid: 0,
      usertype: localStorage.usertype,
      login_ip: this.$store.state.user.userip,
    };
    if (!localStorage.token) {
      if (this.pathname == "job") {
        this.usertype = 1;
        this.$store.dispatch("user/setUserType", 1);
        this.$cookies.set("usertype", 1);
        localStorage.setItem("usertype", 1);
      } else {
        this.usertype = 2;
        this.$store.dispatch("user/setUserType", 2);
        this.$cookies.set("usertype", 2);
        localStorage.setItem("usertype", 2);
      }
    }
  },
  methods: {
    submitForm() {
      if (!this.allow) {
        this.toolShow = true;
        return;
      }
      // this.$refs[formName].validate((valid) => {
      //   if (valid) {
      // alert("submit!");
      console.log(this.ruleForm);
      if (this.loginType == 1) {
        // console.log(1);
        userLoginByCode(this.ruleForm)
          .then((result) => {
            console.log("验证码登录", result);
            if (result.code != 200) {
              if (result.code == 14) {
                // this.show = true; // 新用户,要注册
                // setIsFisterLoginState
                this.$store.dispatch("user/setIsFisterLoginState", true);
                MessageBox({
                  title: "提示",
                  message: result.msg,
                })
                  .then(() => {
                    this.$router.push("/registbyphone");
                  })
                  .catch(() => {});
              }
              return false;
            }
            if (result.code == 200) {
              // uni.setStorageSync("token", logininf.member.token);
              // uni.setStorageSync("uid", logininf.member.uid);
              this.$store.dispatch("user/setToken", result.data.token);
              this.$store.dispatch("user/setUserUid", result.data.member.uid);
              this.$cookies.set("token", result.data.token, "1d");
              localStorage.setItem("token", result.data.token);
              // this.$store.dispatch("user/setInfo", val.data);
              // this.$store.dispatch("user/setUserUid", val.data.member.uid);
              // this.$store.dispatch("user/setUserUid", val.data.member.uid);
            }

            if (this.$store.state.user.usertype == 1) {
              console.log(result.data.login_check);
              if (result.data.login_check == "") {
                // 个人信息
                this.$router.push("/userregistbasinf");
                // userregistbasinf
                // this.showType = 2;
              } else if (result.data.login_check.is_resume == "0") {
                // this.showType = 2;
                this.$router.push("/userregistbasinf");
              } else if (result.data.login_check.is_expect == "0") {
                // this.showType = 3;
                this.$router.push("/userregistbasinf");
              } else {
                // this.$refs;
                this.bindSocketCid();
                console.log("tiaotiaojfladjflidjfidaj");
                if (path == "/findjob") {
                  if (this.usertype == 1) {
                    location.reload();
                  } else {
                    this.$router.push("/findtalentslist");
                    location.reload();
                  }
                } else if (path == "/findtalentslist") {
                  if (this.usertype == 1) {
                    this.$router.push("/findjob");
                    location.reload();
                  } else {
                    location.reload();
                  }
                } else {
                  location.reload();
                }
              }
            } else if (this.$store.state.user.usertype == 2) {
              // console.log("ppp");
              if (result.data.login_check.length == 0) {
                // 公司信息
                this.$router.push("/comregist");
                console.log("走的是这里111");
              } else if (result.data.login_check.is_company == "0") {
                // uni.navigateTo({
                // 	url: `/pagesA/user2/info/info?firstlogin=1`
                // 	// url: '/pagesA/comAddJob/comAddJob?firstlogin=1'
                // })
                this.$router.push("/comregist");
              } else if (result.data.login_check.com_cert == "0") {
                this.$router.push("/businessauth");
                // 营业执照
                // console.log('走的是这里222');
                // uni.navigateTo({
                // 	// url: `/pagesA/user2/info/info?firstlogin=1`
                // 	url: '/pagesA/comAddJob/comAddJob?firstlogin=1'
                // })
              } else {
                this.bindSocketCid();
                var path = this.$store.state.user.headnavmenindex;
                if (path == "/findjob") {
                  if (this.usertype == 1) {
                    location.reload();
                  } else {
                    this.$router.push("/findtalentslist");
                    location.reload();
                  }
                } else if (path == "/findtalentslist") {
                  if (this.usertype == 1) {
                    this.$router.push("/findjob");
                    location.reload();
                  } else {
                    location.reload();
                  }
                } else {
                  location.reload();
                }
                // location.reload();
                // console.log(this.$store.user.headnavmenindex);
                // if (this.$store.user.headnavmenindex) {

                // }
                // 登录成功
                // this.$router.push("/index");
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        if (!this.ruleForm.username) {
          return;
        }
        if (!this.ruleForm.password) {
          return;
        }

        this.ruleForm.password = rsaEncrypt(this.passwordShow);

        userLoginByPassw(this.ruleForm)
          .then((result) => {
            console.log("账号密码登录", result);
            if (result.code != 200) {
              if (result.code == 14) {
                // this.show = true; // 新用户,要注册
                // setIsFisterLoginState
                this.$store.dispatch("user/setIsFisterLoginState", true);
                MessageBox({
                  title: "提示",
                  message: result.msg,
                })
                  .then(() => {
                    this.$router.push("/registbyphone");
                  })
                  .catch(() => {});
              }
              return false;
            }
            if (result.code == 200) {
              // uni.setStorageSync("token", logininf.member.token);
              // uni.setStorageSync("uid", logininf.member.uid);
              this.$store.dispatch("user/setToken", result.data.token);
              this.$store.dispatch("user/setUserUid", result.data.member.uid);
              this.$cookies.set("token", result.data.token, "1d");
              localStorage.setItem("token", result.data.token);
              // this.$store.dispatch("user/setInfo", val.data);
              // this.$store.dispatch("user/setUserUid", val.data.member.uid);
              // this.$store.dispatch("user/setUserUid", val.data.member.uid);
            }

            if (this.$store.state.user.usertype == 1) {
              console.log(result.data.login_check);
              if (result.data.login_check == "") {
                // 个人信息
                this.$router.push("/userregistbasinf");
                // userregistbasinf
                // this.showType = 2;
              } else if (result.data.login_check.is_resume == "0") {
                // this.showType = 2;
                this.$router.push("/userregistbasinf");
              } else if (result.data.login_check.is_expect == "0") {
                // this.showType = 3;
                this.$router.push("/userregistbasinf");
              } else {
                // this.$refs;
                var path = this.$store.state.user.headnavmenindex;
                if (path == "/findjob") {
                  if (this.usertype == 1) {
                    location.reload();
                  } else {
                    this.$router.push("/findtalentslist");
                    location.reload();
                  }
                } else if (path == "/findtalentslist") {
                  if (this.usertype == 1) {
                    this.$router.push("/findjob");
                    location.reload();
                  } else {
                    location.reload();
                  }
                } else {
                  location.reload();
                }
                this.bindSocketCid();
              }
            } else if (this.$store.state.user.usertype == 2) {
              // console.log("ppp");
              if (result.data.login_check.length == 0) {
                // 公司信息
                this.$router.push("/comregist");
                console.log("走的是这里111");
              } else if (result.data.login_check.is_company == "0") {
                // uni.navigateTo({
                // 	url: `/pagesA/user2/info/info?firstlogin=1`
                // 	// url: '/pagesA/comAddJob/comAddJob?firstlogin=1'
                // })
                this.$router.push("/comregist");
              } else if (result.data.login_check.com_cert == "0") {
                this.$router.push("/businessauth");
                // 营业执照
                // console.log('走的是这里222');
                // uni.navigateTo({
                // 	// url: `/pagesA/user2/info/info?firstlogin=1`
                // 	url: '/pagesA/comAddJob/comAddJob?firstlogin=1'
                // })
              } else {
                // location.reload();
                path = this.$store.state.user.headnavmenindex;
                if (path == "/findjob") {
                  if (this.usertype == 1) {
                    location.reload();
                  } else {
                    this.$router.push("/findtalentslist");
                    location.reload();
                  }
                } else if (path == "/findtalentslist") {
                  if (this.usertype == 1) {
                    this.$router.push("/findjob");
                    location.reload();
                  } else {
                    location.reload();
                  }
                } else {
                  location.reload();
                }
                this.bindSocketCid();

                // 登录成功
              }
            }
          })
          .catch((err) => {
            console.log("cuocuocuco", err);
          });
      }
      //   } else {
      //     console.log("error submit!!");
      //     return false;
      //   }
      // });
    },
    validatePhoneNumber() {
      const reg = /^1[3456789]\d{9}$/;
      if (!reg.test(this.ruleForm.moblie)) {
        // 手机号码不符合规则
        return false;
      }
      // 手机号码符合规则
      return true;
    },

    sendCode() {
      if (!this.allow) {
        Message({
          message: "请阅读并勾选下方协议",
          type: "error",
          duration: 3 * 1000,
          showClose: true,
          offset: 100,
        });
        this.toolShow = true;
        return;
      }
      if (this.ruleForm.moblie == "") {
        Message({
          message: "请输入手机号",
          type: "error",
          duration: 3 * 1000,
          showClose: true,
          offset: 100,
        });
        return;
      }
      if (this.ruleForm.moblie.length != 11) {
        Message({
          message: "请输入正确的手机号",
          type: "error",
          duration: 3 * 1000,
          showClose: true,
          offset: 100,
        });
        return;
      }
      if (!this.validatePhoneNumber()) {
        Message({
          message: "请输入正确的手机号",
          type: "error",
          duration: 3 * 1000,
          showClose: true,
          offset: 100,
        });
        return;
      }
      this.countdown();
      // usertype: this.usertype,
      // 			event: 'login',
      // 			moblie: this.tel
      sendPhoneCode({
        usertype: this.ruleForm.usertype,
        event: "login",
        moblie: this.ruleForm.moblie,
      })
        .then((result) => {
          console.log("发送验证码", result);
          if (result.code == 200) {
            Message({
              message: "发送成功",
              type: "success",
              duration: 3 * 1000,
              showClose: true,
              offset: 100,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    checkboxChang() {
      this.toolShow = false;
    },
    frogetPassword() {
      this.$router.push("/forgetpass");
    },

    countdown() {
      // 60秒后重新获取验证码
      this.smsFlag = true;
      this.sendTime = this.snsMsgWait + "s后重发";
      this.snsMsgWait = this.snsMsgWait - 1;

      var inter = setInterval(
        function () {
          this.smsFlag = true;
          this.bordercolor = "no";
          this.sendTime = this.snsMsgWait + "s后重发";
          this.snsMsgWait = this.snsMsgWait - 1;
          if (this.snsMsgWait < 0) {
            clearInterval(inter);
            this.bordercolor = "basics";
            this.sendColor = "#02c598";
            this.sendTime = "获取验证码";
            this.snsMsgWait = 60;
            this.smsFlag = false;
          }
        }.bind(this),
        1000
      );
    },
  },
};
</script>

<style>
.index-login {
  width: 403px;
  height: 481px;
  background-image: url("../assets/login_bac_img.png");
  background-size: cover;
  text-align: center;
  margin-bottom: 40px;
}
.index-login .login-title {
  font-size: 26px;

  font-weight: bold;
  color: #fe6002;
  line-height: 30px;
  padding-top: 24px;
}
.index-login .login-view-username-pass {
  width: 368px;
  height: 404px;
  background: #ffffff;
  box-shadow: 2px 4px 10px 0px rgba(111, 111, 111, 0.15);
  border-radius: 8px 8px 8px 8px;
  margin: 17px;
}
.login-navbar-view {
  display: flex;
  align-items: center;
  width: 100%;
}

.login-navbar-view .nav-item {
  flex: 1;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-navbar-view .nav-item .na-txt {
  font-size: 18px;

  font-weight: 400;
  color: #757575;
}
.login-navbar-view .nav-item .na-txt-sl {
  font-size: 20px;

  font-weight: 600;
  color: #191919;
  line-height: 28px;
}
.login-navbar-view .nav-item .bot-lin {
  width: 37px;
  height: 3px;
  background-color: #fe6002;
}

.index-login .demo-ruleForm {
  width: 308px;
  margin-left: 30px;
  /* display: flex;
  flex-direction: column;
  align-items: center; */
}
/* ::v-deep .demo-ruleForm .el-input__inner {
  height: 50px;
  width: 368px;
  line-height: 50px;
} */

.demo-ruleForm .el-button {
  width: 308px;
  /* height: 46px; */
  font-size: 16px;

  font-weight: 500;
  color: #ffffff;
}

.sub-btn .el-button {
  margin-top: 10px;
}

.demo-ruleForm .code-view {
}

.el-input__suffix {
  display: flex;
  align-items: center;
  height: 44px;
}

.demo-ruleForm .code-view .code-x {
  width: 1px;
  height: 15px;
  background-color: #c1c1c1;
  position: absolute;
  /* left: 10.95rem; */
  right: 111px;
  top: 13px;
}
.demo-ruleForm .code-view .el-button {
  width: 80px;
  color: #fe6002;
  position: absolute;
  /* left: 5.95rem; */
  right: 15px;
  font-size: 14px;

  font-weight: 400;
}

.demo-ruleForm .code-view .codeclear {
  margin-right: 129px;
}
.demo-ruleForm .el-form-item--feedback .el-input__validateIcon {
  display: none;
}

/* .demo-ruleForm .el-input__validateIcon {
  display: none;
} */

/* .demo-ruleForm .code-view .el-input__validateIcon {
  display: none;
} */

.demo-ruleForm .el-form-item {
  margin-bottom: 0;
}

.demo-ruleForm .input-view {
  margin-top: 26px;
}

.demo-ruleForm .remerme {
  /* width: 16.73rem; */
  /* width: 80px; */
}

.demo-ruleForm .remerme .cherem {
  /* width: 80px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.demo-ruleForm .remerme .chbox-view {
  /* width: 12px; */
  display: inline;
}

.demo-ruleForm .remerme .chbox-view label {
  margin-left: 4px;
  font-size: 14px;

  font-weight: 400;
  color: #cccccc;
}

.demo-ruleForm .remerme .el-button {
  color: #fe6002;
  /* text-align: right; */
  width: 74px;
  display: inline;
  font-size: 12px;
}

.demo-ruleForm .remerme .wj-btn {
  font-size: 12px;

  font-weight: 400;
  color: #b1b1b1;
}

.index-login .login-xy {
  display: flex;
  align-items: center;
  font-size: 12px;

  font-weight: 400;
  color: #939597;
  margin-top: 30px;
  margin-left: 30px;
}
.login-xy .el-checkbox__input {
  margin-right: 8px;
}

.login-xy .xy-view a {
  text-decoration: none;
  color: #3291e1;
}

.login-xy .xy-view span {
  color: #3291e1;
  cursor: pointer;
}
.login-view-username-pass .bot-zc-view {
  font-size: 12px;
  font-weight: 400;
  color: #757575;
  margin-top: 30px;
}

.login-view-username-pass .bot-zc-view label {
  color: #0294fe;
}
</style>